import { server, local } from '../js/variables.js'
import moment from 'moment'

var userMixin = {
    data: function () {
        return {
          user:{
            name: '',
            lastname: '',
            document: '',
            birthdate: '',
            gender: '',
            phone: '',
            email: '',
            is_looking_job: '',
            is_looking_products: '',
            // password: '',
            address: [],
            card: [],
            resume:{
              title: '',
              position:'',
              summary: '',
              experience: [],
              education: [],
              skills: [],
            },
            
            // favorite: [],
          },
        }
    },
    // beforeCreate: function() {
    //   if (!localStorage.token) {
    //     server.config.headers = {
    //       Authorization: 'Bearer ' + localStorage.token
    //     }
    //   }
    // },
    mounted() {
      this.user = local.user;
      this.listenuser();
      
    },
    methods: {
      listenuser() {
        let self = this;
        // console.log('listenuser 1');
        setTimeout(function(){
          // console.log('listenuser 2');
          if(local.user._id) {
            // console.log('listenuser 2.1');
            // console.log("local user",local.user);
            self.user = local.user;
            // self.fixDate();
          } else {
            // console.log('listenuser 2.2');
            self.listenuser();
          }
        }, 1000);
      },
      convertDate(data) {
        //let dateFormated = moment(data , 'DD/MM/YYYY');
        // console.log("Dateformated", dateFormatedExperienceS);
        if(data == null ){
          return null
        }
        if(data == "Invalid date" ){
          return null
        }
        let dateFormated = moment(data , 'DD/MM/YYYY').format('YYYY-MM-DD');
        let dateString = '' + dateFormated.toString();

        
        return dateString;
      },
      prepareUserToSend(userCopy) {
        // console.log("prepareUserToSend - start" );

        // Clear phone mask
        let phone = userCopy.phone;
        if (phone)
        if(phone.length > 13){
          let phoneNoMask = phone.substring(1, 3) + phone.substring(5, 10) + phone.substring(11, 15);
          userCopy.phone = phoneNoMask;
        }

        // Clear user Document mask
        let document = userCopy.document;
        if (document)
        if(document.length == 14){
          let documentNoMask = document.substring(0, 3) + document.substring(4, 7) + document.substring(8, 11) + document.substring(12, 14);
          userCopy.document = documentNoMask;
        }

        // convert dates

        for(let experience of userCopy.resume.experience){
          experience.start_date  = this.convertDate(experience.start_date );
          
          if(experience.end_date == "" || experience.end_date == "hoje"){
            experience.end_date = null;
          }else{
            experience.end_date  = this.convertDate(experience.end_date );
          }
        }
        
        for(let education of userCopy.resume.education){
          education.start_date  = this.convertDate(education.start_date );
          
          if(education.end_date == "" || education.end_date == "hoje"){
            education.end_date = null;
          }else{
            education.end_date  = this.convertDate(education.end_date );
          }
        }
        
        if(userCopy.birthdate == ''){
          userCopy.birthdate = null;
        }

        userCopy.birthdate   = this.convertDate( userCopy.birthdate );
        

        console.log("prepareUserToSend - end" );
      },
      prepareUserToSendwithoutResume(userCopy) {
        // console.log("prepareUserToSend - start" );

        // Clear phone mask
        if(userCopy.phone){
          let phone = userCopy.phone;
          let phoneNoMask = phone.substring(1, 3) + phone.substring(5, 10) + phone.substring(11, 15);
          userCopy.phone = phoneNoMask;
        }
        // Clear user Document mask
        
        if(userCopy.document){
          let document = userCopy.document;
          let documentNoMask = document.substring(0, 3) + document.substring(4, 7) + document.substring(8, 11) + document.substring(12, 14);
          userCopy.document = documentNoMask;
        }
        // convert dates
        
        if(userCopy.birthdate == ''){
          userCopy.birthdate = null;
        }

        userCopy.birthdate   = this.convertDate( userCopy.birthdate );
        
        // console.log("prepareUserToSend - end" );
      },
      fixDate() {
        this.user.birthdate = this.fixDatePatern(this.user.birthdate)
        if (this.user.resume) {
          for (let experienceDate of this.user.resume.experience) {
            experienceDate.start_date = this.fixDatePatern(experienceDate.start_date);
            experienceDate.end_date = this.fixDatePatern(experienceDate.end_date);
          }
          for (let educationDate of this.user.resume.education) {
            educationDate.start_date = this.fixDatePatern(educationDate.start_date);
            educationDate.end_date = this.fixDatePatern(educationDate.end_date);
          }
        }
  
      },
      fixDatePatern(data) {
        let f = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
        return moment(data).add(4, 'hours').format('DD/MM/YYYY');
      },
      insertMasks(afterError) {
        if (this.user) {
          if (this.user.phone != '' && this.user.phone != null) {
            let phoneWithMask = '(' + this.user.phone.substring(0, 2) + ') ' + this.user.phone.substring(2, 7) + '-' + this.user.phone.substring(7, 11);
  
            this.user.phone = phoneWithMask;
          }
          if (this.user.document != '' && this.user.document != null) {
            let documentWithMask = this.user.document.substring(0, 3) + '.' + this.user.document.substring(3, 6) + '.' + this.user.document.substring(6, 9) + '-' + this.user.document.substring(9, 11);
  
            this.user.document = documentWithMask;
          }
          
          if(afterError){
        
            if(this.user.birthdate == null ){
              return null
            }
            if(this.user.birthdate == "Invalid date" ){
              return null
            }
            let dateFormated = moment(this.user.birthdate , 'YYYY-MM-DD').format('DD/MM/YYYY');
            let dateString = '' + dateFormated.toString();
  
            
            this.user.birthdate = dateString;
          }
        }
  
        if (this.company) {
          if (this.company.document != '' && this.company.document != null) {
            let documentWithMask = this.company.document.substring(0, 2) + '.' + this.company.document.substring(2, 5) + '.' + this.company.document.substring(5, 8) + '/' + this.company.document.substring(8, 12) + '-' + this.company.document.substring(12, 14);
            this.company.document = documentWithMask;
          }
          if (this.company.phone != '' && this.company.phone != null) {
            let phoneWithMask = '(' + this.company.phone.substring(0, 2) + ') ' + this.company.phone.substring(2, 7) + '-' + this.company.phone.substring(7, 11);
            this.company.phone = phoneWithMask;
          }
        }
  
  
      }
    }

}

export default userMixin;