<template>
  <div>
    <div class="training-item" v-bind:class="{ 'training-item-hover': type != 1 && !subscribed }"
      @click="courseDetails()">
      <div class="top-box">
        <img :src="getImageThumbUrl(training.image)" v-if="training.image" class="training-image" alt="">
      </div>
      <div class="info-box" v-bind:class="{ 'light-grey-bg ': grey, 'white-bg': !grey, 'info-box-cart': type != 1 }">
        <div class="training-name">{{ training.name }}</div>
        <div class="hr-yellow-light hr-25"></div>
        <div class="teacher">{{ training.user.name }} {{ training.user.lastname }}</div>

        <div v-if="type == 1" style="padding-top: 10px">
          <div class="otherInfos">Data criação: <b>{{ training.created_at }}</b></div>
          <div class="otherInfos">Aulas: <b>{{ lessonsAmount }}</b></div>
          <!-- <div class="otherInfos">Horas: <b>{{training.hours}}:00</b></div> -->
          <div class="otherInfos" v-if="training.exclusive_company">Tipo: <b>Exclusivo</b></div>
          <div class="otherInfos" v-else>Tipo: <b>Aberto</b></div>
          <div class="otherInfos" v-if="training.exclusive_company">Licenças: <b>{{ training.exclusive_licenses }}</b>
          </div>
          <div class="otherInfos" v-else>Valor: <b>{{ training.price | toCurrency }}</b></div>
          <div class="otherInfos" v-if="training.exclusive_company">Empresa:
            <b>{{ training.exclusive_company.name_fancy }}</b></div>
          <div style="padding-top: 10px">
            <div class="otherInfos" style="display: inline-block">Alunos: <b>0</b></div>
            <div class="otherInfos" style="display: inline-block; float: right">Total: <b>0</b></div>
          </div>
        </div>
        <div v-else>
          <div class="otherInfos">Valor: <b>{{ training.price | toCurrency }}</b></div>
          <div class="description" v-html="training.description"></div>
        </div>

        <div class="row" v-if="type == 1">
          <div class="col-md-6 d-none d-md-block d-lg-block">
            <button class="border" @click="goToEditCourse()">
              <img src="/static/svg/icon_pencil.svg" alt="" class="" />
              Editar
            </button>
          </div>
          <div class="col-md-6 d-none d-md-block d-lg-block">
            <button class="blue" style="width: auto" @click="goToViewCourse()">Visualizar</button>
          </div>
          <div class="col-md-12 d-block d-md-none d-lg-none">
            <button class="border" @click="goToEditCourse()">
              <img src="/static/svg/icon_pencil.svg" alt="" class="" />
              Editar
            </button>
            <button class="blue" style="float: right" @click="goToViewCourse()">Visualizar</button>
          </div>
          <div v-if="subscribed" style="width: 100%; padding: 0px 20px;">
            <button class="blue" style="width: 100%" @click="goToViewCourse()">Visualizar</button>
          </div>
        </div>
        <div style="text-align:center;" v-if="type == 2">
          <button class="blue" @click="watchTrainingEmployee()">Visualizar</button>
        </div>
        <div style="text-align:center;" v-if="type != 2 && type != 1">
          <button class="border border-big" @click="addCart($event)" v-if="!subscribed">Adicionar ao carrinho</button>
          <button class="blue blue-big" @click="watchTraining($event)" v-else>Visualizar</button>
        </div>
      </div>

      <hr class="d-block d-md-none d-lg-none" v-bind:style="type == 1 ? 'margin-top: 60px;' : 'margin-top: 17px;'">
    </div>

  </div>
</template>

<script>
import { server, local } from "../js/variables.js";
import userMixin from "../mixins/userMixin.js";
import companyMixin from "../mixins/companyMixin.js";

export default {
  mixins: [userMixin, companyMixin],
  props: ["training", "grey", "type", 'subscribed'],
  components: {},
  data() {
    return {
      lessonsAmount: '',
    };
  },
  mounted() {
    // console.log('AQUI'+ this.training)
    this.lessonsAmount = 0;
    for (let section of this.training.sections) {
      this.lessonsAmount += section.chapters.length;
    }

    // console.log('aqui lessonsAmount', this.lessonsAmount);
  },
  methods: {
    watchTraining() {
      let self = this;
      const url = "/vc/treinamentos-inscritos/" + this.training._id;
      self.$router.push(url);
    },
    watchTrainingEmployee() {
      let self = this;
      const url = "/em/meus-treinamentos/" + this.training._id;
      self.$router.push(url);
    },
    goToEditCourse() {
      let self = this;
      const url = "/vc/meus-treinamentos/" + this.training._id;
      self.$router.push(url);
    },
    goToViewCourse() {
      let self = this;
      const url = "/vc/meus-treinamentos/" + this.training._id + '/visualizacao';
      self.$router.push(url);
    },
    courseDetails() {
      let self = this;
      if (this.subscribed) {
        return
      }
      if (this.type != 1) {
        let url = '';
        if (this.company == null || !this.company._id) {
          url = "/vc/treinamento/" + this.training._id;
        } else {
          url = "/co/treinamento/" + this.training._id;
        }
        self.$router.push(url);
      }
    },
    addCart(event) {
      let self = this;
      if (event.stopPropagation) event.stopPropagation();
      if (!localStorage.token) {
        self.$swal({
          icon: 'warning',
          title: 'Atenção',
          text: 'É necessário estar logado para esta funcionalidade',
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            const url = "/vc/cadastro";
            self.$router.push(url);
          }
        })
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      let cartItem = {
        course: this.training._id,
        amount: 1,
      }

      if (self.training.user._id == self.user._id) {
        self.$swal({
          icon: 'warning',
          title: 'Atenção',
          text: 'Não é possivel adicionar seu próprio treinamento.',
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            return
          }
        })
      } else {
        let continueAddingItem = true;
        console.log("local.cartList", local.cartList);
        if (local.cartList) {
          for (let cart of local.cartList) {
            if (cart.items) {
              if (cart.items.some(item => item.course._id === this.training._id)) {
                console.log("Object found inside the array.", cart.items);
                continueAddingItem = false;
              }
            }
          }
        }

        console.log('continueAddingItem 1', continueAddingItem)


        // console.log('studentList', local.studentList, this.training._id)
        for (let course of local.studentList) {
          if (course.course._id == this.training._id) {
            continueAddingItem = false;
          }
        }

        console.log('continueAddingItem 2', continueAddingItem, this.company)

        if (this.company != null && this.company._id) {
          continueAddingItem = true;
        }
        console.log('continueAddingItem 3', continueAddingItem)
        if (continueAddingItem) {
          let company = this.company;
          this.axios.post(server.url + '/api/cart/add-item', {
            item: cartItem

          }, server.config)
            .then(function (response) {
              let responseData = response.data;

              self.$swal({
                icon: "success",
                title: "Item adicionado ao carrinho!",
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: true,
                confirmButtonText: "Ver meu carrinho",
                cancelButtonText: "Continuar comprando",
                // closeOnConfirm: false,
                // closeOnConfirm: false,
              }).then((result) => {
                if (result.value) {
                  let url = '';
                  if (company == null || !company._id) {
                    url = "/vc/carrinho/";
                  } else {
                    url = "/co/carrinho/";
                  }
                  window.open(url, "_self");
                  // self.$router.push(url);
                } else {
                  location.reload();
                }
              })
              // console.log('adicionou', responseData);
            })
            .catch(function (error) {
              self.output = error;
              self.showError(error);
            });
        } else {
          self.$swal({
            icon: 'warning',
            title: 'Atenção',
            text: 'Treinamento ja esta no carrinho ou você ja possui este treinamento.',
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              // window.open("./co/vagas", "_self"); 
            }
          })
        }
      }
    },
  },
};
</script>

<style lang="scss">
.training-item-hover {
  &:hover {
    cursor: pointer;
  }
}

.training-item,
.training-item-hover {
  padding-top: 20px;

  // .swal2-actions{
  //   button{
  //     height: 55px;
  //     width: 170px;
  //   }
  // }

  .swal2-styled.swal2-confirm {
    width: 200px;
  }

  .top-box {
    border-radius: 11px;
    background-color: #e6dcdc;
    height: 170px;
    width: 100%;

    .training-image {
      width: 100%;
      max-height: 100%;
      height: 100%;
      border-radius: 11px;
      object-fit: contain;
    }
  }

  .info-box-cart {
    height: 230px !important;


  }

  .info-box,
  .info-box-cart {
    border-radius: 11px;
    padding: 20px 30px;
    height: 260px;
    margin-top: 8px;

    img {
      margin-left: -5px;
      margin-right: 3px;
    }

    .training-name {
      font-size: 18px;
      font-weight: bold;
      color: var(--main-title-color);
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .teacher {
      font-size: 13px;
      color: var(--main-title-color);
    }

    .description {
      padding-top: 10px;
      font-size: 13px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .otherInfos {
      padding-top: 5px;
      font-size: 13px;
    }

    .border {
      font-size: 13px;
      height: 34px;
      width: 82px;
      margin-top: 20px;
    }

    .border-big {
      font-size: 13px;
      height: 34px;
      width: 189px;
      bottom: 15px;
      position: absolute;
      left: 50%;
      margin-left: -94px;
    }

    .blue-big {
      font-size: 13px;
      height: 34px;
      width: 189px;
      bottom: 15px;
      position: absolute;
      left: 50%;
      margin-left: -94px;
    }


    .blue {
      height: 34px;
      font-size: 13px;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 600px) {

  .training-item,
  .training-item-hover {
    padding-top: 5px;

    .info-box,
    .info-box-cart {
      background-color: transparent;
      padding: 20px 15px;
      min-height: 230px !important;
      height: auto !important;

      .border {
        width: 100% !important;
      }

      .blue {
        width: 100%;
        margin-top: 10px !important;
      }

      .border-big {
        position: initial;
        margin-left: 0px;
      }
    }


  }

}
</style>