<template>
  <div class="terms-policy" v-bind:class="{ 'terms-policy-on-modal ': type == 'modal'}">
    <!-- <h2 style="text-align: center">Termos</h2> -->
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><strong><span style="font-size:19px;">POL&Iacute;TICA DE POL&Iacute;TICA DE PRIVACIDADE E TERMO DE USO</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>O portal VOCEVENDE&nbsp;tem ci&ecirc;ncia que a privacidade &eacute; uma das grandes preocupa&ccedil;&otilde;es da maioria dos usu&aacute;rios da internet e &eacute; fundamental para o uso proveitoso e satisfat&oacute;rio da rede. Assim, para que possamos oferecer um site melhor e mais direcionado &agrave;s suas prefer&ecirc;ncias, processar eventuais pedidos e reclama&ccedil;&otilde;es, inform&aacute;-lo sobre altera&ccedil;&otilde;es relevantes em nossos servi&ccedil;os ou relacionadas &agrave;s suas buscas, compreender suas prefer&ecirc;ncias nas escolhas efetuadas por meio do site, sugerir produtos, servi&ccedil;os e promo&ccedil;&otilde;es de acordo com seus crit&eacute;rios, de forma a tornar sua busca mais pr&oacute;xima aos seus interesses, &eacute; importante contarmos com suas informa&ccedil;&otilde;es, por isso, leia atentamente a Pol&iacute;tica de Privacidade e nosso Termo de Uso.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>A menos que nos vinculemos a outra pol&iacute;tica ou indiquemos em contr&aacute;rio, esta Pol&iacute;tica de Privacidade e Termo de Uso s&atilde;o aplicados quando o Usu&aacute;rio/Afiliado visita ou usa o site e/ou aplicativos m&oacute;veis, ou servi&ccedil;os e produtos relacionados da Vocevende.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><strong><span style="font-size:19px;">&nbsp;</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><strong><span style="font-size:19px;">Pol&iacute;tica de Privacidade</span></strong><span style="font-size:19px;">&nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Nossa pol&iacute;tica de privacidade visa assegurar e garantir que, quaisquer informa&ccedil;&otilde;es relativas aos usu&aacute;rios, ser&atilde;o fornecidas e publicadas de acordo com as funcionalidades do site, neste sentido, suas informa&ccedil;&otilde;es&nbsp;ser&atilde;o coletadas e guardadas de acordo com padr&otilde;es de seguran&ccedil;a e confidencialidade, podendo, nas condi&ccedil;&otilde;es previstas pela presente pol&iacute;tica de privacidade, ser transmitidas aos usu&aacute;rios profissionais, empresas e afiliados para promover a conex&atilde;o entre os usu&aacute;rios e empresas, conforme o objetivo do portal Vocevende.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Suas informa&ccedil;&otilde;es cadastrais ficam disponibilizadas de duas maneiras: (i) quando voc&ecirc; efetua o seu cadastro em nosso site; (ii) atrav&eacute;s de cookies, arquivos que podem ser livremente alterados pelo usu&aacute;rio na configura&ccedil;&atilde;o de seu navegador e que est&atilde;o relacionados &agrave;s suas visitas em nossos sites.</p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;font-weight:normal;'>Para criar uma conta, voc&ecirc; precisa fornecer seus dados, incluindo seu nome, endere&ccedil;o de e-mail, n&uacute;mero de celular e uma senha. Se voc&ecirc; se registrar para um servi&ccedil;o oferecido pela Vocevende, precisar&aacute; fornecer informa&ccedil;&otilde;es adicionais relativas &agrave; data de nascimento, n&uacute;mero do CPF, endere&ccedil;o e informa&ccedil;&otilde;es de seu perfil profissional. Caso adquira algum servi&ccedil;o pago, tamb&eacute;m dever&aacute; fornecer informa&ccedil;&otilde;es de pagamento (por exemplo, cart&atilde;o de cr&eacute;dito) e de cobran&ccedil;a.&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>Ao criar ou atualizar a conta, coletamos e armazenamos os dados fornecidos.</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;font-weight:normal;'>O usu&aacute;rio/afiliado tem op&ccedil;&otilde;es sobre as informa&ccedil;&otilde;es em seu perfil, como educa&ccedil;&atilde;o, experi&ecirc;ncia profissional, habilidades. As informa&ccedil;&otilde;es ajudam voc&ecirc; a obter mais de nossos servi&ccedil;os, inclusive ajudando recrutadores e oportunidades de neg&oacute;cios a encontrar o usu&aacute;rio/afiliado. &Eacute; sua escolha incluir informa&ccedil;&otilde;es confidenciais em seu perfil e torn&aacute;-las p&uacute;blicas. N&atilde;o adicione dados pessoais que voc&ecirc; n&atilde;o gostaria que estivessem dispon&iacute;veis publicamente, pois ser&aacute; de sua inteira responsabilidade a divulga&ccedil;&atilde;o destes dados confidenciais.&nbsp;</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>O perfil cadastrado<strong><span style="color:black;background:white;">&nbsp;</span></strong>&eacute; totalmente vis&iacute;vel para os demais usu&aacute;rios/afiliados de nossos servi&ccedil;os. Sujeito &agrave;s suas configura&ccedil;&otilde;es, ele tamb&eacute;m pode ser vis&iacute;vel para outras pessoas dentro ou fora de nossos Servi&ccedil;os (por exemplo, visitantes de nossos servi&ccedil;os ou usu&aacute;rios de mecanismos de pesquisa de terceiros).</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Se voc&ecirc; &eacute; um instrutor, armazenamos seu conte&uacute;do educacional, que pode conter dados sobre voc&ecirc;. Todos os dados publicados s&atilde;o de responsabilidades &uacute;nica e exclusiva do usu&aacute;rio e/ou afiliado que postou, podendo responder por danos a terceiros, isentando o site Vocevende de total responsabilidade pelo seu conte&uacute;do.<span style="color:red;">&nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Quando o usu&aacute;rio acessa o conte&uacute;do, coletamos alguns dados, inclusive vagas buscadas, produtos a serem vendidos, o conte&uacute;do dos servi&ccedil;os oferecidos, empresas vinculadas aos servi&ccedil;os e produtos apresentados.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Quando o usu&aacute;rio &eacute; Hunter Afiliado, Profissional Premium ou Empresarial, coletamos alguns dados sobre a compra em quest&atilde;o para processar o pedido. Cabe ao usu&aacute;rio fornecer determinados dados sobre pagamento e fatura diretamente aos nossos parceiros de processamento de pagamentos, entre eles nome do usu&aacute;rio, informa&ccedil;&otilde;es sobre o cart&atilde;o de cr&eacute;dito, endere&ccedil;o de faturamento e CEP. Tamb&eacute;m podemos receber informa&ccedil;&otilde;es limitada, como o fato que voc&ecirc; tem um novo cart&atilde;o e os &uacute;ltimos quatro d&iacute;gitos desse cart&atilde;o, dos parceiros para facilitar os pagamentos.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Tendo em vista que os planos de assinatura s&atilde;o recorrentes, o site Vocevende coleta e armazena dados do titular do cart&atilde;o atrav&eacute;s da operadora de pagamento contratada, tais como o n&uacute;mero completo do cart&atilde;o de cr&eacute;dito ou os dados de autentica&ccedil;&atilde;o do cart&atilde;o.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Se voc&ecirc; &eacute; um usu&aacute;rio e/ou afiliado que est&aacute; vendendo seus servi&ccedil;os e produtos por meio da plataforma Vocevende (Minha loja online), poder&aacute; vincular sua conta de pagamento &agrave; sua conta corrente de sua titularidade em alguma institui&ccedil;&atilde;o banc&aacute;ria. Quando voc&ecirc; vincular uma conta de pagamento, coletaremos e usaremos algumas informa&ccedil;&otilde;es, incluindo o endere&ccedil;o de e-mail da conta de pagamento, o ID da conta, o endere&ccedil;o f&iacute;sico ou outros dados necess&aacute;rios para que possamos enviar os pagamentos para sua conta. Em alguns casos, coletaremos informa&ccedil;&otilde;es de transfer&ecirc;ncia banc&aacute;ria ou eletr&ocirc;nica para enviar pagamentos &agrave; sua conta. Para cumprir as leis aplic&aacute;veis, tamb&eacute;m trabalharemos com terceiros de confian&ccedil;a que coletam informa&ccedil;&otilde;es fiscais conforme legalmente exigido. Essas informa&ccedil;&otilde;es fiscais podem incluir informa&ccedil;&otilde;es sobre sua resid&ecirc;ncia e outras informa&ccedil;&otilde;es pessoais necess&aacute;rias para fins de tributa&ccedil;&atilde;o. O<span style="color:red;">&nbsp;</span>Vocevende coleta e armazena informa&ccedil;&otilde;es confidenciais de contas banc&aacute;rias. No entanto, a coleta, o uso e a divulga&ccedil;&atilde;o dos seus dados de pagamento, faturamento e tributa&ccedil;&atilde;o est&atilde;o sujeitos &agrave; pol&iacute;tica de privacidade e a outros termos do provedor da sua conta de pagamento</p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;font-weight:normal;'>A forma como usamos seus dados pessoais depender&aacute; de quais servi&ccedil;os voc&ecirc; estar&aacute; usando, como voc&ecirc; usa esses servi&ccedil;os e as escolhas que voc&ecirc; faz em suas configura&ccedil;&otilde;es. Usamos os dados que temos sobre voc&ecirc; para fornecer e personalizar nossos Servi&ccedil;os, inclusive com a ajuda de sistemas automatizados e infer&ecirc;ncias que fazemos, para que nossos Servi&ccedil;os (incluindo an&uacute;ncios) possam ser mais relevantes e &uacute;teis para voc&ecirc; e outras pessoas.</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;font-weight:normal;'>Usamos seus dados para autorizar o acesso aos nossos servi&ccedil;os e respeitar suas configura&ccedil;&otilde;es.</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Nossos servi&ccedil;os permitem que voc&ecirc; fique em contato e atualizado com parceiros, busque potenciais clientes e outros contatos profissionais para fazer neg&oacute;cios.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>&Eacute; sua escolha convidar ou indicar algu&eacute;m para nossos servi&ccedil;os.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Nossos servi&ccedil;os permitem que voc&ecirc; explore carreiras, avalie oportunidades de treinamento e busque e seja encontrado em oportunidades de carreira. O seu perfil pode ser encontrado por quem pretende contratar para uma vaga de emprego espec&iacute;fica ou busca um representante para seus produtos, um treinador para sua empresa ou para oportunidades de neg&oacute;cios.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Oferecemos planos pagos que fornecem aos nossos usu&aacute;rios cadastrados funcionalidades e ferramentas personalizadas como parte de nossas solu&ccedil;&otilde;es de talentos, recoloca&ccedil;&atilde;o, treinamentos e vendas. Forneceremos informa&ccedil;&otilde;es de contato aos usu&aacute;rios e/ou afiliados como parte desses servi&ccedil;os pagos, quando necess&aacute;rio com seu consentimento definidos para fins de contatos e indica&ccedil;&atilde;o<span style="color:red;">.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Caso o usu&aacute;rio acesse ou use nossos Servi&ccedil;os por meio de uma plataforma ou servi&ccedil;o de terceiros ou clique em links de terceiros, a coleta, o uso e o compartilhamento dos dados estar&atilde;o tamb&eacute;m sujeitos &agrave;s pol&iacute;ticas de privacidade e outros acordos dos respectivos terceiros.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Os dados listados acima s&atilde;o armazenados pelo Vocevende e associados &agrave; conta do usu&aacute;rio.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><strong><span style="font-size:16px;">Comunica&ccedil;&otilde;es e suporte</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>O Vocevende disponibiliza a todos os seus usu&aacute;rios e/ou afiliados um meio de contato seguro atrav&eacute;s do canal Fale Conosco em nosso site.&nbsp;Caso o usu&aacute;rio entre em contato conosco para obter suporte ou relatar um problema ou d&uacute;vida, coletamos e armazenamos as informa&ccedil;&otilde;es de contato do usu&aacute;rio, bem como mensagens e outros dados sobre o usu&aacute;rio, tais como nome, endere&ccedil;o de e-mail, mensagens, localiza&ccedil;&atilde;o, ID do usu&aacute;rio no Vocevende. Esses dados ser&atilde;o usados para responder ao usu&aacute;rio sobre a d&uacute;vida apresentada, de acordo com esta Pol&iacute;tica de Privacidade.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>N&atilde;o nos responsabilizamos pelo tr&aacute;fego de informa&ccedil;&otilde;es confidenciais, inclusive usu&aacute;rio e senha de acesso aos servi&ccedil;os contratados, se o usu&aacute;rio ou afiliado insistir em enviar estes dados.&nbsp;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>O suporte t&eacute;cnico nos dias &uacute;teis (segunda-feira a sexta-feira), no hor&aacute;rio das 08:00h &agrave;s 17:00h por meio de mensagem no formul&aacute;rio Fale Conosco. O hor&aacute;rio mencionado nesta cl&aacute;usula refere-se ao Hor&aacute;rio de Bras&iacute;lia.&nbsp;&nbsp;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>&nbsp;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><strong><span style="font-size:16px;">Finalidade dos dados dos usu&aacute;rios</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Usamos os dados do usu&aacute;rio e/ou afiliado, dentre outros fins, para:</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Fornecer, administrar, melhorar e atualizar nossos servi&ccedil;os, bem como, desenvolver novos produtos, servi&ccedil;os e recursos;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Responder &agrave;s perguntas e d&uacute;vida do usu&aacute;rio e/ou afiliado;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Analisar como as pessoas usam nossos servi&ccedil;os;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Promover os servi&ccedil;os e produtos do usu&aacute;rio e/ou afiliado;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>-Facilitar a comunica&ccedil;&atilde;o com outros usu&aacute;rios e/ou afiliados, veicular publicidade personalizada, conforme exigido por lei ou necess&aacute;rio para a seguran&ccedil;a e a integridade;</p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>- Recomendar vagas de empregos ao usu&aacute;rio e/ou afiliado;</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Processar pagamentos;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Autenticar o usu&aacute;rio cadastrado e o usu&aacute;rio e/ou afiliado assinante de planos para acesso ao conte&uacute;do personalizado;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Comunicar o usu&aacute;rio e/ou afiliado sobre altera&ccedil;&otilde;es em nossos servi&ccedil;os e atualiza&ccedil;&otilde;es de nossos contratos;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Enviar informa&ccedil;&otilde;es ao usu&aacute;rio, por e-mail, WhatsApp, ou outros meios de mensagem eletr&ocirc;nica, sobre o progresso dele na plataforma, programas de pr&ecirc;mios, novos servi&ccedil;os, novos recursos, promo&ccedil;&otilde;es, boletins informativos e outro conte&uacute;do dispon&iacute;vel no site (que, a qualquer momento, o usu&aacute;rio e/ou afiliado pode optar por n&atilde;o receber);</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Gerenciar a conta e as prefer&ecirc;ncias da conta do usu&aacute;rio e/ou afiliado;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Facilitar a opera&ccedil;&atilde;o t&eacute;cnica dos servi&ccedil;os, inclusive solu&ccedil;&atilde;o de problemas, prote&ccedil;&atilde;o dos servi&ccedil;os e preven&ccedil;&atilde;o de fraudes e abuso;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Solicitar feedback dos usu&aacute;rios e/ou afiliados;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Saber mais sobre o usu&aacute;rio e/ou afiliado, vinculando os dados dele a outros dados por meio de provedores de dados terceirizados e/ou analisando os dados com a ajuda de provedores de servi&ccedil;os de an&aacute;lise;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Identificar usu&aacute;rios e/ou afiliados &uacute;nicos entre dispositivos;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Personalizar an&uacute;ncios entre dispositivos;</p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>- Fornecer conte&uacute;do e recomenda&ccedil;&otilde;es para ajudar a tornar nossos servi&ccedil;os mais relevantes para nossos usu&aacute;rios e/ou afiliados, visitantes e clientes.&nbsp;</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Analisar tend&ecirc;ncias e tr&aacute;fego, rastrear compras e dados sobre utiliza&ccedil;&atilde;o;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>- Anunciar os servi&ccedil;os em sites e aplicativos de terceiros, conforme permitido por lei.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><strong>Manter o perfil do&nbsp;usu&aacute;rio e/ou afiliado</strong><strong>&nbsp;preciso e atualizado pode ajud&aacute;-lo a se conectar melhor a outras pessoas e a oportunidades por meio de nossos servi&ccedil;os.</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>O usu&aacute;rio e/ou afiliado possui certos direitos quanto ao uso de seus dados, inclusive a possibilidade de optar por n&atilde;o receber mensagens em e-mails e WhatsApp, cookies e a coleta de dados por determinados terceiros. O usu&aacute;rio poder&aacute; atualizar ou encerrar sua conta em nosso site a qualquer momento.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Para atualizar dados fornecidos diretamente, o usu&aacute;rio e/ou afiliado deve se conectar &agrave; sua conta e atualiz&aacute;-la a qualquer momento.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Para encerrar a conta, o usu&aacute;rio deve acessar a p&aacute;gina do perfil e no item cadastro clicar em excluir.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Observa&ccedil;&atilde;o: mesmo depois de encerrada a conta, alguns ou todos os dados do usu&aacute;rio e/ou afiliado ainda ficar&atilde;o vis&iacute;veis para outras pessoas, inclusive, entre outros, dados que tenham sido (a) copiados, armazenados ou disseminados por outros usu&aacute;rios; (b) compartilhados ou disseminados pelo pr&oacute;prio usu&aacute;rio e/ou afiliado ou por outras pessoas (inclusive no conte&uacute;do compartilhado do usu&aacute;rio); ou (c) publicados em uma plataforma de terceiros. Mesmo depois de encerrada a conta, reteremos os dados do usu&aacute;rio enquanto tivermos um prop&oacute;sito leg&iacute;timo para assim o fazer (e de acordo com a lei aplic&aacute;vel), inclusive para ajudar em obriga&ccedil;&otilde;es legais, resolver conflitos e fazer cumprir nossos contratos. Poderemos reter e divulgar esses dados de acordo com esta Pol&iacute;tica de Privacidade depois do encerramento da conta do usu&aacute;rio.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Em caso de d&uacute;vidas ou caso tenha interesse em verificar a corre&ccedil;&atilde;o de seus dados ou estiver com problemas para encerrar sua conta, entre em contato com Vocevende por meio do servi&ccedil;o Fale Conosco, dispon&iacute;vel no site.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><span style="font-size:16px;">&nbsp;<strong>Compartilhamento de informa&ccedil;&otilde;es</strong></span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Como j&aacute; informamos, para fins de seguran&ccedil;a, as informa&ccedil;&otilde;es poder&atilde;o ser compartilhadas quando houver requerimento de autoridades judiciais ou governamentais competentes para fins de investiga&ccedil;&otilde;es pessoais conduzidas por elas, mesmo que n&atilde;o exista uma ordem judicial, por exemplo (e sem limitar-se a) quando se tratar de investiga&ccedil;&atilde;o de car&aacute;ter penal ou a viola&ccedil;&atilde;o de direitos autorais, ressalvadas as hip&oacute;teses de sigilo de informa&ccedil;&otilde;es determinadas por leis em vigor, ou ainda, comunicar informa&ccedil;&otilde;es quando haja motivos suficientes para considerar que a atividade de um usu&aacute;rio/afiliado seja suspeita de tentar ou de cometer um delito ou tentar prejudicar outras pessoas. Este direito ser&aacute; utilizado pelo Vocevende a sua inteira discri&ccedil;&atilde;o quando o considere apropriado ou necess&aacute;rio para manter a integridade e a seguran&ccedil;a da comunidade e de seus usu&aacute;rios e/ou afiliados e funcion&aacute;rios ou nossos servi&ccedil;os.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Poderemos tamb&eacute;m divulgar dados sobre o usu&aacute;rio e/ou afiliado para nossos auditores e consultores jur&iacute;dicos, a fim de avaliar nossas obriga&ccedil;&otilde;es e direitos de divulga&ccedil;&atilde;o ao abrigo desta Pol&iacute;tica de Privacidade.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Se o Vocevende se submeter a uma transa&ccedil;&atilde;o comercial, tal como uma fus&atilde;o, aquisi&ccedil;&atilde;o, aliena&ccedil;&atilde;o corporativa ou dissolu&ccedil;&atilde;o (inclusive fal&ecirc;ncia), ou uma venda parcial ou total de ativos, poderemos compartilhar, divulgar ou transferir todos os dados do usu&aacute;rio e/ou afiliado para a organiza&ccedil;&atilde;o sucessora, durante a referida transi&ccedil;&atilde;o, ou na contempla&ccedil;&atilde;o de uma transi&ccedil;&atilde;o (inclusive durante uma auditoria).</p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>Podemos combinar informa&ccedil;&otilde;es internamente entre os diferentes servi&ccedil;os cobertos por esta Pol&iacute;tica de Privacidade para ajudar nossos servi&ccedil;os a serem mais relevantes e &uacute;teis para voc&ecirc; e outras pessoas.&nbsp;</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Com consentimento do usu&aacute;rio, poderemos compartilhar dados com terceiros fora do escopo desta Pol&iacute;tica de Privacidade.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Aten&ccedil;&atilde;o: O Vocevende pode ter links de acesso para outros sites e que n&atilde;o est&atilde;o cobertos por esta pol&iacute;tica. Assim, verifique a pol&iacute;tica de privacidade desses sites, tendo em vista que suas informa&ccedil;&otilde;es ser&atilde;o administradas de acordo com a pol&iacute;tica de cada um deles e que podem diferenciar das nossas.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><strong><span style="font-size:16px;">Cookies</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Os Cookies s&atilde;o informa&ccedil;&otilde;es enviadas pelo servidor do Vocevende ao computador do usu&aacute;rio e/ou afiliado, que auxilia na identifica&ccedil;&atilde;o de seu IP e na facilidade e efici&ecirc;ncia de sua navega&ccedil;&atilde;o, simplificando o login de usu&aacute;rios ou afiliados cadastrados, auxiliando na garantia de seguran&ccedil;a e autenticidade dos acessos, bem como para controle interno de audi&ecirc;ncia e de prefer&ecirc;ncias. A aceita&ccedil;&atilde;o ou n&atilde;o dos cookies pode ser livremente alterada na configura&ccedil;&atilde;o de seu navegador. No entanto, quando o usu&aacute;rio e/ou afiliado desrespeitar alguma regra de seguran&ccedil;a ou exercer alguma atividade prejudicial ao bom funcionamento do site, como por exemplo, tentativas de hackear o servi&ccedil;o, o Vocevende, para prote&ccedil;&atilde;o e seguran&ccedil;a do site e dos demais usu&aacute;rios e/ou afiliados, pode adotar medidas para identificar o usu&aacute;rio e coibir tais condutas il&iacute;citas.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>O menu de ajuda na barra de seu navegador do seu computador poder&aacute; auxili&aacute;-lo a evitar a inser&ccedil;&atilde;o de novos cookies ou desativ&aacute;-los.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><strong>Seguran&ccedil;a das Informa&ccedil;&otilde;es</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Todos os dados pessoais informados ao nosso site s&atilde;o armazenados em um banco de dados reservado e com acesso restrito &agrave;s pessoas habilitadas, que s&atilde;o obrigadas, por contrato, a manter a confidencialidade das informa&ccedil;&otilde;es e n&atilde;o as utilizar inadequadamente.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>O Vocevende adota medidas de seguran&ccedil;a adequadas para se proteger contra acesso n&atilde;o autorizado, altera&ccedil;&atilde;o, divulga&ccedil;&atilde;o ou destrui&ccedil;&atilde;o dos dados pessoais do usu&aacute;rio por n&oacute;s coletados e armazenados. Essas medidas variam com base no tipo e na confidencialidade dos dados. Infelizmente, nenhum sistema pode ser 100% protegido. Por isso, n&atilde;o podemos garantir que as comunica&ccedil;&otilde;es entre o usu&aacute;rio e/ou afiliado e o Vocevende, ou qualquer informa&ccedil;&atilde;o fornecida ao Vocevende em rela&ccedil;&atilde;o aos dados por n&oacute;s coletados estejam livres de acesso n&atilde;o autorizado por terceiros. A senha do usu&aacute;rio e/ou afiliado &eacute; uma parte importante do nosso sistema de seguran&ccedil;a, e &eacute; responsabilidade do usu&aacute;rio proteg&ecirc;-la. N&atilde;o compartilhe a senha com terceiros. Em caso de suspeita de viola&ccedil;&atilde;o da senha ou conta, altere-a imediatamente e entre em contato com Fale Conosco, dispon&iacute;vel no site, para sanar a situa&ccedil;&atilde;o.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><strong><span style="font-size:16px;">Atualiza&ccedil;&otilde;es e Modifica&ccedil;&otilde;es nesta Pol&iacute;tica de Privacidade</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>De tempos em tempos, poderemos atualizar esta Pol&iacute;tica de Privacidade. Em caso de altera&ccedil;&otilde;es significativas, notificaremos o usu&aacute;rio por e-mail, notifica&ccedil;&atilde;o publicada no site ou conforme exigido pela lei aplic&aacute;vel. Incluiremos tamb&eacute;m um resumo das principais altera&ccedil;&otilde;es. Salvo indica&ccedil;&atilde;o em contr&aacute;rio, as modifica&ccedil;&otilde;es entrar&atilde;o em vigor no dia em que forem publicadas.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Conforme permitido pela lei aplic&aacute;vel, ao continuar a usar os Servi&ccedil;os ap&oacute;s a data de vig&ecirc;ncia de qualquer altera&ccedil;&atilde;o, o acesso e/ou uso, por parte do usu&aacute;rio, ser&atilde;o considerados uma aceita&ccedil;&atilde;o da Pol&iacute;tica de Privacidade revisada (e concord&acirc;ncia em seguir e vincular-se a ela). A Pol&iacute;tica de Privacidade revista substitui todas as Pol&iacute;ticas de Privacidade anteriores.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>&nbsp;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Importante:</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Ao usar os servi&ccedil;os oferecidos pelo site Vocevende, o usu&aacute;rio est&aacute; se envolvendo diretamente com um provedor de servi&ccedil;os terceirizado. Recomendamos que o usu&aacute;rio revise a Pol&iacute;tica de Privacidade e os Termos de Uso do provedor de servi&ccedil;os antes de usar esse servi&ccedil;o de terceiros. Voc&ecirc; reconhece que n&atilde;o somos respons&aacute;veis por qualquer perda ou dano resultante do uso de tais servi&ccedil;os de terceiros, incluindo qualquer responsabilidade decorrente de quaisquer dados pessoais trocados entre voc&ecirc; e o terceiro.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>Ao usar os Servi&ccedil;os, o usu&aacute;rio e/ou afiliado concorda com os termos desta Pol&iacute;tica de Privacidade. N&atilde;o use os Servi&ccedil;os caso n&atilde;o concorde com esta Pol&iacute;tica de Privacidade ou qualquer outro acordo que reja o uso dos Servi&ccedil;os por parte do usu&aacute;rio.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><strong><span style="font-size:19px;">Termo de uso</span></strong><span style="font-size:19px;">&nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>O portal <a href="http://www.vocevende.com">www.vocevende.com</a> (&ldquo;Vocevende&rdquo;) opera como uma&nbsp;<span style="color:black;">plataforma tecnol&oacute;gica voltada &agrave; intermedia&ccedil;&atilde;o</span> e divulga&ccedil;&atilde;o de neg&oacute;cios e vagas de emprego, e tem a responsabilidade de proteger cada usu&aacute;rio e/ou afiliado e lhes proporcionar os melhores servi&ccedil;os poss&iacute;veis. Desta forma desenvolvemos as seguintes diretrizes para assegurar a qualidade de nossos servi&ccedil;os.&nbsp;</p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>Ao clicar em &ldquo;Cadastrar&rdquo;, acessar ou usar nossos servi&ccedil;os, o usu&aacute;rio e/ou afiliado estar&aacute; concordando com as diretrizes, condi&ccedil;&otilde;es e pol&iacute;tica de privacidades estipuladas pelo Vocevende.&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>A ACEITA&Ccedil;&Atilde;O DOS TERMOS DE USO &Eacute; ABSOLUTAMENTE INDIPENS&Aacute;VEL &Agrave; UTILIZA&Ccedil;&Atilde;O DO WEBSITE E SEUS SERVI&Ccedil;OS</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>.</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>O Usu&aacute;rio e/ou Afiliado dever&aacute; ler, certificar-se de haver entendido e aceitar todas as condi&ccedil;&otilde;es estabelecidas neste Termo de Uso, assim como nos demais documentos incorporados a este por refer&ecirc;ncia, antes de efetuar seu cadastro no Vocevende.</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>Importante destacar que a qualquer momento o usu&aacute;rio e/ou afiliado poder&aacute; excluir sua conta e n&atilde;o acessando mais ou usando nossos servi&ccedil;os.</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>1. Objeto</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>1.1 Este Termo de Uso se aplica ao Vocevende e demais aplicativos, comunica&ccedil;&otilde;es e todos servi&ccedil;os relacionados ao Vocevende, incluindo a coleta externa de dados para esses servi&ccedil;os, como nossos an&uacute;ncios e os plug-ins. Os usu&aacute;rios registrados em nossos servi&ccedil;os s&atilde;o &ldquo;Cadastrados&rdquo;, &ldquo;Afiliados&rdquo; ou &ldquo;Treinadores&rdquo; e os usu&aacute;rios n&atilde;o registrados s&atilde;o &ldquo;Visitantes&rdquo;.</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>1.2 Podemos modificar este Termo de Uso e nossa Pol&iacute;tica de Privacidade de tempos em tempos. Se fizermos altera&ccedil;&otilde;es materiais nele, iremos avis&aacute;-lo por meio de nosso portal, aplicativos, ou por outros meios, para lhe dar a oportunidade de revisar as altera&ccedil;&otilde;es antes de entrarem em vigor. Concordamos que as altera&ccedil;&otilde;es n&atilde;o podem ser retroativas. Se voc&ecirc; se opor a quaisquer altera&ccedil;&otilde;es, voc&ecirc; pode encerrar sua conta. Seu uso continuado de nossos servi&ccedil;os ap&oacute;s publicar ou enviar um aviso sobre nossas altera&ccedil;&otilde;es a este Termo de Uso significa que voc&ecirc; concorda com os termos atualizados a partir de sua data de vig&ecirc;ncia.</span></p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;'>2. Cadastro</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.1 Os servi&ccedil;os do portal s&oacute; ser&atilde;o prestados a usu&aacute;rios cadastrados. Para se cadastrar, o usu&aacute;rio e/ou afiliado dever&aacute; fornecer seus Dados Pessoais.</span></p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;font-weight:normal;'>2.2 Para criar uma conta, o&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>usu&aacute;rio e/ou afiliado</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;font-weight:normal;'>precisa fornecer dados, incluindo seu nome (pessoa f&iacute;sica ou jur&iacute;dica), endere&ccedil;o de e-mail e/ou n&uacute;mero de celular e uma senha. Caso o usu&aacute;rio se registrar para um servi&ccedil;o pago, precisar&aacute; fornecer informa&ccedil;&otilde;es de pagamento (por exemplo, cart&atilde;o de cr&eacute;dito) e de cobran&ccedil;a.</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.3 O Vocevende reserva-se os direitos de: (i) exigir todos os Dados Pessoais do usu&aacute;rio e/ou afiliado que julgar necess&aacute;rios para seu cadastro, respeitada a legisla&ccedil;&atilde;o vigente; e (ii) utilizar todos os meios v&aacute;lidos e poss&iacute;veis para identificar seus Usu&aacute;rios, bem como solicitar dados adicionais e documentos que estime serem pertinentes a fim de conferir os Dados Pessoais informados.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.4 O cadastro do usu&aacute;rio e/ou afiliado somente ser&aacute; confirmado ap&oacute;s o preenchimento de todos os campos obrigat&oacute;rios. O usu&aacute;rio e/ou afiliado dever&aacute; completar os campos com informa&ccedil;&otilde;es exatas, precisas e verdadeiras, assumindo o compromisso de atualizar os Dados Pessoais sempre que neles ocorrer alguma altera&ccedil;&atilde;o.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.5 Os usu&aacute;rios e/ou afiliados ser&atilde;o &uacute;nica e exclusivamente respons&aacute;veis pela exatid&atilde;o, veracidade e autenticidade dos Dados Pessoais cadastrados. O Vocevende n&atilde;o se responsabilizar&aacute; em hip&oacute;tese alguma pela atualiza&ccedil;&atilde;o e/ou corre&ccedil;&atilde;o de tais Dados Pessoais inseridos pelos pr&oacute;prios usu&aacute;rios e/ou afiliados.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.6 Para se cadastrar, o usu&aacute;rio e/ou afiliado precisa ser maior de 18 anos de idade e estar no pleno exerc&iacute;cio da capacidade civil. Caso a Vocevende tome conhecimento, por qualquer meio, de que o usu&aacute;rio e/ou afiliado esteja utilizando a plataforma em viola&ccedil;&atilde;o &agrave; restri&ccedil;&atilde;o da faixa et&aacute;ria acima referida, o Vocevende se reserva o direito de interromper imediata e definitivamente o acesso de tal usu&aacute;rio e/ou afiliado, sem pr&eacute;via notifica&ccedil;&atilde;o.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.7 &Eacute; permitido o cadastro de pessoa jur&iacute;dica apenas mediante representante legal.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.8 Caso a Vocevende constate, ap&oacute;s verifica&ccedil;&atilde;o da veracidade dos Dados Pessoais de um usu&aacute;rio e/ou afiliado, que houve inser&ccedil;&atilde;o de dados incorretos ou inver&iacute;dicos, ou ainda, caso o usu&aacute;rio e/ou afiliado se furte ou negue a enviar documentos eventualmente requeridos, o Vocevende poder&aacute;: (i) bloque&aacute;-lo para efetuar transa&ccedil;&otilde;es; e (ii) suspender temporariamente ou cancelar definitivamente o cadastro do usu&aacute;rio e/ou afiliado, sem preju&iacute;zo de outras medidas que entender necess&aacute;rias e oportunas.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.9 O usu&aacute;rio e/ou afiliado que se cadastrar utilizando dados de terceiros poder&aacute; incorrer em pr&aacute;tica de crimes, sem preju&iacute;zo de eventual responsabiliza&ccedil;&atilde;o nos termos da legisla&ccedil;&atilde;o espec&iacute;fica.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.10 O acesso &agrave; conta do usu&aacute;rio e/ou afiliado somente ser&aacute; permitido mediante inser&ccedil;&atilde;o de e-mail e senha. O usu&aacute;rio e/ou afiliado ser&aacute; &uacute;nica e exclusivamente respons&aacute;vel pelo uso de seu login e senha, assim como, compromete-se n&atilde;o divulg&aacute;-los a terceiros.&nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.11 O usu&aacute;rio e/ou afiliado ser&aacute; o &uacute;nico respons&aacute;vel pelas opera&ccedil;&otilde;es efetuadas em sua conta, uma vez que o acesso &agrave; conta s&oacute; ser&aacute; poss&iacute;vel mediante a aposi&ccedil;&atilde;o da senha, cujo conhecimento &eacute; exclusivo do usu&aacute;rio e/ou afiliado.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.12 O usu&aacute;rio e/ou afiliado compromete-se a notificar o Vocevende imediatamente, e atrav&eacute;s de meio seguro, a respeito de qualquer uso n&atilde;o autorizado de sua conta, bem como o acesso n&atilde;o autorizado por terceiros &agrave;quela.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.13 Em nenhuma hip&oacute;tese ser&aacute; permitida a cess&atilde;o, venda, aluguel ou outra forma de transfer&ecirc;ncia da conta. Tampouco ser&aacute; permitida a cria&ccedil;&atilde;o de novos cadastros por pessoas cujos cadastros originais tenham sido cancelados por infra&ccedil;&otilde;es &agrave;s pol&iacute;ticas da Vocevende.</span></p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>2.14 O Usu&aacute;rio e/ou afiliado &eacute; respons&aacute;vel por todos os atos realizados com sua identifica&ccedil;&atilde;o e senha de acesso, incluindo, mas n&atilde;o se limitando a: navega&ccedil;&atilde;o para busca de produtos, comunica&ccedil;&atilde;o com o portal, efetiva&ccedil;&atilde;o de pagamentos de planos.</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.15 Embora possamos editar e fazer altera&ccedil;&otilde;es de formato em seu conte&uacute;do (transcrev&ecirc;-lo,&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>revisar frases ou textos ortograficamente incorretos,&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>modificar o tamanho, layout ou tipo de arquivo,&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>incluir ou excluir fotos, que possam danificar a imagem do portal ao p&uacute;blico em geral</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>), n&atilde;o modificaremos os dados do usu&aacute;rio/afiliado.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.16 O usu&aacute;rio e/ou afiliado concorda que Vocevende pode acessar, armazenar, processar e usar quaisquer informa&ccedil;&otilde;es e dados pessoais fornecidos, de acordo com os termos da Pol&iacute;tica de Privacidade.</span></p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>2.17 A plataforma Vocevende utiliza as informa&ccedil;&otilde;es e dados que seus usu&aacute;rios e/ou afiliados fornecem para fazer recomenda&ccedil;&otilde;es de conex&otilde;es, produtos, servi&ccedil;os, conte&uacute;do, oportunidades e recursos que podem ser &uacute;teis para usu&aacute;rios e/ou afiliados. Manter seu perfil cadastrado preciso e atualizado nos ajuda a tornar essas recomenda&ccedil;&otilde;es mais precisas e relevantes.</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>2.18 O Vocevende se reserva o direito de incluir ou excluir vagas, curr&iacute;culos, produtos, v&iacute;deos aulas a seu crit&eacute;rio quando pertinente.</span></p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;font-weight:normal;'>&nbsp;</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><strong>3. Perfil&nbsp;</strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>3.1 Perfil Profissional&nbsp;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>3.1.1&nbsp;Os usu&aacute;rios/afiliados que cadastram seu perfil e curr&iacute;culo no Plano Profissional, para os servi&ccedil;os de busca emprego na &aacute;rea comercial ou representa&ccedil;&atilde;o de uma empresa (representar e vender produto de uma empresa), no Plano Gratuito ou Plano Profissional Premium (pago)</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>3.1.2&nbsp;Os usu&aacute;rios/afiliados que cadastram seu perfil para anunciar na plataforma do Vocevende cursos e treinamentos para uma empresa exclusiva e seus colaboradores.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>3.1.3 O Profissional ao se cadastrar se torna afiliado e recebe um link de afiliado que ao enviar a quem se cadastrar, receber&aacute; um pr&ecirc;mio pela indica&ccedil;&atilde;o dos servi&ccedil;os oferecidos pelo Vocevende, que poder&aacute; escalonado de acordo com plano adquirido.</p>
    <p style='margin-right:0cm;margin-left:35.4pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>3.1.3.1&nbsp;Os usu&aacute;rios/afiliados que se cadastrarem no servi&ccedil;o pago de Afiliado Hunter pode vir a receber pr&ecirc;mios mais atrativos, pela indica&ccedil;&atilde;o dos servi&ccedil;os da plataforma Vocevende, a crit&eacute;rio do plano contrato.&nbsp;</p>
    <p style='margin-right:0cm;margin-left:35.4pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>3.1.3.2 Ser&atilde;o considerados usu&aacute;rios indicados somente aqueles que tiverem se cadastrado no portal Vocevende mediante o acesso do link enviado por determinado Usu&aacute;rio. Exemplificadamente: se 2 (dois) Usu&aacute;rios diferentes tiverem enviado um link para que terceiro se cadastre no site, esse terceiro ser&aacute; o Usu&aacute;rio Indicado daquele Usu&aacute;rio que lhe enviou o link por meio do qual tal Usu&aacute;rio Indicado efetivamente se cadastrou no site.</span></p>
    <p style='margin-right:0cm;margin-left:35.4pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>3.1.3.3 Os valores dos pr&ecirc;mios ser&atilde;o disponibilizados na plataforma no prazo de at&eacute; 120 (cento e vinte) dias, a contar da data do efetivo recebimento do valor referente &agrave; aquisi&ccedil;&atilde;o do plano contratado pelo indicado do usu&aacute;rio e/ou afiliado, sempre descontando impostos e custos diretos e indiretos a crit&eacute;rio do Vocevende. &nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>3.2 Perfil Empresa&nbsp;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>3.2.1&nbsp;Os usu&aacute;rios que cadastram seu perfil Empresa para anunciar vagas na &aacute;rea comercial e/ou divulga&ccedil;&atilde;o produtos e servi&ccedil;os (dados do produto - pre&ccedil;o de venda e comiss&atilde;o do representante) por meio de representantes ou vendedores no Plano Empresarial Gratuito ou Plano Empresarial Licen&ccedil;as (pago);</p>
    <p style='margin-right:0cm;margin-left:35.25pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>3.2.1.1 Os&nbsp;<span style="color:black;background:white;">vendedores e representantes poder&atilde;o ter uma loja virtual com os produtos representados para divulgar e obter clientes para a empresa de acordo com plano adquirido.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>3.2.2&nbsp;Os usu&aacute;rios que cadastram seu perfil Empresa para se candidatar como representante ou vendedor, no Plano Empresarial Gratuito ou Plano Empresarial Licen&ccedil;as (pago)&nbsp;</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>3.2.3&nbsp;Os usu&aacute;rios que se cadastram nos Servi&ccedil;os do Plano Empresarial Licen&ccedil;as, adquirem Licen&ccedil;as para que os colaboradores possam utilizar um Treinamento Exclusivo para empresa.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>4. Servi&ccedil;os</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>Nossos servi&ccedil;os permitem que o usu&aacute;rio e/ou afiliado colabore com colegas, busque potenciais clientes, parceiros e outros com quem fazer neg&oacute;cios, assim como, permitem que o usu&aacute;rio e/ou afiliado possa buscar vagas de acordo com o seu perfil profissional, enviar curr&iacute;culos via nossa plataforma, explorar oportunidades de carreiras, avalie oportunidades de treinamentos. O seu perfil pode ser encontrado por quem pretende contratar (para uma fun&ccedil;&atilde;o comercial).</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>4.1 Para usar os servi&ccedil;os,&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rio e/ou afiliado</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;concorda que: (1) ter a idade m&iacute;nima de 18 (dezoito) anos ou mais; (2) cadastrar com seus dados verdadeiros.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style="color:black;">4.2 Os servi&ccedil;os oferecidos nos planos pagos fornecem aos nossos usu&aacute;rios e/ou afiliados<strong>&nbsp;</strong>funcionalidades e ferramentas personalizadas como parte de nossas solu&ccedil;&otilde;es de talentos, marketing e vendas.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style="color:black;">4.3 Ao adquirir qualquer um dos nossos servi&ccedil;os&nbsp;</span><span style="color:black;">oferecidos nos planos pa</span><span style="color:black;">gos, o&nbsp;</span><span style="color:black;">usu&aacute;rio e/ou afiliado</span><span style="color:black;">&nbsp;concorda em pagar os valores aplic&aacute;veis e os termos adicionais espec&iacute;ficos dos servi&ccedil;os pagos. O n&atilde;o pagamento desses valores resultar&aacute; no cancelamento de seus servi&ccedil;os pagos&nbsp;</span><span style="color:black;">(Plano Profissional Premium, Plano Empresarial licen&ccedil;as e Afiliado Hunter)</span><span style="color:black;">.</span><span style="color:black;">&nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style="color:black;">4.4 Ao adquirir servi&ccedil;os pagos&nbsp;</span><span style="color:black;">(Plano Profissional Premium, Plano Empresarial licen&ccedil;as e Afiliado Hunter), o usu&aacute;rio e/ou afiliado</span><span style="color:black;">&nbsp;concorda que o seu m&eacute;todo de pagamento ser&aacute; automaticamente renovado a cada per&iacute;odo do plano, nos valores aplic&aacute;veis ao plano para evitar interrup&ccedil;&otilde;es em seus servi&ccedil;os. Para evitar cobran&ccedil;as futuras, cancele antes da data de renova&ccedil;&atilde;o.&nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style="color:black;">4.5 O usu&aacute;rio/afiliado n&atilde;o poder&aacute; alterar o plano inicialmente cadastrado, antes de transcorridos 30 dias da assinatura do servi&ccedil;o;&nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:35.4pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>4.5.1 Ap&oacute;s mudan&ccedil;a de um plano, a pr&oacute;xima cobran&ccedil;a ser&aacute; emitida no valor da mensalidade do plano atualizado.</p>
    <p style='margin-right:0cm;margin-left:35.4pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>4.5.2 Em caso do usu&aacute;rio/afiliado migrar de um plano para outro de valor inferior ou gratuito, os valores que j&aacute; foram pagos n&atilde;o ser&atilde;o restitu&iacute;dos pelo Vocevende.</p>
    <p style='margin-right:0cm;margin-left:35.45pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>4.5.3 O processamento dos pagamentos por cart&atilde;o de cr&eacute;dito ser&aacute; realizado por empresa terceirizada.</span></p>
    <p style='margin-right:0cm;margin-left:35.45pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>4.5.4 O Vocevende n&atilde;o poder&aacute; intervir nos resultados da rela&ccedil;&atilde;o do usu&aacute;rio e/ou afiliado com a empresa respons&aacute;vel por gest&atilde;o de pagamentos, uma vez que esta administra suas opera&ccedil;&otilde;es independentemente da Vocevende.</span></p>
    <p style='margin-right:0cm;margin-left:35.45pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>4.5.5 O Vocevende n&atilde;o pode garantir de forma nenhuma que os servi&ccedil;os prestados pela empresa respons&aacute;vel pela gest&atilde;o de pagamentos funcionar&atilde;o livre de erros, interrup&ccedil;&otilde;es, mal funcionamentos, atrasos e outras imperfei&ccedil;&otilde;es.</span></p>
    <p style='margin-right:0cm;margin-left:35.45pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>4.5.6 O Vocevende n&atilde;o ser&aacute; respons&aacute;vel pela disponibilidade ou n&atilde;o dos servi&ccedil;os prestados pela empresa respons&aacute;vel pela gest&atilde;o de pagamentos ou pela impossibilidade do uso do servi&ccedil;o.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style="color:black;">4.6 O Vocevende fornecer&aacute; avisos e mensagens para o&nbsp;</span><span style="color:black;">usu&aacute;rio e/ou afiliado</span><span style="color:black;">&nbsp;das seguintes formas: (ii) dentro do servi&ccedil;o, ou (ii) expresso para as informa&ccedil;&otilde;es de contato fornecidas (por exemplo, e-mail, n&uacute;mero de celular, endere&ccedil;o f&iacute;sico).&nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style="color:black;">4.7 A plataforma Vocevende n&atilde;o disponibilizar&aacute; o conte&uacute;do dos&nbsp;</span><span style="color:black;">usu&aacute;rios e/ou afiliados</span><span style="color:black;">&nbsp;em an&uacute;ncios de produtos e servi&ccedil;os de terceiros para terceiros (incluindo conte&uacute;do patrocinado) sem o consentimento separado do&nbsp;</span><span style="color:black;">usu&aacute;rio e/ou afiliado</span><span style="color:black;">. No entanto, o site Vocevende tem o direito, sem pagamento para&nbsp;</span><span style="color:black;">usu&aacute;rio e/ou afiliado</span><span style="color:black;">&nbsp;ou terceiros, de veicular an&uacute;ncios pr&oacute;ximos ao seu conte&uacute;do, conforme observado na Pol&iacute;tica de Privacidade. Se&nbsp;</span><span style="color:black;">usu&aacute;rio e/ou afiliado</span><span style="color:black;">&nbsp;usar um recurso do servi&ccedil;o, a plataforma Vocevende pode mencion&aacute;-lo com seu nome ou foto para promover esse recurso em nossos servi&ccedil;os, sujeito &agrave;s suas configura&ccedil;&otilde;es.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>4.8 A plataforma Vocevende pode alterar, suspender ou descontinuar qualquer um dos servi&ccedil;os disponibilizados; assim como, pode modificar os valores dos planos pagos em vigor mediante notifica&ccedil;&atilde;o razo&aacute;vel, na medida permitida por lei.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>4.9 Ao enviar sugest&otilde;es ou outros coment&aacute;rios sobre nossos servi&ccedil;os, o&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rio e/ou afiliado</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;concorda que o Vocevende pode usar e compartilhar (mas n&atilde;o &eacute; obrigado) tais coment&aacute;rios para qualquer finalidade, sem compensa&ccedil;&atilde;o para o&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rio e/ou afiliado</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>4.10 Vocevende ajuda a seus&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rios e/ou afiliados</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;que oferecem seus servi&ccedil;os e produtos (coaching de carreira, treinamento, representa&ccedil;&atilde;o, etc.) com outros&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rios e/ou afiliados,</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;ou terceiros, que buscam servi&ccedil;os, produtos, vagas. Vocevende n&atilde;o realiza nem emprega indiv&iacute;duos para realizar esses servi&ccedil;os.&nbsp;</span></p>
    <p style='margin-right:0cm;margin-left:1.0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>4.10.1 O&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rio e/ou afiliado</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;(i) reconhece que Vocevende n&atilde;o supervisiona, dirige, controla ou monitora os&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rios e/ou afiliados</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;na pelos usu&aacute;rios e/ou &nbsp;afiliados; e (ii) concorda que nada deve criar uma rela&ccedil;&atilde;o de emprego, ag&ecirc;ncia ou joint venture entre a plataforma <span style="background:white;">Vocevende</span><strong>&nbsp;</strong>comercializa&ccedil;&atilde;o e divulga&ccedil;&atilde;o desses servi&ccedil;os, vagas e/ou produtos; (iii) concorda que o Vocevende n&atilde;o &eacute; respons&aacute;vel, n&atilde;o garante nem endossa a oferta, desempenho ou aquisi&ccedil;&atilde;o dos servi&ccedil;os, treinamentos, vagas e/ou produtos oferecidos pelos&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rios e/ou afiliados.</span></p>
    <p style='margin-right:0cm;margin-left:1.0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;4.10.2 O&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rio e/ou afiliado</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;declara e garante que possui todas as licen&ccedil;as necess&aacute;rias e fornecer&aacute; servi&ccedil;os e produtos consistentes com nossas pol&iacute;ticas e em conformidade com a lei.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>4.11 O&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rio e/ou afiliado</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;pode se inscrever e/ou participar de eventos e treinamentos organizados por outro&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rio e/ou afiliado</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;e conectar-se com outros&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rios e/ou afiliados</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;que participam de tais eventos. O&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rio e/ou afiliado</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;concorda que a plataforma Vocevende n&atilde;o &eacute; respons&aacute;vel pela conduta de qualquer um dos membros ou outros participantes em tais eventos e/ou treinamentos.</span></p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>4.12 O site&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;font-weight:normal;'>Vocevende</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>reserva-se o direito de restringir, suspender ou encerrar conta do usu&aacute;rio e/ou afiliado</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>que violar este Termo de Uso ou a lei ou estiver fazendo uso indevido dos servi&ccedil;os.</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>5. Responsabilidades</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>5.1 Conforme a lei e as diretrizes de Termo de Uso, a plataforma <span style="background:white;">Vocevende</span> n&atilde;o ser&aacute; respons&aacute;vel por perda de oportunidade de emprego ou perda de neg&oacute;cio (relat&oacute;rio de perda de oportunidade ou perda de negocia&ccedil;&atilde;o, declara&ccedil;&otilde;es ofensivas ou difamat&oacute;rias), perda de dados (por exemplo, tempo de parada ou perda, uso ou altera&ccedil;&otilde;es de suas informa&ccedil;&otilde;es ou conte&uacute;do) ou quaisquer danos indiretos, incidentais, consequenciais, especiais ou punitivos.&nbsp;</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>5.2 O usu&aacute;rio e/ou afiliado concorda que as limita&ccedil;&otilde;es de responsabilidade se aplicam a todas as reivindica&ccedil;&otilde;es de responsabilidade (por exemplo, garantia, ato il&iacute;cito, neglig&ecirc;ncia, contrato e lei), mesmo que <span style="background:white;">Vocevende</span> tenha sido informada da possibilidade de qualquer dano, e mesmo que esses recursos falhem em seu prop&oacute;sito essencial.</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>5.3 A Vocevende n&atilde;o ser&aacute; respons&aacute;vel pelo efetivo cumprimento das obriga&ccedil;&otilde;es assumidas pelos usu&aacute;rios e/ou afiliados.</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>5.4 Na hip&oacute;tese de ocorr&ecirc;ncia de caso fortuito ou de for&ccedil;a maior, a Vocevende n&atilde;o poder&aacute; ser responsabilizada pelo n&atilde;o cumprimento de suas obriga&ccedil;&otilde;es aqui estabelecidas.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>5.5 Tendo em vista, ainda, a impossibilidade de funcionamento integral e ininterrupto de qualquer sistema de telecomunica&ccedil;&atilde;o ou de inform&aacute;tica, durante 365 (trezentos e sessenta e cinco) dias por ano, 24 (vinte e quatro) horas por dia, nesta situa&ccedil;&atilde;o de fragilidade tamb&eacute;m se incluindo, em raz&atilde;o da depend&ecirc;ncia de servi&ccedil;os de telecomunica&ccedil;&otilde;es prestados por terceiros, o Vocevende n&atilde;o garante, de nenhuma forma, a presta&ccedil;&atilde;o do servi&ccedil;o de forma ininterrupta e/ou isenta de erros.</span></p>
    <p style='margin-right:0cm;margin-left:1.0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><span style="color:black;">5.5.1&nbsp;</span>Em caso de dano no site Vocevende ocasionado por invas&atilde;o de sistemas ou qualquer outra natureza t&eacute;cnica que ocorra por defeito ou a&ccedil;&atilde;o que cause problemas de infraestrutura ou sistema que indisponibilize acesso do usu&aacute;rio empresa ou profissional ou afiliado ao site vocevende por mais de 168 horas, o usu&aacute;rio/afiliado poder&aacute; solicitar o cancelamento sem ressarcimento de valores referente ao plano adquirido. O Vocecende &eacute; o respons&aacute;vel em tentar sanar quaisquer problemas t&eacute;cnicos.</p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>5.6 O Vocevende n&atilde;o se responsabilizar&aacute; pelas obriga&ccedil;&otilde;es tribut&aacute;rias que recaiam sobre as atividades dos usu&aacute;rios e/ou afiliados na plataforma.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><span style="color:black;">5.7 O Vocevende n&atilde;o se responsabiliza nem garante a&nbsp;</span>contrata&ccedil;&atilde;o ou garantias presentes ou futuras de recoloca&ccedil;&atilde;o ou obten&ccedil;&atilde;o de emprego, nem realiza&ccedil;&atilde;o de vendas.</p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;font-weight:normal;'>5.8 Esta se&ccedil;&atilde;o n&atilde;o privar&aacute;&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>usu&aacute;rio e/ou afiliado <span style="background:white;">de nenhuma prote&ccedil;&atilde;o obrigat&oacute;ria ao consumidor de acordo com o C&oacute;digo de Defesa do Consumidor. &nbsp;&nbsp;</span></span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;'>5.9 A empresa EB2C Consultoria Ltda,&nbsp;</span><strong><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>inscrita no CNPJ/MF n. 10.780.262/0001-70, com sede no Brasil, na cidade de S&atilde;o Paulo no Estado de S&atilde;o Paulo,</span></strong><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;'>&nbsp;atrav&eacute;s de um dos seus s&oacute;cios, &eacute; detentora da plataforma www.vocevende.com.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;'>6. Direitos e Obriga&ccedil;&otilde;es do Usu&aacute;rio/Afiliado</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>6.1 Caber&aacute; ao Usu&aacute;rio e/ou Afiliado:</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>1. Cumprir todas as leis aplic&aacute;veis, incluindo, sem limita&ccedil;&atilde;o, leis de privacidade, leis de propriedade intelectual, leis anti-spam, leis de controle de exporta&ccedil;&atilde;o, leis tribut&aacute;rias e requisitos regulat&oacute;rios;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>2. Fornecer informa&ccedil;&otilde;es precisas e verdadeiras no site&nbsp;</span></strong><span style="color:black;background:white;">Vocevende</span><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>&nbsp;e mant&ecirc;-las atualizadas;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>4. Usar os Servi&ccedil;os de maneira profissional.</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>6.2 Fica vedado ao Usu&aacute;rio e/ou Afiliado:</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>1. Criar uma identidade falsa no site VoceVende, deturpar sua identidade, criar um perfil de membro para qualquer pessoa que n&atilde;o seja voc&ecirc; (uma pessoa real), ou usar ou tentar usar a conta de outra pessoa ou empresa;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>2. Desenvolver, apoiar ou usar software, dispositivos, scripts, rob&ocirc;s ou quaisquer outros meios ou processos (incluindo crawlers, plug-ins de navegador e add-ons ou qualquer outra tecnologia) para raspar os servi&ccedil;os ou copiar perfis e outros dados dos servi&ccedil;os;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>3. Substituir qualquer recurso de seguran&ccedil;a ou ignorar ou contornar quaisquer controles de acesso ou limites de uso do servi&ccedil;o;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>4. Copiar, usar, divulgar ou distribuir quaisquer informa&ccedil;&otilde;es obtidas nos servi&ccedil;os, seja diretamente ou por meio de terceiros (como mecanismos de pesquisa), sem o consentimento do Vocevende;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>5. Divulgar informa&ccedil;&otilde;es nem oferecer/comercializar servi&ccedil;os e produtos que o usu&aacute;rio e/ou afiliado n&atilde;o tem consentimento para divulgar ou oferecer/comercializar;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>6. Violar os direitos de propriedade intelectual de terceiros, incluindo direitos autorais, patentes, marcas registradas, segredos comerciais ou outros direitos de propriedade.&nbsp;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>7. Violar a propriedade intelectual ou outros direitos da Vocevende, incluindo, sem limita&ccedil;&atilde;o, (i) copiar ou distribuir nossos v&iacute;deos de treinamento ou outros materiais ou (ii) copiar ou distribuir nossa tecnologia, a menos que seja lan&ccedil;ada sob licen&ccedil;as de c&oacute;digo aberto; (iii) usar a palavra &ldquo;Vocevende&rdquo; ou nossos logotipos em qualquer nome comercial, e-mail ou URL, exceto conforme fornecido nas Diretrizes da marca;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>8. Publicar qualquer coisa que contenha v&iacute;rus de software, worms ou qualquer outro c&oacute;digo prejudicial;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>9. Fazer engenharia reversa, descompilar, desmontar, decifrar ou de outra forma tentar derivar o c&oacute;digo-fonte dos Servi&ccedil;os ou qualquer tecnologia relacionada que n&atilde;o seja de c&oacute;digo aberto;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>10. Implicar ou declarar que voc&ecirc; &eacute; afiliado ou endossado pelo Vocevende sem nosso consentimento expresso;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>11. Alugar, arrendar, emprestar, trocar, vender/revender ou monetizar os servi&ccedil;os ou dados relacionados ou acesso aos mesmos, sem o consentimento do Vocevende;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>12. Fazer um link direto para nossos servi&ccedil;os para qualquer finalidade que n&atilde;o seja para promover seu perfil, seus produtos e/ou servi&ccedil;os ou suas representa&ccedil;&otilde;es profissionais em nossos servi&ccedil;os, sem o consentimento do Vocevende;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>13. Usar bots ou outros m&eacute;todos automatizados para acessar os servi&ccedil;os, adicionar ou baixar contatos, enviar ou redirecionar mensagens;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>14. Monitorar a disponibilidade, desempenho ou funcionalidade dos servi&ccedil;os oferecidos na plataforma Vocevende para qualquer finalidade competitiva;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>15. Envolver-se em &quot;enquadramento&quot;, &quot;espelhamento&quot; ou de outra forma simular a apar&ecirc;ncia ou fun&ccedil;&atilde;o dos servi&ccedil;os;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>16. Sobrepor ou de outra forma modificar os servi&ccedil;os ou sua apar&ecirc;ncia (por exemplo, inserindo elementos nos servi&ccedil;os ou removendo, cobrindo ou obscurecendo um an&uacute;ncio inclu&iacute;do nos servi&ccedil;os);</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style="color:black;">17. Transmitir ou armazenar qualquer informa&ccedil;&atilde;o, dado ou material que viole qualquer lei federal, estadual ou municipal;</span></p>
    <p style='margin-right:0cm;margin-left:35.45pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>18. Divulgar e promover materiais relativos &agrave; venda de armas de fogo ou incrementos de uso exclusivo das for&ccedil;as armadas nacionais ou estrangeiras;&nbsp;</p>
    <p style='margin-right:0cm;margin-left:35.45pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>19. Divulgar e promover informa&ccedil;&atilde;o instrutiva sobre atividades ilegais, que promovam ou induzam dano f&iacute;sico ou moral contra qualquer grupo ou indiv&iacute;duo;&nbsp;</p>
    <p style='margin-right:0cm;margin-left:35.45pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>20. Disponibilizar, utilizar ou armazenar qualquer material que explore de alguma forma, crian&ccedil;as ou adolescentes menores de 18 anos de idade;&nbsp;</p>
    <p style='margin-right:0cm;margin-left:35.45pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style="color:black;">21. Disponibilizar, utilizar ou armazenar qualquer material de conte&uacute;do grotesco ou ofensivo para a comunidade web, que pode incluir, mas n&atilde;o se limitando a isso, fanatismo, racismo, &oacute;dio ou profana&ccedil;&atilde;o e maus tratos a animais</span></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>22. Interferir na opera&ccedil;&atilde;o ou colocar uma carga excessiva nos servi&ccedil;os (por exemplo, spam, ataque de nega&ccedil;&atilde;o de servi&ccedil;o, v&iacute;rus, algoritmos de jogos);&nbsp;</span></strong></p>
    <p style='margin-right:0cm;margin-left:36.0pt;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>23. Violar quaisquer termos e diretrizes adicionais relativos a um servi&ccedil;o espec&iacute;fico que s&atilde;o fornecidos ao se cadastrar e utilizar os servi&ccedil;os da plataforma Vocevende.</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>6.2.1 As penalidades para as infra&ccedil;&otilde;es das condi&ccedil;&otilde;es acima podem resultar em suspens&atilde;o ou t&eacute;rmino da conta, independentemente de aviso ou notifica&ccedil;&atilde;o e sem nenhum reembolso de valores pagos.</p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>7. Cancelamento</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'>7.1 O&nbsp;<strong><span style='font-family:"Calibri",sans-serif;font-weight:normal;'>Usu&aacute;rio e/ou Afiliado</span></strong> poder&aacute; encerrar a conta a qualquer momento, basta acessar a p&aacute;gina do perfil e no item cadastro clicar em excluir.</p>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>7.2 A plataforma Vocevende poder&aacute; encerrar o cadastro do usu&aacute;rio e/ou afiliado que violar qualquer das disposi&ccedil;&otilde;es e diretrizes da Pol&iacute;tica de Privacidade ou do Termo de Uso.</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>7.3 Uma vez encerrada a conta, o usu&aacute;rio e/ou afiliado perder&aacute; o direito de acessar ou usar os servi&ccedil;os.&nbsp;</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>7.4 O encerramento da conta n&atilde;o exime o&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>usu&aacute;rio e/ou afiliado</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>&nbsp;do pagamento dos valores at&eacute; o final do per&iacute;odo do plano adquirido na plataforma Vocevende.</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>7.5 Os valores dos pr&ecirc;mios dos afiliados das vendas efetuadas at&eacute; a data do encerramento da conta poder&atilde;o ser repassados pela Vocevende em conta de pagamento vinculada pelo usu&aacute;rio e/ou afiliado para receber os pagamentos de acordo com as normas vigentes no termo de usos desta. Ressaltando, que o repasse desses valores podem ser suspensos por condi&ccedil;&otilde;es caso fortuito e for&ccedil;a maior.</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>7.6 O Vocevende disponibiliza planos pagos profissionais e empresariais que j&aacute; est&atilde;o com 70% (setenta por cento) de desconto. Assim, havendo cancelamento dos servi&ccedil;os estes descontos ser&atilde;o automaticamente cancelados.&nbsp;</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;font-weight:normal;'>7.7 O <strong><span style='font-family:"Calibri",sans-serif;font-weight:normal;'>Usu&aacute;rio e/ou Afiliado</span></strong> poder&aacute; cancelar o plano adquirido, mensal, trimestral, semestral ou anual, mas n&atilde;o ter&aacute; a devolu&ccedil;&atilde;o dos valores podendo utilizar seu plano at&eacute; o fim do per&iacute;odo contratual, basta acessar a p&aacute;gina do perfil e no item cancelar plano clicar em cancelar que n&atilde;o ser&atilde;o renovados automaticamente.</span></h4>
    <h4 style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;font-weight:bold;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;font-weight:normal;'>&nbsp;</span></h4>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><strong><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;'>8. Condi&ccedil;&otilde;es Gerais</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><span style="color:black;">8.1 Se qualquer disposi&ccedil;&atilde;o do Termo de Uso ou da Pol&iacute;tica de Privacidade for considerada inv&aacute;lida, inexequ&iacute;vel, nula ou sem efeito por qualquer &oacute;rg&atilde;o administrativo ou judicial competente, ou por for&ccedil;a de lei, as demais disposi&ccedil;&otilde;es permanecer&atilde;o v&aacute;lidas, em pleno vigor e efeito.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;'>8.2 Vocevende</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>&nbsp;reserva todos os seus direitos de propriedade intelectual sobre os servi&ccedil;os. marcas comerciais e logotipos usados em conex&atilde;o com os servi&ccedil;os s&atilde;o marcas comerciais de seus respectivos propriet&aacute;rios. As marcas de servi&ccedil;o, gr&aacute;ficos e logotipos usados para nossos servi&ccedil;os s&atilde;o marcas comerciais ou registradas da&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;'>Vocevende</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;'><span style="color:black;">8.3 A toler&acirc;ncia pelo Vocevende ao descumprimento, ainda que reiterado das disposi&ccedil;&otilde;es contidas neste termo, n&atilde;o dever&aacute; ser interpretado como ren&uacute;ncia ou nova&ccedil;&atilde;o.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;'>8.4 O usu&aacute;rio e/ou afiliado concorda que a &uacute;nica maneira de nos fornecer um aviso legal &eacute; se&ccedil;&atilde;o &ldquo;Fale Conosco&rdquo; no site Vocevende.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;'>8.5 Os&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>usu&aacute;rios /afiliados <span style="background:white;">concorda que Vocevende pode ceder este Contrato a suas afiliadas ou a uma parte que o compre sem o seu consentimento.&nbsp;</span></span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;background:white;'>8.6&nbsp;</span><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>O Usu&aacute;rio e/ou afiliado indenizar&aacute; a Vocevende, empresas controladoras, diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por outros Usu&aacute;rios ou terceiro, decorrentes de suas atividades na plataforma ou por seu descumprimento deste Termo ou pela viola&ccedil;&atilde;o de qualquer lei ou direitos de terceiros, incluindo honor&aacute;rios de advogados.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>8.7 Todos os itens deste Termo de Uso est&atilde;o regidos pelas leis vigentes na Rep&uacute;blica Federativa do Brasil, incluindo a lei LGPD &ndash; LEI GERAL DE PROTE&Ccedil;&Atilde;O DE DADOS. Para todos os assuntos referentes &agrave; sua interpreta&ccedil;&atilde;o e cumprimento, as partes se submeter&atilde;o ao Foro da Comarca de S&atilde;o Paulo/SP, exce&ccedil;&atilde;o feita a reclama&ccedil;&otilde;es apresentadas por usu&aacute;rios e/ou afiliados que se enquadrem no conceito legal de consumidores, que poder&atilde;o submeter ao foro de seu domic&iacute;lio.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><strong><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>9. Autoriza&ccedil;&atilde;o envio e recebimentos de e-mails, WhatsApp e outros meios pelo Vocevende</span></strong></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>9.1 Os usu&aacute;rios e/ou afiliados autorizam receber e-mails e/ou mensagens de WhatsApp de Newsletter, propaganda do Vocevende, solicita&ccedil;&otilde;es para cadastrar profissionais, curr&iacute;culos, vagas, produtos, v&iacute;deos aulas, treinamentos ou outra propaganda do pr&oacute;prio Vocevende estimulando os usu&aacute;rios profissionais e empresas a utilizarem o portal.</span></p>
    <p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:10.0pt;text-align:justify;background:white;vertical-align:baseline;'><span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>9.2 O Vocevende enviar&aacute; para os e-mails e/ou mensagens de WhatsApp dos usu&aacute;rios profissionais ou empresas e-mails notificando um novo cadastro de vaga, cadastro de curr&iacute;culos, produtos, treinamentos ou um novo afiliado ou servi&ccedil;o que forem cadastrados em nossa plataforma.&nbsp;</span></p>
  </div>
</template>

<script>
// import VideoPlayerItem from "../components/VideoPlayerItem.vue";
export default {
  components: {
    // VideoPlayerItem,
  },
  props: ["type"],
  data() {
    return {
      url: window.location.href,
    };
  },
  methods: {
    //TODO: enviar mensagem
  },
};
</script>

<style lang="scss">
.terms-policy {
  height: calc(100vh - 410px);
  overflow-x: hidden;
  padding-right: 10px;

  &-on-modal{
    height: calc(100vh - 550px);
  }
}

@media only screen and (max-width: 600px) {
  .terms-policy {

    height: calc(100vh - 375px);
    padding-right: 0px;

    &-on-modal{
      height: 500px;
    }
  }
}
</style>

