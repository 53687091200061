const { template } = require("lodash");

<template>
  <div class="profile-bar">
    <!-- <img src="/static/svg/logo_horizontal.svg" alt=""> -->
    <!-- <div class="light-grey-bg profile-bg image-box"> -->
    <!-- <img :src="getImageURL(product.image.url) " 
                  v-if="product.image" 
                  class="product-image-1" alt=""> -->
    <!-- <div
      class="light-grey-bg profile-bg image-box"
      style="background-image: url(./static/svg/user.svg)"
    > -->
    <!-- </div> -->
    <div class="">
      <div class="" v-if="!user.image && this.$route.fullPath == '/vc/dados'">
				<div class="">
					<!-- <file-upload 
						:url="url" 
						:thumb-url='thumbUrl' 
						:headers="headers" 
						name="image"
						class="thumb" 
						v-model="files"
						accept="image/png,image/gif,image/jpeg,image/webp"
						@change="onFileChange"
						ref="upload">
					</file-upload> -->
          <image-upload
            v-model="user.image"
            @uploaded="updateUser()">
          </image-upload>
        </div>
			</div>

      <div class="light-grey-bg profile-bg image-box" v-if="!user.image && this.$route.fullPath != '/vc/dados'">
			</div>

			<div class="" v-if="user.image && this.$route.fullPath == '/vc/dados'">
				<div class="">
					<div class="thumb" 
						:style="{ backgroundImage: `url('${getImageThumbUrl(user.image)}')` }" >

						<!-- <a class="close-button" @click="removePhoto(user.image)">
							<i class="fas fa-times-circle"></i>
						</a> -->
					</div>
          <button class="clean center remove-img-btn" @click="removePhoto(user.image)">Remover imagem</button>
				</div>
			</div>
      <div class="" v-if="user.image && this.$route.fullPath != '/vc/dados'" @click="goTo('/vc/dados')">
				<div class="">
					<div class="thumb" 
						:style="{ backgroundImage: `url('${getImageThumbUrl(user.image)}')` }" >
					</div>
				</div>
			</div>
    </div>
      <!-- <img class="user-img" src="/static/svg/user.svg"  alt=""> -->
    <ul class="profile-buttons">
      <li v-on:click="goTo('/vc/dados')" v-bind:class="{ 'active ': this.$route.fullPath == '/vc/dados' }">
        <a >Meu cadastro</a>
      </li>
      <li v-on:click="profileView()">
        <a >Ver perfil</a>
      </li>
      <li v-on:click="goTo('/vc')">
        <a >Buscar produtos para vender/representar</a>
      </li>
      <li v-on:click="goTo('/vc/catalogo/')">
        <a >Meus produtos</a>
      </li>
      <li v-on:click="goTo('/vc/buscar-vagas/')">
        <a >Buscar vagas de emprego</a>
      </li>
      <li v-on:click="goTo('/vc/buscar-treinamentos/')">
        <a >Adquirir treinamento comercial</a>
      </li>
      <li v-on:click="goTo('/vc/meus-treinamentos')">
        <a >Meus treinamentos</a>
      </li>
      <li v-on:click="goTo('/vc/afiliados')">
        <a >Afiliados</a>
      </li>
      <li v-on:click="goTo('/vc/perfil')" v-bind:class="{ 'active ': this.$route.fullPath == '/vc/perfil' }">
        <a >Meus planos</a>
      </li>
      <li v-on:click="goTo('/vc/cartoes')" v-bind:class="{ 'active ': this.$route.fullPath == '/vc/cartoes' }">
        <a >Minha carteira</a>
      </li>
      <li v-on:click="goTo('/vc/indicacoes')" v-bind:class="{ 'active ': this.$route.fullPath == '/vc/indicacoes' }">
        <a >Minhas indicações</a>
      </li>
      <li v-on:click="goTo('/vc/historico-compras')" v-bind:class="{ 'active ': this.$route.fullPath == '/vc/historico-compras' }">
        <a >Histórico de compras</a>
      </li>
      <li v-on:click="goTo('/vc/recebimentos')" v-bind:class="{ 'active ': this.$route.fullPath == '/vc/recebimentos' }">
        <a >Recebimentos</a>
      </li>
      <li v-on:click="logout()">
        <a >Sair</a>
      </li>
    </ul>
  </div>
</template>


<script>
import MainLayout from "../../../layouts/Salesman.vue";
import userMixin from "../../../mixins/userMixin.js";
import ImageUpload from "../../../components/ImageUpload.vue";
import moment from "moment";
import { server, local } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    ImageUpload
  },
  data() {
    return {
      url: server.url + "/api/media/image/",
      headers: {
        Authorization: 'Bearer ' + localStorage.token
      },
      files: [],
      fileUploaded: [],
    };
  },
  validations: {},
  mounted() {
    var parameters = this.$route.query;
    let self = this;
  },
  watch: {
    user(newValue, oldValue) {
      // console.log(newValue, oldValue);
    },
  },

  methods: {
    logout() {
      let self = this;
      var id = this.$route.params.id;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      this.axios
        .post(server.url + "/api/auth/logout", server.config)
        .then(function (response) {
          localStorage.removeItem('token');
          localStorage.removeItem('slug');
          localStorage.removeItem('user');
          localStorage.removeItem('company');
          window.open("/login", "_self");
          // self.$swal({
          // 	icon: 'success',
          // 	title: 'Dados atualizados',
          // 	text: responseData.message,
          // 	// footer: '<a href>Why do I have this issue?</a>'
          // });
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    // goTo(url){
    //   window.open(url, "_self");
    // },
    goTo(url){
      this.$router.push(url);
    },
    profileView(){
      let self = this;
      // window.open("./vc/perfil/visualizacao/" + self.user._id, "_self");
      const url = "/vc/perfil/visualizacao";
      this.$router.push(url);
    },
    thumbUrl(file) {
      return file.myThumbUrlProperty;
      },
    onFileChange(response) {
      // Handle files like:
      const image = response.data.image;
      // if(!this.user.image){
      // 	this.user.image = [];
      // }
      // this.user.image.push(image);
      this.user.image = image;
      this.fileUploaded = image.url;
      },
    removePhoto(image) {
      var self = this;
      this.axios.delete(  server.url + '/api/media/image/' + image._id , server.config)
        .then(function (response) {
          self.user.image = null;
          self.$swal({
            icon: 'success',
            title: 'Imagem removida',
            // text: answer.message,
            // footer: '<a href>Why do I have this issue?</a>'
          });
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    updateUser() {
      let self = this;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      let userCopy = JSON.parse(JSON.stringify(this.user));
      this.prepareUserToSend(userCopy);

      this.axios
        .put(
          server.url + "/api/user/" + self.user._id,
          {
            user: userCopy,
          },
          server.config
        )
        .then(function (response) {
          // location.reload();
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.profile-bar {
  .user-img{
    width: 60%;
    margin: auto;
    margin-top: 40px;
  }

  .remove-img-btn{
    margin-top: -28px;
    font-size: small;
    color: #B2BEE0;
    font-weight: initial;
    &:hover{
      color: var(--main-text-color);
    }
  }

  .image-box {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    height: 100%;
    width: 100%;
  }

  .profile-bg {
    height: 116px;
    width: 130px;
    margin: auto;
    margin-top: 70px;
    margin-bottom: 70px;
  }
}

.profile {
  margin-top: -15px;


  .profile-bg {
    height: 116px;
    width: 130px;
    margin: auto;
    margin-top: 70px;
    margin-bottom: 70px;
  }
}


ul.profile-buttons {
  list-style: none;
  width: 100%;
  // height: 100%;
  min-height: 74px;
  // background-color: var(--main-color-primary);
  display: table;

  li {
    display: block;
    //   vertical-align: middle;
    // padding: 18px;
    text-align: center;

    &:hover {
      background-color: var(--main-color-primary);
      cursor: pointer;
      // border-bottom: 2px solid var(--main-color-secundary);
    }

    a {
      width: 100%;
      color: var(--main-text-color);
      display: inline-block;
      padding: 20px 15px;

      &:hover {
        text-decoration: none;
        color: white !important;
      }
    }
  }
  .active {
    background-color: var(--main-color-primary);
    color: white;
    pointer-events: none;

    a {
      color: white;
    }
  }
}


@media only screen and (max-width: 600px) {
  .profile-bar{
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
</style>
