<template>
	<main-layout>
		<div class="sub-menu container-fluid light-grey-bg grey-title-div">
			<div class="container">
				<div class="row" style="">
				<div class="col-md-9">
					<h1>Buscar profissionais ou representantes </h1>
				</div>
				<div class="col-md-3" style="margin-top: -16px">
					<button v-on:click="goTo('./co/vendedores')">
						<!-- Meus representantes -->
						Candidatos a Representação
					</button>
					<br />
					<button v-on:click="goTo('./co/vendedores/favoritos')">Favoritados</button>
				</div>
				</div>
			</div>
		</div>
		<div class="favorited-salesman container company-page">
			<div class="filter-search">
				<div class="row search-row">
					<div class="col-md-2">
						<label class="label-search">Estado</label>
						<v-select
							label="sigla" 
							:options="stateList"
							v-on:input="getSalesman('state')"
							:reduce="state => state.sigla" 
							v-model="search.state"
							:placeholder="'(Selecione)'"
						>
						</v-select>
					</div>
					<div class="col-md-2">
						<label class="label-search">Cidade</label>
						<v-select
							label="nome" 
							:options="cityList"
							v-on:input="getSalesman('city')"
							:reduce="city => city.nome" 
							v-model="search.city"
              :placeholder="cityPlaceholder"
              :disabled="cityPlaceholder == 'Selecione estado' || cityPlaceholder == 'Carregando...'"
						>
						</v-select>
					</div>
					<div class="col-md-2">
						<label class="label-search">Cargo</label>
						<v-select
							label="label"
							:options="positionList"
							v-on:input="getSalesman"
							v-model="search.position"
							:reduce="(position) => position.value"
							:placeholder="'Cargo'"
						>
						</v-select>
					</div>
					<div class="col-md-2">
						<label class="label-search">Segmento</label>
						<v-select
							label="name"
							:options="segmentList"
							v-on:input="getSalesman"
							v-model="search.segment"
							:reduce="(segment) => segment._id"
							:placeholder="'Segmento'"
						>
						</v-select>
					</div>
					<div class="col-md-2" style="margin-top: 20px;">
						<input
							id="searchText"
							type="text"
							v-model="search.searchText"
							placeholder="Buscar por nome"
							v-on:keyup.enter="getSalesman"
							style="padding: 12px 14px !important;"
						/>
						</div>
					<div class="col-md-2" style="margin-top: 20px;">
						<button @click="getSalesman()" >Buscar</button>
					</div>
					<div class="col-md-4">
						<input
							id="wantProducts"
							type="checkbox"
							v-model="search.wantProducts"
							value="0"
						/>
						<label for="wantProducts"> Buscando produtos para vender / representar</label>
					</div>
					<div class="col-md-3">
						<input
							id="wantJob"
							type="checkbox"
							v-model="search.wantJob"
							value="0"
						/>
						<label for="wantJob"> Buscando vaga / emprego</label>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6" v-for="salesman in salesmanList"  :key="salesman._id" >
					<favorite-salesman-item :salesman = salesman> </favorite-salesman-item>
				</div>
				
				<div class="col-md-12" style="margin-top: 30px; text-align: center;" v-if="canLoadMore">
					<!-- <center> <small> {{maxItems}} de {{productList.length}} </small></center> -->
					<button class="blue-border big" @click="loadMore()" style="width: 40%; margin: 20px 0px;">
							Carregar mais 
					</button>
				</div>
			</div>
		</div>


    <!-- <contact></contact> -->
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Company.vue";
import FavoriteSalesmanItem from "../../../components/FavoriteSalesmanItem.vue";
import { server } from "../../../js/variables.js";

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
	FavoriteSalesmanItem,
  },
  data() {
    return {
			salesmanList: [
				// {_id: 1, name: "João", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
				// {_id: 2, name: "Maria", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
				// {_id: 3, name: "Ana", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
				// {_id: 4, name: "Pedro", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
			],
			search: {
				state: null,
				city: null,
				position: null,
				segment: null,
				searchText: null,
				wantProducts: null,
				wantJob: null,
				afiliate: null,
			},
			company: '',
			maxItems: 100000,
			pageItems: 8,
			page: 0,
			canLoadMore: false,
    };
  },
  mounted() {
	var parameters = this.$route.query;
	this.findSegments();
	this.findPlaces();
	this.getSalesman();
	this.load();

  },
  methods: {
    loadMore(){
      this.maxItems = this.maxItems + this.pageItems;
      this.page = this.page + 1;
      this.getSalesman();
    },
    salesmanDetails(salesman){
        window.open("./co/vendedor/" + salesman._id, "_self");
    },
	load(){
		var parameters = this.$route.query;
		let self = this;
		// console.log('sessionMixin load');
		if (!localStorage.token) {
			return
		}
		server.config.headers = {
			Authorization: 'Bearer ' + localStorage.token
		}
		//650b37536e88a564380f56bc
		this.axios.get(  server.url + '/api/auth/load/', server.config)
		.then(function (response) {
			let responseData = response.data.data;

			self.company = responseData.company;

		})
		.catch(function (error) {
			
		});
	},
	getSalesman(type) {
		let self = this;
		// if (!localStorage.token) {
		// 	return
		// }
		// server.config.headers = {
		// 	Authorization: 'Bearer ' + localStorage.token
		// }
		// this.search.wantProducts = this.search.wantProducts ? 1 : 0;
		// this.search.wantJob = this.search.wantJob ? 1 : 0;
		if(!this.search.state){
			this.cityList = [];
			this.search.city = null;
			this.cityPlaceholder = 'Selecione estado';
		}else if(this.search.state && type == 'state'){
			this.cityList = [];
			this.search.city = null;
			this.findCities(this.search.state);
		}else if(this.search.state && type == 'city'){
			// this.findCities(this.search.state);
		}

		// if(this.search.state){
		// 	this.findCities(this.search.state);
		// }
		let header = JSON.parse(JSON.stringify(server.config));
		header['params'] = {
			limit: this.pageItems,
			page: this.page,
			type: "SALESMAN",
			resume: 1,
			state: this.search.state,
			city: this.search.city,
			position: this.search.position,
			segment: this.search.segment,
			filter: this.search.searchText,
			wantProducts: this.search.wantProducts,
			wantJob: this.search.wantJob,
			afiliate: this.search.afiliate,
		}
		console.log("teste", header);

		this.axios
		.get(server.url + "/api/user", header)
		.then(function (response) {
			
			if (self.page == 0) {
				self.salesmanList = [];
			}
			
			let responseData = response.data.data;
			self.salesmanList = self.salesmanList.concat( responseData.user_list );
			console.log(self.salesmanList, self.pageItems);
			console.log(responseData.user_list.length);
			console.log(self.pageItems);
			if (responseData.user_list.length != self.pageItems) {
				self.canLoadMore = false;
			} else {
				self.canLoadMore = true;
			}
          
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
			console.log(error);
		});
	},
	
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.filter-search{
	margin-bottom: 35px;

	input{
		margin-top: 0px;
	}

	label{
		color: var(--main-text-color);
	}

	.label-search{
		padding-left: 12px;
    padding-bottom: 4px;
	}
}

@media only screen and (max-width: 600px) {
	.filter-search{
		margin-top: 160px;
		
		
	}
}


</style>
