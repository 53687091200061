<template>
  <main-layout>
    <div style="position: relative; max-width: 100dvw;">
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1 >Encontre produtos para você representar</h1>
          </div>
          <div class="col-md-3 ">
            <button v-on:click="goTo('./vc/solicitacoes')">Produtos Solicitados</button>
          </div>
        </div>
      </div>
    </div>

    <!-- <banner :bannerType = 3 > </banner> -->

	<product-finder class="section" :showCompanyList = true>
    Destaques dos produtos para você representar
  </product-finder>

    <!-- <contact></contact> -->
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/SalesmanProfile.vue";
import userMixin from "../../../mixins/userMixin.js";
// import Contact from "../../../components/Contact.vue";
// import ProductItem from "../../../components/ProductItem.vue";
import ProductFinder from "../../../components/ProductFinder.vue";
// import Banner from '../../../components/Banner.vue'
import { server } from "../../../js/variables.js";

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    // Contact,
    ProductFinder,
    // Banner,
  },
  data() {
    return {
      productList: [],
    };
  },
  watch: {
    user(newValue, oldValue) {
      // console.log(newValue._id, oldValue._id);
      // console.log("AQUI",this.user);
    },
  },
  mounted() {
    var parameters = this.$route.query;
    // this.getProduct();
  },
  methods: {
    register(e) {
      window.open("./company-registration", "_self");
    },
    onPageChange(page) {
      this.sliderPage = page;
    },
    gotostore() {
      window.open("./store", "_self");
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";
</style>
