<template>
  <div class="container salesman-list-wrapper" v-if="salesmanList.length > 0">
    <div class="title-wrapper">
      <div class="title-space">
        <h2 class="h2-bigger" style="margin-bottom: 15px;">Candidatos em destaque</h2>
        <div class="hr-yellow hr-45"></div>
      </div>
      <button class="border" @click="registerCurriculum">Cadastre seu currículo</button>
    </div>
    <br><br><br>
    <div class="row salesman-list hide-mobile">
      <div class="col-md-6" v-for="user in salesmanList" :key="user._id" @click="salesman = user" data-toggle="modal"
        data-target="#salesmanDetailsModal">
        <salesman-item :salesman="user" :size="2"> </salesman-item>
      </div>

      <div class="col-md-12" style="text-align: center;" v-if="canLoadMore">
        <button class="border border-bigger" @click="loadMore()">Carregar mais candidatos</button>

      </div>
    </div>

    <div class="row salesman-list only-mobile">
      <div class="col-md-6" v-for="user in salesmanListMobile" :key="user._id" @click="salesman = user"
        data-toggle="modal" data-target="#salesmanDetailsModal">
        <salesman-item :salesman="user" :size="2"> </salesman-item>
      </div>

      <div class="col-md-12" style="text-align: center;" v-if="canLoadMore">
        <button class="border border-bigger" @click="loadMore(true)">Carregar mais candidatos</button>

      </div>
    </div>
    
    <salesman-details-modal :modal_id="'salesmanDetailsModal'" :salesman=salesman></salesman-details-modal>

  </div>
</template>

<script>
import { server, local } from "../js/variables.js";
import SalesmanItem from "./SalesmanItem.vue";
import SalesmanDetailsModal from "../components/SalesmanDetailsModal.vue";
import userMixin from "../mixins/userMixin.js";

export default {
  mixins: [userMixin],
  components: {
    SalesmanItem,
    SalesmanDetailsModal
  },
  data() {
    return {
      salesmanList: [],
      salesmanListMobile: [],
      salesman: {},
      endpoint: "/api/user",
      maxItems: 2,
      pageItems: 2,
      maxItemsMobile: 1,
      pageItemsMobile: 1,
      page: 0,
      canLoadMore: true,

    };
  },
  mounted() {
    this.loadSalesmans();
  },
  methods: {
    loadMore(isMobile) {
      if (isMobile) {
        this.maxItemsMobile = this.maxItemsMobile + this.pageItemsMobile;
        this.page = this.page + 1;
        this.loadSalesmans(true);
      } else {
        this.maxItems = this.maxItems + this.pageItems;
        this.page = this.page + 1;
        this.loadSalesmans();
      }
    },
    loadSalesmans(isMobile) {
      let self = this;
      let limit = isMobile ? this.pageItemsMobile : this.pageItems
      let header = JSON.parse(JSON.stringify(server.config));
      header['params'] = {
        limit: limit,
        page: this.page,
        type: "SALESMAN",
        resume: 1,
      }

      this.axios
        .get(server.url + this.endpoint, header)
        .then(function (response) {
          let responseData = response.data.data;
          if (self.page == 0) {
            self.salesmanList = [];
            self.salesmanListMobile = [];
          }

          if (responseData.user_list.length > 0) {
            self.salesmanList = responseData.user_list
            self.salesmanListMobile = responseData.user_list.slice(0, self.pageItemsMobile)
          }

          if (responseData.user_list.length != limit) {
            self.canLoadMore = false;
          } else {
            self.canLoadMore = true;
          }

        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    registerCurriculum() {
      if (!localStorage.token) {
        this.goTo('./vc/cadastro');
        // $('#loginModal').modal('show');
        return
      }
      if (this.user.type == 'SALESMAN') {
        this.$router.push('/vc/dados')
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Atenção',
          text: 'Entre como um profissional para cadastrar currículo.',
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            // window.open("./co/vagas", "_self"); 
          }
        })
        return
      }
    },

  }
};
</script>

<style lang="scss">
.salesman-list-wrapper {
  padding: 30px 0px;

  .title-wrapper {
    display: flex;
    flex-flow: row wrap;

    >div {
      flex: 1;
    }
  }

  .border {
    height: 40px;
    width: 250px;
    border-radius: 12px;

    &-bigger {
      height: 50px;
      width: 300px;
      font-size: 18px;
      margin-top: 40px;
    }
  }

  .salesman-list {}

}


@media only screen and (max-width: 600px) {

  .salesman-list-wrapper {
    padding: 0px 15px;

    .title-wrapper {
      .border {
        margin-top: 15px;
        width: 100%;
      }
    }

  }

}
</style>