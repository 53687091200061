<template>
  <main-layout>
	<!-- <pre> password {{ $v.postAddress }}</pre> -->
	<!-- <pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container product-details">
        <div class="row" v-if="product.name">
            <div class="col-md-12">
                <h2>{{product.company.name_fancy}}</h2>
                <h1>{{product.name}}</h1>
                <div class="hr-yellow hr-4" style="margin-top: 10px; margin-bottom: 10px;"></div>
                <h2 v-if="product.groups[0]">{{product.groups[0].name}}</h2>
                <h2 v-else></h2>
            </div>
            <!-- <div class="col-md-7 last"> -->
                <!-- <button class="blue" @click="backToSalesmanCatalog()" style="margin-right: 12px;">Continuar comprando</button>
                <button class="blue" @click="goToCart()">Carrinho</button> -->
                <!-- <button @click="addCart(product, $event)">Quero comprar este produto</button> -->
            <!-- </div> -->
            <div class="col-md-4 top-space" v-for="image in product.image" :key="image._id">
                <img :src="getImageThumbUrl(image)" alt="" class="product-image box-item">
            </div>
            <div class="col-md-12 top-space">
                <h2>Descrição</h2>
                <p class="blue-text grey-bg">{{product.description}}</p>
            </div>
            <div class="col-md-6 top-space">
                <h2>Preço</h2>
                <div class="grey-bg">
                    <p class="blue-text prices-bigger">Preço: <b>{{product.price | toCurrency}}</b></p>
                    <!-- <hr> -->
                    <!-- <p class="blue-text prices-bigger">Comissão: <b>{{product.sales_commission}}% ({{(product.sales_commission/100) * product.price | toCurrency}})</b></p> -->
                </div>
            </div>
            <div class="col-md-6 top-space last">
                <button @click="addCart(product, $event)" style="margin-top: 56px;">Quero comprar este produto</button>
            </div>
            <!-- <div class="col-md-6 top-space">
                <h2>Ajuda de custo da empresa</h2>
                <div class="grey-bg">
                    <p class="blue-text prices-bigger">A partir: <b>{{product.company.salary_min | toCurrency}}</b></p>
                    <hr>
                    <p class="blue-text prices-bigger">Até: <b>{{product.company.salary_min | toCurrency}}</b></p>
                </div>
            </div> -->
            <!-- <div class="col-md-12 top-space">
                <h2>Outros produtos da família / categoria</h2>
            </div> -->
            <!-- <div class="col-md-7 last top-space">
                <button>Solicitar representação de categoria. Quero vender os produtos desta categoria</button>
            </div> -->
        </div>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Public.vue";
import moment from 'moment'
import { server } from "../js/variables.js";
import { required, email, sameAs } from 'vuelidate/lib/validators'


export default {
	components: {
	MainLayout,
  },
  data () {
    return {
        product:{
			name: '',
			description: '',
			is_featured: '',
			representation: '',
			unit_measurement: '',
			sales_commission: '',
			ean: '',
			price: '',
			call: '',
			tech_info: '',
			groups: [],
        },
    }
  },
  validations: {

},
	mounted() {
		
		var parameters = this.$route.query;
		let self = this;
		this.getProduct();
	},
	watch:{
	},
	
  methods: {
    getProduct(){
		let self = this;
		var id = this.$route.params.idProduct;
		// console.log("get salesmans");
		// if (!localStorage.token) {
		// 	return
		// }
		// server.config.headers = {
		// 	Authorization: 'Bearer ' + localStorage.token
		// }

		this.axios.get(  server.url + '/api/product/' + id, server.config)
		.then(function (response) {
			let responseData = response.data.data;
			self.product = responseData.product;
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
			console.log(error);
		});
	},
    addCart(item, event){
        let self = this;
        if(event.stopPropagation) event.stopPropagation();
        let groupName = "";
        
        // console.log('primeiro', item)
        if(item.groups[0] != undefined) {
            groupName = item.groups[0].name;
        }
        // console.log('segundo', groupName)
        let cartItem = {
            amount: 1,
            product:{
                _id: item._id,
                name: item.name,
                price: item.price,
                sales_commission: item.sales_commission,
                image: {
                    thumb_url: item.image[0].thumb_url,
                    path : item.image[0].path,
                },
                groups: {
                    name: groupName,
                },
            }
        }
        
        var cart = self.$cookies.get("cart-" + this.$route.params.id);
        
        // console.log('carrinho', cart)
        // console.log('carrinho item', cartItem)
        
        if (!cart) cart = []; 
        else cart = JSON.parse(cart);
        for(let p of cart){
            if(p.product._id == cartItem.product._id){
                // self.$swal({
                //     icon: 'warning',
                //     title: 'Atenção',
                //     text: 'Item já está no carrinho',
                // })
                self.goToCart();
                return
            }
        }
        cart.push(cartItem);
        
        // console.log('antes de colocar no cookies', cart)
        self.$cookies.set("cart-" + this.$route.params.id, JSON.stringify( cart) );
        // self.$swal({
        //   icon: 'success',
        //   title: 'Sucesso',
        //   text: 'Item adicionado ao carrinho',
        // })
        window.open('./catalogo/' + this.$route.params.id + '/carrinho', "_self");

        // console.log('depois de colocar no cookies', cart)
    },
    backToSalesmanCatalog(){
        window.open('./catalogo/' + this.$route.params.id, "_self");
    },
	goToCart(){
        window.open('./catalogo/' + this.$route.params.id + '/carrinho', "_self");
    }
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.product-details{
    padding-top: 45px;
    padding-bottom: 45px;
    white-space: break-spaces;
    
    h2{
        margin-bottom: 10px;
    }

    p{
        font-size: 16px;
    }

    .grey-bg{
        background-color: var(--main-color-lightgrey);
        border-radius: 12px;
        padding: 20px 50px;
        
    }

    .prices-bigger{
        font-size: 18px;
    }

    .blue-text{
        color: var(--main-title-color) !important;
    }

    button{
        width: auto;
    }
}



</style>
