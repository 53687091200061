<template>
  <div>
    <div class="plan">
      <div v-if="paidPlans && paidPlans._id"> <!-- paid plans -->
        <div class="plan-paid-box">
          <h3>{{ paidPlans.name }}</h3>
          <!-- <div class="plan-price">
            <label v-if="intervalPlan == 1 && paidPlans.payment_1"
              >{{ paidPlans.payment_1.amount * licenseAmount | toCurrency }}/mês</label
            >
            <label v-if="intervalPlan == 3 && paidPlans.payment_3"
              >{{ paidPlans.payment_3.amount * licenseAmount | toCurrency }}/mês</label
            >
            <label v-if="intervalPlan == 6 && paidPlans.payment_6"
              >{{ paidPlans.payment_6.amount * licenseAmount | toCurrency }}/mês</label
            >
            <label v-if="intervalPlan == 12 && paidPlans.payment_12"
              >{{ paidPlans.payment_12.amount * licenseAmount | toCurrency }}/mês</label
            >
            <div class="hr-yellow"></div>
          </div> -->
          <p v-html="paidPlans.description">
            <!-- {{paidPlans.description}} -->
          </p>
          <hr />

          <ul v-for="benefit in paidPlans.benefits" :key="benefit._id">
            <li v-if="benefit.title != ''">
              <div class="benefit-item">
                <img class="benefit-icon" src="/static/svg/icon_check_blue.svg" alt=""
                  v-bind:style=" !benefit.included ? 'opacity 0.4; filter: grayscale(100%);' : 'opacity 1' " />
                <p class="benefit-text">{{ benefit.title }}</p>
              </div>
            </li>
          </ul>
          <hr />
          <div class="row">
            <div class="col-md-3">
              <button class="grey center" :class="{
                                active: intervalPlan == 1,
                                disable: !paidPlans.payment_1,
                              }" @click="intervalPlan = 1">
                Mensal
              </button>
            </div>
            <div class="col-md-3">
              <button class="grey center" :class="{
                                active: intervalPlan == 3,
                                disable: !paidPlans.payment_3,
                              }" @click="intervalPlan = 3">
                Trimestral
              </button>
            </div>
            <div class="col-md-3">
              <button class="grey center" :class="{
                                active: intervalPlan == 6,
                                disable: !paidPlans.payment_6,
                              }" @click="intervalPlan = 6">
                Semestral
              </button>
            </div>
            <div class="col-md-3">
              <button class="grey center" :class="{
                                active: intervalPlan == 12,
                                disable: !paidPlans.payment_12,
                              }" @click="intervalPlan = 12">
                Anual
              </button>
            </div>

            <div class="col-md-5" style="text-align: center;" v-if="paidPlans.type == 'COMPANY'">
              <p style="text-align: center; display: inline-block;" v-if="subscribedPlan">Licenças atuais:
                {{subscribedPlan.amount}} </p>
              <p style="text-align: center; display: inline-block;" v-else>Licenças atuais: 0 </p>
            </div>

            <div class="col-md-7" style="text-align: center;" v-if="paidPlans.type == 'COMPANY'">
              <p style="text-align: center; display: inline-block;">Licenças: </p>
              <input class="licenseAmount" id="licenseAmount" placeholder="Qdt" min="1" max="9999" type="number"
                v-model="licenseAmount">
            </div>

            <div class="col-md-12 last">
              <div class="plan-price">
                <label v-if="intervalPlan == 1 && paidPlans.payment_1">{{ paidPlans.payment_1.amount * licenseAmount |
                                  toCurrency }}/mês</label>
                <label v-if="intervalPlan == 3 && paidPlans.payment_3">{{ paidPlans.payment_3.amount * licenseAmount |
                                  toCurrency }}/trimestre</label>
                <label v-if="intervalPlan == 6 && paidPlans.payment_6">{{ paidPlans.payment_6.amount * licenseAmount |
                                  toCurrency }}/semestre</label>
                <label v-if="intervalPlan == 12 && paidPlans.payment_12">{{ paidPlans.payment_12.amount * licenseAmount |
                                  toCurrency }}/ano</label>
                <div class="hr-yellow"></div>
              </div>
            </div>

            <div class="col-md-12" style="text-align: center;" v-if="isSubscribed == 0">
              <button class="get-plan" @click="subscribe(paidPlans, intervalPlan, 1)" :disabled="isSending"
                v-bind:style=" paidPlans.type == 'COMPANY' ? 'margin-top: 32px;' : 'margin-top: 38px;'">
                Assinar plano
              </button>
            </div>

            <div class="col-md-6" style="text-align: center;" v-if="isSubscribed == 1"
              v-bind:class=" paidPlans.type == 'COMPANY' ? 'col-md-6' : 'col-md-12'">
              <button class="get-plan grey" @click="unsubscribe()" :disabled="isSending"
                style="width: 100%; font-size: 16px"
                v-bind:style=" paidPlans.type == 'COMPANY' ? 'margin-top: 32px;' : 'margin-top: 38px; width: 200px;'">
                Cancelar plano
              </button>
            </div>

            <div class="col-md-6" style="text-align: center;" v-if="isSubscribed == 1 && paidPlans.type == 'COMPANY'">
              <button class="get-plan" @click="subscribe(paidPlans, intervalPlan, 1)" :disabled="isSending"
                style="width: 100%;"
                v-bind:style=" paidPlans.type == 'COMPANY' ? 'margin-top: 32px;' : 'margin-top: 38px;'">
                Atualizar plano
              </button>
            </div>
          </div>
        </div>
      </div>


      <div v-if="freePlans && freePlans._id"> <!-- free plans -->
        <div class="plan-free-box">
          <h3>{{ freePlans.name }}</h3>
          <p v-html="freePlans.description"></p>
          <hr />

          <ul v-for="benefit in freePlans.benefits" :key="benefit._id">
            <li v-if="benefit.title != ''">
              <div class="benefit-item">
                <img src="/static/svg/icon_check_blue.svg" alt="" class="icon-yellow benefit-icon" />
                <p class="benefit-text">{{ benefit.title }}</p>
              </div>
            </li>
          </ul>
          <hr />
          <div class="row">
            <div class="col-md-3">
              <button class="grey center" :class="{
                                active: intervalPlan == 1,
                                disable: !freePlans.payment_1 || isSubscribed == 1,
                              }" @click="intervalPlan = 1">
                Mensal
              </button>
            </div>
            <div class="col-md-3">
              <button class="grey center" :class="{
                                active: intervalPlan == 3,
                                disable: !freePlans.payment_3 || isSubscribed == 1,
                              }" @click="intervalPlan = 3">
                Trimestral
              </button>
            </div>
            <div class="col-md-3">
              <button class="grey center" :class="{
                                active: intervalPlan == 6,
                                disable: !freePlans.payment_6 || isSubscribed == 1,
                              }" @click="intervalPlan = 6">
                Semestral
              </button>
            </div>
            <div class="col-md-3">
              <button class="grey center" :class="{
                                active: intervalPlan == 12,
                                disable: !freePlans.payment_12 || isSubscribed == 1,
                              }" @click="intervalPlan = 12">
                Anual
              </button>
            </div>

            <div class="col-md-12" style="text-align: center;" v-if="freePlans.type == 'COMPANY'">
              <p style="text-align: center; display: inline-block;">Licenças: </p>
              <input class="licenseAmount" id="licenseAmount" placeholder="1" value="1" disabled>
            </div>

            <div class="col-md-12" style="text-align: center;">
              <button class="get-plan" :disabled="true" v-if="user._id"
                v-bind:style=" freePlans.type == 'COMPANY' ? 'margin-top: 12px;' : 'margin-top: 38px;'">Plano
                padrão</button>
              <button class="get-plan" :disabled="false" @click="goToRegister(freePlans)"
                v-bind:style=" freePlans.type == 'COMPANY' ? 'margin-top: 12px;' : 'margin-top: 38px;'" v-else>Plano
                padrão</button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <company-register-modal :modal_id="'companyRegisterModal'"></company-register-modal>
    <salesman-register-modal :modal_id="'salesmanRegisterModal'"></salesman-register-modal>
  </div>
</template>

<script>
import { server, local } from "../js/variables.js";
import userMixin from "../mixins/userMixin.js";
import CompanyRegisterModal from "../components/CompanyRegisterModal.vue";
import SalesmanRegisterModal from "../components/SalesmanRegisterModal.vue";
import $ from 'jquery'

export default {
  mixins: [userMixin],
  props: ["isSubscribed", "paidPlans", "freePlans", "subscribedPlan"],
  components: {
    CompanyRegisterModal,
    SalesmanRegisterModal,
  },
  data() {
    return {
      url: window.location.href,
      planList: [],
      intervalPlan: 1,
      subscription: {
        plan_id: "",
        plan_payment_id: "",
        amount: '',
      },
      licenseAmount: 1,
      licenseAmountMin: 1,
      isSending: false,
      alreadyLoaded: false,
      monthTypeList: {
        1: 'mensal',
        3: 'trimestral',
        6: 'semestral',
        12: 'anual',
      },
    };
  },
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    // this.getPlans();
    if(this.paidPlans){
      if(this.paidPlans.payment_1){
        this.intervalPlan = 1;
      }else if(this.paidPlans.payment_3){
        this.intervalPlan = 3;
      }else if(this.paidPlans.payment_6){
        this.intervalPlan = 6;
      }else if(this.paidPlans.payment_12){
        this.intervalPlan = 12;
      }
    }

    
  },
  beforeUpdate(){
    if(!this.alreadyLoaded && this.subscribedPlan && this.subscribedPlan._id){
      // console.log('entrou subscribedPlan');
      this.licenseAmount = this.subscribedPlan.amount;
      // console.log('aqui2', this.subscribedPlan);
      this.alreadyLoaded = true;
    }
    
  },
  methods: {
    goToRegister(plan){
      if(plan.type == 'SALESMAN' || plan.type == 'AFILIATE'){
        // window.open("/vc/cadastro", "_self");
        
        $('#salesmanRegisterModal').modal('show');
      }
      if(plan.type == 'COMPANY'){
        // window.open("/co/cadastro", "_self");
        
        $('#companyRegisterModal').modal('show');
      }
    },
    getPlans() {
      let self = this;
      // if (!localStorage.token) {
      //     return
      // }
      // server.config.headers = {
      //     Authorization: 'Bearer ' + localStorage.token
      // }
      this.axios
        .get(server.url + "/api/plan?is_enabled=true", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.planList = responseData.plan_list;
          for (let plan of self.planList) {
            if (
              plan.type == "COMPANY" &&
              // plan.name != "Plano Empresarial Gratúito"
              plan.payment_option.length > 0
            ) {
              self.employeePlan = plan;
            } else if (
              plan.type == "SALESMAN" &&
              // plan.name != "Profissional Gratúito"
              plan.payment_option.length > 0
            ) {
              self.salesmanPlan = plan;
            } else if (
              plan.type == "AFILIATE" &&
              // plan.name != "Plano Afiliado Gratúito"
              plan.payment_option.length > 0
            ) {
              self.afiliatePlan = plan;
            // } else if (plan.name == "Plano Empresarial Gratúito") {
            } else if (plan.type == "COMPANY" && plan.payment_option.length == 0) {
              self.employeeFreePlan = plan;
            // } else if (plan.name == "Profissional Gratúito") {
            } else if (plan.type == "SALESMAN" && plan.payment_option.length == 0) {
              self.salesmanFreePlan = plan;
            // } else if (plan.name == "Plano Afiliado Gratúito") {
            } else if (plan.type == "AFILIATE" && plan.payment_option.length == 0) {
              self.afiliateFreePlan = plan;
            }
          }
          for (let payment of self.employeePlan.payment_option) {
            if (payment.interval == 1) {
              self.employeePlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.employeePlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.employeePlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.employeePlan.payment_12 = payment;
            }
          }

          for (let payment of self.salesmanPlan.payment_option) {
            if (payment.interval == 1) {
              self.salesmanPlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.salesmanPlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.salesmanPlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.salesmanPlan.payment_12 = payment;
            }
          }

          for (let payment of self.afiliatePlan.payment_option) {
            if (payment.interval == 1) {
              self.afiliatePlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.afiliatePlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.afiliatePlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.afiliatePlan.payment_12 = payment;
            }
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    subscribe(plan, interval) {
      let self = this;
      let type = '';
      if (!localStorage.token) {
        // return;
        if(plan.type == 'SALESMAN' || plan.type == 'AFILIATE'){
          $('#salesmanRegisterModal').modal('show');
          // const url = "/vc/cadastro";
          // self.$router.push(url);
          return
        }
        if(plan.type == 'COMPANY'){
          $('#companyRegisterModal').modal('show');
          // const url = "/co/cadastro";
          // self.$router.push(url);
          return
        }
      }
      if(plan.type == 'SALESMAN' || plan.type == 'AFILIATE'){
        // console.log('plano salesman', local.company)
        if(local.company){
          // console.log('plano salesman ENTROU', local.company)
          self.$swal({
            icon: 'warning',
            title: 'Atenção',
            text: 'Plano disponível apenas para representantes',
          })
          return
        }
      }
      if(plan.type == 'COMPANY'){
        // console.log('plano empresa')
        if(!local.company){
          self.$swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Plano disponível apenas para empresas',
          })
          return
        }
      }

      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      let object = {};
      if(local.company){
        object = local.company;
        type = 1;
      }else{
        object = local.user;
        type = 0;
      }
      // console.log('object', object);
      if (object.card.length == 0) {
        self
          .$swal({
            icon: "error",
            title: "Necessário cadastrar um cartão de crédito.",
            html: "Gostaria de cadastrar seu cartão agora ?",
            showCancelButton: true,
            confirmButtonColor: "#053360",
            confirmButtonText: "Sim!",
            cancelButtonText: "Depois",
          })
          .then((result) => {
            if (result.isConfirmed) {
              if (type == 0) {
                // window.open("/vc/cartoes", "_self");
                const url = "/vc/cartoes";
                self.$router.push(url);
              } else {
                // window.open("/co/cartoes", "_self");
                const url = "/co/cartoes";
                self.$router.push(url);
              }
            }
          });
        return;
      }

      if (object.gateway_id == null && type == 1) {
        self
          .$swal({
            icon: "error",
            title:
              "É necessario completar seus dados e o da empresa antes de assinar um plano",
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: "#053360",
            confirmButtonText: "Ok",
          })
          .then((result) => {
            if (result.isConfirmed) {
              //   window.open("/co/meus-dados", "_self");
            }
          });
      } else if (object.gateway_id == null && type == 0) {
        self
          .$swal({
            icon: "error",
            title:
              "É necessario completar seus dados antes de assinar um plano",
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: "#053360",
            confirmButtonText: "Ok",
          })
          .then((result) => {
            if (result.isConfirmed) {
              if (type == 0) {
                window.open("/vc/dados", "_self");
              } 
              // else {
              //   window.open("/co/meus-dados", "_self");
              // }
            }
          });
      } else {
        let price;
        self.subscription.plan_id = plan._id;
        let monthType;
        if (interval == 1) {
          self.subscription.plan_payment_id = plan.payment_1._id;
          price = plan.payment_1.amount;
          monthType = 'mês';
        } else if (interval == 3) {
          self.subscription.plan_payment_id = plan.payment_3._id;
          price = plan.payment_3.amount;
          monthType = 'trimestre';
        } else if (interval == 6) {
          self.subscription.plan_payment_id = plan.payment_6._id;
          price = plan.payment_6.amount;
          monthType = 'semestre';
        } else if (interval == 12) {
          self.subscription.plan_payment_id = plan.payment_12._id;
          price = plan.payment_12.amount;
          monthType = 'ano';
        } else {
          return;
        }
        self.$swal({
          icon: 'info',
          title: `Assinatura do ${plan.name} ${self.monthTypeList[interval]}`,
          html: `Valor: ${this.convertToCurrency(price*self.licenseAmount)} por ${monthType} <br> 
            Cartão: **** **** **** ${object.card[0].last_four} <br>
            A sua compra pode levar até 2 minutos para ser processada.
          `,
          showCancelButton: true,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Aprovar',
          cancelButtonText: "Voltar",
        }).then((result) => {
          if (result.isConfirmed) {
            self.subscription.amount = parseInt(self.licenseAmount);
            self.isSending = true;
            this.axios
              .post(
                server.url + "/api/subscription",
                {
                  subscription: self.subscription,
                },
                server.config
              )
              .then(function (response) {
                self.isSending = false;
                let responseData = response.data;
                self
                  .$swal({
                    icon: "success",
                    title: "Sucesso",
                    text: responseData.message,
                    showCancelButton: false,
                    confirmButtonColor: "#053360",
                    confirmButtonText: "Ok",
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      location.reload();
                    }
                  });
              })
              .catch(function (error) {
                // console.log("update - error");
                self.isSending = false;
                self.output = error;
                self.showError(error);
              });
          }
        })
      }
    },
    unsubscribe() {
      let self = this;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      self.subscription.plan_id = self.subscribedPlan.plan._id;
      self.subscription.plan_payment_id = self.subscribedPlan.plan_payment;
      self.$swal({
          icon: 'info',
          title: `Cancelamento do ${self.subscribedPlan.plan.name} ${self.getPlanType(self.subscribedPlan)}`,
          html: `Você tem certeza que deseja cancelar sua assinatura ? <br>
            Caso deseje, você poderá utilizar o serviço até o fim do seu contrato.
          `,
          showCancelButton: true,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Confirmar',
          cancelButtonText: "Voltar",
        }).then((result) => {
          if (result.isConfirmed) {
            self.isSending = true;
            this.axios
              .delete(
                server.url + "/api/subscription/" + self.subscribedPlan._id,
                {
                  subscription: self.subscription,
                },
                server.config
              )
              .then(function (response) {
                self.isSending = false;
                let responseData = response.data;
                self
                  .$swal({
                    icon: "success",
                    title: "Sucesso",
                    text: responseData.message,
                    showCancelButton: false,
                    confirmButtonColor: "#053360",
                    confirmButtonText: "Ok",
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      location.reload();
                    }
                  });
              })
              .catch(function (error) {
                // console.log("update - error");
                self.isSending = false;
                self.output = error;
                self.showError(error);
              });
          }
        })
    },
    updatePlan(){
      let self = this;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      self.subscription.plan_id = self.subscribedPlan.plan._id;
      self.subscription.plan_payment_id = self.subscribedPlan.plan_payment;
      self.subscription.amount = parseInt(self.licenseAmount);
      // console.log("subscription cancel", self.subscription);

      self.isSending = true;
      this.axios
        .put(
          server.url + "/api/subscription/" + self.subscribedPlan._id,
          {
            subscription: self.subscription,
          },
          server.config
        )
        .then(function (response) {
          self.isSending = false;
          let responseData = response.data;
          self
            .$swal({
              icon: "success",
              title: "Sucesso",
              text: responseData.message,
              showCancelButton: false,
              confirmButtonColor: "#053360",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                location.reload();
              }
            });
        })
        .catch(function (error) {
          // console.log("update - error");
          self.isSending = false;
          self.output = error;
          self.showError(error);
        });
    },
    getPlanType(subscription){
      for(let payment of subscription.plan.payment_option){
        if(payment._id === subscription.plan_payment){
          return this.monthTypeList[payment.interval];
        }
      }
    },
  },
};
</script>

<style lang="scss">
.plan {
  // margin: 10px;
  // padding: 45px;
  // margin-bottom: 30px;
  height: 100%;

  // .active{
  //     background: var(--main-color-primary);
  //     color: white;
  // }
  h3 {
    font-size: 25px;
    display: inline;
  }

  p {
    text-align: left;
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
  }

  img.check {
    width: 25px !important;
  }

  .benefit-item {
    display: grid;
    // grid-template-columns: repeat(6, 1fr);
    grid-template-columns: 30px auto auto auto auto auto;

    .benefit-icon {
      grid-column: 1/2;
    }

    .benefit-text {
      grid-column: 2/7;
    }
  }

  .licenseAmount {
    width: 93px;
    margin-top: 18px;
    margin-left: 12px;
    padding-right: 5px !important;
  }

  // input::-webkit-outer-spin-button,
  // input::-webkit-inner-spin-button {
  //   -webkit-appearance: none;
  //   margin: 0;
  // }

  .grey {
    width: 82px;
    height: 29px;
    border-radius: 9px;
    color: var(--main-title-color);
    width: 100%;

    letter-spacing: 0.11px;
    font-size: 11px;
  }

  .col-md-3 {
    padding: 0px 1px;
  }

  .get-plan {
    // position: absolute;
    // bottom: 12px;
    height: 32px;
    width: 200px;
    margin-top: 12px;
    border-radius: 10px;
    color: var(--main-title-color);
  }

  .cancel-plan {
    position: absolute;
    background-color: var(--light-grey-color);
    width: 160px;
    left: 50%;
    margin-left: -80px;
    margin-top: 18px;
  }

  ul {
    p {
      margin-top: 0px;
      margin-bottom: 5px;
      margin-left: 10px;
      display: inline-block;
    }

    img {
      width: 25px;
      display: inline-block;
    }
  }

  .plan-paid-box,
  .plan-free-box {
    padding: 40px 0px;
    padding-left: 40px;
    // border-radius: 10px;
    // box-shadow: 0px 0px 18px 0px #c7d2dc;
    background-color: white;
    height: 100%;

    .plan-price {
      margin-top: 15px;

      label {
        font-weight: bold;

        font-size: 20px;
        margin-bottom: 8px;
      }

      display: inline;
      float: right;
    }

    a {
      color: var(--main-text-color);
    }
  }

  .plan-free-box {
    padding-right: 40px;
    padding-left: 0px;
  }

  .plan-box-corp {
    padding: 40px;
    background-color: var(--main-color-primary);
    border-radius: 10px;
    height: 390px;

    .plan-price {
      label {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 8px;
        color: white;
      }

      display: inline;
      float: right;
    }

    h3,
    a,
    p {
      color: white;
    }
  }
}

@media only screen and (max-width: 600px) {
  .plan {
    p {
      margin-top: 25px;
    }

    .grey,
    .border-white {
      margin-top: 8px;
      // width: 100%;
    }

    // .border-white{
    // 	margin-top: 8px;
    // 	width: 150px;
    // }

    .plan-paid-box,
    .plan-free-box {
      padding: 40px 30px;
      // padding-left: 40px;
    }


    .get-plan {
      margin-top: 18px;
    }
  }
}
</style>