<template>
<div>
    <div class="benefits container">
        <h2>Vantagens para você</h2>
        <br>
        <div class="hr-yellow hr-4 center"></div>
        <br>
        <div class="row">
            <div class="col-md-4 benefits-item">
                <img class="icon-left" src="/static/svg/icon_fire.svg" alt="">
                <h4>Vantagem primeira</h4>
                <p>Portal exclusivo para soluções da área comercial para você e sua empresa. No vocevende você encontra  vendedores e representantes para vender seus produtos.</p>
                <div class="hr-yellow hr-4"></div>
            </div>

            <div class="col-md-4 benefits-item">
                <img class="icon-left" src="/static/svg/icon_fire.svg" alt="">
                <h4>Vantagem segunda</h4>
                <p>Sua empresa cadastra vagas de profissionais e executivos de vendas para montar seu time de vendas e você cadastra seu currículo e busca oportunidades.</p>
                <div class="hr-yellow hr-4"></div>
            </div>

            <div class="col-md-4 benefits-item">
                <img class="icon-left" src="/static/svg/icon_fire.svg" alt="">
                <h4>Vantagem terceira</h4>
                <p>Crie, cadastre, ministre e venda seu treinamento de vendas. Sua empresa cadastra treinamentos exclusivos para seus funcionários e time de vendas.</p>
                <div class="hr-yellow hr-4"></div>
            </div>
            <div class="col-md-4 benefits-item">
                <img class="icon-left" src="/static/svg/icon_fire.svg" alt="">
                <h4>Vantagem quarta</h4>
                <p>Você recebe um link de afiliado e quem se cadastrar a partir dele e adquirir um plano pago, você recebe um bônus e ainda gerencia seus recebimentos por aqui mesmo. Indique e ganhe!</p>
                <div class="hr-yellow hr-4"></div>
            </div>

            <div class="col-md-4 benefits-item">
                <img class="icon-left" src="/static/svg/icon_fire.svg" alt="">
                <h4>Vantagem quinta</h4>
                <p>Você autônomo ou pequeno empresário poderá ter vendedores vendendo para você seus produtos e serviços. Agora ficou fácil ter uma equipe de vendas!</p>
                <div class="hr-yellow hr-4"></div>
            </div>

            <div class="col-md-4 benefits-item">
                <img class="icon-left" src="/static/svg/icon_fire.svg" alt="">
                <h4>Vantagem sexta</h4>
                <p>O vocevende cria uma loja virtual que você poderá utilizar através de um link exclusivo criado para você, com produtos e serviços que você está vendendo e representando.</p>
                <div class="hr-yellow hr-4"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
  components: {
    
  },
  data() {
    return {
        showShareBox: false,
        url: window.location.href,
        contact: {
            name: '',
            email: '',
            subject: '',
            message: '',
        }
    };
  },
  methods: {
    
  }
};
</script>

<style lang="scss">

.benefits {
	// margin: 10px;
	padding: 60px 50px;
	
    h2{
        text-align: center;
        font-size: 30px;
    }
    p{
        text-align: left;
        margin-bottom: 20px;
    }

    img{
        position: absolute;
        left: 0px;
    }
    
    .benefits-item{
        padding: 15px;
        padding-left: 55px;
    }

    .hr-yellow{
        position: absolute;
        bottom: 15px;
    }
    
}



@media only screen and (max-width: 600px) {
  
}
</style>