<template>
  <div class="be-a-teacher">
    <div class="container be-a-teacher section">
      <div class="row">
        <div class="col-md-6 d-none d-md-block d-lg-block">
          <div class="img-left" style="background-image: url(./static/img/be-a-teacher-home.jpg)"></div>

        </div>
        <div class="col-md-6">
          <h2 class="h2-bigger">Seja um treinador</h2>
          <!-- <div class="hr-yellow hr-4"></div> -->
          <hr>
          <p>
            Crie, cadastre, ministre e venda seu treinamento de vendas para todas as empresas e profissionais no
            vocevende. Crie seu próprio conteúdo.
            <br><br>
            Ofereça treinamento de vendas para sua equipe planejar suas abordagens, argumentar com consistência e
            sobrepor
            objeções, obtendo resultados mais rapidamente.
            <br><br>
            Cadastre treinamentos online exclusivos para sua empresa e funcionários.
            <br><br>
            Se você tem vontade de empreender na internet, comece criando um treinamento e transmita todo seu
            conhecimento.
            <br><br>
            Vender cursos online é uma alternativa para transmitir conhecimento de maneira rápida e simples. Cadastre-se
            gratuitamente e comece agora.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VideoPlayerItem from "../components/VideoPlayerItem.vue";
export default {
  components: {
    // VideoPlayerItem,
  },
  data() {
    return {
      url: window.location.href,
    };
  },
  methods: {
    //TODO: enviar mensagem
  },
};
</script>

<style lang="scss">
.be-a-teacher {
  background-color: var(--main-color-lightgrey);

  .be-a-teacher {
    margin-top: 30px;
    margin-bottom: 30px;

    h2 {
      text-align: left;
      margin-bottom: 8px;
    }

    p {
      text-align: left;
      margin-top: 20px;
    }
  }

  .img-left {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    height: 100%;
    width: 100%;
  }

}

@media only screen and (max-width: 600px) {
  .be-a-teacher {
    padding: 45px 0px;
    margin: 50px 0px;
  }
}
</style>