import Vue from 'vue'
import Vuex from 'vuex'

import 'bootstrap'

import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import VuePwaInstallPlugin from "vue-pwa-install";
import SocialSharing from 'vue-social-sharing'
import * as VueGoogleMaps from 'vue2-google-maps'
// import VueQrcodeReader from 'vue-qrcode-reader'
import VueCookies from 'vue-cookies'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import vSelect from 'vue-select'
import VueSweetalert2 from 'vue-sweetalert2';
import DatePicker from 'vue2-datepicker';
import FileUpload from 'v-file-upload'
import VueEditor from "vue2-editor"
import _ from 'lodash'
import uuid from "device-uuid";
import 'es6-promise/auto'
import VueScrollTo from 'vue-scrollto';
import Router from './services/router'
import Store from './services/store'
import draggable from 'vuedraggable'
import VideoPlayer from 'vue-vjs-hls'
import CookieLaw from 'vue-cookie-law'
import money from 'v-money'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'vue2-datepicker/index.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'video.js/dist/video-js.css'
import 'video.js/dist/video-js.min.css'


Vue.use(Vuex);
Vue.use(FileUpload);
Vue.use(VueCookies);
// Vue.use(VueQrcodeReader);
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(VuePwaInstallPlugin);
Vue.use(SocialSharing);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.component('v-select', vSelect)
Vue.use(VueSweetalert2);
Vue.use(VueEditor);
Vue.use(_);
Vue.use(uuid);
Vue.use(VueScrollTo);
Vue.use(DatePicker);
Vue.use(draggable);
Vue.use(CookieLaw);
Vue.use(money, { prefix: 'R$ ', decimal: ',', thousands: '.', precision: 2 })

Vue.use(VideoPlayer)
// Vue.use(VideoPlayer, /* {
//   options: global default videojs options,
//   events: global videojs videojs events
// } */)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDy4X6pzzYCLP021PBqluoyv3eB48JScRY',
    libraries: 'places',
  },
  installComponents: true
});

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

Vue.config.productionTip = false

require('./filters/utils');

import { server } from './js/variables.js'

import routes from './routes.js'
import company from './modules/company/module'
import user from './modules/user/module'
import employee from './modules/employee/module'
import moment from 'moment'

Router.addRoutes(routes);

Store.registerModule('company', company.store)
Router.addRoutes(company.routes)
Store.dispatch('company/initialize', null, { root: true })

Router.addRoutes(routes);

Store.registerModule('user', user.store)
Router.addRoutes(user.routes)
Store.dispatch('user/initialize', null, { root: true })

Router.addRoutes(routes);

Store.registerModule('employee', employee.store)
Router.addRoutes(employee.routes)
Store.dispatch('employee/initialize', null, { root: true })

axios.interceptors.request.use(async (config) => {
  if (!config.url.endsWith('load')) {
    // console.log("Interceptor - request " + config.url);
    if (localStorage.token != null) {
      config.headers = {
        Authorization: 'Bearer ' + localStorage.token,
        Device: localStorage.device
      }
    } else {
      config.headers = {
        Device: localStorage.device
      }
    }
  } else {
    // console.log("Interceptor - load");
    if (localStorage.token != null) {
      config.headers = {
        Authorization: 'Bearer ' + localStorage.token,
        Device: localStorage.device
      }
    } else {
      config.headers = {
        Device: localStorage.device
      }
    }
    // console.log(config);

  }
  // console.log("Interceptor - ok ");
  return config;
}, (error) => {
  // I cand handle a request with errors here
  return Promise.reject(error);
});

const guard = function (to, from, next) {

  let path = encodeURI(window.location.pathname);
  // console.log("from", path);
  let self = this;

  axios.get(server.url + '/sale/customer/load', server.config)
    .then(function (response) {
      let responseData = response.data.data;
      let customer = responseData.customer;
      if (customer.email == null || customer.email == '[não informado]') {

        // window.open("./login?redirect="+path, "_self");
        self.$router.push("/login?redirect=" + path);
      }
      next();
    })
    .catch(function (error) {
      // window.open("./login", "_self");
      self.$router.push("/login");
    });
};

Vue.$cookies.config('30d');

var path = __dirname + 'commerce/';
// path = __dirname;

// const router = new VueRouter({
//   mode: 'history',
//   base: path,
//   routes: routes
// })

var template = "<div id='app'> <router-view/> </div>";

Vue.mixin({
  data: function () {
    return {
      employmentType: {
        FULLTIME: "Integral",
        PARTTIME: "Meio-período",
      },
      positionType: {
        INTERN: "Estagiário",
        JUNIOR: "Júnior",
        FULL: "Pleno",
        SENIOR: "Sênior",
        SUPERVISOR: "Supervisor",
        HEAD: "Head",
        ESPECIALIST: 'Especialista',
        COORDINATOR: "Coordenador",
        MANAGER: "Gerente",
        DIRECTOR: "Diretor",
        VP: "VP",
        ESPECIALISTA_COMERCIAL: "Especialista comercial",
        CONSULTOR_COMERCIAL: "Consultor comercial",
        REPRESENTANTE_VENDAS: "Representante de vendas",
        VENDEDOR: "Vendedor",
        EXECUTIVO_VENDAS: "Executivo de vendas",
        SALES_TRADER: "Sales trader",
        KEY_ACCOUNT: "Key account",
        KEY_ACCOUNT_MANAGER: "Key account Manager",
        GERENTE_VENDAS: "Gerente de vendas",
        GERENTE_MARKETING_VENDAS: "Gerente de marketing e vendas",
        SUPERVISOR_VENDAS: "Supervisor de vendas",
        COORDENADOR_VENDAS: "Coordenador de vendas",
        GERENTE_LOJA: "Gerente de loja",
        SUPERVISOR_LOJA: "Supervisor de loja",
        VENDEDOR_LOJA_SHOPPING: "Vendedor de loja shopping center",
        GERENTE_INTELIGENCIA_COMERCIAL: "Gerente de inteligência comercial",
        GERENTE_NOVOS_NEGOCIOS: "Gerente de novos negócios",
        DIRETOR_VENDAS: "Diretor de vendas",
        HEAD_VENDAS: "Head de vendas",
        VP_VENDAS: "VP de vendas",
        SUPORTE_VENDAS: "Suporte a vendas",
        PROSPECTOR_AGENDADOR: "Prospector / Agendador",
        ANALISTA_VENDAS: "Analista de vendas",
        ANALISTA_VENDAS_JUNIOR: "Analista de vendas júnior",
        ANALISTA_VENDAS_PLENO: "Analista de vendas pleno",
        ANALISTA_VENDAS_SENIOR: "Analista de vendas sênior",
        AUXILIAR_VENDAS: "Auxiliar de vendas",
        ASSISTENTE_VENDAS: "Assistente de vendas",
        AUXILIAR_ADMINISTRATIVO_VENDAS: "Auxiliar administrativo de vendas",
        CORRETOR: "Corretor",
        CORRETOR_SEGUROS: "Corretor de seguros",
        CORRETOR_IMOBILIARIO: "Corretor imobiliário",
        VENDEDOR_PLANO_SAUDE: "Vendedor de plano de saúde",
        null: '-',
      },
      degreeType: {
        MBA: "MBA",
        MESTRADO: "Mestrado",
        POSDOUTORADO: "Pós-doutorado",
        DOUTORADO: "Doutorado",
        GRADUACAOBACHARELADO: "Graduação/Bacharelado",
        LICENCIATURA: "Licenciatura",
        TECNOLOGO: 'Tecnólogo',
        ESPECIALIZACAO: "Especialização",
        ENSINOMEDIO: "Ensino médio",
        ENSINOFUNDAMENTAL: "Ensino fundamental",
        CURSANDO: "Cursando",
      },
      opportunityStatus: {
        OPEN: 'Aberta',
        CLOSED: 'Fechada',
        CANCELED: 'Cancelada',
        STANDBY: 'Stand by'
      },
      orderStatus: {
        PAID: 'Pago',
        AWAIT_ORDER: 'Pedido em processamento',
        AWAIT: 'Pedido em processamento',
        AWAIT_PAYMENT: 'Aguardando pagamento',
        CANCEL: 'Cancelada',
      },
      subscriptionStatus: {
        ACTIVE: 'Ativo',
        AWAIT_ORDER: 'Pedido em processamento',
        AWAIT: 'Pedido em processamento',
        AWAIT_PAYMENT: 'Aguardando pagamento',
        CANCELLED: 'Cancelado',
      },
      candidateStatus: {
        NOTFOUND: '(não inscrito)',
        AWAIT: 'Esperando',
        APPROVED: 'Aprovado',
        DENIED: 'Negado',
        CANCELED: 'Cancelado',
      },
      proficiencyLevelLanguage: [
        "(Sem conhecimento)",
        "Básico",
        // "Básico/ intermediário",
        "Intermediário",
        "Avançado",
        "Fluente",
      ],
      proficiencyLevelSegment: [
        "(Sem conhecimento)",
        "Básico",
        // "Básico/ intermediário",
        "Intermediário",
        "Avançado",
        // "Experiente",
      ],
      stateList: [],
      cityList: [],
      segmentList: [],
      monthList: [
        { _id: 1, value: 'Janeiro' },
        { _id: 2, value: 'Fevereiro' },
        { _id: 3, value: 'Março' },
        { _id: 4, value: 'Abril' },
        { _id: 5, value: 'Maio' },
        { _id: 6, value: 'Junho' },
        { _id: 7, value: 'Julho' },
        { _id: 8, value: 'Agosto' },
        { _id: 9, value: 'Setembro' },
        { _id: 10, value: 'Outubro' },
        { _id: 11, value: 'Novembro' },
        { _id: 12, value: 'Dezembro' },
      ],
      yearList: [],
      positionList: [
        { label: "Estagiário", value: "INTERN" },
        { label: "Júnior", value: "JUNIOR" },
        { label: "Pleno", value: "FULL" },
        { label: "Sênior", value: "SENIOR" },
        { label: "Supervisor", value: "SUPERVISOR" },
        { label: "Head", value: "HEAD" },
        { label: "Especialista", value: "ESPECIALIST" },
        { label: "Coordenador", value: "COORDINATOR" },
        { label: "Gerente", value: "MANAGER" },
        { label: "Diretor", value: "DIRECTOR" },
        { label: "VP", value: "VP" },
        { label: "Especialista comercial", value: "ESPECIALISTA_COMERCIAL" },
        { label: "Consultor comercial", value: "CONSULTOR_COMERCIAL" },
        { label: "Representante de vendas", value: "REPRESENTANTE_VENDAS" },
        { label: "Vendedor", value: "VENDEDOR" },
        { label: "Executivo de vendas", value: "EXECUTIVO_VENDAS" },
        { label: "Sales trader", value: "SALES_TRADER" },
        { label: "Key account", value: "KEY_ACCOUNT" },
        { label: "Key account Manager", value: "KEY_ACCOUNT_MANAGER" },
        { label: "Gerente de vendas", value: "GERENTE_VENDAS" },
        { label: "Gerente de marketing e vendas", value: "GERENTE_MARKETING_VENDAS" },
        { label: "Supervisor de vendas", value: "SUPERVISOR_VENDAS" },
        { label: "Coordenador de vendas", value: "COORDENADOR_VENDAS" },
        { label: "Gerente de loja", value: "GERENTE_LOJA" },
        { label: "Supervisor de loja", value: "SUPERVISOR_LOJA" },
        { label: "Vendedor de loja shopping center", value: "VENDEDOR_LOJA_SHOPPING" },
        { label: "Gerente de inteligência comercial", value: "GERENTE_INTELIGENCIA_COMERCIAL" },
        { label: "Gerente de novos negócios", value: "GERENTE_NOVOS_NEGOCIOS" },
        { label: "Diretor de vendas", value: "DIRETOR_VENDAS" },
        { label: "Head de vendas", value: "HEAD_VENDAS" },
        { label: "VP de vendas", value: "VP_VENDAS" },
        { label: "Suporte a vendas", value: "SUPORTE_VENDAS" },
        { label: "Prospector / Agendador", value: "PROSPECTOR_AGENDADOR" },
        { label: "Analista de vendas", value: "ANALISTA_VENDAS" },
        { label: "Analista de vendas júnior", value: "ANALISTA_VENDAS_JUNIOR" },
        { label: "Analista de vendas pleno", value: "ANALISTA_VENDAS_PLENO" },
        { label: "Analista de vendas sênior", value: "ANALISTA_VENDAS_SENIOR" },
        { label: "Auxiliar de vendas", value: "AUXILIAR_VENDAS" },
        { label: "Assistente de vendas", value: "ASSISTENTE_VENDAS" },
        { label: "Auxiliar administrativo de vendas", value: "AUXILIAR_ADMINISTRATIVO_VENDAS" },
        { label: "Corretor", value: "CORRETOR" },
        { label: "Corretor de seguros", value: "CORRETOR_SEGUROS" },
        { label: "Corretor imobiliário", value: "CORRETOR_IMOBILIARIO" },
        { label: "Vendedor de plano de saúde", value: "VENDEDOR_PLANO_SAUDE" },
      ],
      degreeList: [
        { label: "MBA", value: "MBA" },
        { label: "Mestrado", value: "MESTRADO" },
        { label: "Pós-doutorado", value: "POSDOUTORADO" },
        { label: "Doutorado", value: "DOUTORADO" },
        { label: "Graduação/Bacharelado", value: "GRADUACAOBACHARELADO" },
        { label: "Licenciatura", value: "LICENCIATURA" },
        { label: "Tecnólogo", value: "TECNOLOGO" },
        { label: "Especialização", value: "ESPECIALIZACAO" },
        { label: "Ensino médio", value: "ENSINOMEDIO" },
        { label: "Ensino fundamental", value: "ENSINOFUNDAMENTAL" },
        { label: "Cursando", value: "CURSANDO" },
      ],
      genderOptions: [
        { label: "Masculino", name: "M" },
        { label: "Feminino", name: "F" },
        { label: "Outros", name: "O" },
        { label: "Prefiro não informar", name: "N" },
      ],
      cityPlaceholder: 'Selecione estado',
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ 'color': [] }, { 'background': [] }],
      ]
    }
  },
  methods: {
    getImageUrl(image) {
      return image?.url ?? ''
    },
    getImageThumbUrl(image) {
      return image?.thumb_url ?? ''
    },
    getVideoUrl(video) {
      return video?.video_url ?? ''
    },
    subscriptionStatusClass(status) {
      switch (status) {
        case 'CANCELLED':
          return 'status-text-red';

        case 'ACTIVE':
          return 'status-text-green';

        default:
          return 'status-text-blue';
      }
    },
    orderStatusClass(status) {
      switch (status) {
        case 'CANCEL':
          return 'status-text-red';

        case 'PAID':
          return 'status-text-green';

        default:
          return 'status-text-blue';
      }
    },
    findSegments() {
      let self = this;
      this.axios
        .get(server.url + "/api/skill?filter=INDUSTRY_GENERAL", server.config)
        .then(function (response) {
          self.segmentList = [];
          let responseData = response.data.data.skill_list;
          self.segmentList = responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    findPlaces() {
      let self = this;
      this.findStates();
      // this.findCities()

      // this.axios
      //   .get(server.url + "/api/user/places", server.config)
      //   .then(function (response) {
      //     self.stateList= [];
      //     self.cityList= [];
      //     let responseData = response.data.data;

      //     for (let state of responseData.state_list) {
      //       if (state != '') self.stateList.push(state);
      //     }

      //     for (let city of responseData.city_list) {
      //       if (city != '') self.cityList.push(city);
      //     }
      //     // self.stateList = responseData.state_list;
      //     // self.cityList = responseData.city_list;
      //   })
      //   .catch(function (error) {
      //     self.output = error;
      //     self.showError(error);
      //   });

    },
    findStates() {
      let self = this;
      this.axios
        .get(server.url + "/webservices/ibge/estados", server.config)
        .then(function (response) {
          self.stateList = [];
          let responseData = response.data.data;

          for (let state of responseData.state_list) {
            if (state != '') self.stateList.push(state);
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    findCities(state) {
      let self = this;
      let stateName = '';
      if (state != undefined && state != '') {
        self.cityPlaceholder = 'Carregando...';
        stateName += '?uf=' + state;
        this.axios
          .get(server.url + "/webservices/ibge/cidades" + stateName, server.config)
          .then(function (response) {
            self.cityPlaceholder = 'Selecione';
            self.cityList = [];
            let responseData = response.data.data;

            for (let city of responseData.city_list) {
              if (city != '') self.cityList.push(city);
            }
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      }
    },
    goTo(url) {
      // window.open(url, "_self");
      // console.log("goTo", url);
      url = url.replace(".", "");
      this.$router.push(url);
    },
    showError(error) {
      if (error.response) {
        var answer = error.response.data;
        // var errorList = self.$cookies.get('errorList');
        // if (!errorList) errorList = []; 
        // else errorList = JSON.parse(errorList);
        // errorList.push(answer.message);
        // self.$cookies.set( 'errorList', JSON.stringify( errorList) );
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: answer.message,
          // footer: '<a href>Why do I have this issue?</a>'
        });
      } else if (error.request) {
        // console.log(error.request);
      } else {
        // console.log('Error', error.message);
      }
    },
    fixOneDate(date) {
      let f = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
      return moment(date).add(4, 'hours').format('DD/MM/YYYY');
    },
    convertToCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    }
  }
})

new Vue({
  router: Router,
  store: Store,
  template: template,
  data() {
    return {
      device: "",
      // constants

    }
  },
  // Lifecycle
  beforeCreate: function () {
    // console.log('main.js - beforeCreate');
    if (localStorage.device) {
      // console.log('Device loaded' , localStorage.device);
    } else {
      let device = uuid.DeviceUUID().get();
      localStorage.setItem('device', device);
      // console.log('New device' , localStorage.device);
    }
  },
  created: function () {
    // console.log('main.js - created');
  },
  beforeMount: function () {
    // console.log('main.js - beforeMount');
  },
  mounted() {
    var parameters = this.$route.query;
    if (parameters.afiliado) {
      localStorage.setItem('afiliate', parameters.afiliado);
    }
  },
  beforeUpdate: function () {
    // console.log('main.js - beforeUpdate');
  },
  updated: function () {
    // console.log('main.js - updated');
  },
  beforeDestroy: function () {
    // console.log('main.js - beforeDestroy');
  },
  destroyed: function () {
    // console.log('main.js - destroyed');
  },

}).$mount('#app')