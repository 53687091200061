import { server, local } from '../js/variables.js'
import moment from 'moment'

var sessionMixin = {
  data: function () {
    return {
      user: {},
      company: {},
      subscription: {},
      auth: {}
    }
  },
  watch: {
    user: function (val, oldVal) {
      //   console.log('user new: %s, old: %s', val, oldVal)
    },
    company: function (val, oldVal) {
      //   console.log('company new: %s, old: %s', val, oldVal)
    },
    subscription: function (val, oldVal) {
      //   console.log('company new: %s, old: %s', val, oldVal)
    },
    auth: function (val, oldVal) {
      // console.log('auth new: %s, old: %s', val, oldVal)
    },
  },
  // beforeCreate: function() {
  //     // console.log('sessionMixin beforeCreate');
  //     if (localStorage.token != null) {
  //         server.config.headers = {
  //         Authorization: 'Bearer ' + localStorage.token,
  //         Device: localStorage.device
  //         }
  //     } else {
  //         server.config.headers = {
  //         Device: localStorage.device
  //         }
  //     }
  // },
  created: function () {
    this.load();
  },
  methods: {
    load() {
      let self = this;
      if (!localStorage.token) {
        return
      }
      this.axios.get(server.url + '/api/auth/load/', server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.user = responseData.user;
          self.company = responseData.company;
          self.subscription = responseData.subscription;
          self.auth = responseData.auth;
          self.fixDate();
          self.insertMasks();
          local.user = self.user;
          local.company = self.company;
          local.subscription = self.subscription;
          local.permissions = responseData.permissions;
          local.subscriptionList = responseData.userSubscriptionList;
          local.studentList = responseData.studentList;
          local.cartList = responseData.cartList;

          localStorage.setItem('token', responseData.auth.token)
          localStorage.setItem('user', JSON.stringify( responseData.user) );
          localStorage.setItem('company', JSON.stringify( responseData.company));
        })
        .catch(function (error) {
          // console.log("sessionMixin load: ", error);
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem('company');
          // location.reload();
          // window.open("./login", "_self");
        });
    },
    fixDate() {
      this.user.birthdate = this.fixDatePatern(this.user.birthdate)
      if(this.company){
        if(this.company.birthdate){
          this.company.birthdate = this.fixDatePatern(this.company.birthdate)
        }
      }
      if (this.user.resume) {
        for (let experienceDate of this.user.resume.experience) {
          experienceDate.start_date = this.fixDatePatern(experienceDate.start_date);
          if(experienceDate.end_date == null){
            experienceDate.end_date = 'hoje'
          }else{
            experienceDate.end_date = this.fixDatePatern(experienceDate.end_date);
          }
          
        }
        for (let educationDate of this.user.resume.education) {
          educationDate.start_date = this.fixDatePatern(educationDate.start_date);
          if(educationDate.end_date == null){
            educationDate.end_date = 'hoje'
          }else{
            educationDate.end_date = this.fixDatePatern(educationDate.end_date);
          }
        }
      }

    },
    fixDatePatern(data) {
      let f = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
      return moment(data).add(4, 'hours').format('DD/MM/YYYY');
    },
    insertMasks() {
      if (this.user) {
        if (this.user.phone != '' && this.user.phone != null) {
          let phoneWithMask = '(' + this.user.phone.substring(0, 2) + ') ' + this.user.phone.substring(2, 7) + '-' + this.user.phone.substring(7, 11);

          this.user.phone = phoneWithMask;
        }
        if (this.user.document != '' && this.user.document != null) {
          let documentWithMask = this.user.document.substring(0, 3) + '.' + this.user.document.substring(3, 6) + '.' + this.user.document.substring(6, 9) + '-' + this.user.document.substring(9, 11);

          this.user.document = documentWithMask;
        }
      }

      if (this.company) {
        if (this.company.document != '' && this.company.document != null) {
          let documentWithMask = this.company.document.substring(0, 2) + '.' + this.company.document.substring(2, 5) + '.' + this.company.document.substring(5, 8) + '/' + this.company.document.substring(8, 12) + '-' + this.company.document.substring(12, 14);
          
          this.company.document = documentWithMask;
        }
        if (this.company.phone != '' && this.company.phone != null) {
          let phoneWithMask = '(' + this.company.phone.substring(0, 2) + ') ' + this.company.phone.substring(2, 7) + '-' + this.company.phone.substring(7, 11);
          this.company.phone = phoneWithMask;
        }
      }
    }
  }
}

export default sessionMixin;