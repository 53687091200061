<template>
  <div
    class="modal fade refer-and-win-modal"
    id="referAndWinModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
    style="background-color: #5c6377b8"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="refer-modal-top">
          <button
            class="btn-x"
            data-dismiss="modal"
            aria-label="Close"
            style="margin-top: 0px; margin-bottom: 0px"
          >
            X
          </button>
          <img
            src="/static/svg/icon_refer_logo.svg"
            alt=""
            class="refer-logo"
          />
          <p style="font-size: 15pt;">
            Profissionais ou empresas que utilizarem seu link ao adquirirem produtos ou serviços, irá gerar uma renda para você. <br>
            Acompanhe seus rendimentos no seu perfil.
          </p>
          <button class="refer-btn">
            <img src="/static/svg/icon_refer.svg" alt="" class="megaphone" />
            Indique e ganhe
          </button>
        </div>
        <div class="refer-modal-bottom d-block d-md-none d-lg-none">
          <div class="row container ">
            <div class="col-xs-6">
              <button class="refer-link-btn center">
                <img
                  src="/static/svg/icon_refer_facebook.svg"
                  alt=""
                  class="icons-btn"
                />
                Facebook
              </button>
            </div>
            <div class="col-xs-6">
              <button class="refer-link-btn center" @click="shareTwitter()">
                <img
                  src="/static/svg/icon_refer_twitter.svg"
                  alt=""
                  class="icons-btn"
                />
                Twitter
              </button>
            </div>
            <div class="col-xs-6">
              <button class="refer-link-btn center" @click="shareWhats()">
                <img
                  src="/static/svg/icon_refer_whats.svg"
                  alt=""
                  class="icons-btn"
                />
                Whatsapp
              </button>
            </div>
            <div class="col-xs-6">
              <button class="refer-link-btn center" @click="copyURL()">
                <img
                  src="/static/svg/icon_refer_share_link.svg"
                  alt=""
                  class="icons-btn"
                />
                Copiar link
              </button>
            </div>
          </div>
        </div>
        <div class="refer-modal-bottom d-none d-md-block d-lg-block">

          <div class="row container ">
            <div class="col-md-3">
              <button class="refer-link-btn center" @click="shareFacebook()">
                <img
                  src="/static/svg/icon_refer_facebook.svg"
                  alt=""
                  class="icons-btn"
                />
                Facebook
              </button>
            </div>
            <div class="col-md-3">
              <button class="refer-link-btn center" @click="shareTwitter()">
                <img
                  src="/static/svg/icon_refer_twitter.svg"
                  alt=""
                  class="icons-btn"
                />
                Twitter
              </button>
            </div>
            <div class="col-md-3">
              <button class="refer-link-btn center" @click="shareWhats()">
                <img
                  src="/static/svg/icon_refer_whats.svg"
                  alt=""
                  class="icons-btn"
                />
                Whatsapp
              </button>
            </div>
            <div class="col-md-3">
              <button class="refer-link-btn center" @click="copyURL()">
                <img
                  src="/static/svg/icon_refer_share_link.svg"
                  alt=""
                  class="icons-btn"
                />
                Copiar link
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from "../mixins/userMixin.js";
import { server, settings, local } from "../js/variables.js";
import moment from "moment";

export default {
  mixins: [userMixin],
  props: ["modal_id"],
  components: {},
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {
    shareFacebook(){
      if(this.user.slug){
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + window.location.origin + "/?afiliado=" + this.user.slug, "_blank");
      }
    },
    shareWhats(){
      if(this.user.slug){
        window.open("https://api.whatsapp.com/send?text=" + window.location.origin + "/?afiliado=" + this.user.slug, "_blank");
      }
    },
    copyURL() {
      if(this.user.slug){
        let url = window.location.origin + "/?afiliado=" + this.user.slug;
        // console.log(url)
        navigator.clipboard.writeText(url);
      }
    },
    shareTwitter(){
      if(this.user.slug){
        window.open("https://twitter.com/messages/compose?text=" + window.location.origin + "/?afiliado=" + this.user.slug, "_blank");
      }
      
    }
  },
};
</script>

<style lang="scss">
.refer-and-win-modal {
  .modal-content {
    padding: 0px;
    border-radius: 1.5rem;

    .refer-modal-top {
      padding: 120px;
      padding-top: 130px;
      padding-bottom: 80px;

      p {
        text-align: center;
        color: #333333;
      }

      .refer-btn {
        background-color: var(--main-color-secundary);
        color: var(--main-title-color);
        height: 54px;
        width: 250px;
        position: absolute;
        left: 50%;
        margin-left: -125px;
        bottom: 141px;
        font-size: 21px;
        cursor: initial;
        outline: none;
      }

      .megaphone {
        margin-left: -8px;
        margin-right: 10px;
      }
    }

    .refer-modal-bottom {
      background-color: var(--main-color-lightgrey);
      border-bottom-left-radius: 1.5em;
      border-bottom-right-radius: 1.5em;
      height: 168px;
      padding: 0px 16px;

      .row{
        position: absolute;
        bottom: 50px;
      }

      .refer-link-btn {
        width: 139px;
        height: 36px;
        border: 1px solid #c7c7c7;
        border-radius: 7px;
        background-color: transparent;
        color: var(--main-title-color);
      }

      .icons-btn {
        margin-right: 7px;
        margin-left: -12px;
        margin-top: -3px;
      }
    }

    .refer-logo {
      left: 50%;
      margin-left: -38px;
      position: absolute;
      top: -23px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .refer-and-win-modal {
    .modal-content {
      // padding: 70px 30px;

      .refer-modal-top {
        padding: 18px;
        padding-top: 100px;
        padding-bottom: 55px;
      }

      .refer-logo {
        margin-left: -25px;
        top: -9px;
        width: 50px;
      }

      .refer-modal-bottom {

        .row{
          bottom: 30px;
        }

        .col-xs-6{
          padding-left: 4px;
          padding-right: 4px;
          padding-bottom: 5px;
        }
      }
    }
  }
}
</style>