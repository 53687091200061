<template>
  <div class="menu-content-box subscription-history" v-if="subscriptionList.length > 0">
    <h2>Histórico de pagamento</h2>
    <br>
    <div class="row" style="margin-bottom: 25px">
      <div class="col-md-12" style="padding-bottom: 8px; padding-top: 8px;" v-for="subscription in subscriptionList" :key="subscription._id">
        <div>
          <div class="order-title" :class="subscription.status"
            data-toggle="collapse"
              :data-target="'#collapseExample'+subscription._id"
              aria-expanded="false"
              aria-controls="collapseExample">
            <div class="indication-box">
              {{subscription.plan.name}} - Status: <span v-bind:class="subscriptionStatusClass(subscription.status)">{{ subscriptionStatus[subscription.status] }}</span> 
              <button
                class="clean"
                type="button clean last" 
                v-if="subscription.payments.length > 0 || subscription.errors_log.length > 0">
                <i class="fas fa-sort-up"></i>
                <!-- <img src="/static/img/icons/arrow_down_green.png" /> -->
              </button>
            </div>
            <div class="indication-value-box">
              {{subscription.created_at}}
            </div>
            <!-- TODO: collapse para cada item -->
          </div>

          
          <div class="col-md-12 collapse order-items" :id="'collapseExample'+subscription._id" v-if="subscription.payments.length > 0 || subscription.errors_log.length > 0">
            <table v-if="subscription.payments.length > 0">
              <thead>
                <tr>
                  <th>
                    Data
                  </th>
                  <th>
                    Código da compra
                  </th>
                  <th>
                    Valor un
                  </th>
                  <th>
                    QTD
                  </th>
                  <th>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in subscription.payments" :key="payment._id">
                  <td data-label="Data">
                    {{payment.created_at | formatDateTime}}
                  </td>
                  <td data-label="Código da compra">
                    {{payment._id}}
                  </td>
                  <td data-label="Valor un">
                    {{(payment.amount.total/subscription.amount)/100 | toCurrency}}
                  </td>
                  <td data-label="QTD">
                    {{subscription.amount}}
                  </td>
                  <td data-label="Total">
                    {{payment.amount.total/100 | toCurrency}}
                  </td>
                </tr>
              </tbody>
            </table>

            <table v-if="subscription.errors_log.length > 0">
              <thead>
                <tr>
                  <th>
                    Data
                  </th>
                  <th>
                    Erros
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="error in subscription.errors_log" :key="error._id">
                  <td data-label="Data">
                    {{error.registered_at | formatDateTime}}
                  </td>
                  <td data-label="Erros">
                    {{error.message}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { server, local } from "../js/variables.js";

export default {
  props: ["subscriptionList"],
  components: {},
  data() {
    return {
    };
  },
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    
  },
  methods: {
    getPlanType(subscription){
      for(let payment of subscription.plan.payment_option){
        if(payment._id === subscription.plan_payment){
          return this.paymentMonthType[payment.interval];
        }
      }
    },
    getPlanPrice(subscription){
      for(let payment of subscription.plan.payment_option){
        if(payment._id === subscription.plan_payment){
          return payment.amount;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.subscription-history{
  
  h2 {
    font-size: 30px;
  }

  .indication-btns {
    width: 137px;
    height: 34px;
    color: var(--main-color-primary);
  }

  .v-select{
    width: 160px;
    height: 34px;
    display: inline-block;
  }

  .vs__dropdown-toggle{
    height: 38px;
  }

  .indication-box {
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    padding: 12px 36px;
    width: calc(100% - 95px);
    display: inline-block;

    font-size: 13px;
    font-weight: bold;
    color: var(--main-title-color);

    button{
      height: 14px;
      width: 14px;
      float: right;
      padding-top: 3px;
    }
  }

  .indication-value-box {
    background-color: var(--main-color-primary);
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    width: 95px;
    padding: 12px;
    display: inline-block;

    font-size: 13px;
    font-weight: bold;
    color: white;
    text-align: center;
  }

  .order-items{
    border: 1px solid #c7c7c7;
    border-top: 0px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-top: -5px;
    padding: 15px 15px;
    padding-top: 20px;
    background-color: var(--main-color-lightgrey);

    hr{
      margin-bottom: 0px !important; 
    }
  }

  table{
    width: 100%;

    th{
      color: var(--main-title-color);
      font-weight: bold;
    }

    th, td{
      padding: 0px 10px;
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    // border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    // text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
</style>