<template>
  <main-layout>
	<!-- <pre> password {{ $v.postAddress }}</pre> -->
	<!-- <pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container company-user-data light-grey-bg" id="companyuser-data-form">
		<!-- <br> -->
		<div class="menu-content-box">
		<div class="row">
			<div class="col-md-6 separator">
				<!-- <form> -->
					<div class="title-bg-grey">
						<h3>Meus dados</h3>
					</div>
					<br>
					<label for="name">Nome</label>
					<span v-if="$v.user.name.$error">Campo não pode estar vazio</span>
					<input id="name" type="text" v-model="$v.user.name.$model">

					<label for="lastname">Sobrenome</label>
					<span v-if="$v.user.lastname.$error">Campo não pode estar vazio</span>
					<input id="lastname" type="text" v-model="$v.user.lastname.$model">

					<label for="document">CPF</label>
					<span v-if="$v.user.document.$error">Verifique o formato do CPF</span>
					<input id="document" type="text" v-model="$v.user.document.$model" v-mask="'###.###.###-##'" :disabled="disableCpf">

					<label for="email">Email</label>
					<span v-if="$v.user.email.$error">Campo não pode estar vazio</span>
					<input id="email" type="text" v-model="$v.user.email.$model" :disabled="user.email != null && user.email != ''">
					
					<label for="birthdate">Nascimento</label>
					<span v-if="$v.user.birthdate.$error">Campo não pode estar vazio</span>
					<input id="birthdate" type="text" placeholder="DD/MM/AAAA" v-model="$v.user.birthdate.$model" v-mask="'##/##/####'">

					<label for="phone">Telefone</label>
					<span v-if="$v.user.phone.$error">Campo não pode estar vazio</span>
					<input id="phone" type="text" v-model="$v.user.phone.$model" v-mask="'(##) #####-####'">

					<!-- <div class="col-md-6"> -->
						<label for="gender">Gênero</label>
						<v-select label="label" 
							:options="genderOptions" 
							v-model="$v.user.gender.$model" 
							:reduce="gender => gender.name">
						</v-select>
					<!-- </div> -->

				<!-- </form> -->
			</div>
			<div class="col-md-6 separator">
				<div class="title-bg-grey">
					<h3>Endereço</h3>
				</div>
				<br>
				<!-- <input type="text" v-model="$v.teste.zipcode.$model" v-mask="'#####-###'" v-on:blur="getAddress" v-on:keyup.enter="getAddress" > -->

				<label for="zipcode">CEP</label>
				<span v-if="$v.postAddress.zipcode.$error">Campo obrigatório</span>
				<input id="zipcode" 
					type="text" 
					placeholder="CEP" 
					v-model="$v.postAddress.zipcode.$model" 
					v-on:blur="getAddress" v-on:keyup.enter="getAddress" 
					@blur="$v.postAddress.zipcode.$touch()" 
					:class="{ 'fail-error' : $v.postAddress.zipcode.$error }" 
					v-mask="'#####-###'">

				<label for="street">Endereço*</label>
				<span v-if="$v.postAddress.street.$error">Campo obrigatório</span>
				<input id="street" type="text" placeholder="Endereço" v-model="$v.postAddress.street.$model" @blur="$v.postAddress.street.$touch()" :class="{ 'fail-error' : $v.postAddress.street.$error }">

				<label for="number">Número*</label>
				<span v-if="$v.postAddress.number.$error">Campo obrigatório</span>
				<input id="number" type="text" placeholder="Número" v-model="$v.postAddress.number.$model" @blur="$v.postAddress.number.$touch()" :class="{ 'fail-error' : $v.postAddress.number.$error }">

				<label for="complement">Complemento</label>
				<span v-if="$v.postAddress.complement.$error">Campo obrigatório</span>
				<input id="complement" type="text" placeholder="Complemento" v-model="$v.postAddress.complement.$model" @blur="$v.postAddress.complement.$touch()" :class="{ 'fail-error' : $v.postAddress.complement.$error }">

				<label for="district">Bairro*</label>
				<span v-if="$v.postAddress.district.$error">Campo obrigatório</span>
				<input id="district" type="text" placeholder="Bairro" v-model="$v.postAddress.district.$model" @blur="$v.postAddress.district.$touch()" :class="{ 'fail-error' : $v.postAddress.district.$error }">

				<label for="city">Cidade*</label>
				<span v-if="$v.postAddress.city.$error">Campo obrigatório</span>
				<input id="city" type="text" placeholder="Cidade" v-model="$v.postAddress.city.$model" @blur="$v.postAddress.city.$touch()" :class="{ 'fail-error' : $v.postAddress.city.$error }">

				<label for="state">Estado*</label>
				<v-select
					label="sigla" 
					:options="stateList"
					v-on:input="findOpportunities('state')"
					@blur="$v.postAddress.state.$touch()"
					:reduce="state => state.sigla" 
					v-model="$v.postAddress.state.$model"
					:placeholder="'(Selecione)'"
					autocomplete="nope"
				>
				</v-select>
				<span v-if="$v.postAddress.state.$error">Campo obrigatório</span>
				<!-- <input id="state" type="text" placeholder="Estado" v-model="$v.postAddress.state.$model" @blur="$v.postAddress.state.$touch()" :class="{ 'fail-error' : $v.postAddress.state.$error }"> -->
			</div>
			
			<div class="col-md-12 top-space">
                <div class="title-bg-grey">
                    <h3>Fotos</h3>
                </div>
                <br>
            </div>

			<div class="col-md-6" v-if="!user.image">
				<div class="box-item img-box">
					<!-- <file-upload 
						:url="url" 
						:thumb-url='thumbUrl' 
						:headers="headers" 
						name="image"
						class="thumb" 
						v-model="files"
						accept="image/png,image/gif,image/jpeg,image/webp"
						@change="onFileChange"
						ref="upload">
					</file-upload> -->
					<image-upload
            v-model="user.image"
            @uploaded="updateUser()">
          </image-upload>
        </div>
			</div>

			<div class="col-md-3" v-if="user.image">
				<div class="box-item img-box">
					<div class="thumb" 
						:style="{ backgroundImage: `url('${getImageThumbUrl(user.image)}')` }" >

						<a class="close-button" @click="removePhoto(user.image)" >
							<i class="fas fa-times-circle"></i>
						</a>
					</div>
				</div>
			</div>

			<div class="col-md-12 last" style="margin-top: 24px;">
				<button class="remove" type="button" @click="deleteUser()">Excluir</button>
				<button class="remove" type="button" @click="logout()">Desconectar</button>
				<button class="blue " type="button" @click="updateUser('successMessage')" :disabled="isSending">Atualizar</button>
			</div>
		</div>
    </div>
	</div>
	<confirm-delete-modal :modal_id="'confirmModal'"></confirm-delete-modal>

	

  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/EmployeeProfile.vue";
import userMixin from '../../../mixins/userMixin.js'
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal.vue";
import ImageUpload from "../../../components/ImageUpload.vue";
import moment from 'moment'
import VueScrollTo from 'vue-scrollto';
import { server } from "../../../js/variables.js";
import { required, email, sameAs } from 'vuelidate/lib/validators'

const link = "/static/img/produto.png";

export default {
	mixins: [userMixin],
	components: {
	MainLayout,
	ConfirmDeleteModal,
	ImageUpload
  },
  data () {
    return {
		// user:{
		// 	name: '',
		// 	lastname: '',
		// 	document: '',
		// 	birthdate: '',
		// 	gender: '',
		// 	phone: '',
		// 	email: '',
		// 	// password: '',
		// 	address: [],
		// 	card: [],
		// 	resume:{
		// 		title: '',
		// 		summary: '',
		// 		experience: [],
		// 		education: [],
		// 	},
			
		// 	// favorite: [],
		// },
		// postSkillPrimary:{
		// 	skill_id:{
		// 		_id: '',
		// 	},
		// 	proficiency: '',
		// },
		// postSkillSecundary:{
		// 	skill_id:{
		// 		_id: '',
		// 	},
		// 	proficiency: '',
		// },
		postAddress:{
			zipcode: '',
			street: '',
			number: '',
			complement: '',
			district: '',
			city: '',
			state: '',
			// _id: '',
		},
		positionList: [
			{ label: "Estagiário", value: "INTERN" },
			{ label: "Júnior", value: "JUNIOR" },
			{ label: "Pleno", value: "FULL" },
			{ label: "Sênior", value: "SENIOR" },
			{ label: "Coordenador", value: "COORDINATOR" },
			{ label: "Gerente", value: "MANAGER" },
			{ label: "Diretor", value: "DIRECTOR" },
		],
		url: server.url + "/api/media/image/",
		headers: {
			Authorization: 'Bearer ' + localStorage.token
		},
		files: [],
		fileUploaded: [],
		isSending: false,
		disableCpf: false,
		// experienceList: [
		// 	{_id: 1,company: "Oceantek", position: 'Diretor de vendas', start_date: "2015", end_date: "2016", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"},
		// 	{_id: 2,company: "Oceantek", position: 'Diretor de vendas',start_date: "2016", end_date: "2017", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"},
		// 	{_id: 3,company: "Oceantek", position: 'Diretor de vendas',start_date: "2017", end_date: "2019", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"},
		// ],
		// formationList: [
		// 	{_id: 1,scholl: "UNIP", start_date: "2015", end_date: "2019", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"},
		// 	{_id: 2,scholl: "USP", start_date: "2016", end_date: "2017", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"},
		// ],
    }
  },
  validations: {
	user:{
		name: '',
		lastname: '',
		document: { required },
		birthdate: '',
		gender: '',
		phone: '',
		email: { required },
		resume:{
			title: '',
			summary: '',
		},
		// password: '',
	},
	postAddress:{
		zipcode: '',
		street: '',
		number: '',
		complement: '',
		district: '',
		city: '',
		state: '',
		// _id: '',
	},

},
	mounted() {
		
		var parameters = this.$route.query;
		let self = this;
		// this.findLanguages();
		this.findSegments();
    this.findPlaces();
		// this.load();
		// self.postAddress = self.user.address[0];
		// console.log(" postaddress", self.postAddress);
	},
	watch:{
		user(newValue, oldValue){
			// console.log(newValue._id, oldValue._id);
			// console.log("AQUI",this.user);
			
			
			if(this.user.address && this.user.address.length > 0){
				this.postAddress = this.user.address[0];
			}
			else{
				// console.log("Nao carregado");
				this.postAddress = {
					zipcode: '',
					street: '',
					number: '',
					complement: '',
					district: '',
					city: '',
					state: '',
				}
			}
			
      if(this.user.document != null && !this.user.document == '') this.disableCpf = true;
			
		}
	},
	
  methods: {
	load(){
		var parameters = this.$route.query;
		let self = this;
		// console.log('sessionMixin load');
		// console.log("token:", localStorage.token);
		// console.log("Dentro do load userdata")
		if (!localStorage.token) {
			return
		}
		server.config.headers = {
			Authorization: 'Bearer ' + localStorage.token
		}
		
		this.axios.get(  server.url + '/api/auth/load/', server.config)
		.then(function (response) {
			let responseData = response.data.data;

			self.user = responseData.user;
			// self.postAddress = self.user.address[0];
		})
		.catch(function (error) {
			
		});
	},
	updateUser(action){
		let self = this;
		if (!this.$v.$invalid){
			if (!localStorage.token) {
				return
			}
			server.config.headers = {
				Authorization: 'Bearer ' + localStorage.token
			}
			this.user.address = [];
			this.user.address.push(self.postAddress);

      let userCopy = JSON.parse(JSON.stringify(this.user));
			this.prepareUserToSendwithoutResume(userCopy);

			self.isSending = true;
			this.axios.put(  server.url + '/api/user/' + self.user._id, {
				user: userCopy,
			}, server.config)
			.then(function (response) {
				self.isSending = false;
				let responseData = response.data;
				// location.reload();
				if(action == 'successMessage'){
					VueScrollTo.scrollTo('#companyuser-data-form');
					self.$swal({
						icon: 'success',
						title: 'Dados atualizados',
						text: responseData.message,
						// footer: '<a href>Why do I have this issue?</a>'
						showCancelButton: false,
						confirmButtonColor: '#053360',
						confirmButtonText: 'Ok'
						}).then((result) => {
						if (result.isConfirmed) {
							location.reload();
						}
					})
				}
				
			})
			.catch(function (error) {
				self.isSending = false;
				self.load();
				self.output = error;
				self.showError(error);
			});
		}else{
			this.$v.$touch();
			this.$swal({
				icon: 'error',
				title: 'Oops...',
				text: 'Verifique os campos obrigatórios',
				// footer: '<a href>Why do I have this issue?</a>'
			});
		}
	},
	deleteUser(){
		let self = this;
		var id = this.$route.params.id;

		this.$swal.fire({
		title: 'Realmente deseja excluir sua conta?',
		text: "Esta ação é irreversível!",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#053360',
		cancelButtonColor: '#d33',
		cancelButtonText: 'Cancelar',
		confirmButtonText: 'Excluir'
		}).then((result) => {
		if (result.isConfirmed) {
			if (!localStorage.token) {
			return
			}
			server.config.headers = {
				Authorization: 'Bearer ' + localStorage.token
			}

			this.axios.delete(  server.url + '/api/user/' + self.user._id, server.config)
			.then(function (response) {
				localStorage.removeItem('token');
				localStorage.removeItem('user');
				localStorage.removeItem('company');
				window.open ('/' , '_self');
				// self.$swal({
				// 	icon: 'success',
				// 	title: 'Dados atualizados',
				// 	text: responseData.message,
				// 	// footer: '<a href>Why do I have this issue?</a>'
				// });
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
			});
			}
		})

		
	},
	logout(){
		let self = this;
		var id = this.$route.params.id;
		if (!localStorage.token) {
			return
		}
		server.config.headers = {
			Authorization: 'Bearer ' + localStorage.token
		}

		this.axios.post(  server.url + '/api/auth/logout', server.config)
		.then(function (response) {
			localStorage.removeItem('token');
			localStorage.removeItem('slug');
			localStorage.removeItem('user');
			localStorage.removeItem('company');
			window.open ('/login' , '_self');
			// self.$swal({
			// 	icon: 'success',
			// 	title: 'Dados atualizados',
			// 	text: responseData.message,
			// 	// footer: '<a href>Why do I have this issue?</a>'
			// });
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
		});
	},
	getAddress(){
		let self = this;
		this.axios.get(  server.url + '/webservices/zipcode?zipcode=' + this.postAddress.zipcode)
		.then(function (response) {
			let responseData = response.data.data
			self.postAddress.city = responseData.localidade
			self.postAddress.street = responseData.logradouro
			self.postAddress.state = responseData.uf
			self.postAddress.district = responseData.bairro
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
		});
	},
    thumbUrl(file) {
		return file.myThumbUrlProperty;
    },
	onFileChange(response) {
		// Handle files like:
		const image = response.data.image;
		this.user.image = image;
		this.fileUploaded = image.url;
    },
	removePhoto(image) {
		var self = this;
		this.axios.delete(  server.url + '/api/media/image/' + image._id , server.config)
			.then(function (response) {
				self.user.image = null;
				self.$swal({
					icon: 'success',
					title: 'Imagem removida',
					// text: answer.message,
					// footer: '<a href>Why do I have this issue?</a>'
				});
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
			});
	},
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";


.company-user-data{
	// padding-top: 50px;
  // padding-bottom: 50px;

	h1{
		margin-top: 15px;
		margin-bottom: 25px;
	}


	button.yellow{
		margin-top: 8px;
		margin-bottom: 26px;
	}
	
	.user-grey-box{
		border-radius: 10px;
		background-color: var(--main-color-lightgrey);
		padding: 20px 40px;
		margin-bottom: 15px;
	}

	.separator{
		padding-top: 30px;
		padding-bottom: 10px;
	}

	
}

@media only screen and (max-width: 600px) {
  .company-user-data{
	.img-box{
		height: 330px !important;
	}
  }
}
</style>
