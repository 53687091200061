<template>
  <main-layout>
    <div class="container-fluid light-grey-bg">
      <div class="contributors menu-content-box">
        <h1>Funcionários - Treine seus colaboradores licenciados</h1>
        <div class="contributors-create-edit-box">
          <div class="row">
            <div class="col-md-6">
              <label>Nome</label>
              <input type="text" placeholder="" v-model="$v.user.name.$model" :disabled="selected._id">
            </div>
            <div class="col-md-6">
              <label>Sobrenome</label>
              <input type="text" placeholder="" v-model="$v.user.lastname.$model" :disabled="selected._id">
            </div>
            <div class="col-md-12">
              <label>Email - certifique-se que este e-mail seja diferente do que o colaborador utiliza, caso já esteja cadastrado </label>
              <input type="text" placeholder="" v-model="$v.user.email.$model" :disabled="selected._id">
            </div>
            <div class="col-md-6">
              <label for="title">Cargo</label>
              <input
                id="title"
                type="text"
                placeholder=""
                v-model="$v.employee.title.$model"
                :class="{ 'fail-error': $v.employee.title.$error }"
              />
            </div>
            <div class="col-md-6">
              <label for="position">Nível do cargo</label>
              <v-select
                label="label"
                :options="positionList"
                v-model="$v.employee.position.$model"
                :reduce="(position) => position.value"
              >
              </v-select>
            </div>
          </div>
          <!-- <input type="text" placeholder="Nome" v-model="$v.user.name.$model" style="width: 29%">
          <input type="text" placeholder="Sobrenome" v-model="$v.user.lastname.$model" style="width: 29%; margin-left: 1%;">
          <input type="text" placeholder="Email" v-model="$v.user.email.$model" style="width: 40%; margin-left: 1%;"> -->
          
          
            
            <input type="checkbox" name='type' id='manager' value="true" v-model="employee.manager">
            <label for="manager"> Definir usuário como gestor da empresa</label>

            <!-- <p class="checkbox-description">Enviar emails sobre:</p> -->
          <fieldset>
            <legend>Enviar emails sobre:</legend>
            <input type="checkbox" name='type' id='salesman' value="true" v-model="employee.accept_email_product">
            <label for="salesman"> Representação de produtos</label>

            <input type="checkbox" name='type' id='opportunity' value="true" v-model="employee.accept_email_rh">
            <label for="opportunity"> Vagas de emprego</label>

            <input type="checkbox" name='type' id='training' value="true" v-model="employee.accept_email_course">
            <label for="training"> Treinamentos</label>
          </fieldset>

          <p>
            <small>
            * ao cadastrar o colaborador irá receberá um e-mail para acessar a plataforma (login:e-mail) e assistir treinamentos, receber vagas ou produtos da empresa;
            </small>
          </p>

          <div style="padding-top: 15px; text-align: center">
            <button @click="editEmployee()" v-if="selected._id">Atualizar</button>
            <button style="margin-left: 6px" @click="clearSelected()" v-if="selected._id">Cancelar</button>
            <button class="remove" style="margin-left: 6px" @click="deleteEmployee()" v-if="selected._id">Excluir</button>
            <button @click="createEmployee()" v-else>Criar</button>
          </div>
        
          <!-- <input type="checkbox" name='type' id='manager' value="true" v-model="search.manager" @change="getContributors()">
          <label for="manager"> Gestor</label>

          <input type="checkbox" name='type' id='salesman' value="true" v-model="search.canSendEmailProduct" @change="getContributors()">
          <label for="salesman"> Email representante</label>

          <input type="checkbox" name='type' id='opportunity' value="true" v-model="search.canSendEmailRH" @change="getContributors()">
          <label for="opportunity"> Email de vagas</label>

          <input type="checkbox" name='type' id='training' value="true" v-model="search.canSendEmailCourse" @change="getContributors()">
          <label for="training"> Email treinamento</label> -->
        </div>
        <hr class="d-block d-md-none d-lg-none" style="margin-top: 47px">
        <div class="row" style="padding-top: 30px">
          <div class="col-md-12" v-for="contributor in contributorList" :key="contributor._id" style="padding-bottom: 5px; padding-top: 5px;">
            <div @click="selectContributor(contributor)" style="cursor: pointer; display: flex;">
              <!-- <div class="contributor-box-img">
                <img
                  :src="getImageThumbUrl(contributor.user.image)"
                  v-if="contributor.user.image"
                  class=""
                  alt=""
                />
              </div> -->
              <div class="contributor-box-data" v-bind:class="{ 'contributor-box-selected': selected._id == contributor._id }">
                <div class="row">
                  <div class="col-md-6">
                    <p class="contributor-left">{{contributor.user.name}} {{contributor.user.lastname}}</p>
                    <p class="contributor-left">login: {{contributor.user.email}}</p>
                    <!-- <p class="contributor-left">{{contributor.phone}}</p> -->
                  </div>
                  <div class="col-md-6" >
                    <!-- <p class="contributor-right">Ultimo login: {{contributor.user.created_at}}</p> -->
                    <p class="contributor-right">{{contributor.title}} {{positionType[contributor.position]}}</p>
                    <p class="contributor-right" style="color: #eeb013" v-if="contributor.job === 'MANAGER'">{{contributorJob[contributor.job]}}</p>
                    <p class="contributor-right" v-else>{{contributorJob[contributor.job]}}</p>
                  </div>
                  <!-- <div class="col-md-12 ">
                    <input type="checkbox" name='type' :checked="contributor.manager" disabled>
                    <label for="manager"> Gestor</label>

                    <input type="checkbox" name='type' :checked="contributor.accept_email_product" disabled>
                    <label for="salesman"> Email representante</label>

                    <input type="checkbox" name='type' :checked="contributor.accept_email_rh" disabled>
                    <label for="opportunity"> Email de vagas</label>

                    <input type="checkbox" name='type' :checked="contributor.accept_email_course" disabled>
                    <label for="training"> Email treinamento</label>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/CompanyProfile.vue";
import companyMixin from "../../../mixins/companyMixin.js";
import moment from "moment";
import { server } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  mixins: [companyMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      user: {
        name: '',
        lastname: '',
        email: '',
      },
      employee: {
        accept_email_rh: true,
        accept_email_product: true,
        accept_email_course: true,
        manager: false,
        title: '',
        position: null,
      },
      selected: {},
      filter: {
        month: "",
        year: "",
      },
      contributorList: [
        // { _id: 1, name: 'Reinaldo', lastname: 'F', email: 'reinaldo.ferreira@email.com', phone: '(11) 91234-5678', last_login: '20/10/2020', position: 'Gestor comercial'},
        // { _id: 2, name: 'Reinaldo', lastname: 'Fe', email: 'reinaldo.ferreira@email.com', phone: '(11) 91234-5678', last_login: '20/10/2020', position: 'Gestor comercial'},
        // { _id: 3, name: 'Reinaldo', lastname: 'Fer', email: 'reinaldo.ferreira@email.com', phone: '(11) 91234-5678', last_login: '20/10/2020', position: 'Gestor comercial'},
        // { _id: 4, name: 'Reinaldo', lastname: 'Ferr', email: 'reinaldo.ferreira@email.com', phone: '(11) 91234-5678', last_login: '20/10/2020', position: 'Gestor comercial'},
        // { _id: 5, name: 'Reinaldo', lastname: 'Ferre', email: 'reinaldo.ferreira@email.com', phone: '(11) 91234-5678', last_login: '20/10/2020', position: 'Gestor comercial'},
        // { _id: 6, name: 'Reinaldo', lastname: 'Ferrei', email: 'reinaldo.ferreira@email.com', phone: '(11) 91234-5678', last_login: '20/10/2020', position: 'Gestor comercial'},
      ],
      search: {
        manager: null,
        name: null,
        status: null,
        resume: null,
        city: null,
        state: null,
        segment: null,
        canSendEmailCourse: null,
        canSendEmailProduct: null,
        canSendEmailRH: null,
      },
      contributorJob: {
        EMPLOYEE: "Funcionário",
        MANAGER: "Gestor",
      },
    };
  },
  validations: {
    user: {
      name: { required },
      lastname: { required },
      email: { required, email },
    },
    employee: {
      accept_email_rh: '',
      accept_email_product: '',
      accept_email_course: '',
      manager: '',
      title: { required },
      position: { required },
    },
  },
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    
  },
	watch:{
		company(newValue, oldValue){
			// console.log(newValue, oldValue);
			// console.log("watch company", this.company);
			if(this.company._id){
				this.contributorList = this.company.employees;
        for(let contributor of this.contributorList){
          contributor.user.created_at = moment(contributor.user.created_at).add(4, 'hours').format('DD/MM/YYYY');
        }
        // this.getContributors();
			}
		}
	},

  methods: {
    
    getContributors(){
      let self = this;
			if (!localStorage.token) {
				return
			}
			server.config.headers = {
				Authorization: 'Bearer ' + localStorage.token
			}

      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        manager: this.search.manager,
        name: this.search.name,
        status: this.search.status,
        resume: this.search.resume,
        city: this.search.city,
        state: this.search.state,
        segment: this.search.segment,
        canSendEmailCourse: this.search.canSendEmailCourse,
        canSendEmailProduct: this.search.canSendEmailProduct,
        canSendEmailRH: this.search.canSendEmailRH,
      };

      this.axios
        .get(server.url + "/api/company/" + self.company._id +"/employee", header)
        .then(function (response) {
          let responseData = response.data.data;
          self.contributorList = responseData.company_list;
          for(let contributor of self.contributorList){
            contributor.user.created_at = moment(contributor.user.created_at).add(4, 'hours').format('DD/MM/YYYY');
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    createEmployee() {
      let self = this;
      if (!this.$v.$invalid) {
        if (!localStorage.token) {
          return;
        }
        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };

        this.axios
          .post(
            server.url + "/api/company/" + this.company._id + "/employee",
            {
              user: self.user,
              employee: self.employee,
            },
            server.config
          )
          .then(function (response) {
            location.reload();
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      } else {
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    editEmployee() {
      let self = this;
      if (!this.$v.$invalid) {
        if (!localStorage.token) {
          return;
        }
        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };

        this.axios
          .put(
            server.url + "/api/company/" + this.company._id + "/employee/" + this.selected._id,
            {
              employee: self.employee,
            },
            server.config
          )
          .then(function (response) {
            location.reload();
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      } else {
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    deleteEmployee() {
      let self = this;
      
      this.$swal.fire({
      title: 'Realmente deseja excluir este funcionário?',
      text: "Esta ação é irreversível!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#053360',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir'
      }).then((result) => {
      if (result.isConfirmed) {
        if (!localStorage.token) {
          return
        }
        server.config.headers = {
          Authorization: 'Bearer ' + localStorage.token
        }

        this.axios
          .delete(server.url + "/api/company/" + this.company._id + "/employee/" + this.selected._id, server.config)
          .then(function (response) {
            
            let responseData = response.data;
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: responseData.message,
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: '#053360',
              confirmButtonText: 'Ok'
              }).then((result) => {
              if (result.isConfirmed) {
                location.reload();
              }
            })
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      }
      })
    },
    selectContributor(contributor){
      if(this.selected._id == contributor._id){
        this.clearSelected();
      }else{
        this.selected = contributor;
        this.user.name = contributor.user.name;
        this.user.lastname = contributor.user.lastname;
        this.user.email = contributor.user.email;
        this.employee.accept_email_rh = contributor.accept_email_rh;
        this.employee.accept_email_product = contributor.accept_email_product;
        this.employee.accept_email_course = contributor.accept_email_course;
        this.employee.manager = contributor.manager;
        this.employee.title = contributor.title;
        this.employee.position = contributor.position;
        // console.log('employee', this.selected, this.employee)
      }
    },
    clearSelected(){
      this.selected = {};
      this.user.name = '';
      this.user.lastname = '';
      this.user.email = '';
      this.employee.accept_email_rh = false;
      this.employee.accept_email_product = false;
      this.employee.accept_email_course = false;
      this.employee.manager = false;
      this.employee.title = '';
      this.employee.position = '';
    }
  },
};
</script>

<style lang="scss">
// @import "../../../scss/base.scss";

.contributors {

  h1 {
    margin-bottom: 40px;
  }

  .contributors-create-edit-box{
    background-color: var(--main-color-lightgrey);
    padding: 35px;
    border-radius: 18px;

    button{
      width: 150px;
      height: 32px;
    }

    .vs__clear{
      width: 30px;
      
    }

    .checkbox-description{
      color: var(--main-color-primary);
      font-weight: 500;
      font-size: 16px;
      margin-top: 10px;
      margin-left: 7px;
    }

    fieldset{
      border: var(--main-color-mediumgrey) solid 2px;
      padding: 12px;
      padding-bottom: 18px;
      margin-top: 15px;
      
      legend{
        width: auto;
        margin-top: 8px;
        margin-left: 16px;
        padding: 0px 10px;
      }
    }

  }

  input[type="checkbox"] + label {
    width: auto;
    padding-right: 40px;
  }

  input[type="checkbox"], input[type="radio"] {
    width: 0px;
}

  .contributor-box-img{
    width: 15%;
    border-radius: 6px;
    background-color: var(--main-color-lightgrey);
    display: inline-block;
    margin-right: 1%;
  }

  .contributor-box-data{
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    padding: 12px 36px;
    // width: 84%;
    width: 100%;
    display: inline-block;

    input[type="checkbox"] + label {
      cursor: initial;
      pointer-events: none;
    }

    p{
      font-size: 14px;
      font-weight: bold;
      color: var(--main-title-color);
    }

    .contributor-left{
      text-align: left;
    }

    .contributor-right{
      
      text-align: right;
    }
    
  }

  .contributor-box-selected{
    border: 2px solid var(--main-color-primary);
    border-radius: 6px;
    padding: 12px 36px;
    // width: 84%;
    width: 100%;
    display: inline-block;

    input[type="checkbox"] + label {
      cursor: initial;
      pointer-events: none;
    }

    p{
      font-size: 14px;
      font-weight: bold;
      color: var(--main-title-color);
    }

    .contributor-left{
      text-align: left;
    }

    .contributor-right{
      
      text-align: right;
    }
  }
}

@media only screen and (max-width: 600px) {
  .contributors {
    .contributors-create-edit-box {
      background-color: var(--main-color-lightgrey);
      // padding: 35px;
      padding: 0px;
      border-radius: 18px;
    }
  }
}
</style>
