<template>
  <div id="internal-page">
    
      <header>
        <div class="type-1 container center  only-desktop" >
          <div class="row "> 
          </div>
        </div>
        <div>
          <div class="container-fluid main-color-bg company-menu-bg">
            <div class="container center">
              <div class="row main-color-bg company-menu">
                <div class="col col-md-3">
                  <figure class="logo">
                    <!-- <a href=""  target="_self"> -->
                      <!-- <img src="/static/svg/icon_logo_menu.svg" alt class="logo" /> -->
                       <img src="/static/svg/logo.svg" alt class="logo" />
                    <!-- </a> -->
                  </figure>
                </div>
                <div class="offset-md-6 col-md-3 d-none d-md-block d-lg-block">
                  <ul class="menu-buttons">
                    <!-- <li v-on:click="goBackCompany()" v-if="showBackBtn">
                      <a >Voltar</a>
                    </li> -->
                    <li v-on:click="goToStore()">
                      <a >Loja</a>
                    </li>
                    <li v-on:click="goToCart()">
                      <a >Meu carrinho</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </header>
      <main>
        <slot></slot>
      </main>
      <Footer :footerType="4"/>
  </div>
    
</template>

<script>
  // import sessionMixin from '../mixins/sessionMixin.js'
  // import { VuePwaInstallMixin } from "vue-pwa-install";
  import Footer from '../components/Footer.vue'
  import { server, local } from '../js/variables.js'

  export default {
    // mixins: [sessionMixin],
    // mixins: [VuePwaInstallMixin],
    components: {
        Footer
    },
     data () {
      return {
        showBackBtn: false,
      }
    },
    mounted () {
      // var id = this.$route.params.id;
      // if(localStorage.token != null){
      //   if(local.company){
      //     this.showBackBtn = true;
      //   }
      // }
    },
    methods: {
      goToStore(){
        var id = this.$route.params.id;
        var url = "/catalogo/"+id;
        this.$router.push(url);
      },
      goToCart(){
        var id = this.$route.params.id;
        var url = "/catalogo/"+id+"/carrinho";
        this.$router.push(url);
        // window.open(url, "_self");
      },
      goBackCompany(){
        var id = this.$route.params.id;
        const url = "/co/vendedor/" + id;
        this.$router.push(url);
      },
    },
  }
</script>

<style  lang="scss" >
@import "../scss/reset.scss";
@import "../scss/base.scss";
@import "../scss/form.scss";
@import "../css/mobile.css";

// main{
    
// }

#internal-page {
  .container-fluid-mobile{
    padding: 0px;
    /* background-color: #fff; */
  }


  .header-top{
    // position: absolute;
    // width: 100%;
    // height: 250px;
    // background-image: url('/static/img/bg/a-1.png');
    // background-position: center top;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-color: #ECF0FB;
  }

  .page-title {
    height: 85px;
    margin-top: -20px;
    margin-bottom: 50px;
    position: relative;

    h1 {
      color: #09529F !important;
      font-weight: 600 !important;
      font-size: 25px !important;
      text-align: left;
    }

    h2 {
      color: #940013;
      font-size: 12pt;
      text-align: left;
    }

    > div {
      position: absolute;
      height: 65px;
      width: 320px;
      border-bottom: 1px solid rgba(0,0,0,.1);
    }
  }

}


@media only screen and (max-width: 600px) {
  .only-desktop {
    display: none !important;
  }

  header figure.logo {
    top: 0;
    margin: auto;
    max-width: fit-content;
    margin-top: 0px;
  }

  header figure.logo img {
    height: 60px;
  }

  #internal-page {
    main{
      padding-top: 60px;
      padding-bottom: 60px;
    }
    
    .header-top{
        height: 150px;
    }
    .page-title{
          margin-top: 10px;
          margin-bottom: 20px;
    }
  }
}
</style>
