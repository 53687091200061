<template>
  <div>
    <div class="benefits-home container">
      <h2 class="h2-bigger">Como o Vocevende funciona</h2>
      <br>
      <div class="hr-yellow hr-4 center"></div>
      <br>
      <div class="row">
        <div class="col-md-4 benefits-item">
          <img class="icon-left" src="/static/svg/icon_fire.svg" alt="">
          <h4>Sobre <br>Vocevende</h4>
          <br>
          <p>Portal de emprego e recrutamento para profissionais de vendas, empresas, representantes e vendedores.</p>
          <p>Além disso, as empresas podem anunciar seus produtos e comissões para que você venda, represente-a e treine seu time online.</p>
        </div>
        <div class="col-md-4 benefits-item">
          <img class="icon-left" src="/static/svg/icon_fire.svg" alt="">
          <h4>Conta <br>Profissionais</h4>
          <br>
          <p>Busque vagas exclusivas da área comercial: diretores, supervisores, vendedores e representantes e muitas outras.</p>
          <p>Encontre oportunidades de representação e crie um catálogo online dos seus produtos.</p>
          <p>E mais: crie e venda seus treinamentos e ofereça para empresas.</p>
        </div>
        <div class="col-md-4 benefits-item">
          <img class="icon-left" src="/static/svg/icon_fire.svg" alt="">
          <h4>Conta <br>Empresa</h4>
          <br>
          <p>Cadastre vagas da área comercial e contrate seu time de vendas e representação</p>
          <p>Anuncie seu catálogo de produtos para representantes e vendedores de todo o país.</p>
          <p>Elabore e disponibilize treinamentos exclusivos para sua empresa ou contrate em nossa plataforma.</p>
        </div>
        <div class="col-md-4 benefits-item only-desktop" style="padding-top: 0px;">
          <div class="hr-yellow hr-4"></div>
        </div>
        <div class="col-md-4 benefits-item only-desktop" style="padding-top: 0px;">
          <div class="hr-yellow hr-4"></div>
        </div>
        <div class="col-md-4 benefits-item only-desktop" style="padding-top: 0px;">
          <div class="hr-yellow hr-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

export default {
  components: {

  },
  data() {
    return {
      showShareBox: false,
      url: window.location.href,
      contact: {
        name: '',
        email: '',
        subject: '',
        message: '',
      }
    };
  },
  methods: {

  }
};
</script>
  
<style lang="scss">
.benefits-home {
  // margin: 10px;
  padding: 30px 30px;

  h2 {
    text-align: center;
  }

  p {
    text-align: left;
    margin-bottom: 20px;
    line-height: 22px;
  }

  img {
    position: absolute;
    left: 0px;
  }

  .benefits-item {
    padding: 15px;
    padding-left: 55px;

    h4 {
      font-size: 22px;
    }
  }

  .hr-yellow {
    // position: absolute;
    // bottom: 15px;
  }

}



@media only screen and (max-width: 600px) {}
</style>