<template>
  <div class="sac-wrapper">
    <div class="row">
      <div class="col-md-6 light-grey-bg sac">
        <img class="icon-left" src="/static/svg/email.svg" alt />
        <h2>Fale conosco</h2>
        <hr />
        <!-- <p>O <b>VOCEVENDE</b> é uma PLATAFORMA GLOBAL para soluções da área Comercial, você e sua empresa encontram soluções para contratar e treinar sua equipe de vendas, assim como buscar vendedores e representantes de forma rápida, eficiente e segura. Caso tenha alguma dúvida a <b>EQUIPE DO VOCEVENDE</b> responderá suas questões em até 24h.</p> -->
        <p><b>Queremos ajudar você a aproveitar ao máximo os benefícios da nossa plataforma.</b></p>
        <p>Se você tem alguma dúvida sobre cadastro de currículo, vagas, produtos para representantes e/ou vendedores,
          treinamento ou outras funcionalidades do VOCEVENDE, fale com a nossa equipe.</p>
        <p>* Consulte-nos para serviços de assessoria personalizada para você e sua empresa!</p>
        <!-- <p>Se você ainda tem alguma dúvida de como cadastrar produtos/serviços, vagas, treinamento ou outras
          funcionalidades do VOCEVENDE, entre em contato conosco a partir do formulário abaixo que em breve nossa equipe
          irá atende-lo e esclarecer como você pode aproveitar ao máximo os benefícios de nossa plataforma.</p> -->
        <div>
          <div class="row">
            <div class="col-md-12">
              <input type="text" id="name" placeholder="Nome" v-model="$v.contact.user.name.$model" />
            </div>
            <div class="col-md-6">
              <input type="text" id="email" placeholder="Email*" v-model="$v.contact.user.email.$model" />
            </div>

            <div class="col-md-6">
              <input type="text" id="phone" placeholder="Telefone*" v-mask="'(##) #####-####'"
                v-model="$v.contact.user.phone.$model" />
            </div>
            <div class="col-md-6">
              <input type="text" id="company" placeholder="Empresa" v-model="$v.contact.user.company.$model" />
            </div>
            <div class="col-md-6">
              <input type="text" id="position" placeholder="Cargo" v-model="$v.contact.user.job.$model" />
            </div>
          </div>

          <!-- <label for="subject">Assunto</label> -->
          <input type="text" id="subject" placeholder="Assunto" v-model="$v.contact.subject.$model" />

          <!-- <label for="message">Mensagem</label> -->
          <textarea placeholder="Digite sua mensagem..." v-model="$v.contact.message.$model"></textarea>

          <button class="float-right " @click="sendContact()">Enviar</button>
        </div>
      </div>

      <div class="col-md-6 no-padding secundary-color-bg d-none d-md-block d-lg-block">
        <img class="img-right" src="/static/img/contact.jpg" alt />
      </div>
    </div>
  </div>
</template>

<script>
import { server } from "../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";
export default {
  components: {},
  data() {
    return {
      showShareBox: false,
      url: window.location.href,
      contact: {
        user: { name: '', email: '', phone: '', company: '', job: '' },
        subject: "",
        message: "",
      },
    };
  },
  validations: {
    contact: {
      user: {
        name: '',
        email: { required, email },
        phone: { required },
        company: '',
        job: '',
      },
      subject: "",
      message: "",
    }
  },
  methods: {
    sendContact() {
      let self = this;
      let contactCopy = JSON.parse(JSON.stringify(self.contact));
      if (!this.$v.$invalid) {

        let phone = contactCopy.user.phone;
        if (phone.length > 13) {
          let phoneNoMask = phone.substring(1, 3) + phone.substring(5, 10) + phone.substring(11, 15);
          contactCopy.phone = phoneNoMask;
        }
        this.axios.post(server.url + '/api/user/contact/', {
          contact: contactCopy,
        }, server.config)
          .then(function (response) {
            let responseData = response.data;
            self.contact = {}
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: responseData.message,
            })

          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      } else {
        // console.log("ERRO", this.user, this.checked);
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }

    },
  },
};
</script>

<style lang="scss">
.sac-wrapper {

  margin-top: 30px;

  .sac {
    // margin: 10px;
    padding: 65px 110px;

    p {
      text-align: left;
      margin-bottom: 20px;
    }

    label {
      display: inline-block;
    }

  }

  .img-right {
    height: 100%;
    object-fit: cover;
  }

}

@media only screen and (max-width: 600px) {
  .sac-wrapper {
    .sac {
      padding: 30px 50px;
    }
  }
}
</style>