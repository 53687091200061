<template>
	<main-layout>
		<div class="light-grey-bg search-salesman-bg">
			<div class="search-salesman-box container">
				<h1>Procurar profissionais da área comercial</h1>
				
				<div class="row search-row">
					<div class="col-md-2">
						<v-select 
							label="sigla" 
							:options="stateList" 
							v-model="$v.search.state.$model" 
							:reduce="state => state._id" 
							:placeholder="'(Selecione)'" 
							@input="findCities(state.sigla)"
							>
						</v-select>
					</div>
					<div class="col-md-2">
						<v-select label="name" 
							:options="cityList" 
							v-model="$v.search.city.$model" 
							:reduce="city => city._id" 
							:placeholder="'Cidade'" >
						</v-select>
					</div>
					<div class="col-md-2">
						<v-select label="name" 
							:options="segmentList" 
							v-model="$v.search.segment.$model" 
							:reduce="segment => segment._id" 
							:placeholder="'Segmento'" >
						</v-select>
					</div>
					<div class="col-md-4">
						<input id="searchText" type="text" v-model="$v.search.searchText.$model" placeholder="Buscar por nome">
					</div>
					<div class="col-md-2">
						<button>Buscar</button>
					</div>
				</div>
				
			</div>
		</div>
		<div class="container">
            <div class="row ">
                <div class="col-md-6" v-for="user in salesmanList"  :key="user._id" @click="salesman = user" data-toggle="modal" data-target="#salesmanDetailsModal">
                    <salesman-item :salesman = user :size = 2 > </salesman-item>
                </div>
            </div>
        </div>
        <salesman-details-modal :modal_id="'salesmanDetailsModal'" :salesman = salesman></salesman-details-modal>


    <!-- <contact></contact> -->
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import SalesmanItem from "../components/SalesmanItem.vue";
// import ConfirmDeleteModal from "../components/ConfirmDeleteModal.vue";
import SalesmanDetailsModal from "../components/SalesmanDetailsModal.vue";
import { server } from "../js/variables.js";


export default {
  components: {
    MainLayout,
	SalesmanItem,
    // ConfirmDeleteModal,
    SalesmanDetailsModal,
  },
  data() {
    return {
		salesmanList: [
			// {id: 1, name: "João", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
			// {id: 2, name: "Maria", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
			// {id: 3, name: "Ana", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
			// {id: 4, name: "Pedro", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
		],
		segmentList: [
			{id: 1, name: "Calçados"},
			{id: 2, name: "Eletronicos"},
		],
		search: {
			state: '',
			city: '',
			segment: '',
			searchText: '',
		},
        salesman: {},
    };
  },
  validations: {
	search: {
		state: '',
		city: '',
		segment: '',
		searchText: '',
	},
  },
  mounted() {
	var parameters = this.$route.query;
	this.getSalesman();
	this.findSegments();

  },
  methods: {
	getSalesman(){
		let self = this;
		// if (!localStorage.token) {
		// 	return
		// }
		// server.config.headers = {
		// 	Authorization: 'Bearer ' + localStorage.token
		// }

		this.axios.get(  server.url + '/api/user/', server.config)
		.then(function (response) {
			self.salesmanList = [];
			let responseData = response.data.data;
			// self.salesmanList = responseData.user_list;
			for(let salesman of responseData.user_list){
				if(salesman.type == "SALESMAN" && salesman.resume){
					self.salesmanList.push(salesman);
				}
			}
			
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
			console.log(error);
		});
    },
    salesmanDetails(salesman){
        console.log("salesman details", salesman);

    },
	findSegments(){
		let self = this;
		this.axios
			.get(server.url + "/api/skill?filter=INDUSTRY_GENERAL", server.config)
			.then(function (response) {
				self.segmentList= [];
				let responseData = response.data.data.skill_list;
				self.segmentList = responseData;
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
			});
	},
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.search-salesman-bg{
	margin-top: -15px;
	margin-bottom: 50px;
	height: 200px;
	z-index: -1;
}

.search-salesman-box{
	margin-top: -15px;
	height: 200px;

	h1{
		padding-top: 50px;
		text-align: center;
	}

	.search-row{
		margin-top: 30px;
	}

	input{
		margin-top: 0px;
	}
}

@media only screen and (max-width: 600px) {
    .search-salesman-bg{
      margin-bottom: 0px;
    }
  
}

</style>
