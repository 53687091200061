<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-7 ">
            <h1 >{{course.name}}</h1>
          </div>
          <div class="col-md-2 ">
            <button class="blue-border" @click="goToCart()">Carrinho</button>
          </div>
          <div class="col-md-3 ">
            <button @click="addCart($event)">Adicionar ao carrinho</button>
          </div>
        </div>
      </div>
    </div>
 
    <div class="container top-space training-view">
      <div class="row">
        <div class="col-md-9">
          <div v-if="course.video.length > 0" style="">
            <video-player-item :src = getVideoUrl(course.video[0])></video-player-item>
          </div>
          <div style="background-color: #e6dcdc; height: 380px; width: 100%" v-else></div>
          <div class="training-view-infos" v-if="course._id">
            <h2>{{course.name}}</h2>

            <div class="row">
              <div class="col-md-7">
                <i class="fas fa-user-circle" v-if="!course.user.image"></i>
                <div class="teacher-photo" v-else  :style="'background: url('+getImageThumbUrl(course.user.image)+')'"></div>
                <h3>{{course.user.name}} {{course.user.lastname}}</h3>
              </div>
              <div class="col-md-5">
                <p style="font-size: 13px; line-height: 14pt; letter-spacing: 0.4px;">Data de criação: <b>{{course.created_at}}</b></p>
                <p style="font-size: 13px; line-height: 14pt; letter-spacing: 0.4px;">Aulas: <b>{{lessonsAmount}}</b></p>
                <!-- <p style="font-size: 13px; line-height: 14pt; letter-spacing: 0.4px;">Horas: </p> -->
                <p style="font-size: 13px; line-height: 14pt; letter-spacing: 0.4px;">Valor: <b>{{course.price | toCurrency}}</b></p>
              </div>
            </div>

            <br>
            <label for="description" style="padding-top: 25px; padding-bottom: 5px">Descrição</label>
            <p v-html="course.description"></p>
            <label for="target" style="padding-top: 25px; padding-bottom: 5px">Público alvo</label>
            <p v-html="course.target"></p>
            <label for="objectives" style="padding-top: 25px; padding-bottom: 5px">Objetivos</label>
            <p v-html="course.objectives"></p>
            <label for="prerequisites" style="padding-top: 25px; padding-bottom: 5px">Pré-requisitos</label>
            <p v-html="course.prerequisites"></p>
          </div>
        </div>
        <div class="col-md-3 training-view-sections" style="padding-right: 0px">  
          <div v-for="section in course.sections" :key="section._id">
            <ul class="section-lesson-buttons">
              <li class="light-grey-bg">
                <a style="font-weight: bold; color: var(--main-title-color);">{{section.title}}</a>
              </li>
              <li v-for="item in section.chapters" :key="item._id"> 
                <a >{{item.lesson.name}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import { server, local } from "../../../js/variables.js";
import userMixin from "../../../mixins/userMixin.js";
import VideoPlayerItem from "../../../components/VideoPlayerItem.vue";
import moment from 'moment';

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    VideoPlayerItem,
  },
  data() {
    return {
      course: {
        name: '',
        description: '',
        objectives: '',
        segment: {},
        target: '',
        prerequisites: '',
        price: '',
        status: '',
        sections: [],
        video: [],
        image: {},
      },
      lessonsAmount: 0,
    };
  },
  validations: {

  },
  mounted() {
    let self = this;
    var id = this.$route.params.id;
    
    
    this.findSegments();
    this.axios.get( server.url + '/api/course/' + id, server.config)
    .then(function (response) {
      let responseData = response.data.data;
      self.course = responseData.course;
      self.course.created_at = moment(self.course.created_at).add(4, 'hours').format('DD/MM/YYYY');
      
      self.lessonsAmount = 0;
      for(let section of self.course.sections){
        self.lessonsAmount += section.chapters.length;
      }
    })
    .catch(function (error) {
      self.output = error;
      self.showError(error);
    });
    
  },
  methods: {
    backToTrainings(){
      let self = this;
      var id = this.$route.params.id;
      const url = "/vc/meus-treinamentos/";
      self.$router.push(url);
    },
    addCart(event){
      let self = this;
      if(event.stopPropagation) event.stopPropagation();
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      let cartItem = {
        course: this.course._id,
        amount: 1,
      }

      if(self.course.user._id == self.user._id){
        self.$swal({
          icon: 'warning',
          title: 'Atenção',
          text: 'Não é possivel adicionar seu próprio treinamento.',
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Ok'
          }).then((result) => {
          if (result.isConfirmed) {
            return
          }
        })
      }else{
        let continueAddingItem = true;
        if(local.cartList){
          // console.log('carrinho', local.cartList)
          for(let cart of local.cartList){
            if(cart.items.some(item => item.course._id === this.course._id)){
              // console.log("Object found inside the array.");
              continueAddingItem = false;
            }
          }
        }
        
        if(continueAddingItem){
          this.axios.post(  server.url + '/api/cart/add-item',{
            item: cartItem

          }, server.config)
          .then(function (response) {
            let responseData = response.data;
            window.open("./vc/carrinho", "_self");
            // console.log('adicionou', responseData);
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
        }else{
          self.$swal({
            icon: 'warning',
            title: 'Atenção',
            text: 'Treinamento ja esta no carrinho.',
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
            }).then((result) => {
            if (result.isConfirmed) {
              // window.open("./co/vagas", "_self"); 
            }
          })

        }
      }
    },
    goToCart(){
      let self = this;
      window.open("./vc/carrinho", "_self");
      // const url = '/vc/carrinho';
      // self.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";


  



@media only screen and (max-width: 600px) {
  
}
</style>
