<template>
  <main-layout>
	<!-- <pre> password {{ $v.postAddress }}</pre> -->
	<!-- <pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container opportunity-details padding-top-to-header">
        <div class="row">
            <div class="col-md-12">
                <h1>{{job_opportunity.name}}</h1>
                <div class="hr-yellow hr-4" style="margin-top: 10px; margin-bottom: 10px;"></div>
                <h2>Cargo: {{job_opportunity.position}}</h2>
                <br><br>
                <h2>Descrição</h2>
                <p v-html="job_opportunity.description"></p>
				<!-- <p>{{job_opportunity.description}}</p> -->
                <br><br>
            </div>
            <div class="col-md-7 row">
                <div class="col-md-12">
                    <h2>Localização</h2>
                </div>
                <div class="col-md-6">
                    <p class="blue-text">Estado: <b>{{job_opportunity.address.state}}</b></p>
                </div>
                <div class="col-md-6">
                    <p class="blue-text">Cidade: <b>{{job_opportunity.address.city}}</b></p>
                </div>
            </div>
            <div class="col-md-5 row">
                <div class="col-md-12">
                    <h2>Faixa salarial</h2>
                </div>
                <div class="col-md-6">
                    <p class="blue-text">De: <b>{{job_opportunity.salary_range_start}}</b></p>
                </div>
                <div class="col-md-6">
                    <p class="blue-text">Até: <b>{{job_opportunity.salary_range_end}}</b></p>
                </div>
            </div>
            
            <div class="col-md-12" >
				<br><br>
				<h2>Conhecimentos necessários</h2>
			</div>
			<!-- <div class="col-md-6" > -->
                <div class="col-md-4" v-for="skill in job_opportunity.skills" :key="skill._id" style="margin-bottom: 10px">
					<p>{{skill.skill.name}}</p>
				</div>
            <!-- </div> -->
			<div class="col-md-12 last">
				<br>
				<button class="blue " type="button" @click="subscribeOpportunity()">Inscrever</button>
			</div>
        </div>
        
        
        
        
    </div>


	

  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import userMixin from '../../../mixins/userMixin.js'
import moment from 'moment'
import { server } from "../../../js/variables.js";
import { required, email, sameAs } from 'vuelidate/lib/validators'


export default {
	mixins: [userMixin],
	components: {
	MainLayout,
  },
  data () {
    return {
        job_opportunity:{
			name: '',
			description: '',
			position: '',
			employment_type: '',
			availability_for_travel: '',
			salary_range_start: '',
			salary_range_end: '',
			status: '',
			address: {
				zipcode: '',
				street: '',
				number: '',
				complement: '',
				district: '',
				city: '',
				state: '',
			},
			skills: [
				// {proficiency: 5, skill_id: {type: "INDUSTRY_GENERAL", _id: "5f46c97a7636715425e11174", name: "Agropecuária", __v: 0}, _id: "5f7df8eb6a74cf55da562a47"},
				// {proficiency: 3, skill_id: {type: "INDUSTRY_GENERAL", _id: "5f49330b94ae0c6f7bb72343", name: "Têxtil", __v: 0}, _id: "5f7df8eb6a74cf55da562a48"},
			],
			company: {},
			candidates: [],
        },
        positionList: [
			{ label: "Estagiário", value: "INTERN" },
			{ label: "Júnior", value: "JUNIOR" },
			{ label: "Pleno", value: "FULL" },
			{ label: "Sênior", value: "SENIOR" },
			{ label: "Coordenador", value: "COORDINATOR" },
			{ label: "Gerente", value: "MANAGER" },
			{ label: "Diretor", value: "DIRECTOR" },
		],
		statusList:[
			{label: 'Aberta', value: 'OPEN'},
			{label: 'Fechada', value: 'CLOSED'},
			{label: 'Cancelada', value: 'CANCELED'},
			{label: 'Em espera', value: 'STANDBY'},
		],
    }
  },
  validations: {

},
	mounted() {
		
		var parameters = this.$route.query;
		let self = this;
		this.getOpportunities();
		// self.postAddress = self.user.address[0];
		// console.log(" postaddress", self.postAddress);
	},
	watch:{
		user(newValue, oldValue){
			// console.log(newValue._id, oldValue._id);
			// console.log("AQUI",this.user);
		
		}
	},
	
  methods: {
    getOpportunities(){
		let self = this;
		var id = this.$route.params.id;
		// console.log("get salesmans");
		// if (!localStorage.token) {
		// 	return
		// }
		// server.config.headers = {
		// 	Authorization: 'Bearer ' + localStorage.token
		// }

		this.axios.get(  server.url + '/api/job-opportunity/' + id, server.config)
		.then(function (response) {
			let responseData = response.data.data;
			self.job_opportunity = responseData.job_opportunity;
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
			console.log(error);
		});
	},
	subscribeOpportunity(){
		let self = this;
		var id = this.$route.params.id;
		if (!this.$v.$invalid){
			if (!localStorage.token) {
				return
			}
			server.config.headers = {
				Authorization: 'Bearer ' + localStorage.token
			}
			this.axios.put(  server.url + '/api/job-opportunity/' + id + '/subscribe', server.config)
			.then(function (response) {
				let responseData = response.data;
				self.$swal({
					icon: 'success',
					title: 'Sucesso',
					text: responseData.message,
					// footer: '<a href>Why do I have this issue?</a>'
					showCancelButton: false,
					confirmButtonColor: '#053360',
					confirmButtonText: 'Ok'
					}).then((result) => {
					if (result.isConfirmed) {
						window.open("./vc/candidaturas", "_self"); 
					}
				})
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
				console.log(error);
			});
		}else{
			this.$v.$touch();
			this.$swal({
				icon: 'error',
				title: 'Oops...',
				text: 'Verifique os campos obrigatórios',
				// footer: '<a href>Why do I have this issue?</a>'
			});
		}
	},
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.opportunity-details{
    h2{
        margin-bottom: 10px;
    }

    p{
        background-color: var(--main-color-lightgrey);
        border-radius: 12px;
        padding: 20px 50px;
        font-size: 16px;
        
    }

    .blue-text{
        color: var(--main-title-color) !important;
    }
}



</style>
