<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9">
            <h1>Representantes</h1>
          </div>
          <div class="col-md-3" style="margin-top: -16px">
            <button v-on:click="goTo('./co/vendedores/favoritos')">
              Favoritos
            </button>
            <br />
            <button v-on:click="goTo('./co/vendedores/buscar')">Buscar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <pre> password {{ $v.user.password }}</pre>
	<pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container salesmans-company company-page">
      <div>
        <h2>Solicitações</h2>

        <div
          v-for="request in requestList"
          :key="request._id"
          @click="salesmanDetails(request.user)"
        >
          <div class="box-item row">
            <div class="col-md-1 salesman-frame">
              <!-- <div class="light-grey-bg" v-if="!request.user.image" style="width: 100%; height:100%;"></div> -->
              <img
                :src="getImageThumbUrl(request.user.image)"
                class="user-img"
                alt=""
                v-if="request.user.image"
              />
            </div>
            <!-- <div class="col-md-1 salesman-frame light-grey-bg" v-else style="width: 100%; height:100%;"> -->
<!-- 
            </div> -->
            <div class="col-md-5">
              <h3>{{ request.user.name }} {{ request.user.lastname }}</h3>
              <div class="hr-yellow-light hr-4"></div>
              <p>
                {{ request.user.address[0].city }} -
                {{ request.user.address[0].state }}
              </p>
            </div>
            <div class="col-md-6">
              <div class="product-amount" v-if="request.product">
                Produto: {{ request.product.name }}
              </div>
              <div class="product-amount" v-if="request.group">
                Categoria: {{ request.group.name }}
              </div>
              <div class="float-right">
                <button @click="call($event, request.user)">Ligar</button>
                <button @click="sendEmail($event, request.user)">Email</button>
                <button
                  class="approve"
                  @click="approveRepresentation(request, $event)"
                >
                  Aprovar
                </button>
                <button
                  class="deny"
                  @click="denyRepresentation(request, $event)"
                >
                  Negar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="requestList.length == 0">
          <h3>Você nao tem nenhuma solicitação pendente</h3>
        </div>
      </div>
      <br />
      <hr />
      <div>
        <h2>Atuais</h2>

        <div
          v-for="salesman in salesmanList"
          :key="salesman._id"
          @click="salesmanDetails(salesman.user)"
        >
          <div class="box-item row">
            <div v-if="salesman.user.image" class="col-md-1 salesman-frame">
              <img
                :src="getImageThumbUrl(salesman.user.image)"
                class="user-img"
                alt=""
              />
            </div>
            <div class="col-md-5">
              <h3>{{ salesman.user.name }} {{ salesman.user.lastname }}</h3>
              <div class="hr-yellow-light hr-4"></div>
              <p>
                {{ salesman.user.address[0].city }} -
                {{ salesman.user.address[0].state }}
              </p>
            </div>
            <div class="col-md-6">
              <div class="float-right" style="padding-top: 18px">
                <button @click="call($event, salesman.user)">
                  Ligar
                </button>
                <button @click="sendEmail($event, salesman.user)">
                  Email
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="salesmanList.length == 0">
          <h3>Você ainda não tem nenhum vendedor</h3>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Company.vue";
// import Contact from "../../../components/Contact.vue";
// import ProductItem from "../../../components/ProductItem.vue";
// import CompanyMenu from "../../../components/CompanyMenu.vue";
import { server, local } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
    // ProductItem,
    // CompanyMenu,
  },
  data() {
    return {
      requestList: [],
      salesmanList: [],
    };
  },
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    self.getSalesmanRepresentative();
  },
  methods: {
    getSalesmanRepresentative() {
      let self = this;
      // if (!localStorage.token) {
      // 	return
      // }
      // server.config.headers = {
      // 	Authorization: 'Bearer ' + localStorage.token
      // }

      this.axios
        .get(server.url + "/api/sales-representative/", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.requestList = [];
          self.salesmanList = [];
          for (let salesman of responseData.representation_list) {
            if (salesman.status == "AWAIT") {
              self.requestList.push(salesman);
            } else if (salesman.status == "APPROVED") {
              // self.salesmanList.push(salesman);
              self.addSalesmanIfNotExists(salesman);
            }
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    addSalesmanIfNotExists(newSalesman) {
      for (let salesman of this.salesmanList) {
        if (newSalesman.user._id == salesman.user._id) {
          return;
        }
      }
      this.salesmanList.push(newSalesman);
    },
    salesmanDetails(salesman) {
      window.open("./co/vendedor/" + salesman.slug, "_self");
    },
    approveRepresentation(representation, event) {
      let self = this;

      if (event.stopPropagation) event.stopPropagation();
      representation.status = "APPROVED";

      const request = {
        representation: representation,
      };

      this.axios
        .put(
          server.url + "/api/sales-representative/" + representation._id,
          request,
          server.config
        )
        .then(function (response) {
          let responseData = response.data.data;
          self.getSalesmanRepresentative();
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: response.data.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
            }).then((result) => {
            if (result.isConfirmed) {
              // window.open("./co/vagas", "_self"); 
            }
          })

        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    denyRepresentation(representation, event) {
      let self = this;

      if (event.stopPropagation) event.stopPropagation();
      representation.status = "DENIED";

      const request = {
        representation: representation,
      };

      this.axios
        .put(
          server.url + "/api/sales-representative/" + representation._id,
          request,
          server.config
        )
        .then(function (response) {
          let responseData = response.data.data;
          self.getSalesmanRepresentative();
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: response.data.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
            }).then((result) => {
            if (result.isConfirmed) {
              // window.open("./co/vagas", "_self"); 
            }
          })
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    call(event, user) {
      let self = this;
      var id = this.$route.params.id;
      if (event.stopPropagation) event.stopPropagation();
      if (local.permissions.calls < local.permissions.maxCalls) {
        if (!localStorage.token) {
          return;
        }
        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };

        this.axios
          .get(server.url + "/api/user/" + user._id + "/call", server.config)
          .then(function (response) {
            let responseData = response.data.data;
            window.open(
              "https://api.whatsapp.com/send?1=pt_BR&phone=55" +
                responseData.user.phone,
              "_blank"
            );
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
            console.log(error);
          });
      } else {
        self
          .$swal({
            icon: "error",
            title: "Aviso",
            text: "Você atingiu o limite de ligações",
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: "#053360",
            confirmButtonText: "Ok",
          })
          .then((result) => {
            if (result.isConfirmed) {
              // window.open("./vc/candidaturas", "_self");
              const url = "/co/perfil";
              self.$router.push(url);
            }
          });
      }
    },
    sendEmail(event, user) {
      let self = this;
      var id = this.$route.params.id;
      if (event.stopPropagation) event.stopPropagation();
      if (local.permissions.emails < local.permissions.maxEmails) {
        if (!localStorage.token) {
          return;
        }
        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };

        this.axios
          .get(server.url + "/api/user/" + user._id + "/email", server.config)
          .then(function (response) {
            let responseData = response.data.data;
            window.open("mailto:" + responseData.user.email, "_blank");
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
            console.log(error);
          });
      } else {
        self
          .$swal({
            icon: "error",
            title: "Aviso",
            text: "Você atingiu o limite de envio de emails",
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: "#053360",
            confirmButtonText: "Ok",
          })
          .then((result) => {
            if (result.isConfirmed) {
              // window.open("./vc/candidaturas", "_self");
              const url = "/co/perfil";
              self.$router.push(url);
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.salesmans-company {
  margin-bottom: 60px;

  h2 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  h3 {
    line-height: 35px;
  }

  p {
    line-height: 35px !important;
  }

  .grey-button {
    height: 32px;
    width: 113px;
    border-radius: 5px;
    background-color: var(--main-color-lightgrey);
    margin-left: 6px;
    display: inline-block;

    a {
      color: var(--main-menu-text-color);
      font-weight: lighter;
      padding-left: 38px;
      padding-right: 38px;
      padding-top: 8px;
      position: absolute;
      height: 32px;
    }
  }

  button {
    height: 32px;
    width: 113px;
    background-color: var(--main-color-lightgrey);
    color: var(--main-menu-text-color);
    font-weight: lighter;
    margin-left: 6px;
  }

  .approve {
    background-color: #28be50;
    color: white;
  }

  .deny {
    background-color: #ee1313;
    color: white;
    margin-left: 6px;
  }

  .product-amount {
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    color: var(--main-color-primary);
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .salesmans-company {
    margin-top: 85px;

    .box-item {
      height: 330px !important;
    }

    .salesman-frame {
      height: 116px;
    }

    .user-img {
      height: 116px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }

    .user-img-empty {
      left: 37px;
    }
  }
}
</style>
