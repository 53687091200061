<template>
	<main-layout>
		<div id="salesman-catalog" class="container-fluid light-grey-bg filter-opportunities" style="margin-bottom:0"  >
			<div class="container">
				<div class="row">
					<div class="col-md-2">
						<div v-if='salesman.image' class="store-picture-frame" :style="'background: url('+getImageThumbUrl(salesman.image)+')'"></div>
					</div>
					<div class="col-md-6">
						<h2>{{salesman.name}} {{salesman.lastname}}</h2>
						<h3>Loja online</h3>
					</div>
				</div>

			</div>
		</div>


		<product-finder class="section" :salesman="salesman._id" :showCompanyList = true :titleText="'Produtos que eu represento - Solicite um orçamento'" v-if="salesman._id">		
		</product-finder>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Public.vue";
import ProductFinder from "../components/ProductFinder.vue";
import { server } from "../js/variables.js";


export default {
  components: {
    MainLayout,
	ProductFinder,
  },
  data() {
    return {
		salesman: {},
		productGroupList: [],
		productList: [],
		companyList: [],
		search: {
			searchText: '',
        },
    };
  },
  validations: {
	search: {
		searchText: '',
	},
  },
  mounted() {
    var parameters = this.$route.query;
    this.getSalesman();
  },
	watch:{
		// company(newValue, oldValue){
		// 	console.log(newValue, oldValue);
		// 	console.log("watch company", this.company);
		// 	if(this.company._id){
		// 		this.getSalesmanCatalog();
		// 	}
		// }
	},
  methods: {
    productGroupDetails(productGroup){
		// window.open("./co/catalogo/categoria/" + productGroup._id, "_self");
		const url = "/co/catalogo/categoria/" + productGroup._id;
        this.$router.push(url);
    },
    getSalesman(){
		let self = this;
        var id = this.$route.params.id;
		this.axios.get(  server.url + '/api/user/' + id, server.config)
			.then(function (response) {
				let responseData = response.data.data;
				self.salesman = responseData.user;
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
				console.log(error);
			});
	},
    getSalesmanCatalog(){
		let self = this;
		var id = this.$route.params.id;
		this.axios.get(  server.url + '/api/sales-representative?salesman=' + id, server.config)
			.then(function (response) {
				let responseData = response.data.data;
				self.productGroupList = [];
				self.productList = [];
				self.companyList = [];
				var found = false;
				//filter company
				for(let item of responseData.representation_list){
					for(let company of self.companyList){
						if(company._id == item.company._id){
							found = true;
						}
					}
					if(!found){
						self.companyList.push(item.company);
					}
					found = false;
				}
				//filter group
				for(let item of responseData.representation_list){
					for(let group of self.productGroupList){
						if(group._id == item.product.groups[0]._id){
							found = true;
						}
					}
					if(!found){
						self.productGroupList.push(item.product.groups[0]);
					}
					found = false;
				}
				//filter product
				for(let item of responseData.representation_list){
					for(let product of self.productList){
						if(product._id == item.product._id){
							found = true;
						}
					}
					if(!found){
						self.productList.push(item.product);
					}
					found = false;
				}

			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
				console.log(error);
			});
	},
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

#salesman-catalog {
	h2{
		text-align: left;
	}

	.store-picture-frame{
		width: 100px;
		height: 100px;
		margin-top: 50px;
		border-radius: 50%;
		background-position: center center  !important;
		background-size: cover  !important;

		border: 2px solid var(--main-color-secundary);
	}
}

.catalog-salesman-view{


    .filter-search{
        margin-bottom: 35px;

        input{
            margin-top: 0px;
        }
    }

    .add-buttons{
        height: 76px;

        .add-button{
            border: 1px solid white;
            border-radius: 8px;
            display: inline-block;
            height: 50px;
            width: 60px;
            color: white;
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            line-height: 48px;
            margin-top: 12px;
            margin-left: 15px;
            margin-bottom: 12px;
        }

        .pointer{
            cursor: pointer;
        }
        
        p{
            display: inline-block;
            color: white;
            font-size: 20px;
            padding-left: 20px;;
        }
    }

    .product-group{
		padding-top: 20px;
		
		.category-icon{
			width: 56px;
			margin-right: 8px;
			margin-bottom: 3px;
		}
    }

    svg {
		width: 70px;
		height: 70px;
	}
	svg:hover {
		fill: red;
	}
}



</style>
