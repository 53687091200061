<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1>Treinamentos inscritos</h1>
          </div>
          <div class="col-md-3 ">
            <!-- <button @click="goToAnnotations()">Anotações</button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="training-subscribed">
      <div class="container-fluid training-list" v-if="studentList.length > 0">
        <div class="container">
          <!-- <h2>Continuar assistindo</h2>
          <br>
          <div class="training-details row" style="border-bottom: none">
            <div class="col-md-3" style="padding-top: 18px; cursor: pointer;" v-for="lesson in studentList[0].course.sections[0].chapters" :key="lesson._id" @click="goToLesson(studentList[0].course ,lesson)">
              <div class="light-grey-bg" style="height: 130px; width: 100%; border-radius: 15px">
                <img :src="getImageThumbUrl(lesson.image)" v-if="lesson.image" class="training-image" alt="">
              </div>
              <p style="padding-top: 10px"><b>{{lesson.lesson.name}}</b></p>
              <p v-html="lesson.lesson.description"></p>
            </div>
          </div>
          <br> -->
          <h2>Treinamentos</h2>
          <br>
          
          <!-- <div class="training-details" v-for="training in studentList" :key="training._id">
            <div class="row">
              <div class="col-md-8">
                <h3>{{training.course.name}}</h3>
                <p style="color: var(--main-title-color)">{{training.course.user.name}} {{training.course.user.lastname}}</p>
              </div>
              <div class="col-md-4">
                Progresso
              </div>
              <div class="col-md-3" style="padding-top: 18px; cursor: pointer;" v-for="lesson in training.course.sections[0].chapters" :key="lesson._id" @click="goToLesson(training.course ,lesson)">
                <div class="light-grey-bg" style="height: 130px; width: 100%; border-radius: 15px">
                  <img :src="getImageThumbUrl(lesson.lesson.image)" v-if="lesson.lesson.image" class="training-image" alt="">
                </div>
                <p style="padding-top: 10px"><b>{{lesson.lesson.name}}</b></p>
                <p v-html="lesson.lesson.description"></p>
              </div>
            </div>
          </div> -->
          <div class="training-details row">
            <div class=" col-md-3" v-for="training in studentList" :key="training._id">
              <training-item :training = training.course :grey = true  :subscribed = true :type="2"></training-item>
            </div>
          </div>

        </div>
      </div>
      <h2 class="container" style="margin-top: 30px" v-else>Vôce ainda não possui treinamentos inscritos</h2>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Employee.vue";
import { server, local } from "../../../js/variables.js";
import userMixin from "../../../mixins/userMixin.js";
import TrainingItem from "../../../components/TrainingItem.vue";
import moment from 'moment';

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    TrainingItem,
  },
  data() {
    return {
      studentList: [
        // {_id: 1, name: 'Treinamento 1', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 2, name: 'Treinamento 2', user:{name: 'Professor 2'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 3, name: 'Treinamento 3', user:{name: 'Professor 3'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 4, name: 'Treinamento 4', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
      ],
    };
  },
  mounted() {
    var parameters = this.$route.query;
  },
  watch: {
    user(newValue, oldValue) {
      // console.log(local.studentList)
      if (local.studentList && local.studentList.length > 0) {
        this.studentList = local.studentList;
        for(let course of this.studentList){
          course.course.created_at = moment(course.course.created_at).add(4, 'hours').format('DD/MM/YYYY');
        }
        // console.log(local.studentList);
      }
    },
  },
  methods: {
    goToAnnotations(){
      let self = this;
      const url = "/em/anotacoes" ;
      self.$router.push(url);
    },
    goToLesson(training, lesson){
      // console.log('aqui', lesson)
      let self = this;
      const url = "/em/meus-treinamentos/" + training._id + '/aula/' + lesson.lesson ;
      self.$router.push(url);
    },
    goToTraining(training){
      let self = this;
      const url = "/em/meus-treinamentos/" + training._id;
      self.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";



@media only screen and (max-width: 600px) {
}
</style>
