<template>
  <div class="opportunity-box box-item">
    <div class="row">
      <div class="col-md-12">
        <h3>{{ opportunity.name }}</h3>
        <div class="hr-yellow-light hr-4"></div>
        <p>
          Cargo:
          <b style="color: var(--main-title-color)">{{ positionType[opportunity.position] }} </b>
        </p>
        <p>
          Empresa:
          <b style="color: var(--main-title-color)">{{ opportunity.company.name_fancy }} </b>
        </p>
        <p style="margin-top: 8px;">Data criação: <b style="color: var(--main-title-color)">{{
          opportunity.created_at
        }}</b></p>
        <p class="job-description" v-html="opportunity.description"></p>
        <!-- <button
          class="blue"
          type="button"
          style="width: 150px; position: absolute; right: 0px; top: -10px;"
          v-if="type == 0"
          data-toggle="modal"
          data-target="#opportunityDetailsModal"
          @click="viewOpportunity($event)"
        >
          Visualizar vaga
        </button> -->

        <button class="clean" style="width: 35px; position: absolute; right: 0px; top: -10px;"
          @click="viewOpportunity($event, opportunity)" v-if="type == 0">
          <i style="font-size: 22px;" class="fas fa-pen edit-btn"></i>
        </button>
        <button v-if="typeCard() == 1" class="blue btn-see-candidates"  @click="goToSeeCandidates($event, opportunity)">
          <!-- Candidatos: {{ opportunity.candidates.length }} -->
          Ver candidatos
        </button>



      </div>
    </div>

    <div class="row bottom">
      <div class="col-md-12">
        <hr />
      </div>
      <div class="col-6" v-if="type == 0">
        <!-- company -->
        <p>
          Status da vaga: <b>{{ opportunityStatus[opportunity.status] }}</b>
        </p>
      </div>
      <div class="col-6 last" v-if="type == 0">
        <!-- company -->
        <button class="blue" @click="goToSeeCandidates($event, opportunity)">
          <!-- Candidatos: {{ opportunity.candidates.length }} -->
          Descritivo
        </button>
      </div>
      <div class="col-6" v-if="type == 1">
        <!-- salesman -->
        <button class="grey" style="pointer-events: none">
          <!-- Candidatos: {{ opportunity.candidates.length }} -->
          Descritivo
        </button>
      </div>
      <div class="col-6 last" v-if="type == 1">
        <!-- salesman -->
        <button class="blue" @click="subscribeOpportunity($event)">
          <!-- <div class="icon-div"> -->
          <!-- <img class="icon-button send-curriculum" src="/static/svg/icon_send_contact.svg" alt=""> -->
          <!-- </div> -->

          Candidatar
        </button>
      </div>
      <div class="col-6" v-if="type == 2">
        <!-- salesman subscribed -->
        <button class="grey" style="pointer-events: none">
          <!-- Candidatos: {{ opportunity.candidates.length }} -->
          Descritivo
        </button>
      </div>
      <div class="col-6 last" v-if="type == 2">
        <!-- salesman subscribed -->
        <!-- <button class="blue" :disabled="opportunity.status !== 'OPEN'">cancelar inscrição</button> -->
        <!-- <p v-if="job_opportunity_list[candidates[status]] == 'Aberta'" class="opportunity-status-text">Vaga 1</p> -->
        <p class="opportunity-status-text">{{ candidateStatus[getMyStatus()] }}</p>

      </div>
    </div>
    <!-- <login-modal :modal_id="'loginModal'"></login-modal> -->
  </div>
</template>

<script>
import { server, local } from "../js/variables.js";
import userMixin from "../mixins/userMixin.js";
// import $ from 'jquery'

export default {
  mixins: [userMixin],
  props: ["opportunity", "type", "job_opportunity_list"],
  components: {
  },
  data() {
    return {
      favorite: 0,
    };
  },
  mounted() { },

  watch: {},
  methods: {
    subscribeOpportunity(event) {
      let self = this;
      if (event.stopPropagation) event.stopPropagation();
      if (this.user.type == 'EMPLOYEE') {
        self.$swal({
          icon: 'warning',
          title: 'Atenção',
          text: 'Entre como um profissional para se candidatar a uma vaga.',
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            // window.open("./co/vagas", "_self"); 
          }
        })
        return
      }
      if (!localStorage.token) {
        this.goTo('./vc/cadastro');
        // $('#loginModal').modal('show');
      }
      else if (
        local.permissions.subscribeVacancies <
        local.permissions.subscribeVacanciesMax
      ) {
        if (!localStorage.token) {
          return;
        }
        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };
        this.axios
          .put(
            server.url +
            "/api/job-opportunity/" +
            this.opportunity._id +
            "/subscribe",
            server.config
          )
          .then(function (response) {
            let responseData = response.data;
            self
              .$swal({
                icon: "success",
                title: "Sucesso",
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: "#053360",
                confirmButtonText: "Ok",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  // window.open("./vc/candidaturas", "_self");
                  // const url = "/vc/candidaturas";
                  // self.$router.push(url);
                  location.reload();
                }
              });
          })
          .catch(function (error) {
            // console.log("create - error");
            self.output = error;
            self.showError(error);
          });
      } else {
        self
          .$swal({
            icon: "error",
            title: "Aviso",
            text: "Você atingiu o limite de inscrições em vagas",
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: "#053360",
            confirmButtonText: "Ok",
          })
          .then((result) => {
            if (result.isConfirmed) {
              // window.open("./vc/candidaturas", "_self");
              const url = "/vc/perfil";
              self.$router.push(url);
            }
          });
      }
    },
    getMyStatus() {
      var answer = "NOTFOUND";
      if (this.opportunity) {
        for (let candidate of this.opportunity.candidates) {
          if (candidate.user._id === this.user._id) {
            // console.log("AQUI", candidate.status );     
            console.log("teste", candidate.user);
            return candidate.status;
          }
        }
      }
      // console.log("AQUI", answer );
      return answer;
    },
    viewOpportunity(event, opportunity) {
      if (event.stopPropagation) event.stopPropagation();
      const url = `/co/vaga/${opportunity._id}`;
      this.$router.push(url);
    },
    goToSeeCandidates(event, opportunity) {
      if (event.stopPropagation) event.stopPropagation();
      const url = `/co/vaga/${opportunity._id}?candidatos=x`;
      this.$router.push(url);
    },
    typeCard() {

      console.log(this.$route.path == "/vc/buscar-vagas" || this.$route.path == "/vc/buscar-vagas/");
      console.log(this.$route);
      if (this.$route.path == "/co/vagas" || this.$route.path == "/co/vagas/") return 1
      else return 2

    }
  },
};
</script>

<style lang="scss">
.opportunity-box {
  padding: 20px 35px !important;
  margin-bottom: 25px !important;
  height: 345px;
  overflow-y: hidden;


  // .btn-see-candidates {
  //   width: auto;
  //   position: absolute;
  //   right: 80px;
  //   top: -10px;
  // }

  h3 {
    font-size: 21px;
    margin-top: 10px;
    margin-bottom: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  p {
    margin-top: 12px;
  }

  p.job-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }



  button {
    font-weight: normal;
    height: 32px;
    width: 100%;
  }

  .user-img {
    position: absolute;
    top: 21px;
    left: 52px;
  }

  .send-curriculum {
    width: 20px;
    filter: grayscale(100%) brightness(120%) sepia(90%) hue-rotate(5deg) saturate(500%) contrast(0.7);
  }

  .icon-div {
    background-color: var(--main-color-secundary);
  }


  .icon-button {
    top: 5px;
    right: 170px;
  }

  .row.bottom {
    position: absolute;
    bottom: 0px;
    padding-bottom: 20px;
    width: 90%;
    background-color: #fff;
    // left: 18px;
    // width: 100%;
    padding-right: 15px;
    -moz-box-shadow: 0px -10px 10px #fff;
    -webkit-box-shadow: 0px -10px 10px #fff;
    box-shadow: 0px -10px 10px #fff;

    hr {
      margin-top: 2px;
    }

    .opportunity-status-text {
      margin-top: 8px;
      font-weight: bold;
      color: var(--main-color-secundary);
    }
  }
}

@media only screen and (max-width: 600px) {
  .box-item.opportunity-box {
    height: 335px !important;
    white-space: initial;

    h3 {
      width: 80%;
    }

    .btn-see-candidates {
      display: none;
    }

    .row.bottom {
      position: absolute;
      // bottom: 20px;
      left: 18px;
      width: 100%;
      padding-right: 0px;



      button {
        width: 100%;
        height: 42px;
      }
    }
  }
}</style>