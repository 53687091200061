<template>
  <div>
    <div class="row about-box">
      <div class="col-md-12">
        <h2>Precisando contratar profissionais, vendedores e representantes <span>para sua empresa</span> ?</h2>

        <div class="hr-yellow hr-4"></div>
      </div>
      <div class="col-md-6">
        <div class="light-grey-bg">
          <video id="myVideo" preload="auto" src="/static/video/motion-vcvende-2.mp4" type="video/mp4" :autoplay="false"
            controls></video>
        </div>
      </div>
      <div class="col-md-6">
        <p>
          Vocevende é um portal exclusivo para a área comercial, une empresas e
          profissionais da área de vendas. Anuncie suas vagas da área comercial
          e contrate seu time completo de vendas.
          <br /><br />
          Cadastre sua empresa, produtos e serviços e você terá vendedores
          interessados em vender para você. Seus vendedores e representantes
          terão uma loja virtual com seus produtos representados para divulgar e
          obter clientes para sua empresa.
          <br /><br />
          Cadastre cursos e treinamentos exclusivos para sua empresa. Você
          treinador ou consultor pode cadastrar suas vídeo aulas e anunciar para
          todas as empresas e profissionais na plataforma.
          <br /><br />
          Você recebe um link como afiliado para profissionais e empresas que
          utilizarem seu link ao adquirirem produtos ou serviços, irá gerar uma
          renda para você.
        </p>
      </div>

    </div>
  </div>
</template>

<script>
// import VideoPlayerItem from "../components/VideoPlayerItem.vue";
export default {
  components: {
    // VideoPlayerItem,
  },
  data() {
    return {
      url: window.location.href,
    };
  },
  methods: {
    //TODO: enviar mensagem
  },
};
</script>

<style lang="scss">
.about-box {
  padding: 30px 0px;
  // margin: 10px;
  // padding: 45px;
  // height: 450px;

  h2 {
    text-align: left;
    margin-bottom: 8px;
  }

  p {
    text-align: left;
    margin-top: 20px;
  }

  .light-grey-bg {
    height: auto;
    width: 100%;
  }

  span {
    color: var(--main-color-secundary);
  }
}

@media only screen and (max-width: 600px) {
  .about-box {
    .light-grey-bg {
      height: 200px;
      width: 100%;
    }

    h2 {}

    .hr-yellow {
      margin-bottom: 40px;

    }
  }
}
</style>