<template>
  <main-layout>
    <!-- <pre> password {{ $v.user.password }}</pre>
	<pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9">
            <h1>Produto</h1>
          </div>
          <div class="col-md-3">
            <button @click="viewProduct()" :disabled="this.$route.fullPath == '/co/catalogo/produto/new'">Visualizar produto</button>
          </div>
        </div>
      </div>
    </div>
    <div class="container register-product company-page">
      <br />
      <div class="row">
        <div class="col-md-12 last" style="margin-bottom: 15px">
          <button
            class="remove"
            type="button"
            @click="deleteProduct()"
            v-if="this.$route.fullPath != '/co/catalogo/produto/new'"
          >
            Excluir
          </button>
          <button
            class="blue"
            type="button"
            @click="updateProduct()"
            v-if="this.$route.fullPath != '/co/catalogo/produto/new'"
          >
            Atualizar
          </button>
          <button
            class="blue"
            type="button"
            @click="createProduct()"
            v-if="this.$route.fullPath == '/co/catalogo/produto/new'"
          >
            Criar
          </button>
        </div>
        <div class="col-md-6">
          <div class="title-bg-grey">
            <h3>Dados do produto</h3>
          </div>
          <br />

          <label for="name">Nome</label>
          <input id="name" type="text" v-model="$v.product.name.$model" />
          <span v-if="$v.product.name.$error">Campo não pode estar vazio</span>

          <label for="segment">Categoria</label>
          <v-select
            label="name"
            :options="productGroupList"
            v-model="$v.postProductGroup.$model"
            :reduce="(parent) => parent._id"
          >
          </v-select>

          <label for="ean">EAN (código de barras)</label>
          <input
            id="ean"
            type="text"
            placeholder=""
            v-model="$v.product.ean.$model"
            @blur="$v.product.ean.$touch()"
            :class="{ 'fail-error': $v.product.ean.$error }"
          />
          <span v-if="$v.product.ean.$error">Campo obrigatório</span>

          <label for="unit_measurement">Unidade de medida</label>
          <v-select
            label="name"
            :options="unit_measurementList"
            v-model="$v.product.unit_measurement.$model"
            :reduce="(product) => product.name"
          >
          </v-select>

          <label for="segment"
            >Esta aberto a representação (e todos os produtos abaixo)</label
          >
          <v-select
            label="label"
            :options="productReadyForRepresentation"
            v-model="$v.product.status.$model"
            :reduce="(option) => option.value"
          >
          </v-select>
        </div>
        <div class="col-md-6">
          <div class="title-bg-grey">
            <h3>Descrição</h3>
          </div>
          <br />
          <label for="call">Simplificada</label>
          <input
            id="call"
            type="text"
            placeholder=""
            v-model="$v.product.call.$model"
            @blur="$v.product.call.$touch()"
            :class="{ 'fail-error': $v.product.call.$error }"
          />

          <label for="description">Detalhada</label>
          <textarea
            v-model="$v.product.description.$model"
            placeholder="Digite a descrição do produto"
            style="height: 63%"
          ></textarea>
        </div>

        <div class="col-md-6 top-space">
          <div class="title-bg-grey">
            <h3>Preço</h3>
          </div>
          <br />

          <label for="price">Venda</label>
          <money v-model="$v.product.price.$model"></money>
          <span v-if="$v.product.price.$error">Campo não pode estar vazio</span>

          <label for="sales_commission">Comissão</label>
          <!-- <input
            id="sales_commission"
            type="text"
            v-model="$v.product.sales_commission.$model"
          /> -->
          <money v-model="$v.product.sales_commission.$model" v-bind="mask"></money>
          <span v-if="$v.product.sales_commission.$error"
            >Campo não pode estar vazio</span
          >
        </div>

        <div class="col-md-6 top-space">
          <div class="title-bg-grey">
            <h3>Para assinantes</h3>
          </div>
          <br />

          <label for="segment"
            >Colocar produto em destaque (Este produto será mais
            visualizado)</label
          >
          <v-select
            label="label"
            :options="isFeatured"
            v-model="$v.product.is_featured.$model"
            :reduce="(option) => option.value"
            @input="checkFeaturedLimit"
            v-if="isPaid"
          >
          </v-select>
          <button
            @click="goTo('./co/perfil')"
            class="center"
            style="width: 230px; margin-top: 37px"
            v-if="!isPaid"
          >
            Assinar plano empresarial
          </button>
        </div>

        <div class="col-md-12 top-space">
          <div class="title-bg-grey">
            <h3>Fotos</h3>
          </div>
          <br />
        </div>

        <div class="col-md-3">
          <div class="box-item">
            <file-upload
              :url="url"
              :thumb-url="thumbUrl"
              :headers="headers"
              name="image"
              class="thumb"
              v-model="files"
              accept="image/png,image/gif,image/jpeg,image/webp"
              @change="onFileChange"
              ref="upload"
            >
            </file-upload>
          </div>
        </div>

        <div v-for="image in product.image" :key="image._id" class="col-md-3">
          <div class="box-item">
            <div
              class="thumb"
              :style="{ backgroundImage: `url('${getImageThumbUrl(image)}')` }"
            >
              <a class="close-button" @click="removePhoto(image)">
                <i class="fas fa-times-circle"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-12 last" style="margin-top: 24px">
          <!-- <button>Remover</button> //TODO -->
          <button
            class="remove"
            type="button"
            @click="deleteProduct()"
            v-if="this.$route.fullPath != '/co/catalogo/produto/new'"
          >
            Excluir
          </button>
          <button
            class="blue"
            type="button"
            @click="updateProduct()"
            v-if="this.$route.fullPath != '/co/catalogo/produto/new'"
          >
            Atualizar
          </button>
          <button
            class="blue"
            type="button"
            @click="createProduct()"
            v-if="this.$route.fullPath == '/co/catalogo/produto/new'"
          >
            Criar
          </button>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import companyMixin from "../../../mixins/companyMixin.js";
import MainLayout from "../../../layouts/Company.vue";
import { server, local } from "../../../js/variables.js";
import moment from 'moment'
import { required, email, sameAs } from "vuelidate/lib/validators";

export default {
  mixins: [companyMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      product: {
        name: "",
        description: "",
        is_featured: 0,
        unit_measurement: "UN",
        sales_commission: "",
        status: 'PREPARING',
        ean: "",
        price: "",
        call: "",
        tech_info: "",
        groups: [],
      },
      teste: 0,
      CategoryList: [
        { label: "Sem subcategoria", value: null },
        { label: "Categoria 1", value: "1" },
        { label: "Categoria 2", value: "2" },
        { label: "Categoria 3", value: "3" },
        { label: "Categoria 4", value: "4" },
      ],
      productGroupList: [],
      yesNo: [
        { _id: 0, label: "Não", value: false },
        { _id: 1, label: "Sim", value: true },
      ],
      productReadyForRepresentation: [
        { _id: 0, label: "Não", value: "PREPARING" },
        { _id: 0, label: "Sim", value: "READY" },
      ],
      isFeatured: [
        { _id: 0, label: "Não", value: 0 },
        { _id: 1, label: "Sim", value: 1 },
      ],
      unit_measurementList: [{ name: "UN" }, { name: "KG" }, { name: "L" }],
      postProductGroup: null,
      url: server.url + "/api/media/image/",
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
      files: [],
      fileUploaded: [],
      isPaid: 0,
      mask: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '%',
        precision: 2,
        masked: false
      },
      noCategoryId: null,
    };
  },
  validations: {
    product: {
      name: "",
      description: "",
      is_featured: "",
      unit_measurement: "",
      sales_commission: "",
      status: "",
      ean: "",
      price: "",
      call: "",
      tech_info: "",
    },
    postProductGroup: "",
  },
  mounted() {
    var parameters = this.$route.query;
    var id = this.$route.params.id;
    let self = this;
  },
  watch: {
    company(newValue, oldValue) {
      var parameters = this.$route.query;
      var id = this.$route.params.id;
      let self = this;
      // console.log(newValue, oldValue);
      // console.log("watch company", this.company);
      if (
        this.$route.fullPath != "/co/catalogo/produto/new" &&
        self.company._id
      ) {
        this.axios
          .get(
            server.url + "/api/company/" + self.company._id + "/product/" + id,
            server.config
          )
          .then(function (response) {
            let responseData = response.data.data;
            self.product = responseData.product;
            self.postProductGroup = self.product.groups[0];
            // self.fileUploaded = self.product.image[0].url;
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      }
      if (self.company._id) {
        this.getProductGroup();
      }
      if(local.subscriptionList){
        for(let subscription of local.subscriptionList){
          if(subscription.plan.type == 'COMPANY'){
            if(subscription.plan.payment_option.length > 0){
              this.isPaid = 1;
              
            }else{
              this.isPaid = 0;
            }
          }
        }
      }else{
        this.isPaid = 0;
      }
    },
  },
  methods: {
    createProduct() {
      let self = this;
      if (!this.$v.$invalid) {
        if (!localStorage.token) {
          return;
        }
        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };

        self.product.price = parseFloat(self.product.price);
        self.product.groups = [];
        if(self.postProductGroup == null) self.postProductGroup = self.noCategoryId;
        self.product.groups.push(self.postProductGroup);
        this.axios
          .post(
            server.url + "/api/company/" + self.company._id + "/product",
            {
              product: self.product,
            },
            server.config
          )
          .then(function (response) {
            let responseData = response.data;
            self
              .$swal({
                icon: "success",
                title: "Sucesso",
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: "#053360",
                confirmButtonText: "Ok",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  window.open("./co/catalogo", "_self");
                }
              });
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      } else {
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    updateProduct() {
      let self = this;
      var id = this.$route.params.id;
      if (!this.$v.$invalid) {
        if (!localStorage.token) {
          return;
        }
        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };
        self.product.groups = [];
        if(self.postProductGroup == null) self.postProductGroup = self.noCategoryId;
        self.product.groups.push(self.postProductGroup);
        this.axios
          .put(
            server.url + "/api/company/" + self.company._id + "/product/" + id,
            {
              product: self.product,
            },
            server.config
          )
          .then(function (response) {
            let responseData = response.data;
            // location.reload();
            // VueScrollTo.scrollTo('#user-data-form');
            self
              .$swal({
                icon: "success",
                title: "Sucesso",
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: "#053360",
                confirmButtonText: "Ok",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  location.reload();
                }
              });
          })
          .catch(function (error) {
            // self.load();
            self.output = error;
            self.showError(error);
          });
      } else {
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    deleteProduct() {
      let self = this;
      var id = this.$route.params.id;

      this.$swal
        .fire({
          title: "Realmente deseja excluir este produto?",
          text: "Esta ação é irreversível!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#053360",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Excluir",
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (!localStorage.token) {
              return;
            }
            server.config.headers = {
              Authorization: "Bearer " + localStorage.token,
            };

            this.axios
              .delete(
                server.url +
                  "/api/company/" +
                  self.company._id +
                  "/product/" +
                  id,
                server.config
              )
              .then(function (response) {
                let responseData = response.data;
                self
                  .$swal({
                    icon: "success",
                    title: "Sucesso",
                    text: responseData.message,
                    // footer: '<a href>Why do I have this issue?</a>'
                    showCancelButton: false,
                    confirmButtonColor: "#053360",
                    confirmButtonText: "Ok",
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      window.open("./co/catalogo", "_self");
                    }
                  });
              })
              .catch(function (error) {
                self.output = error;
                self.showError(error);
              });
          }
        });
    },
    checkFeaturedLimit() {
      let self = this;
      if (this.product.is_featured) {
        if (
          local.permissions.featuredProducts >=
          local.permissions.featuredProductsMax
        ) {
          this.product.is_featured = 0;
          self
            .$swal({
              icon: "error",
              title: "Aviso",
              text: "Você atingiu o limite de produtos em destaque",
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: "#053360",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // window.open("./co/catalogo", "_self");
              }
            });
        }
      }
    },
    viewProduct() {
      let self = this;
      var id = this.$route.params.id;
      window.open("./co/catalogo/produto/" + id + "/visualizacao", "_self");
    },
    getProductGroup() {
      let self = this;
      
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      this.axios
        .get(
          server.url + "/api/company/" + self.company._id + "/product-group/",
          server.config
        )
        .then(function (response) {
          let responseData = response.data.data;
          self.productGroupList = responseData.product_group_list;
          const id = self.$route.params.id;
          console.log('aqui', self.$route.params.id)
          if(id === 'new'){
            let array = self.productGroupList;
            const result = array.reduce((r, o) => o.created_at < r.created_at ? o : r);
            // console.log('sorted array', result)
            // for(let productGroup of self.productGroupList){
            //   if(productGroup._id == '609d7bef4b6745965c69c780'){
            //     self.postProductGroup = productGroup;
            //   }
            // }
            self.noCategoryId = result._id;
            self.postProductGroup = result._id;
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    thumbUrl(file) {
      return file.myThumbUrlProperty;
    },
    onFileChange(response) {
      // Handle files like:
      const image = response.data.image;
      if (!this.product.image) {
        this.product.image = [];
      }
      this.product.image.push(image);
      this.fileUploaded = image.url;
      this.$forceUpdate();
    },
    removePhoto(image) {
      var self = this;
      this.axios
        .delete(server.url + "/api/media/image/" + image._id, server.config)
        .then(function (response) {
          console.log(response);
          const index = self.product.image.indexOf(image);
          if (index > -1) {
            self.product.image.splice(index, 1);
            self.$forceUpdate();
          }

          self.$swal({
            icon: "success",
            title: "Imagem removida",
            // text: answer.message,
            // footer: '<a href>Why do I have this issue?</a>'
          });
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.register-product {
  h1 {
    margin-top: 15px;
    margin-bottom: 25px;
  }

}

@media only screen and (max-width: 600px) {
  .register-product {
    padding-top: 135px;

    .box-item {
      height: auto !important;
    }
  }
}
</style>
