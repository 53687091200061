<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1 >Treinamento</h1>
          </div>
          <div class="col-md-3 ">
            <button @click="backToTrainings()">Voltar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="container top-space training-watch">
      <div class="row">
        <div class="col-md-9">
          <div v-if="course.video.length > 0">
            <video-player-item :src = getVideoUrl(course.video[0])></video-player-item>
          </div>
          <div style="background-color: #e6dcdc; height: 380px; width: 100%" v-else></div>
          
          <div class="training-watch-infos" v-if="course._id">
            <h2>{{course.name}}</h2>

            <div class="row">
              <div class="col-md-7">
                <i class="fas fa-user-circle" v-if="!course.user.image"></i>
                <div class="teacher-photo" v-else  :style="'background: url('+getImageThumbUrl(course.user.image)+')'"></div>
                <h3>{{course.user.name}} {{course.user.lastname}}</h3>
              </div>
              <div class="col-md-5">
                <p style="font-size: 13px; line-height: 14pt; letter-spacing: 0.4px;">Data de criação: <b>{{course.created_at}}</b></p>
                <p style="font-size: 13px; line-height: 14pt; letter-spacing: 0.4px;">Aulas: <b>{{lessonsAmount}}</b></p>
                <!-- <p style="font-size: 13px; line-height: 14pt; letter-spacing: 0.4px;">Horas: </p> -->
                <p style="font-size: 13px; line-height: 14pt; letter-spacing: 0.4px;">Valor: <b>{{course.price | toCurrency}}</b></p>
              </div>
            </div>

            <br>
            <label for="description" style="padding-top: 25px; padding-bottom: 5px">Descrição</label>
            <p v-html="course.description"></p>
            <label for="target" style="padding-top: 25px; padding-bottom: 5px">Público alvo</label>
            <p v-html="course.target"></p>
            <label for="objectives" style="padding-top: 25px; padding-bottom: 5px">Objetivos</label>
            <p v-html="course.objectives"></p>
            <label for="prerequisites" style="padding-top: 25px; padding-bottom: 5px">Pré-requisitos</label>
            <p v-html="course.prerequisites"></p>
          </div>
        </div>
        <!-- <div class="col-md-3 training-watch-sections" style="padding-right: 0px">  
          <div v-for="section in course.sections" :key="section._id">
            <ul class="section-lesson-buttons">
              <li class="light-grey-bg">
                <a >{{section.title}}</a>
              </li>
              <li v-for="item in section.chapters" :key="item._id"> 
                <a >{{item.lesson.name}}</a>
              </li>
            </ul>
          </div>
        </div> -->
        <div class="col-md-3 training-watch-sections" style="padding-right: 0px">  
          <div v-for="section in course.sections" :key="section._id">
            <ul class="section-lesson-buttons">
              <li class="light-grey-bg" style="pointer-events: none;" >
                <a >{{section.title}}</a>
              </li>
              <li v-for="item in section.chapters" :key="item._id" @click="goToLesson(item)"> 
                <!-- <a v-bind:style=" item.lesson._id == this.$route.params.idLesson? 'padding-top: 11px;' : 'padding-top: 17px;' ">{{item.lesson.name}}</a> -->
                <a>{{item.lesson.name}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import { server } from "../../../js/variables.js";
import VideoPlayerItem from "../../../components/VideoPlayerItem.vue";
import moment from 'moment';
export default {
  components: {
    MainLayout,
    VideoPlayerItem,
  },
  data() {
    return {
      course: {
        name: '',
        description: '',
        objectives: '',
        segment: {},
        target: '',
        prerequisites: '',
        price: '',
        status: '',
        sections: [],
        video: [],
        image: {},
      },
      lessonsAmount: 0,
    };
  },
  validations: {

  },
  mounted() {
    let self = this;
    var id = this.$route.params.id;

    this.axios.get( server.url + '/api/course/' + id, server.config)
    .then(function (response) {
      let responseData = response.data.data;
      self.course = responseData.course;
      
      self.course.created_at = moment(self.course.created_at).add(4, 'hours').format('DD/MM/YYYY');
      
      self.lessonsAmount = 0;
      for(let section of self.course.sections){
        self.lessonsAmount += section.chapters.length;
      }
    })
    .catch(function (error) {
      self.output = error;
      self.showError(error);
    });
    
  },
  methods: {
    backToTrainings(){
      let self = this;
      var id = this.$route.params.id;
      const url = "/vc/meus-treinamentos/";
      self.$router.push(url);
    },
    goToLesson(item){
      let self = this;
      var idTraining = this.$route.params.id;
      // const url = "/vc/meus-treinamentos/" + idTraining + '/aula/' + item.lesson._id + '/visualizacao';
      // self.$router.push(url);
      window.open ('/vc/meus-treinamentos/' + idTraining + '/aula/' + item.lesson._id + '/visualizacao', '_self');
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.training-view{
  .training-image{
    width: 100%;
    max-height: 100%;
  }

  .training-view-infos{
    background-color: var(--main-color-lightgrey);
    padding: 50px 45px;
    // margin-top: 12px;
    // border-radius: 15px;

    h2{
      padding-bottom: 8px;
    }

    h3{
      display: inline-block;
      position: absolute;
      padding-top: 16px;
    }

    .teacher-photo{
      height: 40px;
      width: 40px;
      margin-top: 5px;
      border: 2px solid var(--main-color-mediumgrey);
      background-position: center center !important;
      background-size: cover !important;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    i.fas {
      color: var(--main-title-color);
      margin-top: 7px;
      font-size: 22pt;
      margin-right: 12px;
    }

    .col-md-5{
      text-align: right;
    }
    
  }

  .training-view-sections{
    ul.section-lesson-buttons {
      list-style: none;
      width: 100%;
      height: 100%;
      min-height: 74px;
      // background-color: var(--main-color-primary);
      display: table;

      li {
        display: block;
        //   vertical-align: middle;
        padding: 18px;
        // text-align: center;
        pointer-events: none;

        &:hover {
          background-color: var(--main-color-primary);
          cursor: pointer;
          // border-bottom: 2px solid var(--main-color-secundary);
        }

        a {
          max-width: 110px;
          color: var(--main-text-color);
          font-size: 10pt;

          &:hover {
            text-decoration: none;
            color: white;
          }
        }
      }
    }
  }
}
  



@media only screen and (max-width: 600px) {
  .training-view-infos{
    background-color: transparent;
    padding: 40px 15px;

    .col-md-5{
      text-align: left;
    }

    .col-md-7{
      padding-bottom: 12px;
    }
  }
  
}
</style>
