const { template } = require("lodash");

<template>
  <div class="profile-bar">
    <!-- <img src="/static/svg/logo_horizontal.svg" alt=""> -->
    <div class="company-img">
      <div class="" v-if="!company.image && this.$route.fullPath == '/co/dados'">
				<div class="">
					<!-- <file-upload 
						:url="url" 
						:thumb-url='thumbUrl' 
						:headers="headers" 
						name="image"
						class="thumb" 
						v-model="files"
						accept="image/png,image/gif,image/jpeg,image/webp"
						@change="onFileChange"
						ref="upload">
					</file-upload> -->
          <image-upload
            v-model="company.image"
            @uploaded="updateCompany()">
          </image-upload>
          <p style="    text-align: center; margin-top: -15px; margin-bottom: 25px;">Imagem da empresa</p>
        </div>
			</div>
      <div class="light-grey-bg profile-bg image-box" v-if="!company.image && this.$route.fullPath != '/co/dados'">
			</div>

			<div class="" v-if="company.image && this.$route.fullPath == '/co/dados'">
				<div class="">
					<div class="thumb" 
						:style="{ backgroundImage: `url('${getImageThumbUrl(company.image)}')` }" >

						<!-- <a class="close-button" @click="removePhoto(company.image)">
							<i class="fas fa-times-circle"></i>
						</a> -->
					</div>
          <button class="clean center remove-img-btn" @click="removePhoto(company.image)">Remover imagem</button>
				</div>
			</div>
      <div class="" v-if="company.image && this.$route.fullPath != '/co/dados'" @click="goTo('./co/dados')">
				<div class="">
					<div class="thumb" 
						:style="{ backgroundImage: `url('${getImageThumbUrl(company.image)}')` }" >
					</div>
				</div>
			</div>
    </div>
    <ul class="profile-buttons">
      <li v-on:click="goTo('./co/dados')" v-bind:class="{ 'active ': this.$route.fullPath == '/co/dados' }">
        <a >Dados empresa</a>
      </li>
      <li v-on:click="goTo('./co/perfil/visualizacao')">
        <a >Perfil da empresa</a>
      </li>
      <li v-on:click="goTo('./co/meus-dados')" v-bind:class="{ 'active ': this.$route.fullPath == '/co/meus-dados' }">
        <a >Meus dados</a>
      </li>
      <li v-on:click="goTo('./co/perfil')" v-bind:class="{ 'active ': this.$route.fullPath == '/co/perfil' }">
        <a >Meus planos</a>
      </li>
      <li v-on:click="goTo('./co/catalogo')" v-bind:class="{ 'active ': this.$route.fullPath == '/co/catalogo' }">
        <a >Cadastrar produtos para representação</a>
      </li>
      <li v-on:click="goTo('./co/vagas')" v-bind:class="{ 'active ': this.$route.fullPath == '/co/vagas' }">
        <a >Cadastrar vagas</a>
      </li>
      <li v-on:click="goTo('./co/meus-treinamentos')" v-bind:class="{ 'active ': this.$route.fullPath == '/co/buscar-treinamentos' }">
        <a >Treinamentos</a>
      </li>
      <li v-on:click="goTo('./co/vendedores/buscar')" v-bind:class="{ 'active ': this.$route.fullPath == '/co/vendedores/buscar' }">
        <a >Buscar representantes / profissionais</a>
      </li>
      <li v-on:click="goTo('./co/cartoes')" v-bind:class="{ 'active ': this.$route.fullPath == '/co/cartoes' }">
        <a >Carteira</a>
      </li>
      <li v-on:click="goTo('./co/historico-compras')" v-bind:class="{ 'active ': this.$route.fullPath == '/co/historico-compras' }">
        <a >Histórico de compras</a>
      </li>
      <li v-on:click="goTo('./co/colaboradores')" v-bind:class="{ 'active ': this.$route.fullPath == '/co/colaboradores' }">
        <a >Colaboradores</a>
      </li>
      <li v-on:click="goTo('./co/catalogo')">
        <a >Meus produtos</a>
      </li>
      <li v-on:click="logout()">
        <a >Sair</a>
      </li>
    </ul>
  </div>
</template>


<script>
import MainLayout from "../../../layouts/Company.vue";
import companyMixin from "../../../mixins/companyMixin.js";
import ImageUpload from "../../../components/ImageUpload.vue";
import moment from "moment";
import { server, local } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  mixins: [companyMixin],
  components: {
    ImageUpload
  },
  data() {
    return {
      url: server.url + "/api/media/image/",
      headers: {
        Authorization: 'Bearer ' + localStorage.token
      },
      files: [],
      fileUploaded: [],
    };
  },
  validations: {},
  mounted() {
    var parameters = this.$route.query;
    let self = this;
  },
  watch: {
    company(newValue, oldValue) {
      // console.log(newValue, oldValue);
      
    },
  },

  methods: {
    logout() {
      let self = this;
      var id = this.$route.params.id;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      this.axios
        .post(server.url + "/api/auth/logout", server.config)
        .then(function (response) {
          localStorage.removeItem('token');
          localStorage.removeItem('slug');
          localStorage.removeItem('user');
          localStorage.removeItem('company');
          window.open("/login", "_self");
          // const url = "/login" ;
          // this.$router.push(url);
          // self.$swal({
          // 	icon: 'success',
          // 	title: 'Dados atualizados',
          // 	text: responseData.message,
          // 	// footer: '<a href>Why do I have this issue?</a>'
          // });
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          
        });
    },
    thumbUrl(file) {
      return file.myThumbUrlProperty;
      },
    onFileChange(response) {
      // Handle files like:
      const image = response.data.image;
      // if(!this.company.image){
      // 	this.company.image = [];
      // }
      // this.company.image.push(image);
      this.company.image = image;
      this.fileUploaded = image.url;
      },
    removePhoto(image) {
      var self = this;
      this.axios.delete(  server.url + '/api/media/image/' + image._id , server.config)
        .then(function (response) {
          self.company.image = null;
          self.$swal({
            icon: 'success',
            title: 'Imagem removida',
            // text: answer.message,
            // footer: '<a href>Why do I have this issue?</a>'
          });
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          
        });
    },
	updateCompany(){
		let self = this;
			if (!localStorage.token) {
				return
			}
			server.config.headers = {
				Authorization: 'Bearer ' + localStorage.token
			}

			let companyCopy = JSON.parse(JSON.stringify(self.company));
			this.prepareCompanyToSend(companyCopy);
			
			this.axios.put(  server.url + '/api/company/' + self.company._id, {
				company: companyCopy,
			}, server.config)
			.then(function (response) {
        // location.reload();
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
			});
	},
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

</style>
