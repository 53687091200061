import Home from '../views/Home-company.vue';
import Register from '../views/Register-company.vue';
import Salesmans from '../views/Salesmans-company.vue';
import CompanyData from '../views/CompanyData.vue';
import CompanyUserData from '../views/CompanyUserData.vue';
import SalesmanFavorited from '../views/SalesmanFavorited.vue';
import SalesmanSearch from '../views/SalesmanSearch-company.vue';
import SalesmanDetails from '../views/SalesmanDetails-company.vue';
import PlanPreview from '../views/PlanPreview-company.vue';
import Contributors from '../views/Contributors.vue'
import Cards from '../views/Cards-company.vue';
import MyPlans from '../views/MyPlans-company.vue';
import Opportunities from '../views/OpportunityList.vue';
import RegisterOpportunity from '../views/OpportunityRegister.vue';
import Catalog from '../views/Catalog-company.vue';
import RegisterCategory from '../views/CategoryRegister.vue';
import RegisterProduct from '../views/ProductRegister.vue';
import ProductView from '../views/ProductView.vue';
import CompanyDetailsView from '../views/CompanyDetailsView.vue';
import TrainingSubscribedCompany from '../views/TrainingSubscribedCompany.vue';
import TrainingWatchCompany from '../views/TrainingWatchCompany.vue';
import LessonWatchCompany from '../views/LessonWatchCompany.vue';
import TrainingSearchCompany from '../views/TrainingSearchCompany.vue';
import TrainingCompany from '../views/TrainingCompany.vue';
import TrainingTeacherCompany from '../views/TrainingTeacherCompany.vue';
import TrainingSegmentCompany from '../views/TrainingSegmentCompany.vue';
import TrainingContractedExclusive from '../views/TrainingContractedExclusive.vue';
import Cart from '../views/Cart-company.vue';
import OrderConfirm from '../views/OrderConfirm-company.vue';
import OrderSucessful from '../views/OrderSucessful-company.vue';
import OrderHistory from '../views/OrderHistory-company.vue';
import Page404 from '../views/404.vue';


import axios from 'axios'
import { server } from '../../../js/variables.js'

const guard = function(to, from, next) {
  
  // let path = encodeURI(window.location.pathname) ;
  let path = encodeURI(to.fullPath) ;
  if (localStorage.token) {
    let user = JSON.parse(localStorage.user)
    let company = JSON.parse(localStorage.company)
    if(user.type == 'EMPLOYEE'){
      let isEmployee = false;
      for(let employee of company.employees){
        // console.log('id user', employee.user._id, user._id, employee.manager);
        // console.log('==', employee.user._id == user._id && !employee.manager);

        if(employee.user._id == user._id && !employee.manager){
          // console.log('id user ==', employee.user._id == user._id);
          isEmployee = true;
        }
      }
      // console.log('AQUI ISEMPLOYEE no /co', isEmployee);
      if(isEmployee){
        localStorage.removeItem('token');
        localStorage.removeItem('slug');
        localStorage.removeItem('user');
        localStorage.removeItem('company');
        window.open("/login", "_self");
      }else{
        next();
      }
    }else{
      localStorage.removeItem('token');
      localStorage.removeItem('slug');
      localStorage.removeItem('user');
      localStorage.removeItem('company');
      window.open("/login", "_self");
    }
  } else {
    // window.open("./", "_self");
    window.open("./login?redirect="+path, "_self");
  }
};

// const guard = function(to, from, next) {
  
//     let path = encodeURI(window.location.pathname) ;
//     // console.log("from", path);
  
//     axios.get(  server.url + '/api/auth/load', server.config)
//       .then(function (response) {
//         let responseData = response.data.data;
//         // console.log('guard', responseData);
//         if (!responseData.company) {
//           window.open("./login?redirect="+path, "_self");
//         }
//         next();
//       })
//       .catch(function (error) {
//         window.open("./login?redirect="+path, "_self");
//       });
//   };

export default [
    { path: '/co', component: Catalog, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/cadastro', component: Register},
    { path: '/co/dados', component: CompanyData, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/meus-dados', component: CompanyUserData, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/vendedores', component: Salesmans, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/vendedores/favoritos', component: SalesmanFavorited, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/vendedores/buscar', component: SalesmanSearch , beforeEnter: (to, from, next) => { guard(to, from, next); } },
    { path: '/co/vendedor/:id', component: SalesmanDetails , beforeEnter: (to, from, next) => { guard(to, from, next); } },
    { path: '/co/planos', component: PlanPreview, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/cartoes', component: Cards, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/perfil', component: MyPlans, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/vagas', component: Opportunities, beforeEnter: (to, from, next) => { guard(to, from, next); } },
    { path: '/co/vaga/:id', component: RegisterOpportunity, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/catalogo/', component: Catalog, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/catalogo/categoria/:id', component: RegisterCategory, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/catalogo/produto/:id', component: RegisterProduct, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/catalogo/produto/:id/visualizacao', component: ProductView, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/perfil/visualizacao', component: CompanyDetailsView, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/co/colaboradores', component: Contributors, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/co/meus-treinamentos', component: TrainingSubscribedCompany, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/co/meus-treinamentos/:id', component: TrainingWatchCompany, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/co/meus-treinamentos/:idTraining/aula/:idLesson', component: LessonWatchCompany, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/co/buscar-treinamentos', component: TrainingSearchCompany, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/co/treinamento/:id', component: TrainingCompany, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/co/buscar-treinamentos/treinador/:id', component: TrainingTeacherCompany, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/co/buscar-treinamentos/tema/:id', component: TrainingSegmentCompany, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/co/meus-treinamentos-detalhes/:id', component: TrainingContractedExclusive, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/co/carrinho', component: Cart, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/co/pedido/finalizacao', component: OrderConfirm, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/co/pedido/sucesso', component: OrderSucessful, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/co/historico-compras', component: OrderHistory, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '*', component: Page404 } 
 ]
