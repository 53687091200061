<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-7 ">
            <h1>{{segment.name}}</h1>
          </div>
          <div class="col-md-2 ">
            <button class="blue-border" @click="goToCart()">Carrinho</button>
          </div>
          <div class="col-md-3 ">
            <button @click="goToSubscribedTrainings()">Treinamentos inscritos</button>
          </div>
        </div>
      </div>
    </div>
    <div class="training-segment">
      
      <div class="container-fluid training-list" v-if="bestSellersTrainingList.length > 0">
        <div class="container">
          <h2>Treinamentos mais vendidos</h2>
          <br>
          <div class="row" style="padding-bottom: 45px">
            <div class="col-md-3" v-for="training in bestSellersTrainingList" :key="training._id">
              <training-item :training = training :grey = true></training-item>
            </div>
          </div>
          <!-- <button class="all-training-btn" @click="search.searchText = null; getCourses()">Ver todos os treinamentos</button> -->
        </div>
      </div>

      <div class="light-grey-bg container-fluid training-list" v-if="featuredTrainingList.length > 0">
        <div class="container">
          <h2>Treinamentos em destaque</h2>
          <br>
          <div class="row" style="padding-bottom: 45px">
            <div class="col-md-3" v-for="training in featuredTrainingList" :key="training._id">
              <training-item :training = training :grey = false></training-item>
            </div>
          </div>
          <!-- <button class="all-training-btn" @click="search.searchText = null; getCourses()">Ver todos os treinamentos</button> -->
        </div>
      </div>

      <div class="container-fluid training-list" v-if="bestSellersTrainingList.length > 0">
        <div class="container">
          <h2>Treinamentos mais vendidos</h2>
          <br>
          <div class="row" style="padding-bottom: 45px">
            <div class="col-md-3" v-for="training in bestSellersTrainingList" :key="training._id">
              <training-item :training = training :grey = true></training-item>
            </div>
          </div>
          <!-- <button class="all-training-btn" @click="search.searchText = null; getCourses()">Ver todos os treinamentos</button> -->
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import { server } from "../../../js/variables.js";
import TrainingItem from "../../../components/TrainingItem.vue";
import moment from 'moment';

export default {
  components: {
    MainLayout,
    TrainingItem,
  },
  data() {
    return {
      featuredTrainingList: [
        // {_id: 1, name: 'Treinamento 1', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 2, name: 'Treinamento 2', user:{name: 'Professor 2'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 3, name: 'Treinamento 3', user:{name: 'Professor 3'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 4, name: 'Treinamento 4', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
      ],
      bestSellersTrainingList: [
        // {_id: 1, name: 'Treinamento 1', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 2, name: 'Treinamento 2', user:{name: 'Professor 2'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 3, name: 'Treinamento 3', user:{name: 'Professor 3'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 4, name: 'Treinamento 4', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
      ],
      segment: {},
    };
  },
  mounted() {
    var parameters = this.$route.query;
    this.getCourses();
  },
  methods: {
    getCourses() {
      let self = this;
      var id = this.$route.params.id;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      this.axios
      .get(server.url + "/api/course/?visible=1&segment=" + id, server.config)
        .then(function (response) {
          // self.opportunityList = [];
          let responseData = response.data.data;
          self.featuredTrainingList = responseData.course_list;
          // self.bestSellersTrainingList = responseData.course_list;

          for(let course of self.featuredTrainingList){
            course.created_at = moment(course.created_at).add(4, 'hours').format('DD/MM/YYYY');
          }
          // for(let course of self.bestSellersTrainingList){
          //   course.created_at = moment(course.created_at).add(4, 'hours').format('DD/MM/YYYY');
          // }

          self.segment = self.featuredTrainingList[0].segment;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    goToSubscribedTrainings(){
      let self = this;
      var id = this.$route.params.id;
      const url = "/vc/treinamentos-inscritos/" ;
      self.$router.push(url);
    },
    goToCart(){
      let self = this;
      window.open("./vc/carrinho", "_self");
      // const url = '/vc/carrinho';
      // self.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";



.training-segment {
  // padding-top: 70px;
  h1{
    text-align: center;
  }

  .training-list{
    padding-top: 60px; 
    padding-bottom: 80px;

    .all-training-btn{
      float: right;
      width: 230px;
      height: 34px;
      color: var(--main-title-color);
    }
  }

  

}

@media only screen and (max-width: 600px) {
  .training-segment {
    
  }
}
</style>
