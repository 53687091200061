<template>
  <!-- Modal delete -->
	<div class="modal fade salesman-details-modal" 
			id="salesmanDetailsModal" 
			tabindex="-1" 
			role="dialog" 
			aria-labelledby="mySmallModalLabel" 
			aria-hidden="true" 
			style="background-color: #5c6377b8;">

		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<button class="btn-x" data-dismiss="modal" aria-label="Close">X</button>
                
                <div v-if="salesman.image" class="photo-bg" >
                  <img :src="getImageThumbUrl(salesman.image)"  class="user-img" alt="">
                </div>
                
                <div v-else class="photo-bg">
                  <img class="user-img" src="/static/img/logos/logo-vcvende.jpg"  alt="">
                </div>

                <!-- <div class="light-grey-bg photo-bg" v-else style="height: 100px; width: 100px;">
                </div> -->
                <div class="salesman-name">
                    <h2>{{salesman.name}} {{salesman.lastname}}</h2>
                    <button class="blue float right" @click="sendWhatsApp()">
                      <img class="icon-button" src="/static/svg/icone-entrar-em-contato-completo.svg" alt="">
                      Enviar mensagem
                    </button>
                </div>
                <div v-if="salesman.resume">
                  <div class="company-city">{{salesman.resume.title}} | <span v-if="salesman.address && salesman.address.length > 0">{{salesman.address[0].city}}</span> </div> <!--TODO-->
                  <p>
                      {{salesman.resume.summary}}
                  </p>
                </div>
                
			</div>
		</div>
	</div>
	<!-- Modal END -->
</template>

<script>
// import customerMixin from "../mixins/customerMixin.js";
import { server } from "../js/variables.js";

export default {
//   mixins: [customerMixin],
  props: ["modal_id", "salesman"],
  components: {},
  data() {
    return {
    };
  },
  mounted() {},
  watch:{
      salesman(n,v){
        // console.log("watch", n);
      }
      
  },
  methods: {
    checkSubmit: function(e) {
      if (e.keyCode === 13) {
        this.find(null);
      }
    },
    sendWhatsApp() {
      let self = this;
      var id = this.$route.params.id;
      if (!localStorage.token) {
        self.$swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'Você precisa estar logado para realizar está ação',
        })
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      this.axios
        .get(server.url + "/api/user/" + this.salesman._id + "/call", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          window.open(
            "https://api.whatsapp.com/send?1=pt_BR&phone=55" +
              responseData.user.phone,
            "_blank"
          );
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    //TODO: busca
  }
};
</script>

<style lang="scss">

.salesman-details-modal{

    .blue{
      width: 160px;
      height: 32px;
      float: right;
    }

    .icon-button{
      top: 40px;
      right: 222px;
    }

    .modal-content{
        padding: 70px;
        border-radius: 1.5rem;
        padding-left: 90px;
    }

    .photo-bg{
        height: 105px;
        width: 115px;
        left: -15px;
        border-radius: 1em;
        position: absolute;
        top: -15px;

        .user-img{
          border-radius: 1em; 
          margin-left: -30px;
        }
    }

    .salesman-name{
        margin-top: -30px;
        margin-left: -19px;
    }
    h2{
        background-color: var(--main-color-secundary);
        line-height: 45px;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
        // width: 250px;
        width: calc(100% - 220px);
        text-align: center;
        display: inline-block;
        padding-left: 28px;
        padding-right: 10px;
    }

    p{
        padding-top: 35px;
    }

    .company-city{
        color: var(--main-color-primary);
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 600px) {

  
.salesman-details-modal{
  .photo-bg{
      width: 60px;
      top: 5px;
      left: 5px;

    .user-img{
      width: 60px;
      margin-left: 0px;
    }
  }
  h2{
    line-height: 45px;
    // width: 250px;
    width: 100%;
    padding-left: 28px;
    padding-right: 10px;
  }

  .icon-button{
    display: none;
  }

  .blue{
    width: 100%;
    margin-top: 10px;
  }

}

}
</style>