<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Cadastrar novo cartão</h2>
      </div>
      <div class="col-md-12" style="padding-top: 12px">
        <input type="text" id="number" placeholder="Número do cartão" v-model="$v.card.number.$model" v-mask="'################'" />
        <input type="text" id="holder_name" placeholder="Nome completo" v-model="$v.card.holder_name.$model" />
      </div>
      <div class="col-md-6">
        <input type="text" id="exp" placeholder="Data de vencimento" v-mask="'##/##'" v-model="$v.card.exp.$model" />
      </div>
      <div class="col-md-6">
        <input type="text" id="security_code" placeholder="CVV" v-model="$v.card.security_code.$model" />
      </div>
      <div class="col-md-12">
        <button class="float-right" type="button " @click="saveCard()">
          Salvar
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import userMixin from "../mixins/userMixin.js";
import companyMixin from "../mixins/companyMixin.js";

import { server } from "../js/variables.js";

import {
  required,
  email,
  sameAs,
  numeric,
  alpha
} from "vuelidate/lib/validators";

export default {
  mixins: [companyMixin, userMixin],
  props: ['card', 'showTitle'],
  components: {

  },
  data() {
    return {
    };
  },
  validations: {
    card: {
      favorite: "",
      number: "",
      security_code: "",
      last_four: "", // Não usa no cadastro
      first_six: "", // Não usa no cadastro
      holder_name: "",
      holder_document: "",
      holder_additional_name: "", // Não usa no cadastro
      token: "", // Não usa no cadastro
      exp: "",
      exp_month: "",
      exp_year: "",
      brand: "",
      card_type: "",
      _id: "",
    },
  },
  mounted() {
      
  },
  methods: {
    
    addCard() {
      let self = this;
      if (!this.$v.$invalid) {
        if (!localStorage.token) {
          return;
        }
        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };
        if(self.company == null || !self.company._id){
          this.axios
            .post(
              server.url + "/api/user/" + this.user._id + "/card",
              {
                card: self.card,
              },
              server.config
            )
            .then(function (response) {
              location.reload();
            })
            .catch(function (error) {
              self.output = error;
              self.showError(error);
              console.log(error);
            });
        }else{
          this.axios
            .post(
              server.url + "/api/company/" + this.company._id + "/card",
              {
                card: self.card,
              },
              server.config
            )
            .then(function (response) {
              location.reload();
            })
            .catch(function (error) {
              self.output = error;
              self.showError(error);
            });
        }
        
      } else {
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    saveCard() {
      let exp = this.card.exp.split("/");
      if (!exp[1]) return;

      this.card.exp_month = exp[0];
      this.card.exp_year = exp[1];

      this.card.number = this.card.number.replace(/ /g, "");

      // this.card.card_type = "CREDIT";

      this.getBrand();

      if (!this.card.brand) {
        this.card.brand = "Master";
      }
      // this.card.brand = "Master";

      if (this.card._id) {
        this.updateCard();
      } else {
        this.addCard();
      }
    },
    getBrand() {
      var ccNum = this.card.number.split(" ").join("");
      var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
      var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
      var amexpRegEx = /^(?:3[47][0-9]{13})$/;
      var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
      var isValid = false;

      //Bandeira do cartão (Visa / Master / Amex / Elo / Aura / JCB / Diners / Discover / Hipercard / Hiper).

      if (visaRegEx.test(ccNum)) {
        isValid = true;
        this.card.brand = "Visa";
      } else if (mastercardRegEx.test(ccNum)) {
        isValid = true;
        this.card.brand = "Master";
      } else if (amexpRegEx.test(ccNum)) {
        isValid = true;
        this.card.brand = "Amex";
      } else if (discovRegEx.test(ccNum)) {
        isValid = true;
        this.card.brand = "Discover";
      }

      if (!isValid) {
        this.card.brand = null;
      }
    },
  }
};
</script>

<style lang="scss">
</style>