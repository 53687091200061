<template>
  <main-layout>


    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1 >Representações solicitadas</h1>
          </div>
          <div class="col-md-3 ">
            <button v-on:click="goTo('./vc/')">Buscar produtos</button>
          </div>
        </div>
      </div>
    </div>

    <div class="container catalog-salesman">
      <div class="row container">
        <div class="col-md-3 col-6">
          <div class="light-grey-bg box-amount-products">
            <div class="amount-opportunity">
              {{
                productDash.await + productDash.approved + productDash.denied
              }}
            </div>
            <div class="opportunity-status">Produtos solicitados</div>
            <div class="hr-registered center"></div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="light-grey-bg box-amount-products">
            <div class="amount-opportunity">
              {{ productDash.await }}
            </div>
            <div class="opportunity-status">Solicitações em espera</div>
            <div class="hr-waiting center"></div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="light-grey-bg box-amount-products">
            <div class="amount-opportunity">
              {{ productDash.approved }}
            </div>
            <div class="opportunity-status">Solicitações aprovadas</div>
            <div class="hr-aproved center"></div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="light-grey-bg box-amount-products">
            <div class="amount-opportunity">
              {{ productDash.denied }}
            </div>
            <div class="opportunity-status">Solicitações negadas</div>
            <div class="hr-denied center"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- <product-finder class="section" :salesman="user._id" :showAll = true v-if="user._id" ></product-finder> -->
    
    <!-- <div class="container representation-list">
      <div class="row">
        <div class="col-md-6" v-for="representation in representationList" :key="representation._id">
          
          <div class="box-item">
            <div class="row">
              <div class="col-md-4">
                <img
                  :src="getImageThumbUrl(representation.product.image[0])"
                  v-if="representation.product.image[0]"
                  class="product-image-1"
                  alt=""
                />
              </div>
              <div class="col-md-8">
                <h3>{{ representation.product.name }}</h3>
                <div class="light-yellow-line"></div>
                <h4>{{ representation.product.groups[0].name }}</h4>
                <p>
                  Preço venda: <b>{{ representation.product.price | toCurrency }}</b>
                </p>
                <p>
                  Comissão: <b>{{ representation.product.sales_commission }}%</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="container representation-list">
      <div class="row " v-if="awaitRepresentationList.length > 0">
        <div class="col-md-12">
          <h2>Solicitações em espera</h2>
        </div>
        <div class="col-md-6" v-for="representation in awaitRepresentationList" :key="representation._id">
          <product-item :representation = representation :type = 2> </product-item>
        </div>
      </div>

      <div class="row top-space" v-if="approvedRepresentationList.length > 0">
        <div class="col-md-12">
          <h2>Solicitações aprovadas</h2>
        </div>
        <div class="col-md-6" v-for="representation in approvedRepresentationList" :key="representation._id">
          <product-item :representation = representation :type = 2> </product-item>
        </div>
      </div>

      <div class="row top-space" v-if="deniedRepresentationList.length > 0">
        <div class="col-md-12">
          <h2>Solicitações negadas</h2>
        </div>
        <div class="col-md-6" v-for="representation in deniedRepresentationList" :key="representation._id">
          <product-item :representation = representation :type = 2> </product-item>
        </div>
      </div>
    </div>
    
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import userMixin from "../../../mixins/userMixin.js";
// import Contact from "../../../components/Contact.vue";
import ProductItem from "../../../components/ProductItem.vue";
// import ProductFinder from "../../../components/ProductFinder.vue";
// import Banner from '../../../components/Banner.vue'
import { server } from "../../../js/variables.js";

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    // Contact,
    ProductItem,
    // ProductFinder,
    // Banner,
  },
  data() {
    return {
      representationList: [],
      approvedRepresentationList: [],
      awaitRepresentationList: [],
      deniedRepresentationList: [],
      productDash: {
        approved: 0,
        await: 0,
        denied: 0,
      },
      search: {
        brand: "",
        category: "",
        company: "",
        searchText: "",
      },
      brandList: [],
      companyList: [],
      placeList: [],
      categoryList: [],
    };
  },
  watch: {
    user(newValue, oldValue) {
      // console.log(newValue._id, oldValue._id);
      // console.log("AQUI",this.user);
    },
  },
  mounted() {
    var parameters = this.$route.query;
    this.getMyProduct();
  },
  methods: {
    register(e) {
      // window.open("./company-registration", "_self");
      const url = "/company-registration";
      this.$router.push(url);
    },
    onPageChange(page) {
      this.sliderPage = page;
    },
    gotostore() {
      // window.open("./store", "_self");
      const url = "/store";
      this.$router.push(url);
    },
    getMyProduct() {
      let self = this;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      this.axios
        .get(server.url + "/api/sales-representative/", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.approvedRepresentationList = [];
          self.awaitRepresentationList = [];
          self.deniedRepresentationList = [];
          self.representationList = responseData.representation_list;
          self.productDash = responseData.representation_dash;
          for(let representation of self.representationList){
            if(representation.status == 'APPROVED'){
              self.approvedRepresentationList.push(representation);
            }else if(representation.status == 'AWAIT'){
              self.awaitRepresentationList.push(representation);
            }else if(representation.status == 'DENIED'){
              self.deniedRepresentationList.push(representation);
            }
          }
          console.log('awaitRepresentationList', self.awaitRepresentationList)
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">

.representation-list{
  h2{
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 600px) {
  
}
</style>
