<template>
  <div>
    <footer>
      <cookie-law theme="dark-lime" :buttonText='cookieTextBtn' :message='cookieMessage'></cookie-law>
      <div class="blue-line main-color-bg"></div>
      <div class="add-padding">
        <div class="d-none d-md-block d-lg-block">
          <div class="row">
            <div class="offset-md-1 col-md-3">
              <img src="/static/svg/logo_below.svg" alt="" />
            </div>

            <!-- <div class="col-md-3">
					<img class="icon-left" src="/static/svg/phone.svg" alt="">
					<span>Entre em contato</span>
					<p class="phone-number">0000-0000</p>
					<br>
					<p>Lorem ipsum dolor sit amet, consectetur</p>
				</div> -->

            <div class="col-md-2">
              <h3>Para empresas</h3>
              <ul>
                <li>
                  <a href="/co/cadastro">Cadastrar empresa</a>
                </li>
                <li>
                  <a href="/co/cadastro">Cadastrar vaga</a>
                </li>
                <li>
                  <a href="/co/cadastro">Cadastrar produtos</a>
                </li>
                <li>
                  <a href="/co/cadastro">Buscar representantes</a>
                </li>
                <li>
                  <a href="/co/cadastro">Buscar treinamentos</a>
                </li>
                <!-- <li>
                  <a href="/co/vendedores/buscar">Buscar candidatos</a>
                </li> -->
              </ul>
            </div>
            <div class="col-md-2">
              <h3>Para profissionais</h3>
              <ul>
                <li>
                  <a href="/vc/cadastro">Cadastrar representante</a>
                </li>
                <li>
                  <a href="/vc/cadastro">Cadastrar currículo</a>
                </li>
                <li>
                  <a href="/vc/cadastro">Cadastrar treinamento</a>
                </li>
                <li>
                  <a href="/vc/cadastro">Buscar produtos</a>
                </li>
                <li>
                  <a href="./vc/cadastro">Buscar vagas</a>
                </li>
                <li>
                  <a href="./vc/cadastro">Buscar treinamento</a>
                </li>
              </ul>
            </div>
            <div class="col-md-2 last offset-md-1">
              <img class="float-right" src="/static/img/credit-cards.png" alt="" />
              <img class="float-right" src="/static/img/compra-segura.png" alt="" />
            </div>
          </div>
          <hr />
        </div>

        <div id="rights" class="row">
          <div class="col">© Copyright 2020 - Vcvende</div>
          <!-- <div class="col last">
				<a href="https://oceantek.com.br/" alt="Desenvolvimento de apps, e-commerce e market places">
				<img src="/static/svg/logo_oceantek_grey.svg" alt />
				</a>
			</div> -->
        </div>
      </div>
    </footer>

    <div class="bottom-bar d-block d-md-none d-lg-none">
      <div class="container" v-if="footerType == 1">
        <div class="row">
          <div class="col">
            <a href="./">
              <i class="fas fa-home"></i>
              <label>Profissionais</label>
            </a>
          </div>
          <div class="col">
            <a href="./para-empresas">
              <i class="fas fa-building"></i>
              <label>Empresas</label>
            </a>
          </div>
          <div class="col" v-if="user._id">
            <a href="./co/perfil" v-if="user.type !== 'SALESMAN'">
              <i class="fas fa-user" v-if="!user.image"></i>
              <div class="footer-picture-frame" v-else :style="'background: url(' + getImageThumbUrl(user.image) + ')'">
              </div>
              <label>Conta</label>
            </a>
            <a href="./vc/perfil" v-else>
              <i class="fas fa-user" v-if="!user.image"></i>
              <div class="footer-picture-frame" v-else :style="'background: url(' + getImageThumbUrl(user.image) + ')'">
              </div>
              <label>Conta</label>
            </a>
          </div>
          <div class="col" v-else>
            <a href="./login">
              <i class="far fa-user"></i>
              <label>Login</label>
            </a>
          </div>
        </div>
      </div>

      <div class="container" v-if="footerType == 2">
        <div class="row">
          <div class="col">
            <a href="./co/catalogo/">
              <i class="fas fa-list-ul"></i>
              <label>Catálogo</label>
            </a>
          </div>
          <div class="col">
            <a href="./co/vagas">
              <i class="fas fa-briefcase"></i>
              <label>Vagas</label>
            </a>
          </div>
          <!-- <div class="col">
            <a href="./co/vendedores/buscar">
              <i class="fas fa-search"></i>
              <label>Buscar</label>
            </a>
          </div> -->
          <div class="col">
            <a href="./co/meus-treinamentos">
              <i class="fas fa-chalkboard-teacher"></i>
              <label>Treinamento</label>
            </a>
          </div>
          <div class="col">
            <a href="./co/vendedores">
              <i class="fas fa-users"></i>
              <!-- <i class="fas fa-building"></i> -->
              <label>Representantes</label>
            </a>
          </div>
          <!-- <div class="col" v-if="company._id">
            <a href="./co/perfil">
              <i class="fas fa-user" v-if="!company.image"></i>
              <div
                class="footer-picture-frame"
                v-else
                :style="'background: url(' + getImageThumbUrl(company.image) + ')'"
              ></div>
              <label>Conta</label>
            </a>
          </div> -->
        </div>
      </div>

      <div class="container" v-if="footerType == 3">
        <div class="row">
          <div class="col">
            <a href="./vc/">
              <i class="fas fa-search"></i>
              <label>Produtos</label>
            </a>
          </div>
          <div class="col">
            <a href="./vc/buscar-treinamentos">
              <i class="fas fa-chalkboard-teacher"></i>
              <label>Treinamento</label>
            </a>
          </div>

          <div class="col">
            <a href="./vc/buscar-vagas">
              <i class="fas fa-briefcase"></i>
              <label>Vagas</label>
            </a>
          </div>
          <div class="col">
            <a href="./vc/catalogo">
              <i class="fas fa-list-ul"></i>
              <label>Loja</label>
            </a>
          </div>
          <!-- <div class="col" v-if="user._id">
            <a href="./vc/perfil">
              <i class="fas fa-user" v-if="!user.image"></i>
              <div
                class="footer-picture-frame"
                v-else
                :style="'background: url(' + getImageThumbUrl(user.image) + ')'"
              ></div>
              <label>Conta</label>
            </a>
          </div> -->
        </div>
      </div>

      <div class="container" v-if="footerType == 4">
        <div class="row">
          <div class="col">
            <a v-on:click="goToStore()">
              <i class="fas fa-store"></i>
            </a>
          </div>
          <div class="col">
            <a v-on:click="goToCart()">
              <i class="fas fa-shopping-cart"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from "../mixins/userMixin.js";
import companyMixin from "../mixins/companyMixin.js";
import CookieLaw from 'vue-cookie-law'

export default {
  mixins: [companyMixin, userMixin],
  props: ["footerType"],
  components: {
    CookieLaw,
  },
  data() {
    return {
      showShareBox: false,
      url: window.location.href,
      contact: {
        name: "",
        email: "",
        city: "",
        company: "",
        phone: "",
        subject: "",
        message: "",
      },
      cookieTextBtn: 'Entendi',
      cookieMessage: 'Este site utiliza cookies para garantir uma melhor experiencia',
      token: null,
    };
  },
  mounted() {
    this.token = localStorage.token;
    // console.log('token', this.token)
    // console.log('empresa', this.company._id)
  },
  methods: {
    share: function (e) {
      if (navigator.share !== undefined) {
        navigator.share({
          url: window.location.href,
        });
      } else {
        // fallback\[]
        this.showShareBox = true;
      }
    },
    goToStore() {
      var id = this.$route.params.id;
      var url = "/catalogo/" + id;
      this.$router.push(url);
    },
    goToCart() {
      var id = this.$route.params.id;
      var url = "/catalogo/" + id + "/carrinho";
      this.$router.push(url);
      // window.open(url, "_self");
    },
    hideShareBox(e) {
      this.showShareBox = false;
    },
    //TODO: enviar mensagem
  },
};
</script>

<style lang="scss">
/* footer */

footer {
  position: relative;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  text-align: left;
  margin-top: 50px;
  background: var(--main-bg-color);

  h3 {
    margin-bottom: 20px;
  }

  ul li {
    margin-bottom: 8px;
    list-style-type: none;
  }

  ul li a {
    color: var(--main-text-color);
    font-size: 14px;

    &:hover {
      color: var(--main-color-primary);
      text-decoration: none;
      font-weight: bold;
    }
  }

  hr {
    border-top: 1px solid #e2e2e2;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .phone-number {
    font-size: 26px;
  }

  .Cookie--dark-lime .Cookie__button {
    width: auto;
  }
}

.blue-line {
  height: 19px;
  margin-top: 50px;
}

footer ul {
  /* padding-left: 15px; */
}

/* footer ul::before {
  content: "";
  position: absolute;
  left: 14px;
  height: 70px;
  width: 3px;
  background: var(--main-color-primary);
} */

.footer-box a {
  position: relative;
  top: 50px;
  /* font-weight: bold; */
  color: #000;
}

/* Bottom bar */

.bottom-bar {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 60px;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 3px 3px #eee;
  background: #fff;
  z-index: 1000;

  .col {
    cursor: pointer;
    padding-top: 10px;

    a {
      text-decoration: none;

      i,
      img {
        display: block;
        margin: auto;
        text-align: center;

        /* color: var(--main-menu-text-color); */
        color: var(--main-color-primary);
        /* color: #707070; */
        font-size: 20px;
        line-height: 19px;
        padding: 5px 5px 0px;
      }

      label {
        font-size: 8pt;
        text-align: center;
        width: 100%;
      }

      &:hover i,
      &:hover img {
        text-decoration: none;
        color: var(--main-color-primary);
      }
    }
  }
}

.bottom-bar .col a:hover i,
.bottom-bar .col a:hover img {
  text-decoration: none;
  color: var(--main-color-primary);
}

.header-icon {
  margin-right: 20px;
  display: block;
  float: left;
  margin-top: 0px;
}

.menu-icon {
  margin-top: -7px;
}

/* Rights */

#rights {
  color: #0a0f2d;
  font-size: 13px;
  padding-top: 20px;
  padding-bottom: 20px;
}

#rights img {
  margin-top: -20px;
}

/* Modal */

.modal-contact {
  margin-top: 20px;
}

.modal-contact h4 {
  /* margin-top: 50px; */
  margin-bottom: 15px;
}

.modal-contact input,
textarea {
  background-color: #f2f2f2;
  border: 0px !important;
}

.modal-contact input {
  border-radius: 5rem;
}

.left-margin {
  margin-left: 60px;
}

.modal-content {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
  border-radius: 3rem;
}

textarea {
  min-height: 135px;
}

.close-btn {
  width: 36px;
  height: 36px;
  padding-right: 2px;
  padding-top: 1px;
  border-radius: 5rem;
  padding-left: 0px;
  padding-bottom: 0px;
}

.footer-picture-frame {
  height: 30px;
  width: 30px;
  margin: auto;
  margin-top: -2px;
  border: 2px solid var(--main-color-secundary);
  background-position: center center !important;
  background-size: cover !important;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  footer {
    margin-top: 10px;
    display: none;
  }

  ul#menu-footer li {
    display: block;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  ul#menu-footer li a {
    font-weight: 300;
    color: #000;
  }

  .blue-line {
    height: 19px;
    margin-top: 0px;
  }
}
</style>