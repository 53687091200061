<template>
  <main-layout>
    <div
      class="container order-sucessful"
    >
      <div class="center">
        <img
          src="/static/svg/icon_check_blue.svg"
          class="center"
        >
        <h1>Sua compra foi realizada com sucesso!<br>Acompanhe o status no histórico de compras.</h1>
        <div class="center buttons">
          <button
            class="blue-border"
            @click="goToOrderHistory()"
          >Histórico de compras</button>
          <button
            class=""
            @click="goToTrainings()"
            style="margin-left: 20px"
          >Continuar comprando</button>
        </div>
        <br>
      </div>
      <br><br>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Company.vue";
import companyMixin from "../../../mixins/companyMixin.js";
import { server, local } from "../../../js/variables.js";


export default {
  components: {
    MainLayout,
    // NavigationBar,
  },
  data() {
    return {};
  },
  mounted() {
    var id = this.$route.params.id;
    let self = this;
  },
  methods: {
    goTo(link) {
      window.open(link, "_self");
    },
    goToOrderHistory(){
      let self = this;
      const url = "/co/historico-compras";
      self.$router.push(url);
    },
    goToTrainings(){
      let self = this;
      const url = "/co/buscar-treinamentos";
      self.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
.order-sucessful{
  padding: 70px 0px;
  img{
    height: 80px;
    margin-bottom: 20px;
  }

  h1{
    // display: inline-block;
    text-align: center;
  }

  .buttons{
    text-align: center;
    padding-top: 30px;

    button{
      width: 200px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .order-sucessful{

  }
}
</style>