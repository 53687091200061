<template>
  <div class="container opportunity-list-wrapper" v-if="opportunityList.length > 0">
    <div class="title-wrapper">
      <div class="title-space">
        <h2 class="h2-bigger" style="margin-bottom: 15px;">Vagas em evidência</h2>
        <div class="hr-yellow hr-45"></div>
      </div>
      <button class="border" @click="registerOpportunity">Cadastre sua vaga</button>
    </div>
    <br><br><br>
    <div class="row opportunity-list hide-mobile">
      <div class="col-md-6" v-for="opportunity of opportunityList" :key="opportunity._id"
        @click="opportunityNow = opportunity" data-toggle="modal" data-target="#opportunityDetailsModal">
        <opportunity-item :opportunity="opportunity" :type="verifySubscribed(opportunity)">
        </opportunity-item>
      </div>

      <div class="col-md-12" style="text-align: center;" v-if="canLoadMore">
        <button class="border border-bigger" @click="loadMore()">Carregar mais vagas</button>

      </div>
    </div>

    <div class="row opportunity-list only-mobile">
      <div class="col-md-6" v-for="opportunity of opportunityListMobile" :key="opportunity._id"
        @click="opportunityNow = opportunity" data-toggle="modal" data-target="#opportunityDetailsModal">
        <opportunity-item :opportunity="opportunity" :type="verifySubscribed(opportunity)">
        </opportunity-item>
      </div>

      <div class="col-md-12" style="text-align: center;" v-if="canLoadMore">
        <button class="border border-bigger" @click="loadMore(true)">Carregar mais vagas</button>

      </div>
    </div>

    <opportunity-details-modal :modal_id="'opportunityDetailsModal'" :opportunity="opportunityNow"
      :showCandidateButton="false"></opportunity-details-modal>
  </div>
</template>

<script>
import { server, local } from "../js/variables.js";
import moment from 'moment';
import OpportunityItem from "./OpportunityItem.vue";
import OpportunityDetailsModal from "../components/OpportunityDetailsModal.vue";
import userMixin from "../mixins/userMixin.js";

export default {
  mixins: [userMixin],
  components: {
    OpportunityItem,
    OpportunityDetailsModal,
  },
  data() {
    return {
      opportunityList: [],
      opportunityListMobile: [],
      opportunityNow: {},
      endpoint: "/api/job-opportunity",
      maxItems: 2,
      pageItems: 2,
      maxItemsMobile: 1,
      pageItemsMobile: 1,
      page: 0,
      canLoadMore: true,

    };
  },
  mounted() {
    this.loadOpportunities();
  },
  methods: {
    loadMore(isMobile) {
      if (isMobile) {
        this.maxItemsMobile = this.maxItemsMobile + this.pageItemsMobile;
        this.page = this.page + 1;
        this.loadOpportunities(true);
      } else {
        this.maxItems = this.maxItems + this.pageItems;
        this.page = this.page + 1;
        this.loadOpportunities();
      }
    },
    loadOpportunities(isMobile) {
      let self = this;
      let limit = isMobile ? this.pageItemsMobile : this.pageItems
      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        limit: limit,
        page: this.page,
        visible: 1,
        approved: 1,
      };

      this.axios
        .get(server.url + this.endpoint, header)
        .then(function (response) {
          let responseData = response.data.data;
          if (self.page == 0) {
            self.opportunityList = [];
            self.opportunityListMobile = [];
          }

          for (let opportunity of responseData.job_opportunity_list) {
            opportunity.created_at = moment(opportunity.created_at).add(4, 'hours').format('DD/MM/YYYY');
          }

          if (responseData.job_opportunity_list.length > 0) {
            self.opportunityList = responseData.job_opportunity_list
            self.opportunityListMobile = responseData.job_opportunity_list.slice(0, self.pageItemsMobile)
          }

          if (responseData.job_opportunity_list.length != limit) {
            self.canLoadMore = false;
          } else {
            self.canLoadMore = true;
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    registerOpportunity() {
      if (!localStorage.token) {
        this.goTo('./co/cadastro');
        // $('#loginModal').modal('show');
        return
      }
      if (this.user.type == 'EMPLOYEE') {
        this.$router.push('/co/vagas')
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Atenção',
          text: 'Entre como empresa para cadastrar vagas.',
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            // window.open("./co/vagas", "_self"); 
          }
        })
        return
      }
    },
    verifySubscribed(opportunity) {
      let answer = 1;
      for (let candidate of opportunity.candidates) {
        if (localStorage.user) {
          let user = JSON.parse(localStorage.user);
          if (candidate.status != 'CANCELED' && candidate.user._id == user._id) {
            answer = 2;
          }
        }
      }
      return answer;
    },

  }
};
</script>

<style lang="scss">
.opportunity-list-wrapper {
  padding: 30px 0px;

  .title-wrapper {
    display: flex;
    flex-flow: row wrap;

    >div {
      flex: 1;
    }
  }

  .border {
    height: 40px;
    width: 250px;
    border-radius: 12px;

    &-bigger {
      height: 50px;
      width: 300px;
      font-size: 18px;
      margin-top: 40px;
    }
  }

  .opportunity-list {}

}


@media only screen and (max-width: 600px) {

  .opportunity-list-wrapper {
    padding: 0px 15px;

    .title-wrapper {
      .border {
        margin-top: 15px;
        width: 100%;
      }
    }

  }

}
</style>