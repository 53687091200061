<template>
<div class="approve-deny-box">
    <div class="box-item row" @click="salesmanDetails(representation)">
        <div v-if="representation.user.image" class="col-md-1 light-grey-bg d-none d-md-block d-lg-block">
            <img :src="getImageThumbUrl(representation.user.image)"  class="" alt="">
        </div>
        <div v-else class="col-md-1 light-grey-bg d-none d-md-block d-lg-block">
        </div>
        <div class="col-md-5">
            <h3>{{representation.user.name}} {{representation.user.lastname}}</h3>
            <div class="hr-yellow-light hr-4"></div>
            <p v-if="representation.user.address > 0">{{representation.user.address[0].city}} - {{representation.user.address[0].state}}</p>
        </div>
        <div class="col-md-6">
            <!-- <div class="product-amount">Produtos: {{representation.amount}}</div> -->
            <div class="float-right" style="margin-top: 20px;">
                <button @click="call($event)" v-if="representation.status == 'AWAIT' || representation.status == 'APPROVED'">Ligar</button>
                <button @click="sendEmail($event)" v-if="representation.status == 'AWAIT' || representation.status == 'APPROVED'">Email</button>
                <button class="approve" @click="approveRepresentation($event)" v-if="representation.status == 'AWAIT'">Aprovar</button>
                <button class="deny" @click="denyRepresentation($event)" v-if="representation.status == 'AWAIT'">Negar</button>
                <p style="color: orange;font-weight: bold;font-size: 16px;" v-if="representation.status == 'CANCELED'">Cancelado pelo candidato</p>
                <p style="color: red;font-weight: bold;font-size: 16px;" v-if="representation.status == 'DENIED'">Candidato negado</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import VueSweetalert2 from 'vue-sweetalert2';
import { server, local } from "../js/variables.js";

export default {
  props: ['representation'],
  components: {
    
  },
  data() {
    return {
    };
  },
  methods: {
    salesmanDetails(representation){
        var url = "/co/vendedor/" + representation.user.slug;
        this.$router.push(url);
        // window.open("./co/vendedor/" + representation.user._id, "_self");
    },
    approveRepresentation(event){
        let self = this;
        var id = this.$route.params.id;
        if(event.stopPropagation) event.stopPropagation();
        // this.representation.status = 'APPROVED';
        let candidate = {
            _id: this.representation._id,
            status: 'APPROVED',
        }
        if (!localStorage.token) {
            return
        }
        server.config.headers = {
            Authorization: 'Bearer ' + localStorage.token
        }
        this.axios.put(  server.url + '/api/company/' + local.company._id + '/job-opportunity/' + id + '/subscriptions', {
            candidate: candidate,
        }, server.config)
        .then(function (response) {
            let responseData = response.data;
            self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'Ok'
                }).then((result) => {
                if (result.isConfirmed) {
                    // location.reload();
                    window.open(self.$route.fullPath, "_self");
                }
            })
            
        })
        .catch(function (error) {
            // console.log("update - error");
            self.load();
            self.output = error;
            self.showError(error);
        });
    },
    denyRepresentation(event){
		let self = this;
        var id = this.$route.params.id;
        if(event.stopPropagation) event.stopPropagation();
        // this.representation.status = 'DENIED';
        let candidate = {
            _id: this.representation._id,
            status: 'DENIED',
        }
        if (!localStorage.token) {
            return
        }
        server.config.headers = {
            Authorization: 'Bearer ' + localStorage.token
        }
        this.axios.put(  server.url + '/api/company/' + local.company._id + '/job-opportunity/' + id + '/subscriptions', {
            candidate: candidate,
        }, server.config)
        .then(function (response) {
            let responseData = response.data;
            // location.reload();
            // VueScrollTo.scrollTo('#user-data-form');
            self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'Ok'
                }).then((result) => {
                if (result.isConfirmed) {
                    // location.reload();
                    window.open(self.$route.fullPath, "_self");
                }
            })
            
        })
        .catch(function (error) {
            self.load();
            self.output = error;
            self.showError(error);
        });
    },
    call(event){
        if(event.stopPropagation) event.stopPropagation();
        let self = this;
        if(local.permissions.calls < local.permissions.maxCalls){
            if (!localStorage.token) {
                return
            }
            server.config.headers = {
                Authorization: 'Bearer ' + localStorage.token
            }

            this.axios.get(  server.url + '/api/user/' + this.representation.user._id + '/call', server.config)
            .then(function (response) {
                let responseData = response.data.data;
                window.open('https://api.whatsapp.com/send?1=pt_BR&phone=55' + responseData.user.phone , "_blank");
            })
            .catch(function (error) {
                // console.log("get - error");
                self.output = error;
                self.showError(error);
            });
        }else{
            self.$swal({
            icon: 'error',
            title: 'Aviso',
            text: 'Você atingiu o limite de ligações',
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
            }).then((result) => {
            if (result.isConfirmed) {
                // window.open("./vc/candidaturas", "_self"); 
                // const url = "/vc/candidaturas";
                // self.$router.push(url);
            }
            })
        }
    },
    sendEmail(event){
        if(event.stopPropagation) event.stopPropagation();
        let self = this;
        if(local.permissions.emails < local.permissions.maxEmails){
            if (!localStorage.token) {
                return
            }
            server.config.headers = {
                Authorization: 'Bearer ' + localStorage.token
            }

            this.axios.get(  server.url + '/api/user/' + this.representation.user._id + '/email', server.config)
            .then(function (response) {
                let responseData = response.data.data;
                window.open("mailto:" + responseData.user.email , "_blank");
            })
            .catch(function (error) {
                // console.log("get - error");
                self.output = error;
                self.showError(error);
            });
        }else{
            self.$swal({
            icon: 'error',
            title: 'Aviso',
            text: 'Você atingiu o limite de envio de emails',
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
            }).then((result) => {
            if (result.isConfirmed) {
                // window.open("./vc/candidaturas", "_self"); 
                const url = "/co/perfil";
                self.$router.push(url);
            }
            })
        }
    },
  }
};
</script>

<style lang="scss">

.approve-deny-box {
    h3{
        line-height: 35px;
    }

    p{
        line-height: 35px !important;
    }

    button{
        height: 32px;
        width: 113px;
        background-color: var(--main-color-lightgrey);
        color: var(--main-menu-text-color);
        font-weight: lighter;
        margin-left: 6px;
    }
	
    .approve{
        background-color: #28BE50;
        color: white;
    }

    .deny{
        background-color: #EE1313;
        color: white;
    }

    .product-amount{
        font-size: 14px;
        font-weight: bold;
        text-align: right;
        color: var(--main-color-primary);
        margin-bottom: 15px;
    }
}



@media only screen and (max-width: 600px) {
    .approve-deny-box {

        .box-item{
            height: 200px !important;
        }
    }
}
</style>