<template>
  <main-layout>
    <!-- <pre> password {{ $v.postAddress }}</pre> -->
    <!-- <pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container-fluid no-padding">
      <!-- <img src="/static/img/profile-top.jpg" alt style="width: 100%" /> -->
      <div class="light-grey-bg">
        <div class="container company-view-top" v-if="company.name_fancy">
          <div class="row">
            <div class="col-md-3" style="height: 200px">
              <div v-if='company.image' class="store-picture-frame">
                <img :src="getImageThumbUrl(company.image)"  class="" alt="">
              </div>
            </div>
            <div class="col-md-6" style="margin-left: 23px;">
              <h1>{{company.name_fancy}}</h1>
              <h3>{{company.segment.name}}</h3>
              <p style="padding-top: 10px">{{company.description}}</p>
            </div>
          </div>

        </div>
      </div>
      <div class="company-details-view">
        <!-- <product-finder class="top-space" :company="company._id" :showAll = false :showCompanyList = false v-if="company._id"></product-finder> -->
        <product-finder class="section" :showCompanyList = false id="find-products" :especificCompanyProds="$route.params.id"></product-finder>
        <div class="hr-yellow"></div>
        <div class="opportunities-container container">
          <h1 >Vagas</h1>
          <div class="filter-day">
            <h2>Filtrar por</h2>
            <button class="" @click="status = ''">Todas</button>
            <button class="" @click="status = 'OPEN'">Abertas</button>
            <button class="" @click="status = 'CLOSED'">Fechadas</button>
            <button class="" @click="status = 'STANDBY'">Standby</button>
            <button class="" @click="status = 'CANCELED'">Canceladas</button>
            <!-- <button class="" @click="status='CANCELED'">Canceladas</button> -->
          </div>
          <div class="container candidatures">
            <div class="row">
              <div
                class="col-md-6"
                v-for="opportunity in opportunityListFiltered()"
                :key="opportunity._id"
                @click="opportunityNow = opportunity"
                data-toggle="modal"
                data-target="#opportunityDetailsModal"
              >
                <opportunity-item :opportunity="opportunity" :type="0">
                </opportunity-item>
              </div>
            </div>
          </div>

          <opportunity-details-modal
            :modal_id="'opportunityDetailsModal'"
            :opportunity="opportunityNow"
            :showCandidateButton="false"
          ></opportunity-details-modal>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import ProductFinder from "../components/ProductFinder.vue";
import OpportunityItem from "../components/OpportunityItem.vue";
import OpportunityDetailsModal from "../components/OpportunityDetailsModal.vue";
import moment from "moment";
import { server, local } from "../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
    ProductFinder,
    OpportunityItem,
    OpportunityDetailsModal,
  },
  data() {
    return {
      company: {},
      isPaid: 0,
      opportunityList: [],
      status: "",
      search: {
        searchText: null,
      },
      opportunityNow: {},
    };
  },
  validations: {},
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    this.getCompany();
    this.getOpportunities();
  },
  watch: {
  },

  methods: {
    opportunityListFiltered() {
      var list = [];
      for (let item of this.opportunityList) {
        if (item.status.indexOf(this.status) > -1) {
          list.push(item);
        }
      }
      return list;
    },
    getCompany() {
      let self = this;
      var id = this.$route.params.id;

      this.axios
        .get(
          server.url + "/api/company/" + id,
          server.config
        )
        .then(function (response) {
          let responseData = response.data.data;
          self.company = responseData.company;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    getOpportunities() {
      let self = this;
      var id = this.$route.params.id;

      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        // filter: this.search.searchText,
      };
      this.axios
        .get(
          server.url + "/api/job-opportunity/?company=" + id + "&visible=1",
          header
        )
        .then(function (response) {
          let responseData = response.data.data;
          self.opportunityList = responseData.job_opportunity_list;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.company-details-view{

  .opportunities-container{
    padding: 20px;
  }
}

.company-view-top {
	h1{
		text-align: left;
    padding-top: 50px;
	}

	.store-picture-frame{
		width: 150px;
		height: 150px;
		margin-top: 50px;
		// border-radius: 50%;
		// background-position: center center  !important;
		// background-size: cover  !important;

		// border: 2px solid var(--main-color-secundary);

    position: absolute;
    left: 50%;
    margin-left: -75px;
    margin-bottom: 20px;
    
    img{
      max-width: 100%;
      max-height: 100%;
    }
	}
}

@media only screen and (max-width: 600px) {
  .company-details-view{
    width: auto;
    left: 0px;
    margin-left: 10px;
    margin-right: 10px;

    .opportunities-container{
      // padding: 20px;
    }
  }
}
</style>
