<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1 >{{course.name}}</h1>
          </div>
          <div class="col-md-3 ">
            <button @click="backToTrainings()">Voltar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="container top-space lesson-watch">
      <div class="row">
        <div class="col-md-9">
          <div v-if="lesson.video">
            <video-player-item :src = getVideoUrl(lesson.video)></video-player-item>
          </div>
          <div style="background-color: #e6dcdc; height: 380px; width: 100%" v-else></div>
          
          <div class="lesson-watch-infos" v-if="lesson._id">
            <h2>{{lesson.name}}</h2>
            <br>
            <label for="description" style="padding-top: 25px; padding-bottom: 5px">Descrição</label>
            <p v-html="lesson.description"></p>
          </div>
        </div>

        <div class="col-md-3 lesson-watch-sections" style="padding-right: 0px">  
          <div v-for="section in course.sections" :key="section._id">
            <ul class="section-lesson-buttons">
              <li class="light-grey-bg" style="pointer-events: none;" >
                <a >{{section.title}}</a>
              </li>
              <li v-for="item in section.chapters" :key="item._id" @click="goToLesson(item)" v-bind:class="{ 'active ': item.lesson._id == lesson._id }"> 
                <!-- <a v-bind:style=" item.lesson._id == this.$route.params.idLesson? 'padding-top: 11px;' : 'padding-top: 17px;' ">{{item.lesson.name}}</a> -->
                <a>{{item.lesson.name}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import { server } from "../../../js/variables.js";
import userMixin from "../../../mixins/userMixin.js";
import VideoPlayerItem from "../../../components/VideoPlayerItem.vue";
import moment from 'moment';
export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    VideoPlayerItem,
  },
  data() {
    return {
      course: {
        name: '',
        description: '',
        objectives: '',
        segment: {},
        target: '',
        prerequisites: '',
        price: '',
        status: '',
        sections: [],
        video: [],
        image: {},
      },
      lesson: {},
      lessonBefore: {},
      student: {},
      player: {},
      ratingList: [
        {name: 'Geral', value: 0},
        {name: 'Clareza', value: 0},
        {name: 'Ritmo', value: 0},
        {name: 'Relevância', value: 0},
        {name: 'Conteúdo', value: 0},
        {name: 'Diversão', value: 0},
      ],
      assessment: {
        feedback_general: 0,
        feedback_clarity: 0,
        feedback_rhythm: 0,
        feedback_relevance: 0,
        feedback_content: 0,
        feedback_fun: 0,
      },
    };
  },
  validations: {

  },
  mounted() {
    let self = this;
    var idTraining = this.$route.params.idTraining;
    let lessonId = this.$route.params.idLesson;
    this.getLesson();

    this.axios.get( server.url + '/api/course/' + idTraining, server.config)
    .then(function (response) {
      let responseData = response.data.data;
      self.course = responseData.course;
      
      self.course.created_at = moment(self.course.created_at).add(4, 'hours').format('DD/MM/YYYY');

    })
    .catch(function (error) {
      self.output = error;
      self.showError(error);
    });

    
    
  },
	watch:{
		user(newValue, oldValue){
      // console.log('watch')
      if(this.user._id){
        this.getStudents();
      }
		}
	},
  methods: {
    backToTrainings(){
      let self = this;
      var id = this.$route.params.id;
      const url = "/vc/meus-treinamentos/";
      self.$router.push(url);
    },
    goToLesson(item){
      let self = this;
      var idTraining = this.$route.params.idTraining;
      const url = "/vc/meus-treinamentos/" + idTraining + '/aula/' + item.lesson._id + '/visualizacao';
      self.$router.push(url);
      this.getLesson();
    },
    getLesson(){
      let self = this;
      var idTraining = this.$route.params.idTraining;
      let lessonId = this.$route.params.idLesson;
      this.axios.get( server.url + '/api/course/' + idTraining + '/lesson/' + lessonId, server.config)
      .then(function (response) {
        let responseData = response.data.data;
        self.lessonBefore = responseData.lesson;
        if(self.user._id){
          self.getStudents();
        }
        
      })
      .catch(function (error) {
        self.output = error;
        self.showError(error);
      });
    },
    getStudents() {
      let self = this;
      var idTraining = this.$route.params.idTraining;
      var idLesson = this.$route.params.idLesson;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      if(self.user._id){
        let header = JSON.parse(JSON.stringify(server.config));
        header["params"] = {
          active: 1,
          course: idTraining,
          user: self.user._id
        };


        this.axios
        .get(server.url + "/api/student/" , header)
          .then(function (response) {
            let responseData = response.data.data;
            self.student = responseData.student_list[0];
            self.lesson = self.lessonBefore;

          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";



@media only screen and (max-width: 600px) {
  
}
</style>
