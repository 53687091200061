<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
			<div class="container">
				<div class="row" style="">
				<div class="col-md-5 ">
					<h1 >Treinamentos</h1>
				</div>
				<div class="col-md-2 ">
          <button @click="goToContributors()">Colaboradores</button>
				</div>
				<div class="col-md-3 ">
          <button @click="goToTrainingSearch()">Buscar treinamentos</button>
				</div>
				<div class="col-md-2 ">
          <button @click="goToCart()">Carrinho</button>
				</div>
				</div>
			</div>
		</div>
    <div class="training-subscribed-company container">
      <!-- <div style="margin-top: 50px">
        <h3 class="training-tutorial">Sua empresa pode comprar treinamentos para seus funcionáriosem nossa plataforma, clique em <span style="color: var(--main-color-secundary)">"Buscar treinamentos"</span>, encontre os treinamentos ideais, adicione no <span style="color: var(--main-color-secundary)">"Carrinho"</span> e finalize a compra.</h3>
        <h3 class="training-tutorial">Cadastre um treinamento sem precisar comprar. Seu treinador registra um login como profissional, cria um treinamento, seleciona como <span style="color: var(--main-color-secundary)">"Empresa beneficiada"</span> e registra licenças necessárias para cada <span style="color: var(--main-color-secundary)">"Colaborador"</span>.</h3>
        <h3 class="training-tutorial">Sua empresa vai precisar ter licenças, vá em <span style="color: var(--main-color-secundary)">"Plano empresarial licenças"</span> para adquirir licenças</h3>
        <h3 class="training-tutorial">Cada colaborador vai receber um e-mail com convite para assistirem seus treinamentos.</h3>
      </div> -->
      <div class="row" style="margin-top: 50px;text-align:center">
        <div class="col-md-3" style="padding: 0px 5px">
          <div class="box-item">
            <h3>Passo 1</h3>
            <p class="training-tutorial"> Adquira licenças para seus funcionários</p>
            <a href="/co/perfil"> Link rápido</a>
          </div>
          
        </div>
        
        <div class="col-md-3" style="padding: 0px 5px">
          <div class="box-item">
            <h3>Passo 2</h3>
            <p class="training-tutorial"> Cadastre seus colaboradores</p>
            <a href="/co/colaboradores"> Link rápido</a>
          </div>
        </div>
        
        <div class="col-md-3" style="padding: 0px 5px">
          <div class="box-item">
            <h3>Passo 3</h3>
            <p class="training-tutorial"> Adquira um curso na loja, ou um exclusivo direto com o professor</p>
            <a href="/co/buscar-treinamentos/"> Link rápido</a>
          </div>
        </div>
        
        <div class="col-md-3" style="padding: 0px 5px">
          <div class="box-item">
            <h3>Passo 4</h3>
            <p class="training-tutorial"> Libere acesso do treinamento que adquiriu para seus colaboradores. Clique sobre os treinamentos adquiridos</p>
            <a href="#" v-scroll-to="'#trainings-subscribed-list'"> Link rápido</a>
          </div>
        </div>
      </div>
      <h2 class="more-padding" id="trainings-subscribed-list">Treinamentos contratados</h2>
      <h3 class="more-padding" v-if="contractedList.length == 0 || contractedList == null">Você ainda nao possui nenhuma lincensa de treinamento</h3>
      <div class="row" v-for="license in contractedList" :key="license._id" style="padding-bottom: 15px;">
        <div class="col-md-3" v-if="license.course != null">
          <div class="light-grey-bg">
            <img :src="getImageThumbUrl(license.course.image)" v-if="license.course.image" class="training-image" alt="">
          </div>
        </div>
        <div class="col-md-9 info-box" v-if="license.course != null">
          <div class="row">
            <div class="col-md-8">
              <h3>{{license.course.name}}</h3>
              <div class="hr-yellow-light hr-4"></div>
              <div class="teacher">{{license.course.user.name}} {{license.course.user.lastname}}</div>
              <p v-html="license.course.description"></p>
            </div>
            <div class="col-md-4">
              <div class="right-data" style="padding-top: 20px;">Alunos: {{license.students}}</div>
              <br>
              <div class="right-data">Licenças: {{license.buyed}}</div>
              <br>
              <!-- <div class="right-data">Investimentos: R$5200,00</div> -->
              <div class="info-box-btns">
                <button>Assistir</button>
                <button style="margin-left: 10px" @click="goToTraining(license.course)">Gerenciar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <h2 class="more-padding">Treinamentos exclusivos</h2>
      <h3 class="more-padding" v-if="exclusiveList.length == 0 || exclusiveList == null">Você ainda nao contratou nenhum treinamento exclusivo</h3>
      <div class="row" v-for="license in exclusiveList" :key="license._id" style="padding-bottom: 15px;">
        <div class="col-md-3">
          <div class="light-grey-bg" style="width: 100%; height: 185px; border-radius: 11px">
            <img :src="getImageThumbUrl(license.course.image)" v-if="license.course.image" class="training-image" alt="">
          </div>
        </div>
        <div class="col-md-9 info-box">
          <div class="row">
            <div class="col-md-8">
              <h3>{{license.course.name}}</h3>
              <div class="hr-yellow-light hr-4"></div>
              <div class="teacher">{{license.course.user.name}} {{license.course.user.lastname}}</div>
              <p v-html="license.course.description"></p>
            </div>
            <div class="col-md-4">
              <div class="right-data" style="padding-top: 20px;">Alunos: {{license.students}}</div>
              <br>
              <div class="right-data">Licenças: {{license.buyed}}</div>
              <br>
              <!-- <div class="right-data">Investimentos: R$5200,00</div> -->
              <div class="info-box-btns">
                <button v-if="checkSutudentList(license.course)" @click="goToWatchTraining(license.course)">Assistir</button>
                <button style="margin-left: 10px" @click="goToTraining(license.course)">Gerenciar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </main-layout>
</template>

<script>
import companyMixin from "../../../mixins/companyMixin.js";
import MainLayout from "../../../layouts/Company.vue";
import moment from 'moment';
import { server, local } from "../../../js/variables.js";

export default {
  mixins: [companyMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      licenseList: [],
      contractedList: [],
      exclusiveList: [],
      studentList: [],
    };
  },
  mounted() {
    var parameters = this.$route.query;
    // console.log(this.$route);
  },
  watch: {
    company(newValue, oldValue) {
      // console.log(newValue, oldValue);
      // console.log("watch company", this.company);
      if(this.company._id){
        this.getCourses();
      }

      if (local.studentList && local.studentList.length > 0) {
        this.studentList = local.studentList;
        for(let course of this.studentList){
          course.course.created_at = moment(course.course.created_at).add(4, 'hours').format('DD/MM/YYYY');
        }
      }
    },
  },
  methods: {
    
    getCourses() {
      let self = this;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      this.axios
      .get(server.url + "/api/course/licenses", server.config)
      // .get(server.url + "/api/course/?company=" + this.company._id, server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.licenseList = responseData.license_list;
          self.contractedList = [];
          self.exclusiveList = [];

          self.exclusiveList = responseData.exclusive_licenses_list;
          self.contractedList = responseData.license_list;

          // for(let license of self.licenseList){
          //   license.course.created_at = moment(license.course.created_at).add(4, 'hours').format('DD/MM/YYYY');
          //   if(license.course.exclusive_company == null){
          //     self.contractedList.push(license);
          //   }else{
          //     self.exclusiveList.push(license);
          //   }
          // }

          
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    goToTrainingSearch(){
      let self = this;
      var id = this.$route.params.id;
      const url = "/co/buscar-treinamentos/" ;
      self.$router.push(url);
    },
    goToTraining(license){
      let self = this;
      var id = this.$route.params.id;
      const url = "/co/meus-treinamentos-detalhes/" + license._id;
      self.$router.push(url);
    },
    goToCart(){
      let self = this;
      window.open("./co/carrinho", "_self");
    },
    goToContributors(){
      const url = "/co/colaboradores/" ;
      this.$router.push(url);
    },
    checkSutudentList(course){
      for(let item of this.studentList){
        if(item.course._id == course._id){
          return true
        }
      }
      return false
    },
    goToWatchTraining(course){
      let self = this;
      const url = "/co/meus-treinamentos/" + course._id;
      self.$router.push(url);
    }
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.training-subscribed-company{
  .training-image{
    width: 100%;
    max-height: 100%;
    border-radius: 11px;
  }

  .box-item{
    height: 100%;
    padding: 25px 8px;
    padding-bottom: 30px;
    cursor: inherit;

    &:hover{
      border: 1px solid var(--main-color-mediumgrey) !important;	
    }

    a{
      position: absolute;
      bottom: 18px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  h2{
    padding: 50px 0px;
  }

  p{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-top: 3px;
  }

  .training-tutorial{
    color: var(--main-menu-text-color);
    margin-bottom: 10px;
    font-weight: 500;
  }

  .col-md-3{

    .light-grey-bg{
      width: 100%; 
      height: 185px; 
      border-radius: 11px
    }
  }

  .hr-yellow-light.hr-4{    
    margin-bottom: 8px;
    margin-top: 8px;
  }


  .info-box{
    padding: 30px 40px;
    border-radius: 11px;
    background-color: var(--main-color-lightgrey);

    .teacher{
      font-size: 13px;
      color: var(--main-title-color);
    }

    .right-data{
      color: var(--main-title-color);
      font-size: 14px;
      font-weight: bold;
      width: 100%;
      text-align: right;
    }

    .info-box-btns{
      float: right;
      button{
        width: auto;
        height: 34px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .training-subscribed-company{
    
    h2{
      padding: 30px 0px;
    }

    .info-box{
      border-radius: 0px;
      background-color: transparent;
    }

    .col-md-3{
      padding: 0px 0px;

      .light-grey-bg{
        border-radius: 0px
      }
    }

    .more-padding{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
</style>
