<template>
  <main-layout>
	<!-- <pre> password {{ $v.postAddress }}</pre> -->
	<!-- <pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9">
            <h1>Produto</h1>
          </div>
          <div class="col-md-3">
            <button @click="goBack()">Voltar</button>
            <!-- <button @click="viewProduct()">Visualizar produto</button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container product-details">
        <div class="row">
			<!-- <div class="col-md-12 last">
				<button class="blue " type="button" style="width: 150px; margin-bottom: 15px;" @click="goBack()">Voltar</button>
			</div> -->
            <div class="col-md-5">
                <h2>{{product.company.name_fancy}}</h2>
                <h1>{{product.name}}</h1>
                <div class="hr-yellow hr-4" style="margin-top: 10px; margin-bottom: 10px;"></div>
                <h2 v-if="product.groups[0]">{{ product.groups[0].name }}</h2>
                <h2 v-else></h2>
            </div>
            <div class="col-md-7 last">
                <!-- <button>Solicitar representação de produto. Quero vender este produto</button> -->
            </div>
            <!-- <div class="col-md-12 row" >
                <div class="col-md-4 " v-for="image in product.image" :key="image._id">
                    <img :src="getImageThumbUrl(image)" alt="" class="product-image box-item">
                </div>
            </div> -->
            <div class="col-md-4 top-space" v-for="image in product.image" :key="image._id">
                <img :src="getImageThumbUrl(image)" alt="" class="product-image box-item">
            </div>
            <div class="col-md-12 top-space">
                <h2>Descrição</h2>
                <p class="blue-text grey-bg">{{product.description}}</p>
            </div>
            <div class="col-md-6 top-space">
                <h2>Preço</h2>
                <div class="grey-bg">
                    <p class="blue-text prices-bigger">Preço: <b>{{product.price | toCurrency}}</b></p>
                    <!-- <hr> -->
                    <!-- <p class="blue-text prices-bigger">Comissão: <b>{{product.sales_commission}}% ({{(product.sales_commission/100) * product.price | toCurrency}})</b></p> -->
                </div>
            </div>
            <div class="col-md-6 top-space">
                <!-- <h2>Ajuda de custo da empresa</h2>
                <div class="grey-bg">
                    <p class="blue-text prices-bigger">A partir: <b>{{product.company.salary_min | toCurrency}}</b></p>
                    <hr>
                    <p class="blue-text prices-bigger">Até: <b>{{product.company.salary_max | toCurrency}}</b></p>
                </div> -->
            </div>
            <div class="col-md-5 top-space">
                <h2>Outros produtos da família / categoria</h2>
            </div>
            <div class="col-md-7 last top-space">
                <!-- <button>Solicitar representação de categoria. Quero vender os produtos desta categoria</button> -->
            </div>
            <!-- <div class="row"> -->
            <div
                class="col-6 col-md-3"
                v-for="product in productList"
                :key="product._id" >
                <product-item :product="product" :type="0"> </product-item>
            </div>
            <!-- </div> -->
        </div>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Company.vue";
import moment from 'moment'
import { server } from "../../../js/variables.js";
import ProductItem from "../../../components/ProductItem.vue";
import { required, email, sameAs } from 'vuelidate/lib/validators'


export default {
	components: {
    MainLayout,
    ProductItem
  },
  data () {
    return {
        product:{
			name: '',
			description: '',
			is_featured: '',
			representation: '',
			unit_measurement: '',
			sales_commission: '',
			ean: '',
			price: '',
			call: '',
			tech_info: '',
			groups: [],
        },
        productList: [],
    }
  },
  validations: {

},
	mounted() {
		
		var parameters = this.$route.query;
		let self = this;
		this.getProduct();
	},
	watch:{
	},
	
  methods: {
    getProduct(){
		let self = this;
		var id = this.$route.params.id;
		// console.log("get salesmans");
		// if (!localStorage.token) {
		// 	return
		// }
		// server.config.headers = {
		// 	Authorization: 'Bearer ' + localStorage.token
		// }

		this.axios.get(  server.url + '/api/product/' + id, server.config)
		.then(function (response) {
			let responseData = response.data.data;
            self.product = responseData.product;
            self.productList = responseData.product_list;
            
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
		});
	},
	goBack(){
		let self = this;
		var id = this.$route.params.id;
		window.open("./co/catalogo/produto/" + id, "_self"); 
    }
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.product-details{
    padding-top: 45px;
    padding-bottom: 45px;
    white-space: break-spaces;
    
    h2{
        margin-bottom: 10px;
    }

    p{
        font-size: 16px;
    }

    .product-image{
        // width: 130px;
        // max-height: 293px;
        // border: 1px solid var(--main-color-mediumgrey);
        // border-radius: 10px;
        // padding: 10px;
        // cursor: none;
        pointer-events:none;
    }

    .grey-bg{
        background-color: var(--main-color-lightgrey);
        border-radius: 12px;
        padding: 20px 50px;
        
    }

    .prices-bigger{
        font-size: 18px;
    }

    .blue-text{
        color: var(--main-title-color) !important;
    }

    button{
        width: auto;
    }
}



</style>
