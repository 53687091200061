<template>
  <main-layout>


    <contact></contact>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
// import ProductItem from "../components/ProductItem.vue";
// import Newsletter from "../components/Newsletter.vue";
import Contact from "../components/Contact.vue";
import { server } from "../js/variables.js";

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
    // ProductItem,
    // Newsletter,
    Contact,
  },
  data() {
    return {
      productList: [
        { id: 1, name: "Produto 1", voltage: "12V", price: 300, photo: link },
        { id: 2, name: "Produto 2", voltage: "12V", price: 300, photo: link },
        { id: 3, name: "Produto 3", voltage: "18V", price: 500, photo: link },
        { id: 4, name: "Produto 4", voltage: "18V", price: 500, photo: link }
      ],
      blocksTop: [],
      blocksBottom: []
    };
  },
  mounted() {
    var parameters = this.$route.query;

    // this.loadBlocks();
    // this.getBlocks();
  },
  methods: {
    
    register(e) {
      // window.open("./company-registration", "_self");
      const url = "/company-registration";
      this.$router.push(url);
    },
    onPageChange(page) {
      this.sliderPage = page;
    },
    gotostore() {
      // window.open("./store", "_self");
      const url = "/store";
      this.$router.push(url);
    }
  }
};
</script>
