import { server, local } from '../js/variables.js'
import moment from 'moment'

var companyMixin = {
  data: function () {
    return {
      company:{
        name: '',
        name_fancy: '',
        document: '',
        site: '',
        email: '',
        birthdate: '',
        phone: '',
        description: '',
        size: '',
        salary_min: '',
        salary_max: '',
        segment: '',
        // status: "FREE",
        created_at: '',
        updated_at: '',
        employees:[],
        address: [],
      },
    }
  },
  // beforeCreate: function() {
  //   if (!localStorage.token) {
  //     server.config.headers = {
  //       Authorization: 'Bearer ' + localStorage.token
  //     }
  //   }
  // },
  mounted() {
    this.company = local.company;
    this.listencompany();
    
  },
  methods: {
    listencompany() {
      let self = this;
      // console.log('listencompany 1');
      setTimeout(function(){
        // console.log('listencompany 2');
        
        if(local.company && local.company._id) {
          // console.log('listencompany 2.1');
          // console.log("local company",local.company);
          self.company = local.company;
          // self.fixDate();
        } else {
          // console.log('listencompany 2.2');
          self.listencompany();
        }
      }, 1000);
    },
    prepareCompanyToSend(companyCopy){
      // Clear user Document mask
      let document = companyCopy.document;
      if(document.length == 18){
        let documentNoMask = document.substring(0, 2) + document.substring(3, 6) + document.substring(7, 10) + document.substring(11, 15) + document.substring(16, 18);
        companyCopy.document = documentNoMask;
      }
      
      
      // Clear phone mask
      let phone = companyCopy.phone;
      if(phone.length > 13){
        let phoneNoMask = phone.substring(1, 3) + phone.substring(5, 10) + phone.substring(11, 15);
        companyCopy.phone = phoneNoMask;
      }

      companyCopy.birthdate   = this.convertDate( companyCopy.birthdate );
      
    },
    insertMasks(afterError) {
      if (this.user) {
        if (this.user.phone != '' && this.user.phone != null) {
          let phoneWithMask = '(' + this.user.phone.substring(0, 2) + ') ' + this.user.phone.substring(2, 7) + '-' + this.user.phone.substring(7, 11);

          this.user.phone = phoneWithMask;
        }
        if (this.user.document != '' && this.user.document != null) {
          let documentWithMask = this.user.document.substring(0, 3) + '.' + this.user.document.substring(3, 6) + '.' + this.user.document.substring(6, 9) + '-' + this.user.document.substring(9, 11);

          this.user.document = documentWithMask;
        }
        
        if(afterError){
      
          if(this.user.birthdate == null ){
            return null
          }
          if(this.user.birthdate == "Invalid date" ){
            return null
          }
          let dateFormated = moment(this.user.birthdate , 'YYYY-MM-DD').format('DD/MM/YYYY');
          let dateString = '' + dateFormated.toString();

          
          this.user.birthdate = dateString;
        }
      }

      if (this.company) {
        if (this.company.document != '' && this.company.document != null) {
          let documentWithMask = this.company.document.substring(0, 2) + '.' + this.company.document.substring(2, 5) + '.' + this.company.document.substring(5, 8) + '/' + this.company.document.substring(8, 12) + '-' + this.company.document.substring(12, 14);
          this.company.document = documentWithMask;
        }
        if (this.company.phone != '' && this.company.phone != null) {
          let phoneWithMask = '(' + this.company.phone.substring(0, 2) + ') ' + this.company.phone.substring(2, 7) + '-' + this.company.phone.substring(7, 11);
          this.company.phone = phoneWithMask;
        }

        if(afterError){
      
          if(this.company.birthdate == null ){
            return null
          }
          if(this.company.birthdate == "Invalid date" ){
            return null
          }
          let dateFormated = moment(this.company.birthdate , 'YYYY-MM-DD').format('DD/MM/YYYY');
          let dateString = '' + dateFormated.toString();

          
          this.company.birthdate = dateString;
        }
      }


    },
    convertDate(data) {
      //let dateFormated = moment(data , 'DD/MM/YYYY');
      // console.log("Dateformated", dateFormatedExperienceS);
      if(data == null ){
        return null
      }
      if(data == "Invalid date" ){
        return null
      }
      let dateFormated = moment(data , 'DD/MM/YYYY').format('YYYY-MM-DD');
      let dateString = '' + dateFormated.toString();

      
      return dateString;
    },
  }

}

export default companyMixin;