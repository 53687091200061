<template>
  <div>
    <load-bars></load-bars>
    <header>
      <div class="header-top-space" v-if="headerType == 10"></div>
      <div class="type-1 container center" v-else>
        <div class="row only-desktop">
          <div class="col-md-1" v-if="headerType == 2 || headerType == 3">

          </div>
          <div class="col-md-5 d-none d-md-block d-lg-block last"
            v-bind:class="{ 'offset-md-3 ': !user._id, 'offset-md-3': user._id }">
            <ul class="menu float-right">
              <li>
                <a href target="_self">
                  <img src="/static/svg/icon_home.svg" alt class="logo" />
                </a>
              </li>
              <li>
                <a href="./sobre-a-empresa">Sobre</a>
              </li>
              <li>
                <a href="./afiliados">Afiliados</a>
              </li>
              <li>
                <a href="./treinadores">Treinadores</a>
              </li>
              <li v-if="!user._id">
                <a href="./planos">Planos</a>
              </li>
              <li v-if="user._id && company != null">
                <a href="./co/perfil">Planos</a>
              </li>
              <li v-if="user._id && !company">
                <a href="./vc/perfil">Planos</a>
              </li>

            </ul>
          </div>
          <div class="col-md-3" v-if="user._id">
            <a href="./co/vagas" v-if="user.type !== 'SALESMAN' && headerType != 5">
              <i class="fas fa-user-circle" v-if="!company.image"></i>
              <div class="menu-picture-frame" style="float: left;" v-else
                :style="'background: url(' + getImageThumbUrl(company.image) + ')'"></div>
              <h3 class="user-name" style="padding-top: 6px;">
                {{ company.name_fancy }}<br />
                {{ user.name.split(/[ ,]+/)[0] }} {{ user.lastname.split(/[ ,]+/)[user.lastname.split(/[ ,]+/).length -
                    1]
                }}
              </h3>
            </a>
            <a href="./em/meus-dados" v-if="user.type !== 'SALESMAN' && headerType == 5">
              <i class="fas fa-user-circle" v-if="!company.image"></i>
              <div class="menu-picture-frame" style="float: left;" v-else
                :style="'background: url(' + getImageThumbUrl(company.image) + ')'"></div>
              <h3 class="user-name" style="padding-top: 6px;">
                {{ company.name_fancy }}<br />
                {{ user.name.split(/[ ,]+/)[0] }} {{ user.lastname.split(/[ ,]+/)[user.lastname.split(/[ ,]+/).length -
                    1]
                }}
              </h3>
            </a>
            <a href="./vc/perfil" v-if="user.type === 'SALESMAN'">
              <i class="fas fa-user-circle" v-if="!user.image"></i>
              <div class="menu-picture-frame" v-else :style="'background: url(' + getImageThumbUrl(user.image) + ')'">
              </div>
              <h3 class="user-name" style="padding-top: 14px;">
                {{ user.name.split(/[ ,]+/)[0] }} {{ user.lastname.split(/[,]+/)[user.lastname.split(/[ ,]+/).length -
                    1]
                }}
              </h3>
            </a>
          </div>
          <div class="col-md-3" v-if="!user._id">
            <ul class="menu float-right">
              <li style="margin-right: 0px;">
                <button class="border" data-toggle="modal" data-target="#loginModal">
                  Login
                </button>
              </li>
              <li style="margin-right: 0px;">
                <button v-if="$route.fullPath != '/para-empresas'" class="border" style="margin-right: 5px"
                  data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#salesmanRegisterModal">
                  Cadastrar
                </button>
                <button v-else class="border" data-dismiss="modal" aria-label="Close" data-toggle="modal"
                  data-target="#companyRegisterModal">
                  Cadastrar
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="headerType == 10">
        <div>
          <div id="header-color-bg" class="container-fluid">
            <div class="row">
              <div class="blue"></div>
              <div class="yellow"></div>
            </div>
          </div>
          <div id="header-color" class="container">
            <div class="row blue">
              <div class="col col-md-3">
                <figure class="logo">
                  <a href target="_self">
                    <img src="/static/svg/logo.svg" alt class="logo" />
                  </a>
                </figure>
              </div>
              <div class="menu-btn only-mobile" @click="openMobileMenu">
                <i class="fas fa-bars"></i>
              </div>
              <div class="col-md-8 d-none d-md-block d-lg-block">
                <ul class="menu-buttons-home">
                  <li class="only-desktop1" @click="scrollToAbout()">
                    <p>Sobre</p>
                  </li>
                  <li class="separator"></li>
                  <li class="only-desktop1" @click="scrollToAffiliate()">
                    <p>Afiliados</p>
                  </li>
                  <li class="separator"></li>
                  <li class="only-desktop1" @click="goTo('/planos')">
                    <p>Planos</p>
                  </li>
                  <li class="separator"></li>
                  <li class="only-desktop1" @click="scrollToTrainings()">
                    <p>Treinadores</p>
                  </li>
                </ul>
              </div>
              <ul class="col col-md-1 menu yellow d-none d-md-block d-lg-block">
                <button class="border" style="width: 130px;margin-left: -10px;" @click="goToUserLogged" v-if="user._id">
                  Área logada
                </button>

                <!-- <h3 class="user-name" style="padding-top: 14px;" v-if="user._id">
                  {{ user.name.split(/[ ,]+/)[0] }} {{ user.lastname.split(/[,]+/)[user.lastname.split(/[ ,]+/).length -
                      1]
                  }}
                </h3> -->
                <button class="border" data-toggle="modal" data-target="#loginModal" v-else>
                  Login
                </button>
              </ul>
            </div>
          </div>
        </div>

      </div>

      <div v-if="headerType == 1 || headerType == 4">
        <!--  main salesman-->
        <div>
          <div id="header-color-bg" class="container-fluid">
            <div class="row">
              <div class="blue"></div>
              <div class="yellow"></div>
            </div>
          </div>
          <div id="header-color" class="container">
            <div class="row blue">
              <div class="col col-md-3">
                <figure class="logo">
                  <a href target="_self">
                    <img src="/static/svg/logo.svg" alt class="logo" />
                  </a>
                </figure>
              </div>
              <div class="col-md-7 d-none d-md-block d-lg-block" v-if="headerType == 1">
                <ul class="menu-buttons">
                  <li class="only-desktop1" @click="scrollToProducts()">
                    <a>Produtos para representar</a>
                  </li>
                  <li class="only-desktop1" @click="scrollToOpportunities()">
                    <a>Vagas de emprego <br>
                      comercial/vendas</a>
                  </li>
                  <li class="only-desktop1" @click="scrollToTrainings()">
                    <a>Treinamento comercial</a>
                  </li>
                </ul>
              </div>
              <ul v-if="headerType == 1" class="col col-md-2 menu yellow d-none d-md-block d-lg-block"
                @click="goToCompany()" style="cursor: pointer;">
                <h4 class="black-color" style="margin-top: -4px;">Ir para área</h4>
                <h2 class="black-color">Empresarial ></h2>
              </ul>

              <div class="col-md-7 d-none d-md-block d-lg-block" v-if="headerType == 4">
                <ul class="menu-buttons">
                  <li class="only-desktop1" v-on:click="goTo('./co/')">
                    <a>Cadastrar produtos para <br>
                      representantes</a>
                  </li>
                  <li class="only-desktop1" v-on:click="goTo('./co/vagas/')">
                    <a>Anunciar vagas</a>
                  </li>
                  <li class="only-desktop1" v-on:click="goTo('./co/vendedores/buscar')">
                    <a>Contratar equipe de vendas</a>
                  </li>
                </ul>
              </div>
              <ul v-if="headerType == 4" class="col col-md-2 menu yellow d-none d-md-block d-lg-block"
                @click="goToSalesman()" style="cursor: pointer;">
                <h4 class="black-color" style="margin-top: -4px;">Ir para área</h4>
                <h2 class="black-color">Profissional ></h2>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div v-if="headerType == 2">
        <!--  company -->

        <div>
          <div class="container-fluid main-color-bg company-menu-bg">
            <div class="container center">
              <div class="row main-color-bg company-menu">
                <div class="col col-md-2">
                  <figure class="logo">
                    <a href="/co/catalogo/" target="_self">
                      <img src="/static/svg/icon_logo_menu.svg" alt class="logo" />
                    </a>
                  </figure>
                  <a href="./co/perfil" class="d-block d-md-none d-lg-none">
                    <i class="fas fa-user header-no-picture" v-if="!company.image"></i>
                    <div class="header-picture-frame" v-else
                      :style="'background: url(' + getImageThumbUrl(company.image) + ')'"></div>
                  </a>
                </div>
                <div class="col-md-10 d-none d-md-block d-lg-block">
                  <ul class="menu-buttons">
                    <li></li>
                    <li v-on:click="goTo('./co/catalogo/')">
                      <a>Produtos para representação</a>
                    </li>
                    <!-- <li v-on:click="goTo('./co/vendedores/favoritos')">
                      <a >Profissionais favoritados</a>
                    </li>
                    <li v-on:click="goTo('./co/vendedores/buscar')">
                      <a >Buscar Profissionais</a>
                    </li> -->
                    <li v-on:click="goTo('./co/vagas')">
                      <a>Vagas de emprego</a>
                    </li>
                    <li v-on:click="goTo('./co/meus-treinamentos')">
                      <a>Treinamentos</a>
                    </li>
                    <li v-on:click="goTo('./co/vendedores/buscar')">
                      <a>Buscar profissionais ou representantes</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="container-fluid light-grey-bg grey-part-bg" style="margin-bottom: 0px">
            <div class="container">
              <div class="row light-grey-bg grey-part">
                <div class="col-md-8" v-if="company">
                  <h1>{{company.name}}</h1>
                  <p v-if="company.segment">{{company.segment.name}}</p>
                  <p v-else></p>
                </div>
                <div class="col-md-4 last d-none d-md-block d-lg-block" v-if="company.image">                  
                  <img
                    :src="getImageThumbUrl(company.image)"
                    style="height: 53px;position: absolute; right: 15px;"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div> -->

        </div>
      </div>

      <div v-if="headerType == 5">
        <!--  employee -->

        <div>
          <div class="container-fluid main-color-bg company-menu-bg">
            <div class="container center">
              <div class="row main-color-bg company-menu">
                <div class="col col-md-2">
                  <figure class="logo">
                    <a href="/co/catalogo/" target="_self">
                      <img src="/static/svg/icon_logo_menu.svg" alt class="logo" />
                    </a>
                  </figure>
                  <a href="./em/meus-dados" class="d-block d-md-none d-lg-none">
                    <i class="fas fa-user header-no-picture" v-if="!company.image"></i>
                    <div class="header-picture-frame" v-else
                      :style="'background: url(' + getImageThumbUrl(company.image) + ')'"></div>
                  </a>
                </div>
                <div class="col-md-10 d-none d-md-block d-lg-block">
                  <ul class="menu-buttons">
                    <li></li>
                    <li v-on:click="goTo('./em')" class="disable-li">
                      <a>Produtos para representação</a>
                    </li>
                    <!-- <li v-on:click="goTo('./co/vendedores/favoritos')">
                      <a >Profissionais favoritados</a>
                    </li>
                    <li v-on:click="goTo('./co/vendedores/buscar')">
                      <a >Buscar Profissionais</a>
                    </li> -->
                    <li v-on:click="goTo('./em')" class="disable-li">
                      <a>Vagas de emprego</a>
                    </li>
                    <li v-on:click="goTo('./em/meus-treinamentos')">
                      <a>Meus treinamentos</a>
                    </li>
                    <li v-on:click="goTo('./em')" class="disable-li">
                      <a>Representantes</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="container-fluid light-grey-bg grey-part-bg" style="margin-bottom: 0px">
            <div class="container">
              <div class="row light-grey-bg grey-part">
                <div class="col-md-8" v-if="company">
                  <h1>{{company.name}}</h1>
                  <p v-if="company.segment">{{company.segment.name}}</p>
                  <p v-else></p>
                </div>
                <div class="col-md-4 last d-none d-md-block d-lg-block" v-if="company.image">                  
                  <img
                    :src="getImageThumbUrl(company.image)"
                    style="height: 53px;position: absolute; right: 15px;"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div> -->

        </div>
      </div>

      <div v-if="headerType == 3">
        <!--  salesman -->
        <div>
          <div class="container-fluid main-color-bg company-menu-bg">
            <div class="container center">
              <div class="row main-color-bg company-menu">
                <div class="col col-md-2">
                  <figure class="logo">
                    <a href="/vc/" target="_self">
                      <img src="/static/svg/icon_logo_menu.svg" alt class="logo" />
                    </a>
                  </figure>
                  <a href="./vc/perfil/" class="d-block d-md-none d-lg-none">
                    <i class="fas fa-user header-no-picture" v-if="!user.image"></i>
                    <div class="header-picture-frame" v-else
                      :style="'background: url(' + getImageThumbUrl(user.image) + ')'"></div>
                  </a>
                </div>
                <div class="col-md-10 d-none d-md-block d-lg-block">
                  <ul class="menu-buttons">
                    <li></li>
                    <li v-on:click="goTo('./vc/')">
                      <a>Produtos para vender</a>
                    </li>
                    <!-- <li v-on:click="goTo('./vc/solicitacoes')">
                      <a >Buscar solicitados</a>
                    </li> -->
                    <!-- <li v-on:click="goTo('./vc/candidaturas')">
                      <a >Vagas inscritas</a>
                    </li> -->
                    <li v-on:click="goTo('./vc/buscar-vagas')">
                      <a>Vagas de emprego</a>
                    </li>
                    <li v-on:click="goTo('./vc/buscar-treinamentos')">
                      <a>Treinamento comercial</a>
                    </li>
                    <li v-on:click="goTo('./vc/catalogo')">
                      <a>Meus produtos / Minha loja online</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav class="menu-items" :class="{ show: showMobileMenu }">
        <ul>

          <li>
            <figure class="logo">
              <a href target="_self">
                <img src="/static/svg/logo.svg" alt class="logo" />
              </a>
            </figure>
          </li>
          <li @click="scrollToAbout()">
            <a>Sobre</a>
          </li>
          <li @click="scrollToAffiliate()">
            <a>Afiliados</a>
          </li>
          <li @click="goTo('/planos')">
            <a>Planos</a>
          </li>
          <li @click="scrollToTrainings()">
            <a>Treinadores</a>
          </li>
        </ul>
      </nav>
      <div class="header-overlay" v-if="showMobileMenu" @click="hideMobileMenu"></div>
    </header>
  </div>
</template>

<script>
import companyMixin from "../mixins/companyMixin.js";
import userMixin from "../mixins/userMixin.js";
import { server, local } from "../js/variables.js";
import LoadBars from "./LoadBars.vue";

export default {
  mixins: [companyMixin, userMixin],
  props: ["headerType"],
  components: {
    LoadBars
  },
  data() {
    return {
      findTerms: "",
      customer: {},
      company: {},
      searchWord: "",
      contact: {
        name: "",
        email: "",
        city: "",
        company: "",
        phone: "",
        subject: "",
        message: "",
      },
      menuItems: [
        { name: 'Universo', link: "/categoria/universo" },
        { name: 'Economia', link: "/categoria/economia" },
        { name: 'Geografia', link: "/categoria/geografia" },
        { name: 'História', link: "/categoria/historia" },
        { name: 'Dicas', link: "/categoria/dicas" },
        { name: 'Corpo humano', link: "/categoria/corpohumano" },
      ],
      showMobileMenu: false,
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.user);
    this.company = JSON.parse(localStorage.company);
  },
  watch: {
    user(newValue, oldValue) {
      if (this.user._id) {
        // this.user = JSON.parse(localStorage.user);
        // this.company = JSON.parse(localStorage.company);
      }

    }
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    find(e) {
      window.open("#" + this.findTerms, "_self");
    },
    checkSubmit: function (e) {
      if (e.keyCode === 13) {
        this.find(null);
      }
    },
    goToLogin() {
      // window.open("./login", "_self");
      const url = "/login";
      this.$router.push(url);
    },
    goToRegister() {
      if (this.$route.fullPath != '/para-empresas') {
        // window.open("./co/cadastro", "_self");
        const url = "/vc/cadastro";
        this.$router.push(url);
      } else {
        const url = "/co/cadastro";
        this.$router.push(url);
      }

    },
    goToCompany() {
      // window.open("./para-empresas", "_self");
      const url = "/para-empresas";
      this.$router.push(url);
    },
    goToSalesman() {
      // window.open("./", "_self");
      const url = "/";
      this.$router.push(url);
    },
    goToUserLogged() {
      let url = ''
      if (this.user.type === 'SALESMAN') url = '/vc/perfil'
      else url = '/co/perfil'
      this.$router.push(url);
    },
    goToProfile() {
      // console.log(this.customer.email);
      if (
        this.customer.email == null ||
        this.customer.email == "[não informado]"
      ) {
        // window.open("./login", "_self");
        const url = "/login";
        this.$router.push(url);
      } else {
        // window.open("./cliente/info?menu=open", "_self");
        const url = "/cliente/info?menu=open";
        this.$router.push(url);
      }
    },
    scrollToOpportunities() {
      if (this.$route.fullPath === '/') {
        this.$scrollTo('#find-opportunities');
      } else {
        const url = "/";
        this.$router.push(url);
      }
    },
    scrollToTrainings() {
      if (this.$route.fullPath === '/') {
        this.$scrollTo('#find-trainings');
        this.hideMobileMenu()
      } else {
        const url = "/";
        this.$router.push(url);
      }
    },
    scrollToAbout() {
      if (this.$route.fullPath === '/') {
        this.$scrollTo('#find-about');
        this.hideMobileMenu()
      } else {
        const url = "/";
        this.$router.push(url);
      }
    },
    scrollToAffiliate() {
      if (this.$route.fullPath === '/afiliados') {
        this.$scrollTo('#find-affiliate');
        this.hideMobileMenu()
      } else {
        const url = "/afiliados";
        this.$router.push(url);
      }
    },
    scrollToProducts() {
      if (this.$route.fullPath === '/') {
        this.$scrollTo('#find-products');
      } else {
        const url = "/";
        this.$router.push(url);
      }
    },
    openMobileMenu() {
      this.showMobileMenu = true
    },
    hideMobileMenu() {
      this.showMobileMenu = false
    },
    // goTo(url){
    //   window.open(url, "_self");
    // }
    //TODO: busca
  },
};
</script>

<style lang="scss">
/* Header */
#header-color {
  height: 74px;
  position: relative;

  img.logo {
    max-width: 100%;
  }

  .row {
    &::before {
      content: "";
      width: 90%;
      height: 100%;
      position: absolute;
      border-radius: 0 5px 5px 0px;
      background-color: var(--main-color-primary);
    }
  }

  .blue {
    // background-color: var(--main-color-primary);
    height: 74px;

    .menu-btn {
      position: absolute;
      right: 0;
      height: 100%;
      display: flex !important;
      align-items: center;

      i {
        color: white !important;
        margin-right: 15px;
        margin-top: 0px;
      }
    }
  }

  .yellow {
    background-color: var(--main-color-secundary);
    height: 54px;
    border-radius: 5px 0px 0px 5px;
    margin-top: 20px;
    padding-top: 14px;
    padding-left: 23px;
    pointer-events: all;

    a {
      font-size: 16px;
      font-weight: bold;
    }
  }
}


.black-color {
  color: black;
  font-weight: bold;
}

#header-color-bg {
  position: absolute;
  height: 74px;
  z-index: -1;

  .blue {
    background-color: var(--main-color-primary);
    height: 74px;
    width: 70%;
    display: inline-block;
  }

  .yellow {
    background-color: var(--main-color-secundary);
    height: 54px;
    width: 30%;
    margin-top: 20px;
    display: inline-block;
  }
}

.company-menu {
  // margin-right: 0px;
  // margin-left: 0px;

  img {
    margin-top: -7px;
  }

  figure {
    padding: 4px;
  }
}

.company-menu-bg {
  z-index: -1;
  height: 74px;
}

.grey-part {
  padding: 32px 15px;
  height: 120px;

  h1 {
    text-align: left;
  }
}

.header-picture-frame {
  height: 45px;
  width: 45px;
  margin: auto;
  margin-top: -2px;
  border: 2px solid var(--main-color-secundary);
  background-position: center center !important;
  background-size: cover !important;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 0px;
}

.header-no-picture{
  position: absolute;
  top: 16px;
  right: 0px;
  height: 45px;
  width: 45px;
  margin: auto;
  color: white !important;
}

.grey-part-bg {
  height: 120px;
  z-index: -1;
  margin-bottom: 50px;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   height: 70px;
  margin-bottom: 0px !important;
  z-index: 1000;
  position: relative;

  .header-top-space {
    height: 18px;
  }

  .menu-buttons-home {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    li {
      vertical-align: bottom !important;

      p {
        margin-bottom: 10px;
        color: white;
        font-size: 16px;
      }
    }

    .separator {
      width: 1px;
      height: 100%;
      background-color: #8080803d;
      padding: 0px !important;
    }
  }

  .type-1 {
    // width: 85%;
    padding: 10px 0px;
    height: 70px;

    .user-name {
      display: inline-block;
      position: absolute;
      // padding-top: 17px;
      padding-left: 14px;
    }

    button {
      width: 102px !important;
      margin-left: 3px;
    }
  }

  ul.menu {
    position: relative;
    left: 0px;
    padding: 10px 16px;

    a {
      color: var(--main-title-color);
      font-size: 16px;
      font-weight: bold;
    }

    i {
      font-size: 16pt;
    }
  }

  i.fas {
    color: var(--main-title-color);
    margin-top: 7px;
    font-size: 22pt;
  }

  .menu-picture-frame {
    height: 40px;
    width: 40px;
    margin-top: 5px;
    border: 2px solid var(--main-color-secundary);
    background-position: center center !important;
    background-size: cover !important;
    border-radius: 50%;
    display: inline-block;
  }

  .menu-items {
    width: 100%;
    color: white;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 70%;
    height: 100vh;
    background-color: var(--main-color-primary);
    z-index: 1;
    justify-content: center;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 2s;

    ul {
      display: flex;
      align-items: center;
      flex-direction: column;
      list-style-type: none;
      // justify-content: center;
      margin-top: 100px;

      li {
        padding: 12px 8px;

        a {
          text-decoration: none;
          color: white;
          font-size: 17px;
        }

        figure {
          margin-bottom: 80px;
        }
      }
    }
  }

  .header-overlay {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.7;
    width: 30%;
    height: 100vh;
  }

  .show {
    display: flex !important;
  }

}

.top-menu {
  box-shadow: 1px 3px 3px #eee;
  border-radius: 50px;
  height: 90px;
  background: #fff;
  padding-top: 28px;
}

header figure.logo {
  margin-top: -17px;
  /* border: 5px #fff solid; */
}

header ul.menu {
  position: relative;
  left: 0px;
  margin-top: 4px;
}

header ul.menu li {
  display: inline;
  margin-right: 20px;
  position: relative;
}

span.cart-alert {
  background: red;
  border-radius: 50%;
  position: absolute;
  font-size: 8pt;
  top: -6px;
  right: 0px;
  height: 12px;
  width: 12px;
  text-align: center;
  color: white;
  line-height: 10px;
}

header ul.menu li span {
  cursor: pointer;
}

header ul.menu li a {
  color: var(--main-menu-text-color);
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}

header ul.menu li a:hover {
  text-decoration: none;
  color: var(--main-color-primary);
}

.header-icon {
  margin-right: 20px;
  display: block;
  float: left;
  margin-top: 0px;
}

.menu-icon {
  margin-top: -7px;
}

.button-sitemap {
  background: var(--main-color-mediumgrey);
  border-radius: 13px;
  width: 100%;
  color: var(--main-menu-text-color);
  font-size: 8pt;
  font-weight: 300;
  padding: 10px 15px;
  position: relative;
}

.button-sitemap-hamburguer {
  float: left;
  /* margin-top: -3px; */
}

.button-sitemap-arrow {
  float: right;
  margin-top: 1px;
}

.button-sitemap:hover .button-sitemap-hamburguer,
.button-sitemap:hover .button-sitemap-arrow {
  filter: invert(96%) sepia(70%) saturate(0%) hue-rotate(134deg) brightness(110%) contrast(100%);
}

.menu-header {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
  border-radius: 3rem;
  background-color: #fff0;
  border: 0px;
}

.menu-header input {
  margin-bottom: 22px;
  text-align: center;
  font-weight: bold;
  border-radius: 5rem;
}

.menu-header .button-menu {
  border: 2px solid #aaaeb8;
  border-radius: 2.5rem;
  margin-bottom: 8px;
  background-color: #fff0;
  height: 160px;
  padding: 12px;
  width: 100%;
}

.menu-header .button-menu:hover {
  border: 0px;
  background-color: #fff;
  color: #5c6377;
}

.menu-header .button-menu:hover img {
  border: 0px;
  /* background-color: #fff; */
  color: #5c6377;
  filter: contrast(1000%) invert(60%);
}

.menu-header .button-menu:hover h3 {
  color: #5c6377;
}

.menu-header h3 {
  color: var(--main-color-lightgrey);
  font-size: 14px;
  margin-top: 20px;
  font-weight: lighter;
}

.menu-header .menu-item {
  padding-left: 4px;
  padding-right: 4px;
}

.disable-li {
  pointer-events: none;

  a {
    opacity: 0.3;
  }
}

@media only screen and (max-width: 600px) {
  header {
    margin-bottom: 0px !important;
    height: 60px;
    position: fixed;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  #header-color-bg {
    display: none;
  }

  #header-color .row::before {
    width: 100%;
    border-radius: 0;
  }

  header .type-1 {
    padding: 0px;
    height: 0px;
  }

  header figure.logo {
    top: 0;
    margin: auto;
    margin-top: 0px;
  }

  header figure.logo img {
    height: 60px;
  }

  header .menu-icon {
    margin-top: 0px;
  }

  .top-menu {
    border-radius: 0;
    height: 60px;
    padding-top: 20px;
    box-shadow: 0px 0px 0px #eee;
  }

  .top-menu .last {
    padding: 0px;
  }

  header ul.menu {
    margin-top: 0;
  }

  .only-desktop {
    display: none !important;
  }

  .btn_sidebar {
    display: inline;
  }

  header figure {
    max-width: 80%;
    display: block;
    /* margin: auto;
        margin-top: 16px; */
  }

  .header-icon img {
    max-width: 90%;
    margin-left: 18px;
    margin-top: 12px;
  }

  .button-login {
    margin-top: 4px;
  }

  .modal-content.menu-header {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-radius: 3rem;
  }

  .company-menu {

    figure {
      padding-top: 10px
    }
  }
}

@media only screen and (max-width: 600px) {
  .header-top-space {
    display: none;
  }

  .grey-part {
    padding: 20px 15px;

    h1 {
      font-size: 20pt !important;
    }
  }
}
</style>