<template>
  <main-layout>
	<!-- <company-menu></company-menu> -->
    
	<div class="container-fluid recover-password">
		<div class="row">
			<div class="col-md-4 ">
                <br><br>
				<a href target="_self">
					<img src="/static/svg/logo_clean.svg" alt="" class="center">
				</a>
                
				<h1>Recuperar senha</h1>
                <div class="inputs-recover-password center">
                    <input type="email" placeholder="Email" v-model="login.email">
                    <div class="password">
                        <input id="password" :type="passwordInputType" v-model="$v.login.password.$model" placeholder="Senha">
                        <button class="clean" @click="showHidePassword()">
                            <i class="far fa-eye" v-if="passwordInputType === 'password'"></i>
                            <i class="far fa-eye-slash" v-if="passwordInputType === 'text'"></i>
                        </button>
                    </div>
                    
                    <div class="password">
                        <input id="confirm" :type="confirmInputType" v-model="$v.login.confirm.$model" placeholder="Confirme senha">
                        <button class="clean" @click="showHideConfirm()">
                            <i class="far fa-eye" v-if="confirmInputType === 'password'"></i>
                            <i class="far fa-eye-slash" v-if="confirmInputType === 'text'"></i>
                        </button>
                    </div>
                    
                    <!-- <input type="password" placeholder="Senha" v-model="login.password">
                    <input type="password" placeholder="Confirme senha" v-model="login.confirm"> -->
                    
                    <button class="blue float-right" @click="changePassword()">Salvar</button>
                </div>
			</div>
			<div class="col-md-8 no-padding">
                <img src="/static/img/vc-register.jpg" alt />
			</div>
		</div>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Basic.vue";
import { server } from "../js/variables.js";
import { required, email, sameAs } from 'vuelidate/lib/validators'

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,

  },
  data () {
	return {
		slideList:[],
		login: {
			email: '',
			password: '',
			confirm: '',
		},
        passwordInputType: 'password',
        confirmInputType: 'password',
	}
  },
  mounted() {
	var parameters = this.$route.query;
    let self = this;
  },
  validations: {
	login: {
		email: { required },
		password: { required },
		confirm: { required },
	},
        
  },
  methods: {
    changePassword() {
        let self = this;
        let recoverPasswordToken = this.$route.params.id;
        if(this.login.password !== this.login.confirm){
            this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Verifique se as senhas coincidem ',
                // footer: '<a href>Why do I have this issue?</a>'
            });
            return
        }
        
        if(this.login.password.length < 6){
            this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Senha deve ter no mínimo 6 caracteres',
            // footer: '<a href>Why do I have this issue?</a>'
            });
            return
        }
        
        if (!this.$v.$invalid){

            this.axios.post(  server.url + '/api/auth/reset-password', {
                email: self.login.email,
                password: self.login.password,
                confirm: self.login.confirm,
                token: recoverPasswordToken,

            }, server.config)
            .then(function (response) {
                let responseData = response.data;
                self.$swal({
					icon: 'success',
					title: 'Sucesso',
					text: responseData.message,
					// footer: '<a href>Why do I have this issue?</a>'
					showCancelButton: false,
					confirmButtonColor: '#053360',
					confirmButtonText: 'Ok'
					}).then((result) => {
					if (result.isConfirmed) {
						window.open ('/login' , '_self');
					}
				})
                
            })
            .catch(function (error) {
                self.output = error;
                self.showError(error);
                console.log(error);
            });
        }else{
            this.$v.$touch();
            this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Verifique os campos obrigatórios',
                // footer: '<a href>Why do I have this issue?</a>'
            });
        }
    },
    showHidePassword(){
        this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    },
    showHideConfirm(){
        this.confirmInputType = this.confirmInputType === 'password' ? 'text' : 'password'
    },
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.recover-password{

    h1{
        text-align: center;
        // font-size: 36px;
        margin-top: 40px;
        margin-bottom: 30px;
    }

	

    img{
        // margin-top: 20px;
        // margin-bottom: 60px;
        // padding-top: 70px;
        // padding-bottom: 30px;
    }

    .create-acount{
        height: 55px;
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 0px;
        
        
        p{
            color: white;
            display: inline-block;
            margin-right: 5px;
            margin-top: 14px;
        }

        a{
            color: var(--main-color-secundary);
            // display: inline-block;
        }

    }
    
    .inputs-recover-password{
        width: 85%;

        button{
            font-weight: normal;
            padding: 8px 20px; 

            &:focus{
               outline: none; 
            }
        }

        input{
            background-color: var(--main-color-lightgrey);
            border: 0px !important;
            margin-bottom: 10px;
        }
        
        .password{
            position: relative;
            button{
                position: absolute;
                right: 0px;
                top: 4px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {

	.recover-password .inputs-recover-password{
		margin-bottom: 50px;
		position: relative;
	}

	.recover-password .create-acount {
		position: fixed;
		bottom: 0px;
		left: 0px;
		z-index: 1000;
	}
}
</style>
