<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1 >Carrinho</h1>
          </div>
          <div class="col-md-3 ">
            <button class="" @click="keepAdding()">Continuar adicionando itens</button>
          </div>
        </div>
      </div>
    </div>
    <div class="container cart-user">
      <div class="row">
        <div class="col-md-9 row remove-side-margin">
          <div class="col-md-6 d-none d-md-block d-lg-block">
            <h3>Descrição</h3>
          </div>
          <div class="col-md-4 d-none d-md-block d-lg-block">
            <h3 style="text-align: center;">Quantidade</h3>
          </div>
          <div class="col-md-2 d-none d-md-block d-lg-block">
            <h3 style="text-align: center;">Remover</h3>
          </div>
          <div class="col-md-12" style="margin-top: 12px" v-for="item in cart" :key="item._id">
            <br>
            <h3>{{item.shopper.name}} {{item.shopper.lastname}}</h3>
            <div v-for="training in item.items" :key="training._id">
              <div class="light-grey-bg cart-item row">
                <div class="col-md-2">
                  <img
                    :src="getImageThumbUrl(training.course.image)"
                    v-if="training.course.image"
                    class="product-image-1"
                    alt=""
                  />
                </div>
                <div class="col-md-4">
                    <h3>{{training.course.name}}</h3>
                    <div class="light-yellow-line"></div>
                    <!-- <h4>{{training.course.segment.name}}</h4> -->
                    <p>Preço venda: <b>{{training.course.price | toCurrency}}</b></p>
                </div>
                <div class="col-md-4">
                  <div  class="d-block d-md-none d-lg-none">
                    <p style="display: inline-block;">Qtd: </p>
                    <div class="cart-item-amount">
                      {{training.amount}}
                    </div>
                  </div>
                  <div class="cart-item-amount d-none d-md-block d-lg-block">
                    {{training.amount}}
                  </div>
                  
                </div>
                <div class="col-md-2 d-none d-md-block d-lg-block">
                  <button class="clean remove-btn" @click="removeItem(item, training)">X</button>
                </div>
                <button class="clean remove-btn d-block d-md-none d-lg-none" @click="removeItem(item, training)">X</button>
              </div>
            </div>
          </div>
          <div
            class="col-md-12"
            style="margin-top: 12px"
            v-if="cart.length == 0"
          >
          <h2>Você ainda nao adicionou nenhum treinamento ao carrinho.</h2>
          </div>
        </div>
        <div class="col-md-3">
          <h3>Finalizar</h3>
          <div class="resume-box" >
            <h3>Resumo</h3>
            <div class="light-yellow-line"></div>
            <div class="amount-itens" style="margin-top: 25px">
              Treinamentos únicos: {{amountItems}}
            </div>
            <div class="amount-itens" style="margin-top: 5px">
              Total: {{total | toCurrency}}
            </div>
            <!-- <p>Id diam maecenas ultricies mi. Mi ipsum faucibus vitae aliquet nec ullamcorper sit amet. Est pellentesque elit ullamcorper dignissim cras tincidunt.</p> -->
            <!-- <input type="text" placeholder="Seu e-mail"> -->
            <button @click="prepareOrder()">Finalizar compra</button>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
// import Contact from "../components/Contact.vue";
import userMixin from "../../../mixins/userMixin.js";
import { server, local } from "../../../js/variables.js";

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    // Contact,
  },
  data() {
    return {
      cart: [
        // {amount: 2, product:{name: 'Produto 1', price: 50, sales_commission: 12, groups:[{name: 'Categoria Um'}], image: null}},
        // {amount: 1, product:{name: 'Produto 2', price: 60, sales_commission: 5, groups:[{name: 'Categoria Dois'}], image: null}},
        // {amount: 5, product:{name: 'Produto 3', price: 10, sales_commission: 5, groups:[{name: 'Categoria Um'}], image: null}},
        // {amount: 1, product:{name: 'Produto 4', price: 70, sales_commission: 5, groups:[{name: 'Categoria Dois'}], image: null}},
      ],
      amountItems: 0,
      total: 0,
    };
  },
  validations: {
  },
  mounted() {
    var parameters = this.$route.query;
    // location.reload();
    

  },
  watch: {
    user(newValue, oldValue) {
      // console.log(newValue, oldValue);

      if(local.cartList){
        this.cart = local.cartList;
        let cartToRemove = [];
        for(let cart of this.cart){
          if(cart.shopper == null){
            cartToRemove.push(cart)
          }
        }
        for(let c of cartToRemove){
          let index = this.cart.indexOf(c);
          if (index > -1) {
            this.cart.splice(index, 1);
          }
        }
        console.log('carrinho', this.cart)
        this.amountItems = 0
        this.total = 0;
        for(let cart of this.cart){
          this.amountItems += cart.items.length;
          this.total += cart.price;
          if(cart.shopper == null){
            cartToRemove.push(cart)
          }
        }
      }
    },
  },
  methods: {
    plusButton(item){
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      let amountUpdated = item.amount + 1;

      let object = {
        course: item.course._id,
        amount: amountUpdated,
      }
      item.amount++;

      this.axios.put(  server.url + '/api/cart/update-item/', {
        item: object,
      }, server.config)
      .then(function (response) {
        let responseData = response.data; 

      })
      .catch(function (error) {
        self.output = error;
        item.amount--;
        self.showError(error);
      });
    },
    minusButton(item, training){
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      if(training.amount == 1){
        this.removeItem(item, training);
      }else{
        let amountUpdated = training.amount - 1;

        let object = {
          course: training.course._id,
          amount: amountUpdated,
        }
        training.amount--;
        this.axios.put(  server.url + '/api/cart/update-item/', {
          item: object,
        }, server.config)
        .then(function (response) {
          let responseData = response.data; 
          

        })
        .catch(function (error) {
          self.output = error;
          training.amount++;
          self.showError(error);
        });
      }
    },
    removeItem(item, training){
      let self = this;
      var id = this.$route.params.id;

      // this.$swal.fire({
      // title: 'Realmente deseja excluir este treinamento?',
      // text: "Esta ação é irreversível!",
      // icon: 'warning',
      // showCancelButton: true,
      // confirmButtonColor: '#053360',
      // cancelButtonColor: '#d33',
      // cancelButtonText: 'Cancelar',
      // confirmButtonText: 'Excluir'
      // }).then((result) => {
      // if (result.isConfirmed) {
        if (!localStorage.token) {
          return
        }
        server.config.headers = {
          Authorization: 'Bearer ' + localStorage.token
        }

        let object = {
          course: training.course._id,
        }

        this.axios.put(  server.url + '/api/cart/remove-item/', {
          item: object,
        }, server.config)
        .then(function (response) {
          let responseData = response.data;
          let indexItem = self.cart.indexOf(item);
          let indexTraining = self.cart[indexItem].items.indexOf(training);
          self.cart[indexItem].items.splice(indexTraining, 1);
          self.amountItems--;
          if(self.cart[indexItem].items.length == 0){
            self.cart.splice(indexItem, 1);
          }
          self.total -= training.price * training.amount;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
      // }
      // })
    },
    prepareOrder(){
      let self = this;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      this.axios.post(  server.url + '/api/order/' + local.cartList[0]._id + '/prepare', server.config)
      .then(function (response) {
        let responseData = response.data;
        const url = "/vc/pedido/finalizacao";
        self.$router.push(url);
      })
      .catch(function (error) {
        self.output = error;
        self.showError(error);
      });
    },

    keepAdding(){
      let self = this;
      const url = "/vc/buscar-treinamentos/";
      self.$router.push(url);
    },

  },
};
</script>

<style lang="scss">
// @import "../scss/reset.scss";
// @import "../scss/base.scss";

.cart-user{
  padding-top: 45px;

  p{
    line-height: 16pt !important;
  }
  .cart-item{
    border-radius: 18px;
    padding: 25px 20px;
    margin-top: 6px;
    margin-bottom: 12px;

    h4{
      font-size: 14px;
      margin-bottom: 12px;
    }

    img{
      height: 55px;
    }

    .clean{
      font-size: 28px;
      font-weight: bold;
    }

    .cart-item-amount{
      font-size: 28px;
      font-weight: bold;
      color: var(--main-color-primary);
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%)
    }

    button{
      color: var(--main-color-primary);
    }

    .remove-btn{
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%)
    }

  }

  .blue{
    width: 250px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .resume-box{
    background-color: var(--main-color-primary);
    border-radius: 12px;
    margin-top: 12px;
    padding: 30px;
    position: relative;

    h3{
      color: white;
    }

    p{
      margin-top: 20px;
      margin-bottom: 15px;
      color: white;
    }

    .amount-itens{
      color: var(--main-color-secundary);
      // color: white;
      font-size: 18px;
    }

    button{
      // position: absolute;
      // right: 40px;
      // bottom: -17px;
      width: 100%;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .cart-user{
    .cart-item{

      h3{
        padding-top: 15px;
      }

      .cart-item-amount{
        position: initial;
        top: 0px;
        margin-right: 0px;
        margin-left: 10px;
        transform: initial;
        display: inline-block;
      }

      .remove-btn{
        top: 45px;
        left: initial;
        right: 10px;
        margin-right: 0px;
        transform: initial;
      }

    }
  }
}

</style>
