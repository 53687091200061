import CompanyEmployeeData from '../views/CompanyEmployeeData.vue';
import TrainingSubscribedEmployee from '../views/TrainingSubscribedEmployee.vue';
import TrainingWatchEmployee from '../views/TrainingWatchEmployee.vue';
import LessonWatchEmployee from '../views/LessonWatchEmployee.vue';
import Page404 from '../views/404.vue';

import axios from 'axios'
import { server } from '../../../js/variables.js'

const guard = function(to, from, next) {
  let path = encodeURI(window.location.pathname) ;
  if (localStorage.token) {
    next();
  } else {
    // window.open("./", "_self");
    window.open("./login?redirect="+path, "_self");
  }
};

export default [
    { path: '/em', component: TrainingSubscribedEmployee, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/em/meus-dados', component: CompanyEmployeeData, beforeEnter: (to, from, next) => { guard(to, from, next); }  },
    { path: '/em/meus-treinamentos', component: TrainingSubscribedEmployee, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/em/meus-treinamentos/:id', component: TrainingWatchEmployee, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/em/meus-treinamentos/:idTraining/aula/:idLesson', component: LessonWatchEmployee, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '*', component: Page404 } 
 ]
