<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1 >Confirmar pedido</h1>
          </div>
          <div class="col-md-3 ">
            <button class="" @click="keepAdding()">Continuar adicionando itens</button>
          </div>
        </div>
      </div>
    </div>
    <div class="container section order-confirm">
      <h2>Forma de pagamento</h2>
      <div class="row">
        <!-- Lista de cartões -->
        <div
          v-for="card in cardList"
          :key="card._id"
          class="col-md-3"
        >
          <div
            class="card-grey-box"
            @click="selectCard(card)"
          >
            <!-- <h3 v-if="card.card_type == 'CREDIT'"><b>Crédito</b></h3>
            <h3 v-if="card.card_type == 'DEBIT'"><b>Débito</b></h3> -->
            <h4>
              <!-- {{ card.number.substring(0, 4) }}
              {{ card.number.substring(4, 8) }}
              {{ card.number.substring(8, 12) }}
              {{ card.number.substring(12, 16) }} -->
              **** **** **** {{card.last_four}}
            </h4>
            <p>
              {{ card.holder_name }} <br />
              Vencimento: {{ card.exp_month }}/{{ card.exp_year }}
            </p>
            <div
              class="box-check"
              :class="{'box-selected': selectedCard == card}"
              alt=""
              style="padding-top: 5px"
            >
              <img src="/static/svg/icon_check_blue.svg" style="height: 30px">
            </div>
          </div>
        </div>

        <!-- Novo cartão-->
        <div
          class="col-md-3 d-none d-md-block d-lg-block"
          data-toggle="modal"
          data-target="#cardModal"
          @click="cleanCard()"
          v-if="cardList!= null && cardList.length < 3"
        >
          <div class="card-empty-box">
            <img
              src="/static/svg/icon_plus.svg"
              alt=""
            >
          </div>
        </div>
        <div
          class="col-md-3 d-none d-md-block d-lg-block"
          data-toggle="modal"
          data-target="#cardModal"
          @click="cleanCard()"
          v-if="cardList!= null && cardList.length < 2"
        >
          <div class="card-empty-box">
            <img
              src="/static/svg/icon_plus.svg"
              alt=""
            >
          </div>
        </div>
        <div
          class="col-md-3 d-none d-md-block d-lg-block"
          data-toggle="modal"
          data-target="#cardModal"
          @click="cleanCard()"
          v-if="cardList!= null && cardList.length < 1"
        >
          <div class="card-empty-box">
            <img
              src="/static/svg/icon_plus.svg"
              alt=""
            >
          </div>
        </div>
        <div
          class="col-md-3"
          data-toggle="modal"
          data-target="#cardModal"
          @click="cleanCard()"
        >
          <div class="card-empty-box">
            <img
              src="/static/svg/icon_plus.svg"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="cardModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="cardModalLabel"
      aria-hidden="true"
      style="background-color: #5c6377b8;"
    >

      <div
        class="modal-dialog modal-lg"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body modal-contact">
            <button
              type="button"
              class="close-btn"
              data-dismiss="modal"
              aria-label="Close"
              style="position: absolute; right: 25px; top: -30px;"
            >
              <span aria-hidden="true"></span>
              X
            </button>
            <form-card
              class="form-inside"
              :card="selectedCard"
              :showTitle=0
              @saved="listCards()"
            ></form-card>
          </div>
        </div>
      </div>
    </div>

    <div class="container cart-company">
      <div class="row">
        <div class="col-md-9 row">
          <div class="col-md-8 d-none d-md-block d-lg-block">
            <h3>Descrição</h3>
          </div>
          <div class="col-md-4 d-none d-md-block d-lg-block">
            <h3 style="text-align: center;">Quantidade</h3>
          </div>
          <!-- <div class="col-md-2">
            <h3 style="text-align: center;">Remover</h3>
          </div> -->
          <div class="col-md-12" style="margin-top: 12px" v-for="item in cart" :key="item._id">
            <br>
            <h3>{{item.shopper.name}} {{item.shopper.lastname}}</h3>
            <div v-for="training in item.items" :key="training._id">
              <div class="light-grey-bg cart-item row">
                <div class="col-md-2">
                  <img
                    :src="getImageThumbUrl(training.course.image)"
                    v-if="training.course.image"
                    class="product-image-1"
                    alt=""
                  />
                </div>
                <div class="col-md-6">
                    <h3>{{training.course.name}}</h3>
                    <div class="light-yellow-line"></div>
                    <!-- <h4>{{training.course.segment.name}}</h4> -->
                    <p>Preço venda: <b>{{training.course.price | toCurrency}}</b></p>
                </div>
                <div class="col-md-4">
                  <div class="cart-item-amount">
                    <!-- <button class="clean" @click="minusButton(item, training)">-</button> -->
                    {{training.amount}}
                    <!-- <button class="clean" @click="plusButton(training)">+</button> -->
                  </div>
                  
                </div>
                <!-- <div class="col-md-2">
                  <button class="clean remove-btn" @click="removeItem(item, training)">X</button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <h3>Finalizar</h3>
          <div class="resume-box" >
            <h3>Resumo</h3>
            <div class="light-yellow-line"></div>
            <div class="amount-itens" style="margin-top: 25px">
              Treinamentos únicos: {{amountItems}}
            </div>
            <div class="amount-itens" style="margin-top: 5px">
              Total: {{total | toCurrency}}
            </div>
            <!-- <p>Id diam maecenas ultricies mi. Mi ipsum faucibus vitae aliquet nec ullamcorper sit amet. Est pellentesque elit ullamcorper dignissim cras tincidunt.</p> -->
            <!-- <input type="text" placeholder="Seu e-mail"> -->
            <button @click="orderBuy()">Finalizar compra</button>
          </div>
        </div>
      </div>
    </div>
    
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Company.vue";
import companyMixin from "../../../mixins/companyMixin.js";
import FormCard from "../../../components/FormCard.vue";
import { server, local } from "../../../js/variables.js";

const link = "/static/img/produto.png";

export default {
  mixins: [companyMixin],
  components: {
    MainLayout,
    FormCard,
  },
  data() {
    return {
      order_id: "",
      selectedCard: {},
      cardList: [],
      productList: [],
      order: {
        coupon: "",
        amount: 0,
        price: 0,
        freight: 0,
        discount: 0,
        total: 0,
      },
      amountItems: 0,
      total: 0,
      cart: [],
    };
  },
  mounted() {
    var id = this.$route.params.id;
    

  },
  watch: {
    company(newValue, oldValue) {
      if (this.company.card && this.company.card.length > 0) {
        this.cardList = this.company.card;
        this.selectedCard = this.cardList[0];
      }

      if(local.cartList){
        this.cart = local.cartList;
        console.log('carrinho', this.cart)
        this.amountItems = 0;
        this.total = 0;
        for(let cart of this.cart){
          this.amountItems += cart.items.length;
          this.total += cart.price;
        }
      }
    },
  },
  methods: {
    cleanCard() {
      this.selectedCard = {
        favorite: 0,
        number: "",
        security_code: "",
        last_four: "", // Não usa no cadastro
        first_six: "", // Não usa no cadastro
        holder_name: "",
        holder_document: "",
        holder_additional_name: "", // Não usa no cadastro
        token: "", // Não usa no cadastro
        exp: "",
        exp_month: 0,
        exp_year: 0,
        brand: "",
        card_type: "",
        _id: "",
      };
    },
    selectCard(card) {
      this.selectedCard = card;
    },
    orderBuy(){
      let self = this;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      if (!this.selectedCard._id) {
        this.$swal({
          icon: "error",
          title: "Defina um cartão de crédito válido",
          // text: answer.message,
          // footer: '<a href>Why do I have this issue?</a>'
        });
        return
      }

      let working = 0;
      var params = {
        payment: {
          card: self.selectedCard._id,
          // cvv: self.selectedCard.security_code,
          // address: null,
        },
      };
      for(let cart of self.cart){
        this.axios.post(  server.url + '/api/order/' + cart._id + '/buy', params, server.config)
        .then(function (response) {
          let responseData = response.data;
          console.log('entrou', working);
          working ++;
          console.log('depois', working, self.cart.length);
          if(working == self.cart.length){
            console.log('igual');
            const url = "/co/pedido/sucesso";
            self.$router.push(url);
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
      }
    },
    keepAdding(){
      let self = this;
      const url = "/co/buscar-treinamentos/";
      self.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
.order-confirm {

  h2{
    padding-bottom: 20px;
  }

  .card-grey-box {
    border-radius: 10px;
    background-color: var(--main-color-lightgrey);
    padding: 30px;
    margin-bottom: 15px;
    cursor: pointer;

    .box-check {
      margin: auto;
      height: 30px;
      width: 30px;
      img{
        display: none;
      }
    }

    .box-check.box-selected img{
        display: block;
    }
  }

  .card-empty-box{
    background-color: var(--main-color-lightgrey);
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 15px 15px;
    height: 152px;
    cursor: pointer;

    img {
      display: block;
      margin: auto;
      margin-top: 24px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .order-confirm {
    
    .card-grey-box {
      margin-left: 15px;
      margin-right: 15px;

    }

    .card-empty-box{
      margin-left: 15px;
      margin-right: 15px;

    }
  }

}
</style>