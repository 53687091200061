<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div" style="padding-top: 25px;">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1 style="margin-top: 15px">Minha loja</h1>
          </div>
          <div class="col-md-3 ">
           <button class="button-share" @click="share">Enviar link para clientes</button>
           <button class="clean" @click="goToCatalog()">Acessar catálogo</button>
          </div>
        </div>
      </div>
    </div>
    

    <!-- <div class="container">
      <div class="row company-list" v-if="companyList != null" >
        <div class="col-md-2 company-list-item" v-for="company in companyList"  :key="company._id">
          <div style="height: 155px; width: 155px;" class="box-item">
            <img :src="getImageThumbUrl(company.image)"  class="" alt="">
          </div>
          
          <h2 >{{company.name}}</h2>
        </div>
      </div>
    </div> -->
    <!-- <company-list :companyList="companyList" v-if="companyList != null"></company-list> -->
    <product-finder class="top-space" :salesman="user._id" :showAll = false :showCompanyList = true :titleText="'Produtos que eu represento'" v-if="user._id"></product-finder>

    <!-- <contact></contact> -->
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import userMixin from "../../../mixins/userMixin.js";
// import Contact from "../../../components/Contact.vue";
// import CompanyList from "../../../components/CompanyList.vue";
import ProductFinder from "../../../components/ProductFinder.vue";
// import Banner from '../../../components/Banner.vue'
import { server } from "../../../js/variables.js";

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    // Contact,
    ProductFinder,
    // CompanyList,
  },
  data() {
    return {
      shareInfo: {
        url: "",
        title: "",
        call: "",
      },
      representationList: [
        // {id: 1, name: "Notebook Samsung", category: "Categoria 1", call: "Descrição produto 1", price: 1999.90, commission: 12, city: "São Paulo", comissionReais: 100, company: "Samsung"},
        // {id: 2, name: "Produto 2", category: "Categoria 2", call: "Descrição produto 2", price: 29.90, commission: 10, city: "São Paulo", comissionReais: 100, company: "Samsung"},
        // {id: 3, name: "Produto 3", category: "Categoria 3", call: "Descrição produto 3", price: 9.90, commission: 28, city: "São Paulo", comissionReais: 100, company: "Samsung"},
        // {id: 4, name: "Produto 4", category: "Categoria 4", call: "Descrição produto 4", price: 35.90, commission: 18, city: "São Paulo", comissionReais: 100, company: "Samsung"},
      ],
      productDash: {
        approved: 0,
        await: 0,
        denied: 0,
      },
      search: {
        brand: "",
        category: "",
        company: "",
        searchText: "",
      },
      brandList: [],
      companyList: [],
      placeList: [],
      categoryList: [],
    };
  },
  watch: {
    user(newValue, oldValue) {
      // console.log(newValue._id, oldValue._id);
      // console.log("AQUI",this.user);
      this.share.url = window.location.origin + "/catalogo/" + this.user.slug;
      this.share.title = "Loja do " + this.user.name + " " + this.user.lastname;
      this.share.call = "Encontre os melhores produtos para você !";
    },
  },
  mounted() {
    var parameters = this.$route.query;
    this.getMyProduct();
  },
  methods: {
    register(e) {
      window.open("./company-registration", "_self");
    },
    goToCatalog() {
      window.open(this.share.url, "_blank");
    },
    share() {
      let self = this;
      var url = window.location.origin + "/catalogo/" + this.user.slug;
      var title = "Loja do " + this.user.name + " " + this.user.lastname;
      var call = "Encontre os melhores produtos para você !";

      // if (navigator.share) {
      //   navigator.share({
      //       title: self.share.title,
      //       text: self.share.call,
      //       url: self.share.url,
      //     })
      //     .then(() => console.log('Successful share'))
      //     .catch((error) => console.log('Error sharing', error));
      // } else {
        self.$swal({
          // icon: "success",
          title: "Onde você quer enviar ?",
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: true,
          confirmButtonText: "Whatsapp",
          cancelButtonText: "Copiar link",
          // closeOnConfirm: false,
          // closeOnConfirm: false,
        }).then((result) => {
          // console.log('GotoCarrinho',result);
          var url = self.share.url
          var text = self.share.title;
          if(result.value) {
            window.open("https://api.whatsapp.com/send?text=" + self.share.url, "_blank");
            // window.open("http://www.facebook.com/share.php?u=" + self.share.url, "_blank");
          } else {
            // window.open("https://twitter.com/share?url=" + self.share.url +"&text=" + self.share.call , "_blank");
            navigator.clipboard.writeText(self.share.url);
          }
        })
        console.log('Share not supported on this browser, do it the old way.');
      // }
    },
    getMyProduct() {
      let self = this;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      this.axios
        .get(
          server.url + "/api/sales-representative/?approved=1",
          server.config
        )
        .then(function (response) {
          let responseData = response.data.data;
          console.log("representationList INICIO", responseData);
          self.companyList = [];
          self.representationList = responseData.representation_list;
          self.productDash = responseData.representation_dash;
          for(let representation of responseData.representation_list){
            if(self.companyList.some(company => company._id === representation.company._id)){
              // console.log("Object found inside the array.");
            } else{
              self.companyList.push(representation.company);
              self.companyList.push(representation.company);self.companyList.push(representation.company);self.companyList.push(representation.company);
              // console.log("Object not found.");
            }
          }
          // let temp = self.companyList;
          // for(let c of temp){
          //   self.companyList.push(c);
          // }
          self.representationList = [];
          console.log("representationList FINAL", self.representationList);
          
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">

.filter-opportunities button{
  margin-top: 45px;
}

.catalog-salesman {
  padding-top: 30px;
  padding-bottom: 50px;

  .box-amount-products {
    border-radius: 10px;
    color: var(--main-title-color);
    font-weight: bold;
    text-align: center;
    padding: 20px;

    .amount-opportunity {
      font-size: 45px;
    }

    .opportunity-status {
      font-size: 17px;
    }
    .hr-registered {
      background-color: #053360;
      height: 3px;
      width: 60%;
      margin-top: 12px;
    }

    .hr-waiting {
      background-color: #eeb013;
      height: 3px;
      width: 60%;
      margin-top: 12px;
    }

    .hr-aproved {
      background-color: #28be50;
      height: 3px;
      width: 60%;
      margin-top: 12px;
    }

    .hr-denied {
      background-color: #ee1313;
      height: 3px;
      width: 60%;
      margin-top: 12px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .filter-opportunities button{
      margin-top: 0px;
    }

  .filter-opportunities {
    height: auto !important;
    padding-bottom: 40px;
  }

  .catalog-salesman {
    padding-bottom: 30px;
    padding-top: 0px;

    .row{
      margin: 0px;

      .col-6{
        padding: 1%;
      }
    }

    .box-amount-products {
      margin-bottom: 10px;
      padding: 10px;
    }
  }
}
</style>
