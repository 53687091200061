<template>
	<main-layout>
		<div class="container-fluid salesman-details-view light-grey-bg">
            <div class="row ">
                <div class="col-md-2">
                    <div v-if="salesman.image" class="user-frame" >
                        <img :src="getImageThumbUrl(salesman.image)"  class="product-image-1" alt="">
                    </div>
                    <div v-else class="user-frame" >
                        <img class="user-img" src="/static/img/logos/logo-vcvende.jpg"  alt="">
                    </div>
                </div>
                <div class="col-md-8">
                    <h2>{{salesman.name}} {{salesman.lastname}}</h2>
                    <h3 v-if="salesman.resume.title != ''" class="salesman-title">
                        {{salesman.resume.title}}
                    </h3>
                    <div class="company-city" v-if=" salesman.address">
                        {{salesman.address[0].city}} | {{salesman.address[0].state}}
                    </div>
                    <p>
                        <b>Nivel:</b> {{ positionType[salesman.resume.position] }}
                    </p>
                    <p style="margin-top: 0px">
                        <b>Idade:</b> {{ salesman.birthdate | calculateAge }} anos ({{salesman.birthdate}}) <br/>

                    </p>
                </div>
                <div class="col-md-2">
                    <!-- <button class="blue full" @click="sendWhatsApp()" v-if="salesman.phone.length > 0" >Enviar mensagem</button> <br><br>
                    <button class="full" @click="sendEmail()" v-if="salesman.email.length > 0">Enviar email</button> <br><br>
                    <button class="clean full">Acessar catálogo</button> <br> -->
                </div>
            </div>

            <hr>

            <div class="row">
                <div class="offset-md-2 col-md-10">
                    <h2>Apresentação</h2>
                    <p  style="white-space: break-spaces;" > {{salesman.resume.summary}}</p>
                </div>
            </div>

            <hr>

            <div class="row">
                <div class="offset-md-2 col-md-10">
                    <h2>Experiência Profissional</h2>
                </div>
                <br><br>
            </div>

            <div v-for="experience in salesman.resume.experience"  :key="experience._id" class="row experience" >
                <div class="col-md-2">
                    <h4 class="date">
                        {{experience.start_date}} - {{experience.end_date}} <br/>
                        Período: {{ employmentType[ experience.employment_type ] }}
                    </h4>
                </div>
                <div class="col-md-10">
                    <div  >
                        <h3>{{experience.title}} - {{positionType[experience.position]}}</h3>
                        <h4 class="company">
                            {{experience.company}}
                        </h4>
                        
                        
                        <p class="summary" v-html="experience.description"></p>

                    </div>
                </div>
            </div>

            <hr>

            <div class="row">
                <div class="offset-md-2 col-md-10">
                    <h2>Experiência Acadêmica</h2>
                </div>
                <br><br>
            </div>

            <div v-for="education in salesman.resume.education"  :key="education._id" class="row experience" >
                <div class="col-md-2">
                    <h4 class="date">
                        {{education.start_date}} - {{education.end_date}} <br/>
                        
                    </h4>
                </div>
                <div class="col-md-10">
                    <div  >
                        <h3>{{education.field_of_study}} - {{education.degree}}</h3>
                        <h4 class="company">
                            {{education.scholl}}
                        </h4>
                        
                        
                        <p class="summary" v-html="education.description"></p>

                    </div>
                </div>
            </div>

            <hr>

            <div class="row">
                <div class="offset-md-2 col-md-10">
                    <h2>Habilidades</h2>
                </div>
                <br><br>
            </div>

            <div class="row" >
                <div class="col-md-2">
                    <h4 class="date">
                        Segmentos <br/>
                        
                    </h4>
                </div>
                <div class="col-md-10">
                    <div v-for="skill in filterBySkillType('INDUSTRY_GENERAL')"  :key="skill.skill._id" class="row" >
                        <div class="col-md-6">
                            <h3>{{skill.skill.name}}</h3>
                        </div>

                        <div class="col-md-6">
                            {{proficiencyLevelSegment[skill.proficiency]}}
                        </div>
                        <br><br>
                    </div>

                </div>
            </div>

            <br><br>

            <div class="row"  >
                <div class="col-md-2">
                    <h4 class="date">
                        Idiomas <br/>
                        
                    </h4>
                </div>
                <div class="col-md-10">
                    <div v-for="skill in filterBySkillType('LANGUAGE')"  :key="skill.skill._id" class="row " >
                        <div class="col-md-6">
                            <h3>{{skill.skill.name}}</h3>
                        </div>

                        <div class="col-md-6">
                            {{proficiencyLevelLanguage[skill.proficiency]}}
                        </div>
                        <br><br>
                    </div>
                </div>
            </div>





        </div>

        

        
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/SalesmanProfile.vue";
import userMixin from "../../../mixins/userMixin.js";
import { server } from "../../../js/variables.js";


export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    
  },
  data() {
    return {
        salesman:{
            name: '',
            lastname: '',
            company: '',
            city: '',
            resume:{
                summary: "",
            }
        },
    };
  },
  mounted() {
    var parameters = this.$route.query;
    // this.getSalesman();
  },
  watch: {
    user(newValue, oldValue) {
        if(this.user._id){
            // this.getSalesman();
            this.salesman = this.user;
        }
      
    },
  },
  methods: {
	getSalesman(){
		let self = this;
		if (!localStorage.token) {
			return
		}
		server.config.headers = {
			Authorization: 'Bearer ' + localStorage.token
		}

		this.axios.get(  server.url + '/api/user/' + self.user._id, server.config)
		.then(function (response) {
			let responseData = response.data.data;
			self.salesman = responseData.user;
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
			console.log(error);
		});
    },
    sendWhatsApp(){
        let self = this;
        var id = this.$route.params.id;
		if (!localStorage.token) {
            self.$swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Você precisa estar logado para realizar está ação',
            })
			return
		}
		server.config.headers = {
			Authorization: 'Bearer ' + localStorage.token
		}

		this.axios.get(  server.url + '/api/user/' + this.salesman._id + '/call', server.config)
		.then(function (response) {
			let responseData = response.data.data;
            window.open('https://api.whatsapp.com/send?1=pt_BR&phone=55' + responseData.user.phone , "_blank");
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
			console.log(error);
		});
    },
    sendEmail(){
        let self = this;
        var id = this.$route.params.id;
		if (!localStorage.token) {
			return
		}
		server.config.headers = {
			Authorization: 'Bearer ' + localStorage.token
		}

		this.axios.get(  server.url + '/api/user/' + this.salesman._id + '/email', server.config)
		.then(function (response) {
			let responseData = response.data.data;
            window.open("mailto:" + responseData.user.email , "_blank");
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
			console.log(error);
		});
    },
    filterBySkillType(type) {
        var list = [];
        if(this.salesman.resume.skills){
            for(let skill of this.salesman.resume.skills) {

                if (skill.skill.type == type) {
                    list.push(skill);
                }
            }

            return list;
        }
		
	},
    
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.salesman-details-view{
    // padding: 25px 35px;
    padding-top: 60px;


    .salesman-title{
        color: var(--main-color-secundary);
        // font-size: 16px;
        margin-top: 5px;
    }

    .clean{
        color: var(--main-color-primary);
        text-align: right;
        width: 140px;
        height: 32px;
        margin-top: 6px;
    }

    .company-city{
        color: var(--main-color-darkgrey);
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
    }

    p{
        margin-top: 16px;
    }

    .experience{
        h3{
            font-size: 14pt;
            line-height: 16pt;
            // color: var(--main-color-darkgrey);
            // font-weight: 100;
        }   

        h4.company{
            font-size: 13pt;
            line-height: 15pt;
            color: var(--main-color-primary);
        }

        h4.date{
            font-size: 10pt;
            line-height: 15pt;
            color: var(--main-color-darkgrey);
            font-weight: 100;
        }

        p{
            margin-top: 0px;
            white-space: break-spaces;
            margin-bottom: 30px;
        }

        .summary{
            p{ 
                margin-bottom: 0px;
            }
        }

        
    }
}



</style>
