<template>
  <div>
    <div class="banner light-grey-bg" v-if="bannerType == 10">
      <!-- new home -->
      <img src="/static/img/Banner.jpg" alt="" />
      <div class="banner-content-home hide-mobile">
        <h2 class="yellow">Portal exclusivo para recrutar e treinar seu time de vendas</h2>
        <h2 class="blue">Contrate profissionais de vendas, vendedores e representantes</h2>
        <!-- <h2 class="yellow">Portal de emprego e recrutamento exclusivo para profissionais de vendas, empresas,
          representantes e vendedores. </h2>
        <h2 class="blue">Empresas anunciam produtos para os vendedores e representantes se candidatarem a vender ou
          representar seus produtos e ainda treinam seu time online.</h2> -->
      </div>
      <div class="banner-info-mobile only-mobile">

        <h2 class="yellow">Portal exclusivo para recrutar e treinar seu time de vendas</h2>
        <h2 class="blue">Contrate profissionais de vendas, vendedores e representantes</h2>
        <!-- <h2 class="yellow">Portal de emprego e recrutamento exclusivo para profissionais de vendas, empresas,
          representantes e vendedores. </h2>
        <h2 class="blue">Empresas anunciam produtos para os vendedores e representantes se candidatarem a vender ou
          representar seus produtos e ainda treinam seu time online.</h2> -->
      </div>
      <div class="banner-bottom">
        <div class="items-wrapper">
          <div class="separator hide-mobile"></div>
          <div>
            <h3>Você é um profissional da área de vendas ?</h3>
            <button class="white" style="width: 280px" @click="goTo('/vc/cadastro')">
              Cadastre seu currículo grátis
            </button>
          </div>
          <div class="separator hide-mobile"></div>
          <div class="separator-mobile only-mobile"></div>
          <div>
            <h3>Procurando vendedores para o seu negócio ?</h3>
            <button class="white" style="width: 280px" @click="goTo('/co/cadastro')">
              Cadastre sua empresa grátis
            </button>
          </div>
          <div class="separator hide-mobile"></div>
        </div>
      </div>
    </div>
    <div class="banner light-grey-bg" v-if="bannerType == 0">
      <!-- salesman -->
      <img src="/static/img/Banner.jpg" alt="" />
      <div class="banner-content">
        <h2 class="yellow">Você é profissional da área comercial buscando</h2>
        <h2 class="blue">vagas de emprego, representar produtos e treinar seu time</h2>
        <div class="d-none d-md-block d-lg-block">
          <p style="color: #111">
            Vocevende é ideal para você buscar vagas na área comercial/vendas, representar empresas para vender seus
            <br />produtos, criar seus treinamentos para anunciar ou aplicar exclusivamente em uma empresa.
          </p>
        </div>
        <!-- <button class="grey" @click="gotoCompany()">Empresas</button> -->
        <button class="white" style="width: 280px" data-dismiss="modal" aria-label="Close" data-toggle="modal"
          data-target="#salesmanRegisterModal">
          Cadastre seu currículo (grátis)
        </button>
      </div>

      <!-- <div class="be-a-teacher">
        <h2 class="yellow">Seja um treinador</h2>
        <h2 class="blue">Aulas 100% online, sem sair de casa</h2>
      </div> -->

    </div>
    <div class="banner light-grey-bg" v-if="bannerType == 1">
      <!-- company -->
      <img src="/static/img/banner_about.jpg" alt="" />
      <div class="banner-content">
        <h2 class="yellow">Precisando desenvolver sua área comercial,</h2>
        <h2 class="blue">contratar e treinar sua equipe de vendas?</h2>
        <div class="d-none d-md-block d-lg-block">
          <p style="color: white; margin-top: 10px">
            Tenha vendedores e representantes vendendo os produtos de <br />
            sua empresa e sendo treinados on line por você!
          </p>
        </div>
        <!-- <button class="grey" @click="gotoCompany()">Empresas</button> -->
        <!-- <button class="white" @click="gotoProfessionals()">Cadastre-se agora</button> -->

        <button class="white" style="margin-top: 15px; width: 205px" data-dismiss="modal" aria-label="Close"
          data-toggle="modal" data-target="#companyRegisterModal">
          Cadastre sua empresa
        </button>
      </div>
    </div>
    <div class="banner light-grey-bg" v-if="bannerType == 2">
      <!-- afiliado home -->
      <img src="/static/img/Banner.jpg" alt="" />
      <div class="banner-content">
        <h2 class="yellow">Você é profissional e procura uma renda extra?</h2>
        <h2 class="blue">ou tem um produto e gostaria de vender?</h2>
        <!-- <div class="d-none d-md-block d-lg-block">
                    <p>Indique amigos e parceiros ao Vocevende e ganhe<br>
                        uma comissão sobre as compras deles
                    </p>
                </div> -->
        <!-- <button class="grey" @click="gotoCompany()">Empresas</button> -->
        <button class="white" @click="gotoAfiliate()">
          Indique a um amigo
        </button>
      </div>
    </div>
    <div class="banner light-grey-bg banner-small" v-if="bannerType == 3">
      <!-- salesman home -->
      <img src="/static/img/Banner.jpg" alt="" />
      <div class="banner-content">
        <h2 class="yellow">Está gostando da plataforma e conhece</h2>
        <h2 class="blue">outras pessoas que também gostariam?</h2>
        <div class="d-none d-md-block d-lg-block">
          <p>
            Indique amigos e parceiros ao Vocevende e ganhe<br />
            uma comissão sobre as compras deles
          </p>
        </div>
        <!-- <button class="grey" @click="gotoCompany()">Empresas</button> -->
        <button class="white" @click="gotoProfessionals()">
          Indique a um amigo
        </button>
      </div>
    </div>
    <div class="banner light-grey-bg" v-if="bannerType == 4">
      <!-- teacher home -->
      <img src="/static/img/banner_teacher@2x.png" alt="" />
      <div class="banner-content-teacher">
        <h2 class="yellow">Seja um treinador</h2>
        <h2 class="blue">aulas 100% online, sem sair de casa</h2>
        <button class="white" @click="gotoProfessionals()">Inscreva-se</button>
      </div>
    </div>
  </div>
</template>

<script>
import { server, local } from "../js/variables.js";

export default {
  props: ["bannerType"],
  components: {},
  // props: ['items'],
  data() {
    return {
      page: -1,
      company: {
        name: "",
        email: "",
        password: "",
      },
    };
  },
  validations: {
    company: {
      name: "",
      email: "",
      password: "",
    },
  },
  mounted() {
    this.page = 0;
  },
  methods: {
    onPageChange(page) {
      this.page = page;
    },
    gotoProfessionals() {
      // window.open("./vc/cadastro", "_self");

      const url = "/vc/cadastro";
      this.$router.push(url);
    },
    gotoCompany() {
      // window.open("./co/cadastro", "_self");
      const url = "/co/cadastro";
      this.$router.push(url);
    },
    gotoAfiliate() {
      console.log("aqui empresa", local);
      if (localStorage.token != null) {
        if (local.company) {
          const url = "/vc/cadastro";
          this.$router.push(url);
        } else {
          const url = "/vc/afiliados";
          this.$router.push(url);
        }
      } else {
        const url = "/vc/cadastro";
        this.$router.push(url);
      }
    },
  },
};
</script>

<style lang="scss">
.banner.banner-small {
  height: 450px;

  .banner-content {
    top: 25%;
  }
}

.banner {
  // height: 608px;
  margin-top: -15px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;

  img {
    height: auto;
    width: 100%;
  }

  .banner-content,
  .banner-content-teacher,
  .banner-content-home {
    position: absolute;
    z-index: 100;
    top: 40%;
    left: 10%;

    h2 {
      color: var(--main-color-primary);
      margin-bottom: 4px;
      text-align: center;
    }

    .yellow {
      background-color: var(--main-color-secundary);
      padding: 10px;
      max-width: 65%;
    }

    .blue {
      background-color: var(--main-color-primary);
      padding: 10px;
      color: white;
      line-height: 35px;
      max-width: 75%;
    }

    p {
      margin-top: 30px;
      color: var(--main-color-primary);
      letter-spacing: 0.15px;
      line-height: 15pt;
    }

    button {
      margin-top: 30px;
      // margin-right: 15px;
      width: 180px;
    }


  }

  .banner-content-home {
    top: 32%;

    h2 {
      font-size: 30px;
      margin-bottom: 12px;
      padding: 12px 30px !important;
    }


    .yellow {
      background-color: var(--main-color-secundary);
      line-height: inherit;
      max-width: 75%;
    }

    .blue {
      background-color: var(--main-color-primary);
      color: white;
      line-height: inherit;
    }

  }

  .banner-bottom {
    position: absolute;
    bottom: 0;
    background-color: #053360cf;
    width: 100%;
    height: 23%;
    display: flex;
    justify-content: center;
    align-items: center;

    .items-wrapper {
      display: flex;
      height: 110px;
      align-items: center;
      justify-content: center;
      gap: 5px;
      width: 80%;

      >div {
        width: 100%;
        min-width: 430px;
        text-align: center;


        h3 {
          color: white;
          margin-bottom: 15px;
          font-size: 25px;
        }
      }

      .separator {
        background-color: gray;
        width: 2px;
        min-width: 2px;
        height: 92%;
        padding: 0px;
      }

      .separator-mobile {
        background-color: gray;
        width: 100%;
        min-width: 2px;
        height: 2px;
        padding: 0px;
      }

    }
  }

  .be-a-teacher {
    width: 285px;
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: center;

    .yellow {
      background-color: var(--main-color-secundary);
      padding: 10px;
      font-size: 15px;
    }

    .blue {
      background-color: var(--main-color-primary);
      padding: 10px;
      color: white;
      line-height: 30px;
      margin-top: 2px;
      font-size: 15px;
    }
  }
}

.banner-company {
  height: 422px;
  margin-top: -15px;
  margin-bottom: 20px;
  position: relative;

  img {
    height: 100%;
    width: 100%;
  }

  .banner-content {
    position: absolute;
    z-index: 100;
    bottom: 0%;
    left: 10%;
    // background-color: white;
    width: 317px;
    height: 362px;

    h2 {
      color: var(--main-color-primary);
      background-color: var(--main-color-lightgrey);
      text-align: center;
      width: 290px;
      line-height: 63px;
      position: absolute;
      left: 50%;
      margin-left: -145px;
      top: -12px;
    }

    p {
      margin-top: 30px;
      color: var(--main-title-color);
      letter-spacing: 0.15px;
      line-height: 15pt;
      font-weight: bold;
      text-align: center;
    }

    button {
      margin-top: 10px;
      width: 180px;
      right: 26px;
      position: absolute;
    }

    .register-company-form {
      margin-top: 51px;
      padding: 15px;

      input {
        border: none !important;
        margin-bottom: 0px;
        outline: none;
      }

      hr {
        margin-top: 2px;
        width: 242px;
        margin-bottom: 12px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .banner {
    .banner-content-home {
      top: 32%;

      h2 {
        font-size: 21px;
        margin-bottom: 12px;
        padding: 10px !important;
      }

      .yellow {
        background-color: var(--main-color-secundary);
        line-height: 22px;
      }

      .blue {
        background-color: var(--main-color-primary);
        color: white;
      }

    }

    .banner-bottom {
      height: 130px;

      .items-wrapper {
        >div {
          // padding: 0px 70px;

          h3 {
            font-size: 16px;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  .banner {
    height: auto;
    margin-top: 13px;

    .banner-bottom {
      // display: none;
      position: initial;
      height: auto;
      padding: 30px 0px;

      .items-wrapper {
        flex-direction: column;
        height: auto;
        gap: 20px;
      }
    }

    .banner-info-mobile {
      padding: 25px 15px;

      h2 {
        font-size: 14px;
      }

      .yellow {
        background-color: var(--main-color-secundary);
        padding: 10px;
        width: auto;
      }

      .blue {
        background-color: var(--main-color-primary);
        padding: 10px;
        color: white;
        line-height: 20px;
        width: auto;
        margin-top: 10px;
      }
    }

    .banner-content,
    .banner-content-teacher {
      top: 8%;
      left: 3%;

      h2 {
        font-size: 12px;
      }

      .yellow {
        background-color: var(--main-color-secundary);
        padding: 10px;
        width: 272px;
      }

      .blue {
        background-color: var(--main-color-primary);
        padding: 10px;
        color: white;
        line-height: 20px;
        width: 275px;
      }

      p {
        margin-top: 30px;
        color: white;
        letter-spacing: 0.15px;
        line-height: 15pt;
      }

      button {
        margin-top: 0px;
        width: auto;
        height: 25px;
      }
    }

    .banner-content-teacher {
      top: 4%;

      .yellow {
        background-color: var(--main-color-secundary);
        padding: 10px;
        width: 160px;
      }

      .blue {
        background-color: var(--main-color-primary);
        padding: 10px;
        color: white;
        line-height: 20px;
        width: 225px;
      }
    }
  }

  .banner-company {
    height: auto;
    margin-top: 15px;
  }
}
</style>