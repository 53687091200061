<template>
    <div id="menu_sidebar" class="sidebar">
        <span class="botao-link close_sidebar">
            <span class="trans-slow"></span>
            <span class="trans-slow"></span>
            <span class="trans-slow"></span>
        </span>
        <div class="inside">
            <h3>Menu</h3>
            <ul>
                <li>
                    <a href="/">Home</a>
                </li>
                <li>
                    <a href="/product-list?category=home">Casa</a>
                </li>
                <li>
                    <a href="/product-list?category=pool">Piscina</a>
                </li>
                <li>
                    <a href="/product-list?category=car">Automóveis</a>
                </li>
                <li>
                    <a href="/product-list?category=home">Matérias Primas</a>
                </li>
            </ul>
            <h3>Institucional</h3>
            <ul class="sub">
                <li>
                  <a href>Política</a>
                </li>
                <li>
                  <a href="./profile-info">Minha conta</a>
                </li>
                <li>
                  <a href>Retirada de produtos</a>
                </li>
                <li>
                  <a href="./profile-exchange">Trocas e devoluções</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
  

  export default {
    components: {
        
    }
  }
</script>

<style src="../css/sidenav.css"></style>