<template>
  <div id="product-finder" class="container">
    <div style="position: relative;" v-if="companyList != null && showCompanyList && companyList.length > 3">
      <h2 style="text-align: center; margin-bottom: 15px;">Empresas para você representar</h2>
      <div class="row company-list"  >
        <div class="col-md-2 company-list-item" v-for="company in companyList" @click="companySelected(company)" :key="company._id">
          <div class="box-item" v-bind:style="filter.companyId == company._id ? 'border: 1px solid var(--main-title-color);' : 'border: 1px solid var(--main-color-mediumgrey);' ">
            <img :src="getImageThumbUrl(company.image)" v-if="company.image" class="" alt="">
          </div>
          <h2 style="font-size: 17px;">{{company.name_fancy}}</h2>
        </div>
        <div>
          
        </div>
      </div>
      <button id="slideLeft" class="clean left-arrow-btn" @click="scrollLeft()">
        <i class="fas fa-chevron-left d-none d-md-block d-lg-block"></i>
      </button>
      <button id="slideRight" class="clean right-arrow-btn" @click="scrollRight()">
        <i class="fas fa-chevron-right d-none d-md-block d-lg-block"></i>
      </button>
    </div>
    <div class="row top-space">
      <aside
        id="filter-sidebar"
        class="col-md-3"
        v-bind:class="{ open: isFilterOpen() }"
      >
        <div class="rounded light-grey-bg filter-sidebar">
          <div class="sidebar-title rounded main-color-bg">
            <img class="icon-left"  src="/static/svg/icon_search.svg" alt="" />
            <h2 class=" ">Refine sua busca</h2>
            <!-- <input type="text" class="searchText" placeholder="Refine sua busca" v-model="filter.searchText" v-on:keyup.enter="findProducts" > -->
          </div>
          <input type="text" class="searchText" placeholder="O que você procura?" v-model="filter.searchText" v-on:keyup.enter="findProducts" >
          <!-- <hr> -->
          <div class="filter-group" v-if="this.$route.fullPath != '/catalogo/' + this.$route.params.id">
            <img
              class="icon-left"
              src="/static/svg/icon_comission.svg"
              alt=""
            />
            <h3>Taxa comissão (%)</h3>
            <table id="commision-range">
              <tr>
                <td>
                  <label for="commission_from">De :</label>
                  <input
                    type="number"
                    v-model="filter.commission_from"
                    placeholder="0.5"
                    @change="check()"
                  />
                </td>
                <td>
                  <label for="commission_to">Até:</label>
                  <input
                    type="number"
                    v-model="filter.commission_to"
                    placeholder="10"
                    @change="check()"
                  />
                </td>
              </tr>
            </table>
          </div>
          <hr v-if="this.$route.fullPath != '/catalogo/' + this.$route.params.id"/>
          <div class="filter-group">
            <img
              class="icon-left"
              src="/static/svg/icon_comission.svg"
              alt=""
            />
            <h3>Valor do produto (R$)</h3>
            <table id="commision-range">
              <tr>
                <td>
                  <label for="price_from">De :</label>
                  <input
                    type="number"
                    v-model="filter.price_from"
                    placeholder="100"
                    @change="check()"
                  />
                </td>
                <td>
                  <label for="price_to">Até:</label>
                  <input
                    type="number"
                    v-model="filter.price_to"
                    placeholder="1000"
                    @change="check()"
                  />
                </td>
              </tr>
            </table>
          </div>
          <hr />
          <div class="filter-group">
            <img class="icon-left" src="/static/svg/icon_category.svg" alt="" />
            <h3>Segmento</h3>
            <ul class="collapse show segment-vertical-list" :id="'collapse-segment'">
              <li v-for="segment in segmentList" :key="segment._id">
                <input
                  type="radio"
                  class="checkbox-blue"
                  name="filter_segment"
                  :value="segment._id"
                  id="segment._id"
                  v-model="filter.segmentlist"
                  @change="check($event)"
                />
                <label>
                  {{ segment.name }}
                </label>
              </li>
            </ul>
          </div>
          <hr />
          <div class="filter-group">
            <img class="icon-left" src="/static/svg/icon_region.svg" alt="" />
            <h3>Região</h3>

            <!-- <ul class="collapse show" :id="'collapse-place'">
              <li v-for="state in cityList" :key="state">
                <input
                  type="radio"
                  class="checkbox-blue"
                  name="filter_place"
                  :value="state"
                  id="state"
                  v-model="filter.placelist"
                  @change="check($event)"
                />
                <label>
                  {{ state }}
                </label>
              </li>
            </ul> -->
            <br>
            <label>Estado</label>
            <v-select
              label="sigla" 
              :options="stateList"
              v-on:input="check('state')"
							:reduce="state => state.sigla" 
              v-model="filter.state"
              :placeholder="'(Selecione)'"
              autocomplete="nope"
            >
            </v-select>

            <label>Cidade</label>
            <v-select
              label="nome" 
              :options="cityList"
              v-on:input="check('city')"
              :reduce="city => city.nome" 
              v-model="filter.city"
              :placeholder="cityPlaceholder"
              :disabled="cityPlaceholder == 'Selecione estado' || cityPlaceholder == 'Carregando...'"
              autocomplete="nope"
            >
            </v-select>
          </div>
          <!-- <hr />
          <div class="filter-group">
            <img class="icon-left" src="/static/svg/icon_category.svg" alt="" />
            <h3>Categoria</h3>
          </div> -->
          <button class="blue center" style="margin-top: 20px;" @click="cleanFilter()">Limpar filtro</button>
        </div>
        <br />

        <div class="d-md-none d-lg-none d-xl-none">
          <button class="big center" @click="openFilter(false)">
            Confirmar
          </button>
        </div>
      </aside>

      <div class="col-md-9" id="product-list-scroll">
        <div class="">
          <h2 style="margin-left: 23px" v-if="titleText == undefined || titleText == ''">  Produtos para você representar </h2>
          <h2 style="margin-left: 23px" v-else>  {{titleText}} </h2>
        </div>
        <!-- <div class="d-block d-md-none d-lg-none">
          <h2 style="" v-if="titleText == undefined || titleText == ''">  Produtos para você representar</h2>
          <h2 style="" v-else>  {{titleText}} </h2>
        </div> -->
        <hr />

        <div class="d-md-none d-lg-none d-xl-none col" style="margin: 30px 0px 20px;">
          <button class="col blue-border bigcenter" @click="openFilter(true)">
            Filtrar
          </button>
        </div>

        <!-- with groups -->

        <div class="" v-if="groupList.length > 0">
          <div
            class="row product-group"
            v-for="productGroup in groupList"
            :key="productGroup._id"
          >
            <div class="col-md-12">
              <h2 style="display: inline-block">
                {{ productGroup.name }}
              </h2>
              <button
                class="clean"
                style="margin-left: 10px"
                @click="productGroupDetails(productGroup)"
              >
                <i class="fas fa-edit"></i>
              </button>
            </div>
            <div class="product-list">
              <div
                class="col-md-4"
                v-for="product in productList"
                :key="product._id"
              >
                <div v-if="product.groups[0]">
                  <div v-if="product.groups[0]._id == productGroup._id">
                    <product-item :product="product" :type="1"> </product-item>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <!-- without groups -->

        <div class="row product-list" v-if="groupList.length == 0 && showAll != 1">
          <div
            class="col-6 col-md-4"
            v-for="product in productList"
            :key="product._id"
          >
            <product-item :product="product" :type="0">
            </product-item>
          </div>
        </div>

        <div class="row product-list" v-if="groupList.length == 0 && showAll == 1">
          <div
            class="col-6 col-md-4"
            v-for="product in productList"
            :key="product._id"
          >
            <product-item :product="product" :type="0">
            </product-item>
          </div>
        </div>

        <div style="margin-top: 30px;" v-if="canLoadMore">
            <!-- <center> <small> {{maxItems}} de {{productList.length}} </small></center> -->
            <button class="blue-border big" @click="loadMore()" style="width: 100%; margin: 20px 0px;">
                Carregar mais 
            </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { server, local } from "../js/variables.js";
import userMixin from "../mixins/userMixin.js";
// import CompanyList from "../components/CompanyList.vue";
import ProductItem from "./ProductItem.vue";

export default {
  mixins: [userMixin],
  props: ["salesman", "company", "showAll", "showCompanyList", 'titleText', 'especificCompanyProds'],
  components: {
    ProductItem,
    // CompanyList,
  },
  data() {
    return {
      endpoint: "/api/product/",
      productList: [],
      groupList: [],
      productListFiltered: [],
      filter: {
        searchText: null,
        price_from: null,
        price_to: null,
        commission_from: null,
        commission_to: null,
        companyId: null,
        list: [],
        segmentlist: [],
        placelist: [],
        grouplist: [],
        state: null,
        city: null,
      },
      scrollToProductList: false,
      companyList: [],
      maxItems: 9,
      pageItems: 9,
      page: 0,
      mobileShowFilter: false,
      canLoadMore: true
    };
  },
  mounted() {
    this.findPlaces();
    this.findSegments();
    // console.log('AQUI/'+ this.$route.params.id, this.$route)
    // console.log('titletext', this.titleText);
  },
  created() {
    this.endpoint = "/api/product/"; // all products

    // if (this.salesman) {
    //   this.endpoint = "/api/sales-representative/"; // my requests
    // }
    
    if (this.company) {
      this.endpoint = "/api/company/" + this.company + "/product/"; // company products
      // this.findGroups();
    }

    // console.log("Created", this.endpoint);
    // console.log("showAll", this.showAll);
    // console.log("salesman", this.salesman);
    // console.log("company", this.company);

    // console.log(this.$el);

    this.findProducts();
  },
  updated() {
    var newEndPoint = "/api/product/"; // all products
    if (this.company) {
      newEndPoint = "/api/company/" + this.company + "/product/"; // company products
      // this.findGroups();
    }

    if (newEndPoint !== this.endpoint) {
      // console.log("newEndPoint", newEndPoint);
      // console.log("showAll", this.showAll);
      // console.log("salesman", this.salesman);
      // console.log("company", this.company);
    
      this.endpoint = newEndPoint;
      this.findProducts();
    }
  },
  methods: {
    loadMore(){
      this.maxItems = this.maxItems + this.pageItems;
      this.page = this.page + 1;
      this.findProducts();
    },
    findProducts() {
      let self = this;
      // console.log('especificCompanyProds', self.especificCompanyProds)
      if(self.especificCompanyProds){
        self.filter.companyId = self.especificCompanyProds
      }
      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        limit: this.pageItems,
        page: this.page,
        filter: this.filter.searchText,
        price_from: this.filter.price_from,
        price_to: this.filter.price_to,
        commission_from: this.filter.commission_from,
        commission_to: this.filter.commission_to,
        company: this.filter.companyId,
        state: this.filter.state,
        city: this.filter.city,
      };

      if (!this.showAll) {
        header["params"].approved = 1;
        header["params"].visible = 1;
      }

      if(this.salesman){
        header["params"].salesman = this.salesman;
      }

      if (this.filter.placelist.length > 0) {
        header["params"].city = this.filter.placelist;
      }

      if (this.filter.segmentlist.length > 0) {
        header["params"].segment = this.filter.segmentlist;
      }

      // console.log(header["params"]);

      this.axios
        .get(server.url + this.endpoint, header)
        .then(function (response) {
          let responseData = response.data.data;
          self.companyList = [];

          if (self.page == 0) {
            self.productList = [];
          }
          
          // self.productList = responseData.product_list;
          if (responseData.representation_list) {
            for (let representation of responseData.representation_list) {
              if( representation.product ) {
                self.productList.push(representation.product);
              } else{
                //self.productList.push(representation.product);
              }
              if(self.companyList.some(company => company._id === representation.company._id)){
                // console.log("Object found inside the array.");
              } else{
                self.companyList.push(representation.company);
                // console.log("Object not found.");
              }
            }
          } else {
            self.productList = self.productList.concat( responseData.product_list );
            for(let c of responseData.company_list){
              if(self.companyList.some(company => company._id === c._id)){
                // console.log("Object found inside the array.");
              } else{
                self.companyList.push(c);
              }
            }
          }

          // console.log(self.productList.length, self.pageItems);
          if (responseData.product_list.length != self.pageItems) {
            self.canLoadMore = false;
          } else {
            self.canLoadMore = true;
          }
          
          if(self.scrollToProductList){
            self.$scrollTo('#product-list-scroll');
            self.scrollToProductList = false;
          }
          
          
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    findGroups(){
      let self = this;
      
      let header = JSON.parse(JSON.stringify(server.config));

      this.axios.get(  server.url + '/api/company/' + self.company + '/product-group/', header)
        .then(function (response) {
          let responseData = response.data.data;
          self.groupList = responseData.product_group_list;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    companySelected(company){
      if(this.filter.companyId == company._id){
        this.filter.companyId = null;
      }else{
        this.filter.companyId = company._id;
      }
      this.findProducts();
    },
    isFilterOpen() {
      return this.mobileShowFilter;
    },
    openFilter(status) {
      this.mobileShowFilter = status;
      this.check();
    },
    groupClick(productGroup) {
      this.$emit("groupClick", productGroup);
    },
    check(type) {
      if(!this.filter.state){
        this.cityList = [];
        this.filter.city = null;
        this.cityPlaceholder = 'Selecione estado';
      }else if(this.filter.state && type == 'state'){
        this.cityList = [];
        this.filter.city = null;
        // this.cityPlaceholder = 'Selecione estado';
        this.findCities(this.filter.state);
      }else if(this.filter.state && type == 'city'){
        // this.findCities(this.filter.state);
      }
      this.scrollToProductList = true;
      this.findProducts();
      // this.filterMyList()
      // console.log(this.filter.list);
    },
    cleanFilter(){
      let self = this;
      let filterClean = {
        searchText: null,
        price_from: null,
        price_to: null,
        commission_from: null,
        commission_to: null,
        companyId: null,
        list: [],
        segmentlist: [],
        placelist: [],
        grouplist: [],
        state: null,
        city: null,
      }
      self.filter = filterClean;
      this.findProducts();
    },
    scrollLeft() {
      let content = document.querySelector(".company-list");
      content.scrollLeft -= 800;
    },
    scrollRight() {
      let content = document.querySelector(".company-list");
      content.scrollLeft += 800;
    },
  },
};
</script>

<style lang="scss" scoped>
#collapse-place,
#collapse-segment li {
  margin-top: 5px;
  position: relative;
}

#product-finder{
  position: relative;
  // max-width: 960px;
}

table#commision-range {
  border-spacing: 10px;
  border-collapse: separate;

  tr {
    td {
      margin-left: 5px;

      label {
        color: var(--main-color-mediumgrey);
      }

      input {
        padding: 12px 5px !important;
        text-align: center;
      }
    }
  }
}

.searchText{
  letter-spacing: 0.30px;
  margin-bottom: 20px;

  &:focus{
    outline: none;
  }
}

.left-arrow-btn{
  position: absolute;
  top: 95px;
  left: 0px;
  font-size: 40px;
  cursor: pointer;
  outline: none;
}

.right-arrow-btn{
  position: absolute;
  top: 95px;
  right: 0px;
  font-size: 40px;
  cursor: pointer;
  outline: none;
}

.company-list{
  display: block;
  margin: 0px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  min-height: 60px;    
  margin-right: 45px;
  margin-left: 45px;
  

    /* width */
  &::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--main-color-mediumgrey);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--main-color-darkgrey);
  }

//   background-color: var(--main-color-lightgrey);
//   padding-top: 20px;
}


.company-list-item{
  width: auto;
  display: inline-flex;
  float: none;
  white-space: initial;
  // padding-bottom: 30px !important;
  vertical-align: top;
  display: inline-block;
  height: 215px;
  width: 200px;
  white-space: initial;

  .box-item{
    height: 155px;
    width: 155px;
  }

  img{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 10px;
  }

  h2{
    // display: block;
    // bottom: 30px;
    // position: absolute;
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.segment-vertical-list{
  display: block;
  margin: 0px;
  margin-top: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  // white-space: nowrap;
  max-height: 350px;

    /* width */
  &::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--main-color-mediumgrey);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--main-color-darkgrey);
  }
}

@media only screen and (max-width: 1300px){
  #product-finder{
    max-width: 960px;

    .company-list-item{
      height: 190px;
      width: 165px;

      .box-item{
        height: 135px;
        width: 135px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .company-list{
    margin-top: 30px;
  }

  .company-list-item{
    height: 180px;
    width: 155px;

    .box-item{
      height: 125px !important;
      width: 125px !important;
    }
    
    h2{
      font-size: 16px;
    }
  } 
}
</style>