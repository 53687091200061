<template>
  <div class="modal fade company-register-modal" id="companyRegisterModal" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" style="background-color: #5c6377b8">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-top">
          <button class="btn-x" data-dismiss="modal" aria-label="Close" style="margin-top: 0px; margin-bottom: 0px">
            X
          </button>
          <img src="/static/svg/icon_logo_small.svg" alt="" class="center" />
          <h1>Cadastro</h1>
          <h3>de empresa</h3>
          <div v-if="showingTermsCompany">
            <terms-and-policy :type="'modal'"></terms-and-policy>
          </div>
          <div v-else>
            <input id="nameCompany" placeholder="Nome da empresa" type="text" v-model="$v.company.name_fancy.$model">
            <input id="document" placeholder="CNPJ" type="text" v-model="$v.company.document.$model"
              v-mask="'##.###.###/####-##'">
            <span v-if="$v.company.document.$error">Verifique o formato do CNPJ</span>
            <hr>
            <input id="name" placeholder="Nome do responsável pela empresa" type="text" v-model="$v.user.name.$model">
            <span v-if="$v.user.name.$error">Campo não pode estar vazio</span>

            <input id="lastname" placeholder="Sobrenome" type="text" v-model="$v.user.lastname.$model">
            <span v-if="$v.user.lastname.$error">Campo não pode estar vazio</span>

            <input id="lastname" placeholder="Site da empresa" type="text" v-model="$v.company.site.$model">
							<span v-if="$v.company.site.$error">Campo não pode estar vazio</span>

            <input id="phone" type="text" placeholder="Telefone" v-model="$v.user.phone.$model"
              v-mask="'(##) #####-####'" />

            <input id="emailreg" placeholder="Login e-mail" type="text" v-model="$v.user.email.$model">
            <span v-if="$v.user.email.$email">Verifique o formato do email</span>

            <div class="password">
                <input id="password" :type="passwordInputType" v-model="$v.user.password.$model" placeholder="Digite sua senha">
                <span v-if="$v.user.password.$error">Campo obrigatório</span>
                <button class="clean" @click="showHidePassword()">
                  <i class="far fa-eye" v-if="passwordInputType === 'password'"></i>
                  <i class="far fa-eye-slash" v-if="passwordInputType === 'text'"></i>
                </button>
              </div>


              <div class="password">
                <input id="passwordConfirm" :type="passwordInputType" placeholder="Confirme sua senha"
                  v-model="$v.user.passwordConfirm.$model">
                <span v-if="$v.user.passwordConfirm.$error">As senhas nao coincidem</span> <button class="clean"
                  @click="showHidePassword()">
                  <i class="far fa-eye" v-if="passwordInputType === 'password'"></i>
                  <i class="far fa-eye-slash" v-if="passwordInputType === 'text'"></i>
                </button>
              </div>
          </div>
          <!-- <div style=" padding-top: 35px">
            <div style="width: 65%; display: inline-block">
              <input type="checkbox" name='type' id='terms-company' v-model="checked" style="float: left">
              <label for="terms-company" @click="showTerms()"> Estou de acordo com os termos</label>
            </div>
            
            <div style="width: 65%; display: inline-block">
              <input type="checkbox" name='type' id='accept_phone' value="true" v-model="user.accept_phone">
              <label for="accept_phone"> Quero receber mensagens por WhatsApp</label>
            </div>
            <button class="blue" style="display: inline-block; float: right" @click="createCompany()" :disabled="isSending">Confirmar</button>
          </div> -->
          <div style=" padding-top: 35px">
            <div style="width: 65%; display: inline-block">
              <input type="checkbox" name='type' id='terms-company' v-model="checked" style="float: left">
              <label for="terms-company" @click="showTerms()"> Estou de acordo com os termos</label>
            </div>
            <button class="blue" style="display: inline-block; float: right" @click="createCompany()"
              :disabled="isSending">Confirmar</button>
          </div>
        </div>
        <div class="modal-bottom" style="text-align:center;">
          <button class="register-btn" data-dismiss="modal" aria-label="Close" data-toggle="modal"
            data-target="#loginModal" style="margin-right: 20px">
            Ja tenho um cadastro
          </button>
          <button class="register-btn" style="margin-right: 5px" data-dismiss="modal" aria-label="Close"
            data-toggle="modal" data-target="#salesmanRegisterModal">
            Cadastro conta profissional
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import userMixin from "../mixins/userMixin.js";
import { server, settings, local } from "../js/variables.js";
import { required, email, sameAs } from 'vuelidate/lib/validators'
import TermsAndPolicy from "../components/TermsAndPolicy.vue";
import moment from "moment";

export default {
  // mixins: [userMixin],
  props: ["modal_id"],
  components: {
    TermsAndPolicy,
  },
  data() {
    return {
      user: {
        name: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        passwordConfirm: '',
      },
      passwordInputType: 'password',

      company: {
        name_fancy: '',
        document: '',
        // phone: '',
        site: '',

      },
      afiliate: '',
      checked: false,
      isSending: false,
      showingTermsCompany: false,
    };
  },
  validations: {
    user: {
      name: { required },
      lastname: { required },
      email: { required, email },
      phone: {required},
      password: { required },
      passwordConfirm: '',
    },
    company: {
      name_fancy: { required },
      // phone: {required},
      document: { required },
      site: '',
    },
    afiliate: '',

  },
  mounted() {
    if (localStorage.afiliate) {
      this.afiliate = localStorage.afiliate;
    }
  },
  watch: {},
  methods: {
    createCompany() {
      let self = this;
      if (!this.$v.$invalid && this.checked) {
        let userCopy = JSON.parse(JSON.stringify(self.user));
        let companyCopy = JSON.parse(JSON.stringify(self.company));

        if (userCopy.password.length < 6) {
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Senha deve ter no mínimo 6 caracteres',
            // footer: '<a href>Why do I have this issue?</a>'
          });
          return
        }

        let document = companyCopy.document;
        let documentNoMask = document.substring(0, 2) + document.substring(3, 6) + document.substring(7, 10) + document.substring(11, 15) + document.substring(16, 18);
        companyCopy.document = documentNoMask;
        self.isSending = true;

        this.axios.post(server.url + '/api/company', {
          company: companyCopy,
          user: userCopy,
          afiliate: self.afiliate,

        }, server.config)
          .then(function (response) {
            let token = response.data.data.auth.token;
            let user = response.data.data.user;
            let company = response.data.data.company;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('company', JSON.stringify(company));
            self.isSending = false;
            // console.log("token", token)
            window.open("./co/dados", "_self");
          })
          .catch(function (error) {
            self.output = error;
            self.isSending = false;
            self.showError(error);
          });
      } else {
        this.$v.$touch();
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique os campos obrigatórios',
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    showTerms() {
      this.showingTermsCompany = !this.showingTermsCompany;
    },
    showHidePassword(){
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    }
  },
};
</script>

<style lang="scss">
.company-register-modal {
  max-height: 100vh;
  overflow: scroll;

  .modal-content {
    padding: 0px;
    border-radius: 1.5rem;



    .modal-top {
      padding: 40px 80px;


      h1 {
        text-align: center;
        // font-size: 36px;
        margin-top: 12px !important;
        margin-bottom: 0px !important;
        color: var(--main-text-color);
      }

      h3 {
        text-align: center;
        margin-bottom: 30px;
      }

      input {
        background-color: var(--main-color-lightgrey);
        border: none !important;
        height: 45px;
      }

      input[type="checkbox"] {
        width: 25px;
      }

      .clean {
        font-size: 15px;
        font-weight: normal;
        margin-right: 30px;
        outline: none;
      }
    }

    .password{
        position: relative;
        button{
          position: absolute;
          right: 0px;
          top: 7px;
        }
      }
    .modal-bottom {
      background-color: var(--main-color-lightgrey);
      border-bottom-left-radius: 1.5em;
      border-bottom-right-radius: 1.5em;
      height: 102px;
      padding: 0px 16px;

      button {
        width: 230px;
        color: var(--main-color-primary);
        height: 46px;
        border-radius: 9px;
        margin-top: 25px;
      }
    }

  }
}

@media only screen and (max-width: 600px) {
  .company-register-modal {
    max-height: 100vh;
    overflow: scroll;

    .modal-top {
      padding: 50px 20px !important;

      img {
        position: absolute;
        left: 20px;
        top: 30px;
      }
    }

    .modal-content {
      padding: 0px;
    }

    .modal-bottom {
      button {
        width: 46% !important;
        margin: 5px !important;
        margin-top: 23px !important;
        float: left;
      }
    }
  }
}
</style>