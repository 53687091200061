<template>
  <main-layout>
    <!-- <pre> password {{ $v.postAddress }}</pre> -->
    <!-- <pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container-fluid light-grey-bg no-padding">
      <div class="order-history menu-content-box">
        <h1>Histórico de compras</h1>
        <div style="">
          <v-select
            label="value"
            :options="monthList"
            v-model="filter.month"
            :placeholder="'Mês'"
            :reduce="month => month._id"
            @input="getOrders"
          ></v-select>
          <v-select
            style="margin-left: 10px"
            :options="yearList"
            v-model="filter.year"
            :placeholder="'Ano'"
            @input="getOrders"
          ></v-select>
        </div>
        <hr style="margin-top: 30px; margin-bottom:22px">

        <div class="row" style="margin-bottom: 25px">
          <div class="col-md-12" style="padding-bottom: 8px; padding-top: 8px;" v-for="order in orderList" :key="order._id">
            <div>
              <div class="order-title" :class="order.process_status"
                data-toggle="collapse"
                  :data-target="'#collapseExample'+order._id"
                  aria-expanded="false"
                  aria-controls="collapseExample">
                <div class="indication-box">
                  Compra: {{order.gateway_id}} - Status: {{ orderStatus[order.process_status] }}
                  <!-- Compra: {{order.gateway_id}} - Status: {{ orderStatus[order.process_status] }}<span v-bind:class="orderStatusClass(order.process_status)">{{ orderStatus[order.process_status] }}</span>  -->
                  <button
                    class="clean"
                    type="button clean last" >
                    <i class="fas fa-sort-up"></i>
                    <!-- <img src="/static/img/icons/arrow_down_green.png" /> -->
                  </button>
                </div>
                <div class="indication-value-box">
                  {{order.created_at}}
                </div>
              </div>

              
              <div class="col-md-12 collapse order-items" :id="'collapseExample'+order._id" >
                <div class="row " style="margin-top: 10px" v-for="item in order.items" :key="item._id">
                  <!-- <div class="col-md-2  d-none d-md-block d-lg-block">
                    <img :src="getImageURL(item.course.image_url)">
                  </div> -->
                  <div class="col-md-8" v-if="item.course">
                    <p>
                      <b>{{item.course.name}}</b>
                    </p>
                  </div>
                  <div class="col-md-4 last" v-if="item.course">
                    <p>
                      {{item.amount }} x {{item.course.price | toCurrency}} <br/>
                      <b>{{item.amount*item.course.price | toCurrency}}</b>
                    </p>
                  </div>
                  <hr>
                </div>

                <div class="row" style="padding-top: 10px; padding-bottom: 10px">
                  <div class="offset-md-9 col-md-3 last">
                    Total: <b>{{ order.price | toCurrency}}</b>
                  </div>
                </div>
              </div>
              <div class="col-md-12 error-log" v-if="order.errors_log.length > 0">
                {{order.errors_log[order.errors_log.length - 1].message}} - {{order.errors_log[order.errors_log.length - 1].registered_at | formatDateTime}}
              </div>

            </div>
          </div>
          <div class="col-md-12">
            <h2 style="padding-top: 15px" v-if="orderList.length == 0 || orderList == null">Você não realizou nenhuma compra</h2>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/SalesmanProfile.vue";
import userMixin from "../../../mixins/userMixin.js";
import moment from "moment";
import { server, local } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      orderList: [],
      filter: {
        month: null,
        year: null,
      },
    };
    
  },
  validations: {},
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    this.getOrders();

    this.yearList = []
    var max = new Date().getFullYear()
    var min = max - 100
    var years = []

    for (var i = max; i >= min; i--) {
      this.yearList.push(i)
    }
  },
  watch: {
    user(newValue, oldValue) {
      // console.log(newValue, oldValue);
    },
  },
  methods: {
    getOrders() {
      let self = this;
      if (!localStorage.token) {
          return
      }
      server.config.headers = {
          Authorization: 'Bearer ' + localStorage.token
      }

      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        from: null,
        to: null,
      };

      if(self.filter.month != null && self.filter.year != null){
        
        let date = '01/' + self.filter.month + '/' + self.filter.year;
        let lastDate = new Date(self.filter.year, self.filter.month, 0);
        lastDate = moment(lastDate).add(4, 'hours').format('DD/MM/YYYY');
        let dateFormated = moment(date , 'DD/MM/YYYY').format('YYYY-MM-DD');
        let dateFormatedLast = moment(lastDate , 'DD/MM/YYYY').format('YYYY-MM-DD');

        header["params"] = {
          from: dateFormated,
          to: dateFormatedLast,
        };
      }

      this.axios.get( server.url + '/api/order/', header)
      .then(function (response) {
        let responseData = response.data.data;
        self.orderList = responseData.order_list;
        for(let order of self.orderList){
          let date = self.fixOneDate(order.created_at);
          order.created_at = date;
        }
        
      })
      .catch(function (error) {
        self.output = error;
        self.showError(error);
      });
    },
    getMyStatus(){
      var answer = "NOTFOUND";
      if (this.opportunity) {
        for (let candidate of this.opportunity.candidates) {
          if (candidate.user._id === this.user._id) {
            // console.log("AQUI", candidate.status );     
            return candidate.status;
          }
        }
      }
      // console.log("AQUI", answer );
      return answer;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";



.order-history {
  h1 {
    margin-bottom: 40px;
  }

  .indication-btns {
    width: 137px;
    height: 34px;
    color: var(--main-color-primary);
  }

  .v-select{
    width: 160px;
    height: 34px;
    display: inline-block;
  }

  .vs__dropdown-toggle{
    height: 38px;
  }

  .indication-box {
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    padding: 12px 36px;
    width: 85%;
    display: inline-block;

    font-size: 13px;
    font-weight: bold;
    color: var(--main-title-color);

    button{
      height: 14px;
      width: 14px;
      float: right;
      padding-top: 3px;
    }
  }

  .indication-value-box {
    background-color: var(--main-color-primary);
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    width: 15%;
    padding: 12px;
    display: inline-block;

    font-size: 13px;
    font-weight: bold;
    color: white;
    text-align: center;
  }

  .order-items{
    border: 1px solid #c7c7c7;
    border-top: 0px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-top: -5px;
    padding: 15px 15px;
    padding-top: 20px;
    background-color: var(--main-color-lightgrey);

    hr{
      margin-bottom: 0px !important; 
    }
  }
}



@media only screen and (max-width: 600px) {
  .order-history {
    .indications {
      border-radius: 10px;
      padding: 20px;
      margin: 15px 0px;

      h1{
        text-align: center;
        width: 100%;
        margin-bottom: 30px;
      }

      .v-select{
        width: 130px;
        font-size: 14px;
      }
    }
  }
}
</style>
