<template>
  <main-layout>
    <div class=" container-fluid company-data  light-grey-bg">

      <div class="sub-menu container-fluid h-auto m-0  menu-content-box">
        <div class="container">
          <div class="row " style="">
            <div class="col-md-9 mt-4 col-sm-12">
              <h1a class="category" >Catálogo de produtos para representação</h1a>
            </div>
            <div class="col-md-3 col-sm-12">
              <button v-on:click="goTo('./co/perfil/visualizacao')">
                Visualizar Catálogo
              </button>
            </div>
          </div>
          
              <div id="company-catalog" class="catalog container company-page">
                <div class="filter-search" v-if="productGroupList.length != 0">
                  <div class="row search-row  d-flex justify-content-center">
                  
                      <div class="col-md-10 my-3">
                        <input
                          id="searchText"
                          type="text"
                          class="my-auto"
                          v-model="search.searchText"
                          v-on:keyup.enter="getProduct"
                          placeholder="Buscar por nome"
                        />
                      </div>
                      <div class="col-md-2 my-auto">
                        <button class="my-auto" @click="getProduct()">Buscar</button>
                      </div>

                   
                  </div>
                </div>
                <div class="row add-buttons">
                  <div class="col-md-6">
                    <div
                      class="main-color-bg pointer"
                      style="border-radius: 5px"
                      @click="newProduct()"
                    >
                      <div class="add-button">+</div>
                      <p>Adicionar produto</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div
                      style="border-radius: 5px; background-color: #a5a5a5"
                      class="pointer"
                      @click="newCategory()"
                    >
                      <div class="add-button">+</div>
                      <p>Adicionar categoria</p>
                    </div>
                  </div>
                </div>
                
                <div class="" v-if="productGroupList.length == 0" style="padding-top: 50px">
                  <h2>Você não cadastrou nada até o momento, inicie cadastrando uma categoria e depois um produto.</h2>
                </div>
          
                <div
                  class="row product-group"
                  v-for="productGroup in productGroupList"
                  :key="productGroup._id"
                >
                  <div class="col-md-5  col-sm-12 " style="margin-bottom: 8px">
                    <h2 style="display: inline-block">
                      <img
                        :src="getImageUrl(productGroup.image_icon)"
                        v-if="productGroup.image_icon"
                        class="category-icon"
                        alt=""
                      />
                      {{ productGroup.name }}
                    </h2>
                    <button
                      class="clean w-25 "
                      style="margin-left: 5px"
                      @click="productGroupDetails(productGroup)"
                      :disabled="productGroup.name === 'Sem categoria'"
                    >
                      <i style="font-size: 2em;" class="fas fa-edit  size"></i>
                    </button>
                  </div>
                  <div
                    class="col-md-3"
                    v-for="product in getProductListByGroup(productGroup)"
                    :key="product._id"
                  >
                    <product-item :product="product" :type="0"> </product-item>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import companyMixin from "../../../mixins/companyMixin.js";
import MainLayout from "../../../layouts/CompanyProfile.vue";
import ProductItem from "../../../components/ProductItem.vue";
import { server } from "../../../js/variables.js";

export default {
  mixins: [companyMixin],
  components: {
    MainLayout,
    ProductItem,
  },
  data() {
    return {
      productGroupList: [
        // {_id: 1, name: "Título da vaga 1", position: 'Gerente 1', amount: 5, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. '},
        // {_id: 2, name: "Título da vaga 2", position: 'Gerente 2', amount: 10, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. '},
        // {_id: 3, name: "Título da vaga 3", position: 'Gerente 3 ', amount: 2, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. '},
        // {_id: 4, name: "Título da vaga 4", position: 'Gerente 4', amount: 15, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. '},
      ],
      productList: [],
      search: {
        searchText: null,
      },
    };
  },
  mounted() {
    var parameters = this.$route.query;
    // console.log(this.$route);
  },
  watch: {
    company(newValue, oldValue) {
      // console.log(newValue, oldValue);
      // console.log("watch company", this.company);
      if (this.company._id) {
        this.getProductGroup();
        this.getProduct();
      }
    },
  },
  methods: {
    productGroupDetails(productGroup) {
      // window.open("./co/catalogo/categoria/" + productGroup._id, "_self");
      const url = "/co/catalogo/categoria/" + productGroup._id;
      this.$router.push(url);
    },
    newProduct() {
      // window.open("./co/catalogo/produto/new", "_self");
      if(this.productGroupList.length == 0){
        this.$swal({
          icon: 'info',
          title: 'Atenção',
          text: 'Você ainda não cadastrou nenhuma categoria.',
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Ok'
          }).then((result) => {
          if (result.isConfirmed) {
            // window.open("./co/vagas", "_self"); 
          }
        })

      }else{
        const url = "/co/catalogo/produto/new";
        this.$router.push(url);
      }
    },
    newCategory() {
      // window.open("./co/catalogo/categoria/new", "_self");
      const url = "/co/catalogo/categoria/new";
      this.$router.push(url);
    },
    getProductListByGroup(productGroup) {
      var list = [];
      for (let product of this.productList) {
        if (product.groups[0] && product.groups[0]._id == productGroup._id) {
          list.push(product);
        }
      }
      return list;
    },
    getProductGroup() {
      let self = this;
      if (!localStorage.token) {
        return;
      }

      let header = JSON.parse(JSON.stringify(server.config));

      this.axios
        .get(
          server.url + "/api/company/" + self.company._id + "/product-group/",
          header
        )
        .then(function (response) {
          let responseData = response.data.data;
          self.productGroupList = responseData.product_group_list;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    getProduct() {
      let self = this;
      if (!localStorage.token) {
        return;
      }
      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        filter: this.search.searchText,
      };

      this.axios
        .get(
          server.url + "/api/company/" + self.company._id + "/product/",
          header
        )
        .then(function (response) {
          let responseData = response.data.data;
          self.productList = responseData.product_list;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    filterStateCity() {
      let self = this;
      let state = self.search.state;
      let city = self.search.city;
      let segment = self.search.segment;

      let query = state ? "state=" + state : "";
      query += query && city ? "&" : "";
      query += city ? "city=" + city : "";
      query += query && segment ? "&" : "";
      query += segment ? "segment=" + segment : "";
      query = query ? "?" + query : "";

      this.axios
        .get(server.url + "/api/user" + query, server.config)
        .then(function (response) {
          self.salesmanList = [];
          let responseData = response.data.data.user_list;
          for (let item of responseData) {
            self.salesmanList.push(item);
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.catalog {
  .filter-search {
    margin-bottom: 35px;

    input {
      margin-top: 0px;
    }
  }

  .add-buttons {
    height: 76px;

    .add-button {
      border: 1px solid white;
      border-radius: 8px;
      display: inline-block;
      height: 50px;
      width: 60px;
      color: white;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      line-height: 48px;
      margin-top: 12px;
      margin-left: 15px;
      margin-bottom: 12px;
    }

    .pointer {
      cursor: pointer;
    }

    p {
      display: inline-block;
      color: white;
      font-size: 20px;
      padding-left: 20px;
    }
  }

  .product-group {
    padding-top: 70px;

    .category-icon {
      width: 56px;
      margin-right: 8px;
      margin-bottom: 3px;
    }
  }

  svg {
    width: 70px;
    height: 70px;
  }
  svg:hover {
    fill: red;
  }
}



@media only screen and (max-width: 600px) {
  .category{
    display: flex;
    justify-content: center;
    font-size: 1.8em;
    text-align: center;
    color: #053360;
    font-weight: 700;
  }
  .catalog {
    margin-top: -40px;


    .add-buttons {
      height: 160px !important;
    }

    .product-group {
      padding-top: 30px !important;
    }
  }
}
</style>
