<template>
  <main-layout>
    <!-- <pre> password {{ $v.postAddress }}</pre> -->
    <!-- <pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container-fluid light-grey-bg no-padding">
      <div class="menu-content-box">
        <h1>Meus planos</h1>
        <current-plan-item :mySubscriptionList="mySubscriptionList"></current-plan-item>
      </div>
      <div class="my-plans menu-content-box">
        <h2 class="title-bigger">Alterar plano</h2>
        <h2 v-if="salesmanFreePlan._id || salesmanPlan._id">Profissional</h2>
        <div class="light-grey-bg" v-if="salesmanFreePlan._id || salesmanPlan._id">
          <div class="row" style="padding: 4px 0px; margin-top: 25px">
            <div class="col-md-6 no-padding-left-m" style="padding-right: 2px">
              <!-- salesman free -->
              <plan-item :isSubscribed="2" :freePlans="salesmanFreePlan"></plan-item>
            </div>
            <div class="col-md-6 no-padding-right-m" style="padding-left: 2px">
              <!-- salesman paid -->
              <plan-item :isSubscribed="salesmanIsPaid" :paidPlans="salesmanPlan"
                :subscribedPlan="subscribedSalesmanPlan"></plan-item>
            </div>
          </div>
        </div>
        <h2 style="padding-top: 30px" v-if="afiliateFreePlan._id || afiliatePlan._id">Afiliado</h2>
        <div class="light-grey-bg" v-if="afiliateFreePlan._id || afiliatePlan._id">
          <div class="row" style="padding: 4px 0px; margin-top: 25px">
            <div class="col-md-6 no-padding-left-m" style="padding-right: 2px;">
              <!-- afiliate free -->
              <plan-item :isSubscribed="2" :freePlans="afiliateFreePlan"></plan-item>
            </div>
            <div class="col-md-6 no-padding-right-m" style="padding-left: 2px;">
              <!-- afiliate paid -->
              <plan-item :isSubscribed="afiliateIsPaid" :paidPlans="afiliatePlan"
                :subscribedPlan="subscribedAfiliatePlan"></plan-item>
            </div>
          </div>
        </div>
      </div>
      <subscription-history :subscriptionList="subscriptionList"></subscription-history>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/SalesmanProfile.vue";
// import MainLayout from "../../../layouts/Salesman.vue";
import userMixin from "../../../mixins/userMixin.js";
import PlanItem from "../../../components/PlanItem.vue";
import CurrentPlanItem from "../../../components/CurrentPlanItem.vue";
import SubscriptionHistory from "../../../components/SubscriptionHistory.vue";
import moment from "moment";
import { server, local } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    PlanItem,
    CurrentPlanItem,
    SubscriptionHistory,
  },
  data() {
    return {
      salesmanIsPaid: 0,
      afiliateIsPaid: 0,
      salesmanPlan: {},
      afiliatePlan: {},
      salesmanFreePlan: {},
      afiliateFreePlan: {},
      subscribedSalesmanPlan: {},
      subscribedAfiliatePlan: {},
      mySubscriptionList: [],
      subscriptionList: [],
    };
  },
  validations: {},
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    self.getPlans();
  },
  watch: {
    user(newValue, oldValue) {
      // console.log(newValue, oldValue);
    // console.log("plano", local.subscriptionList)

      if(local.subscriptionList){
        this.mySubscriptionList = local.subscriptionList;
        for(let subscription of local.subscriptionList){
          // console.log("plano", subscription)
          if(subscription.plan.type == 'SALESMAN'){
            if(subscription.plan.payment_option.length > 0){
              this.salesmanIsPaid = 1;
              this.subscribedSalesmanPlan = subscription;
            }else{
              this.salesmanIsPaid = 0;
            }
          }else if(subscription.plan.type == 'AFILIATE'){
            if(subscription.plan.payment_option.length > 0){
              this.afiliateIsPaid = 1;
              this.subscribedAfiliatePlan = subscription;
            }else{
              this.afiliateIsPaid = 0;
            }
          }
        }
      }
      if(this.user._id) {
        this.getSubscriptions();
      }
    },
  },
  methods: {
    getPlans() {
      let self = this;
      // if (!localStorage.token) {
      //     return
      // }
      // server.config.headers = {
      //     Authorization: 'Bearer ' + localStorage.token
      // }
      this.axios
        .get(server.url + "/api/plan?is_enable=true", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.planList = responseData.plan_list;
          for (let plan of self.planList) {
            if (plan.type == "SALESMAN" && plan.payment_option.length > 0) {
              self.salesmanPlan = plan;
            } else if (plan.type == "AFILIATE" && plan.payment_option.length > 0) {
              self.afiliatePlan = plan;
            } else if (plan.type == "SALESMAN" && plan.payment_option.length == 0) {
              self.salesmanFreePlan = plan;
            } else if (plan.type == "AFILIATE" && plan.payment_option.length == 0) {
              self.afiliateFreePlan = plan;
            }
          }
          for (let payment of self.salesmanPlan.payment_option) {
            if (payment.interval == 1) {
              self.salesmanPlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.salesmanPlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.salesmanPlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.salesmanPlan.payment_12 = payment;
            }
          }

          for (let payment of self.afiliatePlan.payment_option) {
            if (payment.interval == 1) {
              self.afiliatePlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.afiliatePlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.afiliatePlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.afiliatePlan.payment_12 = payment;
            }
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    getSubscriptions() {
      let self = this;
      if (!localStorage.token) {
          return
      }
      server.config.headers = {
          Authorization: 'Bearer ' + localStorage.token
      }

      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        user: this.user._id,
      };

      this.axios.get( server.url + '/api/subscription', header)
      .then(function (response) {
        let responseData = response.data.data;
        self.subscriptionList = responseData.subscription_list;
        let array = [];

        for(let subscription of self.subscriptionList){
          if(subscription.plan_payment){
            array.push(subscription);
          }
        }

        self.subscriptionList = array.sort(function(a,b){
          return new Date(b.created_at) - new Date(a.created_at);
        });

        for(let subscription of self.subscriptionList){
          let date = self.fixOneDate(subscription.created_at);
          subscription.created_at = date;
        }
        
      })
      .catch(function (error) {
        self.output = error;
        self.showError(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.banner {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.profile {
  min-height: 100vh;

  .user-img {
    // position: absolute;
    // left: 50%;
    // margin-left: -69px;
    // top: 103px;
  }

  .profile-bg {
    height: 116px;
    width: 130px;
    margin: auto;
    margin-top: 70px;
    margin-bottom: 70px;
  }
}

.my-plans {
  position: relative;


  .title-bigger {
    font-size: 30px;
  }

}


@media only screen and (max-width: 600px) {
  .my-plans {}
}
</style>
