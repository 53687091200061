<template>
  <div
    class="modal fade opportunity-details-modal"
    id="opportunityDetailsModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
    style="background-color: #5c6377b8"
    v-if="opportunity!=null"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" v-if="opportunity.company">
        <button style="width: 2.5em; height: 2.5em;" class="btn-x" data-dismiss="modal" aria-label="Close">X</button>
        <div class="opportunity-name">
          <h2>{{ opportunity.name }}</h2>

          <button class="blue float right" v-if="getMyStatus() === 'NOTFOUND' && showCandidateButton" @click="subscribeOpportunity()" data-dismiss="modal" aria-label="Close">
            <!-- <img class="icon-button" src="/static/svg/icone-entrar-em-contato-completo.svg" alt=""> -->
            Candidatar
          </button>
          <button class="blue float right" v-if="getMyStatus() === 'CANCELED' && showCandidateButton" @click="subscribeOpportunity()" data-dismiss="modal" aria-label="Close">
            <!-- <img class="icon-button" src="/static/svg/icone-entrar-em-contato-completo.svg" alt=""> -->
            Candidatar
          </button>
          <!-- <button class="blue float right" v-if="getMyStatus() !== 'CANCELED' && getMyStatus() !== 'NOTFOUND' && showCandidateButton && opportunity.status == 'OPEN'" @click="unsubscribeOpportunity()" data-dismiss="modal" aria-label="Close">
          
            Cancelar inscrição
          </button> -->
        </div>
        <div  class="exp-place" v-if="opportunity.address">
          {{ opportunity.expiration | formatDate}} | {{ opportunity.address.city }}
        </div>
        <div class="exp-info ">
          <b>Candidaturas:</b> {{ opportunity.candidates.length }} <br/>
          <b>Status da vaga:</b> {{ opportunityStatus[opportunity.status] }} <br/>
          <b>Status do candidato:</b> {{ candidateStatus[getMyStatus()] }}
        </div>
        <br />

        <h3>Sobre a empresa</h3>
        <p class="exp-info">
          <b>Empresa: </b> {{ opportunity.company.name_fancy }} <br />
        </p>
        <p class="exp-info" v-if="opportunity.company.segment">
          <b>Segmento: </b> {{ opportunity.company.segment.name }} <br />
        </p>

        <p v-html="opportunity.company.description">
        </p>

        <h3>Sobre a oportunidade</h3>
        <div class="exp-info row">
          <div class="col-md-6">
            <b>Cargo</b> {{ positionType[opportunity.position] }} <br />
            <b>Regime de tempo:</b>
            {{ employmentType[opportunity.employment_type] }} <br />
            <b>Disponibilidade para viagem:</b>
            {{opportunity.availability_for_travel ? "Sim" : "Não"}}
          </div>

          <div class="col-md-6">
            <!-- <b>Salário fixo: </b>
            {{ opportunity.salary_range_end | toCurrency }} <br /> -->
            <!-- <b>Comissão min: </b>
            {{ opportunity.salary_range_start | toCurrency }} <br />
            <b>Comissão max: </b>
            {{ opportunity.salary_range_end | toCurrency }} <br /> -->
            <b>Faixa salarial: </b>
            {{ opportunity.salary_range_start | toCurrency }} - {{ opportunity.salary_range_end | toCurrency }} <br />

          </div>
        </div>

        <p v-html="opportunity.description">
        </p>

        <h3 v-if="getLanguageByType('INDUSTRY_GENERAL').length > 0">Conhecimentos necessários</h3>
        <ul v-if="getLanguageByType('INDUSTRY_GENERAL').length > 0">
          <li v-for="skill in getLanguageByType('INDUSTRY_GENERAL')" :key="skill.skill._id">
            <label for="">{{ skill.skill.name }} </label> - 
            {{ proficiencyLevelSegment[skill.proficiency] }}
          </li>
        </ul>

        <h3 v-if="getLanguageByType('LANGUAGE').length > 0">Idiomas</h3>
        <ul v-if="getLanguageByType('LANGUAGE').length > 0">
          <li v-for="skill in getLanguageByType('LANGUAGE')" :key="skill.skill._id">
            <label for="">{{ skill.skill.name }} </label> - 
            {{ proficiencyLevelLanguage[skill.proficiency] }}
          </li>
        </ul>

        <h3 v-if="getLanguageByType('LANGUAGE').length > 0">Candidatos</h3>
        <button  @click="goToSeeCandidates($event, opportunity)"  data-dismiss="modal" aria-label="Close">Ver candidatos</button>



        
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from "../mixins/userMixin.js";
import { server, settings, local } from "../js/variables.js";
import moment from "moment";

export default {
  mixins: [userMixin],
  props: ["modal_id", "opportunity", "showCandidateButton"],
  components: {},
  data() {
    return {

    };
  },
  mounted() {},
  watch: {
    opportunity(n, v) {
      // console.log("watch", n);
    },
  },
  methods: {
    checkSubmit: function (e) {
      if (e.keyCode === 13) {
        this.find(null);
      }
    },

    getMyStatus(){
      var answer = "NOTFOUND";
      if (this.opportunity) {
        for (let candidate of this.opportunity.candidates) {
          if (candidate.user._id === this.user._id) {
            // console.log("AQUI", candidate.status );     
            return candidate.status;
          }
        }
      }
      return answer;
    },
    getLanguageByType(type) {
      var list = [];
      if (this.opportunity) {
        for (let opportunitySkill of this.opportunity.skills) {
          if (!opportunitySkill.skill) {
            break;
          }

          const skillType = opportunitySkill.skill.type;
          if(!skillType) {
            break;
          }

          if (skillType === type ) {
            list.push(opportunitySkill);
          }
        }
      }
      

      return list;
    },
    subscribeOpportunity(){
      let self = this;
      if(local.permissions.subscribeVacancies < local.permissions.subscribeVacanciesMax){
        if (!localStorage.token) {
          return
        }
        server.config.headers = {
          Authorization: 'Bearer ' + localStorage.token
        }
        this.axios.put(  server.url + '/api/job-opportunity/' + this.opportunity._id + '/subscribe', server.config)
        .then(function (response) {
          let responseData = response.data;
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: responseData.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
            }).then((result) => {
            if (result.isConfirmed) {
              // window.open("./vc/candidaturas", "_self"); 
              const url = "/vc/candidaturas";
              self.$router.push(url);
            }
          })
        })
        .catch(function (error) {
          // console.log("create - error");
          self.output = error;
          self.showError(error);
        });
      }else{
        self.$swal({
          icon: 'error',
          title: 'Aviso',
          text: 'Você atingiu o limite de inscrições em vagas',
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Ok'
          }).then((result) => {
          if (result.isConfirmed) {
            // window.open("./vc/candidaturas", "_self"); 
            const url = "/vc/perfil";
            self.$router.push(url);
          }
        })
      }
    },
    goToSeeCandidates(event, opportunity) {
      setTimeout(()=>{
        if (event.stopPropagation) event.stopPropagation();
        const url = `/co/vaga/${opportunity._id}?candidatos=x`;
        this.$router.push(url);

      }, 200)
      
    },
    unsubscribeOpportunity(){
      let self = this;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }
      this.axios.delete(  server.url + '/api/job-opportunity/' + this.opportunity._id + '/unsubscribe', server.config)
      .then(function (response) {
        let responseData = response.data;
        self.$swal({
          icon: 'success',
          title: 'Sucesso',
          text: responseData.message,
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Ok'
          }).then((result) => {
          if (result.isConfirmed) {
            // console.log('OK')
            window.open("./vc/candidaturas", "_self"); 
            // const url = "/vc/candidaturas";
            // self.$router.push(url);
          }
        })
      })
      .catch(function (error) {
        // console.log("create - error");
        self.output = error;
        self.showError(error);
      });
    },
    //TODO: busca
  },
};
</script>

<style lang="scss">
.opportunity-details-modal {
  .blue {
    width: 160px;
    height: 32px;
    float: right;
  }

  .icon-button {
    top: 40px;
    right: 222px;
  }

  .modal-content {
    padding: 70px;
    border-radius: 1.5rem;
    padding-left: 90px;
  }

  .opportunity-name {
    margin-top: -30px;
    margin-left: -19px;
  }

  h2 {
    background-color: var(--main-color-secundary);
    line-height: 45px;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    width: 300px;
    text-align: center;
    position: absolute;
    left: 0px;
    margin-bottom: 30px;
  }

  h3 {
    color: var(--main-color-secundary);
    line-height: 35px;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .exp-place {
    color: var(--main-title-color);
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    margin-top: 60px;
  }

  .exp-info {
    margin-bottom: 10px;
    color: var(--main-text-color);
    font-size: 14px;
    line-height: 15pt;
    font-weight: 300;
  }

  p {
  }
}

@media only screen and (max-width: 600px) {
  .opportunity-details-modal{

    .blue{
      margin-top: 55px;
    }
    
    .modal-content {
      padding: 70px 30px;
    }
  } 
}
</style>