<template>
  <main-layout>
    <banner :bannerType=1> </banner>
    <!-- <img class="banner-about" src="/static/img/banner_about.jpg" alt /> -->
    <div class="container public-company-home">
      <about-company />

      <div class="search-salesman-bg">
        <div class="search-salesman-box container" id="find-salesmans">
          <h1>Procurar profissionais da área comercial</h1>

          <!-- <div class="filter-search d-none d-md-block d-lg-block">
            <div class="row search-row">
              <div class="col-md-2">
                <v-select
                  label="sigla" 
                  :options="stateList"
                  v-on:input="getSalesman('state')"
                  :reduce="state => state.sigla" 
                  v-model="search.state"
                  :placeholder="'(Selecione)'"
                >
                </v-select>
              </div>
              <div class="col-md-2">
                <v-select
                  label="nome" 
                  :options="cityList"
                  v-on:input="getSalesman('city')"
                  :reduce="city => city.nome" 
                  v-model="search.city"
                  :placeholder="cityPlaceholder"
                  :disabled="cityPlaceholder == 'Selecione estado' || cityPlaceholder == 'Carregando...'"
                >
                </v-select>
              </div>
              <div class="col-md-2">
                <v-select
                  label="name"
                  :options="segmentList"
                  v-on:input="getSalesman"
                  v-model="search.segment"
                  :reduce="(segment) => segment._id"
                  :placeholder="'Segmento'"
                >
                </v-select>
              </div>
              <div class="col-md-4">
                <input
                  id="searchText"
                  type="text"
                  v-model="search.searchText"
                  placeholder="Buscar por nome"
                  v-on:keyup.enter="getSalesman"
                />
                </div>
              <div class="col-md-2">
                <button @click="getSalesman()" >Buscar</button>
              </div>
            </div>
          </div> -->
          <div class="filter-search d-none d-md-block d-lg-block">
            <div class="row search-row">
              <div class="col-md-2">
                <label class="label-search">Estado</label>
                <v-select label="sigla" :options="stateList" v-on:input="getSalesman('state')"
                  :reduce="state => state.sigla" v-model="search.state" :placeholder="'(Selecione)'">
                </v-select>
              </div>
              <div class="col-md-2">
                <label class="label-search">Cidade</label>
                <v-select label="nome" :options="cityList" v-on:input="getSalesman('city')" :reduce="city => city.nome"
                  v-model="search.city" :placeholder="cityPlaceholder"
                  :disabled="cityPlaceholder == 'Selecione estado' || cityPlaceholder == 'Carregando...'">
                </v-select>
              </div>
              <div class="col-md-2">
                <label class="label-search">Cargo</label>
                <v-select label="label" :options="positionList" v-on:input="getSalesman" v-model="search.position"
                  :reduce="(position) => position.value" :placeholder="'Cargo'">
                </v-select>
              </div>
              <div class="col-md-2">
                <label class="label-search">Segmento</label>
                <v-select label="name" :options="segmentList" v-on:input="getSalesman" v-model="search.segment"
                  :reduce="(segment) => segment._id" :placeholder="'Segmento'">
                </v-select>
              </div>
              <div class="col-md-2" style="margin-top: 20px;">
                <input id="searchText" type="text" v-model="search.searchText" placeholder="Buscar por nome"
                  v-on:keyup.enter="getSalesman" style="padding: 12px 14px !important;" />
              </div>
              <div class="col-md-2" style="margin-top: 20px;">
                <button @click="getSalesman()">Buscar</button>
              </div>
              <div class="col-md-4">
                <input id="wantProducts" type="checkbox" v-model="search.wantProducts" value="0" />
                <label for="wantProducts"> Buscando produtos para vender / representar</label>
              </div>
              <div class="col-md-3">
                <input id="wantJob" type="checkbox" v-model="search.wantJob" value="0" />
                <label for="wantJob"> Buscando vaga / emprego</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="row">
          <div class="col-md-6" v-for="user in salesmanList" :key="user._id" @click="salesman = user" data-toggle="modal"
            data-target="#salesmanDetailsModal">
            <salesman-item :salesman="user" :size="2"> </salesman-item>
          </div>
          <div class="col-md-12" style="margin-top: 30px; text-align: center;" v-if="canLoadMore">
            <!-- <center> <small> {{maxItems}} de {{productList.length}} </small></center> -->
            <button class="blue-border big" @click="loadMore()" style="width: 40%; margin: 20px 0px;">
              Carregar mais
            </button>
          </div>
        </div>
      </div>
      <salesman-details-modal :modal_id="'salesmanDetailsModal'" :salesman=salesman></salesman-details-modal>

      <hr>
      <div class="afiliate-box">
        <h1>Escolha seu plano</h1>
        <div class="hr-yellow hr-4 center" style="margin-bottom: 38px;"></div>
        <!-- <h2 style="padding-top: 30px">Profissional</h2>
        <div class=" light-grey-bg">
          <div class="row" style="padding: 4px 0px; margin-top: 25px">
            <div class="col-md-6" style="padding-right: 2px">
              <plan-item :isSubscribed="2" :freePlans="salesmanFreePlan"></plan-item>
            </div>
            <div class="col-md-6" style="padding-left: 2px">
              <plan-item :isSubscribed="0" :paidPlans="salesmanPlan"></plan-item>
            </div>
          </div>
        </div> -->
        <h2 style="padding-top: 30px" v-if="companyFreePlan._id || companyPlan._id">Empresarial</h2>
        <div class=" light-grey-bg" v-if="companyFreePlan._id || companyPlan._id">
          <div class="row" style="padding: 4px 0px; margin-top: 25px">
            <div class="col-md-6 no-padding-left-m" style="padding-right: 2px;">
              <!-- company free -->
              <plan-item :isSubscribed="2" :freePlans="companyFreePlan"></plan-item>
            </div>
            <div class="col-md-6 no-padding-right-m" style="padding-left: 2px;">
              <!-- company paid -->
              <plan-item :isSubscribed="0" :paidPlans="companyPlan"></plan-item>
            </div>
          </div>
        </div>
        <!-- <h2 style="padding-top: 30px">Afiliado</h2>
        <div class=" light-grey-bg">
          <div class="row" style="padding: 4px 0px; margin-top: 25px">
            <div class="col-md-6" style="padding-right: 2px;">
              <plan-item :isSubscribed="2" :freePlans="afiliateFreePlan"></plan-item>
            </div>
            <div class="col-md-6" style="padding-left: 2px;">
              <plan-item :isSubscribed="0" :paidPlans="afiliatePlan"></plan-item>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <br />

    <contact />
    <benefits-for-you />
    <newsletter />
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import Contact from "../components/Contact.vue";
import BenefitsForYou from "../components/BenefitsForYou.vue";
import PlanItem from "../components/PlanItem.vue";
import AboutCompany from "../components/AboutCompany.vue";
import Newsletter from "../components/Newsletter.vue";
import SalesmanItem from "../components/SalesmanItem.vue";
import SalesmanDetailsModal from "../components/SalesmanDetailsModal.vue";
import Banner from '../components/Banner.vue'
import { server } from "../js/variables.js";

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
    Contact,
    // Slider,
    SalesmanItem,
    SalesmanDetailsModal,
    BenefitsForYou,
    PlanItem,
    AboutCompany,
    Newsletter,
    Banner,
  },
  data() {
    return {
      salesmanList: [
        // {id: 1, name: "João", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
        // {id: 2, name: "Maria", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
        // {id: 3, name: "Ana", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
        // {id: 4, name: "Pedro", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
      ],
      search: {
        state: null,
        city: null,
        position: null,
        segment: null,
        searchText: null,
        wantProducts: null,
        wantJob: null,
        afiliate: null,
      },
      salesman: {},
      companyPlan: {},
      salesmanPlan: {},
      afiliatePlan: {},
      companyFreePlan: {},
      salesmanFreePlan: {},
      afiliateFreePlan: {},
      maxItems: 10000,
      pageItems: 8,
      page: 0,
      canLoadMore: false,
      // planList: [
      //     {id: 1, name: "Plano profissional", desc: "Anuncie suas vagas gratuitamente \n Anuncie seu currículo gratuitamente<br/>Anuncie sua empresa, produtos e serviços gratuitamente<br/>Cadastre seus vendedores no vocevendepara vender seus produtos e também anunciar no vocecompra, gratuitamente.", price: 6.90},
      //     {id: 2, name: "Plano corporativo", desc: "Anuncie suas vagas gratuitamente \n Anuncie seu currículo gratuitamente<br/>Anuncie sua empresa, produtos e serviços gratuitamente<br/>Cadastre seus vendedores no vocevendepara vender seus produtos e também anunciar no vocecompra, gratuitamente.", price: 6.90},
      // ],
    };
  },
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    this.getSalesman();
    this.findPlaces();
    this.findSegments();
    self.getPlans();
  },
  methods: {
    loadMore() {
      this.maxItems = this.maxItems + this.pageItems;
      this.page = this.page + 1;
      this.getSalesman();
    },
    getSalesman(type) {
      let self = this;
      // if (!localStorage.token) {
      // 	return
      // }
      // server.config.headers = {
      // 	Authorization: 'Bearer ' + localStorage.token
      // }
      // if(this.search.state){
      //   this.findCities(this.search.state);
      // }
      if (!this.search.state) {
        this.cityList = [];
        this.search.city = null;
        this.cityPlaceholder = 'Selecione estado';
      } else if (this.search.state && type == 'state') {
        this.cityList = [];
        this.search.city = null;
        this.findCities(this.search.state);
      } else if (this.search.state && type == 'city') {
        // this.findCities(this.search.state);
      }

      // if(this.search.state){
      // 	this.findCities(this.search.state);
      // }
      let header = JSON.parse(JSON.stringify(server.config));
      header['params'] = {
        limit: this.pageItems,
        page: this.page,
        type: "SALESMAN",
        resume: 1,
        state: this.search.state,
        city: this.search.city,
        position: this.search.position,
        segment: this.search.segment,
        filter: this.search.searchText,
        wantProducts: this.search.wantProducts,
        wantJob: this.search.wantJob,
        afiliate: this.search.afiliate,
      }

      this.axios
        .get(server.url + "/api/user", header)
        .then(function (response) {
          if (self.page == 0) {
            self.salesmanList = [];
          }

          let responseData = response.data.data;
          self.salesmanList = self.salesmanList.concat(responseData.user_list);

          console.log(self.salesmanList.length);
          console.log(self.pageItems);
          if (self.salesmanList.length != self.pageItems) {
            self.canLoadMore = true;
          } else {
            self.canLoadMore = true;
          }

        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    salesmanDetails(salesman) {
      console.log("salesman details", salesman);
    },
    getPlans() {
      let self = this;
      // if (!localStorage.token) {
      //     return
      // }
      // server.config.headers = {
      //     Authorization: 'Bearer ' + localStorage.token
      // }
      this.axios
        .get(server.url + "/api/plan?is_enable=true", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.planList = responseData.plan_list;
          for (let plan of self.planList) {
            if (plan.type == "SALESMAN" && plan.payment_option.length > 0) {
              self.salesmanPlan = plan;
            } else if (plan.type == "COMPANY" && plan.payment_option.length > 0) {
              self.companyPlan = plan;
            } else if (plan.type == "AFILIATE" && plan.payment_option.length > 0) {
              self.afiliatePlan = plan;
            } else if (plan.type == "SALESMAN" && plan.payment_option.length == 0) {
              self.salesmanFreePlan = plan;
            } else if (plan.type == "COMPANY" && plan.payment_option.length == 0) {
              self.companyFreePlan = plan;
            } else if (plan.type == "AFILIATE" && plan.payment_option.length == 0) {
              self.afiliateFreePlan = plan;
            }
          }
          for (let payment of self.salesmanPlan.payment_option) {
            if (payment.interval == 1) {
              self.salesmanPlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.salesmanPlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.salesmanPlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.salesmanPlan.payment_12 = payment;
            }
          }

          for (let payment of self.companyPlan.payment_option) {
            if (payment.interval == 1) {
              self.companyPlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.companyPlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.companyPlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.companyPlan.payment_12 = payment;
            }
          }

          for (let payment of self.afiliatePlan.payment_option) {
            if (payment.interval == 1) {
              self.afiliatePlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.afiliatePlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.afiliatePlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.afiliatePlan.payment_12 = payment;
            }
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss">
// @import "../scss/reset.scss";
// @import "../scss/base.scss";

.public-company-home {
  .banner-about {
    width: 100%;
    margin-top: -15px;
    margin-bottom: 50px;
  }

  .afiliate-box {
    margin-bottom: 60px;

    h1 {
      text-align: center;
      margin-top: 45px !important;
      margin-bottom: 15px !important;
    }
  }

  .search-salesman-bg {
    margin-top: -15px;
    margin-bottom: 50px;
    height: 200px;
    z-index: -1;
  }

  .search-salesman-box {
    margin-top: -15px;
    height: 200px;

    h1 {
      padding-top: 50px;
      text-align: center;
    }

    .search-row {
      margin-top: 30px;
    }

    input {
      margin-top: 0px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .public-company-home {
    .search-salesman-bg {
      margin-bottom: 0px;
    }
  }
}
</style>
