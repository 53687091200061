<template>
  <div class="image-upload">
    <file-upload
      v-if="!image"
      :url="url"
      :thumb-url="thumbUrl"
      :headers="headers"
      :uniqueId="getRandomArbitrary()"
      name="image"
      class="thumb"
      v-model="files"
      accept="image/png, image/gif, image/jpeg, image/webp"
      @change="onFileChange"
      ref="upload"
    ></file-upload>

    <div
      v-else
      class="thumb"
      :style="{
        backgroundImage: `url('${getImageThumbUrl(image)}')`,
      }"
    >
      <i
        class="fas fa-times-circle close-button"
        @click="removeMediaImage(image)"
      ></i>
    </div>
  </div>
</template>

<script>
import { server } from "../js/variables.js";

export default {
  props: {
    image: {
      required: true,
    },
  },
  model: {
    prop: "image",
    event: "imagechange",
  },
  data() {
    return {
      url: server.url + "/api/media/image/",
      headers: {
        Authorization: 'Bearer ' + localStorage.token
      },
      files: [],
      filesUploaded: [],
      fileUploaded: null,
    };
  },
  computed: {
    imageLocal: {
      get: function () {
        return this.liimagest;
      },
      set: function (value) {
        this.$emit("imagechange", value);
      },
    },
  },
  mounted() {
    this.headers = server.config.headers;
    this.url = server.url + "/api/media/image/";
    if (!this.headers.Authorization && localStorage.token) {
        this.headers = {
            Authorization: 'Bearer ' + localStorage.token
        }
    }
  },
  updated() {
    this.url = server.url + "/api/media/image/";
  },
  methods: {
    getRandomArbitrary() {
      return "-" + Math.random() * (100 - 0) + 0;
    },
    thumbUrl(file) {
      return file.myThumbUrlProperty;
    },
    onFileChange(response) {
      // Handle files like:
      // console.log(response.data);
      this.imageLocal = response.data.image;
      this.image = this.imageLocal;
      this.fileUploaded = response.data.image.url;
      this.$forceUpdate();
      this.$emit("uploaded");
    },
    removeMediaImage(image) {
      var self = this;
      if (!image) return;

      this.axios
        .delete(server.url + "/api/media/image/" + image._id, server.config)
        .then(function (response) {
          // self.driver.image = null;
          self.image = null;
          self.$emit("uploaded");
          // self.updateItem();
          self.$swal({
            icon: "success",
            title: "Imagem removida",
            time: 5000,
          });
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss" >
.file-upload {
  .input-wrapper {
    height: 100% !important;
    background-color: #efeaea !important;
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #a3aab4 !important;
    }

    .file-upload-label {
      .file-upload-icon {
        align-self: center;
        color: #555;
        display: initial !important;
      }

      div {
        font-size: 10pt;
        margin-top: 20px;
        color: black;
      }
    }
  }
}

.image-upload{
  .thumb{
    height: 392px;
    padding-top: 0px;
  }
  
  .file-upload-label{
    height: 100%;
    padding: initial !important;
    padding-top: 30% !important;
  }
}


@media only screen and (max-width: 600px) {
  .image-upload {
    .thumb {
      height: 305px;
      padding-top: 0px;
    }
  }
}
</style>