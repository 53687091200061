<template>
  <div
    class="modal fade salesman-register-modal"
    id="salesmanRegisterModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
    style="background-color: #5c6377b8"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-top">
          <button
            class="btn-x"
            data-dismiss="modal"
            aria-label="Close"
            style="margin-top: 0px; margin-bottom: 0px"
          >
            X
          </button>
          <img src="/static/svg/icon_logo_small.svg" alt="" class="center" />
          <h1>Cadastro</h1>
          <h3>do profissional</h3>
          <div v-if="showingTerms">
            <terms-and-policy :type="'modal'"></terms-and-policy>
          </div>
          <div v-else>
            <input
              id="namesalesman"
              type="text"
              placeholder="Nome"
              v-model="$v.user.name.$model"
            />
            <input
              id="lastnamesalesman"
              type="text"
              placeholder="Sobrenome"
              v-model="$v.user.lastname.$model"
            />
            <input
              id="emailregsalesman"
              type="text"
              placeholder="Login e-mail"
              v-model="$v.user.email.$model"
            />
            <span v-if="!$v.user.email">Verifique o formato do email</span>
            <input
                id="phone"
                type="text"
                placeholder="Telefone"
                v-model="$v.user.phone.$model"
                v-mask="'(##) #####-####'"
              />
              <div class="password">
                <input id="password" :type="passwordInputType" v-model="$v.user.password.$model" placeholder="Digite sua senha">
                <span v-if="$v.user.password.$error">Campo obrigatório</span>
                <button class="clean" @click="showHidePassword()">
                  <i class="far fa-eye" v-if="passwordInputType === 'password'"></i>
                  <i class="far fa-eye-slash" v-if="passwordInputType === 'text'"></i>
                </button>
              </div>


              <div class="password">
                <input id="passwordConfirm" :type="passwordInputType" placeholder="Confirme sua senha"
                  v-model="$v.user.passwordConfirm.$model">
                <span v-if="$v.user.passwordConfirm.$error">As senhas nao coincidem</span> <button class="clean"
                  @click="showHidePassword()">
                  <i class="far fa-eye" v-if="passwordInputType === 'password'"></i>
                  <i class="far fa-eye-slash" v-if="passwordInputType === 'text'"></i>
                </button>
              </div>
            <!-- <input
                id="afiliate"
                type="text"
                placeholder="Código de afiliado (opcional)"
                v-model="$v.afiliate.$model"
              /> -->
          </div>
          <div style=" padding-top: 35px">
            <div style="width: 65%; display: inline-block">
              <input type="checkbox" name='type' id='terms' v-model="checked" style="float: left">
              <label for="terms" @click="showTerms()"> Estou de acordo com os termos</label>
            </div>
            <div style="width: 65%; display: inline-block">
              <input type="checkbox" name='type' id='accept_phone' value="true" v-model="user.accept_phone">
              <label for="accept_phone"> Quero receber mensagens por WhatsApp</label>
            </div>
            <button class="blue" style="display: inline-block; float: right" @click="createUser()" :disabled="isSending">Confirmar</button>
          </div>
        </div>
        <div class="modal-bottom" style="text-align: center">
          <button
            class="register-btn"
            data-dismiss="modal"
            aria-label="Close"
            data-toggle="modal"
            data-target="#loginModal"
            style="margin-right: 20px"
          >
            Ja tenho um cadastro
          </button>
          <button
            class="register-btn"
            data-dismiss="modal"
            aria-label="Close"
            data-toggle="modal"
            data-target="#companyRegisterModal"
          >
            Cadastro conta empresa
          </button>
          <!-- <button class="register-btn" style="margin-right: 20px">Ja tenho um cadastro</button>
          <button class="register-btn">Cadastro conta empresa</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import userMixin from "../mixins/userMixin.js";
import { server, settings, local } from "../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";
import TermsAndPolicy from "../components/TermsAndPolicy.vue";
import moment from "moment";
import $ from "jquery";

export default {
  // mixins: [userMixin],
  props: ["modal_id"],
  components: {
    TermsAndPolicy,
  },
  data() {
    return {
      user: {
        name: "",
        lastname: "",
        email: "",
        phone: '',
        password: "",
        confirmPassword: "",
        type: "SALESMAN",
        accept_phone: false,
      },
      passwordInputType: 'password',

      afiliate: '',
      checked: false,
      isSending: false,
      showingTerms: false,
    };
  },
  validations: {
    user: {
      name: { required },
      lastname: { required },
      email: { required, email },
      phone: { required },
      password: { required },
      passwordConfirm: "",
        accept_phone: '',
    },
    afiliate: '',
  },
  mounted(){
    if(localStorage.afiliate){
      this.afiliate = localStorage.afiliate;
    }
  },
  watch: {},
  methods: {
    createUser() {
      let self = this;
      let userCopy = JSON.parse(JSON.stringify(self.user));

      if(userCopy.password.length < 6){
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Senha deve ter no mínimo 6 caracteres',
          // footer: '<a href>Why do I have this issue?</a>'
        });
        return
      }
          
      let phone = userCopy.phone;
      if (phone)
      if(phone.length > 13){
        let phoneNoMask = phone.substring(1, 3) + phone.substring(5, 10) + phone.substring(11, 15);
        userCopy.phone = phoneNoMask;
      }
      // console.log("invalido", this.$v.$invalid);
      if (!this.$v.$invalid && this.checked) {
        // console.log("PASSOU", this.user, this.checked);
        // let userCopy = JSON.parse(JSON.stringify(self.user));
        self.isSending = true;
        this.axios
          .post(
            server.url + "/api/user",
            {
              user: userCopy,
              afiliate: self.afiliate,
            },
            server.config
          )
          .then(function (response) {
            let token = response.data.data.auth.token;
            let user = response.data.data.user;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user) );
            self.isSending = false;
            // window.open("./vc/dados", "_self");
            const url = "/vc/dados";
            self.$router.push(url);
            $("#salesmanRegisterModal").modal("hide");
          })
          .catch(function (error) {
            self.output = error;
            self.isSending = false;
            self.showError(error);
            console.log(error);
          });
      } else {
        // console.log("ERRO", this.user, this.checked);
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    showTerms(){
      this.showingTerms = !this.showingTerms;
    },
    showHidePassword(){
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    }
  },
};
</script>

<style lang="scss">
.salesman-register-modal {
  .modal-content {
    padding: 0px;
    border-radius: 1.5rem;

    .modal-top {
      padding: 40px 80px;

      h1 {
        text-align: center;
        // font-size: 36px;
        margin-top: 12px !important;
        margin-bottom: 0px !important;
        color: var(--main-text-color);
      }

      h3 {
        text-align: center;
        margin-bottom: 30px;
      }

      input {
        background-color: var(--main-color-lightgrey);
        border: none !important;
        height: 45px;
      }

      input[type="checkbox"] {
        width: 25px;
      }

      .clean {
        font-size: 15px;
        font-weight: normal;
        margin-right: 30px;
        outline: none;
      }

      pre{
        // width: 100%;
        overflow: hidden;
      }
    }
    .password{
        position: relative;
        button{
          position: absolute;
          right: 0px;
          top: 7px;
        }
      }

    .modal-bottom {
      background-color: var(--main-color-lightgrey);
      border-bottom-left-radius: 1.5em;
      border-bottom-right-radius: 1.5em;
      height: 102px;
      padding: 0px 16px;

      button {
        width: 230px;
        color: var(--main-color-primary);
        height: 46px;
        border-radius: 9px;
        margin-top: 25px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .salesman-register-modal {
    max-height: 100vh;
    overflow: scroll;

    .modal-top {
      padding: 50px 20px !important;

      img {
        position: absolute;
        left: 20px;
        top: 30px;
      }
    }
    .modal-content {
      padding: 0px;
    }

    .modal-bottom {
      button {
        width: 46% !important;
        margin: 5px !important;
        margin-top: 23px !important;
        float: left;
      }
    }
  }
}
</style>