<template>
  <main-layout>
    <banner :bannerType="4"> </banner>
    <div class="teacher">
      <div class="container be-a-teacher section">
        <div class="row">
          <div class="col-md-6 d-none d-md-block d-lg-block">
            <div class="img-left" style="background-image: url(./static/img/be-a-teacher-home.jpg)"></div>

          </div>
          <div class="col-md-6">
            <h2>Seja um treinador</h2>
            <div class="hr-yellow hr-4"></div>
            <p>
              Crie, cadastre, ministre e venda seu treinamento de vendas para todas as empresas e profissionais no
              vocevende. Crie seu próprio conteúdo.
              <br><br>
              Ofereça treinamento de vendas para sua equipe planejar suas abordagens, argumentar com consistência e
              sobrepor objeções, obtendo resultados mais rapidamente.
              <br><br>
              Cadastre treinamentos online exclusivos para sua empresa e funcionários.
              <br><br>
              Se você tem vontade de empreender na internet, comece criando um treinamento e transmita todo seu
              conhecimento.
              <br><br>
              Vender cursos online é uma alternativa para transmitir conhecimento de maneira rápida e simples. Cadastre-se
              gratuitamente e comece agora.
            </p>
          </div>
        </div>
      </div>

      <div class="container-fluid plans-for-teachers">
        <div class="row">
          <div class="col-md-6 light-grey-bg left">
            <h1>Plano para treinadores</h1>
            <!-- <hr style="width: 360px"> -->
            <div class="hr-yellow hr-4" style="margin-top: 15px; margin-bottom: 15px"></div>
            <p>A assinatura plano profissional permite uma maior visibilidade a clientes que podem se tornar potenciais
              consumidores de seu treinamento. Você tem todas as vantagens para criar e vender seu treinamento. Porém, com
              esse upgrade você não se preocupa com limitações do plano gratuito e ter mais serviços dentro do Vocevende.
            </p>
            <button class="free-version-btn" @click="goToRegister">Acessar versão gratuita</button>

            <!-- <plan-item :isSubscribed="0" :paidPlans="salesmanPlan"></plan-item> -->
            <plan-item :isSubscribed="salesmanIsPaid" :paidPlans="salesmanPlan"
              :subscribedPlan="subscribedSalesmanPlan"></plan-item>

          </div>

          <div class="col-md-6 d-none d-md-block d-lg-block" style="padding-left: 0px;">
            <img src="/static/img/for_teacher@2x.png" alt="">
          </div>
        </div>
      </div>

      <refer-and-win-modal :modal_id="'referAndWinModal'"></refer-and-win-modal>
      <login-modal :modal_id="'loginModal'"></login-modal>
      <company-register-modal :modal_id="'companyRegisterModal'"></company-register-modal>
      <salesman-register-modal :modal_id="'salesmanRegisterModal'"></salesman-register-modal>
    </div>
    <BenefitsForYou />

    <newsletter />
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import userMixin from "../mixins/userMixin.js";
import Banner from "../components/Banner.vue";
import ReferAndWinModal from "../components/ReferAndWinModal.vue";
import LoginModal from "../components/LoginModal.vue";
import CompanyRegisterModal from "../components/CompanyRegisterModal.vue";
import SalesmanRegisterModal from "../components/SalesmanRegisterModal.vue";
import Newsletter from "../components/Newsletter.vue";
import PlanItem from "../components/PlanItem.vue";
import BenefitsForYou from "../components/BenefitsForYou.vue";
import { server, local } from "../js/variables.js";

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    Banner,
    ReferAndWinModal,
    LoginModal,
    CompanyRegisterModal,
    SalesmanRegisterModal,
    Newsletter,
    PlanItem,
    BenefitsForYou,
  },
  data() {
    return {
      sliderList: [
        { link_redirect: "./", image_desktop: "/static/img/slider.jng" },
      ],
      mobileShowFilter: false,
      salesmanPlan: {},
      salesmanIsPaid: 0,
      subscribedSalesmanPlan: {},
    };
  },
  mounted() {
    var parameters = this.$route.query;
    this.getPlans();
  },
  watch: {
    user(newValue, oldValue) {
      // console.log(newValue, oldValue);
    // console.log("plano", local.subscriptionList)

      if(local.subscriptionList){
        for(let subscription of local.subscriptionList){
          // console.log("plano", subscription)
          if(subscription.plan.type == 'SALESMAN'){
            if(subscription.plan.payment_option.length > 0){
              this.salesmanIsPaid = 1;
              this.subscribedSalesmanPlan = subscription;
            }else{
              this.salesmanIsPaid = 0;
            }
          }else if(subscription.plan.type == 'AFILIATE'){
            if(subscription.plan.payment_option.length > 0){
              this.afiliateIsPaid = 1;
              this.subscribedAfiliatePlan = subscription;
            }else{
              this.afiliateIsPaid = 0;
            }
          }
        }
      }
    },
  },
  methods: {
    goToRegister(){
      window.open("/vc/cadastro", "_self");
    },
    onPageChange(page) {
      this.sliderPage = page;
    },
    gotostore() {
      // window.open("./store", "_self");
      const url = "/store";
      this.$router.push(url);
    },
    getPlans() {
      let self = this;
      // if (!localStorage.token) {
      //     return
      // }
      // server.config.headers = {
      //     Authorization: 'Bearer ' + localStorage.token
      // }
      this.axios
        .get(server.url + "/api/plan?is_enable=true", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.planList = responseData.plan_list;
          for (let plan of self.planList) {
            if (plan.type == "SALESMAN" && plan.payment_option.length > 0) {
              self.salesmanPlan = plan;
            } else if (plan.type == "COMPANY" && plan.payment_option.length > 0) {
              self.companyPlan = plan;
            } else if (plan.type == "AFILIATE" && plan.payment_option.length > 0) {
              self.afiliatePlan = plan;
            } else if (plan.type == "SALESMAN" && plan.payment_option.length == 0) {
              self.salesmanFreePlan = plan;
            } else if (plan.type == "COMPANY" && plan.payment_option.length == 0) {
              self.companyFreePlan = plan;
            } else if (plan.type == "AFILIATE" && plan.payment_option.length == 0) {
              self.afiliateFreePlan = plan;
            }
          }
          for (let payment of self.salesmanPlan.payment_option) {
            if (payment.interval == 1) {
              self.salesmanPlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.salesmanPlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.salesmanPlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.salesmanPlan.payment_12 = payment;
            }
          }

          for (let payment of self.companyPlan.payment_option) {
            if (payment.interval == 1) {
              self.companyPlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.companyPlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.companyPlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.companyPlan.payment_12 = payment;
            }
          }

          for (let payment of self.afiliatePlan.payment_option) {
            if (payment.interval == 1) {
              self.afiliatePlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.afiliatePlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.afiliatePlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.afiliatePlan.payment_12 = payment;
            }
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss">
// @import "../scss/reset.scss";
// @import "../scss/base.scss";

.teacher {
  .be-a-teacher {
    h2 {
      text-align: left;
      margin-bottom: 8px;
    }

    p {
      text-align: left;
      margin-top: 20px;
    }
  }

  .img-left {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    height: 100%;
    width: 100%;
  }

  .plans-for-teachers {
    .left {
      padding: 75px;
    }

    .free-version-btn {
      height: 46px;
      width: 100%;
      color: var(--main-title-color);
      margin-top: 50px;
      margin-bottom: 35px;
    }

    .plan-paid-box {
      padding: 40px;
      border-radius: 10px;
      box-shadow: 0px 10px 40px #0000001A;
    }
  }

}

@media only screen and (max-width: 600px) {
  .teacher {
    .plans-for-teachers {
      .left {
        padding: 25px;
      }

      .free-version-btn {
        height: 46px;
        width: 100%;
        color: var(--main-title-color);
        margin-top: 50px;
        margin-bottom: 35px;
      }

      .plan-paid-box {
        padding: 40px;
        border-radius: 10px;
        box-shadow: 0px 10px 40px #0000001A;
      }
    }
  }
}</style>
