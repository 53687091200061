<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-8 ">
            <h1 >Aula</h1>
          </div>
          <div class="col-md-2 ">
            <button class="remove" v-if="this.$route.params.id != 'new'" @click="deleteLesson()">Excluir</button>
          </div>
          <div class="col-md-2 ">
            <button v-if="this.$route.params.id == 'new'" @click="createLesson()">Criar</button>
            <button v-else @click="updateLesson('redirect')">Salvar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="lesson-register container">
      <div class="row">
        <div class="col-md-12">
          <div class="title-bg-grey">
            <h3>Informações da aula</h3>
          </div>
          <br>
        </div>

        <!-- <div class="col-md-6">
          <label for="name">Curso</label>
          <v-select label="name" 
            :options="segmentList" 
            v-model="course.name" 
            :reduce="segment => segment._id" >
          </v-select>
        </div> -->

        <div class="col-md-6">
          <label for="name">Treinamento</label>
          <input id="name" type="text" v-model="course.name" disabled> 
        </div>

        <div class="col-md-6">
          <label for="segment">Matéria</label>
          <v-select label="title" 
            :options="course.sections" 
            v-model="section" 
            :reduce="segment => segment._id" >
          </v-select>
        </div>

        <div class="col-md-6">
          <label for="openForSale">Nome*</label>
          <input id="price" type="text" v-model="$v.lesson.name.$model">
        </div>

        <div class="col-md-3" >
          <label for="description">Publicar</label>
          <v-select label="label" 
            :options="availability" 
            v-model="$v.lesson.status.$model" 
            :reduce="option => option.value" >
          </v-select>
        </div>

        <div class="col-md-3" >
          <label for="description">Gratuito?</label>
          <v-select label="label" 
            :options="yesNo" 
            v-model="$v.lesson.is_free.$model" 
            :reduce="option => option.value" >
          </v-select>
        </div>

        <div class="col-md-12">
          <label for="description">Descrição*</label>
          <vue-editor id="description" type="text" placeholder="" :editorToolbar="customToolbar" v-model="$v.lesson.description.$model" @blur="$v.lesson.description.$touch()" :class="{ 'fail-error' : $v.lesson.description.$error }"></vue-editor>
        </div>

        <div class="col-md-6" style="padding-top: 25px">
          <div class="title-bg-grey" >
            <h3>Imagem promocional</h3>
          </div>
          <br>
          <div class="box-item" style="margin-top: 25px;">
            <image-upload
              v-model="lesson.image"
              @uploaded="updateLesson()"
              @deleted="updateLesson()">
            </image-upload>
          </div>
        </div>


        <div class="col-md-6" style="padding-top: 25px" v-if="lesson.video && lesson.video.process_completed == false">
          <div class="title-bg-grey" >
            <h3>Vídeo aula (Tamanho máximo 5GB e formato MP4)</h3>
          </div>
          <br>
          
          <div class="box-item" style="margin-top: 25px; cursor: initial;pointer-events: none;">
            <p style="font-weight: 500;">Seu vídeo está na fila de processamento e pode demorar até 72h para ser finalizado.</p>
          </div>
        </div>
          
        <div class="col-md-6" style="padding-top: 25px" v-else>
          <div class="title-bg-grey" >
            <h3>Vídeo aula (Tamanho máximo 5GB e formato MP4)</h3>
          </div>
          <br>
          <p>Vídeo pode demorar um pouco para ser processado</p>
          <div class="box-item">
            <video-upload
              v-model="lesson.video"
              @uploaded="updateLesson('messageSuccess')"
              @deleted="updateLesson()">
            </video-upload>
            <!-- <file-upload 
              :url="url" 
              :thumb-url='thumbUrl' 
              :headers="headers" 
              name="video"
              class="thumb" 
              v-model="files2"
              accept=""
              :max-size=150000000
              @change="onFileChangeVideo"
              ref="upload">
            </file-upload> -->
          </div>
        </div>
        <div class="offset-md-10 col-md-2" style="padding-top: 25px">
          <button v-if="this.$route.params.id == 'new'" @click="createLesson()" style="width: 100%">Criar</button>
          <button v-else @click="updateLesson('redirect')" style="width: 100%">Salvar</button>
        </div>

        <!-- <div v-for="video in lesson.video" :key="video._id" class="col-md-6">
          <div class="title-bg-grey" >
            <h3>Video aula</h3>
          </div>
          <br>
          <div class="box-item ">
            <div class="thumb" 
              :style="{ backgroundImage: `url('${getImageThumbUrl(video)}')` }" >

              <a class="close-button" @click="removePhoto(video)" >
                <i class="fas fa-times-circle"></i>
              </a>
            </div>
          </div>
        </div> -->

        <!-- <input type="file" id="file" ref="file" />

        <button type="button" @click='uploadFile()' >Upload file</button> -->

        
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import ImageUpload from "../../../components/ImageUpload.vue";
import VideoUpload from "../../../components/VideoUpload.vue";
import { server } from "../../../js/variables.js";
import { required } from 'vuelidate/lib/validators'
export default {
  components: {
    MainLayout,
    ImageUpload,
    VideoUpload
  },
  data() {
    return {
      lesson: {
        name: '',
        description: '',
        // course: '',
        // section: '',
        is_free: false,
        status: false,
      },
      section: '',
      course: {},
      selected: 'info',
      yesNo: [
        {_id: 0, label: 'Não', value: false},
        {_id: 1, label: 'Sim', value: true},
      ],
      openOrExclusive: [
        {_id: 0, label: 'Aberto', value: false},
        {_id: 1, label: 'Exclusivo', value: true},
      ],
      availability: [
        {_id: 0, label: 'Não', value: false},
        {_id: 1, label: 'Sim', value: true},
      ],
      segmentList:[],
      url: server.url + "/api/media/image/",
      headers: {
        Authorization: 'Bearer ' + localStorage.token
      },
      files: [],
      files2: [],
      fileUploaded: [],
      file: '',
    };
  },
  validations: {
    lesson: {
        name: { required },
        description: { required },
        // course: '',
        // section: '',
        is_free: '',
        status: '',
      },
  },
  mounted() {
    let self = this;
    var parameters = this.$route.query;
    var trainingId = this.$route.params.trainingId;
    var sectionId = this.$route.params.sectionId;
    var id = this.$route.params.id;
    this.findSegments();
    if (this.$route.fullPath != "/vc/meus-treinamentos/" + trainingId + '/sessao/' + sectionId + "/aula/new"){
      this.axios.get( server.url + '/api/course/' + trainingId + "/lesson/" + id, server.config)
      .then(function (response) {
        let responseData = response.data.data;
        self.lesson = responseData.lesson;
      })
      .catch(function (error) {
        self.output = error;
        self.showError(error);
      });
    }

    this.axios.get( server.url + '/api/course/' + trainingId, server.config)
    .then(function (response) {
      let responseData = response.data.data;
      self.course = responseData.course;
      self.section = self.findSectionById(sectionId);
    })
    .catch(function (error) {
      self.output = error;
      self.showError(error);
    });
    
    // this.axios.get( server.url + '/api/media/video', server.config)
    // .then(function (response) {
    //   let responseData = response.data.data;
    //   console.log('AQUI', responseData);
    // })
    // .catch(function (error) {
    //   self.output = error;
    //   self.showError(error);
    // });
  },
  methods: {
    findSegments(){
      let self = this;
      this.axios
        .get(server.url + "/api/skill?filter=INDUSTRY_GENERAL", server.config)
        .then(function (response) {
          self.segmentList= [];
          let responseData = response.data.data.skill_list;
          self.segmentList = responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },

    createLesson(){
      let self = this;
      var trainingId = this.$route.params.trainingId;
      var sectionId = this.$route.params.sectionId;
      if (!this.$v.$invalid){
        if (!localStorage.token) {
          return
        }
        server.config.headers = {
          Authorization: 'Bearer ' + localStorage.token
        }

        this.axios.post(  server.url + '/api/course/' + trainingId + '/lesson/', {
          lesson: self.lesson,
          section: self.section._id

        }, server.config)
        .then(function (response) {
          let responseData = response.data;
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: responseData.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
            }).then((result) => {
            if (result.isConfirmed) {
              // window.open("./vc/meus-treinamentos", "_self"); 
              const url = "/vc/meus-treinamentos/" + trainingId + '?local=aulas';
              self.$router.push(url);
            }
          })
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
      }else{
        this.$v.$touch();
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique os campos obrigatórios',
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    updateLesson(action){
      let self = this;
      var lessonId = this.$route.params.id;
      var trainingId = this.$route.params.trainingId;
      if(lessonId == 'new'){
        if(action == 'messageSuccess'){
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: 'Seu vídeo entrou na fila de processamento e pode demorar até 72h para ser finalizado. Ainda é necessário salvar a aula.',
            })
        }
        return
      }
      if (!this.$v.$invalid){
        if (!localStorage.token) {
          return
        }
        server.config.headers = {
          Authorization: 'Bearer ' + localStorage.token
        }
        this.axios.put(  server.url + '/api/course/' + trainingId + '/lesson/' + lessonId, {
          lesson: self.lesson,
        }, server.config)
        .then(function (response) {
          let responseData = response.data;
          if(action == 'redirect'){
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: responseData.message,
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: '#053360',
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                const url = "/vc/meus-treinamentos/" + trainingId + '?local=aulas';
                self.$router.push(url);
              }
            })
          }else if(action == 'messageSuccess'){
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: 'Upload de vídeo realizado, estamos processando e em estantes estará tudo pronto. (A tela ficara preta com texto em cima escrito "The media could not be loaded, either because...")',
              showCancelButton: true,
              confirmButtonColor: '#053360',
              confirmButtonText: 'Voltar - Meus treinamentos',
              cancelButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                const url = "/vc/meus-treinamentos";
                self.$router.push(url)
              }
            })
          }else if(action == 'reload'){
            location.reload();
          }
          
        })
        .catch(function (error) {
          // self.load();
          self.output = error;
          self.showError(error);
        });
      }else{
        this.$v.$touch();
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique os campos obrigatórios',
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    deleteLesson(){
      let self = this;
      var lessonId = this.$route.params.id;
      var trainingId = this.$route.params.trainingId;

      this.$swal.fire({
      title: 'Realmente deseja excluir esta aula?',
      text: "Esta ação é irreversível!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#053360',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir'
      }).then((result) => {
      if (result.isConfirmed) {
        if (!localStorage.token) {
          return
        }
        server.config.headers = {
          Authorization: 'Bearer ' + localStorage.token
        }

        this.axios.delete(  server.url + '/api/course/' + trainingId + '/lesson/' + lessonId, server.config)
        .then(function (response) {
          let responseData = response.data;
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: responseData.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
            }).then((result) => {
            if (result.isConfirmed) {
              const url = "/vc/meus-treinamentos/" + trainingId + '?local=aulas';
              self.$router.push(url);
            }
          })
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
      }
      })
    },
    findSectionById(id){

      for(let section of this.course.sections) {
        if (!section._id) break;
        
        if(section._id === id) {
          return section;
        }
      }

      return;
    },

    thumbUrl(file) {
      return file.myThumbUrlProperty;
    },
    onFileChange(response) {
      // Handle files like:
      console.log("entrou onfilechange", response);
      const image = response.data.image;
      if(!this.lesson.image){
        this.lesson.image = [];
      }
      this.lesson.image.push(image);
      this.fileUploaded = image.url;
    },
    onFileChangeVideo(response) {
      // Handle files like:
      console.log("entrou onfilechange", response);
      const video = response.data.video;
      if(!this.lesson.video){
        this.lesson.video = [];
      }
      this.lesson.video.push(video);
      this.fileUploaded = video.url;
    },
    removePhoto(image) {
      var self = this;
      this.axios.delete(  server.url + '/api/media/image/' + image._id , server.config)
        .then(function (response) {
          self.$swal({
            icon: 'success',
            title: 'Imagem removida',
            // text: answer.message,
            // footer: '<a href>Why do I have this issue?</a>'
          });
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    uploadFile: function(){

      this.file = this.$refs.file.files[0];

      let formData = new FormData();
      formData.append('file', this.file);

      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      this.axios.post(  server.url + '/api/media/video/', formData, server.config)
      .then(function (response) {

        if(!response.data){
          alert('File not uploaded.');
          
        }else{
          alert('File uploaded successfully.');
          const video = response.data.video;
          if(!this.lesson.video){
            this.lesson.video = [];
          }
          this.lesson.video = video;
          this.fileUploaded = video.url;
        }

      })
      .catch(function (error) {
          console.log(error);
      });

    }
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";



.lesson-register {
  padding-top: 50px;
  h1 {
    text-align: center;
  }

  .file-upload .input-wrapper {
    
  }

  .thumb {
    padding-top: 50%;
  }

  .file-upload .input-wrapper .file-upload-label {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }
  

}

@media only screen and (max-width: 600px) {
  .lesson-register {
    .box-item {
        height: 75%!important;
    }
  }
}
</style>
