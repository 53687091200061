<template>
  <div
    class="modal fade login-modal"
    id="loginModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
    style="background-color: #5c6377b8"
  >
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-top">
          <button
            class="btn-x"
            data-dismiss="modal"
            aria-label="Close"
            style="margin-top: 0px; margin-bottom: 0px"
          >
            X
          </button>
          <h1>Conectar-se</h1>
          <input id="email" type="text" v-model="$v.login.email.$model" placeholder="Login e-mail">
          <!-- <input id="password" type="password" v-model="$v.login.password.$model" v-on:keyup.enter="loginMethod" placeholder="Senha"> -->
          <div class="password">
            <input id="password" :type="passwordInputType" v-model="$v.login.password.$model" v-on:keyup.enter="loginMethod" placeholder="Senha">
            <button class="clean" @click="showHidePassword()">
              <i class="far fa-eye" v-if="passwordInputType === 'password'"></i>
              <i class="far fa-eye-slash" v-if="passwordInputType === 'text'"></i>
            </button>
          </div>
          <div style="float: right; padding-top: 35px">
            <button class="clean" @click="forgetPassword()">Lembrar senha</button>
            <button class="blue" @click="loginMethod()">Entrar</button>
          </div>
        </div>
        <div class="modal-bottom" style="text-align:center;">
          <button 
              class="register-btn" 
              style="margin-right: 5px" 
              data-dismiss="modal"
              aria-label="Close"
              data-toggle="modal"
              data-target="#salesmanRegisterModal">
                Cadastro conta profissional
          </button>
          <button 
              class="register-btn" 
              data-dismiss="modal"
              aria-label="Close"
              data-toggle="modal"
              data-target="#companyRegisterModal">
                Cadastro conta empresa
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from "../mixins/userMixin.js";
import { server, settings, local } from "../js/variables.js";
import { required, email, sameAs } from 'vuelidate/lib/validators'
import $ from 'jquery'
import moment from "moment";

export default {
  mixins: [userMixin],
  props: ["modal_id"],
  components: {},
  data() {
    return {
      login: {
        email: '',
        password: '',
      },
      passwordInputType: 'password',
    };
  },
  mounted() {},
  validations: {
    login: {
      email: { required },
      password: { required },
    },
	},
  watch: {},
  methods: {
		forgetPassword() {
      // window.open("./solicitar-nova-senha", "_self");
      const url = "/solicitar-nova-senha";
      this.$router.push(url);
      $('#loginModal').modal('hide');
		},
		registerCompany() {
      const url = "/co/cadastro";
      this.$router.push(url);
      $('#loginModal').modal('hide');
		},
		registerSalesman() {
      const url = "/vc/cadastro";
      this.$router.push(url);
      $('#loginModal').modal('hide');
		},
		loginMethod(){
			let self = this;
			if (!this.$v.$invalid){

        if(this.login.password.length < 6){
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Senha deve ter no mínimo 6 caracteres',
            // footer: '<a href>Why do I have this issue?</a>'
          });
          return
        }
                
				this.axios.post(  server.url + '/api/auth/login', {
					email: self.login.email,
					password: self.login.password,

				}, server.config)
				.then(function (response) {
          let token = response.data.data.auth.token;
          let user = response.data.data.user;
          let company = response.data.data.company;
					localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user) );
          localStorage.setItem('company', JSON.stringify(company));
          if(user.type == "SALESMAN"){
            window.open ('/vc/buscar-vagas' , '_self');
          }else{
              window.open ('/co/vagas' , '_self');
          }
					
				})
				.catch(function (error) {
					self.output = error;
					self.showError(error);
					console.log(error);
				});
			}else{
				this.$v.$touch();
				this.$swal({
					icon: 'error',
					title: 'Oops...',
					text: 'Verifique os campos obrigatórios',
					// footer: '<a href>Why do I have this issue?</a>'
				});
			}
		},
    showHidePassword(){
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    },
  },
};
</script>

<style lang="scss">
.login-modal {
  .modal-content {
    padding: 0px;
    border-radius: 1.5rem;

    .modal-top {
      padding: 40px 80px;

      h1{
        text-align: center;
        padding-bottom: 35px;
      }

      input{
        background-color: var(--main-color-lightgrey);
        border: none !important;
        height: 45px;
      }

      .clean{
        font-size: 15px;
        font-weight: normal;
        margin-right: 30px;
        outline: none;
      }

      .password{
        position: relative;
        button{
          position: absolute;
          right: 0px;
          top: 7px;
        }
      }
    }

    .modal-bottom {
      background-color: var(--main-color-lightgrey);
      border-bottom-left-radius: 1.5em;
      border-bottom-right-radius: 1.5em;
      height: 102px;
      padding: 0px 16px;

      .register-btn{
        width: 230px;
        color: var(--main-color-primary);
        height: 46px;
        border-radius: 9px;
        margin-top: 25px;
      }
    }

  }
}

@media only screen and (max-width: 600px) {
  .login-modal {
    max-height: 100vh;
    overflow: scroll;

    .modal-top {
      padding: 50px 20px !important;

      img {
        position: absolute;
        left: 20px;
        top: 30px;
      }
    }
    .modal-content {
      padding: 0px;
    }

    .modal-bottom {
      button {
        width: 46% !important;
        margin: 5px !important;
        margin-top: 23px !important;
        float: left;
      }
    }
  }
}
</style>