<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9">
            <h1>Representantes Favoritados</h1>
          </div>
          <div class="col-md-3" style="margin-top: -16px">
            <button style="font-size: 15px;" v-on:click="goTo('./co/vendedores')">
              Candidatos para
              representantes
            </button>
            <br />
            <button v-on:click="goTo('./co/vendedores/buscar')">Buscar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="favorited-salesman container company-page">
      <div class="row">
        <div class="col-md-6" v-for="favorite in favoriteList" :key="favorite._id" @click="salesmanDetails(favorite)">
          <favorite-salesman-item :salesman="favorite">
          </favorite-salesman-item>
        </div>
        <div class="col-md-12" v-if="favoriteList.length == 0">
          <h2 style="text-align: center">
            Você ainda não favoritou nenhum profissional
          </h2>
        </div>
      </div>
    </div>

    <!-- <contact></contact> -->
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Company.vue";
import FavoriteSalesmanItem from "../../../components/FavoriteSalesmanItem.vue";
import { server } from "../../../js/variables.js";

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
    FavoriteSalesmanItem,
  },
  data() {
    return {
      favoriteList: [
        // {_id: 1, name: "João", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
        // {_id: 2, name: "Maria", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
        // {_id: 3, name: "Ana", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
        // {_id: 4, name: "Pedro", resume:{summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}},
      ],
      segmentList: [
        // { id: 1, name: "Calçados" },
        // { id: 2, name: "Eletronicos" },
      ],
      search: {
        state: null,
        city: null,
        segment: null,
        searchText: null,
      },
      company: "",
    };
  },
  mounted() {
    var parameters = this.$route.query;
    console.log(this.$route);
    this.findSegments();
    this.load();
  },
  methods: {
    salesmanDetails(salesman) {
      window.open("./co/vendedor/" + salesman._id, "_self");
    },
    load() {
      var parameters = this.$route.query;
      let self = this;
      // console.log('sessionMixin load');
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      this.axios
        .get(server.url + "/api/auth/load/", server.config)
        .then(function (response) {
          let responseData = response.data.data;

          self.favoriteList = responseData.company.favourites;
          self.company = responseData.company;
          console.log("teste", self.favoriteList);
          // self.getCompany(responseData.company._id);
        })
        .catch(function (error) { });
    },
    getCompany(companyId) {
      var parameters = this.$route.query;
      let self = this;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      this.axios
        .get(server.url + "/api/company/" + companyId, server.config)
        .then(function (response) {
          let responseData = response.data.data;

          self.company = responseData.company;
        })
        .catch(function (error) { });
    },
    findSegments() {
      let self = this;
      this.axios
        .get(server.url + "/api/skill?filter=INDUSTRY_GENERAL", server.config)
        .then(function (response) {
          self.segmentList = [];
          let responseData = response.data.data.skill_list;
          self.segmentList = responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

@media only screen and (max-width: 600px) {
  .favorited-salesman {
    margin-top: 160px;
  }
}
</style>
