<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1 >Meus treinamentos</h1>
          </div>
          <div class="col-md-3 ">
            <button @click="addTraining()" class="">Adicionar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="training-my container">
      <div class="training-list">
        <!-- <h2 style="display: inline-block; padding-right: 15px;">Categoria 1</h2>
        <a href target="">
          <img src="/static/svg/icon_pencil_category.svg" alt="" class="" style="padding-bottom: 15px;"/>
        </a>
        
        <br />
        <hr> -->
        <h2 v-if="myTrainingList.length == 0">Você ainda não cadastrou nenhum treinamento</h2>
        <div class="row" style="padding-bottom: 45px">
          <div
            class="col-md-3"
            v-for="training in myTrainingList"
            :key="training._id"
          >
            <training-item :training="training" :grey= true :type=1></training-item>
          </div>
        </div>
        
      </div>

    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import { server } from "../../../js/variables.js";
import userMixin from '../../../mixins/userMixin.js'
import TrainingItem from "../../../components/TrainingItem.vue";
import moment from 'moment';

export default {
	mixins: [userMixin],
  components: {
    MainLayout,
    TrainingItem,
  },
  data() {
    return {
      myTrainingList: [
        // {_id: 1, name: 'Treinamento 1', teacher: 'Professor 1', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo', creation_date: '10/01/2020', lessons: 10, hours: 25, price: 15, students: 5},
        // {_id: 2, name: 'Treinamento 2', teacher: 'Professor 2', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo', creation_date: '10/01/2020', lessons: 10, hours: 25, price: 15, students: 5},
        // {_id: 3, name: 'Treinamento 3', teacher: 'Professor 3', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo', creation_date: '10/01/2020', lessons: 10, hours: 25, price: 15, students: 5},
        // {_id: 4, name: 'Treinamento 4', teacher: 'Professor 1', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo', creation_date: '10/01/2020', lessons: 10, hours: 25, price: 15, students: 5},
        // {_id: 5, name: 'Treinamento 1', teacher: 'Professor 1', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo', creation_date: '10/01/2020', lessons: 10, hours: 25, price: 15, students: 5},
        // {_id: 6, name: 'Treinamento 2', teacher: 'Professor 2', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo', creation_date: '10/01/2020', lessons: 10, hours: 25, price: 15, students: 5},
        // {_id: 7, name: 'Treinamento 3', teacher: 'Professor 3', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo', creation_date: '10/01/2020', lessons: 10, hours: 25, price: 15, students: 5},
        // {_id: 8, name: 'Treinamento 4', teacher: 'Professor 1', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo', creation_date: '10/01/2020', lessons: 10, hours: 25, price: 15, students: 5},
        // {_id: 9, name: 'Treinamento 1', teacher: 'Professor 1', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo', creation_date: '10/01/2020', lessons: 10, hours: 25, price: 15, students: 5},
        // {_id: 10, name: 'Treinamento 2', teacher: 'Professor 2', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo', creation_date: '10/01/2020', lessons: 10, hours: 25, price: 15, students: 5},
        // {_id: 11, name: 'Treinamento 3', teacher: 'Professor 3', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo', creation_date: '10/01/2020', lessons: 10, hours: 25, price: 15, students: 5},
        // {_id: 12, name: 'Treinamento 4', teacher: 'Professor 1', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo', creation_date: '10/01/2020', lessons: 10, hours: 25, price: 15, students: 5},
      ],
    };
  },
  mounted() {
    var parameters = this.$route.query;
    // this.getCourses();
  },
	watch:{
		user(newValue, oldValue){
      if(this.user._id){
        this.getCourses();
      }
		}
	},
  methods: {
    addTraining(){
      let self = this;
      const url = "/vc/meus-treinamentos/new";
      self.$router.push(url);
    },
    getCourses() {
      let self = this;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      this.axios
      .get(server.url + "/api/course/?user=" + self.user._id, server.config)
        .then(function (response) {
          // self.opportunityList = [];
          let responseData = response.data.data;
          self.myTrainingList = responseData.course_list;

          for(let course of self.myTrainingList){
            course.created_at = moment(course.created_at).add(4, 'hours').format('DD/MM/YYYY');
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";



.training-my {
  padding-top: 70px;
  h1 {
    text-align: center;
  }

  .add-btns{
    border: 1px solid #053360;
    border-radius: 6px;
    height: 90px;
  }

  .training-list {
    // padding-top: 60px;
    padding-bottom: 80px;

    .all-training-btn {
      float: right;
      width: 230px;
      height: 34px;
      color: var(--main-title-color);
    }
  }
}

@media only screen and (max-width: 600px) {
  .training-my {
    padding-top: 20px;
    .training-list {
      padding-bottom: 0px;
    }
  }
}
</style>
