<template>
<div>
    <div class="newsletter main-color-bg">
        <div class="row">
            <div class="offset-md-1 col-md-4">
                <h2>Newsletter</h2>
                <p>Cadastre-se e receba novidades</p>
            </div>

            <div class="input-group col-md-4">
                <input type="text" class="form-control" placeholder="Cadastrar Email" aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append rounded-left">
                    <button class="send-btn btn-outline-secondary" type="button">Enviar</button>
                </div>
            </div>

            <div class="col-md-2"></div>
        </div>
    </div>
</div>
</template>

<script>

export default {
  components: {
    
  },
  data() {
    return {
        showShareBox: false,
        url: window.location.href,
        contact: {
            name: '',
            email: '',
            subject: '',
            message: '',
        }
    };
  },
  methods: {
    
  }
};
</script>

<style lang="scss">

.newsletter {
	// margin: 10px;
	padding: 25px;
	
    h2{
        // text-align: center;
        color: var(--main-color-secundary);
    }
    p{
        text-align: left;
        // margin-bottom: 20px;
        color: white;
    }

    img{
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        display: block;
    }

    .send-btn{
        height: 38px;
        border-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-top: 5px;
        color: white;
    }

    
}



@media only screen and (max-width: 600px) {
  
}
</style>