<template>
  <main-layout>
    <!-- <pre> password {{ $v.login.password }}</pre>
	<pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container-fluid light-grey-bg">
      <div class="card-company menu-content-box">
        <h1 style="display: inline-block">Minha carteira</h1>
        <hr style="margin-top: 30px; margin-bottom: 22px" />

        <div class="row" style="padding-bottom: 40px; padding-top: 30px">
          <div class="col-md-4">
            <div class="col-md-12">
              <h2 class="center" style="margin-bottom: 30px;">Cartões cadastrados</h2>
            </div>
            <div
              v-for="card in cardList"
              :key="card.id"
              @click="cardDetails(card)"
              class="card-grey-box-company col-md-12"
            >
              <h4>
                <!-- {{ card.number.substring(0, 4) }}
                {{ card.number.substring(4, 8) }}
                {{ card.number.substring(8, 12) }}
                {{ card.number.substring(12, 16) }} -->
                **** **** **** {{card.last_four}}
              </h4>
              <p>
                {{ card.holder_name }} <br />
                Vencimento: {{ card.exp_month }}/{{ card.exp_year }}
              </p>
              <button class="btn-x" @click="removeCard(card)">X</button>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12">
                <h2>Cadastrar novo cartão</h2>
              </div>
              <div class="col-md-12">
                <input
                  type="text"
                  id="number"
                  placeholder="Número do cartão"
                  v-model="$v.card.number.$model"
                  v-mask="'################'"
                />
                <input
                  type="text"
                  id="holder_name"
                  placeholder="Nome impresso no cartão"
                  v-model="$v.card.holder_name.$model"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  id="exp"
                  placeholder="Data de vencimento"
                  v-mask="'##/##'"
                  v-model="$v.card.exp.$model"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  id="security_code"
                  placeholder="CVV"
                  v-model="$v.card.security_code.$model"
                />
              </div>
              <div class="col-md-12">
                <hr>
              </div>
              <div class="col-md-12">
                <h3>Dados do portador</h3>
                <br>
              </div>
              <div class="col-md-3">
                <v-select
                  class="remove-select-x"
                  style="margin-top: 3px;"
                  label="value"
                  :options="documentTypeList"
                  v-model="$v.documentType.$model"
                ></v-select>
              </div>
              <div class="col-md-9">
                <input
                  type="text"
                  id="holder_document"
                  placeholder="CPF"
                  v-model="$v.card.holder_document.$model"
                  v-mask="'###.###.###-##'"
                  v-if="documentType == 'CPF'"
                />
                <input
                  type="text"
                  id="holder_document"
                  placeholder="CNPJ"
                  v-model="$v.card.holder_document.$model"
                  v-mask="'##.###.###/####-##'"
                  v-else
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  id="holder_birthday"
                  placeholder="Data de nascimento"
                  v-model="$v.card.holder_birthday.$model"
                  v-mask="'##/##/####'"
                  v-if="documentType == 'CPF'"
                />
                <input
                  type="text"
                  id="holder_birthday"
                  placeholder="Data de fundação"
                  v-model="$v.card.holder_birthday.$model"
                  v-mask="'##/##/####'"
                  v-else
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  id="holder_phone"
                  placeholder="Telefone"
                  v-model="$v.card.holder_phone.$model"
                  v-mask="'(##) #####-####'"
                />
              </div>
              <div class="col-md-12">
                <hr>
              </div>
              <div class="col-md-12">
                <h3>Endereço de fatura</h3>
                <br>
              </div>
              <div class="col-md-12">
                <input type="checkbox" name='sameAsUser' id='sameAsUser' value="true" v-model="sameAsUser">
                <label for="sameAsUser"> Usar o mesmo cadastrado</label>
              </div>
              <div class="col-md-12">
                <input type="text" id="zipcode" placeholder="CEP*" v-model="$v.card.address.zipcode.$model"
                  v-mask="'#####-###'" v-on:blur="getAddress" v-on:keyup.enter="getAddress" :disabled="sameAsUser" />
              </div>
              <div class="col-md-9">
                <input type="text" id="zipcode" placeholder="Rua*" v-model="$v.card.address.street.$model" :disabled="sameAsUser" />
              </div>
              <div class="col-md-3">
                <input type="text" id="number" placeholder="nº" v-model="$v.card.address.number.$model" :disabled="sameAsUser" />
              </div>
              <div class="col-md-6">
                <v-select
                  label="sigla" 
                  :options="stateList"
                  v-on:input="findOpportunities('state')"
                  @blur="$v.card.address.state.$touch()"
                  :reduce="state => state.sigla" 
                  v-model="$v.card.address.state.$model"
                  :placeholder="'Estado'"
                  autocomplete="nope"
                  style="margin-top: 5px; margin-bottom: 10px;" 
                  :disabled="sameAsUser"
                >
                </v-select>
              </div>
              <div class="col-md-6">
                <input type="text" id="city" placeholder="Cidade*" v-model="$v.card.address.city.$model" :disabled="sameAsUser" />
              </div>
              <div class="col-md-6">
                <input type="text" id="district" placeholder="Bairro*" v-model="$v.card.address.district.$model" :disabled="sameAsUser" />
              </div>
              <div class="col-md-6">
                <input type="text" id="complement" placeholder="Complemento" v-model="$v.card.address.complement.$model" :disabled="sameAsUser" />
              </div>
              <div class="col-md-12">
                <button class="float-right" type="button " @click="saveCard()" v-if="card._id">
                  Salvar
                </button>
                <button class="float-right grey" type="button " style="margin-right: 10px" @click="cleanCard()" v-if="card._id">
                  Cancelar
                </button>
                <button class="float-right" type="button " @click="saveCard()" v-else>
                  Cadastrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/CompanyProfile.vue";
import { server } from "../../../js/variables.js";
import companyMixin from "../../../mixins/companyMixin.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  mixins: [companyMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      cardList: [
        // {id: 1, holder_name: 'Cartão teste um', number: '1234123412341234', exp: '10/2022'},
        // {id: 2, holder_name: 'Cartão teste dois', number: '1234123412341234', exp: '10/2022'},
        // {id: 3, holder_name: 'Cartão teste três', number: '1234123412341234', exp: '10/2022'},
      ],
      sameAsUser: true,
      card: {
        favorite: 0,
        number: "",
        security_code: "",
        last_four: "", // Não usa no cadastro
        first_six: "", // Não usa no cadastro
        holder_name: "",
        holder_document: "",
        holder_phone: '',
        holder_birthday: null,
        holder_additional_name: "", // Não usa no cadastro
        token: "", // Não usa no cadastro
        exp: "",
        exp_month: 0,
        exp_year: 0,
        brand: "",
        card_type: "",
        _id: "",
        address: {
          zipcode: '',
          street: '',
          number: '',
          complement: '',
          district: '',
          city: '',
          state: '',
          // _id: '',
        }
      },
      documentTypeList: ['CPF', 'CNPJ'],
      documentType: 'CPF',
    };
  },
  validations: {
    card: {
      favorite: "",
      number: "",
      security_code: "",
      last_four: "", // Não usa no cadastro
      first_six: "", // Não usa no cadastro
      holder_name: "",
      holder_document: "",
      holder_phone: '',
      holder_birthday: '',
      holder_additional_name: "", // Não usa no cadastro
      token: "", // Não usa no cadastro
      exp: "",
      exp_month: "",
      exp_year: "",
      brand: "",
      card_type: "",
      _id: "",
      address: {
        zipcode: '',
        street: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
        // _id: '',
      }
    },
    documentType: '',
  },
  mounted() {
    var parameters = this.$route.query;
    this.findPlaces();
    // self.load();
  },
  watch: {
    company(newValue, oldValue) {
      if (this.company.card && this.company.card.length > 0) {
        this.cardList = this.company.card;
        // this.card.holder_birthday = this.company.birthdate;

        for(let card of this.cardList){
          card.exp = card.exp_month + '/' + card.exp_year
        }
      }
    },
  },
  methods: {
    cardDetails(card) {
      // console.log('clicou', card)
      // this.card = card;
    },
    cleanCard() {
      this.card = {
        favorite: 0,
        number: "",
        security_code: "",
        last_four: "", // Não usa no cadastro
        first_six: "", // Não usa no cadastro
        holder_name: "",
        holder_document: "",
        holder_birthday: this.company.birthdate,
        holder_additional_name: "", // Não usa no cadastro
        token: "", // Não usa no cadastro
        exp: "",
        exp_month: 0,
        exp_year: 0,
        brand: "",
        card_type: "",
        _id: "",
      };
    },
    getAddress(){
      let self = this;
      if(!this.card.address.zipcode || this.card.address.zipcode.length < 9) return
      this.axios.get(  server.url + '/webservices/zipcode?zipcode=' + this.card.address.zipcode)
      .then(function (response) {
        let responseData = response.data.data
        self.card.address.city = responseData.localidade
        self.card.address.street = responseData.logradouro
        self.card.address.state = responseData.uf
        self.card.address.district = responseData.bairro
      })
      .catch(function (error) {
        self.output = error;
        self.showError(error);
      });
    },
    addCard(card) {
      let self = this;
      if (!this.$v.$invalid) {
        if (!localStorage.token) {
          return;
        }
        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };

        this.axios
          .post(
            server.url + "/api/company/" + this.company._id + "/card",
            {
              card: card,
            },
            server.config
          )
          .then(function (response) {
            location.reload();
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      } else {
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    updateCard(card) {
      let self = this;
      if (!this.$v.$invalid) {
        if (!localStorage.token) {
          return;
        }
        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };

        this.axios
          .put(
            server.url + "/api/company/" + this.company._id + "/card/" + card._id,
            {
              card: card,
            },
            server.config
          )
          .then(function (response) {
            location.reload();
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      } else {
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    saveCard() {
      let card = JSON.parse(JSON.stringify(this.card));
      
      if (this.sameAsUser && this.company.address.length == 0) {
          self.$swal({
            icon: 'warning',
            title: 'Atenção',
            text: 'Você ainda não cadastrou um endereço',
          })
          return
        }
        if(this.sameAsUser && this.company.address.length > 0) card.address = this.company.address[0]
      let exp = card.exp.split("/");
      if (!exp[1]) return;

      card.exp_month = exp[0];
      card.exp_year = exp[1];

      card.number = card.number.replace(/ /g, "");

      // this.card.card_type = "CREDIT";

      // this.card.holder_birthday   = this.convertDate( this.company.birthdate );
      card.holder_birthday   = this.convertDate( card.holder_birthday );

      if(card.holder_document.length != 14 && card.holder_document.length != 18){
        self.$swal({
          icon: 'warning',
          title: 'Atenção',
          text: 'Verifique o formato do documento',
        })
        return
      }else{
        if(card.holder_document.length == 14){
          let documentNoMask = card.holder_document.slice(0, 3);
          documentNoMask += card.holder_document.slice(4, 7);
          documentNoMask += card.holder_document.slice(8, 11);
          documentNoMask += card.holder_document.slice(-2);
          card.holder_document = documentNoMask;
        }

        if(card.holder_document.length == 18){
          let cnpjNoMask = card.holder_document.slice(0, 2);
          cnpjNoMask += card.holder_document.slice(3,6);
          cnpjNoMask += card.holder_document.slice(7,10);
          cnpjNoMask += card.holder_document.slice(11,15);
          cnpjNoMask += card.holder_document.slice(-2);
          card.holder_document = cnpjNoMask;
        }
      }

      if(card.holder_phone.length != 15){
        self.$swal({
          icon: 'warning',
          title: 'Atenção',
          text: 'Verifique o formato do telefone',
        })
        return
      }else{
        if(card.holder_phone.length == 15){
          let phoneNoMask = card.holder_phone.slice(1, 3);
          phoneNoMask += card.holder_phone.slice(5,10);
          phoneNoMask += card.holder_phone.slice(-4);
          card.holder_phone = phoneNoMask;
        }
      }

      // console.log('CARTAO ESTA AQUI', card)
      this.getBrand(card);

      if (!card.brand) {
        card.brand = "Master";
      }
      // this.card.brand = "Master";

      // if (card._id) {
      //   this.updateCard(card);
      // } else {
        this.addCard(card);
      // }
    },
    getBrand(card) {
      var ccNum = card.number.split(" ").join("");
      var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
      var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
      var amexpRegEx = /^(?:3[47][0-9]{13})$/;
      var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
      var isValid = false;

      //Bandeira do cartão (Visa / Master / Amex / Elo / Aura / JCB / Diners / Discover / Hipercard / Hiper).

      if (visaRegEx.test(ccNum)) {
        isValid = true;
        card.brand = "Visa";
      } else if (mastercardRegEx.test(ccNum)) {
        isValid = true;
        card.brand = "Master";
      } else if (amexpRegEx.test(ccNum)) {
        isValid = true;
        card.brand = "Amex";
      } else if (discovRegEx.test(ccNum)) {
        isValid = true;
        card.brand = "Discover";
      }

      if (!isValid) {
        card.brand = null;
      }
    },
    removeCard(cardToRemove) {
      let self = this;

      if (!localStorage.token) {
        return;
      }

      this.axios
        .delete(
          server.url +
            "/api/company/" +
            this.company._id +
            "/card/" +
            cardToRemove._id,
          server.config
        )
        .then(function (response) {
          self.$swal({
            icon: "success",
            title: "Cartão removido com sucesso",
            // text: answer.message,
            // footer: '<a href>Why do I have this issue?</a>'
          });
          location.reload();
        })
        .catch(function (error) {
          if (error.response) {
            var answer = error.response;
            self.$swal({
              icon: "error",
              title: "Erro atualizado o cartão",
              text: answer.message,
              // footer: '<a href>Why do I have this issue?</a>'
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
.card-company {
  

  h2 {
    margin-bottom: 30px !important;
  }

  .card-company-btns {
    width: 137px;
    height: 34px;
    color: var(--main-color-primary);
  }
}

.remove-card {
  width: 30px;
}

.card-grey-box-company {
  border-radius: 10px;
  background-color: var(--main-color-lightgrey2);
  padding: 40px;
  margin-bottom: 15px;
  // cursor: pointer;
  // height: fit-content;
}

@media only screen and (max-width: 600px) {
  .card-company {
    border-radius: 10px;
    padding: 20px;
    margin: 15px 0px;

    h1{
      text-align: center;
      width: 100%;
    }

    h2 {
      margin-bottom: 20px;
    }
  }
}
</style>
