<template>
  <main-layout>
    <!-- <pre> password {{ $v.user.password }}</pre>
	<pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container-fluid register-user">
      <div class="row">
        <div id="register-box" class="col-md-4 no-padding">
          <br /><br />
          <a href target="_self">
            <img src="/static/svg/logo_clean.svg" alt="" class="center" />
          </a>

          <h1>Cadastro</h1>
          <h3>do profissional</h3>
          <div class="inputs-register center">

            <div v-if="showingTerms">
              <terms-and-policy></terms-and-policy>
            </div>
            <div v-else>
              <input id="name" type="text" placeholder="Nome" v-model="$v.user.name.$model" />
              <!-- <span v-if="!$v.user.name.required">Campo Obrigatório</span> -->
              <input id="lastname" type="text" placeholder="Sobrenome" v-model="$v.user.lastname.$model" />
              <!-- <span v-if="!$v.user.lastname.required">Campo Obrigatório</span> -->

              <input id="phone" type="text" placeholder="Telefone" v-model="$v.user.phone.$model"
                v-mask="'(##) #####-####'" />
              <!-- <span v-if="!$v.user.phone">Verifique o formato do </span> -->
              <input id="email" type="text" placeholder="Login e-mail" v-model="$v.user.email.$model" />
              <span v-if="!$v.user.email">Verifique o formato do e-mail</span>

              <div class="password">
                <input id="password" :type="passwordInputType" v-model="$v.user.password.$model" placeholder="Digite sua senha">
                <span v-if="$v.user.password.$error">Campo obrigatório</span>
                <button class="clean" @click="showHidePassword()">
                  <i class="far fa-eye" v-if="passwordInputType === 'password'"></i>
                  <i class="far fa-eye-slash" v-if="passwordInputType === 'text'"></i>
                </button>
              </div>


              <div class="password">
                <input id="passwordConfirm" :type="passwordInputType" placeholder="Confirme sua senha"
                  v-model="$v.user.passwordConfirm.$model">
                <span v-if="$v.user.passwordConfirm.$error">As senhas nao coincidem</span> <button class="clean"
                  @click="showHidePassword()">
                  <i class="far fa-eye" v-if="passwordInputType === 'password'"></i>
                  <i class="far fa-eye-slash" v-if="passwordInputType === 'text'"></i>
                </button>
              </div>

              <!-- <input
                id="password"
                type="password"
                placeholder="Digite sua senha"
                v-model="$v.user.password.$model"
              />
               <span v-if="!$v.user.password.required">Campo Obrigatório</span> 
              <input
                id="confirmPassword"
                type="password"
                placeholder="Confirme sua senha"
                v-model="$v.user.passwordConfirm.$model"
              /> -->
            </div>

            <input type="checkbox" name='type' id='terms' v-model="checked" style="float: left">
            <label for="terms" @click="showTerms()"> Estou de acordo com os termos</label>

            <input type="checkbox" name='type' id='accept_phone' value="true" v-model="user.accept_phone">
            <label for="accept_phone"> Quero receber mensagens por WhatsApp</label>

            <!-- <input
              id="afiliate"
              type="text"
              placeholder="Código de afiliado (opcional)"
              v-model="$v.afiliate.$model"
            /> -->

            <!-- <button class="clean" @click="forgetPassword()">
                        Esqueci a senha
                    </button> -->
            <button class="blue float-right" style="margin-top: 15px" @click="createUser()">
              Cadastrar
            </button>
          </div>
          <div class="main-color-bg create-acount">
            <!-- <div class="center"> -->
            <p>Ja tem conta?</p>
            <a href="./login">Acessar</a>
            <!-- </div> -->
          </div>
        </div>
        <div class="col-md-8 no-padding">
          <!-- <img src="/static/img/vc-register.jpg" alt /> -->
          <div class="image-box" style="background-image: url(./static/img/vc-register.jpg)"></div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Basic.vue";
import { server } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";
import TermsAndPolicy from "../../../components/TermsAndPolicy.vue";

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
    TermsAndPolicy,
  },
  data() {
    return {
      slideList: [],
      user: {
        name: "",
        lastname: "",
        email: "",
        phone: '',
        password: "",
        confirmPassword: "",
        type: "SALESMAN",
        accept_phone: true,
      },
      passwordInputType: 'password',

      afiliate: '',
      checked: true,
      showingTerms: false,
      // confirmPassword: '',
    };
  },
  validations: {
    user: {
      name: { required },
      lastname: { required },
      email: { required },
      phone: '',
      password: { required },
      passwordConfirm: "",
      accept_phone: '',
    },
    afiliate: '',
    // passwordConfirm : { required, sameAs: sameAs('user.password') }
  },
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    // this.afiliate = this.$route.query.afiliado;
    if (localStorage.afiliate) {
      this.afiliate = localStorage.afiliate;
    }
  },
  methods: {
    createUser() {
      let self = this;
      let userCopy = JSON.parse(JSON.stringify(self.user));

      if (userCopy.password.length < 6) {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Senha deve ter no mínimo 6 caracteres',
          // footer: '<a href>Why do I have this issue?</a>'
        });
        return
      }

      let phone = userCopy.phone;
      if (phone)
        if (phone.length > 13) {
          let phoneNoMask = phone.substring(1, 3) + phone.substring(5, 10) + phone.substring(11, 15);
          userCopy.phone = phoneNoMask;
        }

      if (!this.$v.$invalid && this.checked) {

        this.axios
          .post(
            server.url + "/api/user",
            {
              user: userCopy,
              afiliate: self.afiliate,
            },
            server.config
          )
          .then(function (response) {
            let token = response.data.data.auth.token;
            let user = response.data.data.user;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            // window.open("./vc/dados", "_self");
            const url = "/vc/dados";
            self.$router.push(url);
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
            console.log(error);
          });
      } else {
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    showTerms() {
      this.showingTerms = !this.showingTerms;
    },
    showHidePassword(){
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    }
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

#register-box {
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888888;
    /* Cor do polegar da barra de rolagem */
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    /* Cor da trilha da barra de rolagem */
  }
}

.register-user {
  h1 {
    text-align: center;
    // font-size: 36px;
    margin-top: 12px;
    color: var(--main-text-color);
  }

  h3 {
    text-align: center;
    margin-bottom: 30px;
  }

  .image-box {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    height: 100%;
    width: 100%;
  }

  img {
    // margin-top: 20px;
    // margin-bottom: 60px;
    // padding-top: 70px;
    // padding-bottom: 30px;
  }

  .create-acount {
    height: 55px;
    width: 33.3%;
    text-align: center;
    position: fixed;
    bottom: 0px;

    p {
      color: white;
      display: inline-block;
      margin-right: 5px;
      margin-top: 14px;
    }

    a {
      color: var(--main-color-secundary);
      // display: inline-block;
    }
  }
  .password{
        position: relative;
        button{
          position: absolute;
          right: 0px;
          top: 7px;
        }
      }

  .inputs-register {
    width: 85%;
    margin-bottom: 155px;

    button {
      font-weight: normal;
      padding: 8px 20px;

      &:focus {
        outline: none;
      }
    }

    input {
      background-color: var(--main-color-lightgrey);
      border: none !important;
      height: 45px;
    }

    input[type="checkbox"] {
      width: 25px;
      height: 25px;
    }

  }
}

@media only screen and (max-width: 600px) {
  #register-box {
    height: auto;
  }

  .image-box {
    display: none;
  }

  .register-user .inputs-register {
    margin-bottom: 50px;
    position: relative;
  }

  .register-user .create-acount {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 1000;
  }
}
</style>
