<template>
  <main-layout>
    <div class="container salesman-details-company company-page">
      <div class="row box-padding light-grey-bg">
        <div class="col-md-2">
          <div v-if="salesman.image" class="user-frame">
            <img
              :src="getImageThumbUrl(salesman.image)"
              class="product-image-1"
              alt=""
            />
          </div>
          <div v-else class="user-frame" >
            <img class="user-img" src="/static/img/logos/logo-vcvende.jpg"  alt="">
          </div>
        </div>
        <div class="col-md-8">
          <h2 style="display: inline-block;">{{ salesman.name }} {{ salesman.lastname }}</h2>
          <img class="icon-favorite-salesman-details not-favorite-salesman-details" src="/static/svg/icon_favorite.svg" alt="" @click="addFavorite($event)" v-if="favorite == 0">
          <img class="icon-favorite-salesman-details" src="/static/svg/icon_favorite.svg" alt="" @click="removeFavorite($event)" v-if="favorite == 1">
          <h3 v-if="salesman.resume.title != ''" class="salesman-title">
            {{ salesman.resume.title }}
          </h3>
          <div class="company-city" v-if="salesman.resume.position">
            {{ positionType[salesman.resume.position] }}
          </div>
          <div class="company-city" v-if="salesman.address">
            {{ salesman.address[0].city }} | {{ salesman.address[0].state }}
          </div>
          <p>
            <b>Nivel:</b> {{ positionType[salesman.resume.position] }}
          </p>
          <p style="margin-top: 0px">
            <b>Idade:</b> {{ salesman.birthdate | calculateAge }} anos ({{
              salesman.birthdate}}) <br />
          </p>
        </div>
        <div class="col-md-2">
          <button class="blue full" @click="sendWhatsApp()">
            Enviar mensagem
          </button>
          <br /><br />
          <button class="full" @click="sendEmail()">Enviar email</button>
          <br /><br />
          <!-- <button class=" links"  v-on:click="goTo('./co/vendedores')">Ver candidatos</button> -->
          <!-- <br /><br />
          <button class="clean full" @click="openCatalogue()">
            Acessar catálogo
          </button> -->
          <br />
        </div>
      </div>

      <div class="row top-space">
        <div class="offset-md-2 col-md-10">
          <h2>Apresentação</h2>
          <p style="white-space: break-spaces">{{ salesman.resume.summary }}</p>
        </div>
      </div>

      <div class="row top-space" v-if="salesman.resume.experience.length">
        <div class="offset-md-2 col-md-10">
          <h2>Experiência Profissional</h2>
        </div>
        <br />
      </div>

      <div
        v-for="experience in salesman.resume.experience"
        :key="experience._id"
        class="row experience top-space"
      >
        <div class="col-md-2">
          <h4 class="date">
            {{ experience.start_date | formatDate }} -
            {{ experience.end_date | formatDate }} <br />
            Período: {{ employmentType[experience.employment_type] }}
          </h4>
        </div>
        <div class="col-md-10 box-padding light-grey-bg">
          <div>
            <h3>
              {{ experience.title }} - {{ positionType[experience.position] }}
            </h3>
            <h4 class="company">
              {{ experience.company }}
            </h4>

            <p class="summary" v-html="experience.description"></p>
          </div>
        </div>
      </div>

      <div class="row top-space" v-if="salesman.resume.education.length">
        <div class="offset-md-2 col-md-10">
          <h2>Experiência Acadêmica</h2>
        </div>
        <br />
      </div>

      <div
        v-for="education in salesman.resume.education"
        :key="education._id"
        class="row experience top-space"
      >
        <div class="col-md-2">
          <h4 class="date">
            {{ education.start_date | formatDate }} -
            {{ education.end_date | formatDate }} <br />
          </h4>
        </div>
        <div class="col-md-10 box-padding light-grey-bg">
          <div>
            <h3>{{ education.field_of_study }} - {{ education.degree }}</h3>
            <h4 class="company">
              {{ education.scholl }}
            </h4>

            <p class="summary" v-html="education.description"></p>
          </div>
        </div>
      </div>

      <div class="row top-space" v-if="salesman.resume.skills.length">
        <div class="offset-md-2 col-md-10">
          <h2>Habilidades</h2>
        </div>
        <br />
      </div>

      <div
        class="row top-space"
        v-if="filterBySkillType('INDUSTRY_GENERAL').length"
      >
        <div class="col-md-2">
          <h3 class="date">Segmentos <br /></h3>
        </div>
        <div class="col-md-10 box-padding light-grey-bg">
          <div
            v-for="skill in filterBySkillType('INDUSTRY_GENERAL')"
            :key="skill.skill._id"
            class="row"
          >
            <div class="col-md-6">
              <h3>{{ skill.skill.name }}</h3>
            </div>

            <div class="col-md-6">
              {{ proficiencyLevelSegment[skill.proficiency] }}
            </div>
            <br /><br />
          </div>
        </div>
      </div>

      <br /><br />

      <div class="row top-space" v-if="filterBySkillType('LANGUAGE').length">
        <div class="col-md-2">
          <h3 class="date">Idiomas <br /></h3>
        </div>
        <div class="col-md-10 box-padding light-grey-bg">
          <div
            v-for="skill in filterBySkillType('LANGUAGE')"
            :key="skill.skill._id"
            class="row"
          >
            <div class="col-md-6">
              <h3>{{ skill.skill.name }}</h3>
            </div>

            <div class="col-md-6">
              {{ proficiencyLevelLanguage[skill.proficiency] }}
            </div>
            <br /><br />
          </div>
        </div>
      </div>
    </div>

    <div class="container salesman-details-company company-page">
      <hr />
      <div class="row top-space">
        <div class="col-md-12">
          <!-- <h2>Produtos que representa</h2> -->
        </div>
        <div
            class="col-6 col-md-4"
            v-for="product in productList"
            :key="product._id"
        >
            <product-item :product="product" :type="0"> </product-item>
        </div>
      </div>
      
    </div>
  </main-layout>
</template>

<script>
import companyMixin from "../../../mixins/companyMixin.js";
import MainLayout from "../../../layouts/Company.vue";
import { server } from "../../../js/variables.js";
import ProductItem from "../../../components/ProductItem.vue";
import moment from 'moment';

export default {
  mixins: [companyMixin],
  components: {
    MainLayout,
    ProductItem,
  },
  data() {
    return {
      productList: [],
      salesman: {
        name: "",
        lastname: "",
        company: "",
        city: "",
        resume: {
          summary: "",
        },
      },
      requestList: [],
      salesmanList: [],
      favorite: 0,
    };
  },
  mounted() {
    var parameters = this.$route.query;
    this.getSalesman();
    // this.getSalesmanRepresentative();
    // this.findProducts();
  },
  watch:{
    company(newValue, oldValue){
      this.checkFavorite();
    }
  },
  methods: {
    getSalesman() {
      let self = this;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      this.axios
        .get(server.url + "/api/user/" + this.$route.params.id, server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.salesman = responseData.user;
          self.salesman.birthdate = moment(self.salesman.birthdate).add(4, 'hours').format('DD/MM/YYYY');
          self.findProducts();
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    sendWhatsApp() {
      let self = this;
      var id = this.$route.params.id;
      if (!localStorage.token) {
        self.$swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'Você precisa estar logado para realizar está ação',
        })
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      this.axios
        .get(server.url + "/api/user/" + id + "/call", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          window.open(
            "https://api.whatsapp.com/send?1=pt_BR&phone=55" +
              responseData.user.phone,
            "_blank"
          );
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    sendEmail() {
      let self = this;
      var id = this.$route.params.id;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      this.axios
        .get(server.url + "/api/user/" + id + "/email", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          window.open("mailto:" + responseData.user.email, "_blank");
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    getSalesmanRepresentative() {
      let self = this;
      var id = this.$route.params.id;

      this.axios
        .get(
          server.url + "/api/sales-representative/?salesman=" + id,
          server.config
        )
        .then(function (response) {
          let responseData = response.data.data;
          self.requestList = [];
          for (let salesman of responseData.representation_list) {
            if (salesman.status == "AWAIT") {
              // self.requestList.push(salesman);
            } else if (salesman.status == "APPROVED" && salesman.product) {
              self.salesmanList.push(salesman);
              // self.addSalesmanIfNotExists(salesman)
            }
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    findProducts() {
      let self = this;

      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        // salesman: this.$route.params.id,
        salesman: this.salesman._id,
        company: this.company._id,
      };

      if (!this.showAll) {
        header["params"].approved = 1;
        header["params"].visible = 1;
      }

      this.axios
        .get(server.url + "/api/product/", header)
        .then(function (response) {
          let responseData = response.data.data;
          self.companyList = [];

          if (self.page == 0) {
            self.productList = [];
          }

          self.productList = self.productList.concat(responseData.product_list);
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    openCatalogue() {
        // const url = "/catalogo/" + this.$route.params.id;
        // this.$router.push(url);
        window.open("./catalogo/" + this.$route.params.id, "_blank");
    },
    filterBySkillType(type) {
      var list = [];

      for (let skill of this.salesman.resume.skills) {

        if (skill.skill.type == type) {
          list.push(skill);
        }
      }

      return list;
    },
    checkFavorite() {
      if(!this.company.favourites || this.company.favourites.length == 0) {
        this.favorite = 0;
        console.log("checkFavorite - not found");
        return;
      }
      for (let p of this.company.favourites ){
        if (p._id == this.salesman._id) {
          this.favorite = 1;
          console.log("checkFavorite - FOUND");
          return;
        }
      }
      this.favorite = 0;
      console.log("checkFavorite - not found");
    },
    addFavorite(event) {
      var parameters = this.$route.query;
      let self = this;
      if(event.stopPropagation) event.stopPropagation();
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }
      // this.loading = true;
      this.axios.put(  server.url + '/api/company/' + this.company._id + '/salesman/' + this.salesman._id, server.config)
      .then(function (response) {
        self.favorite = 1;
        // this.loading = false;
      })
      .catch(function (error) {
          
      });
        
    },
    removeFavorite(event) {
      var parameters = this.$route.query;
      let self = this;
      if(event.stopPropagation) event.stopPropagation();
      // console.log("add favorite", this.company._id, this.salesman._id)
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }
      
      this.axios.delete(  server.url + '/api/company/' + this.company._id + /salesman/ + this.salesman._id, server.config)
      .then(function (response) {
        self.favorite = 0;
      })
      .catch(function (error) {
          
      });
        
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.salesman-details-company {
  // padding: 25px 35px;

  .links{
    background-color: transparent;
    color: var( --main-color-primary-dark);
    text-decoration: underline;
    width: 150px;
 
  }

  .blue {
    // width: 140px;
    // height: 32px;
    // font-size: 14px;
  }

  .icon-favorite-salesman-details{
    height: 20px;
    margin-left: 10px;
    margin-bottom: 6px;
    cursor: pointer;
  }

  .not-favorite-salesman-details{
    filter: invert(65%) sepia(100%) saturate(0%) hue-rotate(178deg) brightness(150%) contrast(100%)
  }

  .salesman-title {
    color: var(--main-color-secundary);
    font-size: 23px;
    margin-top: 5px;
  }

  h3 {
  }

  .clean {
    color: var(--main-color-primary);
    text-align: right;
    width: 140px;
    height: 32px;
    margin-top: 6px;
  }

  .company-city {
    color: var(--main-color-darkgrey);
    font-size: 15px;
    font-weight: bold;
    margin-top: 5px;
  }

  p {
    margin-top: 16px;
  }

  .top-space {
    margin-top: 30px;
  }

  .experience {
    h3 {
      font-size: 14pt;
      line-height: 16pt;
      // color: var(--main-color-darkgrey);
      // font-weight: 100;
    }

    h4.company {
      font-size: 13pt;
      line-height: 15pt;
      color: var(--main-color-primary);
    }

    h4.date {
      font-size: 10pt;
      line-height: 15pt;
      color: var(--main-color-darkgrey);
      font-weight: 100;
    }

    p {
      margin-top: 0px;
      white-space: break-spaces;
      margin-bottom: 30px;
    }

    
    .summary{
      p{ 
        margin-bottom: 0px;
      }
    }
  }
}
</style>
