export const localPath = '/commerce'

// const url_local = "http://167.172.195.160:4051";
// const url_dev = "https://63d0770072db.ngrok.io"
const hom_server = "https://vocevende.oceantek.com.br"
const prod_server = "https://vocevende.com"

export const local = {
  user: {},
  company: {},
  subscription: {},
  permissions: {},
  subscriptionList: [],
  cartList: [],
  studentList: [],
}

export const server = {
  url: prod_server,
  img_url: prod_server,

  username: "",
  password: "",

  config: {
    headers: {
      // 'Authorization': "Basic NTNjNWFiOGMtYjdlZS00ZWQzLTk5ZDYtMjFmMTBiMGJiN2ZmOkIwc2NIQE9jZWFudGVr" 

    }
  }
}
export const settings = {
  some: 'Settings'
}

// reportagem
// atrnp  associados 