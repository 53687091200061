<template>
  <div id="opportunity-finder" class="container">
    <div class="row top-space">
      <aside
        id="filter-sidebar"
        class="col-md-3"
        v-bind:class="{ open: isFilterOpen() }"
      >
        <div class="rounded light-grey-bg filter-sidebar">
          <div class="sidebar-title rounded main-color-bg">
            <img class="icon-left" src="/static/svg/icon_search.svg" alt="" />
            <h2 class=" ">Refine sua busca</h2>
            <!-- <input type="text" class="searchText" placeholder="Refine sua busca" v-model="filter.searchText" v-on:keyup.enter="findOpportunities" > -->
          </div>

          <input
            type="text"
            class="searchText"
            placeholder="busca por texto"
            v-model="search.searchText"
            v-on:keyup.enter="findOpportunities()"
          />
          <!-- <hr> -->
          <div class="filter-group">
            <h3>Estado</h3>
            <v-select
              label="sigla" 
              :options="stateList"
              v-on:input="findOpportunities('state')"
							:reduce="state => state.sigla" 
              v-model="search.state"
              :placeholder="'(Selecione)'"
              autocomplete="nope"
            >
            </v-select>
          </div>

          <div class="filter-group">
            <h3>Cidade</h3>
            <v-select
              label="nome" 
              :options="cityList"
              v-on:input="findOpportunities('city')"
              :reduce="city => city.nome" 
              v-model="search.city"
              :placeholder="cityPlaceholder"
              :disabled="cityPlaceholder == 'Selecione estado' || cityPlaceholder == 'Carregando...'"
              autocomplete="nope"
            >
            </v-select>
          </div>

          <div class="filter-group">
            <h3>Cargo</h3>
            <v-select
              label="label"
              :options="positionList"
              v-on:input="findOpportunities()"
              v-model="search.position"
              :reduce="(position) => position.value"
              :placeholder="'Cargo'"
            >
            </v-select>
          </div>

          <div class="filter-group">
            <h3>Segmento</h3>
            <v-select
              label="name"
              :options="segmentList"
              v-on:input="findOpportunities()"
              v-model="search.segment"
              :reduce="(segment) => segment._id"
              :placeholder="'Segmento'"
            >
            </v-select>
          </div>

          <!-- <hr />
          <div class="filter-group">
            <img class="icon-left" src="/static/svg/icon_category.svg" alt="" />
            <h3>Categoria</h3>
          </div> -->
          <button
            class="blue center"
            style="margin-top: 20px"
            @click="cleanFilter()"
          >
            Limpar filtro
          </button>
        </div>
        <br />

        <div class="d-md-none d-lg-none d-xl-none">
          <button class="big center" @click="openFilter(false)">
            Confirmar
          </button>
        </div>
      </aside>

      <div class="col-md-9">
        <div class="d-md-none d-lg-none d-xl-none col"  style="margin: 20px 0px 20px;">
          <button class="col blue-border bigcenter" @click="openFilter(true)">
            Filtrar
          </button>
        </div>

        <div class="row opportunity-list">
          <div
            class="col-12 col-md-6"
            v-for="opportunity in opportunityList"
            @click="opportunityNow = opportunity"
            :key="opportunity._id"
            data-toggle="modal"
            data-target="#opportunityDetailsModal"
          >
            <opportunity-item :opportunity="opportunity" :typeCard="typeCard"  :type="verifySubscribed(opportunity)">
            </opportunity-item>
          </div>
        </div>

        <div style="margin-top: 30px" v-if="canLoadMore">
          <!-- <center>
            <small v-if="opportunityList">
              {{ maxItems }} de {{ opportunityList.length }}
            </small>
          </center> -->
          <button class="blue-border big" @click="loadMore()" style="width: 100%; margin: 20px 0px;">
            Carregar mais
          </button>
        </div>
      </div>
    </div>

    <opportunity-details-modal
      :modal_id="'opportunityDetailsModal'"
      :opportunity="opportunityNow"
      :showCandidateButton="showCandidateButtonFinder"
    ></opportunity-details-modal>
  </div>
</template>

<script>
import { server, local } from "../js/variables.js";
import userMixin from "../mixins/userMixin.js";
// import CompanyList from "../components/CompanyList.vue";
import OpportunityItem from "./OpportunityItem.vue";
import moment from 'moment';
import OpportunityDetailsModal from "../components/OpportunityDetailsModal.vue";

export default {
  mixins: [userMixin],
  props: ["salesman", "company", "showAll", "showCompanyList", 'showCandidateButtonFinder', 'typeFinder', 'typeCard'],
  components: {
    OpportunityItem,
    OpportunityDetailsModal,
    // CompanyList,
  },
  data() {
    return {
      endpoint: "/api/opportunity/",
      opportunityList: [],
      opportunityNow: null,
      groupList: [],
      opportunityListFiltered: [],
      // positionList: [
      //   { _id: 1, name: "Estagiário", value: "INTERN" },
      //   { _id: 2, name: "Júnior", value: "JUNIOR" },
      //   { _id: 3, name: "Pleno", value: "FULL" },
      //   { _id: 4, name: "Sênior", value: "SENIOR" },
      //   { _id: 5, name: "Coordenador", value: "COORDINATOR" },
      //   { _id: 6, name: "Gerente", value: "MANAGER" },
      //   { _id: 7, name: "Diretor", value: "DIRECTOR" },
      // ],
      search: {
        searchText: null,
        state: null,
        city: null,
        segment: null,
        position: null,
        commission_from: null,
        commission_to: null,
        companyId: null,
        list: [],
        segmentlist: [],
        placelist: [],
        grouplist: [],
      },
      companyList: [],
      maxItems: 6,
      pageItems: 6,
      page: 0,
      mobileShowFilter: false,
      canLoadMore: true,
      
    };
  },
  mounted() {
    this.findPlaces();
    this.findSegments();
  },
  created() {
    this.endpoint = "/api/job-opportunity/"; // all opportunitys

    if (this.company) {
      this.endpoint = "/api/company/" + this.company + "/job-opportunity/"; // company opportunitys
      // this.findGroups();
    }

    this.findOpportunities();
  },
  updated() {
    var newEndPoint = "/api/job-opportunity/"; // all opportunitys
    if (this.company) {
      newEndPoint = "/api/company/" + this.company + "/opportunity/"; // company opportunitys
    }

    if (newEndPoint !== this.endpoint) {
      this.endpoint = newEndPoint;
      this.findOpportunities();
    }
  },
  methods: {
    loadMore() {
      this.maxItems = this.maxItems + this.pageItems;
      this.page = this.page + 1;
      this.findOpportunities();
    },
    opportunityDetails(opportunity) {
      const url = "/vc/vaga/" + opportunity._id;
      this.$router.push(url);
    },
    findOpportunities(type) {
      let self = this;

      if(!this.search.state){
        this.cityList = [];
        this.search.city = null;
        this.cityPlaceholder = 'Selecione estado';
      }else if(this.search.state && type == 'state'){
        this.cityList = [];
        this.search.city = null;
        // this.cityPlaceholder = 'Selecione estado';
        this.findCities(this.search.state);
      }else if(this.search.state && type == 'city'){
        // this.findCities(this.search.state);
      }
      
      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        limit: this.pageItems,
        page: this.page,
        state: this.search.state,
        city: this.search.city,
        segment: this.search.segment,
        position: this.search.position,
        filter: this.search.searchText,
      };

      if (!this.showAll) {
        header["params"].approved = 1;
        header["params"].visible = 1;
      }

      // console.log(header["params"]);

      this.axios
        .get(server.url + this.endpoint, header)
        .then(function (response) {
          let responseData = response.data.data;
          self.companyList = [];

          if (self.page == 0) {
            self.opportunityList = [];
          }

          self.opportunityList = self.opportunityList.concat( responseData.job_opportunity_list );

          for(let opportunity of self.opportunityList){
            opportunity.created_at = moment(opportunity.created_at).add(4, 'hours').format('DD/MM/YYYY');
          }

          if (responseData.job_opportunity_list.length != self.pageItems) {
            self.canLoadMore = false;
          } else {
            self.canLoadMore = true;
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    verifySubscribed(opportunity){
      let answer = 1;
      for(let candidate of opportunity.candidates){
        if(localStorage.user){
          let user = JSON.parse(localStorage.user);
          if(candidate.status != 'CANCELED' && candidate.user._id == user._id){
            answer = 2;
          }
        }
      }
      return answer;
    },
    findGroups() {
      let self = this;

      let header = JSON.parse(JSON.stringify(server.config));

      this.axios
        .get(
          server.url + "/api/company/" + self.company + "/opportunity-group/",
          header
        )
        .then(function (response) {
          let responseData = response.data.data;
          self.groupList = responseData.opportunity_group_list;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    filterStateCity() {
      let self = this;
      let state = self.search.state;
      let city = self.search.city;
      let segment = self.search.segment;

      let query = state ? "state=" + state : "";
      query += query && city ? "&" : "";
      query += city ? "city=" + city : "";
      query += query && segment ? "&" : "";
      query += segment ? "segment=" + segment : "";
      query = query ? "?" + query : "";

      this.axios
        .get(server.url + "/api/user" + query, server.config)
        .then(function (response) {
          self.salesmanList = [];
          let responseData = response.data.data.user_list;
          for (let item of responseData) {
            self.salesmanList.push(item);
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    companySelected(company) {
      this.search.companyId = company._id;
      this.findOpportunities();
    },
    isFilterOpen() {
      return this.mobileShowFilter;
    },
    openFilter(status) {
      this.mobileShowFilter = status;
    },
    groupClick(opportunityGroup) {
      this.$emit("groupClick", opportunityGroup);
    },
    check() {
      this.findOpportunities();
      // this.filterMyList()
      // console.log(this.filter.list);
    },
    cleanFilter() {
      let self = this;
      let filterClean = {
        searchText: null,
        position: null,
        city: null,
        state: null,
        segment: null,
        commission_to: null,
        companyId: null,
        list: [],
        segmentlist: [],
        placelist: [],
        grouplist: [],
      };
      self.search = filterClean;
      this.findOpportunities();
    },
  },
};
</script>

<style lang="scss" scoped>
#collapse-place,
#collapse-segment li {
  margin-top: 5px;
  position: relative;
}

#filter-sidebar {
  h3 {
    margin-bottom: 5px;
  }
}

table#commision-range {
  border-spacing: 10px;
  border-collapse: separate;

  tr {
    td {
      margin-left: 5px;

      label {
        color: var(--main-color-mediumgrey);
      }

      input {
        padding: 12px 5px !important;
        text-align: center;
      }
    }
  }
}

.searchText {
  letter-spacing: 0.3px;
  margin-bottom: 20px;

  &:focus {
    outline: none;
  }
}


@media only screen and (max-width: 600px) {
}
</style>