<template>
  <div class="current-plan" style="margin-top:25px" v-if="mySubscriptionList.length > 0">
    <!-- <hr> -->

    <!-- <h3>Planos vigentes</h3> -->
    <div style="display: block;">
      <div class="row" style="display: block;">
        <div class="col-md-12" v-for="subscription in mySubscriptionList"  :key="subscription._id">
          <p>{{subscription.plan.name}} {{getPlanType(subscription)}} - assinatura em {{subscription.created_at | formatDate}} <span v-if="getPlanPrice(subscription)">-</span> <span v-if="subscription.plan.payment_option.length > 0">{{getPlanPrice(subscription)*subscription.amount | toCurrency}}</span> <span style="font-weight: 700" v-if="subscription.cancel_at">- cancalamento programado para {{subscription.cancel_at | formatDate}}</span></p>
        </div>
      </div>
    </div>
    <!-- <hr> -->
  </div>
</template>

<script>
import { server, local } from "../js/variables.js";

export default {
  props: ["mySubscriptionList"],
  components: {},
  data() {
    return {
      paymentMonthType: {
        1: 'mensal',
        3: 'trimestral',
        6: 'semestral',
        12: 'anual',
      },
    };
  },
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    
  },
  methods: {
    getPlanType(subscription) {
      return this.paymentMonthType[subscription?.plan_payment?.interval] ?? ''
    },
    getPlanPrice(subscription) {
      return subscription?.plan_payment?.amount ?? ''
    },
  },
};
</script>

<style lang="scss">
.current-plan{
  // position: absolute;
  // border-radius: 18px;
  margin-bottom: 5px;
  padding: 0px;
  // border: 1px solid var(--main-color-mediumgrey);
  // top: 10px;
  // left: 275px;

  h3{
    margin-bottom: 10px
  }

  p{
    line-height: 12pt;
  }
}

@media only screen and (max-width: 600px) {
  .current-plan{
    position: initial;
    border-radius: 18px;
    margin-top: 18px;
    margin-bottom: 0px;
    padding: 0px;
    border: 0px solid var(--main-color-mediumgrey);
    top: 10px;
    left: 275px;

    h3{
      margin-bottom: 10px
    }

    p{
      line-height: 12pt;
    }
  }
}
</style>