<template>
  <main-layout>
    <div class="container section catalog-cart">
      <div class="row">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-6 d-none d-md-block d-lg-block">
              <h3>Descrição</h3>
            </div>
            <div class="col-md-4 d-none d-md-block d-lg-block">
              <h3 style="text-align: center">Quantidade</h3>
            </div>
            <div class="col-md-2 d-none d-md-block d-lg-block">
              <h3 style="text-align: center">Remover</h3>
            </div>
            <div
              class="col-md-12"
              style="margin-top: 12px"
              v-for="item in cart"
              :key="item._id"
            >
              <div class="light-grey-bg cart-item row">
                <div class="col-md-2">
                  <img
                    :src="getImageThumbUrl(item.product.image)"
                    v-if="item.product.image"
                    class="product-image-1"
                    alt=""
                  />
                </div>
                <div class="col-md-4">
                  <h3>{{ item.product.name }}</h3>
                  <div class="light-yellow-line"></div>
                  <h4>{{ item.product.groups.name }}</h4>
                  <p>
                    Preço venda: <b>{{ item.product.price | toCurrency }}</b>
                  </p>
                  <!-- <p>Comissão: <b>{{item.product.sales_commission}}%</b></p> -->
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="col-md-4">
                  <div class="cart-item-amount">
                    <button class="clean" @click="minusButton(item)">-</button>
                    {{ item.amount }}
                    <button class="clean" @click="plusButton(item)">+</button>
                  </div>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="col-md-2 d-none d-md-block d-lg-block">
                  <button class="clean remove-btn" @click="removeItem(item)">
                    X
                  </button>
                </div>
                <button class="clean remove-btn d-block d-md-none d-lg-none" @click="removeItem(item)">
                  X
                </button>
                
              </div>
            </div>
            <div
              class="col-md-12"
              style="margin-top: 12px"
              v-if="cart.length == 0"
            >
            <h2>Você ainda nao adicionou nenhum item ao carrinho.</h2>
            </div>
            <div class="col-md-12" style="text-align: center">
              <button class="blue" @click="goToSalesmanCatalog()">Continuar adicionando itens</button>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <h3>Resumo</h3>
          <div class="resume-box">
            <h3>Todos os itens</h3>
            <div class="light-yellow-line"></div>
            <div class="amount-itens">
              {{ cart.length }}
            </div>
            <p>
              As seleções de sua lista serão encaminhadas para o vendedor desta loja, que vai receber seu pedido por e-mail e em breve entrará em contato com você para efetivar sua compra, deixe seu e-mail, nome e telefone de contato.
            </p>
            <label for="">E-mail</label>
            <input type="text" v-model="email" placeholder="Seu e-mail" />
            <label for="">Mensagem</label>
            <textarea  type="text" v-model="name" placeholder="Nome, telefone e mensagem" />
            <button @click="sendRequest" >Enviar orçamento</button>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Public.vue";
// import Contact from "../components/Contact.vue";
import { server } from "../js/variables.js";

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
    // Contact,
  },
  data() {
    return {
      cart: [
        // {amount: 2, product:{name: 'Produto 1', price: 50, sales_commission: 12, groups:[{name: 'Categoria Um'}], image: null}},
        // {amount: 1, product:{name: 'Produto 2', price: 60, sales_commission: 5, groups:[{name: 'Categoria Dois'}], image: null}},
        // {amount: 5, product:{name: 'Produto 3', price: 10, sales_commission: 5, groups:[{name: 'Categoria Um'}], image: null}},
        // {amount: 1, product:{name: 'Produto 4', price: 70, sales_commission: 5, groups:[{name: 'Categoria Dois'}], image: null}},
      ],
      email: "",
      name: ''
    };
  },
  validations: {},
  mounted() {
    var parameters = this.$route.query;

    this.cart = self.$cookies.get("cart-" + this.$route.params.id);
    if (!this.cart) this.cart = [];
    else this.cart = JSON.parse(this.cart);
    // this.cart.push(answer.message);
    // self.$cookies.set( 'cart', JSON.stringify( this.cart) );
  },
  methods: {
    getSalesman() {
      let self = this;
      // if (!localStorage.token) {
      // 	return
      // }
      // server.config.headers = {
      // 	Authorization: 'Bearer ' + localStorage.token
      // }

      this.axios
        .get(server.url + "/api/user?limit=6", server.config)
        .then(function (response) {
          self.salesmanList = [];
          let responseData = response.data.data;
          // self.salesmanList = responseData.user_list;
          for (let salesman of responseData.user_list) {
            if (salesman.type == "SALESMAN" && salesman.resume) {
              self.salesmanList.push(salesman);
            }
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    sendRequest(){
      let self = this;

      const data = {
        cart:  this.cart,
        email: this.email,
        name: this.name,
        salesman: this.$route.params.id
      }

      this.axios
        .post(server.url + "/api/order/budget", data,  server.config)
        .then(function (response) {
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: response.data.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
            }).then((result) => {
            if (result.isConfirmed) {
              self.$cookies.remove("cart-" + self.$route.params.id);
              self.goToSalesmanCatalog();
            }
          })

        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    salesmanDetails(salesman) {
      console.log("salesman details", salesman);
    },
    plusButton(item) {
      let index = this.cart.indexOf(item);
      this.cart[index].amount += 1;
      self.$cookies.set("cart-" + this.$route.params.id, JSON.stringify(this.cart));
    },
    minusButton(item) {
      let index = this.cart.indexOf(item);
      if (this.cart[index].amount == 1) {
        this.removeItem(item);
      } else {
        this.cart[index].amount -= 1;
      }
      self.$cookies.set("cart-" + this.$route.params.id, JSON.stringify(this.cart));
    },
    removeItem(item) {
      let index = this.cart.indexOf(item);
      this.cart.splice(index, 1);
      self.$cookies.set("cart-" + this.$route.params.id, JSON.stringify(this.cart));
    },
    goToSalesmanCatalog() {
      // window.open("/catalogo/" + this.$route.params.id, "_self");
      const url = "/catalogo/" + this.$route.params.id;
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
// @import "../scss/reset.scss";
// @import "../scss/base.scss";

.catalog-cart {
  p {
    line-height: 16pt !important;
  }
  .cart-item {
    border-radius: 18px;
    padding: 25px 20px;
    margin-top: 6px;
    margin-bottom: 12px;

    h4 {
      font-size: 14px;
      margin-bottom: 12px;
    }

    .clean {
      font-size: 28px;
      font-weight: bold;
    }

    .cart-item-amount {
      font-size: 28px;
      font-weight: bold;
      color: var(--main-color-primary);
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }

    button {
      color: var(--main-color-primary);
    }

    .remove-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }

  .blue {
    width: 250px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .resume-box {
    background-color: var(--main-color-primary);
    border-radius: 12px;
    margin-top: 12px;
    padding: 30px 38px;
    position: relative;

    h3 {
      color: white;
    }

    p {
      margin-top: 20px;
      margin-bottom: 15px;
      color: white;
    }

    .amount-itens {
      color: var(--main-color-secundary);
      font-size: 25px;
    }

    button {
      position: absolute;
      right: 49px;
      bottom: -17px;
      width: auto;
    }

    label{
      color: white;
    }
  }
}


@media only screen and (max-width: 600px) {
  .catalog-cart{
    .cart-item{
      border-radius: 0px;
      .cart-item-amount {
        position: inherit;
        top: 50%;
        left: 40%;
        margin-right: 0px;
        transform: none;
      }

      button {
        color: var(--main-color-primary);
      }

      .remove-btn{
        top: 7px;
        left: initial;
        right: 10px;
        margin-right: 0px;
        transform: initial;
      }
    }
  }
}
</style>
