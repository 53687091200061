<template>
  <main-layout>
    <!-- <pre> password {{ $v.postAddress }}</pre> -->
    <!-- <pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container-fluid no-padding">
      <!-- <img src="/static/img/profile-top.jpg" alt style="width: 100%" /> -->
      <div class="light-grey-bg">
        <div class="container company-view-top">
          <div class="row">
            <div class="col-md-3" style="height: 200px">
              <div v-if='company.image' class="store-picture-frame">
                <img :src="getImageThumbUrl(company.image)" class="" alt="">
              </div>
            </div>
            <div class="col-md-6" style="margin-left: 23px;">
              <h1>{{ company.name_fancy }}</h1>
              <h3>{{ company.segment.name }}</h3>
              <p style="padding-top: 10px">{{ company.description }}</p>
            </div>
          </div>

        </div>
      </div>
      <div class="company-details-view">
        <product-finder class="top-space" :company="company._id" :showAll=false :showCompanyList=false
          v-if="company._id"></product-finder>
        <div class="hr-yellow"></div>
        <div class="opportunities-container container">
          <h1>Vagas</h1>
          <div class="filter-day row d-flex justify-content-center ">
            <h2 class="col-12  d-flex justify-content-center">Filtrar por</h2>
            <br>
            <br>
            <button class="col-6 col-sm-2 py-4 my-1 d-flex justify-content-center align-items-center" @click="status = ''"><h3>Todas</h3></button>
            <button class="col-6 col-sm-2 py-4 my-1 d-flex justify-content-center align-items-center" @click="status = 'OPEN'"><h3>Abertas</h3></button>
            <button class="col-6 col-sm-2 py-4 my-1  d-flex justify-content-center align-items-center" @click="status = 'CLOSED'"><h3>Fechadas</h3></button>
            <button class="col-6 col-sm-2 py-4 my-1  d-flex justify-content-center align-items-center" @click="status = 'STANDBY'"><h3>Standby</h3></button>
            <button class="col-6 col-sm-2 py-4 my-1  d-flex justify-content-center align-items-center" @click="status = 'CANCELED'"><h3>Canceladas</h3></button>
            <!-- <button class="" @click="status='CANCELED'">Canceladas</button> -->
          </div>
          <div class="container candidatures">
            <div class="row">
              <div class="col-md-6" v-for="opportunity in opportunityListFiltered()" :key="opportunity._id"
                @click="opportunityNow = opportunity" data-toggle="modal" data-target="#opportunityDetailsModal">
                <opportunity-item :opportunity="opportunity"  :type="0">
                </opportunity-item>
              </div>
            </div>
          </div>

          <opportunity-details-modal :modal_id="'opportunityDetailsModal'" :opportunity="opportunityNow"
            :showCandidateButton="false"></opportunity-details-modal>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Company.vue";
import companyMixin from "../../../mixins/companyMixin.js";
import ProductFinder from "../../../components/ProductFinder.vue";
import OpportunityItem from "../../../components/OpportunityItem.vue";
import OpportunityDetailsModal from "../../../components/OpportunityDetailsModal.vue";
import moment from "moment";
import { server, local } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  mixins: [companyMixin],
  components: {
    MainLayout,
    ProductFinder,
    OpportunityItem,
    OpportunityDetailsModal,
  },
  data() {
    return {
      isPaid: 0,
      opportunityList: [],
      status: "",
      search: {
        searchText: null,
      },
      opportunityNow: {},
    };
  },
  validations: {},
  mounted() {
    var parameters = this.$route.query;
    let self = this;
  },
  watch: {
    company(newValue, oldValue) {
      // console.log(newValue, oldValue);
      if (this.company) {
        this.getOpportunities();
      }

    },
  },

  methods: {
    opportunityListFiltered() {
      var list = [];
      for (let item of this.opportunityList) {
        if (item.status.indexOf(this.status) > -1) {
          list.push(item);
        }
      }
      return list;
    },
    getOpportunities() {
      let self = this;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        // filter: this.search.searchText,
      };
      this.axios
        .get(
          server.url + "/api/company/" + self.company._id + "/job-opportunity/",
          header
        )
        .then(function (response) {
          let responseData = response.data.data;
          self.opportunityList = responseData.job_opportunity_list;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.company-details-view {

  .opportunities-container {
    padding: 20px;
  }
}

.company-view-top {
  h1 {
    text-align: left;
    padding-top: 50px;
  }

  // .store-picture-frame{
  // 	width: 150px;
  // 	height: 150px;
  // 	margin-top: 50px;
  // 	border-radius: 50%;
  // 	background-position: center center  !important;
  // 	background-size: cover  !important;

  // 	border: 2px solid var(--main-color-secundary);

  //   position: absolute;
  //   left: 50%;
  //   margin-left: -75px;
  //   margin-bottom: 20px;
  // }
  .store-picture-frame {
    width: 150px;
    height: 150px;
    margin-top: 50px;
    // border-radius: 50%;
    // background-position: center center  !important;
    // background-size: cover  !important;

    // border: 2px solid var(--main-color-secundary);

    position: absolute;
    left: 50%;
    margin-left: -75px;
    margin-bottom: 20px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .company-details-view {
    width: auto;
    left: 0px;
    margin-left: 10px;
    margin-right: 10px;

    .opportunities-container {
      // padding: 20px;
    }
  }
}
</style>
