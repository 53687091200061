<template>
  <main-layout>
    <div>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div h-auto">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-7 ">
            <h1 class="mt-3" >Encontre aulas para se aperfeiçoar</h1>
          </div>
          <div class="col-md-5 ">
            <button class="blue-border" @click="goToCart()">Carrinho</button>
            <button @click="goToSubscribedTrainings()">Treinamentos gratuitos para assinantes</button>
          </div>
        </div>
      </div>
    </div>
    <div class="training-search">
      <div class="container" style="padding-bottom: 35px;" v-if="!hideCallTraining">
        <div class="training-register-call" style="text-align: center">
          <button class="hide" @click="hideCall()">X</button>
          <h2>Cadastre seus próprios treinamentos e comece a vender</h2>
          <button class="go-to-training-btn" @click="goToMyTraining()">Cadastrar / meus treinamentos</button>
        </div>
      </div>

      <h1>O que você quer aprender?</h1>
      <div class="container search">
        <div class="row" style="padding-top:30px">
          <div class="offset-md-1 col-md-8">
            <input type="text" placeholder="Buscar por nome" v-on:keyup.enter="getCourses" v-model="search.searchText">
          </div>
          <div class="col-md-2">
            <button @click="getCourses()" style="margin-top: 3px;">Buscar</button>
          </div>
        </div>
      </div>
      
      <div class="container-fluid" style="padding-top: 60px;" v-if="segmentListWithTraining.length > 0">
        <div class="container">
          <h2>Treinamentos por tema</h2>
          <br>
          <div class="row segment-list">
            <div class="col-md-2 segment-list-item" v-for="segment in segmentListWithTraining" :key="segment._id" @click="goToSegment(segment._id)">
              <div class="segment-item">
                {{segment.name}}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="container-fluid" style="padding-top: 60px; padding-bottom: 45px;" v-if="teacherList.length > 0">
        <div class="container">
          <h2>Treinadores</h2>
          <br>
          <div class="row teacher-list">
            <div class="col-md-2 teacher-list-item" v-for="teacher in teacherList" :key="teacher._id" @click="goToTeacher(teacher._id)">
              <div class="teacher-item" :style="'background: url('+getImageThumbUrl(teacher.image)+')'"></div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="light-grey-bg container-fluid training-list" v-if="featuredTrainingList.length > 0" id="training-list">
        <div class="container">
          <h2>Treinamentos para comprar</h2>
          <br>
          <div class="row" style="padding-bottom: 45px">
            <div class="col-md-3" v-for="training in featuredTrainingList" :key="training._id">
              <training-item :training = training :grey = false></training-item>
            </div>
          </div>
          <button class="all-training-btn" @click="search.searchText = null; getCourses()">Ver todos os treinamentos</button>
        </div>
      </div>

      <div class="container-fluid training-list" v-if="bestSellersTrainingList.length > 0">
        <div class="container">
          <h2>Treinamentos mais vendidos</h2>
          <br>
          <div class="row" style="padding-bottom: 45px">
            <div class="col-md-3" v-for="training in bestSellersTrainingList" :key="training._id">
              <training-item :training = training :grey = true></training-item>
            </div>
          </div>
          <button class="all-training-btn" @click="search.searchText = null; getCourses()">Ver todos os treinamentos</button>
        </div>
      </div>
    </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/SalesmanProfile.vue";
import { server } from "../../../js/variables.js";
import userMixin from "../../../mixins/userMixin.js";
import VueScrollTo from "vue-scrollto";
import TrainingItem from "../../../components/TrainingItem.vue";
import moment from 'moment';

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    TrainingItem,
  },
  data() {
    return {
      featuredTrainingList: [
        // {_id: 1, name: 'Treinamento 1', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 2, name: 'Treinamento 2', user:{name: 'Professor 2'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 3, name: 'Treinamento 3', user:{name: 'Professor 3'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 4, name: 'Treinamento 4', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
      ],
      bestSellersTrainingList: [
        // {_id: 1, name: 'Treinamento 1', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 2, name: 'Treinamento 2', user:{name: 'Professor 2'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 3, name: 'Treinamento 3', user:{name: 'Professor 3'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 4, name: 'Treinamento 4', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
      ],
      segmentList: [],
      teacherList: [],
      search: {
        searchText: null,
      },
      hideCallTraining: true,
    };
  },
  mounted() {
    var parameters = this.$route.query;
    if(!localStorage.hideCallTraining){
      this.hideCallTraining = false;
    }
    this.findSegments();
    this.getTeachers();
  },
  computed: {
    segmentListWithTraining: function () {
      let segmentsWithTrainings = [];
      // let trainings = this.featuredTrainingList;
      let trainings = JSON.parse(JSON.stringify(this.featuredTrainingList));
      let indexList = [];
      for(let t of trainings){
        // console.log('aqui', t)
        if(t.segment == null){
          // console.log('nulo');
          let index = trainings.indexOf(t);
          // console.log('index', index);
          // trainings.splice(index, 1);
          // console.log('t', t);
          indexList.push(index);
        }
      }
      for(let i = indexList.length - 1; i>=0; i--){
        // console.log('apagando', trainings)
        trainings.splice(indexList[i], 1);
        // console.log('apagou', trainings)
      }
      // console.log('this.featuredTrainingList', this.featuredTrainingList)
      // console.log('trainings', trainings)
      
      if(trainings.length > 0 && this.segmentList.length > 0){
        for(let segment of this.segmentList){
          if(trainings.some(training => training.segment._id === segment._id)){
            if(segmentsWithTrainings.some(segmentTraining => segmentTraining._id === segment._id)){
              console.log('ja tem: ')
            }else{
              segmentsWithTrainings.push(segment);
            }
          }
        }
      }
      // console.log('FINAL', segmentsWithTrainings)
      return segmentsWithTrainings
    },
  },
  watch: {
    user(newValue, oldValue) {
      // console.log(newValue, oldValue);
      // console.log("watch user", this.user);
      if(this.user._id){
        this.getCourses();
      }
    },
  },
  methods: {
    getCourses() {
      let self = this;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        filter: this.search.searchText,
        visible: 1,
      };

      this.axios
      .get(server.url + "/api/course/", header)
        .then(function (response) {
          let responseData = response.data.data;
          self.featuredTrainingList = [];
          self.bestSellersTrainingList = [];
          self.featuredTrainingList = responseData.course_list;
          // self.bestSellersTrainingList = responseData.course_list;

          for(let course of responseData.course_list){
            course.created_at = moment(course.created_at).add(4, 'hours').format('DD/MM/YYYY');
            // if(course.user._id != self.user._id){
            //   self.featuredTrainingList.push(course);
            //   self.bestSellersTrainingList.push(course);
            // }
          }
          // if(self.search.searchText != null && self.search.searchText != ''){
            VueScrollTo.scrollTo('#training-list');
          // }

          // for(let course of self.featuredTrainingList){
          //   course.created_at = moment(course.created_at).add(4, 'hours').format('DD/MM/YYYY');
          // }
          // for(let course of self.bestSellersTrainingList){
          //   course.created_at = moment(course.created_at).add(4, 'hours').format('DD/MM/YYYY');
          // }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    getTeachers() {
      let self = this;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }
      
      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        filter: this.search.searchText,
      };

      this.axios
      .get(server.url + "/api/course/users", header)
        .then(function (response) {
          self.teacherList = [];
          let responseData = response.data.data;
          self.teacherList = responseData.user_list;

        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    goToSubscribedTrainings(){
      let self = this;
      var id = this.$route.params.id;
      const url = "/vc/treinamentos-inscritos/" ;
      self.$router.push(url);
    },
    findSegments(){
      let self = this;
      this.axios
        .get(server.url + "/api/skill?filter=INDUSTRY_GENERAL", server.config)
        .then(function (response) {
          self.segmentList= [];
          let responseData = response.data.data.skill_list;
          self.segmentList = responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    goToSegment(segmentId){
      let self = this;
      const url = '/vc/buscar-treinamentos/tema/' + segmentId;
      self.$router.push(url);
    },
    goToTeacher(teacherId){
      let self = this;
      const url = '/vc/buscar-treinamentos/treinador/' + teacherId;
      self.$router.push(url);
    },
    goToCart(){
      let self = this;
      window.open("./vc/carrinho", "_self");
      // const url = '/vc/carrinho';
      // self.$router.push(url);
    },
    goToMyTraining(){
      let self = this;
      const url = '/vc/meus-treinamentos/';
      self.$router.push(url);
    },
    hideCall(){
      localStorage.setItem('hideCallTraining', "true");
      this.hideCallTraining = true;
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";



.training-search {
  padding-top: 70px;
  h1{
    text-align: center;
  }

  .segment-list{
    display: block;
    margin: 0px;
    margin-top: 30px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    min-height: 60px;

      /* width */
    &::-webkit-scrollbar {
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--main-color-mediumgrey);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--main-color-darkgrey);
    }

    .segment-list-item{
      width: auto;
      display: inline-flex;
      float: none;
      white-space: initial;
      vertical-align: top;
      display: inline-block;
      height: 125px;
      width: 200px;
      white-space: initial;

      img{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        padding: 10px;
      }

      h2{
        // display: block;
        // bottom: 30px;
        // position: absolute;
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .teacher-list{
    display: block;
    margin: 0px;
    margin-top: 30px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    min-height: 60px;

      /* width */
    &::-webkit-scrollbar {
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--main-color-mediumgrey);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--main-color-darkgrey);
    }

    .teacher-list-item{
      width: auto;
      display: inline-flex;
      float: none;
      white-space: initial;
      vertical-align: top;
      display: inline-block;
      height: 125px;
      width: 200px;
      white-space: initial;

    }
  }


  .training-register-call{
    position: relative;
    padding: 40px;
    border-radius: 18px;
    border: 1px solid var(--main-color-mediumgrey);

    .hide{
      width: auto;
      padding: 5px 10px;
      background-color: transparent;
      color: var(--main-title-color);
      position: absolute;
      right: 12px;
      top: 10px;

      &:hover{
        background-color: var(--main-color-secundary);
      }
    }

    .go-to-training-btn{
      margin-top: 10px;
      height: 30px;
      margin-left: 40px;
      width: auto;
    }

    h2{
      text-align: center;
      display: inline-block;
    }
  }

  .training-list{
    padding-top: 60px; 
    padding-bottom: 80px;

    .all-training-btn{
      float: right;
      width: 230px;
      height: 34px;
      color: var(--main-title-color);
    }
  }

  .segment-item{
    text-align: center;
    padding: 35px 0px;
    border-radius: 17px;
    background-color: var(--main-color-lightgrey);
    color: var(--main-title-color);
    font-weight: bold;
    cursor: pointer;
    height: 96px;
  }

  .teacher-item{
    height: 85px;
    width: 85px;
    border: 2px solid var(--main-color-mediumgrey);
    background-position: center center !important;
    background-size: cover !important;
    border-radius: 50%;
    cursor: pointer;
  }
  

}

@media only screen and (max-width: 600px) {
  .training-search {

    .segment-list{
      .segment-list-item{
        width: auto;
      }
    }

    .teacher-list{
      .teacher-list-item{
        width: auto;
      }
    }

    .training-register-call{

      .go-to-training-btn{
        margin-left: 0px;
      }
    }

    .search{
      button{
        width: 100%;
      }
    }

    .segment-item{
      margin-bottom: 10px;
    }
  }
}
</style>
