<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1 >Candidaturas para vagas</h1>
          </div>
          <div class="col-md-3 ">
            <button  v-on:click="goTo('./vc/buscar-vagas')">Buscar vagas</button>
          </div>
        </div>
      </div>
    </div>

    <div class="container candidatures" style="padding-top:50px">
		<div class="row" >
			<div class="col-md-3 ">
                <div class="light-grey-bg box-amount-opportunities">
                    <div class="amount-opportunity">
                        {{opportunityDash.await + opportunityDash.approved + opportunityDash.denied}}
                    </div>
                    <div class="opportunity-status">
                        Vagas inscritas
                    </div>
                    <div class="hr-registered center"></div>
                </div>
			</div>
			<div class="col-md-3 ">
                <div class="light-grey-bg box-amount-opportunities">
                    <div class="amount-opportunity">
                        {{opportunityDash.await}}
                    </div>
                    <div class="opportunity-status">
                        Vagas em espera
                    </div>
                    <div class="hr-waiting center"></div>
                </div>
			</div>
			<div class="col-md-3 ">
                <div class="light-grey-bg box-amount-opportunities">
                    <div class="amount-opportunity">
                        {{opportunityDash.approved}}
                    </div>
                    <div class="opportunity-status">
                        Vagas aprovadas
                    </div>
                    <div class="hr-aproved center"></div>
                </div>
			</div>
			<div class="col-md-3 ">
                <div class="light-grey-bg box-amount-opportunities">
                    <div class="amount-opportunity">
                        {{opportunityDash.denied}}
                    </div>
                    <div class="opportunity-status">
                        Vagas negadas
                    </div>
                    <div class="hr-denied center"></div>
                </div>
			</div>
        </div>
        
        <div class="filter-day" >
            <h2>Filtrar por</h2>
            <button class="" @click="status=''">Todas</button>
            <button class="" @click="status='APPROVED'">Aprovados</button>
            <button class="" @click="status='DENIED'">Negado</button>
            <button class="" @click="status='AWAIT'">Esperando</button>
            <!-- <button class="" @click="status='CANCELED'">Canceladas</button> -->
        </div>
        

        <div class="row">
            <div class="col-md-12" style="padding-bottom: 20px" v-if="myOpportunityList.length != 0">
                <h2 v-if="status ==''">Todas vagas</h2>
                <h2 v-if="status =='APPROVED'">Vagas aprovadas</h2>
                <h2 v-if="status =='DENIED'">Vagas negadas</h2>
                <h2 v-if="status =='AWAIT'">Vagas em espera</h2>
            </div>
            <div class="col-md-6" v-for="opportunity in myOpportunityListFiltered()"  :key="opportunity._id" @click="opportunityCopy = opportunity" data-toggle="modal" data-target="#opportunityDetailsModal">
                <opportunity-item :opportunity = opportunity :type = 2> </opportunity-item>
            </div>
        </div>

        <!-- <div class="col-md-12" v-if="myOpportunityList.length == 0"> -->
            <h2 style="text-align: center;" class="no-itens padding-top-to-header" v-if="myOpportunityList.length == 0">Você ainda não se inscreveu em nenhuma vaga</h2>
        <!-- </div> -->

        <opportunity-details-modal :modal_id="'opportunityDetailsModal'" :opportunity = opportunityCopy :showCandidateButton = true></opportunity-details-modal>
        
        
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import userMixin from '../../../mixins/userMixin.js'
import OpportunityItem from "../../../components/OpportunityItem.vue";
import OpportunityDetailsModal from "../../../components/OpportunityDetailsModal.vue";
import moment from 'moment'
import { server } from "../../../js/variables.js";
import { required, email, sameAs } from 'vuelidate/lib/validators'


export default {
	mixins: [userMixin],
	components: {
	MainLayout,
    OpportunityItem,
    OpportunityDetailsModal,
  },
  data () {
    return {
		myOpportunityList: [
			// {_id: 1, name: "Título da vaga 1", position: 'Gerente 1', amount: 5, address: {city: 'São Paulo', state: 'SP'}, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. '},
			// {_id: 2, name: "Título da vaga 2", position: 'Gerente 2', amount: 10, address: {city: 'Diadema', state: 'SP'}, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. '},
			// {_id: 3, name: "Título da vaga 3", position: 'Gerente 3 ', amount: 2, address: {city: 'Osasco', state: 'SP'}, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. '},
			// {_id: 4, name: "Título da vaga 4", position: 'Gerente 4', amount: 15, address: {city: 'Vinhedo', state: 'SP'}, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. '},
        ],
        opportunityCopy: {},
		opportunityDash: {
			approved: 0,
			await: 0,
			denied: 0,
		},
        positionList: [
			{ _id: 1, name: "Estagiário", value: "INTERN" },
			{ _id: 2, name: "Júnior", value: "JUNIOR" },
			{ _id: 3, name: "Pleno", value: "FULL" },
			{ _id: 4, name: "Sênior", value: "SENIOR" },
			{ _id: 5, name: "Coordenador", value: "COORDINATOR" },
			{ _id: 6, name: "Gerente", value: "MANAGER" },
			{ _id: 7, name: "Diretor", value: "DIRECTOR" },
        ],
        status: "",
		search: {
			state: '',
			city: '',
            segment: '',
            position: '',
			searchText: '',
        },
    }
  },
	mounted() {
		
		var parameters = this.$route.query;
		let self = this;
        // this.findSegments();
        // this.findPlaces();
		// this.getCandidatures();
	},
	watch:{
		user(newValue, oldValue){
			// console.log(newValue._id, oldValue._id);
            // console.log("AQUI",this.user); 
            if(this.user && this.user._id){
                this.getCandidatures();
            }
            
		
		}
	},
  methods: {
    myOpportunityListFiltered(){
        var list = [];
        for ( let item of this.myOpportunityList) {
            if (item.candidates) {
                for ( let candidate of item.candidates) {
                    if(candidate.user._id == this.user._id){
                        if (candidate.status.indexOf(this.status )> -1 ) {
                            list.push(item);
                        }
                    }
                }
            }
            
        }
        return list;
    },
	teste(teste){
        self.opportunityCopy = teste;
    },
	getSalesman() {
		let self = this;
		// if (!localStorage.token) {
		// 	return
		// }
		// server.config.headers = {
		// 	Authorization: 'Bearer ' + localStorage.token
		// }
		let header = JSON.parse(JSON.stringify(server.config));
		header['params'] = {
			limit: 6,
			type: "SALESMAN",
			resume: 1,
			state: this.search.state,
			city: this.search.city,
            segment: this.search.segment,
            position: this.search.position,
			// filter: this.search.searchText
		}

		this.axios
		.get(server.url + "/api/user", header)
		.then(function (response) {
			self.salesmanList = [];
			let responseData = response.data.data;
			self.salesmanList = responseData.user_list;
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
			console.log(error);
		});
	},
    getCandidatures(){
		let self = this;
		if (!localStorage.token) {
			return
		}
		server.config.headers = {
			Authorization: 'Bearer ' + localStorage.token
		}

		this.axios.get(  server.url + '/api/job-opportunity/?my=true', server.config)
		.then(function (response) {
            let responseData = response.data.data;
            self.myOpportunityList = [];
            // self.myOpportunityList = responseData.job_opportunity_list;
            self.opportunityDash = responseData.job_opportunity_dash;

            // for(let opportunity of self.myOpportunityList) {
            //     opportunity.expiration = moment(opportunity.expiration).add(4, 'hours').format('DD/MM/YYYY');
            // }

            for(let opportunity of responseData.job_opportunity_list) {
                for(let candidate of opportunity.candidates){
                    if(candidate.status != 'CANCELED' && candidate.user._id == self.user._id){
                        opportunity.expiration = moment(opportunity.expiration).add(4, 'hours').format('DD/MM/YYYY');
                        opportunity.created_at = moment(opportunity.created_at).add(4, 'hours').format('DD/MM/YYYY');
                        self.myOpportunityList.push(opportunity);
                    }
                }
            }
            console.log('aqui', self.myOpportunityList)
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
			console.log(error);
		});
	},
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.filter-opportunities{
    height: 190px;
    margin-top: -15px;
    margin-bottom: 60px;

    h2{
        text-align: center;
        font-size: 30px;
        padding-top: 50px;
    }

    button{
        width: 100%;
    }

    .filters-div{
        width: 70%;
        margin-top: 20px;
    }
}

.candidatures{

    .box-amount-opportunities{
        border-radius: 10px;
        color: var(--main-title-color);
        font-weight: bold;
        text-align: center;
        padding: 20px;

        .amount-opportunity{
            font-size: 45px;
        }

        .opportunity-status{
            font-size: 17px;
        }
    }

    .hr-registered{
        background-color: #053360;
        height: 3px;
        width: 60%;
        margin-top: 12px;;
    }

    .hr-waiting{
        background-color: #EEB013;
        height: 3px;
        width: 60%;
        margin-top: 12px;;
    }

    .hr-aproved{
        background-color: #28BE50;
        height: 3px;
        width: 60%;
        margin-top: 12px;;
    }

    .hr-denied{
        background-color: #EE1313;
        height: 3px;
        width: 60%;
        margin-top: 12px;;
    }

    .filter-day{
        margin-top: 40px;
        margin-bottom: 40px;

        button{
            margin-right: 8px;
            display: inline-block;
            border: 0.5px solid var(--main-color-mediumgrey);
            font-weight: normal;
            width: 105px;
            height: 25px;
            background-color: white;
            color: var(--main-title-color);
        }

        h2{
            display: inline-block;
            margin-right: 20px;
        }
    }
}

@media only screen and (max-width: 600px) {
	.filter-opportunities{
		height: 365px;
        margin-bottom: 30px;
    }
    
    .candidatures{

        .box-amount-opportunities{
            margin-bottom: 15px;
        }
    }
}

</style>
