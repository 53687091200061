<template>
  <main-layout>
	<!-- <pre> password {{ $v.login.password }}</pre>
	<pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container-fluid plan-preview-company">
		<div class="row">
			<div class="offset-md-1 col-md-3 " id="plan-preview-box">
                <br><br>
				<a href target="_self">
					<img src="/static/svg/logo_horizontal.svg" alt="" class="logo">
				</a>
                
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Semper feugiat nibh sed pulvinar proin gravida hendrerit lectus a. Consectetur a erat nam at lectus urna duis convallis convallis. Quis vel eros donec ac odio tempor. Et netus et malesuada fames ac turpis egestas. Id donec ultrices tincidunt arcu non sodales. Quis ipsum suspendisse ultrices gravida dictum fusce. Eu volutpat odio facilisis mauris sit amet. Orci phasellus egestas tellus rutrum tellus pellentesque eu.
<br><br>Malesuada fames ac turpis egestas. Nunc faucibus a pellentesque sit amet porttitor eget dolor. Nunc sed id semper risus in hendrerit gravida. Est sit amet facilisis magna etiam tempor orci. Magna sit amet purus gravida. Dui id ornare arcu odio ut sem. </p>
                <button class="free-version-btn">Acessar versão grátis</button>
                <div class="position-plan">
                    <plan-item :planType = 1 :isSubscribed = isPaid></plan-item>
                </div>
			</div>
			<div class="offset-md-3 col-md-5 no-padding">
                <!-- <img src="/static/img/plan.jpg" alt /> -->
				<div class="image-box" style="background-image: url(./static/img/plan.jpg)"></div>
			</div>
		</div>

        
        
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Company.vue";   
import { server, local } from "../../../js/variables.js";
import userMixin from '../../../mixins/userMixin.js';
import { required, email, sameAs } from 'vuelidate/lib/validators'
import PlanItem from '../../../components/PlanItem.vue';

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    PlanItem,
  },
  data () {
    return {
      isPaid: 0,
    }
  },
  validations: {
        
	},
    mounted() {
        var parameters = this.$route.query;
		let self = this;
		
    },
	watch:{
    user(newValue, oldValue){
			// console.log(newValue, oldValue);
      // console.log("watch user", local.subscription);
      if(local.subscription){
        if(local.subscription.plan_payment != '' && local.subscription.plan_payment != null){
          // console.log("Plano pago");
          this.isPaid = 1;
        }else{
          // console.log("Plano gratuito");
          this.isPaid = 0;
        }
      }else{
          // console.log("Plano gratuito");
        this.isPaid = 0;
      }
		}
	},
	methods: {
		
	}
};
</script>

<style lang="scss">

// @import "../scss/reset.scss";
// @import "../scss/base.scss";
// @import "../scss/form.scss";
// @import "../css/mobile.css";
#plan-preview-box{
	height: 100vh;
}

.plan-preview-company{
    background-color: var(--main-color-lightgrey);

    .logo{
        margin-top: 30px;
        margin-bottom: 60px;
    }

    .position-plan{
        top: 30%;
        position: absolute;
        right: -160%;
        left: 110%;
        z-index: 5;
        // background-color: #0000;
        // border-radius: 10px;

        .plan-box-professional{
            box-shadow: none;
        }
    }

    .free-version-btn{
        margin-top: 30px;
        width: 100%;
    }

	.image-box{
		background-size:cover;
		background-position: center center;
		background-repeat: no-repeat;

		height: 100%;
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {

}


</style>
