<template>
  <main-layout>

    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-8 ">
            <h1 >Treinamento</h1>
          </div>
          <div class="col-md-2 ">
            <button class="remove" v-if="this.$route.params.id != 'new'" @click="deleteCourse()">Excluir</button>
          </div>
          <div class="col-md-2 ">
            <button v-if="this.$route.params.id == 'new'" @click="createCourse()">Criar</button>
            <button v-else @click="updateCourse('redirect')">Salvar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="training-register container">
      <button class="grey" @click="selected = 'informacoes'" :class="{'active': selected ==  'informacoes'}">Info</button>
      <button class="grey" style="margin-left: 20px" @click="selected = 'aulas'" :class="{'active': selected ==  'aulas'}" :disabled="this.$route.fullPath == '/vc/meus-treinamentos/new'">Matérias</button>
      
      <div v-if="selected == 'informacoes'" style="padding-top: 40px">
        <div class="row">
          
          <div class="col-md-12">
            <div class="title-bg-grey">
              <h3>Disponibilidade do treinamento</h3>
            </div>
            <br>
          </div>

          <div class="col-md-6">
            <label for="description">Tipo de publicação*</label>
            <span class="alert-required" v-if="$v.courseType.$error">Campo não pode estar vazio</span>
            <v-select label="label" 
              :options="openOrExclusive" 
              v-model="$v.courseType.$model" 
              :reduce="option => option.value"
              v-if="this.$route.fullPath == '/vc/meus-treinamentos/new'"
            >
            </v-select>
            <input type="text" disabled v-if="this.$route.fullPath != '/vc/meus-treinamentos/new'" v-model="$v.courseType.$model">
          </div>
          
          
          <div class="col-md-2" v-if="courseType == 'Aberto'">
            <label for="status">Aberto para publicar*</label>
            <v-select label="label" 
              :options="yesNo" 
              v-model="$v.course.status.$model" 
              :reduce="option => option.value" >
            </v-select>
          </div>

          <div class="col-md-4" v-if="courseType == 'Aberto'">
            <label for="price" >Valor de venda* (min: R$ 20,00)</label>
            <money v-model="$v.course.price.$model"></money>
          </div>

          <div class="col-md-6" v-if="courseType == 'Exclusivo'"></div>

          <div class="col-md-6" v-if="courseType == 'Exclusivo'">
            <label for="description">Empresa beneficiada*</label>
            <v-select label="name" 
              :options="companyList" 
              v-model="$v.course.exclusive_company.$model" 
              :reduce="option => option._id" >
            </v-select>
          </div>

          <div class="col-md-6" v-if="courseType == 'Exclusivo'">
            <label for="price">Licenças*</label>
            <input id="price" type="text" v-model="$v.course.exclusive_licenses.$model">
          </div>


          <div class="col-md-12" style="padding-top: 25px;">
            <div class="title-bg-grey">
              <h3>Informações do treinamento</h3>
            </div>
            <br>
            <label for="name">Nome do treinamento*</label>
            <input id="name" type="text" v-model="$v.course.name.$model">
          </div>

          <div class="col-md-6">
            <label for="segment">Segmento*</label>
            <v-select label="name" 
              :options="segmentList" 
              v-model="$v.course.segment.$model" 
              :reduce="segment => segment._id" >
            </v-select>
          </div>



          <div class="col-md-12">
            <label for="description">Descrição*</label>
            <vue-editor id="description" type="text" placeholder="" :editorToolbar="customToolbar" v-model="$v.course.description.$model" @blur="$v.course.description.$touch()" :class="{ 'fail-error' : $v.course.description.$error }"></vue-editor>

            <label for="objectives" style="padding-top: 18px;">Objetivos*</label>
            <vue-editor id="objectives" type="text" placeholder="" :editorToolbar="customToolbar" v-model="$v.course.objectives.$model" @blur="$v.course.objectives.$touch()" :class="{ 'fail-error' : $v.course.objectives.$error }"></vue-editor>

            <label for="target" style="padding-top: 18px;">Público alvo*</label>
            <vue-editor id="target" type="text" placeholder="" :editorToolbar="customToolbar" v-model="$v.course.target.$model" @blur="$v.course.target.$touch()" :class="{ 'fail-error' : $v.course.target.$error }"></vue-editor>

            <label for="prerequisites" style="padding-top: 18px;">Pré-requisitos*</label>
            <vue-editor id="prerequisites" type="text" placeholder="" :editorToolbar="customToolbar" v-model="$v.course.prerequisites.$model" @blur="$v.course.prerequisites.$touch()" :class="{ 'fail-error' : $v.course.prerequisites.$error }"></vue-editor>
          </div>

          <div class="col-md-6" style="padding-top: 25px">
            <div class="title-bg-grey" >
              <h3>Imagem promocional</h3>
            </div>
            <br>
            <div class="box-item" style="margin-top: 25px;">
              <image-upload
                v-model="course.image"
                @uploaded="updateCourse()"
                @deleted="updateCourse()">
              </image-upload>
            </div>
          </div>


          <div class="col-md-6" style="padding-top: 25px" v-if="course.video[0] && course.video[0].process_completed == false">
            <div class="title-bg-grey" >
              <h3>Vídeo de apresentação (Tamanho máximo 5GB e formato MP4)</h3>
            </div>
            <br>
            
            <div class="box-item" style="margin-top: 25px; cursor: initial;pointer-events: none;">
              <p style="font-weight: 500;">Seu vídeo está na fila de processamento e pode demorar até 72h para ser finalizado.</p>
            </div>
          </div>
          
          <div class="col-md-6" style="padding-top: 25px" v-else>
            <div class="title-bg-grey" >
              <h3>Vídeo de apresentação (Tamanho máximo 5GB e formato MP4)</h3>
            </div>
            <br>
            <p>Video pode demorar um pouco para ser processado</p>
            <div class="box-item">
              <video-upload
                v-model="course.video[0]"
                @uploaded="updateCourse('messageSuccess')"
                @deleted="updateCourse('reload')">
              </video-upload>
            </div>
          </div>
          
          <div class="offset-md-8 col-md-2 " style="padding-top: 25px">
            <button class="remove" v-if="this.$route.params.id != 'new'" @click="deleteCourse()" style="width: 100%">Excluir</button>
          </div>
          <div class="col-md-2 " style="padding-top: 25px">
            <button v-if="this.$route.params.id == 'new'" @click="createCourse()" style="width: 100%">Criar</button>
            <button v-else @click="updateCourse('redirect')" style="width: 100%">Salvar</button>
          </div>

        </div>
      </div>

      <div v-if="selected == 'aulas'" class="lessons" style="padding-top: 40px">
        <div class="row">
          <div class="col-md-12">
            <draggable v-model="course.sections" group="sections" @start="drag=true" @end="drag=false">
              <div v-for="section in course.sections" :key="section.id" >
                <div class="classItem">
                  <!-- <div style="display: inline-block">{{section.name}}</div> -->
                  <input id="title" type="text" v-model="section.title" v-bind:style=" section.title == '' ? 'border: 1px solid var(--main-title-color) !important;' : 'border: none !important'" @blur="updateCourse()">
                  <button @click="removeItem(section)" class="close">X</button>
                  <!-- <button class="grey item-btn" style="float: right" @click="addLesson(section._id)" :disabled="!section._id">Adicionar aula</button> -->
                  
                  <button class="grey item-btn" style="float: right" @click="updateCourse('reloadLesson')" v-if="!section._id">Salvar</button>
                  <button class="grey item-btn" style="float: right" @click="addLesson(section._id)" v-if="section._id">Adicionar aula</button>
                </div>
                <draggable v-model="section.chapters" group="lessons" @start="drag=true" @end="drag=false">
                  <!-- <div v-for="item in section.chapters" :key="item.id" class="classItem" style="margin-left: 40px; cursor: pointer;" @click="lessonDetails(section._id, item.lesson._id)">
                    <img :src="getImageThumbUrl(item.lesson.image)" v-if="item.lesson.image" class="" alt="">
                    <p style="padding: 14px 25px; display: inline-block;" class="lesson-name">{{item.lesson.name}}</p>
                    <p style="float: right; margin-top: 14px;">Duração: 10:15</p>
                  </div> -->
                  <div v-for="item in section.chapters" :key="item.id" class="classItem row" style="margin-left: 40px; cursor: pointer;" @click="lessonDetails(section._id, item.lesson._id)">
                    <div class="col-md-2">
                      <img :src="getImageThumbUrl(item.lesson.image)" v-if="item.lesson.image" class="" alt="">
                    </div>
                    <div class="col-md-7 lesson-name">
                      <p class="">{{item.lesson.name}}</p>
                    </div>
                    <!-- <input id="title" type="text" v-model="item.lesson.name" style="cursor: pointer;" v-bind:style=" item.lesson.name == '' ? 'border: 1px solid var(--main-title-color) !important;' : 'border: none !important' "> -->
                    <div class="col-md-3">
                      <!-- <p style="float: right; margin-top: 14px;">Duração: 10:15</p> -->
                    </div>
                  </div>
                </draggable>
              </div>
              
            </draggable>
            
          </div>
          <div class="col-md-12">
            <button class="new-section" @click="addSection()">Nova sessão</button>
          </div>
        </div>
      </div>

    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import ImageUpload from "../../../components/ImageUpload.vue";
import VideoUpload from "../../../components/VideoUpload.vue";
// import VideoPlayerItem from "../../../components/VideoPlayerItem.vue";
import { server } from "../../../js/variables.js";
import draggable from 'vuedraggable'
import { required, email, sameAs } from 'vuelidate/lib/validators'
// import TrainingItem from "../../../components/TrainingItem.vue";

export default {
  components: {
    MainLayout,
    draggable,
    ImageUpload,
    VideoUpload,
    // VideoPlayerItem
  },
  data() {
    return {
      course: {
        name: '',
        description: '',
        exclusive_company: null,
        exclusive_licenses: 0,
        objectives: '',
        segment: {},
        target: '',
        prerequisites: '',
        price: '',
        status: false,
        sections: [],
        openForSale: '',
        video: [],
      },
      courseType: 'Aberto',
      selected: '',
      yesNo: [
        {_id: 0, label: 'Não', value: false},
        {_id: 1, label: 'Sim', value: true},
      ],
      openOrExclusive: [
        {_id: 0, label: 'Disponibilidade para todo site', value: 'Aberto'},
        {_id: 1, label: 'Exclusivo para uso de uma empresa', value: 'Exclusivo'},
      ],
      availability: [
        {_id: 0, label: 'Indisponível', value: false},
        {_id: 1, label: 'Disponível', value: true},
      ],
      segmentList:[],
      companyList: [],
      options: {},
      reloadPage: false,
    };
  },
  validations: {
    course: {
      name: '',
      description: {required},
      exclusive_company: '',
      exclusive_licenses: '',
      objectives: {required},
      segment: {},
      target: {required},
      prerequisites: {required},
      price: '',
      status: {required},
      sections: [],
      openForSale: '',
    },
    courseType: {required},
  },
  computed: {
  },
  mounted() {
    let self = this;
    var parameters = this.$route.query;
    var id = this.$route.params.id;
    if(parameters.local){
      this.selected = parameters.local;
    }else{
      this.selected = 'informacoes';
    }
    this.findSegments();
    this.getCompany();
    if (this.$route.fullPath != "/vc/meus-treinamentos/new"){
      this.getCourse();
    }
  },
  methods: {
    getCourse(){
      let self = this;
      var id = this.$route.params.id;
      this.axios.get( server.url + '/api/course/' + id, server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.course = responseData.course;
          if(self.course.exclusive_company == null){
            self.courseType = 'Aberto'
          }else{
            self.courseType = 'Exclusivo'
          }
          if(self.reloadPage){
            self.reloadPage = false;
            location.reload();
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    findSegments(){
      let self = this;
      this.axios
        .get(server.url + "/api/skill?filter=INDUSTRY_GENERAL", server.config)
        .then(function (response) {
          self.segmentList= [];
          let responseData = response.data.data.skill_list;
          self.segmentList = responseData;
          let indifferent = {_id: 'indiferente', name: 'Indiferente'};
          self.segmentList.push(indifferent);
          if(self.$route.params.id == 'new'){
            self.course.segment = 'indiferente';
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },

    createCourse(){
      let self = this;
      if (!this.$v.$invalid && self.courseType != ''){
        if (!localStorage.token) {
          return
        }
        server.config.headers = {
          Authorization: 'Bearer ' + localStorage.token
        }
        
        if(self.course.price < 20 && self.courseType == 'Aberto'){
          self.$swal({
            icon: 'warning',
            title: 'Atenção',
            text: 'Valor mínimo do curso é R$ 20,00',
          })
          return
        }

        if(self.courseType == 'Aberto'){
          self.course.exclusive_company = null;
          self.course.exclusive_licenses = 0;
        }else{
          self.course.price = 0;
          self.course.status = false;
        }
        self.course.price = parseFloat(self.course.price);

        if(self.course.segment == 'indiferente'){
          self.course.segment = null;
        }
        this.axios.post(  server.url + '/api/course/', {
          course: self.course,

        }, server.config)
        .then(function (response) {
          let responseData = response.data;
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: responseData.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
            }).then((result) => {
            if (result.isConfirmed) {
              window.open("./vc/meus-treinamentos", "_self"); 
            }
          })
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
      }else{
        this.$v.$touch();
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique os campos obrigatórios',
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    updateCourse(action){
      let self = this;
      var id = this.$route.params.id;
      if(id == 'new'){
        if(action == 'messageSuccess'){
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: 'Seu vídeo entrou na fila de processamento e pode demorar até 72h para ser finalizado.',
          })
        }
        return
      }
      if (!this.$v.$invalid){
        if (!localStorage.token) {
          return
        }
        server.config.headers = {
          Authorization: 'Bearer ' + localStorage.token
        }
        if(self.courseType == 'Aberto'){
          self.course.exclusive_company = null;
          self.course.exclusive_licenses = 0;
        }else{
          self.course.price = 0;
          self.course.status = false;
        }
        this.axios.put(  server.url + '/api/course/' + id, {
          course: self.course,
        }, server.config)
        .then(function (response) {
          let responseData = response.data;
          // location.redirect();
          // VueScrollTo.scrollTo('#user-data-form');
          if(action == 'redirect'){
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: responseData.message,
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: '#053360',
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                window.open("./vc/meus-treinamentos", "_self"); 
              }
            })
          }else if(action == 'messageSuccess'){
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: 'Upload de vídeo realizado, estamos processando e em estantes estará tudo pronto. (A tela ficara preta com o seguinte texto "The media could not be loaded, either because...")',
              showCancelButton: true,
              confirmButtonColor: '#053360',
              confirmButtonText: 'Voltar - Meus treinamentos',
              cancelButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                const url = "/vc/meus-treinamentos";
                self.$router.push(url)
              }
            })

          }else if(action == 'reload'){
            location.reload();
          }else if(action == 'reloadLesson'){
            window.open ('/vc/meus-treinamentos/' + self.course._id + '?local=aulas', '_self');
          }
          
        })
        .catch(function (error) {
          // self.load();
          self.output = error;
          self.showError(error);
        });
      }else{
        this.$v.$touch();
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique os campos obrigatórios',
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    deleteCourse(){
      let self = this;
      var id = this.$route.params.id;

      this.$swal.fire({
      title: 'Realmente deseja excluir este treinamento?',
      text: "Esta ação é irreversível!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#053360',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir'
      }).then((result) => {
      if (result.isConfirmed) {
        if (!localStorage.token) {
          return
        }
        server.config.headers = {
          Authorization: 'Bearer ' + localStorage.token
        }

        this.axios.delete(  server.url + '/api/course/' + id, server.config)
        .then(function (response) {
          let responseData = response.data;
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: responseData.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
            }).then((result) => {
            if (result.isConfirmed) {
              // window.open("./vc/meus-treinamentos", "_self"); 
              const url = "/vc/meus-treinamentos";
              self.$router.push(url);
            }
          })
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
      }
      })
    },
    getCompany() {
      var parameters = this.$route.query;
      let self = this;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      this.axios
        .get(server.url + "/api/company", server.config)
        .then(function (response) {
          let responseData = response.data.data;

          self.companyList = responseData.company_list;
        })
        .catch(function (error) {});
    },
    addLesson(sectionId){
      let self = this;
      var id = this.$route.params.id;
      const url = "/vc/meus-treinamentos/" + id  + '/sessao/' + sectionId + '/aula/new';
      self.$router.push(url);
      
    },
    addSection(){
      let self = this;
      // const url = "/vc/meus-treinamentos/new";
      // self.$router.push(url);
      let section = {
        title: '',
        status: true,
      }
      this.course.sections.push(section);
    },
    lessonDetails(sectionId, lessonId){
      let self = this;
      var id = this.$route.params.id;
      const url = "/vc/meus-treinamentos/" + id  + '/sessao/' + sectionId + '/aula/' + lessonId;
      self.$router.push(url);
    },
    removeItem(item){
      let index = this.course.sections.indexOf(item);
      this.course.sections.splice(index, 1);
      this.updateCourse();
    },

  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";



.training-register {
  padding-top: 50px;
  h1 {
    text-align: center;
  }

  .lessons{
    .item-btn{
      height: 25px;
      width: 136px;
      font-weight: normal;
      float: right;
      margin-top: 14px;
    }

    .classItem{
      border: 1px solid var(--main-color-primary);
      border-radius: 1em;
      padding: 20px 40px;
      margin-bottom: 8px;
      height: 95px;

      img{
        max-width: 100%;
        height: 50px;
      }

      input{
        border: none !important;
        outline: none;
        width: calc(100% - 180px);
        border-radius: 0px;
      }

      .close{
        background-color: transparent;
        width: 20px;
        height: 20px;
        margin-top: 14px;
        margin-left: 20px;
      }

      .lesson-name{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .new-section{
      width: 100%;
      height: 45px;
      background-color: var(--main-color-lightgrey);
      color: var(--main-title-color);
      font-weight: bold;
      margin-top: 10px;
      

      &:hover{
        background-color: var(--main-color-primary);
        color: white;
      }
    }
  }

  .thumb {
    padding-top: 50%;
  }

}

@media only screen and (max-width: 600px) {
  .training-register {
    .box-item {
        height: 75%!important;
    }
  }
}
</style>
