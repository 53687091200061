<template>
  <video
    id="my-video"
    ref="videoPlayer"
    class="video-js vjs-default-skin"
    controls
    :playsinline="true"
    preload="metadata"
  >
    <source :src="src" type="application/x-mpegURL" />
  </video>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import Hls from 'hls.js'

export default {
  name: 'VideoPlayer',
  props: {
    src: {
      type: String,
      required: true
    },
    start: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      player: null,
      hls: null
    }
  },
  mounted() {
    // Aguarde o DOM estar pronto
    this.$nextTick(() => {
      this.initializePlayer()
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
      this.player = null
    }
    if (this.hls) {
      this.hls.destroy()
      this.hls = null
    }
  },
  methods: {
    isSafari() {
      const ua = navigator.userAgent.toLowerCase()
      return (
        ua.includes('safari') && 
        !ua.includes('chrome') && 
        !ua.includes('android')
      )
    },
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    },
    initializePlayer() {
      const video = this.$refs.videoPlayer
      const isSafariOrIOS = this.isSafari() || this.isIOS()

      const options = {
        fluid: true,
        controls: true,
        playsinline: true,
        sources: [{
          src: this.src,
          type: 'application/x-mpegURL'
        }]
      }
      console.log('isSafariOrIOS', isSafariOrIOS)
      if (isSafariOrIOS) {
        // Para Safari/iOS, use reprodução nativa
        video.src = this.src
        this.player = videojs(video, {
          ...options,
          html5: {
            hls: {
              overrideNative: true,
            },
            nativeAudioTracks: true,
            nativeVideoTracks: true,
            nativeTextTracks: true
          }
        })
      } else if (Hls.isSupported()) {
        // Para outros navegadores, use HLS.js
        this.player = videojs(video, options)
        this.hls = new Hls({
          debug: false,
          enableWorker: true,
          lowLatencyMode: true
        })

        this.hls.loadSource(this.src)
        this.hls.attachMedia(video)

        this.hls.on(Hls.Events.ERROR, (event, data) => {
          // console.error('Erro HLS:', data)
          if (data.fatal) {
            this.hls.destroy()
            this.hls = null
          }
        })
      }

      // Configurar tempo inicial
      if (this.player) {
        this.player.ready(() => {
          this.player.currentTime(this.start)
        })

        // Tratamento de erros
        this.player.on('error', (e) => {
          console.error('Erro do player:', this.player.error())
        })
      }
    }
  }
}
</script>

<style>
.video-js {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}

/* Fixes para iOS */
video::-webkit-media-controls {
  display: inherit;
}

video::-webkit-media-controls-start-playback-button {
  display: inherit;
}
</style>