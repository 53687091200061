<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1 >Buscar treinamentos</h1>
          </div>
          <div class="col-md-3 ">
            <button @click="goToCart()">Carrinho</button>
          </div>
        </div>
      </div>
    </div>
    <div class="training-search">
      <h1>O que você está buscando?</h1>
      <div class="container search">
        <div class="row" style="padding-top:30px">
          <div class="offset-md-1 col-md-8">
            <input type="text" placeholder="Buscar por nome" v-on:keyup.enter="getCourses" v-model="search.searchText">
          </div>
          <div class="col-md-2">
            <button @click="getCourses()">Buscar</button>
          </div>
        </div>
      </div>
      
      <div class="container-fluid" style="padding-top: 60px;" v-if="segmentListWithTraining.length > 0">
        <div class="container">
          <h2>Treinamentos por tema</h2>
          <br>
          <div class="row segment-list">
            <div class="col-md-2 segment-list-item" v-for="segment in segmentListWithTraining" :key="segment._id" @click="goToSegment(segment._id)">
              <div class="segment-item">
                {{segment.name}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid" style="padding-top: 60px; padding-bottom: 45px;" v-if="teacherList.length > 0">
        <div class="container">
          <h2>Treinadores</h2>
          <br>
          <div class="row teacher-list">
            <div class="col-md-2 teacher-list-item" v-for="teacher in teacherList" :key="teacher._id" @click="goToTeacher(teacher._id)">
              <div class="teacher-item" :style="'background: url('+getImageThumbUrl(teacher.image)+')'"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="light-grey-bg container-fluid training-list"  id="training-list" v-if="featuredTrainingList.length > 0">
        <div class="container">
          <h2>Treinamentos em destaque</h2>
          <br>
          <div class="row" style="padding-bottom: 45px">
            <div class="col-md-3" v-for="training in featuredTrainingList" :key="training._id">
              <training-item :training = training :grey = false></training-item>
            </div>
          </div>
          <button class="all-training-btn" @click="search.searchText = null; getCourses()">Ver todos os treinamentos</button>
        </div>
      </div>

      <div class="container-fluid training-list" v-if="bestSellersTrainingList.length > 0">
        <div class="container">
          <h2>Treinamentos mais vendidos</h2>
          <br>
          <div class="row" style="padding-bottom: 45px">
            <div class="col-md-3" v-for="training in bestSellersTrainingList" :key="training._id">
              <training-item :training = training :grey = true></training-item>
            </div>
          </div>
          <button class="all-training-btn" @click="search.searchText = null; getCourses()">Ver todos os treinamentos</button>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Company.vue";
import { server } from "../../../js/variables.js";
import TrainingItem from "../../../components/TrainingItem.vue";
import VueScrollTo from "vue-scrollto";
import moment from 'moment';

export default {
  components: {
    MainLayout,
    TrainingItem,
  },
  data() {
    return {
      featuredTrainingList: [
        // {_id: 1, name: 'Treinamento 1', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 2, name: 'Treinamento 2', user:{name: 'Professor 2'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 3, name: 'Treinamento 3', user:{name: 'Professor 3'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 4, name: 'Treinamento 4', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
      ],
      bestSellersTrainingList: [
        // {_id: 1, name: 'Treinamento 1', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 2, name: 'Treinamento 2', user:{name: 'Professor 2'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 3, name: 'Treinamento 3', user:{name: 'Professor 3'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 4, name: 'Treinamento 4', user:{name: 'Professor 1'}, description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
      ],
      segmentList: [],
      teacherList: [],
      search: {
        searchText: null,
      },
    };
  },
  mounted() {
    var parameters = this.$route.query;
    this.getCourses();
    this.findSegments();
    this.getTeachers();
  },
  computed: {
    segmentListWithTraining: function () {
      let segmentsWithTrainings = [];
      // let trainings = this.featuredTrainingList;
      let trainings = JSON.parse(JSON.stringify(this.featuredTrainingList));
      let indexList = [];
      for(let t of trainings){
        // console.log('aqui', t)
        if(t.segment == null){
          // console.log('nulo');
          let index = trainings.indexOf(t);
          // console.log('index', index);
          // trainings.splice(index, 1);
          // console.log('t', t);
          indexList.push(index);
        }
      }
      for(let i = indexList.length - 1; i>=0; i--){
        // console.log('apagando', trainings)
        trainings.splice(indexList[i], 1);
        // console.log('apagou', trainings)
      }
      // console.log('this.featuredTrainingList', this.featuredTrainingList)
      // console.log('trainings', trainings)
      
      if(trainings.length > 0 && this.segmentList.length > 0){
        for(let segment of this.segmentList){
          if(trainings.some(training => training.segment._id === segment._id)){
            if(segmentsWithTrainings.some(segmentTraining => segmentTraining._id === segment._id)){
              console.log('ja tem: ')
            }else{
              segmentsWithTrainings.push(segment);
            }
          }
        }
      }
      // console.log('FINAL', segmentsWithTrainings)
      return segmentsWithTrainings
      
      // let segmentsWithTrainings = [];
      // for(let segment of this.segmentList){
      //   if(this.featuredTrainingList.some(training => training.segment._id === segment._id)){
      //     if(segmentsWithTrainings.some(segmentTraining => segmentTraining._id === segment._id)){
      //       console.log('ja tem: ')
      //     }else{
      //       segmentsWithTrainings.push(segment);
      //     }
      //   }
      // }
      // return segmentsWithTrainings
    },
  },
  methods: {
    getCourses() {
      let self = this;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }
      
      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        filter: this.search.searchText,
        visible: 1,
      };

      this.axios
      .get(server.url + "/api/course/", header)
        .then(function (response) {
          // self.opportunityList = [];
          let responseData = response.data.data;
          self.featuredTrainingList = [];
          self.bestSellersTrainingList = [];
          self.featuredTrainingList = responseData.course_list;
          // self.bestSellersTrainingList = responseData.course_list;

          for(let course of self.featuredTrainingList){
            course.created_at = moment(course.created_at).add(4, 'hours').format('DD/MM/YYYY');
          }
          VueScrollTo.scrollTo('#training-list');
          // for(let course of self.bestSellersTrainingList){
          //   course.created_at = moment(course.created_at).add(4, 'hours').format('DD/MM/YYYY');
          // }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    getTeachers() {
      let self = this;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }
      
      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        filter: this.search.searchText,
      };

      this.axios
      .get(server.url + "/api/course/users", header)
        .then(function (response) {
          self.teacherList = [];
          let responseData = response.data.data;
          self.teacherList = responseData.user_list;

        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    findSegments(){
      let self = this;
      this.axios
        .get(server.url + "/api/skill?filter=INDUSTRY_GENERAL", server.config)
        .then(function (response) {
          self.segmentList= [];
          let responseData = response.data.data.skill_list;
          self.segmentList = responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    goToSegment(segmentId){
      let self = this;
      const url = '/co/buscar-treinamentos/tema/' + segmentId;
      self.$router.push(url);
    },
    goToTeacher(teacherId){
      let self = this;
      const url = '/co/buscar-treinamentos/treinador/' + teacherId;
      self.$router.push(url);
    },
    goToCart(){
      let self = this;
      window.open("./co/carrinho", "_self");
      // const url = '/co/carrinho';
      // self.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";





@media only screen and (max-width: 600px) {
  .training-search {
    
  }
}
</style>
