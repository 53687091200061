<template>
    <div class="salesman-box box-item">

        <div class="row" v-if="size != 3">
            <div class="col-md-4">
                <div v-if="salesman.image" class="salesman-frame">
                    <img :src="getImageThumbUrl(salesman.image)" class="user-img" alt="">
                </div>

                <div v-else class="salesman-frame">
                    <img class="user-img" src="/static/img/logos/logo-vcvende.jpg" alt="">
                </div>
            </div>
            <div class="col-md-8">
                <h3>{{ salesman.name }} {{ salesman.lastname }}</h3>
                <div class="hr-yellow-light hr-4"></div>
                <p class="title" v-if="salesman.resume.title">{{ salesman.resume.title }}</p>
                <p class="place" v-if="salesman.address && salesman.address[0]">
                    {{ salesman.address[0].city }} | {{ salesman.address[0].state }}
                </p>
                <p class="summary" v-if="salesman.resume.summary != '' && salesman.resume.summary != null && size == 2">
                    {{ salesman.resume.summary }}
                </p>
                <p v-if="size == 1 && salesman.address && salesman.address.length > 0">
                    {{ salesman.address[0].city }}
                </p>
            </div>
            <div class="col-md-12 last" style="position: absolute; bottom: 17px;" v-if="size == 2">
                <button class="blue" @click="sendWhatsApp($event)">
                    <img class="icon-button" src="/static/svg/icone-entrar-em-contato-completo.svg" alt="">
                    Entrar em contato
                </button>
            </div>
        </div>

        <div class="row" v-if="size == 3">
            <div class="col-md-4">
                <div class="light-grey-bg" style="height: 88px; width: 88px;">
                    <img class="user-img-small" src="/static/svg/user.svg" alt="">
                </div>
            </div>
            <div class="col-md-8">
                <h3>{{ salesman.name }} {{ salesman.lastname }}</h3>
                <div class="hr-yellow-light hr-4"></div>
                <p>
                    <!-- {{salesman.address[0].city}} -->
                </p>
            </div>
        </div>

    </div>
</template>

<script>
import { server, local } from "../js/variables.js";
import userMixin from '../mixins/userMixin.js'

export default {
    mixins: [userMixin],
    props: ['salesman', 'size'],
    components: {

    },
    data() {
        return {
            favorite: 0
        }
    },
    // beforeCreate: function() {
    //     if (!localStorage.token) {
    //         server.config.headers = {
    //             Authorization: 'Bearer ' + localStorage.token
    //         }
    //     }
    // },
    mounted() {

    },
    watch: {
        user(newValue, oldValue) {
            // console.log(newValue._id, oldValue._id);
            // console.log(this.user);
            if (!this.user.resume) {
                this.user.resume = {
                    title: '',
                    summary: '',
                    experience: [],
                    education: [],
                };
                // this.postAddress = this.user.address[0];
            }
        }
    },
    methods: {
        sendWhatsApp(event) {
            let self = this;
            if (event.stopPropagation) event.stopPropagation();
            if (!localStorage.token) {
                
                self.$swal({
                icon: 'warning',
                title: 'Atenção',
                text: 'Você precisa estar logado para realizar está ação',
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'Ok'
                }).then((result) => {
                if (result.isConfirmed) {
                    const url = "/co/cadastro";
                    self.$router.push(url);
                }
                })
                return;
            }
            server.config.headers = {
                Authorization: "Bearer " + localStorage.token,
            };

            this.axios
                .get(server.url + "/api/user/" + this.salesman._id + "/call", server.config)
                .then(function (response) {
                    let responseData = response.data.data;
                    window.open(
                        "https://api.whatsapp.com/send?1=pt_BR&phone=55" +
                        responseData.user.phone,
                        "_blank"
                    );
                })
                .catch(function (error) {
                    self.output = error;
                    self.showError(error);
                    console.log(error);
                });
        },
        // goToProduct() {
        //     if (this.product.slug) {
        //         window.open ('./product/' + this.product.slug, '_self')
        //     } else {
        //         window.open ('./product/' + this.product._id, '_self')
        //     }

        // },
    }
}
</script>

<style lang="scss">
.salesman-box {
    // padding: 12px 16px;
    margin-bottom: 25px !important;
    height: 290px;

    h3 {
        font-size: 21px;
        margin-top: 10px;
        margin-bottom: 12px;
    }

    p {
        margin-top: 12px;
    }

    p.summary {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
    }

    p.place {
        padding-bottom: 0px;
    }

    p.title {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    button {
        font-weight: normal;
        height: 32px;
        width: 153px;
    }


    .user-img-empty {
        position: absolute;
        top: 21px;
        left: 37px;
    }

    .user-img-small {
        position: absolute;
        top: 8px;
        left: 31px;
    }
}

@media only screen and (max-width: 600px) {
    .salesman-box {
        min-height: 400px;
        white-space: initial;
        padding: 0px;


        .salesman-frame {
            height: 116px;
        }

        .user-img {
            height: 116px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%)
        }

        .user-img-empty {
            left: 37px;
        }

    }


}
</style>