<template>
  <main-layout>
	<!-- <company-menu></company-menu> -->
    
	<div class="container-fluid ask-password">
		<div class="row">
			<div class="col-md-4 ">
                <br><br>
				<a href target="_self">
					<img src="/static/svg/logo_clean.svg" alt="" class="center">
				</a>
                
				<h1>Solicitar nova senha</h1>
                <div class="inputs-ask-password center">
                    <input type="email" placeholder="Email" v-model="login.email">
                    
                    <button class="blue float-right" @click="changePassword()">Solicitar</button>
                </div>
			</div>
			<div class="col-md-8 no-padding">
                <img src="/static/img/login.jpg" alt />
			</div>
		</div>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Basic.vue";
// import Contact from "../../../components/Contact.vue";
// import ProductItem from "../../../components/ProductItem.vue";
// import CompanyMenu from "../../../components/CompanyMenu.vue";
import { server } from "../js/variables.js";
import { required, email, sameAs } from 'vuelidate/lib/validators'

export default {
  components: {
    MainLayout,
	// ProductItem,
	// CompanyMenu,
  },
  data () {
	return {
		slideList:[],
		login: {
			email: '',
		},
	}
  },
  mounted() {
	var parameters = this.$route.query;
	let self = this;
  },
  validations: {
	login: {
		email: { required },
	},
        
  },
  methods: {
    changePassword() {
      let self = this;
			if (!this.$v.$invalid){

				this.axios.post(  server.url + '/api/auth/forgot-password', {
					email: self.login.email,

				}, server.config)
				.then(function (response) {
					self.$swal({
						icon: 'success',
						title: 'Sucesso!',
						text: response.data.message,
						// footer: '<a href>Why do I have this issue?</a>'
					});
				})
				.catch(function (error) {
					self.output = error;
					self.showError(error);
					console.log(error);
				});
			}else{
				this.$v.$touch();
				this.$swal({
					icon: 'error',
					title: 'Oops...',
					text: 'Verifique os campos obrigatórios',
					// footer: '<a href>Why do I have this issue?</a>'
				});
			}
    },
	
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.ask-password{

    h1{
        text-align: center;
        // font-size: 36px;
        margin-top: 40px;
        margin-bottom: 30px;
    }

	

    img{
        // margin-top: 20px;
        // margin-bottom: 60px;
        // padding-top: 70px;
        // padding-bottom: 30px;
    }

    
    .inputs-ask-password{
        width: 85%;

        button{
            font-weight: normal;
            padding: 8px 20px; 
            margin-top: 15px;

            &:focus{
               outline: none; 
            }
        }

        input{
            background-color: var(--main-color-lightgrey);
            border: 0px !important;
            margin-bottom: 10px;
        }
    }
}

@media only screen and (max-width: 600px) {

	.ask-password .inputs-ask-password{
		margin-bottom: 50px;
		position: relative;
	}
}
</style>
