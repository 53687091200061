<template>
  <div class="video-upload">
    <file-upload
      v-if="!video"
      :url="url"
      :thumb-url="thumbUrl"
      :headers="headers"
      :uniqueId="getRandomArbitrary()"
      name="video"
      class="thumb"
      v-model="files"
      accept="video/mp4"
      :max-size=5000000000
      @change="onFileChange"
      ref="upload"
    ></file-upload>

    <div v-else class="thumb" style="padding-top: 0px">
      <!-- <video id="bitmovinplayer-video-my-player" 
           preload="auto" 
           :src="getVideoUrl(video)"  
           style="display: block !important;">
      </video> -->

        <!-- {{ getVideoUrl(video) }} -->
        <!-- <video controls preload="auto">
          <source :src="getVideoUrl(video)" type="application/x-mpegURL" />
      </video> -->
      <video-player-item :src = getVideoUrl(video)></video-player-item>

      <i
        class="fas fa-times-circle close-button"
        v-if="video.process_completed == true"
        @click="removeMediaVideo(video)"
      ></i>
    </div>
  </div>
</template>

<script>
import { server } from "../js/variables.js";
import VideoPlayerItem from "../components/VideoPlayerItem.vue";

export default {
  props: {
    video: {
      required: true,
    },
  },
  model: {
    prop: "video",
    event: "videochange",
  },
  components: {
    VideoPlayerItem,
  },
  data() {
    return {
      url: server.url + "/api/media/video/",
      headers: null,
      files: [],
      filesUploaded: [],
      fileUploaded: null,
      options: {},
    };
  },
  computed: {
    videoLocal: {
      get: function () {
        return this.livideost;
      },
      set: function (value) {
        this.$emit("videochange", value);
      },
    },
  },
  mounted() {
    this.headers = server.config.headers;
    this.url = server.url + "/api/media/video/";
    if (!this.headers.Authorization && localStorage.token) {
      this.headers = {
        Authorization: "Bearer " + localStorage.token,
      };
    }
  },
  methods: {
    getRandomArbitrary() {
      return "-" + Math.random() * (100 - 0) + 0;
    },
    thumbUrl(file) {
      return file.myThumbUrlProperty;
    },
    onFileChange(response) {
      // Handle files like:
      console.log(response.data);
      this.videoLocal = response.data.video;
      this.fileUploaded = response.data.video.url;
      this.$forceUpdate();
      this.$emit("uploaded");
    },
    getVideoThumbUrl(video) {
      return server.url + video.path + video.code + "/" + +video.thumb_url;
    },
    removeMediaVideo(video) {
      var self = this;
      this.axios
        .delete(server.url + "/api/media/video/" + video._id, server.config)
        .then(function (response) {
          // self.driver.video = null;
          // self.updateItem();
          self.$emit("deleted");
          self.$swal({
            icon: "success",
            title: "Vídeo removido",
            showConfirmButton: false,
            timer: 1800,
          });
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss" >
.file-upload {
  .input-wrapper {
    height: 100% !important;
    background-color: #efeaea !important;
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #a3aab4 !important;
    }

    .file-upload-label {
      .file-upload-icon {
        align-self: center;
        color: #555;
        display: initial !important;
      }

      div {
        font-size: 10pt;
        margin-top: 20px;
        color: black;
      }
    }
  }
}

video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.video-upload{
  .thumb{
    height: 392px;
  }
  
  .file-upload-label{
    height: 100%;
    padding: initial !important;
    padding-top: 30% !important;
  }
}
</style>