<template>
  <main-layout>
    <!-- <banner/> -->
    <div class="about">
      <img class="banner-about" src="/static/img/banner_about.jpg" alt />
      <div class="container">
        <about-company />
        <div class="afiliate-box">
          <h1>Escolha seu plano</h1>
          <div class="hr-yellow hr-4 center" style="margin-bottom: 38px;"></div>
          <h2 style="padding-top: 30px" v-if="salesmanFreePlan._id || salesmanPlan._id">Profissional</h2>
          <div class=" light-grey-bg" v-if="salesmanFreePlan._id || salesmanPlan._id">
            <div class="row" style="padding: 4px 0px; margin-top: 25px">
              <div class="col-md-6 no-padding-left-m" style="padding-right: 2px">
                <!-- salesman free -->
                <plan-item :isSubscribed="2" :freePlans="salesmanFreePlan"></plan-item>
              </div>
              <div class="col-md-6 no-padding-right-m" style="padding-left: 2px">
                <!-- salesman paid -->
                <plan-item :isSubscribed="0" :paidPlans="salesmanPlan"></plan-item>
              </div>
            </div>
          </div>
          <h2 style="padding-top: 30px" v-if="companyFreePlan._id || companyPlan._id">Empresarial</h2>
          <div class=" light-grey-bg" v-if="companyFreePlan._id || companyPlan._id">
            <div class="row" style="padding: 4px 0px; margin-top: 25px">
              <div class="col-md-6 no-padding-left-m" style="padding-right: 2px;">
                <!-- company free -->
                <plan-item :isSubscribed="2" :freePlans="companyFreePlan"></plan-item>
              </div>
              <div class="col-md-6 no-padding-right-m" style="padding-left: 2px;">
                <!-- company paid -->
                <plan-item :isSubscribed="0" :paidPlans="companyPlan"></plan-item>
              </div>
            </div>
          </div>
          <h2 style="padding-top: 30px" v-if="afiliateFreePlan._id || afiliatePlan._id">Afiliado</h2>
          <div class=" light-grey-bg" v-if="afiliateFreePlan._id || afiliatePlan._id">
            <div class="row" style="padding: 4px 0px; margin-top: 25px">
              <div class="col-md-6 no-padding-left-m" style="padding-right: 2px;">
                <!-- afiliate free -->
                <plan-item :isSubscribed="2" :freePlans="afiliateFreePlan"></plan-item>
              </div>
              <div class="col-md-6 no-padding-right-m" style="padding-left: 2px;">
                <!-- afiliate paid -->
                <plan-item :isSubscribed="0" :paidPlans="afiliatePlan"></plan-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>

      <contact />
      <benefits-for-you />
      <newsletter />
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import Contact from "../components/Contact.vue";
import BenefitsForYou from "../components/BenefitsForYou.vue";
import PlanItem from '../components/PlanItem.vue';
import AboutCompany from '../components/AboutCompany.vue';
import Newsletter from "../components/Newsletter.vue";
// import Banner from '../components/Banner.vue'
import { server } from "../js/variables.js";

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
    Contact,
    // Slider,
    BenefitsForYou,
    PlanItem,
    AboutCompany,
    Newsletter,
    // Banner,
  },
  data() {
    return {
        // planList: [
        //     {id: 1, name: "Plano profissional", desc: "Anuncie suas vagas gratuitamente \n Anuncie seu currículo gratuitamente<br/>Anuncie sua empresa, produtos e serviços gratuitamente<br/>Cadastre seus vendedores no vocevendepara vender seus produtos e também anunciar no vocecompra, gratuitamente.", price: 6.90},
        //     {id: 2, name: "Plano corporativo", desc: "Anuncie suas vagas gratuitamente \n Anuncie seu currículo gratuitamente<br/>Anuncie sua empresa, produtos e serviços gratuitamente<br/>Cadastre seus vendedores no vocevendepara vender seus produtos e também anunciar no vocecompra, gratuitamente.", price: 6.90},
        // ],
      companyPlan: {},
      salesmanPlan: {},
      afiliatePlan: {},
      companyFreePlan: {},
      salesmanFreePlan: {},
      afiliateFreePlan: {},
    };
  },
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    self.getPlans();

  },
  methods: {
    getPlans() {
      let self = this;
      // if (!localStorage.token) {
      //     return
      // }
      // server.config.headers = {
      //     Authorization: 'Bearer ' + localStorage.token
      // }
      this.axios
        .get(server.url + "/api/plan?is_enable=true", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.planList = responseData.plan_list;
          for (let plan of self.planList) {
            if (plan.type == "SALESMAN" && plan.payment_option.length > 0) {
              self.salesmanPlan = plan;
            } else if (plan.type == "COMPANY" && plan.payment_option.length > 0) {
              self.companyPlan = plan;
            } else if (plan.type == "AFILIATE" && plan.payment_option.length > 0) {
              self.afiliatePlan = plan;
            } else if (plan.type == "SALESMAN" && plan.payment_option.length == 0) {
              self.salesmanFreePlan = plan;
            } else if (plan.type == "COMPANY" && plan.payment_option.length == 0) {
              self.companyFreePlan = plan;
            } else if (plan.type == "AFILIATE" && plan.payment_option.length == 0) {
              self.afiliateFreePlan = plan;
            }
          }
          for (let payment of self.salesmanPlan.payment_option) {
            if (payment.interval == 1) {
              self.salesmanPlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.salesmanPlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.salesmanPlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.salesmanPlan.payment_12 = payment;
            }
          }

          for (let payment of self.companyPlan.payment_option) {
            if (payment.interval == 1) {
              self.companyPlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.companyPlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.companyPlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.companyPlan.payment_12 = payment;
            }
          }

          for (let payment of self.afiliatePlan.payment_option) {
            if (payment.interval == 1) {
              self.afiliatePlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.afiliatePlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.afiliatePlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.afiliatePlan.payment_12 = payment;
            }
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  }
};
</script>

<style lang="scss">
// @import "../scss/reset.scss";
// @import "../scss/base.scss";

.banner-about {
  width: 100%;
  margin-top: -15px;
  margin-bottom: 50px;
}

.afiliate-box {
  margin-bottom: 60px;

  h1 {
    text-align: center;
    margin-top: 45px !important;
    margin-bottom: 15px !important;
  }
}

@media only screen and (max-width: 600px) {
  .banner-about {
    margin-top: 15px;
    margin-bottom: 35px;
  }

  .afiliate-box {
    margin-bottom: 20px;
  }

}
</style>
