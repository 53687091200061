<template>
  <main-layout>
    <!-- <pre> password {{ $v.user.password }}</pre>
	<pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container-fluid register-opportunity light-grey-bg company-page">
      <div class=" menu-content-box">
        <br />
        <div class="row">
          <div class="col-md-12 last">
            <button class="blue" type="button" style="width: 150px; margin-bottom: 15px"
              v-if="this.$route.fullPath != '/co/vaga/new'" data-toggle="modal" data-target="#opportunityDetailsModal">
              Visualizar vaga
            </button>
          </div>
          <div class="col-md-6">
            <div class="title-bg-grey">
              <h3>Dados da vaga</h3>
            </div>
            <br />

            <label for="name">Título*</label>
            <span v-if="$v.job_opportunity.name.$error">Campo não pode estar vazio</span>
            <input id="name" type="text" v-model="$v.job_opportunity.name.$model" />

            <label for="size">Cargo*</label>
            <v-select label="label" :options="positionList" v-model="$v.job_opportunity.position.$model"
              :reduce="(position) => position.value">
            </v-select>

            <label for="size">Tipo*</label>
            <v-select label="label" :options="employmentTypeList" v-model="$v.job_opportunity.employment_type.$model"
              :reduce="(employmentType) => employmentType.value">
            </v-select>

            <!-- <label for="size">Graduação desejada</label>
                <v-select label="value" 
                        :options="sizeList" 
                        v-model="$v.job_opportunity.salary_min.$model" 
                        :reduce="size => size.value" >
                </v-select> -->

            <!-- <label for="size">Data de expiração</label>
          <date-picker
            v-model="job_opportunity.expiration"
            value-type="YYYY-MM-DD"
            format="DD/MM/YYYY"
            type="date"
            placeholder="Selecione a data"
          >
          </date-picker> -->

            <label for="segment">Status*</label>
            <v-select class="remove-select-x" label="label" :options="statusList"
              v-model="$v.job_opportunity.status.$model" :reduce="(status) => status.value">
            </v-select>

            <div class="title-bg-grey">
              <h3>Dados da vaga</h3>
            </div>
            <br />

            <label for="state">Estado</label>
            <!-- <input
            id="state"
            type="text"
            v-model="$v.job_opportunity.address.state.$model"
          /> -->
            <v-select label="sigla" :options="stateList" v-on:input="findCities('state')" :reduce="state => state.sigla"
              v-model="job_opportunity.address.state" :placeholder="'(Selecione)'">
            </v-select>

            <label for="city">Cidade</label>
            <!-- <input
            id="city"
            type="text"
            v-model="$v.job_opportunity.address.city.$model"
          />
          <span v-if="$v.job_opportunity.address.city.$error"
            >Campo não pode estar vazio</span
          > -->
            <v-select label="nome" :options="cityList" :reduce="city => city.nome" v-model="job_opportunity.address.city"
              :placeholder="cityPlaceholder"
              :disabled="cityPlaceholder == 'Selecione estado' || cityPlaceholder == 'Carregando...'">
            </v-select>

            <label for="segment">Disponibilidade de viagem</label>
            <v-select label="name" :options="travelYesNo" v-model="$v.job_opportunity.availability_for_travel.$model"
              :reduce="(YesNo) => YesNo.value">
            </v-select>

            <div class="title-bg-grey">
              <h3>Faixa salarial</h3>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <money v-model="$v.job_opportunity.salary_range_start.$model"></money>
                <span v-if="$v.job_opportunity.salary_range_start.$error">Campo não pode estar vazio</span>
              </div>
              <div class="col-md-6">
                <money v-model="$v.job_opportunity.salary_range_end.$model"></money>
                <span v-if="$v.job_opportunity.salary_range_end.$error">Campo não pode estar vazio</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title-bg-grey">
              <h3>Descrição*</h3>
            </div>
            <br />
            <vue-editor v-model="$v.job_opportunity.description.$model" placeholder="Digite a descrição da vaga"
              class="textArea" :editorToolbar="customToolbar"></vue-editor>
          </div>

          <div class="col-md-12 mobile-size">
            <br />
            <div class="title-bg-grey">
              <h3>Segmentos que têm experiência</h3>
            </div>
            <br />
            <div class="skill col-md-12" v-for="skill in filterBySkillType('INDUSTRY_GENERAL')" :key="skill._id">
              <div v-if="skill.skill.type == 'INDUSTRY_GENERAL'" class="row">
                <br /><br />
                <div class="col-md-6">
                  <label for="segment">Segmento</label>
                  <v-select label="name" :options="segmentList" v-model="skill.skill">
                  </v-select>
                </div>

                <div class="col-md-6">
                  <label for="segment">Nível</label>
                  <v-select label="label" :options="proficiencySegmentList" v-model="skill.proficiency"
                    :reduce="(proficiency) => proficiency.name">
                  </v-select>
                </div>
              </div>
            </div>
            <button class="float-right yellow" @click="addSkill('INDUSTRY_GENERAL')">
              Adicionar
            </button>
          </div>

          <div class="col-md-12 mobile-size">
            <br />
            <div class="title-bg-grey">
              <h3>Idiomas</h3>
            </div>
            <br />
            <div class="skill col-md-12" v-for="skill in filterBySkillType('LANGUAGE')" :key="skill._id">
              <div v-if="skill.skill.type == 'LANGUAGE'" class="row">
                <br /><br />
                <div class="col-md-6">
                  <label for="segment">Idioma</label>
                  <v-select label="name" :options="languageList" v-model="skill.skill">
                  </v-select>
                </div>

                <div class="col-md-6">
                  <label for="segment">Nível</label>
                  <v-select label="label" :options="proficiencyList" v-model="skill.proficiency"
                    :reduce="(proficiency) => proficiency.name">
                  </v-select>
                </div>
              </div>
            </div>

            <button class="float-right yellow" @click="addSkill('LANGUAGE')">
              Adicionar
            </button>
          </div>

          <div class="col-md-12 last" style="margin-top: 24px">
            <!-- <button>Remover</button> //TODO -->
            <button class="remove" type="button" @click="deleteOpportunity()"
              v-if="this.$route.fullPath != '/co/vaga/new'">
              Excluir
            </button>
            <button class="blue" type="button" @click="updateOpportunity()" v-if="this.$route.fullPath != '/co/vaga/new'">
              Atualizar
            </button>
            <button class="blue" type="button" @click="createOpportunity()" v-if="this.$route.fullPath == '/co/vaga/new'">
              Criar
            </button>
          </div>
        </div>

        <hr id="candidates-scroll" />
        <div class="row" v-if="job_opportunity.candidates.length != 0">
          <div class="col-md-12">
            <h2>Canditatos</h2>
          </div>
          <div class="col-md-12" v-for="candidate in job_opportunity.candidates" :key="candidate._id" data-toggle="modal"
            data-target="#salesmanDetailsModal">
            <!-- <salesman-item :salesman = candidate.user :size = 3 > </salesman-item> -->
            <approve-deny-item :representation="candidate"> </approve-deny-item>
          </div>
        </div>
        <h2 class="center" v-if="job_opportunity.candidates.length == 0">
          Esta vaga ainda não tem candidatos
        </h2>
      </div>
    </div>
    <opportunity-details-modal :modal_id="'opportunityDetailsModal'" :showCandidateButton="false"
      :opportunity="job_opportunity"></opportunity-details-modal>
  </main-layout>
</template>

<script>
import companyMixin from "../../../mixins/companyMixin.js";
import MainLayout from "../../../layouts/CompanyProfile.vue";
import ApproveDenyItem from "../../../components/ApproveDenyItem.vue";
// import SalesmanItem from "../../../components/SalesmanItem.vue";
import OpportunityDetailsModal from "../../../components/OpportunityDetailsModal.vue";
import { server, local } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";
import VueScrollTo from "vue-scrollto";
import moment from 'moment';

export default {
  mixins: [companyMixin],
  components: {
    MainLayout,
    // SalesmanItem,
    ApproveDenyItem,
    OpportunityDetailsModal,
    // CompanyMenu,
  },
  data() {
    return {
      job_opportunity: {
        name: "",
        description: "",
        position: "INTERN",
        employment_type: "FULLTIME",
        availability_for_travel: false,
        salary_range_start: "",
        salary_range_end: "",
        status: 'OPEN',
        expiration: "",
        address: {
          zipcode: "",
          street: "",
          number: "",
          complement: "",
          district: "",
          city: "",
          state: "",
        },
        skills: [],
        // company: {},
        candidates: [],
      },
      // confirmPassword: '',
      candidateList: [],
      travelYesNo: [
        { _id: 0, name: "Não", value: false },
        { _id: 1, name: "Sim", value: true },
      ],
      statusList: [
        { label: "Ativa", value: "OPEN" },
        { label: "Fechada", value: "CLOSED" },
        { label: "Cancelada", value: "CANCELED" },
        { label: "Em espera", value: "STANDBY" },
      ],
      employmentTypeList: [
        { label: "Integral", value: "FULLTIME" },
        { label: "Meio período", value: "PARTTIME" },
      ],
      proficiencyList: [
        { name: null, label: "(Sem conhecimento)" },
        { name: 1, label: "Básico" },
        { name: 2, label: "Básico/ intermediário" },
        { name: 3, label: "Intermediário" },
        { name: 4, label: "Avançado" },
        { name: 5, label: "Fluente" },
      ],
      proficiencySegmentList: [
        { name: null, label: "(Sem conhecimento)" },
        { name: 1, label: "Básico" },
        // { name: 2, label: "Básico/ intermediário" },
        { name: 3, label: "Intermediário" },
        { name: 4, label: "Avançado" },
        // {name: 5, label:'Fluente'},
      ],
      segmentList: [],
      languageList: [],
      postLanguage: "",
      stateList: [],
      cityList: [],
    };
  },
  validations: {
    job_opportunity: {
      name: { required },
      description: { required },
      position: { required },
      employment_type: { required },
      availability_for_travel: "",
      salary_range_start: "",
      salary_range_end: "",
      status: "",
      address: {
        zipcode: "",
        street: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",
      },
    },
    postLanguage: {},
  },
  mounted() {
    var parameters = this.$route.query;
    var id = this.$route.params.id;
    let self = this;
    this.findLanguages();
    this.findSegments();
    this.findStates();
    // this.job_opportunity.expiration = moment().format('YYYY-MM-DD');
    this.job_opportunity.expiration = moment().add(1, 'd').format('YYYY-MM-DD');
    if (this.$route.query.candidatos == 'x') {
      VueScrollTo.scrollTo('#candidates-scroll');
    }

  },
  watch: {
    company(newValue, oldValue) {
      var parameters = this.$route.query;
      var id = this.$route.params.id;
      let self = this;
      // console.log(newValue, oldValue);
      // console.log("watch company", this.company);
      if (this.$route.fullPath != "/co/vaga/new" && self.company._id) {
        this.axios
          .get(
            server.url +
            "/api/company/" +
            self.company._id +
            "/job-opportunity/" +
            id,
            server.config
          )
          .then(function (response) {
            let responseData = response.data.data;
            self.job_opportunity = responseData.job_opportunity;
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      }
    },
  },
  methods: {
    createOpportunity() {
      let self = this;
      if (!this.$v.$invalid) {
        if (
          local.permissions.advertiseVacancies <
          local.permissions.advertiseVacanciesMax
        ) {

          let itensToRemove = [];
          for (let skill of this.job_opportunity.skills) {
            if (!skill.skill._id || skill.skill._id == '') {
              itensToRemove.push(skill);
            }
            else if (!skill.proficiency) {
              itensToRemove.push(skill);
            }

            else if (skill.proficiency == null) {
              itensToRemove.push(skill);
            }
          }

          for (let item of itensToRemove) {
            this.removeSkill(item);
          }

          this.axios
            .post(
              server.url +
              "/api/company/" +
              self.company._id +
              "/job-opportunity",
              {
                job_opportunity: self.job_opportunity,
              },
              server.config
            )
            .then(function (response) {
              let responseData = response.data;
              self
                .$swal({
                  icon: "success",
                  title: "Sucesso",
                  text: responseData.message,
                  // footer: '<a href>Why do I have this issue?</a>'
                  showCancelButton: false,
                  confirmButtonColor: "#053360",
                  confirmButtonText: "Ok",
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    window.open("./co/vagas", "_self");
                  }
                });
            })
            .catch(function (error) {
              self.output = error;
              self.showError(error);
            });
        } else {
          self
            .$swal({
              icon: "error",
              title: "Aviso",
              text: "Você atingiu o limite de vagas criadas",
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: "#053360",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // window.open("./vc/candidaturas", "_self");
                const url = "/vc/perfil";
                self.$router.push(url);
              }
            });
        }
      } else {
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    reloadPage() {
      window.location.reload();
    },
    removeSkill(skill) {
      let index = this.job_opportunity.skills.indexOf(skill);
      if (index > -1) {
        this.job_opportunity.skills.splice(index, 1);
      }
    },
    updateOpportunity() {
      let self = this;
      var id = this.$route.params.id;
      if (!this.$v.$invalid) {
        if (!localStorage.token) {
          return;
        }
        let itensToRemove = [];
        // console.log('update op', this.job_opportunity.skills.length)
        for (let skill of this.job_opportunity.skills) {
          if (!skill.skill._id || skill.skill._id == '') {
            // this.removeSkill(skill);
            itensToRemove.push(skill);
          }
          else if (!skill.proficiency) {
            // this.removeSkill(skill);
            itensToRemove.push(skill);
          }

          else if (skill.proficiency == null) {
            // this.removeSkill(skill);
            itensToRemove.push(skill);
          }
        }

        // console.log('itensToRemove', itensToRemove)
        for (let item of itensToRemove) {
          this.removeSkill(item);
        }
        // this.user.resume.skills = this.postSkills;
        // console.log('update op depois', this.job_opportunity.skills)

        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };
        this.axios
          .put(
            server.url +
            "/api/company/" +
            self.company._id +
            "/job-opportunity/" +
            id,
            {
              job_opportunity: self.job_opportunity,
            },
            server.config
          )
          .then(function (response) {
            let responseData = response.data;
            // location.reload();
            // VueScrollTo.scrollTo('#user-data-form');
            self
              .$swal({
                icon: "success",
                title: "Sucesso",
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: "#053360",
                confirmButtonText: "Ok",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  // location.reload();
                  window.open('/co/vaga/' + id, "_self");
                }
              });
          })
          .catch(function (error) {
            self.load();
            self.output = error;
            self.showError(error);
          });
      } else {
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    deleteOpportunity() {
      let self = this;
      var id = this.$route.params.id;

      this.$swal
        .fire({
          title: "Realmente deseja excluir esta vaga?",
          text: "Esta ação é irreversível!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#053360",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Excluir",
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (!localStorage.token) {
              return;
            }
            server.config.headers = {
              Authorization: "Bearer " + localStorage.token,
            };

            this.axios
              .delete(
                server.url +
                "/api/company/" +
                self.company._id +
                "/job-opportunity/" +
                id,
                server.config
              )
              .then(function (response) {
                let responseData = response.data;
                self
                  .$swal({
                    icon: "success",
                    title: "Sucesso",
                    text: responseData.message,
                    // footer: '<a href>Why do I have this issue?</a>'
                    showCancelButton: false,
                    confirmButtonColor: "#053360",
                    confirmButtonText: "Ok",
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      window.open("./co/vagas", "_self");
                    }
                  });
              })
              .catch(function (error) {
                self.output = error;
                self.showError(error);
              });
          }
        });
    },
    viewOpportunity() {
      let self = this;
      var id = this.$route.params.id;
      window.open("./co/vaga/" + id + "/visualizacao", "_self");
    },
    addSkill(type, skill) {
      if (!this.job_opportunity) {
        return;
      }
      if (!this.job_opportunity.skills) {
        return;
      }
      if (!skill) {
        skill = {
          _id: "",
          name: "",
          type: type,
        };
      }

      this.job_opportunity.skills.push({
        skill: skill,
        proficiency: null,
      });
    },
    filterBySkillType(type) {
      var list = [];
      for (let skill of this.job_opportunity.skills) {
        if (skill.skill.type == type) {
          list.push(skill);
        }
      }
      return list;
    },
    findLanguages() {
      let self = this;
      this.axios
        .get(server.url + "/api/skill?filter=LANGUAGES", server.config)
        .then(function (response) {
          self.languageList = [];
          let responseData = response.data.data.skill_list;
          self.languageList = responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    findSegments() {
      let self = this;
      this.axios
        .get(server.url + "/api/skill?filter=INDUSTRY_GENERAL", server.config)
        .then(function (response) {
          self.segmentList = [];
          let responseData = response.data.data.skill_list;
          self.segmentList = responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    findStates() {
      let self = this;
      this.axios
        .get(server.url + "/webservices/ibge/estados", server.config)
        .then(function (response) {
          self.stateList = [];
          let responseData = response.data.data;

          for (let state of responseData.state_list) {
            if (state != '') self.stateList.push(state);
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    findCities(type) {
      let self = this;
      let stateName = '';

      if (!this.job_opportunity.address.state) {
        this.cityList = [];
        this.job_opportunity.address.city = null;
        this.cityPlaceholder = 'Selecione estado';
      } else if (this.job_opportunity.address.state && type == 'state') {
        this.cityList = [];
        this.job_opportunity.address.city = null;
        // this.cityPlaceholder = 'Selecione estado';
        this.findCities(this.job_opportunity.address.state);
      } else if (this.job_opportunity.address.state && type == 'city') {
        // this.findCities(this.job_opportunity.address.state);
      }

      if (self.job_opportunity.address.state != undefined && self.job_opportunity.address.state != '') {
        stateName += '?uf=' + self.job_opportunity.address.state;
        self.cityPlaceholder = 'Carregando...';
        this.axios
          .get(server.url + "/webservices/ibge/cidades" + stateName, server.config)
          .then(function (response) {
            self.cityPlaceholder = 'Selecione';
            self.cityList = [];
            let responseData = response.data.data;

            for (let city of responseData.city_list) {
              if (city != '') self.cityList.push(city);
            }
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      }
    },

  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.register-opportunity {
  h1 {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  label {
    margin-bottom: 5px;
  }

  h2 {
    margin-bottom: 15px;
  }

  .mx-datepicker {
    display: initial;
    width: auto;
  }

  .mx-btn-text {
    width: auto !important;
  }

  .mx-input {
    height: 38px;
    border: 1px solid #b2bee0 !important;
  }

  .textArea {
    height: 80%;
    max-width: 83vw;
  }
}

@media only screen and (max-width: 600px) {
  .register-opportunity {
    padding-top: 135px;


    .mobile-size {
      margin: 2.5em auto;
    }

    .textArea {
      height: auto;
      max-width: 83vw;
    }
  }

}
</style>
