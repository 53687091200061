<template>
  <div class="loading" v-if="requests > 0">
    <div class="loading_line_wrapper">
      <div class="loading_line">
        <div class="loading_line_inner loading_line_inner--1"></div>
        <div class="loading_line_inner loading_line_inner--2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      requests: 0
    };
  },
  mounted() {
    let self = this;
    this.axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        self.requests++;
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    this.axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        self.requests--;
        if (self.requests < 0) self.requests = 0;
        return response;
      },
      function (error) {
        self.requests--;
        if (self.requests < 0) self.requests = 0;
        // Do something with response error
        return Promise.reject(error);
      }
    );
  },
};
</script>

<style lang="scss">
@import "../scss/reset.scss";
@import "../scss/base.scss";
@import "../scss/form.scss";
@import "../css/mobile.css";

$duration: 1s;

.loading {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3px;    
  bottom: 0px;
  z-index: 1000;
}

.loading_line_wrapper {
  width: 100%;
  height: 5px;
  position: relative;
  top: 0px;
  left: 0px;
  bottom: auto;
  right: auto;
}

.loading_line {
  position: relative;
  top: 0px;
  left: 0px;
  bottom: auto;
  right: auto;
  width: 100%;
  height: 100%;
  transform-origin: 100% 0%;
  animation: kf_loading_line $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s
    infinite;

  .loading_line_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: auto;
    right: auto;
    width: 50%;
    height: 100%;
    background: var(--main-color-primary);
    transform-origin: 0% 0%;
  }
}
.loading_line_inner--1 {
  opacity: 0; //change for inner line opacity, currently 0/hidden
  animation: kf_loading_line_inner--1 $duration
    cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
}

.loading_line_inner--2 {
  opacity: 1;
  animation: kf_loading_line_inner--2 $duration
    cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
}

.loading_line_inner {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: auto;
  right: auto;
  width: 100%;
  height: 100%;
  background-color: red;
  transform-origin: 0% 0%;
}

@keyframes kf_loading_line {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes kf_loading_line_inner--1 {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes kf_loading_line_inner--2 {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}
</style>