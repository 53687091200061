<template>
  <main-layout>
    <div class="container-fluid light-grey-bg">
      <div class="earnings menu-content-box">
        <h1>Recebimentos</h1>
        <!-- <div style="">
          <v-select
            label="value"
            :options="monthList"
            v-model="filter.month"
            :placeholder="'Mês'"
            :reduce="month => month._id"
            @input="getEarnings"
          ></v-select>
          <v-select
            style="margin-left: 10px"
            label="value"
            :options="yearList"
            v-model="filter.year"
            :placeholder="'Ano'"
            @input="getEarnings"
          ></v-select>
        </div> -->
        <hr style="margin-top: 30px; margin-bottom:22px">

        <div class="row">
          <div class="col-md-12 row remove-side-margin" style="padding-bottom: 8px; padding-top: 8px;" v-for="earning in earningList" :key="earning._id">
            <!-- <div> -->
              <!-- <div class="earning-box">
                {{earning.created_at}} - Afiliado: {{earning.user.name}} {{earning.user.lastname}} - Status: {{ earningStatus[earning.status] }}
              </div>
              <div class="earning-value-box">
                {{earning.amount | toCurrency}}
              </div> -->
            <!-- </div> -->

            <div class="col-md-10 earning-box">
              {{earning.month}}/{{earning.year}} - {{earning.user.name}} {{earning.user.lastname}} - Status: {{ earningStatus[earning.status] }}
            </div>
            <div class="col-md-2 earning-value-box">
              {{earning.amount | toCurrency}}
            </div>
          </div>
          <div class="col-md-12">
            <h2 style="padding-top: 15px" v-if="earningList.length == 0 || earningList == null">Você não tem nada a receber</h2>
          </div>
        </div>

      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/SalesmanProfile.vue";
import userMixin from "../../../mixins/userMixin.js";
import moment from "moment";
import VueScrollTo from "vue-scrollto";
import { server } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      filter: {
        month: null,
        year: null,
      },
      earningList: [
        // { _id: 1, day: 1, type: "Afiliado", user: "#19292918", value: 40.0 },
        // { _id: 2, day: 2, type: "Afiliado", user: "#19292918", value: 28.0 },
        // { _id: 3, day: 5, type: "Afiliado", user: "#19292918", value: 43.0 },
        // { _id: 4, day: 13, type: "Afiliado", user: "#19292918", value: 10.0 },
        // { _id: 5, day: 9, type: "Afiliado", user: "#19292918", value: 12.0 },
        // { _id: 6, day: 12, type: "Afiliado", user: "#19292918", value: 92.0 },
      ],
      earningStatus: {
        PAID: 'Pago',
        WAITING: 'Esperando',
        CANCELED: 'Erro, verifique se sua bancaria esta cadastrada corretamente',
      },
    };
  },
  validations: {},
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    
    this.yearList = []
    var max = new Date().getFullYear()
    var min = max - 100
    var years = []

    for (var i = max; i >= min; i--) {
      this.yearList.push(i)
    }
  },
  watch: {
    user(newValue, oldValue) {
      if(this.user != null && this.user._id){
        this.getEarnings();
      }
      
    },
  },

  methods: {
    getEarnings(){
      let self = this;
			if (!localStorage.token) {
				return
			}
			server.config.headers = {
				Authorization: 'Bearer ' + localStorage.token
			}

      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        from: null,
        to: null,
      };

      if(self.filter.month != null && self.filter.year != null){
        
        let date = '01/' + self.filter.month + '/' + self.filter.year;
        let lastDate = new Date(self.filter.year, self.filter.month, 0);
        lastDate = moment(lastDate).add(4, 'hours').format('DD/MM/YYYY');
        let dateFormated = moment(date , 'DD/MM/YYYY').format('YYYY-MM-DD');
        let dateFormatedLast = moment(lastDate , 'DD/MM/YYYY').format('YYYY-MM-DD');

        header["params"] = {
          from: dateFormated,
          to: dateFormatedLast,
        };
      }

      this.axios
        .get(server.url + '/api/user/' + this.user._id + '/transfer', header)
        .then(function (response) {
          let responseData = response.data.data;
          self.earningList = responseData.transfer_list;
          for(let earning of self.earningList){
            let date = self.fixOneDate(earning.created_at);
            earning.created_at = date;
            let string = '' + earning.month;
            if (string.length == 1){
              string = '0' + string;
              earning.month = string;
            }
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    fixOneDate(date) {
      let f = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
      return moment(date).add(4, 'hours').format('DD/MM/YYYY');
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.earnings {

  h1 {
    margin-bottom: 40px;
  }

  .earning-btns {
    width: 137px;
    height: 34px;
    color: var(--main-color-primary);
  }

  .v-select{
    width: 160px;
    height: 34px;
    display: inline-block;
  }

  .vs__dropdown-toggle{
    height: 38px;
  }

  // .earning-box2 {
  //   border: 1px solid #c7c7c7;
  //   border-radius: 6px;
  //   padding: 12px 36px;
  //   width: 85%;
  //   display: inline-block;

  //   font-size: 13px;
  //   font-weight: bold;
  //   color: var(--main-title-color);
  // }

  // .earning-value-box2 {
  //   background-color: var(--main-color-primary);
  //   border: 1px solid #c7c7c7;
  //   border-radius: 6px;
  //   width: 15%;
  //   padding: 12px;
  //   display: inline-block;

  //   font-size: 13px;
  //   font-weight: bold;
  //   color: white;
  //   text-align: center;
  // }
  
  .earning-box {
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    padding: 12px 36px;

    font-size: 13px;
    font-weight: bold;
    color: var(--main-title-color);
  }

  .earning-value-box {
    background-color: var(--main-color-primary);
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    padding: 12px;

    font-size: 13px;
    font-weight: bold;
    color: white;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .earnings {
    border-radius: 10px;
    padding: 20px;
    margin: 15px 0px;

    h1{
      text-align: center;
      width: 100%;
      margin-bottom: 30px;
    }

    .v-select{
      width: 130px;
      font-size: 14px;
    }

  }
}
</style>
