<template>
  <main-layout>
    <div class="container-fluid light-grey-bg">
      <div class="indications menu-content-box">
        <h1>Minhas indicações</h1>
        <div style="">
          <v-select
            label="value"
            :options="monthList"
            v-model="filter.month"
            :placeholder="'Mês'"
            :reduce="month => month._id"
            @input="getAfiliates"
          ></v-select>
          <v-select
            style="margin-left: 10px"
            label="value"
            :options="yearList"
            v-model="filter.year"
            :placeholder="'Ano'"
            @input="getAfiliates"
          ></v-select>
        </div>
        <hr style="margin-top: 30px; margin-bottom:22px">

        <div class="row">
          <div class="col-md-12" style="padding-bottom: 8px; padding-top: 8px;" v-for="afiliate in afiliateList" :key="afiliate._id">
            <div v-if="afiliate.company">
              <div class="indication-box" >
                Empresa: {{afiliate.company.name_fancy}}
              </div>
              <div class="indication-value-box">
                {{afiliate.company.created_at}}
              </div>
            </div>
            <div v-else>
              <div class="indication-box">
                Usuário: {{afiliate.user.name}} {{afiliate.user.lastname}}
              </div>
              <div class="indication-value-box">
                {{afiliate.user.created_at}}
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <h2 style="padding-top: 15px" v-if="afiliateList.length == 0 || afiliateList == null">Você não possui nenhum afiliado</h2>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/SalesmanProfile.vue";
import userMixin from "../../../mixins/userMixin.js";
import moment from "moment";
import VueScrollTo from "vue-scrollto";
import { server } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      filter: {
        month: null,
        year: null,
      },
      indicationList: [
        // { _id: 1, day: 1, type: "Afiliado", user: "#19292918", value: 40.0 },
        // { _id: 2, day: 2, type: "Afiliado", user: "#19292918", value: 28.0 },
        // { _id: 3, day: 5, type: "Afiliado", user: "#19292918", value: 43.0 },
        // { _id: 4, day: 13, type: "Afiliado", user: "#19292918", value: 10.0 },
        // { _id: 5, day: 9, type: "Afiliado", user: "#19292918", value: 12.0 },
        // { _id: 6, day: 12, type: "Afiliado", user: "#19292918", value: 92.0 },
      ],
      afiliateList: [
        // { _id: 1, date: '10/10/2020', type: "Afiliado", user: "Nome do usuario", value: 40.0 },
        // { _id: 2, date: '10/10/2020', type: "Afiliado", user: "Nome do usuario", value: 28.0 },
        // { _id: 3, date: '10/10/2020', type: "Afiliado", user: "Nome do usuario", value: 43.0 },
        // { _id: 4, date: '10/10/2020', type: "Afiliado", user: "Nome do usuario", value: 10.0 },
        // { _id: 5, date: '10/10/2020', type: "Afiliado", user: "Nome do usuario", value: 12.0 },
        // { _id: 6, date: '10/10/2020', type: "Afiliado", user: "Nome do usuario", value: 92.0 },
      ],
    };
  },
  validations: {},
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    this.getAfiliates();
    
    this.yearList = []
    var max = new Date().getFullYear()
    var min = max - 100
    var years = []

    for (var i = max; i >= min; i--) {
      this.yearList.push(i)
    }

  },
  watch: {
    user(newValue, oldValue) {},
  },

  methods: {
    getAfiliates(){
      let self = this;
			if (!localStorage.token) {
				return
			}
			server.config.headers = {
				Authorization: 'Bearer ' + localStorage.token
			}

      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        from: null,
        to: null,
      };

      if(self.filter.month != null && self.filter.year != null){
        
        let date = '01/' + self.filter.month + '/' + self.filter.year;
        let lastDate = new Date(self.filter.year, self.filter.month, 0);
        lastDate = moment(lastDate).add(4, 'hours').format('DD/MM/YYYY');
        let dateFormated = moment(date , 'DD/MM/YYYY').format('YYYY-MM-DD');
        let dateFormatedLast = moment(lastDate , 'DD/MM/YYYY').format('YYYY-MM-DD');

        header["params"] = {
          from: dateFormated,
          to: dateFormatedLast,
        };
      }

      this.axios
        .get(server.url + "/api/subscription/from-me", header)
        .then(function (response) {
          let responseData = response.data.data;
          self.afiliateList = [];
          for(let afiliate of responseData.subscription_list){
            let date = self.fixOneDate(afiliate.user.created_at);
            afiliate.user.created_at = date;
            if(afiliate.company){
              let date = self.fixOneDate(afiliate.company.created_at);
              afiliate.company.created_at = date;
            }
            if(afiliate.type == 'AFILIATE'){
              self.afiliateList.push(afiliate);
            }
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.indications {

  h1 {
    margin-bottom: 40px;
  }

  .indication-btns {
    width: 137px;
    height: 34px;
    color: var(--main-color-primary);
  }

  .v-select{
    width: 160px;
    height: 34px;
    display: inline-block;
  }

  .vs__dropdown-toggle{
    height: 38px;
  }

  .indication-box {
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    padding: 12px 36px;
    width: 85%;
    display: inline-block;

    font-size: 13px;
    font-weight: bold;
    color: var(--main-title-color);
  }

  .indication-value-box {
    background-color: var(--main-color-primary);
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    width: 15%;
    padding: 12px;
    display: inline-block;

    font-size: 13px;
    font-weight: bold;
    color: white;
    text-align: center;
  }
}


@media only screen and (max-width: 600px) {
  .indications {
    border-radius: 10px;
    padding: 20px;
    margin: 15px 0px;

    h1{
      text-align: center;
      width: 100%;
      margin-bottom: 30px;
    }

    .v-select{
      width: 130px;
      font-size: 14px;
    }
  }
}
</style>
