<template>
  <main-layout>
    <div class=" container-fluid company-data m-0   light-grey-bg">
      <div class="sub-menu container-fluid h-auto m-0  menu-content-box">
        <div class="container">
          <div class="row " style="">
            <div class="col-md-5 text-center  mt-1 col-sm-12">
              <h1>Vagas de emprego</h1>
            </div>
            <div class="col-lg-7 col-sm-10">

              <div class="row pt-3">
                <div class="col-lg-5 col-sm-2"> <button @click="newOpportunity()">Cadastrar vaga</button>
                 <button  v-on:click="goTo('./co/vendedores/buscar')">Buscar profissionais ou representantes</button>
                </div>
                <div class="col-1"></div>
                <div class="col-lg-6 col-sm-10">
                  <button v-on:click="goTo('./co/vendedores')">
                    <!-- Meus representantes -->
                    Candidatos para Representantes
                  </button>
                  <button  v-on:click="goTo('./co/vendedores')">Meus representantes efetivados</button>
             
                </div>
              </div>




            </div>
          </div>
        </div>
        <div class="opportunities container-fluid   company-page mt-5">
          <div class="filter-search">
            <div class="row search-row ">
              <div class="col-md-10 my-3">
                <input id="searchText" type="text" class="my-0" v-model="search.searchText"
                  v-on:keyup.enter="getOpportunities" placeholder="Buscar por nome" />
              </div>
              <div class="col-md-2 d-flex justify-content-center align-items-center ">
                <button class="full d-flex justify-content-center align-items-center mb-2 py-3" @click="getOpportunities()">Buscar</button>
              </div>
            </div>
          </div>
          <div class="filter-day d-flex justify-content-center flex-row row    my-3">
            <h2 class="h2  d-flex justify-content-center col-12 ">Filtrar por</h2>
            <button class="col-md-2 d-flex justify-content-center align-items-center col-sm-6 py-4" @click="status = ''">
  <h3>Todas</h3>
</button>
            <button class="col-md-2 d-flex justify-content-center align-items-center col-sm-6 py-4" @click="status = 'OPEN'">Abertas</button>
            <button class="col-md-2 d-flex justify-content-center align-items-center col-sm-6 py-4" @click="status = 'CLOSED'">Fechadas</button>
            <button class="col-md-2 d-flex justify-content-center align-items-center col-sm-6 py-4" @click="status = 'STANDBY'">Standby</button>
            <button class="col-md-2 d-flex justify-content-center align-items-center col-sm-6 py-4" @click="status = 'CANCELED'">Canceladas</button>
            
          </div>
          <br>
            <br>
          <div class="" v-if="opportunityListFiltered().length == 0" style="padding-top: 20px">
            <h2>Você ainda não cadastrou nenhuma vaga</h2>
          </div>
          <div class="row" v-else>
            <div class="col-md-6" v-for="opportunity in opportunityListFiltered()" :key="opportunity._id"
              data-toggle="modal" data-target="#opportunityDetailsModal" @click="selectedOpportunity = opportunity">
              <opportunity-item :opportunity="opportunity" :type="0">
              </opportunity-item>
            </div>
          </div>
          <opportunity-details-modal :modal_id="'opportunityDetailsModal'" :showCandidateButton="false"
            :opportunity="selectedOpportunity"></opportunity-details-modal>
        </div>
      </div>

    </div>

  </main-layout>
</template>

<script>
import companyMixin from "../../../mixins/companyMixin.js";
import MainLayout from "../../../layouts/CompanyProfile.vue";
import OpportunityItem from "../../../components/OpportunityItem.vue";
import OpportunityDetailsModal from "../../../components/OpportunityDetailsModal.vue";
import moment from 'moment';
import { server, local } from "../../../js/variables.js";

export default {
  mixins: [companyMixin],
  components: {
    MainLayout,
    OpportunityItem,
    OpportunityDetailsModal,
  },
  data() {
    return {
      opportunityList: [
        // {_id: 1, name: "Título da vaga 1", position: 'Gerente 1', amount: 5, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. '},
        // {_id: 2, name: "Título da vaga 2", position: 'Gerente 2', amount: 10, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. '},
        // {_id: 3, name: "Título da vaga 3", position: 'Gerente 3 ', amount: 2, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. '},
        // {_id: 4, name: "Título da vaga 4", position: 'Gerente 4', amount: 15, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. '},
      ],
      selectedOpportunity: {},
      status: "",
      search: {
        searchText: null,
      },
    };
  },
  mounted() {
    var parameters = this.$route.query;
    // console.log(this.$route);
  },
  watch: {
    company(newValue, oldValue) {
      // console.log(newValue, oldValue);
      // console.log("watch company", this.company);
      if (this.company._id) {
        this.getOpportunities();
      }
    },
  },
  methods: {
    opportunityListFiltered() {
      var list = [];
      for (let item of this.opportunityList) {
        if (item.status.indexOf(this.status) > -1) {
          list.push(item);
        }
      }
      return list;
    },
    opportunityDetails(opportunity) {
      window.open("./co/vaga/" + opportunity._id, "_self");
    },
    newOpportunity() {
      let self = this;
      if (local.permissions) {
        if (local.permissions.advertiseVacancies < local.permissions.advertiseVacanciesMax) {
          window.open("./co/vaga/new", "_self");
        } else {
          self.$swal({
            icon: 'error',
            title: 'Aviso',
            text: 'Você atingiu o limite de vagas criadas',
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              // window.open("./vc/candidaturas", "_self"); 
              const url = "/co/perfil";
              self.$router.push(url);
            }
          })
        }
      } else {
        self.$swal({
          icon: "error",
          title: "Aviso",
          text: 'Você atingiu o limite de vagas de emprego',
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: true,
          confirmButtonText: "Ir para planos",
          cancelButtonText: "Ok",
          // closeOnConfirm: false,
          // closeOnConfirm: false,
        }).then((result) => {
          if (result.value) {
            let url = '';
            if (self.company == null || !self.company._id) {
              url = "/vc/perfil/";
            } else {
              url = "/co/perfil/";
            }
            self.$router.push(url);
          }
        })
      }
    },
    getOpportunities() {
      let self = this;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        filter: this.search.searchText,
      };
      this.axios
        .get(
          server.url + "/api/company/" + self.company._id + "/job-opportunity/",
          header
        )
        .then(function (response) {
          let responseData = response.data.data;
          self.opportunityList = responseData.job_opportunity_list;
          for (let opportunity of self.opportunityList) {
            opportunity.created_at = moment(opportunity.created_at).add(4, 'hours').format('DD/MM/YYYY');
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    filterStateCity() {
      let self = this;
      let state = self.search.state;
      let city = self.search.city;
      let segment = self.search.segment;

      let query = state ? "state=" + state : "";
      query += query && city ? "&" : "";
      query += city ? "city=" + city : "";
      query += query && segment ? "&" : "";
      query += segment ? "segment=" + segment : "";
      query = query ? "?" + query : "";

      this.axios
        .get(server.url + "/api/user" + query, server.config)
        .then(function (response) {
          self.salesmanList = [];
          let responseData = response.data.data.user_list;
          for (let item of responseData) {
            self.salesmanList.push(item);
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.filter-search {
  margin-bottom: 35px;

  input {
    margin-top: 0px;
  }


}

.filter-day {
  margin-top: 40px;
  margin-bottom: 40px;

  button {
    margin-right: 8px;
    display: inline-block;
    border: 0.5px solid var(--main-color-mediumgrey);
    font-weight: normal;
    width: 105px;
    height: 25px;
    background-color: white;
    color: var(--main-title-color);
  }

  h2 {
    display: inline-block;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .opportunities {
    padding-top: 135px;
  }
}
</style>
