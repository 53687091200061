<template>
	<main-layout>
		<div class="container-fluid register-company">

			<div class="row">
				<div id="register-box" class="col-md-4 no-padding">
					<br><br>
					<a href target="_self">
						<img src="/static/svg/logo_clean.svg" alt="" class="center">
					</a>

					<h1>Cadastro</h1>
					<h3>da empresa</h3>
					<div class="inputs-register center">

						<div v-if="showingTerms">
							<terms-and-policy></terms-and-policy>
						</div>
						<div v-else>
							<input id="name" placeholder="Nome da empresa" type="text"
								v-model="$v.company.name_fancy.$model">
							<input id="document" placeholder="CNPJ" type="text" v-model="$v.company.document.$model"
								v-mask="'##.###.###/####-##'">
							<span v-if="$v.company.document.$error">Verifique o formato do CNPJ</span>

							<input id="name" placeholder="Seu nome" type="text" v-model="$v.user.name.$model">
							<span v-if="$v.user.name.$error">Campo não pode estar vazio</span>

							<input id="lastname" placeholder="Sobrenome" type="text" v-model="$v.user.lastname.$model">
							<span v-if="$v.user.lastname.$error">Campo não pode estar vazio</span>

							<input id="lastname" placeholder="Site da empresa" type="text" v-model="$v.company.site.$model">
							<span v-if="$v.company.site.$error">Campo não pode estar vazio</span>


							<input type="text" id="phone" placeholder="Telefone" @input="formatarTelefone"
								v-mask="'(##) #####-####'" v-model="$v.company.phone.$model" />
							<span v-if="$v.company.phone.$error">Campo não pode estar vazio</span>

							<input id="email" placeholder="Login e-mail" type="text" v-model="$v.user.email.$model">
							<span v-if="$v.user.email.$error">Verifique o formato do e-mail</span>
							<!-- 
							<input id="password" type="password" placeholder="Digite sua senha"
								v-model="$v.user.password.$model">
							<span v-if="$v.user.password.$error">Campo obrigatório</span> -->
							<div class="password">
								<input id="password" :type="passwordInputType" v-model="$v.user.password.$model" placeholder="Digite sua senha">
								<span v-if="$v.user.password.$error">Campo obrigatório</span> 
								<button class="clean" @click="showHidePassword()">
									<i class="far fa-eye" v-if="passwordInputType === 'password'"></i>
									<i class="far fa-eye-slash" v-if="passwordInputType === 'text'"></i>
								</button>
							</div>
						

							<div class="password">
								<input id="passwordConfirm" :type="passwordInputType" placeholder="Confirme sua senha"
								v-model="$v.user.passwordConfirm.$model">
							<span v-if="$v.user.passwordConfirm.$error">As senhas nao coincidem</span>								<button class="clean" @click="showHidePassword()">
									<i class="far fa-eye" v-if="passwordInputType === 'password'"></i>
									<i class="far fa-eye-slash" v-if="passwordInputType === 'text'"></i>
								</button>
							</div>
							
						</div>
						<!-- <div style=" padding-top: 35px">
							<div style="width: 65%; display: inline-block">
								<input type="checkbox" name='type' id='terms-company' v-model="checked" style="float: left">
								<label for="terms-company" @click="showTerms()"> Estou de acordo com os termos</label>
							</div>
							<button class="blue" style="display: inline-block; float: right" @click="createCompany()" :disabled="isSending">Confirmar</button>
						</div> -->

						<!-- <input id="afiliate" type="text" placeholder="Código de afiliado (opcional)" v-model="$v.afiliate.$model"/> -->

						<input type="checkbox" name='type' id='terms' v-model="checked" style="float: left">
						<label for="terms" @click="showTerms()"> Estou de acordo com os termos</label>

						<button class="blue float-right" type="button" @click="createCompany()">Cadastrar</button>

					</div>
					<div class="main-color-bg create-acount">
						<!-- <div class="center"> -->
						<p>Ja tem conta?</p>
						<a href="./login">Acessar</a>
						<!-- </div> -->

					</div>
				</div>
				<div class="col-md-8 no-padding">
					<div class="image-box" style="background-image: url(./static/img/login.jpg)"></div>
					<!-- <img src="/static/img/login.jpg" alt /> -->
				</div>
			</div>
		</div>

	</main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Basic.vue";
// import Contact from "../../../components/Contact.vue";
// import ProductItem from "../../../components/ProductItem.vue";
// import CompanyMenu from "../../../components/CompanyMenu.vue";
import TermsAndPolicy from "../../../components/TermsAndPolicy.vue";
import { server } from "../../../js/variables.js";
import { required, email, sameAs } from 'vuelidate/lib/validators'

const link = "/static/img/produto.png";

export default {
	components: {
		MainLayout,
		TermsAndPolicy,
		// ProductItem,
		// CompanyMenu,
	},
	data() {
		return {
			slideList: [],
			user: {
				name: '',
				lastname: '',
				email: '',
				// phone: '',
				password: '',
				passwordConfirm: '',
			},
			passwordInputType: 'password',

			company: {
				name_fancy: '',
				document: '',
				phone: '',
				site: ''
			},
			afiliate: '',
			checked: true,
			showingTerms: false,
			validPhone: false,
			// passwordConfirm: '',
			salaryRange: [
				{ _id: 1, value: 1000.00 },
				{ _id: 2, value: 3000.00 },
				{ _id: 3, value: 5000.00 },
				{ _id: 4, value: 10000.00 },
			],
			typeList: [
				{ name: "tipo 1" },
				{ name: "tipo 2" },
				{ name: "tipo 3" },
			]
		}
	},
	validations: {
		user: {
			name: { required },
			lastname: { required },
			email: { required, email },
			// phone: {required},
			password: { required },
			passwordConfirm: '',
			validPhone: false,
		},
		company: {
			name_fancy: { required },
			document: { required },
			phone: { required },
			site: ''

		},
		afiliate: '',
		// passwordConfirm : { required, sameAs: sameAs('user.password') }

	},
	mounted() {
		var parameters = this.$route.query;
		let self = this;
		this.afiliate = this.$route.query.afiliado;
	},
	methods: {
		register(e) {
			window.open("./company-registration", "_self");
		},
		onPageChange(page) {
			this.sliderPage = page;
		},
		formatarTelefone(number) {
			if (typeof number !== 'string') {
				// Convert to string if it's not already
				number = number.toString();
			}

			let numeroLimpo = number.replace(/\D/g, "");
			console.log(numeroLimpo);

			let validPhone = /^[0-9]{9,10}$/.test(numeroLimpo);
			console.log("validPhone" + validPhone);


			return numeroLimpo; // Return the formatted number

		},


		createCompany() {
			let self = this;
			if (!this.$v.$invalid && this.checked) {

				let userCopy = JSON.parse(JSON.stringify(self.user));
				let companyCopy = JSON.parse(JSON.stringify(self.company));

				if (userCopy.password.length < 6) {
					this.$swal({
						icon: 'error',
						title: 'Oops...',
						text: 'Senha deve ter no mínimo 6 caracteres',
						// footer: '<a href>Why do I have this issue?</a>'
					});
					return
				}

				let document = companyCopy.document;
				let documentNoMask = document.substring(0, 2) + document.substring(3, 6) + document.substring(7, 10) + document.substring(11, 15) + document.substring(16, 18);
				companyCopy.document = documentNoMask;

				let numberPhone = companyCopy.phone;
				console.log("Antes do filtro: " + numberPhone);

				let cell = self.formatarTelefone(numberPhone);
				console.log("Após o filtro: " + cell);

				companyCopy.phone = cell;
				this.axios.post(server.url + '/api/company', {
					company: companyCopy,
					user: userCopy,
					afiliate: self.afiliate,

				}, server.config)
					.then(function (response) {
						let token = response.data.data.auth.token;
						let user = response.data.data.user;
						let company = response.data.data.company;
						localStorage.setItem('token', token);
						localStorage.setItem('user', JSON.stringify(user));
						localStorage.setItem('company', JSON.stringify(company));
						// console.log("token", token)
						window.open("./co/dados", "_self");
					})
					.catch(function (error) {
						self.output = error;
						self.showError(error);
					});
			} else {
				this.$v.$touch();
				this.$swal({
					icon: 'error',
					title: 'Oops...',
					text: 'Verifique os campos obrigatórios',
					// footer: '<a href>Why do I have this issue?</a>'
				});
			}
		},
		showTerms() {
			this.showingTerms = !this.showingTerms;
		},
		showHidePassword(){
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    },
	}
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

#register-box {
	height: 100vh;
	overflow-y: auto;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #888888;
		/* Cor do polegar da barra de rolagem */
	}

	&::-webkit-scrollbar-track {
		background-color: #f0f0f0;
		/* Cor da trilha da barra de rolagem */
	}
}

.register-company {

	h1 {
		text-align: center;
		// font-size: 36px;
		margin-top: 12px;
		color: var(--main-text-color);
	}

	h3 {
		text-align: center;
		margin-bottom: 30px;
	}

	.image-box {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;

		height: 100%;
		width: 100%;
	}


	img {
		// margin-top: 20px;
		// margin-bottom: 60px;
		// padding-top: 70px;
		// padding-bottom: 30px;
	}

	.create-acount {
		height: 55px;
		width: 33.3%;
		text-align: center;
		position: fixed;
		bottom: 0px;


		p {
			color: white;
			display: inline-block;
			margin-right: 5px;
			margin-top: 14px;
		}

		a {
			color: var(--main-color-secundary);
			// display: inline-block;
		}

	}
	.password{
        position: relative;
        button{
          position: absolute;
          right: 0px;
          top: 7px;
        }
      }

	.inputs-register {
		width: 85%;
		margin-bottom: 155px;

		button {
			font-weight: normal;
			padding: 8px 20px;

			&:focus {
				outline: none;
			}
		}

		input {
			background-color: var(--main-color-lightgrey);
			border: none !important;
			height: 45px;
		}

		input[type="checkbox"] {
			width: 25px;
			height: 25px;
		}

	}
}

@media only screen and (max-width: 600px) {
	.image-box {
		display: none;
	}

	.register-company .inputs-register {
		margin-bottom: 50px;
		position: relative;
	}

	.register-company .create-acount {
		position: fixed;
		bottom: 0px;
		left: 0px;
		width: 100%;
		z-index: 1000;
	}
}
</style>
