import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', function(value) {
  if (value) {
    // return moment(String(value)).format('MM/DD/YYYY')
    return moment(String(value)).add(4, 'hours').format('DD/MM/YYYY')
  }
});

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
});

Vue.filter('calculateAge', function(value) {
  if (value) {
    // return moment(String(value)).format('MM/DD/YYYY hh:mm')

    let currentDate = new Date();
    let birthDate = moment(value, 'DD/MM/YYYY')
    // let birthDate = new Date(value);
    let difference = currentDate - birthDate;
    let age = Math.floor(difference/31557600000);
    return age
  }
});



Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});

Vue.filter('str_limit', function (value, size) {
    if (!value) return '';
    value = value.toString();
  
    if (value.length <= size) {
      return value;
    }
    return value.substr(0, size) + '...';
});

Vue.filter('replace', function (value, from, to) {
    return value.replace(from, to)
})