import HomeSalesman from './views/Home-salesman.vue';
import HomeCompany from './views/Home-company.vue';
import Home from './views/Home.vue';
import Salesman from './views/Salesman-main.vue';
import HomeAffiliate from './views/HomeAffiliate.vue';
import HomeTeacher from './views/HomeTeacher.vue';
import Plans from './views/Plans.vue';
import Page from './views/Page.vue';
import Page404 from './views/404.vue';
import About from './views/About.vue';
import Login from './views/Login.vue';
import AskNewPassword from './views/AskNewPassword.vue';
import RecoverPassword from './views/RecoverPassword.vue';
import ProductDetails from './views/ProductDetails.vue';
import ProductDetailsBuyer from './views/ProductDetails-buyer.vue';
import CatalogCart from './views/SalesmanCatalogCart.vue';
import SalesmanCatalog from './views/SalesmanCatalog-view.vue';
import CompanyProfileView from './views/CompanyProfileView.vue';



export default [
    { path: '/', component: Home },
    { path: '/home-antiga', component: HomeSalesman },
    { path: '/para-empresas', component: HomeCompany },
    { path: '/afiliados', component: HomeAffiliate },
    { path: '/treinadores', component: HomeTeacher },
    { path: '/login', component: Login },
    { path: '/planos', component: Plans },
    { path: '/solicitar-nova-senha', component: AskNewPassword },
    { path: '/recuperar-senha/:id', component: RecoverPassword },
    { path: '/produto/:id', component: ProductDetails },
    { path: '/catalogo/:id/produto/:idProduct', component: ProductDetailsBuyer },
    { path: '/catalogo/:id/carrinho', component: CatalogCart },
    { path: '/catalogo/:id', component: SalesmanCatalog },
    { path: '*', component: Page404 },
    // Institucional
    { path: '/info/:id', component: Page },
    { path: '/sobre-a-empresa', component: About },
    // Para profissionais
    { path: '/info/:id', component: Page },
    //Para empresas
    { path: '/vendedores', component: Salesman },
    { path: '/empresa/:id', component: CompanyProfileView },
]