<template>
  <main-layout>
	<!-- <pre> password {{ $v.user.password }}</pre>
	<pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container-fluid company-data light-grey-bg">
		<!-- <br> -->
		<div class="menu-content-box">
		<div class="row">
			<div class="col-md-6">
				<!-- <form> -->
					<div class="title-bg-grey">
						<h3>Dados da empresa</h3>
					</div>
					<br>

					<label for="document">CNPJ*</label>
					<span v-if="$v.company.document.$error">Verifique o formato do CNPJ</span>
					<input id="document" type="text" v-model="$v.company.document.$model" v-mask="'##.###.###/####-##'" :disabled="company.document != null && company.document != ''">

					<label for="name_fancy">Nome fantasia*</label>
					<span v-if="$v.company.name_fancy.$error">Campo não pode estar vazio</span>
					<input id="name_fancy" type="text" v-model="$v.company.name_fancy.$model">

					<label for="name">Razão social*</label>
					<span v-if="$v.company.name.$error">Campo não pode estar vazio</span>
					<input id="name" type="text" v-model="$v.company.name.$model">

					<label for="site">Site</label>
					<span v-if="$v.company.site.$error">Campo não pode estar vazio</span>
					<input id="site" type="text" v-model="$v.company.site.$model">

					<label for="phone">Telefone*</label>
					<span v-if="$v.company.phone.$error">Campo não pode estar vazio</span>
					<input id="phone" type="text" v-model="$v.company.phone.$model" v-mask="'(##) #####-####'">

					<label for="email">Email da empresa*</label>
					<span v-if="$v.company.email.$error">Campo não pode estar vazio</span>
					<input id="email" type="text" v-model="$v.company.email.$model" :disabled="disableEmail">

					<label for="birthdate">Data de criação da empresa</label>
					<span v-if="$v.company.birthdate.$error">Campo não pode estar vazio</span>
					<input id="birthdate" type="text" placeholder="DD/MM/AAAA" v-model="$v.company.birthdate.$model" v-mask="'##/##/####'">

					<label for="size">Porte</label>
					<v-select label="value" 
							:options="sizeList" 
							v-model="$v.company.size.$model" 
							:reduce="size => size.value" >
					</v-select>

					<label for="segment">Segmento</label>
					<v-select label="name" 
							:options="segmentList" 
							v-model="$v.company.segment.$model" 
							:reduce="segment => segment._id" >
					</v-select>

				<!-- </form> -->
			</div>
			<div class="col-md-6">
				<div class="title-bg-grey">
					<h3>Endereço</h3>
				</div>
				<br>

				<label for="zipcode">CEP*</label>
				<span v-if="$v.postAddress.zipcode.$error">Campo obrigatório</span>
				<input id="zipcode" 
					type="text" 
					placeholder="CEP" 
					v-model="$v.postAddress.zipcode.$model" 
					v-on:blur="getAddress" v-on:keyup.enter="getAddress" 
					@blur="$v.postAddress.zipcode.$touch()" 
					:class="{ 'fail-error' : $v.postAddress.zipcode.$error }" 
					v-mask="'#####-###'">

				<label for="street">Endereço*</label>
				<span v-if="$v.postAddress.street.$error">Campo obrigatório</span>
				<input id="street" type="text" placeholder="Endereço" v-model="$v.postAddress.street.$model" @blur="$v.postAddress.street.$touch()" :class="{ 'fail-error' : $v.postAddress.street.$error }">

				<label for="number">Número*</label>
				<span v-if="$v.postAddress.number.$error">Campo obrigatório</span>
				<input id="number" type="text" placeholder="Número" v-model="$v.postAddress.number.$model" @blur="$v.postAddress.number.$touch()" :class="{ 'fail-error' : $v.postAddress.number.$error }">

				<label for="complement">Complemento</label>
				<span v-if="$v.postAddress.complement.$error">Campo obrigatório</span>
				<input id="complement" type="text" placeholder="Complemento" v-model="$v.postAddress.complement.$model" @blur="$v.postAddress.complement.$touch()" :class="{ 'fail-error' : $v.postAddress.complement.$error }">

				<label for="district">Bairro*</label>
				<span v-if="$v.postAddress.district.$error">Campo obrigatório</span>
				<input id="district" type="text" placeholder="Bairro" v-model="$v.postAddress.district.$model" @blur="$v.postAddress.district.$touch()" :class="{ 'fail-error' : $v.postAddress.district.$error }">

				<label for="city">Cidade*</label>
				<span v-if="$v.postAddress.city.$error">Campo obrigatório</span>
				<input id="city" type="text" placeholder="Cidade" v-model="$v.postAddress.city.$model" @blur="$v.postAddress.city.$touch()" :class="{ 'fail-error' : $v.postAddress.city.$error }">

				<label for="state">Estado*</label>
				<v-select
					label="sigla" 
					:options="stateList"
					v-on:input="findOpportunities('state')"
					@blur="$v.postAddress.state.$touch()"
					:reduce="state => state.sigla" 
					v-model="$v.postAddress.state.$model"
					:placeholder="'(Selecione)'"
					autocomplete="nope"
				>
				</v-select>
				<span v-if="$v.postAddress.state.$error">Campo obrigatório</span>
				<!-- <input id="state" type="text" placeholder="Estado" v-model="$v.postAddress.state.$model" @blur="$v.postAddress.state.$touch()" :class="{ 'fail-error' : $v.postAddress.state.$error }"> -->
			</div>

			<div class="col-md-12">
				<div class="title-bg-grey">
					<h3>Descrição da empresa</h3>
				</div>
				<br>
				<textarea v-model="$v.company.description.$model" placeholder="Digite uma descrição com os pontos mais importantes da empresa, história, diferencial e negócio"></textarea>
			</div>

			<div class="col-md-12 top-space">
				<div class="title-bg-grey">
					<h3>Dados de ajuda de custo para vendedores/representantes</h3>
				</div>
				<br>
			</div>
			<div class="col-md-6">
				<label for="salary_min">De</label>
				<money v-model="$v.company.salary_min.$model"></money>
				<span v-if="$v.company.salary_min.$error">Campo não pode estar vazio</span>
			</div>
			<div class="col-md-6">
				<label for="salary_max">Até</label>
				<money v-model="$v.company.salary_max.$model"></money>
				<span v-if="$v.company.salary_max.$error">Campo não pode estar vazio</span>
			</div>
			<div class="col-md-12 last btn-full-m" style="margin-top: 24px;">
				<!-- <button>Remover</button> //TODO -->
				<button class="remove" type="button" @click="deleteCompany()">Excluir</button>
				<button class="remove" type="button" @click="logout()">Desconectar</button>
				<button class=" blue " type="button" @click="updateCompany()" :disabled="isSending">Salvar</button>
			</div>
		</div>

        
        
    </div>
	</div>
  </main-layout>
</template>

<script>
import companyMixin from '../../../mixins/companyMixin.js'
import MainLayout from "../../../layouts/CompanyProfile.vue";
// import Contact from "../../../components/Contact.vue";
// import ProductItem from "../../../components/ProductItem.vue";
// import CompanyMenu from "../../../components/CompanyMenu.vue";
import { server } from "../../../js/variables.js";
import { required, email, sameAs } from 'vuelidate/lib/validators'

const link = "/static/img/produto.png";

export default {
	mixins: [companyMixin],
  components: {
    MainLayout,
  },
  data () {
    return {
			sizeList:[
				{_id: 1, value: "Até 200 funcionários"},
				{_id: 2, value: "Até 500 funcionários"},
				{_id: 3, value: "Até 1000 funcionários"},
				{_id: 4, value: "Até 5000 funcionários"},
			],
			segmentList:[],
			postAddress: {
				zipcode: '',
				street: '',
				number: '',
				complement: '',
				district: '',
				city: '',
				state: '',
				// _id: '',
			},
			url: server.url + "/api/media/image/",
			headers: {
				Authorization: 'Bearer ' + localStorage.token
			},
			files: [],
			fileUploaded: [],
			isSending: false,
			disableEmail: false,
    }
  },
  validations: {
		company: {
			name: "",
			name_fancy: { required },
			document: { required },
			site: '',
			phone: { required },
			email: '',
			birthdate: '',
			description: '',
			size: '',
				salary_min: '',
				salary_max: '',
				segment: '',
				created_at: '',
				updated_at: '',
			},
		
			postAddress: {
				zipcode: "",
				street: "",
				number: "",
				complement: '',
				district: "",
				city: "",
				state:"",
			},
        
    },
    mounted() {
		
        var parameters = this.$route.query;
		let self = this;
		this.findSegments();
    this.findPlaces();
	},
	watch:{
		company(newValue, oldValue){
			// console.log(newValue, oldValue);
			// console.log("watch company", this.company);
			// this.postAddress = this.company.address[0];
			// this.employeeManager = this.company.employees[0];
			// this.employeeRH = this.company.employees[1];

			if(this.company.address && this.company.address.length > 0){
				this.postAddress = this.company.address[0];
			}
			else{
				// console.log("Nao carregado");
				this.postAddress = {
					zipcode: '',
					street: '',
					number: '',
					complement: '',
					district: '',
					city: '', 
					state: '',
				}
			}
			if(this.company.email != null && this.company.email != ''){
				this.disableEmail = true;
			}
			
		}
	},
  methods: {
	updateCompany(){
		let self = this;
		if (!this.$v.$invalid){
			if (!localStorage.token) {
				return
			}
			server.config.headers = {
				Authorization: 'Bearer ' + localStorage.token
			}
			self.company.address = [];
			self.company.employees = [];
			self.company.address.push(self.postAddress);
			

			let companyCopy = JSON.parse(JSON.stringify(self.company));
			this.prepareCompanyToSend(companyCopy);
			
			self.isSending = true;
			this.axios.put(  server.url + '/api/company/' + self.company._id, {
				company: companyCopy,
			}, server.config)
			.then(function (response) {
				self.isSending = false;
				let responseData = response.data;
				self.$swal({
					icon: 'success',
					title: 'Dados atualizados',
					text: responseData.message,
					// footer: '<a href>Why do I have this issue?</a>'
					showCancelButton: false,
					confirmButtonColor: '#053360',
					confirmButtonText: 'Ok'
					}).then((result) => {
					if (result.isConfirmed) {
						location.reload();
					}
				})
				window.open("./co/vagas", "_self");

			})
			.catch(function (error) {
				self.isSending = false;
				self.output = error;
				// self.insertMasks(true);
				self.showError(error);
			});
		}else{
			this.$v.$touch();
			this.$swal({
				icon: 'error',
				title: 'Oops...',
				text: 'Verifique os campos obrigatórios',
				// footer: '<a href>Why do I have this issue?</a>'
			});
		}
	},
	logout(){
		let self = this;
		var id = this.$route.params.id;
		if (!localStorage.token) {
			return
		}
		server.config.headers = {
			Authorization: 'Bearer ' + localStorage.token
		}

		this.axios.post(  server.url + '/api/auth/logout', server.config)
		.then(function (response) {
			localStorage.removeItem('token');
			localStorage.removeItem('slug');
			localStorage.removeItem('user');
			localStorage.removeItem('company');
			window.open ('/login' , '_self');
			// self.$swal({
			// 	icon: 'success',
			// 	title: 'Dados atualizados',
			// 	text: responseData.message,
			// 	// footer: '<a href>Why do I have this issue?</a>'
			// });
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
		});
	},
	deleteCompany(){
		let self = this;
		var id = this.$route.params.id;

		this.$swal.fire({
		title: 'Realmente deseja excluir sua conta?',
		text: "Esta ação é irreversível!",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#053360',
		cancelButtonColor: '#d33',
		cancelButtonText: 'Cancelar',
		confirmButtonText: 'Excluir'
		}).then((result) => {
		if (result.isConfirmed) {
			if (!localStorage.token) {
				return
			}
			server.config.headers = {
				Authorization: 'Bearer ' + localStorage.token
			}

			this.axios.delete(  server.url + '/api/company/' + self.company._id, server.config)
			.then(function (response) {
				localStorage.removeItem('token');
				localStorage.removeItem('user');
				localStorage.removeItem('company');
				window.open ('/' , '_self');
				// self.$swal({
				// 	icon: 'success',
				// 	title: 'Dados atualizados',
				// 	text: responseData.message,
				// 	// footer: '<a href>Why do I have this issue?</a>'
				// });
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
			});
		}
		})

		
	},
	getAddress(){
		let self = this;
		this.axios.get(  server.url + '/webservices/zipcode?zipcode=' + self.postAddress.zipcode)
		.then(function (response) {
			let responseData = response.data.data
			self.postAddress.city = responseData.localidade
			self.postAddress.street = responseData.logradouro
			self.postAddress.state = responseData.uf
			self.postAddress.district = responseData.bairro
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
		});
	},
	findSegments(){
		let self = this;
		this.axios
			.get(server.url + "/api/skill?filter=INDUSTRY_GENERAL", server.config)
			.then(function (response) {
				self.segmentList= [];
				let responseData = response.data.data.skill_list;
				self.segmentList = responseData;
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
			});
	},
	removeSpecialCharacters(item){
		let result = item.replaceAll("[-+.^:,/]","");
	},
    thumbUrl(file) {
		return file.myThumbUrlProperty;
    },
	onFileChange(response) {
		// Handle files like:
		const image = response.data.image;
		// if(!this.company.image){
		// 	this.company.image = [];
		// }
		// this.company.image.push(image);
		this.company.image = image;
		this.fileUploaded = image.url;
    },
	removePhoto(image) {
		var self = this;
		this.axios.delete(  server.url + '/api/media/image/' + image._id , server.config)
			.then(function (response) {
				self.$swal({
					icon: 'success',
					title: 'Imagem removida',
					// text: answer.message,
					// footer: '<a href>Why do I have this issue?</a>'
				});
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
			});
	},
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.company-data{
	// padding-top: 50px;
  //   padding-bottom: 50px;

	h1{
		margin-top: 15px;
		margin-bottom: 25px;
	}

}

</style>
