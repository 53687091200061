<template>
  <main-layout>
    <banner :bannerType="0" class=" "> </banner>

    <product-finder class="section" :showCompanyList=true id="find-products"></product-finder>
    <div class="container">
      <hr>
    </div>

    <div class="container section candidatures" id="find-opportunities">
      <h2>Encontre oportunidades de trabalho</h2>
      <div class="hr-yellow hr-4" style="margin-top: 8px; margin-bottom: 30px"></div>
      <opportunity-finder :showCandidateButtonFinder="false" :typeFinder="1"></opportunity-finder>
    </div>

    <div class="container">
      <hr>
    </div>

    <div class="container section" id="find-trainings">
      <h2>Melhore suas técnicas de vendas</h2>
      <div class="hr-yellow hr-4" style="margin-top: 8px; margin-bottom: 30px"></div>
      <div class="row">
        <div class="col-md-3" v-for="training in trainingList" :key="training._id">
          <training-item :training=training :grey=true></training-item>
        </div>
      </div>
      <div style="margin-top: 30px" v-if="canLoadMore">
        <!-- <center>
          <small v-if="opportunityList">
            {{ maxItems }} de {{ opportunityList.length }}
          </small>
        </center> -->
        <button class="blue-border big" @click="loadMore()" style="width: 100%; margin: 20px 0px;">
          Carregar mais
        </button>
      </div>
    </div>

    <hr>

    <div class="afiliate-box container">
      <h1>Escolha seu plano</h1>
      <div class="hr-yellow hr-4 center" style="margin-bottom: 38px;"></div>
      <h2 style="padding-top: 30px" v-if="salesmanFreePlan._id || salesmanPlan._id">Profissional</h2>
      <div class=" light-grey-bg" v-if="salesmanFreePlan._id || salesmanPlan._id">
        <div class="row" style="padding: 4px 0px; margin-top: 25px">
          <div class="col-md-6 no-padding-left-m" style="padding-right: 2px">
            <!-- salesman free -->
            <plan-item :isSubscribed="2" :freePlans="salesmanFreePlan"></plan-item>
          </div>
          <div class="col-md-6 no-padding-right-m" style="padding-left: 2px">
            <!-- salesman paid -->
            <plan-item :isSubscribed="0" :paidPlans="salesmanPlan"></plan-item>
          </div>
        </div>
      </div>
      <h2 style="padding-top: 30px" v-if="afiliateFreePlan._id || afiliatePlan._id">Afiliado</h2>
      <div class=" light-grey-bg" v-if="afiliateFreePlan._id || afiliatePlan._id">
        <div class="row" style="padding: 4px 0px; margin-top: 25px">
          <div class="col-md-6 no-padding-left-m" style="padding-right: 2px;">
            <!-- afiliate free -->
            <plan-item :isSubscribed="2" :freePlans="afiliateFreePlan"></plan-item>
          </div>
          <div class="col-md-6 no-padding-right-m" style="padding-left: 2px;">
            <!-- afiliate paid -->
            <plan-item :isSubscribed="0" :paidPlans="afiliatePlan"></plan-item>
          </div>
        </div>
      </div>
    </div>
    <br>
    <contact></contact>
    <benefits-for-you />
    <newsletter />
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import Contact from "../components/Contact.vue";
import ProductFinder from "../components/ProductFinder.vue";
import Banner from "../components/Banner.vue";
import BenefitsForYou from "../components/BenefitsForYou.vue";
import Newsletter from "../components/Newsletter.vue";
import TrainingItem from "../components/TrainingItem.vue";
import OpportunityFinder from "../components/OpportunityFinder.vue";
import PlanItem from "../components/PlanItem.vue";
import moment from 'moment'
import { server } from "../js/variables.js";

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
    Contact,
    ProductFinder,
    Banner,
    BenefitsForYou,
    Newsletter,
    TrainingItem,
    OpportunityFinder,
    PlanItem,
  },
  data() {
    return {
      sliderList: [
        { link_redirect: "./", image_desktop: "/static/img/slider.jng" },
      ],
      mobileShowFilter: false,
      trainingList: [
        // {_id: 1, name: 'Treinamento 1', teacher: 'Professor 1', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 2, name: 'Treinamento 2', teacher: 'Professor 2', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 3, name: 'Treinamento 3', teacher: 'Professor 3', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 4, name: 'Treinamento 4', teacher: 'Professor 1', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 5, name: 'Treinamento 5', teacher: 'Professor 2', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 6, name: 'Treinamento 6', teacher: 'Professor 3', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
        // {_id: 7, name: 'Treinamento 7', teacher: 'Professor 1', description: 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo'},
      ],
      salesman: {},
      companyPlan: {},
      salesmanPlan: {},
      afiliatePlan: {},
      companyFreePlan: {},
      salesmanFreePlan: {},
      afiliateFreePlan: {},
      pageItems: 8,
      page: 0,
      canLoadMore: true,
    };
  },
  mounted() {
    // var parameters = this.$route.query;
    // if(parameters.afiliado){
    //   localStorage.setItem('afiliate', parameters.afiliado);
    // }
    this.getCourses();
    this.getPlans();
  },
  methods: {
    loadMore() {
      this.maxItems = this.maxItems + this.pageItems;
      this.page = this.page + 1;
      this.getCourses();
    },
    register(e) {
      //   window.open("./company-registration", "_self");
      const url = "/company-registration";
      this.$router.push(url);
    },
    onPageChange(page) {
      this.sliderPage = page;
    },
    gotostore() {
      // window.open("./store", "_self");
      const url = "/store";
      this.$router.push(url);
    },
    getCourses() {
      let self = this;
      // if (!localStorage.token) {
      //   return
      // }
      // server.config.headers = {
      //   Authorization: 'Bearer ' + localStorage.token
      // }

      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        limit: this.pageItems,
        page: this.page,
        filter: null,
      };

      this.axios
      .get(server.url + "/api/course/?visible=1", header)
        .then(function (response) {
          // self.opportunityList = [];
          let responseData = response.data.data;

          if (self.page == 0) {
            self.trainingList = [];
          }

          self.trainingList = self.trainingList.concat( responseData.course_list );

          for(let course of self.trainingList){
            course.created_at = moment(course.created_at).add(4, 'hours').format('DD/MM/YYYY');
          }

          if (responseData.course_list.length != self.pageItems) {
            self.canLoadMore = false;
          } else {
            self.canLoadMore = true;
          }
          
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    getPlans() {
      let self = this;
      // if (!localStorage.token) {
      //     return
      // }
      // server.config.headers = {
      //     Authorization: 'Bearer ' + localStorage.token
      // }
      this.axios
        .get(server.url + "/api/plan?is_enable=true", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.planList = responseData.plan_list;
          for (let plan of self.planList) {
            if (plan.type == "SALESMAN" && plan.payment_option.length > 0) {
              self.salesmanPlan = plan;
            } else if (plan.type == "COMPANY" && plan.payment_option.length > 0) {
              self.companyPlan = plan;
            } else if (plan.type == "AFILIATE" && plan.payment_option.length > 0) {
              self.afiliatePlan = plan;
            } else if (plan.type == "SALESMAN" && plan.payment_option.length == 0) {
              self.salesmanFreePlan = plan;
            } else if (plan.type == "COMPANY" && plan.payment_option.length == 0) {
              self.companyFreePlan = plan;
            } else if (plan.type == "AFILIATE" && plan.payment_option.length == 0) {
              self.afiliateFreePlan = plan;
            }
          }
          for (let payment of self.salesmanPlan.payment_option) {
            if (payment.interval == 1) {
              self.salesmanPlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.salesmanPlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.salesmanPlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.salesmanPlan.payment_12 = payment;
            }
          }

          for (let payment of self.companyPlan.payment_option) {
            if (payment.interval == 1) {
              self.companyPlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.companyPlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.companyPlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.companyPlan.payment_12 = payment;
            }
          }

          for (let payment of self.afiliatePlan.payment_option) {
            if (payment.interval == 1) {
              self.afiliatePlan.payment_1 = payment;
            }
            if (payment.interval == 3) {
              self.afiliatePlan.payment_3 = payment;
            }
            if (payment.interval == 6) {
              self.afiliatePlan.payment_6 = payment;
            }
            if (payment.interval == 12) {
              self.afiliatePlan.payment_12 = payment;
            }
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    
  },
};
</script>

<style lang="scss">
// @import "../scss/reset.scss";
// @import "../scss/base.scss";

.main-home {
  padding: 30px 0px;
}

ul.menu {
  position: relative;
  left: 0px;
  // margin-top: 10px;

  li {
    display: inline;
    margin-right: 20px;
    position: relative;

    span {
      cursor: pointer;
    }

    a {
      color: white;
      font-size: 14px;
      line-height: 50px;
      text-align: left;

      .menu-icon {
        margin-top: -7px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .for-yellow {
      color: var(--main-title-color);
      font-weight: bold;
      font-size: 12px;
    }
  }
}

.padding-title {
  padding-left: 23px;
  padding-right: 23px;
}

.for-professionals {
  // display: flex;
  margin-bottom: 10px;

  p {
    color: var(--main-title-color);
    font-weight: bold;
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: -25px;
  }
}

.filter-sidebar {
  padding: 30px;
  margin-top: 20px;

  .sidebar-title {
    // background-color: blue;
    position: absolute;
    top: -5px;
    left: 15%;
    width: 70%;
    padding: 12px;

    h2 {
      color: white;
      font-weight: normal;
      font-size: 17px;
    }
  }

  .filter-group {
    // label{
    // 	color: var(--main-title-color);
    // }
  }
}

.center {
  margin: auto;
}

@media only screen and (max-width: 600px) {
  #filter-sidebar {
    display: none;
    // position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0;
    right: 0;
    z-index: 1000;
    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 50px;
    background-color: #fff;
    background: #fff;
    border-top: 2px solid #ccc;
  }

  #filter-sidebar.open {
    display: block;
  }

  .sac {
    padding: 30px 50px;
  }
}
</style>
