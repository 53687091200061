<template>
  <!-- Modal delete -->
	<div class="modal fade" 
			id="confirmModal" 
			tabindex="-1" 
			role="dialog" 
			aria-labelledby="mySmallModalLabel" 
			aria-hidden="true" 
			style="background-color: #5c6377b8;">

		<div class="modal-dialog modal-sm" role="document">
			<div class="modal-content">
                <h3 class="modal-title center" id="confirmModal">
					<b>Realmente deseja excluir?</b>
				</h3> 
                <div class="btns-box">
                    <button type="button" class="grey" aria-label="Close" style="margin-bottom: 12px;">
                        Excluir
                    </button>
                    <button type="button" class="blue" data-dismiss="modal" aria-label="Close">
                        Cancelar
                    </button>
                </div>
                
			</div>
		</div>
	</div>
	<!-- Modal END -->
</template>

<script>
// import customerMixin from "../mixins/customerMixin.js";
// import { server } from "../js/variables.js";

export default {
//   mixins: [customerMixin],
  props: ["modal_id"],
  components: {},
  data() {
    return {
    };
  },
  mounted() {},
  methods: {
    checkSubmit: function(e) {
      if (e.keyCode === 13) {
        this.find(null);
      }
    }
    //TODO: busca
  }
};
</script>

<style>

.btns-box{
    margin-top: 20px;
    padding-left: 37px;
}
@media only screen and (max-width: 600px) {

  

}
</style>