<template>
  <div>
    <div class="product-box" @click="goToProduct()" v-if="type == 0">
      <!-- big -->
      <div v-if="product.image" class="product-frame" :class="{ multiple: product.image[1] }">
        <img :src="getImageThumbUrl(product.image[0])" v-if="product.image[0]" class="product-image-1" alt="" />
        <img :src="getImageThumbUrl(product.image[1])" v-if="product.image[1]" class="product-image-2" alt="" />
        <img src="" v-if="product.image[0] == undefined" class="product-image-1 product-image-empty" alt="" />
        <div class="product-price text-center">
          {{ product.price | toCurrency }}
        </div>
        <div class="hr-yellow"></div>
      </div>
      <div v-else class="product-frame">
        <img src="" class="product-image-1 product-image-empty" alt="" />
        <div class="product-price text-center">
          {{ product.price | toCurrency }}
        </div>
        <div class="hr-yellow"></div>
      </div>

      <div class="product-infos">
        <div>
          <div class="product-commission"
            v-if="this.$route.fullPath != '/catalogo/' + this.$route.params.id && !user._id">
            <img class="icon-left" src="/static/svg/comission.svg" alt="" />
            <b> Ver comissão - ( p/ assinantes )</b>
          </div>
          <div class="product-commission" v-if="this.$route.fullPath != '/catalogo/' + this.$route.params.id && user._id">
            <img class="icon-left" src="/static/svg/comission.svg" alt="" />
            {{ product.sales_commission }}% Comissão - ({{
            ((product.sales_commission / 100) * product.price) | toCurrency
            }})
          </div>
          <hr v-if="this.$route.fullPath != '/catalogo/' + this.$route.params.id" />
          <div class="product-name">
            <h3>{{ product.name }}</h3>
            <!-- <h3 v-if="product.name.length < 35">{{ product.name }}</h3>
            <h3 v-else>{{ product.name.substring(0,35) + "..." }}</h3> -->
          </div>
          <div class="light-yellow-line"></div>
          <div class="product-category" v-if="product.groups[0] != undefined">
            <h4>{{ product.groups[0].name }}</h4>
          </div>
          <div class="product-call text-center">
            {{ product.call }}
          </div>
          <hr />
          <div class="row">
            <div class="col-md-6 product-commission" v-if="product.company.address[0] && product.company.address[0].city">
              {{ product.company.address[0].city }} -
              {{ product.company.address[0].state }}
            </div>
            <div class="col-md-6 product-commission">
              <b>{{ product.company.name_fancy }}</b>
            </div>
          </div>
        </div>
        <div>
          <div class="want-represent"
            v-if="company == null || !company._id || this.$route.fullPath.indexOf('vc/catalogo') != -1">
            <button class="" @click="representProductRequest($event)"
              v-if="this.$route.fullPath.indexOf('/catalogo/' + this.$route.params.id) == -1">Quero representar</button>
            <button class="" @click="addCart(product, $event)" v-else>Quero comprar</button>
          </div>
          <div class="want-represent" v-else>
            <button class="" v-if="product.status == 'READY'">Disponivel</button>
            <button class="" v-else>Indisponivel</button>
          </div>
          <div class="want-represent" v-if="this.$route.fullPath.indexOf('vc/catalogo') != -1">
            <button class=""
              v-if="this.$route.fullPath.indexOf('/catalogo/' + this.$route.params.id) == -1">Disponível</button>
          </div>
        </div>
      </div>
    </div>
    <div class="product-box-small box-item" @click="goToProduct()" v-if="type == 1">
      <!-- small -->
      <div class="row">
        <div class="col-4">
          <img :src="getImageThumbUrl(product.image[0])" v-if="product.image[0]" class="product-image-1" alt="" />
        </div>
        <div class="col-8">
          <h3>{{ product.name }}</h3>
          <div class="light-yellow-line"></div>
          <h4 v-if="product.groups[0]">{{ product.groups[0].name }}</h4>
          <h4 v-else></h4>
          <p>
            Preço venda: <b>{{ product.price | toCurrency }}</b>
          </p>
          <p>
            Comissão: <b>{{ product.sales_commission }}%</b>
          </p>
        </div>
      </div>
    </div>
    <div class="product-box-small box-item" @click="goToProductType2()" v-if="type == 2 && representation.product">
      <!-- small -->
      <div class="row">
        <div class="col-4">
          <img :src="getImageThumbUrl(representation.product.image[0])" v-if="representation.product.image[0]"
            class="product-image-1" alt="" />
        </div>
        <div class="col-8">
          <h3 class="limit-lines">{{ representation.product.name }}</h3>
          <div class="light-yellow-line"></div>
          <h4 v-if="representation.product.groups[0]">{{ representation.product.groups[0].name }}</h4>
          <h4 v-else></h4>
          <p>
            Preço venda: <b>{{ representation.product.price | toCurrency }}</b>
          </p>
          <p>
            Comissão: <b>{{ representation.product.sales_commission }}%</b>
          </p>

        </div>
        <button class="cancel-representation grey"
          @click="cancelRepresentation($event, representation)">Cancelar</button>
      </div>
    </div>
    <div class="product-box-small box-item" @click="goToProductType2()" v-if="type == 2 && representation.group">
      <div class="row" v-if="representation.group">
        <div class="col-4">
          <img :src="getImageThumbUrl(representation.group.image_icon)" v-if="representation.group.image_icon"
            class="product-image-1" alt="" />
        </div>
        <div class="col-md-8">
          <h3 class="limit-lines">{{ representation.group.name }}</h3>
          <div class="light-yellow-line"></div>
          <h4 v-if="representation.group.company.name_fancy">{{ representation.group.company.name_fancy }}</h4>
        </div>
        <button class="cancel-representation grey"
          @click="cancelRepresentation($event, representation)">Cancelar</button>
      </div>

    </div>

  </div>
</template>

<script>
import { server, local } from "../js/variables.js";
import userMixin from "../mixins/userMixin.js";
import companyMixin from "../mixins/companyMixin.js";

export default {
  mixins: [userMixin, companyMixin],
  props: ["product", "type", "representation"],
  components: {},
  data() {
    return {
      favorite: 0,
      representationProduct: {
        product_id: ''
      }
    };
  },
  mounted() {
    // console.log('AQUI/'+ this.$route.params.id, this.$route)
    // console.log('AQUIE STA', this.$route.fullPath.indexOf('vc/catalogo')!= -1)
  },
  watch: {
    company(newValue, oldValue) {
      // console.log(newValue, oldValue);

    },
  },
  methods: {
    goToProduct() {
      // if (this.product.slug) {
      //     if(this.$route.fullPath == '/co/catalogo'){
      //         window.open ('./co/catalogo/produto/' + this.product.slug, '_self')
      //     }else{
      //         window.open ('./product/' + this.product.slug, '_self')
      //     }
      // } else {
      // console.log('fullpath',this.$route.fullPath);
      // console.log('company id',this.company._id);
      let salesmanId = this.$route.params.id;
      if (this.user.type == 'EMPLOYEE') {
        if (this.company._id == this.product.company._id) window.open("./co/catalogo/produto/" + this.product.slug, "_self");
        // const url = "/co/catalogo/produto/"+ this.product.slug;
        // this.$router.push(url);
      } else if (this.user.type == 'SALESMAN') {
        window.open("./vc/produto/" + this.product.slug, "_self");
        // const url = "/vc/produto/"+ this.product.slug;
        // this.$router.push(url);
      } else if (this.$route.fullPath == '/catalogo/' + this.$route.params.id) {
        // window.open("./catalogo/" + salesmanId + "/produto/" + this.product.slug, "_self");
        const url = "/catalogo/" + salesmanId + "/produto/" + this.product.slug;
        this.$router.push(url);
      } else {
        window.open("./produto/" + this.product.slug, "_self");
        // const url = "/produto/" + this.product.slug;
        // this.$router.push(url);
      }
    },
    goToProductType2() {
      let salesmanId = this.$route.params.id;
      if (this.representation.product) {
        const url = "/vc/produto/" + this.representation.product.slug;
        this.$router.push(url);
      } else if (this.representation.group) {
        return
      }
    },
    addCart(item, event) {
      let self = this;
      if (event.stopPropagation) event.stopPropagation();
      if (!item.image || item.image.length == 0) {
        item.image = [
          { thumb_url: '', path: '' }
        ]
      }

      let groupName = "";
      if (item.groups[0] != undefined) {
        groupName = item.groups[0].name;
      }

      let cartItem = {
        amount: 1,
        product: {
          _id: item._id,
          name: item.name,
          price: item.price,
          sales_commission: item.sales_commission,
          image: {
            thumb_url: item.image[0].thumb_url,
            path: item.image[0].path,
          },
          groups: {
            name: groupName,
          },
        }
      }

      var cart = self.$cookies.get("cart-" + this.$route.params.id);
      let found = false;
      if (!cart) cart = [];
      else cart = JSON.parse(cart);
      console.log('cart', cart)
      for (let p of cart) {
        if (p.product._id == cartItem.product._id) {
          found = true;
          p.amount++;
          // return
        }
      }
      if (!found) {
        cart.push(cartItem);
      }

      self.$cookies.set("cart-" + this.$route.params.id, JSON.stringify(cart));

      self.$swal({
        icon: "success",
        title: "Item adicionado ao carrinho!",
        // footer: '<a href>Why do I have this issue?</a>'
        showCancelButton: true,
        confirmButtonText: "Ver meu carrinho",
        cancelButtonText: "Continuar comprando",
        // closeOnConfirm: false,
        // closeOnConfirm: false,
      }).then((result) => {
        if (result.value) {
          window.open("./catalogo/" + this.$route.params.id + '/carrinho', "_self");
        }
      })
    },
    representProductRequest(event) {
      let self = this;
      if (event.stopPropagation) event.stopPropagation();
      if (localStorage.token) {
        if (local.permissions.requestRepresentation < local.permissions.requestRepresentationMax) {
          self.representationProduct.product_id = this.product._id;
          this.axios.post(server.url + '/api/sales-representative/', {
            representation: self.representationProduct,

          }, server.config)
            .then(function (response) {
              let responseData = response.data;
              self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                  // window.open("./vc/", "_self"); 
                  // const url = "/vc/perfil";
                  // self.$router.push(url);
                }
              })
            })
            .catch(function (error) {
              self.output = error;
              self.showError(error);

            });
        } else {
          self.$swal({
            icon: "error",
            title: "Aviso",
            text: 'Você atingiu o limite de representações de produtos',
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: true,
            confirmButtonText: "Ir para planos",
            cancelButtonText: "Ok",
            // closeOnConfirm: false,
            // closeOnConfirm: false,
          }).then((result) => {
            if (result.value) {
              let url = '';
              if (self.company == null || !self.company._id) {
                url = "/vc/perfil/";
              } else {
                url = "/co/perfil/";
              }
              self.$router.push(url);
            }
          })
        }
      } else {
        self.$swal({
          icon: 'warning',
          title: 'Atenção',
          text: 'É necessário estar logado como profissional para esta funcionalidade',
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            const url = "/vc/cadastro";
            self.$router.push(url);
          }
        })
      }
    },
    cancelRepresentation(event, item) {
      let self = this;
      // var id = this.$route.params.id;
      if (event.stopPropagation) event.stopPropagation();

      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }
      this.axios.delete(server.url + '/api/sales-representative/' + item._id, server.config)
        .then(function (response) {
          let responseData = response.data;
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: responseData.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              // window.open("./co/vagas", "_self"); 
              location.reload();
            }
          })

        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);

        });
    },
  },
};
</script>

<style lang="scss">
.product-box-small-group {
  height: 90px;

  h4 {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .cancel-representation {
    position: absolute;
    right: 15px;
    width: 95px;
    bottom: 8px;
  }

  &:hover {
    border-color: var(--main-color-mediumgrey) !important;
    cursor: initial;
  }
}

.product-box-small {
  height: 149px;

  h4 {
    font-size: 14px;
    margin-bottom: 12px;
  }

  img {
    height: 112px;
    margin: auto;
    display: block;
  }

  .cancel-representation {
    position: absolute;
    right: 15px;
    width: 95px;
    bottom: 8px;
  }
}

.product-box {

  position: relative;
  display: block;
  cursor: pointer;
  background: #fff;
  width: 100%;
  min-height: 356px;
  // padding: 0px 12px;
  margin-top: 9px;
  margin-bottom: 24px;
  // border: 1px solid var(--main-color-primary);
  border-radius: 13px;

  &:hover {

    .product-price,
    .product-infos button,
    .hr-yellow {
      background-color: var(--main-color-secundary-dark);
      color: var(--main-title-color);
    }
  }

  .product-frame {
    width: 100%;
    display: block;
    margin: auto;
    margin-top: 15px;
    // margin-bottom: 15px;
    height: 165px;
    position: relative;
    /* background-color: #aaa; */

    .hr-yellow {
      position: absolute;
      bottom: 0px;
    }

  }

  img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
  }

  .product-image-1 {
    display: block;
  }

  .product-image-2 {
    display: none;
  }

  .product-frame.multiple:hover>.product-image-1 {
    display: none;
  }

  .product-frame.multiple:hover>.product-image-2 {
    display: block;
  }

  .product-name {
    color: var(--main-title-color);
    font-size: 14px;
    font-weight: bold;
    line-height: 15px;
    margin-top: 3px;
    text-align: left;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .product-category {
    h4 {
      color: #efcb70;
    }

    margin-top: 3px;
  }

  .product-call {
    color: var(--main-title-color);
    font-size: 12px;
    line-height: 17px;
    margin-top: 20px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .product-price {
    color: var(--main-title-color);
    font-size: 15px;
    font-weight: bold;
    line-height: 23px;
    background-color: var(--main-color-secundary);
    width: 60%;
    height: 23px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .product-commission {
    color: var(--main-title-color);
    font-size: 12px;
    line-height: 19px;
    // margin-top: 15px;
    margin-bottom: 12px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    img {
      margin-top: 2px;
      margin-right: 6px;
    }
  }

  .want-represent {

    // bottom: 0px;
    // position: absolute;
    // left: 50%;
    // margin-left: -72px;

    button {
      font-size: 14px;
      padding: 6px;
      text-align: center;
      display: block;
      cursor: pointer;
      margin: auto;
      width: 145px;
      height: auto;

    }
  }

  a {
    position: absolute;
    bottom: 32px;
    color: #006db7;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
  }

  .product-infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--main-color-lightgrey);
    padding: 15px;
    padding-bottom: 0px;
    min-height: 315px;

    button {
      background-color: var(--main-color-primary);
      color: var(--main-color-secundary);
      border-radius: 0px;
    }
  }
}

.light-yellow-line {
  background-color: #eeb013;
  width: 20%;
  height: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.product-image-empty {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid var(--main-color-mediumgrey);
  border-radius: 5px;
  background-color: transparent;
}

.product-box a.product-cart-button img {
  width: 32px;
  height: 29px;
  display: block;
  margin: auto;
  margin-top: 10px;
}

.limit-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media only screen and (max-width: 600px) {
  .product-box {
    min-height: 300px;
    white-space: initial;
    padding: 0px;

    .product-frame {
      width: 85%;
      height: 150px;
    }

    .product-infos {
      min-height: 350px;
    }

    .product-price {
      font-size: 10px;
    }
  }

  // .product-box h3 {
  //     font-size: 14px;
  // }
  .product-list {
    margin-right: 0px;
    margin-left: 0px;

    .col-6 {
      padding: 2px;
    }
  }

  .product-box-small {
    &.box-item {
      height: auto !important;
    }

    img {
      height: auto;
    }
  }

  .product-list-horizontal {
    display: block;
    margin: 0px;
    margin-top: 30px;
    overflow-x: auto;
    white-space: nowrap;
    min-height: 60px;
  }
}
</style>