<template>
  <div>
    <Sidebar />
    <!-- <Header :headerType="1" v-if="this.$route.fullPath != '/para-empresas' && this.$route.fullPath != '/sobre-a-empresa'" ></Header>
    <Header :headerType="4" v-else></Header> -->
    <Header :headerType="10"></Header>

    <!-- <button v-if="deferredPrompt" @onClick="promptInstall">
      Add to home screen
    </button> -->
    <div v-html="output"></div>
    <main>
      <slot></slot>
    </main>


    <login-modal :modal_id="'loginModal'"></login-modal>
    <company-register-modal :modal_id="'companyRegisterModal'"></company-register-modal>
    <salesman-register-modal :modal_id="'salesmanRegisterModal'"></salesman-register-modal>
    <Footer :footerType="1" />
  </div>
</template>

<script>
import sessionMixin from "../mixins/sessionMixin.js";
import { BeforeInstallPromptEvent, VuePwaInstallMixin } from "vue-pwa-install";

import Header from "../components/Header.vue";
import Sidebar from "../components/Sidebar.vue";
import Footer from "../components/Footer.vue";

import LoginModal from "../components/LoginModal.vue";
import CompanyRegisterModal from "../components/CompanyRegisterModal.vue";
import SalesmanRegisterModal from "../components/SalesmanRegisterModal.vue";

export default {
  mixins: [sessionMixin, VuePwaInstallMixin],
  components: {
    Header,
    Sidebar,
    Footer,

    LoginModal,
    CompanyRegisterModal,
    SalesmanRegisterModal,
  },
  data() {
    return {
      // deferredPrompt: BeforeInstallPromptEvent | void
      deferredPrompt: BeforeInstallPromptEvent,
      output: ""
    };
  },
  mounted() { },
  created() {
    let self = this;

    this.$on("canInstall", (event) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt:
      // console.log("canInstall");
      event.preventDefault();

      // Stash the event so it can be triggered later:
      this.deferredPrompt = event;
    });
  },
  methods: {
    promptInstall() {
      // Show the prompt:
      // console.log("promptInstall");
      this.deferredPrompt.prompt();

      // Wait for the user to respond to the prompt:
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // console.log("User accepted the install prompt");
        } else {
          // console.log("User dismissed the install prompt");
        }

        this.deferredPrompt = null;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../scss/reset.scss";
@import "../scss/base.scss";
@import "../scss/form.scss";
@import "../css/mobile.css";

.container-fluid-mobile {
  padding: 0px;
  /* background-color: #fff; */
}

@media only screen and (max-width: 600px) {
  main {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
</style>
