import { server, local } from '../../../js/variables'
import axios from 'axios'
import Home from '../views/Home-user.vue';
import Register from '../views/Register-user.vue';
import UserData from '../views/UserData.vue';
import PlanPreview from '../views/PlanPreview-user.vue';
import Cards from '../views/Cards-user.vue';
import MyPlans from '../views/MyPlans-user.vue';
import Indications from '../views/Indications.vue';
import EarningsList from '../views/EarningsList.vue'
import Candidatures from '../views/Candidatures.vue';
import SearchOpportunities from '../views/SearchOpportunities.vue';
import OpportunityDetails from '../views/OpportunityDetails.vue';
import ProductDetails from '../views/ProductDetails-salesman.vue';
import Catalog from '../views/Catalog-salesman.vue';
import RepresentationList from '../views/RepresentationRequestList.vue'
import SalesmanDetailsView from '../views/SalesmanDetailsView.vue'
import TrainingSearch from '../views/TrainingSearch.vue';
import TrainingMy from '../views/TrainingMy.vue';
import TrainingRegister from '../views/TrainingRegister.vue';
import LessonRegister from '../views/LessonRegister.vue';
import TrainingView from '../views/TrainingView.vue';
import LessonView from '../views/LessonView.vue';
import Training from '../views/Training.vue';
import TrainingTeacher from '../views/TrainingTeacher.vue';
import TrainingSegment from '../views/TrainingSegment.vue';
import TrainingSubscribed from '../views/TrainingSubscribed.vue';
import Cart from '../views/Cart.vue';
import OrderConfirm from '../views/OrderConfirm.vue';
import OrderSucessful from '../views/OrderSucessful.vue';
import OrderHistory from '../views/OrderHistory.vue';
import LessonWatch from '../views/LessonWatch.vue';
import TrainingWatch from '../views/TrainingWatch.vue';
import Annotations from '../views/Annotations.vue';
import Afiliates from '../views/Afiliates.vue';
import Page404 from '../views/404.vue';

export default [
    { path: '/vc/', component: Home, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/cadastro', component: Register},
    { path: '/vc/dados', component: UserData, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/planos', component: PlanPreview, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/cartoes', component: Cards, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/perfil', component: MyPlans, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/indicacoes', component: Indications, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/recebimentos', component: EarningsList, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/candidaturas', component: Candidatures, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/solicitacoes', component: RepresentationList, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/buscar-vagas', component: SearchOpportunities, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/vaga/:id', component: OpportunityDetails, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/produto/:id', component: ProductDetails, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/catalogo/', component: Catalog, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/perfil/visualizacao', component: SalesmanDetailsView, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/buscar-treinamentos', component: TrainingSearch, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/meus-treinamentos', component: TrainingMy, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/meus-treinamentos/:id', component: TrainingRegister, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/meus-treinamentos/:trainingId/sessao/:sectionId/aula/:id', component: LessonRegister, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/meus-treinamentos/:id/visualizacao', component: TrainingView, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/meus-treinamentos/:idTraining/aula/:idLesson/visualizacao', component: LessonView, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/treinamento/:id', component: Training, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/buscar-treinamentos/treinador/:id', component: TrainingTeacher, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/buscar-treinamentos/tema/:id', component: TrainingSegment, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/treinamentos-inscritos', component: TrainingSubscribed, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/carrinho', component: Cart, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/pedido/finalizacao', component: OrderConfirm, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/pedido/sucesso', component: OrderSucessful, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/historico-compras', component: OrderHistory, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/treinamentos-inscritos/:idTraining/aula/:idLesson', component: LessonWatch, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/treinamentos-inscritos/:id', component: TrainingWatch, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/anotacoes', component: Annotations, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '/vc/afiliados', component: Afiliates, beforeEnter: (to, from, next) => { guard(to, from, next); }},
    { path: '*', component: Page404 } 
 ]

 const guard = function(to, from, next) {
  // let path = encodeURI(window.location.pathname) ;
  let path = encodeURI(to.fullPath) ;

  if (localStorage.token) {
    let user = JSON.parse(localStorage.user)
    if(user.type == 'SALESMAN'){
      next();
    }else{
      localStorage.removeItem('token');
      localStorage.removeItem('slug');
      localStorage.removeItem('user');
      localStorage.removeItem('company');
      window.open("/login", "_self");
    }
  } else {
    // window.open("./", "_self");
    window.open("./login?redirect="+path, "_self");
  }
};

//  const guard = function(to, from, next) {
  
//     let path = encodeURI(window.location.pathname) ;
//     // console.log("from", path);
  

//     axios.get(  server.url + '/api/auth/load', server.config)
//       .then(function (response) {
//         let responseData = response.data.data;
//         if (!responseData.user) {
//           window.open("./login?redirect="+path, "_self");
//         }
//         next();
//       })
//       .catch(function (error) {
//         window.open("./login", "_self");
//       });
//   };
