<template>
  <main-layout>
    <banner :bannerType="10" class=" "> </banner>

    <benefits-home id="find-about" />
    <company-list :companyList="companyList" v-if="companyList.length > 0"></company-list>
    <OpportunityList />
    <div class="container no-padding hide-mobile">
      <hr>
    </div>
    <ProductList />
    <!-- <div class="container">
      <hr>
    </div> -->
    <div class="register-your-team">
      <h2>Recrute e treine seu time de vendas</h2>
      <button @click="goTo('/co/cadastro')">Cadastre sua empresa</button>
    </div>

    <div class="container">

      <about-company />
    </div>
    <div class="container no-padding hide-mobile">
      <hr>
    </div>
    <SalesmanList />
    <be-a-trainer id="find-trainings" />
    <TrainingList />
    <!-- find-trainings -->
    <contact></contact>
    <newsletter />
    <!-- <refer-and-win-modal :modal_id="'referAndWinModal'"></refer-and-win-modal> -->
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import Contact from "../components/Contact.vue";
import Banner from "../components/Banner.vue";
import BenefitsHome from "../components/BenefitsHome.vue";
import Newsletter from "../components/Newsletter.vue";
import CompanyList from "../components/CompanyList.vue";
import OpportunityList from "../components/OpportunityList.vue";
import ProductList from "../components/ProductList.vue";
import AboutCompany from "../components/AboutCompany.vue";
import SalesmanList from "../components/SalesmanList.vue";
import BeATrainer from "../components/BeATrainer.vue";
import TrainingList from "../components/TrainingList.vue";
// import ReferAndWinModal from "../components/ReferAndWinModal.vue";
import moment from 'moment'
import { server } from "../js/variables.js";

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
    Contact,
    Banner,
    BenefitsHome,
    Newsletter,
    CompanyList,
    OpportunityList,
    ProductList,
    AboutCompany,
    SalesmanList,
    BeATrainer,
    TrainingList,
    // ReferAndWinModal,
  },
  data() {
    return {
      salesman: {},
      companyList: [],
    };
  },
  mounted() {
    // var parameters = this.$route.query;
    // if(parameters.afiliado){
    //   localStorage.setItem('afiliate', parameters.afiliado);
    // }
    this.getCompanies();
  },
  methods: {
    register(e) {
      //   window.open("./company-registration", "_self");
      const url = "/company-registration";
      this.$router.push(url);
    },
    compararObjetosPorData(a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    },
    onPageChange(page) {
      this.sliderPage = page;
    },
    gotostore() {
      // window.open("./store", "_self");
      const url = "/store";
      this.$router.push(url);
    },
    getCompanies() {
      let self = this;
      // if (!localStorage.token) {
      // 	return
      // }
      // server.config.headers = {
      // 	Authorization: 'Bearer ' + localStorage.token
      // }

      this.axios
        .get(server.url + "/api/company?limit=6", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.companyList = responseData.company_list
          for (let i = 0; i < self.companyList.length; i++) {
            self.companyList.sort(self.compararObjetosPorData);
          }
          console.log(self.companyList);  
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },

  },
};
</script>

<style lang="scss">
// @import "../scss/reset.scss";
// @import "../scss/base.scss";

.register-your-team {
  display: flex;
  flex-flow: column wrap;
  background-color: var(--main-color-primary);
  align-items: center;
  // justify-content: center;
  height: 230px;
  margin: 30px 0px;

  h2 {
    color: white;
    margin-top: 55px;
    margin-bottom: 40px;
  }

  button {
    background-color: white;
    color: var(--main-color-primary);
    width: auto;
    padding: 0px 40px;
  }
}

@media only screen and (max-width: 600px) {}
</style>
