<template>
  <main-layout>
  <!-- <banner/> -->
	<img class="banner-about" src="/static/img/banner_about.jpg" alt />
    <div class="container">
    <div class="afiliate-box">
      <h1>Página não encontrada</h1>
      <div class="hr-yellow hr-4 center" style="margin-bottom: 38px;"></div>
      
      
    </div>
    </div>
    <br>
    
    <contact/>
    <benefits-for-you/>
    <newsletter/>
  </main-layout>
</template>

<script>
  import MainLayout from '../layouts/Main.vue'
  export default {
    components: {
      MainLayout
    }
  }
</script>


<style lang="scss">
// @import "../scss/reset.scss";
// @import "../scss/base.scss";

.banner-about{
	width: 100%;
	margin-top: -15px;
	margin-bottom: 50px;
}

</style>