<template>
  <main-layout>
    <banner :bannerType="10"> </banner>
    <div class="affiliate">
      <div class="container affiliate-explanation separator" id="find-affiliate">
        <div class="row">
          <div class="col-md-6">
            <h1 class="h1-affiliate">Indique e lucre</h1>
            <h1 class="h1-affiliate-small">todos os dias</h1>
            <h2>Veja como é fácil se tornar um afiliado de sucesso</h2>
            <p style="padding-top: 15px">
              Você sabia que um afiliado pode ganhar dinheiro somente divulgando
              seu link para amigos se cadastrarem no site pela internet?
            </p>

            <div class="light-grey-bg warning">
              <!-- <div class="grey-left"></div> -->
              <p>
                Toda venda realizada através dessa divulgação é atribuida ao
                afiliado e, após o faturamento, gera uma comissão!
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="video-area">
              <div class="light-grey-bg">
                <video id="myVideo" preload="auto" src="/static/video/motion-vcvende-2.mp4" type="video/mp4"
                  :autoplay="false" controls></video>
              </div>
              <!-- <img src="/static/svg/icon_play.svg" alt="" class="center-all" />
              <div class="secundary-color-bg video-text">
                Veja nosso video de apresentação
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid affiliate-tutorial separator">
        <div class="container affiliate-tutorial-grid">
          <div class="step-1">
            <div class="outer-step-div center">
              <div class="step-icon-div">
                <img src="/static/svg/icon_step1.svg" alt="" class="center" style="padding-top: 20px" />
              </div>
              <div class="secundary-color-bg step-1-yellow"></div>
            </div>
            <div class="step-title">1 passo</div>
            <p>
              Cadastre-se no site como conta profissional aceitando os termos. <br><br>
              Você vai receber um link de afiliado automaticamente sem custo.
            </p>
          </div>
          <div>
            <img src="/static/svg/icon_arrow1.svg" alt="" style="padding-top: 15px" />
          </div>
          <div class="step-2">
            <div class="outer-step-div center">
              <div class="step-icon-div">
                <img src="/static/svg/icon_step1.svg" alt="" class="center" style="padding-top: 20px" />
              </div>
              <div class="secundary-color-bg step-2-yellow"></div>
            </div>
            <div class="step-title">2 passo</div>
            <p>
              Com seu link envie para seus amigos e peça para se cadastrarem gratuitamente no vocevende. <br>
              Ajude seu amigo a encontrar um novo emprego, uma representação ou se tornar um treinador.<br>
              Além de ajudá-lo, caso ele adquira um plano você é recompensando com um bônus e premiação.
            </p>
          </div>
          <div>
            <img src="/static/svg/icon_arrow2.svg" alt="" style="padding-top: 15px" />
          </div>
          <div class="step-3">
            <div class="outer-step-div center">
              <div class="step-icon-div">
                <img src="/static/svg/icon_step1.svg" alt="" class="center" style="padding-top: 20px" />
              </div>
              <div class="secundary-color-bg step-3-yellow"></div>
            </div>
            <div class="step-title">3 passo</div>
            <p>
              Agora é só administrar seus rendimentos! <br>
              adquira o Plano Afiliado Hunter para ter maiores bônus! <br>
              Você tem um trabalho hoje mesmo! <br>
              Vamos começar?
            </p>
          </div>
          <div style="grid-column: 5/8">
            <button class="center affiliate-btn" @click="goTo('/vc/cadastro')">
              Começar agora
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="container-fluid affiliate-tutorial separator">
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <div class="outer-step-div center">
                <div class="step-icon-div">
                  <img
                    src="/static/svg/icon_step1.svg"
                    alt=""
                    class="center"
                    style="padding-top: 20px"
                  />
                </div>
                <div class="secundary-color-bg step-1-yellow"></div>
              </div>
              <div class="step-title">1 passo</div>
              <p>
                Cadastre-se no site como conta profissional aceitando os termos. <br><br>
                Você vai receber um link de afiliado automaticamente sem custo.
              </p>
            </div>
            <div class="col-md-1">
              <img
                src="/static/svg/icon_arrow1.svg"
                alt=""
                style="padding-top: 15px"
              />
            </div>
            <div class="col-md-3">
              <div class="outer-step-div center">
                <div class="step-icon-div">
                  <img
                    src="/static/svg/icon_step1.svg"
                    alt=""
                    class="center"
                    style="padding-top: 20px"
                  />
                </div>
                <div class="secundary-color-bg step-2-yellow"></div>
              </div>
              <div class="step-title">2 passo</div>
              <p>
                Com seu link envie para seus amigos e peça para se cadastrarem gratuitamente no vocevende. <br>
                Ajude seu amigo a encontrar um novo emprego, uma representação ou se tornar um treinador.<br>
                Além de ajudá-lo, caso ele adquira um plano você é recompensando com um bônus e premiação.
              </p>
            </div>
            <div class="col-md-1">
              <img
                src="/static/svg/icon_arrow2.svg"
                alt=""
                style="padding-top: 15px"
              />
            </div>
            <div class="col-md-3">
              <div class="outer-step-div center">
                <div class="step-icon-div">
                  <img
                    src="/static/svg/icon_step1.svg"
                    alt=""
                    class="center"
                    style="padding-top: 20px"
                  />
                </div>
                <div class="secundary-color-bg step-3-yellow"></div>
              </div>
              <div class="step-title">3 passo</div>
              <p>
                Agora é só administrar seus rendimentos! <br>
                adquira o Plano Afiliado Hunter para ter maiores bônus! <br>
                Você tem um trabalho hoje mesmo! <br>
                Vamos começar?
              </p>
            </div>
            <div class="offset-md-1"></div>
            <div class="offset-md-4 col-md-3">
              <button class="center affiliate-btn" @click="goTo('./vc/cadastro')">Começar agora</button>
            </div>
          </div>
        </div>
      </div> -->
      <div class="container-fluid affiliate-benefits">
        <div class="container">
          <h1 class="h1-affiliate-small" style="text-align: center; padding-top: 70px">
            Vantagens do programa de afiliados
          </h1>
          <div class="grey-line center"></div>
          <div class="yellow-line center"></div>
          <div class="row" style="padding-top: 50px; padding-bottom: 75px">
            <div class="col-md-3">
              <div class="benefit-box">
                <img src="/static/svg/icon_advantage.svg" alt="" class="center" />
                <div class="benefit-title">
                  Ganhe dinheiro pelo celular sem sair de casa!
                </div>
                <!-- <div class="yellow-line center"></div> -->
              </div>
            </div>
            <div class="col-md-3">
              <div class="benefit-box">
                <img src="/static/svg/icon_advantage.svg" alt="" class="center" />
                <div class="benefit-title" style="padding-top: 32px;">
                  Recebe renda extra recorrente!
                </div>
                <!-- <div class="yellow-line center"></div> -->
              </div>
            </div>
            <div class="col-md-3">
              <div class="benefit-box">
                <img src="/static/svg/icon_advantage.svg" alt="" class="center" />
                <div class="benefit-title" style="padding-top: 32px;">
                  Inicie um negócio sem burocracia!
                </div>
                <!-- <div class="yellow-line center"></div> -->
              </div>
            </div>
            <div class="col-md-3">
              <div class="benefit-box">
                <img src="/static/svg/icon_advantage.svg" alt="" class="center" />
                <div class="benefit-title" style="padding-top: 32px;">
                  Seja seu próprio chefe!
                </div>
                <!-- <div class="yellow-line center"></div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container affiliate-numbers separator" v-if="false">
        <div class="row">
          <div class="col-md-4">
            <div class="numbers-title-left">
              Veja quantas pessoas foram comissionadas no nosso site
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Donec quam felis, ultricies nec.
            </p>
          </div>
          <div class="col-md-4">
            <div class="circle-yellow">
              <div class="number">R$ 23.222,10</div>
              <div class="below-number">em comissões neste ano</div>
              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="numbers-title-right">
              Participe você também e seja um vendedor de sucesso!
            </div>
            <button class="affiliate-btn start-now" @click="goTo('./vc/cadastro')">
              Começar agora
            </button>
          </div>
        </div>
      </div>
      <div class="container-fluid affiliate-comments separator">
        <div class="container">
          <h1 class="h1-affiliate-small" style="text-align: center">
            O que dizem da gente
          </h1>
          <div class="grey-line center"></div>
          <div class="yellow-line center"></div>
          <div class="row" style="padding-top: 50px">
            <div class="col-md-6">
              <div class="comment-box">
                <div class="row">
                  <div class="col-md-3">
                    <!-- <img
                      src="/static/img/depoimento-rodrigo.jpeg"
                      alt=""
                      class="center"
                    /> -->
                    <div class="user-img" style="background-image: url(./static/img/depoimento-rodrigo.jpeg)"></div>
                  </div>
                  <div class="col-md-9">
                    <div class="comment-user-name">Rodrigo Torres</div>
                    <div class="comment-user-position">Diretor de negócios digitais</div>
                    <p>
                      A VoceVende é uma solução inovadora que une profissionais e empresas em uma
                      plataforma robusta e completa para a realização de negócios. A interface
                      moderna e intuitiva ajudam o seu uso no dia-a-dia. Uma excelente opção para
                      quem trabalha com vendas e treinamentos e que também pode ser aplicada em outras
                      áreas de negócios.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="comment-box">
                <div class="row">
                  <div class="col-md-3">
                    <!-- <img
                      src="/static/img/depoimento-2.jpeg"
                      alt=""
                      class="center"
                    /> -->
                    <div class="user-img" style="background-image: url(./static/img/depoimento-2.jpeg)"></div>
                  </div>
                  <div class="col-md-9">
                    <div class="comment-user-name">Johanne Lepoutre</div>
                    <div class="comment-user-position">CEO & FOUNDER Transforme Centro de Desenvolvimento Humano</div>
                    <p>
                      Eu indico o portal vocevende para quem busca uma oportunidade ou emprego na área comercial e
                      vendas,
                      representar produtos de empresa e também pode criar seus próprios treinamentos e vender no site.
                      Achei sensacional o programa de afiliados para quem quer ter uma renda extra. Parabéns!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="container affiliate-help separator" v-if="false">
        <h1 class="h1-affiliate-small" style="text-align: center">
          Como podemos lhe ajudar?
        </h1>
        <p style="text-align: center; padding-top: 12px">
          Faça parte desta comunidade de vendas!
        </p>
        <div class="row" style="padding-top: 55px">
          <div class="col-md-4">
            <div class="circle-yellow-full center">
              <img src="/static/svg/icon_whats_white.svg" alt="" class="center-all" />
            </div>
            <div class="help-title">Atendimento online</div>
            <p>Whatsapp</p>
          </div>
          <div class="col-md-4">
            <div class="circle-yellow-full center">
              <img src="/static/svg/icon_phone_white.svg" alt="" class="center-all" />
            </div>
            <div class="help-title">Ver telefone</div>
            <p>Telefone fixo</p>
          </div>
          <div class="col-md-4">
            <div class="circle-yellow-full center">
              <img src="/static/svg/icon_sac_white.svg" alt="" class="center-all" />
            </div>
            <div class="help-title">Ligamos para você</div>
            <p>Solicitar ligação</p>
          </div>
        </div>
      </div>
      <!-- <button
        class="affiliate-btn"
        data-toggle="modal"
        data-target="#referAndWinModal"
      >
        teste
      </button>
      
      <button
        class="affiliate-btn"
        data-toggle="modal"
        data-target="#loginModal"
      >
        login
      </button>
      <button
        class="affiliate-btn"
        data-toggle="modal"
        data-target="#companyRegisterModal"
      >
        Cadastro C
      </button>
      <button
        class="affiliate-btn"
        data-toggle="modal"
        data-target="#salesmanRegisterModal"
      >
        Cadastro P
      </button> -->
      <refer-and-win-modal :modal_id="'referAndWinModal'"></refer-and-win-modal>
      <login-modal :modal_id="'loginModal'"></login-modal>
      <company-register-modal :modal_id="'companyRegisterModal'"></company-register-modal>
      <salesman-register-modal :modal_id="'salesmanRegisterModal'"></salesman-register-modal>
    </div>

    <newsletter />
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import Banner from "../components/Banner.vue";
import ReferAndWinModal from "../components/ReferAndWinModal.vue";
import LoginModal from "../components/LoginModal.vue";
import CompanyRegisterModal from "../components/CompanyRegisterModal.vue";
import SalesmanRegisterModal from "../components/SalesmanRegisterModal.vue";
import Newsletter from "../components/Newsletter.vue";
import { server } from "../js/variables.js";

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
    Banner,
    ReferAndWinModal,
    LoginModal,
    CompanyRegisterModal,
    SalesmanRegisterModal,
    Newsletter,
  },
  data() {
    return {
      sliderList: [
        { link_redirect: "./", image_desktop: "/static/img/slider.jng" },
      ],
      mobileShowFilter: false,
    };
  },
  mounted() {
    var parameters = this.$route.query;
    this.$scrollTo('#find-affiliate');
  },
  methods: {
    register(e) {
      //   window.open("./company-registration", "_self");
      const url = "/company-registration";
      this.$router.push(url);
    },
    onPageChange(page) {
      this.sliderPage = page;
    },
    gotostore() {
      // window.open("./store", "_self");
      const url = "/store";
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
// @import "../scss/reset.scss";
// @import "../scss/base.scss";

.affiliate {
  .h1-affiliate {
    font-size: 50px;
    padding-top: 45px;
  }

  .h1-affiliate-small {
    font-size: 35px;
    padding-top: 0px;
  }

  .affiliate-btn {
    margin-top: 60px;
    margin-bottom: 50px;
    color: var(--main-color-primary);
    width: 200px;
  }

  .separator {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .grey-line {
    background-color: var(--main-color-lightgrey2);
    width: 70%;
    height: 2px;
    margin-top: 35px;
  }

  .yellow-line {
    background-color: var(--main-color-secundary);
    width: 30%;
    height: 4px;
    margin-top: -4px;
  }

  .affiliate-explanation {
    h2 {
      padding-top: 30px;
    }

    .video-area {
      height: 100%;
      width: 100%
    }

    .warning {
      padding: 20px;
      margin-top: 25px;
      margin-bottom: 20px;

      p {
        font-weight: bold;
        color: var(--main-title-color);
        // margin-left: -20px;
      }

      .grey-left {
        background-color: var(--main-color-lightgrey);
        position: absolute;
        width: 92px;
        height: 89px;
        left: -77px;
        top: 269px;
      }
    }

    .video-text {
      padding: 20px;
      color: var(--main-title-color);
      font-weight: bold;
      width: 295px;
      position: absolute;
      bottom: 50px;
      left: 50%;
      margin-left: -147px;
    }
  }

  .affiliate-tutorial-grid {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    // grid-template-columns: 30px auto auto auto auto auto;
    text-align: center;

    .step-1 {
      grid-column: 1/4;
    }

    .step-2 {
      grid-column: 5/8;
    }

    .step-3 {
      grid-column: 9/12;
    }

  }

  .affiliate-tutorial {
    background-color: var(--main-color-primary);

    .outer-step-div {
      width: 93px;
      height: 96px;
    }

    .step-1-yellow {
      width: 62px;
      height: 64px;
      border-radius: 5px;
      position: absolute;
      top: -20px;
      left: -20px;
    }

    .step-2-yellow {
      width: 62px;
      height: 64px;
      border-radius: 5px;
      position: absolute;
      bottom: -13px;
      right: -19px;
    }

    .step-3-yellow {
      width: 62px;
      height: 64px;
      border-radius: 5px;
      position: absolute;
      top: -11px;
      right: -15px;
    }

    .step-icon-div {
      background-color: white;
      width: 93px;
      height: 96px;
      border-radius: 5px;
      position: absolute;
      z-index: 5;
    }

    .step-title {
      font-size: 33px;
      color: var(--main-color-secundary);
      font-weight: bold;
      text-align: center;
      padding-top: 40px;
    }

    p {
      text-align: center;
      color: #fff;
      padding-top: 20px;
      font-size: 17px;
    }
  }

  .affiliate-benefits {
    background-color: var(--main-color-lightgrey);

    .benefit-box {
      background-color: white;
      box-shadow: 1px 1px 1px #00000029;
      border-radius: 11px;
      padding: 30px 40px;
      height: 212px;

      .benefit-title {
        padding-top: 20px;
        font-size: 19px;
        font-weight: bold;
        color: var(--main-title-color);
        text-align: center;
      }

      .yellow-line {
        margin-top: 0px;
      }
    }
  }

  .affiliate-numbers {
    .numbers-title-left {
      font-size: 30px;
      color: var(--main-title-color);
      font-weight: bold;
      padding-top: 70px;
      padding-right: 20px;
      text-align: left;
    }

    .numbers-title-right {
      font-size: 30px;
      color: var(--main-title-color);
      font-weight: bold;
      padding-top: 70px;
      padding-left: 30px;
      text-align: left;
    }

    .start-now {
      margin-left: 30px;
      margin-top: 30px
    }

    p {
      padding-top: 25px;
      padding-right: 20px;
    }

    .circle-yellow {
      border: 2px solid var(--main-color-secundary);
      border-radius: 12em;
      width: 384px;
      height: 384px;

      .number {
        font-size: 50px;
        color: var(--main-title-color);
        font-weight: bold;
        text-align: center;
        padding-top: 137px;
      }

      .below-number {
        font-size: 23px;
        color: var(--main-title-color);
        font-weight: bold;
        text-align: center;
        padding-top: 10px;
      }

      p {
        padding: 20px 60px;
        text-align: center;
      }
    }
  }

  .affiliate-comments {
    .comment-box {
      background-color: var(--main-color-lightgrey);
      border-radius: 22px;
      padding: 35px 50px;
      height: 300px;

      .comment-user-name {
        color: #4c4da0;
        font-size: 20px;
        text-align: left;
        font-weight: bold;
      }

      .comment-user-position {
        color: var(--main-text-color);
        font-size: 13px;
        text-align: left;
        opacity: 0.4;
        font-weight: bold;
        padding-top: 5px;
      }

      p {
        padding-top: 15px;
      }

      img {
        border: 1px solid var(--main-color-primary);
        border-radius: 10px;
        aspect-ratio: 1/1;
      }

      .user-img {
        border: 1px solid var(--main-color-primary);
        border-radius: 10px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        height: 85px;
        width: 85px;
      }
    }
  }

  .affiliate-help {
    .circle-yellow-full {
      width: 85px;
      height: 85px;
      border-radius: 12em;
      background-color: var(--main-color-secundary);
    }

    .help-title {
      font-size: 21px;
      color: var(--main-title-color);
      font-weight: bold;
      text-align: center;
      padding-top: 30px;
    }

    p {
      text-align: center;
      padding-top: 8px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .affiliate {
    .h1-affiliate {
      padding-top: 0px;
    }

    .separator {
      padding-top: 80px;
    }

    .affiliate-explanation {
      .video-area {
        height: 250px;
      }

      .video-text {
        width: 100%;
        position: initial;
        margin-left: 0px;
      }
    }

    .affiliate-numbers {
      .start-now {
        margin-left: 65px;
        margin-bottom: 0px;
      }

      .circle-yellow {
        width: 325px;
        height: 325px;

        .number {
          font-size: 45px;
          padding-top: 105px;
        }

        .below-number {
          font-size: 22px;
          padding-top: 8px;
        }

        p {
          padding: 14px 60px;
        }
      }

      .numbers-title-right {
        padding-top: 40px;
        padding-left: 5px;
      }

      .numbers-title-left {
        padding-top: 20px;
        padding-right: 0px;
      }
    }

    .affiliate-comments {
      .comment-box {
        margin-bottom: 8px;
        height: auto;

        img {
          margin-left: 0px;
          margin-bottom: 12px;
        }
      }
    }

    .affiliate-benefits {
      background-color: var(--main-color-lightgrey);

      .benefit-box {
        margin-bottom: 12px;
      }
    }
  }
}
</style>
