<template>
  <main-layout>
	<!-- <pre> password {{ $v.user.password }}</pre>
	<pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container register-category company-page">
		<br>
		<div class="row">
			<div class="col-md-6">
                <div class="title-bg-grey">
                    <h3>Dados da categoria</h3>
                </div>
                <br>

                <label for="name">Título</label>
                <input id="name" type="text" v-model="$v.product_group.name.$model">
                <span v-if="$v.product_group.name.$error">Campo não pode estar vazio</span>

                <!-- <label for="segment">Subcategoria</label>
                <v-select label="name" 
                        :options="productGroupList" 
                        v-model="$v.product_group.parent.$model" 
                        :reduce="parent => parent._id" >
                </v-select> -->
			</div>
			<div class="col-md-6">
				<div class="title-bg-grey">
					<h3>Descrição</h3>
				</div>
				<br>
                <textarea v-model="$v.product_group.description.$model" placeholder="Digite a descrição da categoria" style="height: 71%;"></textarea>
			</div>

			<!-- <div class="col-md-3" v-if="!product_group.image_icon">
				<br>
				<h3>Imagem para icon</h3>
				<br>
				<div class="box-item">
					<image-upload
            v-model="product_group.image_icon"
            @uploaded="updateCategory()">
          </image-upload>
				</div>
			</div>

			<div class="col-md-3" v-else>
				<br>
				<h3>Imagem para icon</h3>
				<br>
				<div class="box-item ">
					<div class="thumb" 
						:style="{ backgroundImage: `url('${getImageThumbUrl(product_group.image_icon)}')` }" >

						<a class="close-button" @click="removePhoto(product_group.image_icon, '?type=icon')" >
							<i class="fas fa-times-circle"></i>
						</a>
					</div>
				</div>
			</div>

			<div class="col-md-3" v-if="!product_group.image_banner">
				<br>
				<h3>Imagem para banner</h3>
				<br>
				<div class="box-item">
					<image-upload
            v-model="product_group.image_banner"
            @uploaded="updateCategory()">
          </image-upload>
				</div>
			</div>

			<div class="col-md-3" v-else>
				<br>
				<h3>Imagem para banner</h3>
				<br>
				<div class="box-item ">
					<div class="thumb" 
						:style="{ backgroundImage: `url('${getImageUrl(product_group.image_banner)}')` }" >

						<a class="close-button" @click="removePhoto(product_group.image_banner, '?type=banner')" >
							<i class="fas fa-times-circle"></i>
						</a>
					</div>
				</div>
			</div> -->

			<div class="col-md-12 last" style="margin-top: 24px;">
				<!-- <button>Remover</button> //TODO -->
				<button class="remove" type="button" @click="deleteCategory()" v-if="this.$route.fullPath != '/co/catalogo/categoria/new'">Excluir</button>
				<button class=" blue " type="button" @click="updateCategory('successMessage')" v-if="this.$route.fullPath != '/co/catalogo/categoria/new'">Atualizar</button>
				<button class=" blue " type="button" @click="createCategory()" v-if="this.$route.fullPath == '/co/catalogo/categoria/new'">Criar</button>
			</div>
		</div>
        
        
        
    </div>

  </main-layout>
</template>

<script>
import companyMixin from '../../../mixins/companyMixin.js'
import MainLayout from "../../../layouts/Company.vue";
// import ImageUpload from "../../../components/ImageUpload.vue";
import { server } from "../../../js/variables.js";
import { required, email, sameAs } from 'vuelidate/lib/validators'


export default {
	mixins: [companyMixin],
  components: {
    MainLayout,
		// ImageUpload,
	// CompanyMenu,
  },
  data () {
    return {
		product_group:{
			name: '',
			description: '',
			parent: null,
			image_banner: null,
			image_icon: null,
		},
		categoryList:[],
		productGroupList:[],
		url: server.url + "/api/media/image/",
		headers: {
			Authorization: 'Bearer ' + localStorage.token
		},
		files1: [],
		files2: [],
		fileUploaded: [],
    }
  },
  validations: {
        product_group:{
			name: '',
			description: '',
			parent: '',
		},
        
    },
    mounted() {
		
		var parameters = this.$route.query;
		var id = this.$route.params.id;
		let self = this;
	},
	watch:{
		company(newValue, oldValue){
			var parameters = this.$route.query;
			var id = this.$route.params.id;
			let self = this;
			// console.log(newValue, oldValue);
			// console.log("watch company", this.company);
			if (this.$route.fullPath != "/co/catalogo/categoria/new" && self.company && self.company._id){
				this.axios.get( server.url + '/api/company/' + self.company._id + '/product-group/' + id, server.config)
				.then(function (response) {
					let responseData = response.data.data;
					self.product_group = responseData.product_group;
				})
				.catch(function (error) {
					self.output = error;
					self.showError(error);
					
				});
			}
			if(self.company && self.company._id) this.getProductGroup();
			
		}
	},
  methods: {
	createCategory(){
		let self = this;
		if (!this.$v.$invalid){

			this.axios.post(  server.url + '/api/company/' + self.company._id + '/product-group', {
				product_group: self.product_group,

			}, server.config)
			.then(function (response) {
				let responseData = response.data;
				self.$swal({
					icon: 'success',
					title: 'Sucesso',
					text: responseData.message,
					// footer: '<a href>Why do I have this issue?</a>'
					showCancelButton: false,
					confirmButtonColor: '#053360',
					confirmButtonText: 'Ok'
					}).then((result) => {
					if (result.isConfirmed) {
						window.open("./co/catalogo", "_self"); 
					}
				})
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
			});
		}else{
			this.$v.$touch();
			this.$swal({
				icon: 'error',
				title: 'Oops...',
				text: 'Verifique os campos obrigatórios',
				// footer: '<a href>Why do I have this issue?</a>'
			});
		}
	},
	updateCategory(action){
		let self = this;
		var id = this.$route.params.id;
		if (!this.$v.$invalid){
			if (!localStorage.token) {
				return
			}
			server.config.headers = {
				Authorization: 'Bearer ' + localStorage.token
			}
			this.axios.put(  server.url + '/api/company/' + self.company._id + '/product-group/' + id, {
				product_group: self.product_group,
			}, server.config)
			.then(function (response) {
				let responseData = response.data;
				// location.reload();
				// VueScrollTo.scrollTo('#user-data-form');
				if(action == 'successMessage'){
					self.$swal({
						icon: 'success',
						title: 'Sucesso',
						text: responseData.message,
						// footer: '<a href>Why do I have this issue?</a>'
						showCancelButton: false,
						confirmButtonColor: '#053360',
						confirmButtonText: 'Ok'
						}).then((result) => {
						if (result.isConfirmed) {
							location.reload();
						}
					})
				}
				
			})
			.catch(function (error) {
				self.load();
				self.output = error;
				self.showError(error);
			});
		}else{
			this.$v.$touch();
			this.$swal({
				icon: 'error',
				title: 'Oops...',
				text: 'Verifique os campos obrigatórios',
				// footer: '<a href>Why do I have this issue?</a>'
			});
		}
	},
	deleteCategory(){
		let self = this;
		var id = this.$route.params.id;

		this.$swal.fire({
		title: 'Realmente deseja excluir esta categoria?',
		text: "Esta ação é irreversível!",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#053360',
		cancelButtonColor: '#d33',
		cancelButtonText: 'Cancelar',
		confirmButtonText: 'Excluir'
		}).then((result) => {
		if (result.isConfirmed) {
			if (!localStorage.token) {
				return
			}
			server.config.headers = {
				Authorization: 'Bearer ' + localStorage.token
			}

			this.axios.delete(  server.url + '/api/company/' + self.company._id + '/product-group/' + id, server.config)
			.then(function (response) {
				let responseData = response.data;
				self.$swal({
					icon: 'success',
					title: 'Sucesso',
					text: responseData.message,
					// footer: '<a href>Why do I have this issue?</a>'
					showCancelButton: false,
					confirmButtonColor: '#053360',
					confirmButtonText: 'Ok'
					}).then((result) => {
					if (result.isConfirmed) {
						window.open("./co/catalogo", "_self"); 
					}
				})
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
			});
		}
		})
	},
    getProductGroup(){
		let self = this;
		if (!localStorage.token) {
			return
		}
		server.config.headers = {
			Authorization: 'Bearer ' + localStorage.token
		}
		this.axios.get(  server.url + '/api/company/' + self.company._id + '/product-group/', server.config)
			.then(function (response) {
				let responseData = response.data.data;
                self.productGroupList = responseData.product_group_list;
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
			});
	},
    thumbUrl(file) {
		return file.myThumbUrlProperty;
    },
	onFileChangeBanner(response) {
		// Handle files like:
		const image = response.data.image;
		// if(!this.company.image){
		// 	this.company.image = [];
		// }
		// this.company.image.push(image);
		this.product_group.image_banner = image;
		this.fileUploaded = image.url;
    },
	onFileChangeIcon(response) {
		// Handle files like:
		const image = response.data.image;
		// if(!this.company.image){
		// 	this.company.image = [];
		// }
		// this.company.image.push(image);
		this.product_group.image_icon = image;
		this.fileUploaded = image.url;
    },
	removePhoto(image) {
		var self = this;
		this.axios.delete(  server.url + '/api/media/image/' + image._id , server.config)
			.then(function (response) {
				self.$swal({
					icon: 'success',
					title: 'Imagem removida',
					// footer: '<a href>Why do I have this issue?</a>'
					showCancelButton: false,
					confirmButtonColor: '#053360',
					confirmButtonText: 'Ok'
					}).then((result) => {
					if (result.isConfirmed) {
						location.reload();
					}
				})
			})
			.catch(function (error) {
				self.output = error;
				self.showError(error);
			});
	},
	
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.register-category{

	h1{
		margin-top: 15px;
		margin-bottom: 25px;
	}

}

@media only screen and (max-width: 600px) {
	.register-category{
		padding-top: 135px;

		.box-item{
			height: auto !important;
		}
	}
}

</style>
