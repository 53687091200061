<template>
  <div>
    <!-- <Sidebar /> -->
    <Header :headerType="3"></Header>
    <!-- <company-menu/> -->
    <main class="page-with-sidebar">
      
      <menu-profile></menu-profile>
      
      <div class="refer-and-win-floating" data-toggle="modal" data-target="#referAndWinModal">
        <div class="refer-and-win-floating-left">$</div>
        <button class="refer-and-win-floating-btn">Indique e ganhe</button>
      </div>
      <slot></slot>
      
    </main>
    <Footer :footerType="3" />
    <refer-and-win-modal :modal_id="'referAndWinModal'"></refer-and-win-modal>
  </div>
</template>

<script>

import MenuProfile from '../modules/user/components/MenuProfile.vue';
import sessionMixin from '../mixins/sessionMixin.js'
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import ReferAndWinModal from "../components/ReferAndWinModal.vue";

export default {
  mixins: [sessionMixin],
  components: {
    Header,
    MenuProfile,
    // Sidebar,
    Footer,
    ReferAndWinModal,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" >
@import "../scss/reset.scss";
@import "../scss/base.scss";
@import "../scss/form.scss";
@import "../css/mobile.css";

.container-fluid-mobile {
  padding: 0px;
  /* background-color: #fff; */
}

.page-with-sidebar {
    display: grid;
    grid-template-columns:  minmax(150px, 25%) 1fr;
    margin-top: -15px;
    // height: 100vh;
}

@media only screen and (max-width: 600px) {
  main {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .page-with-sidebar {
    grid-template-rows: auto 1fr;
    grid-template-columns: none;
    // height: 100vh;
  }
}
</style>
