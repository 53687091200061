<template>
  <main-layout>
    <!-- <pre> password {{ $v.postAddress }}</pre> -->
    <!-- <pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div id="user-data-form" class="container-fluid user-data light-grey-bg">
      <!-- <br /> -->
      <div class="menu-content-box">
      <div class="row">
        <!-- <div class="col-md-12 last">
          <button
            class="blue"
            type="button"
            style="width: 150px; margin-bottom: 15px"
            @click="profileView()"
          >
            Visualizar perfil
          </button>
        </div> -->
        <div class="col-md-6 separator">
          <!-- <form> -->
          <div class="title-bg-grey">
            <h3>Dados do profissional</h3>
          </div>
          <br />

          <label for="name">Nome*</label>
          <span v-if="$v.user.name.$error">Campo não pode estar vazio</span>
          <input id="name" type="text" v-model="$v.user.name.$model" />

          <label for="lastname">Sobrenome*</label>
          <span v-if="$v.user.lastname.$error">Campo não pode estar vazio</span>
          <input id="lastname" type="text" v-model="$v.user.lastname.$model" />

          <label for="document">CPF*</label>
          <span v-if="$v.user.document.$error">Verifique o formato do CPF</span>
          <input
            id="document"
            type="text"
            v-model="$v.user.document.$model"
            v-mask="'###.###.###-##'"
            :disabled="disableCpf"
          />

          <label for="email">Email</label>
          <span v-if="$v.user.email.$error">Campo não pode estar vazio</span>
          <input
            id="email"
            type="text"
            v-model="$v.user.email.$model"
            :disabled="user.email != null && user.email != ''"
          />

          <label for="birthdate">Nascimento*</label>
          <span v-if="$v.user.birthdate.$error"
            >Campo não pode estar vazio</span
          >
          <input
            id="birthdate"
            type="text"
            placeholder="DD/MM/AAAA"
            v-model="$v.user.birthdate.$model"
            v-mask="'##/##/####'"
          />

          <label for="phone">Telefone*</label>
          <span v-if="$v.user.phone.$error">Campo não pode estar vazio</span>
          <input
            id="phone"
            type="text"
            v-model="$v.user.phone.$model"
            v-mask="'(##) #####-####'"
          />

          <!-- <div class="col-md-6"> -->
          <label for="gender">Gênero</label>
          <v-select
            label="label"
            :options="genderOptions"
            v-model="$v.user.gender.$model"
            :reduce="(gender) => gender.name"
          >
          </v-select>

          <input type="checkbox" name='type' id='accept_phone' value="true" v-model="user.accept_phone">
          <label for="accept_phone"> Quero receber mensagens por WhatsApp</label>

          <input type="checkbox" name='type' id='accept_email' value="true" v-model="user.accept_email">
          <label for="accept_email"> Quero receber emails</label>
          <!-- </div> -->

          <!-- </form> -->
        </div>
        <div class="col-md-6 separator">
          <div class="title-bg-grey">
            <h3>Endereço</h3>
          </div>
          <br />
          <!-- <input type="text" v-model="$v.teste.zipcode.$model" v-mask="'#####-###'" v-on:blur="getAddress" v-on:keyup.enter="getAddress" > -->

          <label for="zipcode">CEP*</label>
          <span v-if="$v.postAddress.zipcode.$error">Campo obrigatório</span>
          <input
            id="zipcode"
            type="text"
            placeholder="CEP*"
            v-model="$v.postAddress.zipcode.$model"
            v-on:blur="getAddress"
            v-on:keyup.enter="getAddress"
            v-on:keyup="printAddress"
            @blur="$v.postAddress.zipcode.$touch()"
            :class="{ 'fail-error': $v.postAddress.zipcode.$error }"
            v-mask="'#####-###'"
          />

          <label for="street">Endereço*</label>
          <span v-if="$v.postAddress.street.$error">Campo obrigatório</span>
          <input
            id="street"
            type="text"
            placeholder="Endereço"
            v-model="$v.postAddress.street.$model"
            @blur="$v.postAddress.street.$touch()"
            :class="{ 'fail-error': $v.postAddress.street.$error }"
          />

          <label for="number">Número*</label>
          <span v-if="$v.postAddress.number.$error">Campo obrigatório</span>
          <input
            id="number"
            type="text"
            placeholder="Número"
            v-model="$v.postAddress.number.$model"
            @blur="$v.postAddress.number.$touch()"
            :class="{ 'fail-error': $v.postAddress.number.$error }"
          />

          <label for="complement">Complemento</label>
          <span v-if="$v.postAddress.complement.$error">Campo obrigatório</span>
          <input
            id="complement"
            type="text"
            placeholder="Complemento"
            v-model="$v.postAddress.complement.$model"
            @blur="$v.postAddress.complement.$touch()"
            :class="{ 'fail-error': $v.postAddress.complement.$error }"
          />

          <label for="district">Bairro*</label>
          <span v-if="$v.postAddress.district.$error">Campo obrigatório</span>
          <input
            id="district"
            type="text"
            placeholder="Bairro"
            v-model="$v.postAddress.district.$model"
            @blur="$v.postAddress.district.$touch()"
            :class="{ 'fail-error': $v.postAddress.district.$error }"
          />

          <label for="city">Cidade*</label>
          <span v-if="$v.postAddress.city.$error">Campo obrigatório</span>
          <input
            id="city"
            type="text"
            placeholder="Cidade"
            v-model="$v.postAddress.city.$model"
            @blur="$v.postAddress.city.$touch()"
            :class="{ 'fail-error': $v.postAddress.city.$error }"
          />

          <label for="state">Estado*</label>
          <v-select
            label="sigla" 
            :options="stateList"
            v-on:input="findOpportunities('state')"
            @blur="$v.postAddress.state.$touch()"
            :reduce="state => state.sigla" 
            v-model="$v.postAddress.state.$model"
            :placeholder="'(Selecione)'"
            autocomplete="nope"
          >
          </v-select>
          <span v-if="$v.postAddress.state.$error">Campo obrigatório</span>
          <!-- <input
            id="state"
            type="text"
            placeholder="Estado"
            v-model="$v.postAddress.state.$model"
            @blur="$v.postAddress.state.$touch()"
            :class="{ 'fail-error': $v.postAddress.state.$error }"
          /> -->
        </div>

        <!-- Segmentos que tem experiencia -->
        <div class="col-md-12">
          <div class="title-bg-grey">
            <h3>Segmentos que têm experiência</h3>
          </div>
          <br />
          <div
            class="skill col-md-12"
            v-for="skill in filterBySkillType('INDUSTRY_GENERAL')"
            :key="skill._id"
          >
            <div class="row">
              <br /><br />
              <div class="col-md-6">
                <label for="segment">Segmento</label>
                <v-select
                  label="name"
                  :options="segmentList"
                  v-model="skill.skill"
                >
                </v-select>
              </div>

              <div class="col-md-6">
                <label for="segment">Nível</label>
                <v-select
                  label="label"
                  :options="proficiencyListSegment"
                  v-model="skill.proficiency"
                  :reduce="(proficiency) => proficiency.name"
                >
                </v-select>
              </div>
            </div>
          </div>
          <button
            class="float-right yellow"
            style="width: 230px"
            @click="addSkill('INDUSTRY_GENERAL')"
          >
            Adicionar segmento
          </button>
        </div>

        <!-- Perfil resumido -->
        <div class="col-md-12">
          <h1>Perfil resumido</h1>
          <div class="title-bg-grey">
            <h3>Apresentação</h3>
          </div>
          <br />
          <label for="title">Objetivo | cargo desejado</label>
          <input
            id="title"
            type="text"
            placeholder=""
            v-model="$v.user.resume.title.$model"
            @blur="$v.user.resume.title.$touch()"
            :class="{ 'fail-error': $v.user.resume.title.$error }"
          />
          <label for="position">Nível do cargo desejado</label>
          <v-select
            label="label"
            :options="positionList"
            v-model="$v.user.resume.position.$model"
            :reduce="(position) => position.value"
          >
          </v-select>

          <label for="summary"
            >Resumo de sua apresentação para as empresas - Uma apresentação de
            impacto é capaz de promover grandes conexões e engajamento entre
            pessoas, empresas e organizações. Vender seu perfil começa aqui!
            (Máximo de 700 caracteres)</label
          >
          <textarea
            v-model="$v.user.resume.summary.$model"
            placeholder="Digite sua mensagem..."
            :maxlength="700"
          ></textarea>
        </div>

        <div class="col-md-6">
          <label for="is_looking_job">Procurando emprego?</label>
          <v-select
            label="label"
            :options="yesNo"
            v-model="$v.user.is_looking_job.$model"
            :reduce="(option) => option.value"
          >
          </v-select>
        </div>

        <div class="col-md-6">
          <label for="is_looking_products"
            >Procurando produtos para vender ou representar</label
          >
          <v-select
            label="label"
            :options="yesNo"
            v-model="$v.user.is_looking_products.$model"
            :reduce="(option) => option.value"
          >
          </v-select>
        </div>

        <!-- Experiencia profissional -->
        <div class="col-md-12" id="experience-register">
          <h1>Currículo detalhado</h1>
          <div class="title-bg-grey">
            <h3>Experiência profissional</h3>
          </div>
          <br />
        </div>

        <div class="col-md-12">
          <label for="company">Empresa</label>
          <input
            id="company"
            type="text"
            placeholder=""
            v-model="$v.postExperience.company.$model"
          />
          <span v-if="$v.postExperience.company.$error"
            >Campo não pode estar vazio</span
          >
        </div>
        <div class="col-md-6">
          <label for="title">Cargo</label>
          <input
            id="title"
            type="text"
            placeholder=""
            v-model="$v.postExperience.title.$model"
          />
          <span v-if="$v.postExperience.title.$error"
            >Campo não pode estar vazio</span
          >
        </div>
        <div class="col-md-6">
          <label for="position">Nível</label>
          <!-- <input id="position" type="text" placeholder="" v-model="$v.postExperience.position.$model">
				<span v-if="$v.postExperience.position.$error">Campo não pode estar vazio</span> -->
          <v-select
            label="label"
            :options="positionList"
            v-model="$v.postExperience.position.$model"
            :reduce="(position) => position.value"
          >
          </v-select>
        </div>
        <div class="col-md-6">
          <label for="start_date">De</label>
          <input
            id="start_date"
            type="text"
            placeholder="DD/MM/AAAA"
            v-model="$v.postExperience.start_date.$model"
            v-mask="'##/##/####'"
          />
          <span v-if="$v.postExperience.start_date.$error"
            >Campo não pode estar vazio</span
          >
        </div>

        <div class="col-md-6">
          <label for="end_date">Até (não preencher se trabalha até hoje)</label>
          <input
            id="end_date"
            type="text"
            placeholder="DD/MM/AAAA"
            v-model="$v.postExperience.end_date.$model"
            v-mask="'##/##/####'"
          />
          <span v-if="$v.postExperience.end_date.$error"
            >Campo não pode estar vazio</span
          >
        </div>

        <div class="col-md-12">
          <label for="description">Descrição</label>
          <!-- <textarea
            v-model="postExperience.description"
            placeholder="Digite sua mensagem..."
          ></textarea> -->
          <vue-editor id="description" type="text" placeholder="Descrição" :editorToolbar="customToolbar" v-model="$v.postExperience.description.$model" @blur="$v.postExperience.description.$touch()" :class="{ 'fail-error' : $v.postExperience.description.$error }"></vue-editor>
          <button
            class="float-right yellow"
            @click="addExperience(postExperience)"
            v-if="selectedExperience._id == undefined"
          >
            Adicionar
          </button>
          <button
            class="float-right yellow"
            @click="addExperience(postExperience)"
            v-else
          >
            Salvar
          </button>
        </div>

        <div
          class="col-md-12"
          v-for="experience in user.resume.experience"
          :key="experience._id"
          id="experience-list"
        >
          <div class="user-grey-box" @click="selectExperience(experience)" v-bind:class="{ 'user-grey-box-selected': selectedExperience._id == experience._id }">
            <label
              ><b>{{ experience.company }}</b> {{ experience.title }} -
              {{positionType[experience.position]}}</label
            >
            <p>{{ experience.start_date }} até {{ experience.end_date }}</p>
            <p v-html="experience.description"></p>
            <button class="btn-x" @click="deleteExperience(experience)">
              X
            </button>
            <i class="fas fa-pen edit-btn"></i>
          </div>
        </div>

        <!-- Formação academica -->
        <div class="col-md-12 separator" id="education-register">
          <div class="title-bg-grey">
            <h3>Formação acadêmica</h3>
          </div>
          <br />
          <label for="scholl">Escola</label>
          <input
            id="scholl"
            type="text"
            v-model="$v.postEducation.scholl.$model"
          />
          <span v-if="$v.postEducation.scholl.$error"
            >Campo não pode estar vazio</span
          >
        </div>
        <div class="col-md-6">
          <label for="field_of_study">Curso</label>
          <input
            id="field_of_study"
            type="text"
            placeholder=""
            v-model="$v.postEducation.field_of_study.$model"
          />
          <span v-if="$v.postEducation.field_of_study.$error"
            >Campo não pode estar vazio</span
          >
        </div>
        <div class="col-md-6">
          <label for="degree">Tipo</label>
          <v-select
            label="label"
            :options="degreeList"
            v-model="$v.postEducation.degree.$model"
            :reduce="(degree) => degree.value"
          >
          </v-select>
          <span v-if="$v.postEducation.degree.$error"
            >Campo não pode estar vazio</span
          >
        </div>
        <div class="col-md-6">
          <label for="start_date">De</label>
          <input
            id="start_date"
            type="text"
            placeholder="DD/MM/AAAA"
            v-model="$v.postEducation.start_date.$model"
            v-mask="'##/##/####'"
          />
          <span v-if="$v.postEducation.start_date.$error"
            >Campo não pode estar vazio</span
          >
        </div>
        <div class="col-md-6">
          <label for="end_date">Até (não preencher se ainda está cursando)</label>
          <input
            id="end_date"
            type="text"
            placeholder="DD/MM/AAAA"
            v-model="$v.postEducation.end_date.$model"
            v-mask="'##/##/####'"
          />
          <span v-if="$v.postEducation.end_date.$error"
            >Campo não pode estar vazio</span
          >
        </div>

        <div class="col-md-12">
          <label for="description">Descrição</label>
          <!-- <textarea
            v-model="postEducation.description"
            placeholder="Digite sua mensagem..."
          ></textarea> -->
          <vue-editor id="description" type="text" placeholder="Descrição" :editorToolbar="customToolbar" v-model="postEducation.description"></vue-editor>
          <button
            class="float-right yellow"
            @click="addEducation(postEducation)"
            v-if="selectedEducation._id == undefined"
          >
            Adicionar
          </button>
          <button
            class="float-right yellow"
            @click="addEducation(postEducation)"
            v-else
          >
            Salvar
          </button>
        </div>

        <div
          class="col-md-12"
          v-for="education in user.resume.education"
          :key="education._id"
          id="education-list"
        >
          <div class="user-grey-box" @click="selectEducation(education)" v-bind:class="{ 'user-grey-box-selected': selectedEducation._id == education._id }">
            <label
              ><b>{{ education.scholl }}</b> {{ education.field_of_study }} -
              {{degreeType[education.degree]}}
            </label>
            <p>{{ education.start_date }} até {{ education.end_date }}</p>
            <p v-html="education.description"></p>
            <!-- <button class="btn-x" data-toggle="modal" data-target="#confirmModal">X</button> -->
            <button class="btn-x" @click="deleteEducation(education)">X</button>
            <i class="fas fa-pen edit-btn"></i>
          </div>
        </div>

        <!-- Idiomas -->
        <div class="col-md-12 separator">
          <div class="title-bg-grey">
            <h3>Idiomas</h3>
          </div>
          <br />
          <div
            class="skill col-md-12"
            v-for="skill in filterBySkillType('LANGUAGE')"
            :key="skill._id"
          >
            <div v-if="skill.skill.type == 'LANGUAGE'" class="row">
              <br /><br />
              <div class="col-md-6">
                <label for="segment">Idioma</label>
                <v-select
                  label="name"
                  :options="languageList"
                  v-model="skill.skill"
                >
                </v-select>
              </div>

              <div class="col-md-6">
                <label for="segment">Nível</label>
                <v-select
                  label="label"
                  :options="proficiencyListLanguage"
                  v-model="skill.proficiency"
                  :reduce="(proficiency) => proficiency.name"
                >
                </v-select>
              </div>
            </div>
          </div>
          <button
            class="float-right yellow"
            @click="addSkill('LANGUAGE')"
            style="width: 230px"
          >
            Adicionar idioma
          </button>
        </div>
      </div>

      <hr />
      <div class="col-md-12 last btn-full-m" style="padding-top: 24px">
        <button class="remove" type="button" @click="deleteUser()">
          Excluir
        </button>
        <button class="remove" type="button" @click="logout()">
          Desconectar
        </button>
        <button
          class="blue"
          type="button"
          @click="updateUser()"
          :disabled="isSending"
        >
          Salvar
        </button>
      </div>
      </div>
      <!-- </div>
		</div>
		</div> -->
    </div>

    <confirm-delete-modal :modal_id="'confirmModal'"></confirm-delete-modal>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/SalesmanProfile.vue";
import userMixin from "../../../mixins/userMixin.js";
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal.vue";
import moment from "moment";
import VueScrollTo from "vue-scrollto";
import { server } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    ConfirmDeleteModal,
  },
  data() {
    return {
      postAddress: {
        zipcode: "",
        street: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",
        // _id: '',
      },
      teste: {
        zipcode: "",
        street: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",
      },
      postExperience: {
        company: "",
        title: "",
        position: "",
        start_date: "",
        end_date: "",
        description: "",
      },
      postEducation: {
        scholl: "",
        field_of_study: "",
        degree: "",
        start_date: "",
        end_date: "",
        description: "",
        completed: true,
      },
      postSkills: [],
      proficiencyListLanguage: [
        { name: null, label: "(Sem conhecimento)" },
        { name: 1, label: "Básico" },
        // { name: 2, label: "Básico/ intermediário" },
        { name: 2, label: "Intermediário" },
        { name: 3, label: "Avançado" },
        { name: 4, label: "Fluente" },
      ],
      proficiencyListSegment: [
        { name: null, label: "(Sem conhecimento)" },
        { name: 1, label: "Básico" },
        // { name: 2, label: "Básico/ intermediário" },
        { name: 2, label: "Intermediário" },
        { name: 3, label: "Avançado" },
        // {name: 5, label:'Experiente'},
      ],
      segmentList: [],
      languageList: [],
      userLanguageList: {},
      selectedExperience: {},
      selectedEducation: {},
      // positionList: [
      //   { label: "Estagiário", value: "INTERN" },
      //   { label: "Júnior", value: "JUNIOR" },
      //   { label: "Pleno", value: "FULL" },
      //   { label: "Sênior", value: "SENIOR" },
      //   { label: "Coordenador", value: "COORDINATOR" },
      //   { label: "Gerente", value: "MANAGER" },
      //   { label: "Diretor", value: "DIRECTOR" },
      // ],
      url: server.url + "/api/media/image/",
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
      files: [],
      fileUploaded: [],
      isSending: false,
      newSegment: false,
      newLanguage: false,
      yesNo: [
        { _id: 0, label: "Não", value: false },
        { _id: 1, label: "Sim", value: true },
      ],
      disableCpf: false,
    };
  },
  validations: {
    user: {
      name: { required },
      lastname: { required },
      document: { required },
      birthdate: { required },
      gender: "",
      phone: { required },
      email: { required },
      is_looking_job: "",
      is_looking_products: "",
      resume: {
        title: "",
        position: "",
        summary: "",
      },
      // password: '',
    },
    teste: {
      zipcode: "",
      street: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      state: "",
    },
    postExperience: {
      company: "",
      title: "",
      position: "",
      start_date: "",
      end_date: "",
      description: "",
    },
    postEducation: {
      scholl: "",
      field_of_study: "",
      degree: "",
      start_date: "",
      end_date: "",
      description: "",
    },
    postAddress: {
      zipcode: { required },
      street: { required },
      number: { required },
      complement: '',
      district: { required },
      city: { required },
      state: { required },
      // _id: '',
    },
  },
  mounted() {
    var parameters = this.$route.query;
    let self = this;
    this.findLanguages();
    this.findSegments();
    this.findPlaces();
  },
  watch: {
    user(newValue, oldValue) {
      if (!this.user.resume) {
        this.user.resume = {
          title: "",
          summary: "",
          experience: [],
          education: [],
          skills: [],
        };
      }

      if (this.user.address && this.user.address.length > 0) {
        this.postAddress = this.user.address[0];
      } else {
        // console.log("Nao carregado");
        this.postAddress = {
          zipcode: "",
          street: "",
          number: "",
          complement: "",
          district: "",
          city: "",
          state: "",
        };
      }

      if (!this.user.resume.skills) {
        this.postSkills = [];
      } else {
        this.postSkills = this.user.resume.skills;
      }

      if(this.user.document != null && !this.user.document == '') this.disableCpf = true;

      //this.userLanguages();
    },
  },

  methods: {
    userLanguages() {
      let found = false;
      if (!this.userLanguageList) {
        this.userLanguageList = {};
      }
      for (let skill of this.languageList) {
        // console.log('AQUI', skill);
        const index = this.findSkillById(skill._id);
        if (index) {
          // console.log(index);
        } else {
          // this.addSkill("LANGUAGE", skill);
        }
      }
    },
    getLanguageModel(id) {
      return this.userLanguageList[id];
    },
    changeValue(event, id) {
      // console.log(event.target.value, id);
      // this.userLanguageList[id] = event.target.value;
    },
    profileView() {
      let self = this;
      // window.open("./vc/perfil/visualizacao/" + self.user._id, "_self");
      const url = "/vc/perfil/visualizacao";
      this.$router.push(url);
    },
    addSkill(type) {
      this.postSkills.push({
        _id: null,
        skill: {
          _id: null,
          name: "",
          type: type,
        },
        proficiency: null,
        new: true,
      });
      // this.$forceUpdate();
    },
    filterBySkillType(type) {
      var list = [];
      for (let skill of this.postSkills) {
        if (skill.skill.type == type) {
          list.push(skill);
        }
      }
      return list;
    },
    findSkillById(id) {
      for (let skill of this.postSkills) {
        if (!skill.skill) break;
        if (!skill.skill._id) break;

        if (skill.skill._id === id) {
          return this.postSkills.indexOf(skill);
        }
      }

      return;
    },
    removeSkill(skill) {
      let index = this.postSkills.indexOf(skill);
      if (index > -1) {
        this.postSkills.splice(index, 1);
      }
    },
    updateUser() {
      let self = this;

      if (!this.$v.$invalid) {
        if(this.user.document && this.user.document.length != 14){
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Verifique o formato do CPF",
            // footer: '<a href>Why do I have this issue?</a>'
          });
          return
        }
        
        if (!localStorage.token) {
          return;
        }
        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };
        this.user.address = [];
        this.user.address.push(self.postAddress);
        
        let itensToRemove = [];
        for (let skill of this.postSkills) {
          if (!skill.skill._id || skill.skill._id == '') {
            itensToRemove.push(skill);
          }
          else if (!skill.proficiency) {
            itensToRemove.push(skill);
          }

          else if (skill.proficiency == null) {
            itensToRemove.push(skill);
          }
        }
        
        for(let item of itensToRemove){
          this.removeSkill(item);
        }

        // for (let skill of this.postSkills) {
        //   if (!skill.skill._id) {
        //     this.removeSkill(skill);
        //   }
        //   if (!skill.proficiency) {
        //     this.removeSkill(skill);
        //   }

        //   if (skill.proficiency == null) {
        //     this.removeSkill(skill);
        //   }
        // }
        this.user.resume.skills = this.postSkills;

        console.log(this.user.resume.skills);
        
        let userCopy = JSON.parse(JSON.stringify(this.user));
        this.prepareUserToSend(userCopy);

        self.isSending = true;
        this.axios
          .put(
            server.url + "/api/user/" + self.user._id,
            {
              user: userCopy,
            },
            server.config
          )
          .then(function (response) {
            self.isSending = false;
            let responseData = response.data;

            self
              .$swal({
                icon: "success",
                title: "Dados atualizados",
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: "#053360",
                confirmButtonText: "Ok",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  location.reload();
                  // const url = "/vc/perfil";
                  // this.$router.push(url);
                  // window.open("./vc/perfil", "_self");
                }
              });
          })
          .catch(function (error) {
            self.isSending = false;
            self.output = error;
            // self.insertMasks(true);
            self.showError(error);
            console.log(error);
          });
      } else {
        this.$v.$touch();
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Verifique os campos obrigatórios",
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    selectExperience(item) {
      this.selectedExperience = item;
      this.postExperience = item;
      VueScrollTo.scrollTo('#experience-register');
    },
    addExperience(item) {
      const index = this.user.resume.experience.indexOf(item);

      if (item.company != "" && item.company != null) {
        if (index == -1) {
          let data = JSON.parse(JSON.stringify(item));
          if(data.end_date == ""){
            data.end_date = 'hoje';
          }
          this.user.resume.experience.push(data);
        }
      }
      this.selectedExperience = {};
      this.postExperience = {
        company: "",
        title: "",
        position: "",
        start_date: "",
        end_date: "",
        description: "",
      };
      VueScrollTo.scrollTo('#experience-list');
    },
    deleteExperience(item) {
      this.$swal
        .fire({
          title: "Realmente deseja excluir?",
          text: "Esta ação é irreversível!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#053360",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Excluir",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let index = this.user.resume.experience.indexOf(item);
            if (index == -1) {
              // console.log("Erro, nao encontrado");
              return;
            }
            this.user.resume.experience.splice(index, 1);
            // this.updateUser();
            // this.$swal.fire(
            // 'Deleted!',
            // 'Your file has been deleted.',
            // 'success'
            // )
          }
        });
    },
    addEducation(item) {
      const index = this.user.resume.education.indexOf(item);

      if (item.scholl != "" && item.scholl != null) {
        if (index == -1) {
          let data = JSON.parse(JSON.stringify(item));
          if(data.end_date == ""){
            data.end_date = 'hoje';
          }
          this.user.resume.education.push(data);
        }
      }

      this.selectedEducation = {};
      this.postEducation = {
        scholl: "",
        start_date: "",
        end_date: "",
        description: "",
      };
      VueScrollTo.scrollTo('#education-list');
    },
    selectEducation(item) {
      this.selectedEducation = item;
      this.postEducation = item;
      VueScrollTo.scrollTo('#education-register');
    },
    deleteEducation(item) {
      this.$swal
        .fire({
          title: "Realmente deseja excluir?",
          text: "Esta ação é irreversível!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#053360",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Excluir",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let index = this.user.resume.education.indexOf(item);
            if (index == -1) {
              // console.log("Erro, nao encontrado");
              return;
            }
            this.user.resume.education.splice(index, 1);
            // this.updateUser();
            // this.$swal.fire(
            // 'Deleted!',
            // 'Your file has been deleted.',
            // 'success'
            // )
          }
        });
    },
    deleteUser() {
      let self = this;
      var id = this.$route.params.id;

      this.$swal
        .fire({
          title: "Realmente deseja excluir sua conta?",
          text: "Esta ação é irreversível!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#053360",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Excluir",
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (!localStorage.token) {
              return;
            }
            server.config.headers = {
              Authorization: "Bearer " + localStorage.token,
            };

            this.axios
              .delete(server.url + "/api/user/" + self.user._id, server.config)
              .then(function (response) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('company');
                window.open("/", "_self");
                // self.$swal({
                // 	icon: 'success',
                // 	title: 'Dados atualizados',
                // 	text: responseData.message,
                // 	// footer: '<a href>Why do I have this issue?</a>'
                // });
              })
              .catch(function (error) {
                self.output = error;
                self.showError(error);
                console.log(error);
              });
          }
        });
    },
    logout() {
      let self = this;
      var id = this.$route.params.id;
      if (!localStorage.token) {
        return;
      }
      server.config.headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      this.axios
        .post(server.url + "/api/auth/logout", server.config)
        .then(function (response) {
          localStorage.removeItem('token');
          localStorage.removeItem('slug');
          localStorage.removeItem('user');
          localStorage.removeItem('company');
          window.open("/login", "_self");
          // self.$swal({
          // 	icon: 'success',
          // 	title: 'Dados atualizados',
          // 	text: responseData.message,
          // 	// footer: '<a href>Why do I have this issue?</a>'
          // });
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    getAddress() {
      let self = this;
      this.axios
        .get(
          server.url +
            "/webservices/zipcode?zipcode=" +
            this.postAddress.zipcode
        )
        .then(function (response) {
          let responseData = response.data.data;
          self.postAddress.city = responseData.localidade;
          self.postAddress.street = responseData.logradouro;
          self.postAddress.state = responseData.uf;
          self.postAddress.district = responseData.bairro;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    printAddress() {
      console.log(this.postAddress);
    },
    findSegments() {
      let self = this;
      this.axios
        .get(server.url + "/api/skill?filter=INDUSTRY_GENERAL", server.config)
        .then(function (response) {
          self.segmentList = [];
          let responseData = response.data.data.skill_list;
          self.segmentList = responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    findLanguages() {
      let self = this;
      this.axios
        .get(server.url + "/api/skill?filter=LANGUAGES", server.config)
        .then(function (response) {
          self.languageList = [];
          let responseData = response.data.data.skill_list;
          self.languageList = responseData;
          // self.userLanguages();
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    thumbUrl(file) {
      return file.myThumbUrlProperty;
    },
    onFileChange(response) {
      // Handle files like:
      const image = response.data.image;
      this.user.image = image;
      this.fileUploaded = image.url;
    },
    removePhoto(image) {
      var self = this;
      this.axios
        .delete(server.url + "/api/media/image/" + image._id, server.config)
        .then(function (response) {
          this.user.image = null;
          self.$swal({
            icon: "success",
            title: "Imagem removida",
            // text: answer.message,
            // footer: '<a href>Why do I have this issue?</a>'
          });
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.user-data {
  h1 {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  button.yellow {
    margin-top: 8px;
    margin-bottom: 26px;
  }

  span{
    margin-left: 8px;
  }

  .user-grey-box, .user-grey-box-selected {
    border-radius: 10px;
    background-color: var(--main-color-lightgrey);
    padding: 20px 40px;
    margin-bottom: 15px;
    cursor: pointer;

    .edit-btn{
      position: absolute;
      right: 27px;
      top: 39px;
    }
  }

  .user-grey-box-selected{
    border: 2px solid var(--main-color-primary);
  }

  .separator {
    padding-top: 30px;
    padding-bottom: 10px;
  }
}
</style>
