<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1 >{{course.name}}</h1>
          </div>
          <div class="col-md-3 ">
            <button @click="backToTrainings()">Voltar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="container top-space lesson-watch">
      <div class="row">
        <div class="col-md-9">
          <div v-if="lesson.video">
            <video-player-item :src = getVideoUrl(lesson.video) :start="savedVideoTime" @player="updatePlayer"></video-player-item>
            <div class="rating" v-if="showRating">
              <div class="rating-title">
                <h2>Feedback</h2>
              </div>

              <div class="rating-item" style="height: 25px;" v-for="rating in ratingList" :key="rating._id">
                <div class="rating-name">
                  <p>{{rating.name}}</p>
                </div>
                <div class="rating-stars" style="height: 25px; text-align: right;">
                  <i v-bind:class="{ 'fas fa-star': rating.value > 0, 'far fa-star': rating.value < 1 }" @click="changeValue(rating, 1)"></i>
                  <i v-bind:class="{ 'fas fa-star': rating.value > 1, 'far fa-star': rating.value < 2 }" @click="changeValue(rating, 2)"></i>
                  <i v-bind:class="{ 'fas fa-star': rating.value > 2, 'far fa-star': rating.value < 3 }" @click="changeValue(rating, 3)"></i>
                  <i v-bind:class="{ 'fas fa-star': rating.value > 3, 'far fa-star': rating.value < 4 }" @click="changeValue(rating, 4)"></i>
                  <i v-bind:class="{ 'fas fa-star': rating.value > 4, 'far fa-star': rating.value < 5 }" @click="changeValue(rating, 5)"></i>
                </div>
              </div>

              <div style="text-align: center;">
                <button class="blue" @click="sendFeedback()">Enviar feedback</button>
              </div>
            </div>
          </div>
          <div style="background-color: #e6dcdc; height: 380px; width: 100%" v-else></div>
          
          <div class="lesson-watch-infos" v-if="lesson._id">
            <h2>{{lesson.name}}</h2>
            <br>
            <label for="description" style="padding-top: 25px; padding-bottom: 5px">Descrição</label>
            <p v-html="lesson.description"></p>
          </div>
        </div>

        <div class="col-md-3 lesson-watch-sections" style="padding-right: 0px">  
          <div v-for="section in course.sections" :key="section._id">
            <ul class="section-lesson-buttons">
              <li class="light-grey-bg" style="pointer-events: none;" >
                <a style="font-weight: bold; color: var(--main-title-color);">{{section.title}}</a>
              </li>
              <li v-for="item in section.chapters" :key="item._id" @click="goToLesson(item)" v-bind:class="{ 'active ': item.lesson._id == lesson._id }"> 
                <!-- <a v-bind:style=" item.lesson._id == this.$route.params.idLesson? 'padding-top: 11px;' : 'padding-top: 17px;' ">{{item.lesson.name}}</a> -->
                <a>{{item.lesson.name}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import { server } from "../../../js/variables.js";
import userMixin from "../../../mixins/userMixin.js";
import VideoPlayerItem from "../../../components/VideoPlayerItem.vue";
import moment from 'moment';
export default {
  mixins: [userMixin],
  components: {
    MainLayout,
    VideoPlayerItem,
  },
  data() {
    return {
      course: {
        name: '',
        description: '',
        objectives: '',
        segment: {},
        target: '',
        prerequisites: '',
        price: '',
        status: '',
        sections: [],
        video: [],
        image: {},
      },
      lesson: {},
      lessonBefore: {},
      student: {},
      player: {},
      savedVideoTime: 0,
      interval: '',
      ratingList: [
        {name: 'Geral', value: 0},
        {name: 'Clareza', value: 0},
        {name: 'Ritmo', value: 0},
        {name: 'Relevância', value: 0},
        {name: 'Conteúdo', value: 0},
        {name: 'Diversão', value: 0},
      ],
      assessment: {
        feedback_general: 0,
        feedback_clarity: 0,
        feedback_rhythm: 0,
        feedback_relevance: 0,
        feedback_content: 0,
        feedback_fun: 0,
      },
      showRating: false,
      sentRating: false,
    };
  },
  validations: {

  },
  mounted() {
    let self = this;
    var idTraining = this.$route.params.idTraining;
    let lessonId = this.$route.params.idLesson;
    this.getLesson();

    this.axios.get( server.url + '/api/course/' + idTraining, server.config)
    .then(function (response) {
      let responseData = response.data.data;
      self.course = responseData.course;
      
      self.course.created_at = moment(self.course.created_at).add(4, 'hours').format('DD/MM/YYYY');

    })
    .catch(function (error) {
      self.output = error;
      self.showError(error);
    });

    
    
  },
	watch:{
		user(newValue, oldValue){
      // console.log('watch')
      if(this.user._id){
        this.getStudents();
      }
		}
	},
  beforeDestroy () {
    clearInterval(this.interval);
  },
  methods: {
    backToTrainings(){
      let self = this;
      var id = this.$route.params.id;
      const url = "/vc/treinamentos-inscritos/";
      self.$router.push(url);
    },
    goToLesson(item){
      let self = this;
      var idTraining = this.$route.params.idTraining;
      // const url = "/vc/treinamentos-inscritos/" + idTraining + '/aula/' + item.lesson._id ;
      // self.$router.push(url);
      clearInterval(this.interval);
      // this.getLesson();
      window.open ('/vc/treinamentos-inscritos/' + idTraining + '/aula/' + item.lesson._id, '_self');
      // location.reload();
    },
    getLesson(){
      let self = this;
      var idTraining = this.$route.params.idTraining;
      let lessonId = this.$route.params.idLesson;
      this.axios.get( server.url + '/api/course/' + idTraining + '/lesson/' + lessonId, server.config)
      .then(function (response) {
        let responseData = response.data.data;
        self.lessonBefore = responseData.lesson;
        if(self.user._id){
          self.getStudents();
        }
        
        // self.course.created_at = moment(self.course.created_at).add(4, 'hours').format('DD/MM/YYYY');
      })
      .catch(function (error) {
        self.output = error;
        self.showError(error);
      });
    },
    getStudents() {
      let self = this;
      var idTraining = this.$route.params.idTraining;
      var idLesson = this.$route.params.idLesson;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      if(self.user._id){
        let header = JSON.parse(JSON.stringify(server.config));
        header["params"] = {
          active: 1,
          course: idTraining,
          user: self.user._id
        };


        this.axios
        .get(server.url + "/api/student/" , header)
          .then(function (response) {
            let responseData = response.data.data;
            self.student = responseData.student_list[0];
            for(let lesson of self.student.lessons){
              if(lesson.lesson_id == idLesson){
                self.savedVideoTime = lesson.video_timer;
              }
            }
            self.lesson = self.lessonBefore;
            self.timer();

          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
            console.log(error);
          });
      }
    },
    timer(){
      let self = this;
      self.interval = setInterval(function () {
        self.updateWatchTime();
      }, 15000);
      // setInterval(this.updateWatchTime(), 1000)
    },
    updateWatchTime(){
      let self = this;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      this.axios.put(  server.url + '/api/student/' + self.student._id + '/watch',{
        lesson: self.lesson._id,
        timer: self.player.cache_.currentTime,
      }, server.config)
      .then(function (response) {
        let responseData = response.data;
        
      })
      .catch(function (error) {
        self.output = error;
        self.showError(error);
      });
    },
    changeValue(rating, value){
      if(rating.value == value){
        rating.value = value - 1;
      }else{
        rating.value = value;
      }
    },
    sendFeedback(){
      let self = this;
      var idLesson = this.$route.params.idLesson;

      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      if(idLesson != undefined){
        self.assessment.feedback_general = this.ratingList[0].value;
        self.assessment.feedback_clarity = this.ratingList[1].value;
        self.assessment.feedback_rhythm = this.ratingList[2].value;
        self.assessment.feedback_relevance = this.ratingList[3].value;
        self.assessment.feedback_content = this.ratingList[4].value;
        self.assessment.feedback_fun = this.ratingList[5].value;

        
        // console.log('avaliação', this.assessment);
        // console.log('parametro', idLesson);

        this.axios.put(  server.url + '/api/student/' + self.student._id + '/evaluate',{
          lesson: self.lesson._id,
          assessment: self.assessment,
        }, server.config)
        .then(function (response) {
          let responseData = response.data;
          self.showRating = false;
          self.sentRating = true;
          
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
      }
    },
    updatePlayer(player){
      this.player = player;
      if(this.player.cache_.duration - this.player.cache_.currentTime < 5 && !this.showRating){
        this.showRating = true;
      }
    }
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.lesson-watch{
  .training-image{
    width: 100%;
    max-height: 100%;
  }

  .rating{
    position: absolute;
    background-color: #ffffffee;;
    border-radius: 10px;
    padding: 20px 55px;
    top: 175px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 350px;

    h2{
      text-align: center;
      margin-bottom: 20px;
    }
    
    button{
      width: 160px;
      margin-top: 20px;
    }

    i{
      cursor: pointer;
    }
    
    .rating-item{
      display: flex;
      // flex-flow: row wrap;

      .rating-name{
        width: 50%;
      }
      
      .rating-stars{
        width: 50%;
        text-align: right;
      }
    }

  }

  .lesson-watch-infos{
    background-color: var(--main-color-lightgrey);
    padding: 50px 45px;
    // margin-top: 12px;
    // border-radius: 15px;

    h2{
      padding-bottom: 8px;
    }

    h3{
      display: inline-block;
      position: absolute;
      padding-top: 16px;
    }

    .teacher-photo{
      height: 40px;
      width: 40px;
      margin-top: 5px;
      border: 2px solid var(--main-color-mediumgrey);
      background-position: center center !important;
      background-size: cover !important;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    i.fas {
      color: var(--main-title-color);
      margin-top: 7px;
      font-size: 22pt;
      margin-right: 12px;
    }

    .col-md-5{
      text-align: right;
    }
    
  }

  .lesson-watch-sections{
    ul.section-lesson-buttons {
      list-style: none;
      width: 100%;
      height: 100%;
      min-height: 74px;
      // background-color: var(--main-color-primary);
      display: table;

      li {
        display: block;
        //   vertical-align: middle;
        // padding: 18px;
        // text-align: center;
        // pointer-events: none;
        cursor: pointer;

        &:hover {
          background-color: var(--main-color-primary);
          cursor: pointer;
          // border-bottom: 2px solid var(--main-color-secundary);
        }

        a {
          // max-width: 110px;
          color: var(--main-text-color);
          width: 100%;
          display: inline-block;
          padding: 20px 15px;
          font-size: 10pt;

          &:hover {
            text-decoration: none;
            color: white;
          }
        }
      }
      .active {
        background-color: var(--main-color-primary);
        color: white;
        pointer-events: none;

        a {
          color: white;
        }
      }
    }
  }
}
  



@media only screen and (max-width: 600px) {
  .lesson-watch-infos{
    background-color: transparent;
    padding: 40px 15px;

    .col-md-5{
      text-align: left;
    }

    .col-md-7{
      padding-bottom: 12px;
    }
  }
}
</style>
