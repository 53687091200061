<template>
  <main-layout>
    <div class="sub-menu container-fluid  grey-title-div">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-9 ">
            <h1 >Anotações</h1>
          </div>
          <div class="col-md-3 ">
            <button @click="goToSubscribedTrainings()">Treinamentos inscritos</button>
          </div>
        </div>
      </div>
    </div>
    <div class="annotations container-fluid light-grey-bg">
      <div class="container">
        <h2 class="more-padding" v-if="annotationList.length == 0 || annotationList == null">Você ainda não possui nenhuma anotação</h2>
        <div class="row annotation-item" v-for="annotation in annotationList" :key="annotation._id">
          <div class="col-md-4">
            <div class="" style="height: 185px; background-color: white">
              <img :src="getImageThumbUrl(annotation.course.image)" v-if="annotation.course.image" class="training-image" alt="">
            </div>
            <h2 >{{annotation.course.name}}</h2>
            <div class="hr-yellow-light hr-25"></div>
            <div class="teacher">{{annotation.course.user.name}} {{annotation.course.user.lastname}}</div>
          </div>
          <div class="col-md-8">
            <!-- <div class="annotation-right row">
              <div class="col-md-3 no-padding">
              <p class="time">1:00:13</p>
              </div>
              <div class="col-md-9 no-padding">
              <p>At auctor urna nunc id cursus metus aliquam eleifend mi. Pellentesque massa placerat duis ultricies lacus sed turpis tincidunt id. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing. Aliquam id diam maecenas ultricies mi eget mauris. Ultrices eros in cursus turpis</p>
              </div>          
            </div> -->
            <div class="annotation-right">
              <p class="time">1:00:13</p>
              <p>At auctor urna nunc id cursus metus aliquam eleifend mi. Pellentesque massa placerat duis ultricies lacus sed turpis tincidunt id. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing. Aliquam id diam maecenas ultricies mi eget mauris. Ultrices eros in cursus turpis</p>
              <hr>
              <p class="time">1:00:13</p>
              <p>At auctor urna nunc id cursus metus aliquam eleifend mi. Pellentesque massa placerat duis ultricies lacus sed turpis tincidunt id. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing. Aliquam id diam maecenas ultricies mi eget mauris. Ultrices eros in cursus turpis</p>
              <hr>
              <p class="time">1:00:13</p>
              <p>At auctor urna nunc id cursus metus aliquam eleifend mi. Pellentesque massa placerat duis ultricies lacus sed turpis tincidunt id. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing. Aliquam id diam maecenas ultricies mi eget mauris. Ultrices eros in cursus turpis</p>
              <hr>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Salesman.vue";
import { server } from "../../../js/variables.js";
import moment from 'moment';

export default {
  components: {
    MainLayout,
  },
  data() {
    return {
      annotationList: [],
    };
  },
  mounted() {
    var parameters = this.$route.query;
    this.getAnnotations();
  },
  methods: {
    getAnnotations() {
      let self = this;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      this.axios
      .get(server.url + "/api/course/licenses", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          console.log('aqui', responseData.license_list)
          self.annotationList = responseData.license_list;
          console.log('aqui2', self.annotationList)
          
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    goToSubscribedTrainings(){
      let self = this;
      var id = this.$route.params.id;
      const url = "/vc/treinamentos-inscritos/" ;
      self.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";



.annotations {
  padding-top: 70px;
  
  .annotation-item{
    h2{
      padding-top: 25px;
    }

    hr{
      margin: 65px 0px;
    }

    .annotation-right{
      padding: 30px 45px;
      border-radius: 11px;
      background-color: white;

      .time{
        border: 0.5px solid var(--main-color-primary);
        color: var(--main-title-color);
        width: 100px;
        height: 27px;
        text-align: center;
        float: left;
        margin-top: 10px;
        margin-right: 12px;
      }

      .hr-white{
        background-color: white;
        height: 1px;
        width: 100%;
      }

      hr{
        margin: 17px 0px;
      }
    }
  }

  
  

}

@media only screen and (max-width: 600px) {
  .annotations {

  }
    
}
</style>
