<template>
  <div class="container company-list-wrapper">
    <div style="position: relative;" v-if="companyList != null && companyList.length > 3">
      <div class="title-wrapper">
        <div>
          <h2 class="h2-bigger" style="margin-bottom: 15px;">Empresas em destaque</h2>
          <div class="hr-yellow hr-45"></div>
        </div>
        <!-- <button class="border">Cadastre sua vaga</button> -->
      </div>
      <br><br><br>
      <div class="row company-list">
        <div class="col-md-2 company-list-item" v-for="company in companyList" :key="company._id">
          <div style="height: 155px; width: 155px;" class="box-item">
            <img :src="getImageThumbUrl(company.image)" v-if="company.image" class="" alt="">
          </div>
          <h2 style="font-size: 17px;">{{ company.name_fancy }}</h2>
        </div>
        <div>

        </div>
      </div>
      <button id="slideLeft" class="clean left-arrow-btn" @click="scrollLeft()">
        <i class="fas fa-chevron-left d-none d-md-block d-lg-block"></i>
      </button>
      <button id="slideRight" class="clean right-arrow-btn" @click="scrollRight()">
        <i class="fas fa-chevron-right d-none d-md-block d-lg-block"></i>
      </button>
    </div>
    <!-- <div class="row company-list" v-if="companyList != null" >
            <div class="col-md-2 company-list-item" v-for="company in companyList"  :key="company._id">
                <div style="height: 155px; width: 155px;" class="box-item">
                <img :src="getImageThumbUrl(company.image)"  class="" alt="">
                </div>
                
                <h2 style="font-size: 17px;">{{company.name_fancy}}</h2>
            </div>
        </div> -->
  </div>
</template>

<script>

export default {
  props: ["companyList"],
  components: {

  },
  data() {
    return {

    };
  },
  methods: {

    scrollLeft() {
      let content = document.querySelector(".company-list");
      content.scrollLeft -= 1020;
    },
    scrollRight() {
      let content = document.querySelector(".company-list");
      content.scrollLeft += 1020;
    },
  }
};
</script>

<style lang="scss">
.company-list-wrapper {
  padding: 30px 0px;

  .title-wrapper {
    display: flex;
    flex-flow: row wrap;

    >div {
      flex: 1;
    }
  }

  .company-list {
    display: block;
    margin: 0px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    min-height: 60px;
    margin-right: 45px;
    margin-left: 45px;


    /* width */
    &::-webkit-scrollbar {
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--main-color-mediumgrey);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--main-color-darkgrey);
    }

    .company-list-item {
      width: auto;
      display: inline-flex;
      float: none;
      white-space: initial;
      // padding-bottom: 30px !important;
      vertical-align: top;
      display: inline-block;
      height: 215px;
      width: 200px;
      white-space: initial;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        padding: 10px;
      }

      h2 {
        // display: block;
        // bottom: 30px;
        // position: absolute;
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .box-item {
        pointer-events: none;
      }
    }

  }

  .left-arrow-btn {
    position: absolute;
    top: 155px;
    left: 0px;
    font-size: 40px;
    cursor: pointer;
    outline: none;
  }

  .right-arrow-btn {
    position: absolute;
    top: 155px;
    right: 0px;
    font-size: 40px;
    cursor: pointer;
    outline: none;
  }


}


@media only screen and (max-width: 600px) {

  .company-list-wrapper {

    .title-wrapper {
      padding: 0px 15px;

      .border {
        margin-top: 15px;
        width: 100%;
      }
    }

    .company-list {
      margin-right: 15px;
      margin-left: 15px;

      .company-list-item {
        height: 215px;
        width: 220px;

        .box-item {
          height: 175px !important;
          width: 100% !important;
        }
      }
    }

  }

}
</style>