<template>
  <div>
    <!-- <Sidebar /> -->
    <Header :headerType="3"></Header>
    <!-- <company-menu/> -->
    <main class="">
      <div class="refer-and-win-floating" data-toggle="modal" data-target="#referAndWinModal">
        <div class="refer-and-win-floating-left">$</div>
        <button class="refer-and-win-floating-btn">Indique e ganhe</button>
      </div>
      <slot></slot>
    </main>
    <Footer :footerType="3" />
    <refer-and-win-modal :modal_id="'referAndWinModal'"></refer-and-win-modal>
  </div>
</template>

<script>
import sessionMixin from '../mixins/sessionMixin.js'

import Header from "../components/Header.vue";
// import Sidebar from "../components/Sidebar.vue";
import Footer from "../components/Footer.vue";
// import CompanyMenu from "../components/CompanyMenu.vue";
import ReferAndWinModal from "../components/ReferAndWinModal.vue";

export default {
  mixins: [sessionMixin],
  components: {
    Header,
    // Sidebar,
    Footer,
    // CompanyMenu,
    ReferAndWinModal,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "../scss/reset.scss";
@import "../scss/base.scss";
@import "../scss/form.scss";
@import "../css/mobile.css";

.container-fluid-mobile {
  padding: 0px;
  /* background-color: #fff; */
}

.no-itens{
  min-height: 400px;
  vertical-align: middle;
}

.center.filters-div {
  // background: ;
}

.salesman-page{
  padding-top:  50px;
  padding-bottom:  50px;
}

@media only screen and (max-width: 600px) {
  main {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
</style>
