<template>
  <main-layout>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div">
      <div class="container">
        <div class="row">
          <div class="col-md-7 ">
            <h1 v-if="training.exclusive_company == null">Treinamento contratado</h1>
            <h1 v-else>Treinamento exclusivo</h1>
          </div>
          <div class="col-md-2 " v-if="training.exclusive_company == null">
            <button >Favoritos</button>
          </div>
          <div class="col-md-3 " v-if="training.exclusive_company == null">
            <button class="border-secundary" @click="goToTrainingSearch()">Buscar Treinamentos</button>
          </div>
        </div>
      </div>
    </div>
    <div class="training-contracted-exclusive container-fluid">
      <div class="row">
        <div class="col-md-4 light-grey-bg" style="padding-left: 0px; padding-right: 0px">
          <div class="" style="width: 100%; height: 260px; background-color: #e6dcdc">
            <img :src="getImageThumbUrl(training.image)" v-if="training.image" class="training-image" alt="">
          </div>
          <div class="training-info">
            <h2>{{training.name}}</h2>
            <div class="hr-yellow-light hr-4"></div>
            <div class="teacher" v-if="training.user">{{training.user.name}} {{training.user.lastname}}</div>
            <p v-html="training.description"></p>
            <div style="text-align: center">
              <button class="border" @click="goToTraining(training)">Pagina do treinamento</button>
            </div>
            
          </div>
        </div>
        <div class="col-md-8 ">
          <!-- <div style="display: flex">
            <h1>Funcionários</h1>
            <p style="position: absolute; right: 80px" v-if="training.exclusive_company == null">Licenças: {{license.buyed}} - Disponíveis: {{license.free}}</p>
          </div> -->
          <div class="row">
            <div class="col-md-6">
              <h1>Funcionários</h1>
            </div>
            <div class="col-md-6 last">
            <p >Licenças: {{license.buyed}} - Disponíveis: {{license.free}}</p>
            </div>
          </div>
          <div div class="hr-yellow-light hr-4"></div>
          <div class="row top-space">
            <div class="col-md-12 employee-item row" v-for="employee in employeeList" :key="employee._id">
              <div class="col-md-2">
                <img :src="getImageThumbUrl(employee.user.image)" v-if="employee.user.image" class="training-image" alt="">
              </div>
              <div class="col-md-7 item-bottom-border">
                <h3>{{employee.user.name}} {{employee.user.lastname}}</h3>
                <p>Login: {{employee.user.email}}</p>
                <!-- <p style="padding-top: 0px">Treinamentos: 5</p> -->
              </div>
              <div class="col-md-3 item-bottom-border">
                <button class="blue" style="width: 100%; height: 35px;" @click="subscribeEmployee(employee)" v-if="!isStudent(employee)">Liberar</button>
                <button class="blue" style="width: 100%; height: 35px;" @click="subscribeEmployee(employee)" v-else disabled>Liberar</button>
              </div>

              <hr class="d-block d-md-none d-lg-none">
            </div>
          </div>
          
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/Company.vue";
import { server } from "../../../js/variables.js";
import companyMixin from "../../../mixins/companyMixin.js";
import moment from 'moment';

export default {
  mixins: [companyMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      training: {},
      license: {},
      studentList: [],
      employeeList: [],
      employeeStudentsList: [],
    };
  },
  mounted() {
    var parameters = this.$route.query;
    this.getLicense();
  },
  watch: {
    company(newValue, oldValue) {
      // console.log(newValue, oldValue);
      // console.log("watch company", this.company);
      if(this.company._id){
        this.getStudents();
        this.getEmployee();
      }
    },
  },
  methods: {
    getLicense() {
      let self = this;
      var id = this.$route.params.id;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }
      
      this.axios
      .get(server.url + "/api/course/licenses?course=" + id, server.config)
      // .get(server.url + "/api/course/?company=" + this.company._id, server.config)
        .then(function (response) {
          let responseData = response.data.data;
          if(responseData.exclusive_licenses_list.length > 0){
            self.license = responseData.exclusive_licenses_list[0];
          }else{
            self.license = responseData.license_list[0];
          }
          self.training = self.license.course;
          
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    getStudents() {
      let self = this;
      var id = this.$route.params.id;
      if (!localStorage.token) {
        return
      }
      server.config.headers = {
        Authorization: 'Bearer ' + localStorage.token
      }

      let header = JSON.parse(JSON.stringify(server.config));
      header["params"] = {
        active: 1,
        course: id,
        company: this.company._id,
      };


      this.axios
      .get(server.url + "/api/student/" , header)
        .then(function (response) {
          let responseData = response.data.data;
          self.studentList = responseData.student_list;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
          console.log(error);
        });
    },
    isStudent(employee){
      if(this.studentList.some(student => student.user._id === employee.user._id)){
        // console.log("Object found inside the array.");
        return true
      } else{
        // console.log("Object not found.");
        return false
      }
    },
    searchEmployeeStudents(){
      this.employeeStudentsList = [];
      if(this.employeeList.length > 0 && this.studentList.length > 0){
        for(let employee of this.employeeList){
          if(this.studentList.some(student => student.user._id === employee.user._id)){
            // console.log("Object found inside the array.");
            this.employeeStudentsList.push(employee);
          } 
        }
      }
    },
    getEmployee(){
      let self = this;
			if (!localStorage.token) {
				return
			}
			server.config.headers = {
				Authorization: 'Bearer ' + localStorage.token
			}

      this.axios
        .get(server.url + "/api/company/" + self.company._id +"/employee", server.config)
        .then(function (response) {
          let responseData = response.data.data;
          self.employeeList = responseData.company_list;
          for(let employee of self.employeeList){
            employee.user.created_at = moment(employee.user.created_at).add(4, 'hours').format('DD/MM/YYYY');
          }
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    subscribeEmployee(employee) {
      let self = this;
      var id = this.$route.params.id;
      if(self.license.free > 0){
        if (!localStorage.token) {
          return;
        }
        server.config.headers = {
          Authorization: "Bearer " + localStorage.token,
        };

        this.axios
          .post(
            server.url + "/api/student/",
            {
              course: id,
              user: employee.user._id,
            },
            server.config
          )
          .then(function (response) {
            location.reload();
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
            console.log(error);
          });
      }else{
        self.$swal({
          icon: 'warning',
          title: 'Atenção',
          text: 'Você não tem licenças disponíveis.',
          // footer: '<a href>Why do I have this issue?</a>'
          showCancelButton: false,
          confirmButtonColor: '#053360',
          confirmButtonText: 'Ok'
          }).then((result) => {
          if (result.isConfirmed) {
            // window.open("./co/vagas", "_self"); 
          }
        })
      }
    },
    goToTraining(training){
      let self = this;
      const url = '/co/treinamento/' + training._id;
      self.$router.push(url);
    },
    goToTrainingSearch(){
      let self = this;
      var id = this.$route.params.id;
      const url = "/co/buscar-treinamentos/" ;
      self.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.training-contracted-exclusive {
  padding-top: 35px;

  .training-image{
    width: 100%;
    max-height: 100%;
  }

  .hr-yellow-light.hr-4{    
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .teacher{
    font-size: 13px;
    color: var(--main-title-color);
  }

  p{
    padding-top: 8px;
  }

  .training-info{
    padding: 50px 75px;
    
    .border{
      height: 34px;
      width: 80%;

      margin-top: 25px;
    }
  }

  .employee-item{
    margin-bottom: 12px;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 15px;
    background-color: var(--main-color-lightgrey);
    border-radius: 12px;

    .item-bottom-border{
      // border-bottom: 1px solid var(--main-color-lightgrey2);
      button{
        margin-top: 7px;
      }
    }
  }

  .col-md-8{
    padding: 0px 80px;
  }
}



@media only screen and (max-width: 600px) {
  .training-contracted-exclusive {
    padding-top: 0px;

    .training-info{
      padding: 20px 25px
    }

    h1{
      padding-top: 25px;
    }

    .employee-item{
      .item-bottom-border{
        border-bottom: none;
        
      }
    }
    .col-md-8{
      padding: 0px 30px;
    }
  }
}
</style>
