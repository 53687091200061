<template>
  <main-layout>
	<!-- <pre> password {{ $v.postAddress }}</pre> -->
	<!-- <pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container product-details">
        <div class="row">
            <div class="col-md-5">
                <h2>{{product.company.name_fancy}}</h2>
                <h1>{{product.name}}</h1>
                <div class="hr-yellow hr-4" style="margin-top: 10px; margin-bottom: 10px;"></div>
                <h2 v-if="product.groups[0]">{{product.groups[0].name}}</h2>
            </div>
            <div class="col-md-7 last">
                <button @click="goTo('./vc/cadastro/')">Solicitar representação de produto. Quero vender este produto</button>
            </div>
            <div class="col-md-4 top-space" v-for="image in product.image" :key="image._id">
                <img :src="getImageThumbUrl(image)" alt="" class="product-image box-item">
            </div>
            <div class="col-md-12 top-space">
                <h2>Descrição</h2>
                <p class="blue-text grey-bg">{{product.description}}</p>
            </div>
            <div class="col-md-6 top-space">
                <h2>Preços</h2>
                <div class="grey-bg">
                    <p class="blue-text prices-bigger">Preço venda: <b>{{product.price | toCurrency}}</b></p>
                    <hr>
                    <p class="blue-text prices-bigger">Comissão: <b>{{product.sales_commission}}% ({{(product.sales_commission/100) * product.price | toCurrency}})</b></p>
                </div>
            </div>
            <div class="col-md-6 top-space">
                <h2>Ajuda de custo da empresa</h2>
                <div class="grey-bg" v-if="product.company">
                    <p class="blue-text prices-bigger">A partir: <b>{{product.company.salary_min | toCurrency}}</b></p>
                    <hr>
                    <p class="blue-text prices-bigger">Até: <b>{{product.company.salary_max | toCurrency}}</b></p>
                </div>
            </div>
        </div>
        <div class="row top-space" v-if="product.company">
        <div class="col-md-10 top-space">
          <h2>{{product.company.name_fancy}}</h2>
          <div class="hr-yellow hr-4" style="margin-top: 10px; margin-bottom: 10px"></div>
          <p v-if="product.company.address.length > 0"> 
            {{product.company.address[0].city}} - 
            {{product.company.address[0].state}}
          </p>
        </div>
<!-- 
        <div class="col-md-2 top-space" v-if="product.company.image">
          <img
            :src="getImageThumbUrl(product.company.image)"
            alt=""
            class="product-image"
          />
        </div> -->

        <div class="col-md-12 top-space">
          <div class="grey-bg" >
            <p>
              {{product.company.description}}
            </p>
          </div>
        </div>
      </div>
        <br>
        <div class="row">
            <div class="col-md-5 top-space">
                <h2>Outros produtos da família / categoria</h2>
            </div>
            <div class="col-md-7 last top-space">
                <button @click="goTo('./vc/cadastro/')">Solicitar representação de categoria. Quero vender os produtos desta categoria</button>
            </div>
            
        </div>
        <br>
        <div class="row">
                <div class="col-6 col-md-3"
                    v-for="productItem in productList"
                    :key="productItem._id" >
                    <product-item :product="productItem" :type="0"> </product-item>
                </div>
            </div>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import moment from 'moment'
import { server } from "../js/variables.js";
import ProductItem from "../components/ProductItem.vue";
import { required, email, sameAs } from 'vuelidate/lib/validators'


export default {
	components: {
    MainLayout,
    ProductItem
  },
  data () {
    return {
        product:{
			name: '',
			description: '',
			is_featured: '',
			representation: '',
			unit_measurement: '',
			sales_commission: '',
			ean: '',
			price: '',
			call: '',
			tech_info: '',
			groups: [],
        },
        productList: []
    }
  },
  validations: {

},
	mounted() {
		
		var parameters = this.$route.query;
		let self = this;
		this.getProduct();
	},
	watch:{
	},
	
  methods: {
    getProduct(){
		let self = this;
		var id = this.$route.params.id;
		// console.log("get salesmans");
		// if (!localStorage.token) {
		// 	return
		// }
		// server.config.headers = {
		// 	Authorization: 'Bearer ' + localStorage.token
		// }

		this.axios.get(  server.url + '/api/product/' + id, server.config)
		.then(function (response) {
			let responseData = response.data.data;
            self.product = responseData.product;
            self.productList = responseData.product_list;
		})
		.catch(function (error) {
			self.output = error;
			self.showError(error);
			console.log(error);
		});
	},
	
  }
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.product-details{
    padding-top: 45px;
    padding-bottom: 45px;
    white-space: break-spaces;
    
    h2{
        margin-bottom: 10px;
    }

    p{
        font-size: 16px;
    }

    .grey-bg{
        background-color: var(--main-color-lightgrey);
        border-radius: 12px;
        padding: 20px 50px;
        
    }

    .prices-bigger{
        font-size: 18px;
    }

    .blue-text{
        color: var(--main-title-color) !important;
    }

    button{
        width: auto;
    }
}



</style>
