<template>
  <main-layout>
    <div>
    <div class="sub-menu container-fluid light-grey-bg grey-title-div" style="height: auto;">
      <div class="container">
        <div class="row" style="">
          <div class="col-md-7 ">
            <h1 class="mt-3" >Encontre oportunidades de emprego</h1>
          </div>
          <div class="col-md-5 ">
            <button v-on:click="goTo('./vc/candidaturas')">Vagas inscritas</button>
            <button v-on:click="goTo('./vc/treinamentos-inscritos')" style="height: auto;min-height: 34px;">Treinamentos gratuitos para assinantes</button>
          </div>
        </div>
      </div>
    </div>

<!-- 
    <div
      class="container-fluid light-grey-bg filter-opportunities"
      style="height: 230px"
    >
      <h2 class="">Buscar vagas por</h2>
      <div class="center filters-div">
        <div class="row">
          <div class="col-md-3">
            <input
              id="name"
              type="text"
              placeholder="Buscar"
              v-on:keyup.enter="getOpportunities()"
              v-model="search.searchText"
            />
          </div>
          <div class="col-md-2">
            <v-select
              :options="stateList"
              v-on:input="getOpportunities"
              v-model="search.state"
              :placeholder="'Estado'"
            >
            </v-select>
          </div>
          <div class="col-md-2">
            <v-select
              :options="cityList"
              v-on:input="getOpportunities"
              v-model="search.city"
              :placeholder="'Cidade'"
            >
            </v-select>
          </div>
          <div class="col-md-2">
            <v-select
              label="name"
              :options="positionList"
              v-on:input="getOpportunities"
              v-model="search.position"
              :reduce="(position) => position.value"
              :placeholder="'Cargo'"
            >
            </v-select>
          </div>
          <div class="col-md-3">
            <v-select
              label="name"
              :options="segmentList"
              v-on:input="getOpportunities"
              v-model="search.segment"
              :reduce="(segment) => segment._id"
              :placeholder="'Segmento'"
            >
            </v-select>
          </div>
        </div>
        <div class="row">
          <div class="offset-md-4 col-md-4">
            <button style="margin-top: 10px;" @click="getOpportunities()">Buscar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="container candidatures">
      <div class="row">
        <div
          class="col-md-6"
          v-for="opportunity in opportunityList"
          :key="opportunity._id"
          @click="opportunityNow = opportunity"
          data-toggle="modal"
          data-target="#opportunityDetailsModal"
        >
          <opportunity-item :opportunity="opportunity" :type="0">
          </opportunity-item>
        </div>
      </div>
    </div>

    <opportunity-details-modal
      :modal_id="'opportunityDetailsModal'"
      :opportunity="opportunityNow"
      :showCandidateButton="true"
    ></opportunity-details-modal> -->

    <div class="container section candidatures">
      <opportunity-finder :showCandidateButtonFinder="true" :typeFinder="1" :typeCard="0"></opportunity-finder>
    </div>
  </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/SalesmanProfile.vue";
// import OpportunityItem from "../../../components/OpportunityItem.vue";
// import OpportunityDetailsModal from "../../../components/OpportunityDetailsModal.vue";
import OpportunityFinder from "../../../components/OpportunityFinder.vue";
import { server } from "../../../js/variables.js";

export default {
  components: {
    MainLayout,
    // OpportunityItem,
    // OpportunityDetailsModal,
    OpportunityFinder
  },
  data() {
    return {
      opportunityList: [
        // {_id: 1, name: "Título da vaga 1", position: 'Gerente 1', amount: 5, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. ', address: {city: 'São Paulo', state: 'SP'}},
        // {_id: 2, name: "Título da vaga 2", position: 'Gerente 2', amount: 10, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. ', address: {city: 'São Paulo', state: 'SP'}},
        // {_id: 3, name: "Título da vaga 3", position: 'Gerente 3 ', amount: 2, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. ', address: {city: 'São Paulo', state: 'SP'}},
        // {_id: 4, name: "Título da vaga 4", position: 'Gerente 4', amount: 15, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt. Nulla facilisi cras fermentum odio eu. Non curabitur gravida arcu ac tortor. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Sollicitudin nibh sit amet commodo nulla facilisi nullam. Massa sed elementum tempus egestas sed sed risus pretium quam. Orci a scelerisque purus semper eget duis at tellus. ', address: {city: 'São Paulo', state: 'SP'}},
      ],
      segmentList: [
        // {id: 1, name: "Calçados"},
        // {id: 2, name: "Eletronicos"},
      ],
      positionList: [
        { _id: 1, name: "Estagiário", value: "INTERN" },
        { _id: 2, name: "Júnior", value: "JUNIOR" },
        { _id: 3, name: "Pleno", value: "FULL" },
        { _id: 4, name: "Sênior", value: "SENIOR" },
        { _id: 5, name: "Coordenador", value: "COORDINATOR" },
        { _id: 6, name: "Gerente", value: "MANAGER" },
        { _id: 7, name: "Diretor", value: "DIRECTOR" },
      ],
      search: {
        state: null,
        city: null,
        segment: null,
        position: null,
        searchText: null,
      },
      opportunityNow: {},
    };
  },
  mounted() {
    var parameters = this.$route.query;
  },
  methods: {
    opportunityDetails(opportunity) {
      const url = "/vc/vaga/" + opportunity._id;
      this.$router.push(url);
    },
    // typeCard(){

    //   console.log(this.$route.path == "/vc/buscar-vagas" || this.$route.path == "/vc/buscar-vagas/");
    //   console.log(this.$route);
    //   if(this.$route.path == "/vc/buscar-vagas" || this.$route.path == "/vc/buscar-vagas/") return 1
    //   else return 1

    // }
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.opportunities {
  .filter-search {
    margin-bottom: 35px;

    input {
      margin-top: 0px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .opportunities {
    .filter-search {
      margin-top: 20px;
    }
  }
}
</style>
