<template>
  <div>
    <main>
      <slot></slot>
    </main>
  </div>
</template>

<script>


export default {
  // mixins: [sessionMixin],
  components: {
    // CompanyMenu,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "../scss/reset.scss";
@import "../scss/base.scss";
@import "../scss/form.scss";
@import "../css/mobile.css";

.container-fluid-mobile {
  padding: 0px;
  /* background-color: #fff; */
}



@media only screen and (max-width: 600px) {
  main {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
</style>
