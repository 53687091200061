<template>
    <div class="favorite-salesman-box box-item" >

        <div class="row" @click="salesmanDetails(salesman)">
            <div class="col-md-3">
                
                <div v-if="salesman.image" class="salesman-frame " >
                    <img :src="getImageThumbUrl(salesman.image)"  class="user-img" alt="">
                </div>
                
                <div v-else class="salesman-frame" >
                    <img class="user-img" src="/static/img/logos/logo-vcvende.jpg"  alt="">
                </div>
            </div>
            <div class="col-md-8">
                <img class="icon-favorite not-favorite" src="/static/svg/icon_favorite.svg" alt="" @click="addFavorite($event)" v-if="favorite == 0">
                <img class="icon-favorite" src="/static/svg/icon_favorite.svg" alt="" @click="removeFavorite($event)" v-if="favorite == 1">
                <h3>{{salesman.name}} {{salesman.lastname}}</h3>
                <div class="hr-yellow-light hr-4"></div>
                <p class="title" v-if="salesman.resume.title">{{salesman.resume.title}}</p>
                <p class="place" v-if="salesman.address && salesman.address[0]"> 
                    {{salesman.address[0].city}} | {{salesman.address[0].state}}
                </p>
                <p class="sumary" >{{salesman.resume.summary}}</p>
            </div>
            <div class="col-md-1">

            </div>
        </div>
        
    </div>
</template>

<script>
    import { server, local } from "../js/variables.js";
    import companyMixin from '../mixins/companyMixin.js'

    export default {
        mixins: [companyMixin],
        props: ['salesman'],
        components: {
            
        },
        data(){
            return {
                favorite: 0
            }
        },
        // beforeCreate: function() {
        //     if (!localStorage.token) {
        //         server.config.headers = {
        //             Authorization: 'Bearer ' + localStorage.token
        //         }
        //     }
        // },
        mounted() {
            
        },
        watch:{
            company(newValue, oldValue){
                // console.log(newValue._id, oldValue._id);
                this.checkFavorite();
            }
        },
        methods: {
            addFavorite(event) {
                var parameters = this.$route.query;
                let self = this;
                if(event.stopPropagation) event.stopPropagation();
                if (!localStorage.token) {
                    return
                }
                server.config.headers = {
                    Authorization: 'Bearer ' + localStorage.token
                }
                // this.loading = true;
                this.axios.put(  server.url + '/api/company/' + this.company._id + '/salesman/' + this.salesman._id, server.config)
                .then(function (response) {
                    self.favorite = 1;
                    // this.loading = false;
                })
                .catch(function (error) {
                    
                });
                
            },
            removeFavorite(event) {
                var parameters = this.$route.query;
                let self = this;
                if(event.stopPropagation) event.stopPropagation();
                // console.log("add favorite", this.company._id, this.salesman._id)
                if (!localStorage.token) {
                    return
                }
                server.config.headers = {
                    Authorization: 'Bearer ' + localStorage.token
                }
                
                this.axios.delete(  server.url + '/api/company/' + this.company._id + /salesman/ + this.salesman._id, server.config)
                .then(function (response) {
                    self.favorite = 0;
                })
                .catch(function (error) {
                    
                });
                
            },
            checkFavorite() {
                if(!this.company.favourites) {
                    this.favorite = 0;
                    return;
                }
                for (let p of this.company.favourites ){
                    if (p._id == this.salesman._id) {
                         this.favorite = 1;
                         return;
                    }
                }
                this.favorite = 0;
                // console.log("checkFavorite - not found");
            },
            salesmanDetails(salesman){
                // window.open("./co/vendedor/" + salesman._id, "_self");

                const url = "/co/vendedor/" + salesman.slug;
                this.$router.push(url);
            },
        }
    }
</script>

<style lang="scss">

.favorite-salesman-box {
    // padding: 12px 16px;
    margin-bottom: 25px !important;
    height: 260px;

    h3{
        font-size: 21px;
        margin-top: 10px;
        margin-bottom: 12px;
    }

    .salesman-frame{
        
    }

    p.sumary{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
    }

    p.place{
        padding-bottom: 0px;
    }

    p.title{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    p{
        margin-top: 12px;
        // padding-bottom: 30px;
        // // text-overflow:ellipsis;
    }

    button{
        font-weight: normal;
        height: 32px;
        width: 152px;
    }

    // img{
    //     position: absolute;
    //     margin: auto;
    //     top: 0;
    //     left: -19px;
    //     right: 0;
    //     bottom: 0;
            // margin-top: 10px;
    // }
}

@media only screen and (max-width: 600px) {
    .favorite-salesman-box {
        min-height: 370px;
        white-space: initial;
        padding: 0px;


        .salesman-frame {
            height:116px;
        }

        .user-img{
            height: 116px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%)
        }

        .user-img-empty{
            left: 37px;
        }
    }
    
}
</style>