<template>
  <main-layout>
	<!-- <pre> password {{ $v.login.password }}</pre>
	<pre> passwordConfirm {{ $v.passwordConfirm }}</pre> -->
    <div class="container-fluid login-user">
		<div class="row">
			<div id="login-box" class="col-md-4 no-padding">
                <br><br>
				<a href target="_self">
					<img src="/static/svg/logo_below.svg" alt="" class="center">
				</a>
                
				<h1>Conectar-se</h1>
                <div class="inputs-login center">
                    <input id="email" type="text" v-model="$v.login.email.$model" placeholder="Login e-mail">
                    
                    <div class="password">
                        <input id="password" :type="passwordInputType" v-model="$v.login.password.$model" v-on:keyup.enter="loginMethod" placeholder="Senha">
                        <button class="clean" @click="showHidePassword()">
                            <i class="far fa-eye" v-if="passwordInputType === 'password'"></i>
                            <i class="far fa-eye-slash" v-if="passwordInputType === 'text'"></i>
                        </button>
                    </div>
                    
                    <button class="clean" @click="forgetPassword()">
                        Esqueci a senha
                    </button>
                    <button class="blue float-right" @click="loginMethod()">Entrar</button>
                </div>
                <div class="main-color-bg create-acount">
                    <!-- <div class="center"> -->
                        <p>Ainda não tem uma conta?</p>
                        <a :href="createLink">Criar conta grátis</a>
                    <!-- </div> -->
                    
                </div>
			</div>
			<div class="col-md-8 no-padding">
				<div class="image-box" style="background-image: url(./static/img/vc-register.jpg)"></div>
                <!-- <img src="/static/img/vc-register.jpg" alt /> -->
                <!-- <div class="banner-content" v-if="this.$route.query.redirect == '/vc/buscar-vagas'">
                    <h2 class="yellow">Você é profissional da área comercial buscando</h2>
                    <h2 class="blue"> oportunidades ou produtos para vender?</h2>
                    <div class="d-none d-md-block d-lg-block">
                        <p style="background-color: #f5f1d0; padding: 10px;">Vocevende é ideal para você que deseja vender produtos ou<br>
                            procura oportunidades na área comercial.
                        </p>
                    </div>
                </div> -->
			</div>
		</div>

        
        
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Basic.vue";   
import { server } from "..//js/variables.js";
import { required, email, sameAs } from 'vuelidate/lib/validators'

const link = "/static/img/produto.png";

export default {
  components: {
    MainLayout,
  },
  data () {
    return {
        slideList:[],
        login: {
			email: '',
			password: '',
        },
        redirect: '',
        passwordInputType: 'password',
        createLink: './vc/cadastro',
    }
  },
  validations: {
        login: {
			email: { required },
			password: { required },
        },
        
	},
    mounted() {
		let self = this;
        this.redirect = this.$route.query.redirect;
        console.log(this.redirect);
        if( this.redirect && this.redirect.indexOf('/vc') == -1 ){
            this.createLink = './co/cadastro'
        }else{
            this.createLink = './vc/cadastro'
        }
		
    },
	methods: {
		onPageChange(page) {
		this.sliderPage = page;
		},
		forgetPassword() {
            // window.open("./solicitar-nova-senha", "_self");
            const url = "/solicitar-nova-senha";
            this.$router.push(url);
		},
		loginMethod(){
			let self = this;
			if (!this.$v.$invalid){

				// if (!localStorage.token) {
				// 	return
				// }
				// server.config.headers = {
				// 	Authorization: 'Bearer ' + localStorage.token
				// }
                
                if(this.login.password.length < 6){
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Senha deve ter no mínimo 6 caracteres',
                        // footer: '<a href>Why do I have this issue?</a>'
                    });
                    return
                }
                
				this.axios.post(  server.url + '/api/auth/login', {
					email: self.login.email,
					password: self.login.password,

				}, server.config)
				.then(function (response) {
                    let token = response.data.data.auth.token;
                    let user = response.data.data.user;
                    let company = response.data.data.company;
					localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(user) );
                    localStorage.setItem('company', JSON.stringify(company));
                    // console.log('company', company);
                    if(self.redirect){
                        // console.log('entrou');
                        window.open (self.redirect , '_self');
                    }
                    else if(user.type == "SALESMAN"){
                        window.open ('/vc/buscar-vagas' , '_self');
                    }else{
                        let isEmployee = false;
                        for(let employee of company.employees){
                            // console.log('comparaçao', employee.user, user._id, employee.manager);
                            // console.log('== ', employee.user == user._id && employee.manager == false);
                            if(employee.user === user._id && employee.manager == false){
                                // console.log('achou', company);
                                isEmployee = true;
                            }
                        }
                        if(isEmployee){
                            // console.log('é employee', company);
                            window.open ('/em/' , '_self');
                        }else{
                            // console.log('é company', company);
                            window.open ('/co/vagas' , '_self');
                        }
                        
                    }
					
				})
				.catch(function (error) {
					self.output = error;
					self.showError(error);
					console.log(error);
				});
			}else{
				this.$v.$touch();
				this.$swal({
					icon: 'error',
					title: 'Oops...',
					text: 'Verifique os campos obrigatórios',
					// footer: '<a href>Why do I have this issue?</a>'
				});
			}
		},
        showHidePassword(){
            this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
        },
		
	}
};
</script>

<style lang="scss">

// @import "../scss/reset.scss";
// @import "../scss/base.scss";
// @import "../scss/form.scss";
// @import "../css/mobile.css";

#login-box{
	height: 100vh;
}

.login-user{

    h1{
        text-align: center;
        // font-size: 36px;
        margin-top: 60px;
        margin-bottom: 30px;
    }

	.image-box{
		background-size:cover;
		background-position: center center;
		background-repeat: no-repeat;

		height: 100%;
		width: 100%;
	}
	

    img{
        // margin-top: 20px;
        // margin-bottom: 60px;
        // padding-top: 70px;
        // padding-bottom: 30px;
    }

    .create-acount{
        height: 55px;
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 1px;
        
        
        p{
            color: white;
            display: inline-block;
            margin-right: 5px;
            margin-top: 14px;
        }

        a{
            color: var(--main-color-secundary);
            // display: inline-block;
        }

    }
    
    .inputs-login{
        width: 85%;

        button{
            font-weight: normal;
            padding: 8px 20px; 

            &:focus{
               outline: none; 
            }
        }

        input{
            background-color: var(--main-color-lightgrey);
            border: 0px !important;
            margin-bottom: 10px;
        }

        .password{
            position: relative;
            button{
                position: absolute;
                right: 0px;
                top: 4px;
            }
        }
    }

    .banner-content {
        position: absolute;
        z-index: 100;
        top: 40%;
        left: 10%;

        h2{
            color: var(--main-color-primary);
            margin-bottom: 4px;
            text-align: center;
        }

        .yellow{
            background-color: var(--main-color-secundary);
            padding: 10px;
        }

        .blue{
            background-color: var(--main-color-primary);
            padding: 10px;
            color: white;
            line-height: 35px;
        }

        p {
            margin-top: 30px;
            color: var(--main-color-primary);
            letter-spacing: 0.15px;
            line-height: 15pt;
        }

        button{
            margin-top: 30px;
            // margin-right: 15px;
            width: 150px;
        }


    }
}

@media only screen and (max-width: 600px) {

	.image-box {
		display: none;
	}

	.login-user .inputs-login{
		margin-bottom: 50px;
		position: relative;
	}

	.login-user .create-acount {
		position: fixed;
		bottom: 0px;
		left: 0px;
		z-index: 1000;
	}

    .login-user .banner-content{
        top: 8%;
        left: 3%;

        h2{
            font-size: 12px;
        }

        .yellow{
            background-color: var(--main-color-secundary);
            padding: 10px;
            width: 272px;
        }

        .blue{
            background-color: var(--main-color-primary);
            padding: 10px;
            color: white;
            line-height: 20px;
            width: 261px;
        }

        p {
            margin-top: 30px;
            color: white;
            letter-spacing: 0.15px;
            line-height: 15pt;
        }
        button{
            margin-top: 12px;
        }
    }
}


</style>
