<template>
  <main-layout>
    <div class="container-fluid light-grey-bg">      
      <div class="affiliate menu-content-box">
        <div class=" affiliate-explanation">
          <div class="row">
            <div class="col-md-12">
              <h1 class="h1-affiliate">Indique e lucre</h1>
              <h1 class="h1-affiliate-small">todos os dias</h1>
              <!-- <h2>Veja como é fácil se tornar um afiliado de sucesso</h2> -->
              <p style="padding-top: 15px">
                Este é seu link de afiliado, ao indicar para profissionais e empresas que utilizarem para se cadastrar e adquirirem um produto ou serviço, você automaticamente já recebe um bônus, uma renda.
              </p>
              <br>
              <h2>Basta copiar seu link e começar agora mesmo.</h2>
              <div class="light-grey-bg copy-link" @click="copyURL" v-if="user._id">
                <p style="display: inline-block">{{afiliateLink}}</p>
                <i class="far fa-copy"></i>
              </div>
              <h2>Agora é só divulgar! Compartilhe nas suas redes sociais e ganhe Bônus em cada venda realizada.</h2>
              <br>
              <p v-if="!afiliateIsPaid">Agora se quiser ter maior bônus com maior renda se torne um repreentante do Vocevende se tornando um Afiliado Hunter</p>
              <br>
              <p style="display: inline-block">Administre seus rendimentos</p><button class="clean" style="font-weight: 100;padding-left: 5px;text-decoration: underline;" @click="goToEarnings">aqui</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../../layouts/SalesmanProfile.vue";
import userMixin from "../../../mixins/userMixin.js";
import moment from "moment";
import VueScrollTo from "vue-scrollto";
import { server, local } from "../../../js/variables.js";
import { required, email, sameAs } from "vuelidate/lib/validators";

const link = "/static/img/produto.png";

export default {
  mixins: [userMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      afiliateLink: '',
      afiliateIsPaid: 0,
      afiliatePlanName: 'Afiliado Básico',
    };
  },
  validations: {},
  mounted() {

  },
  watch: {
    user(newValue, oldValue) {
      if(this.user._id){
        this.afiliateLink = window.location.origin + "/?afiliado=" + this.user.slug;
      }
      if(local.subscriptionList){
        for(let subscription of local.subscriptionList){
          // console.log("plano", subscription)
          if(subscription.plan.type == 'AFILIATE'){
            if(subscription.plan.payment_option.length > 0){
              this.afiliateIsPaid = 1;
            }else{
              this.afiliateIsPaid = 0;
            }
            this.afiliatePlanName = subscription.plan.name;
          }
        }
      }
    },
  },

  methods: {
    goToEarnings(){
      const url = "/vc/recebimentos";
      this.$router.push(url);
    },
    copyURL() {
      if(this.user.slug){
        let url = window.location.origin + "/?afiliado=" + this.user.slug;
        // console.log(url)
        navigator.clipboard.writeText(url);
      }
    },
  },
};
</script>

<style lang="scss">
// @import "../../../scss/reset.scss";
// @import "../../../scss/base.scss";

.copy-link{
  padding: 10px 20px;
  width: auto;
  cursor: pointer;
  margin-top: 10px;

  p{
    display: inline-block;
  }

  i{
    padding-left: 10px;
  }
}


@media only screen and (max-width: 600px) {
  
}
</style>
